import * as moment from 'moment';
import * as momenttz from 'moment-timezone';

const FormatDate = (epochDate, dateFormat) => {
  if (epochDate && dateFormat) {
    let date = moment(epochDate).format(dateFormat);
    return date;
  }
};

const FormatLocalTimeZone = (epochTime, timeFormat) => {
  if (epochTime && timeFormat) {
    let zone = momenttz.tz.guess();
    let timeZone = momenttz.tz(zone).zoneAbbr();
    let timeStamp = moment(epochTime).format(timeFormat);
    let timeStampWithTimeZone = timeStamp + '  ' + timeZone;
    console.log('****** FormatLocalTimeZone *********:' + timeStampWithTimeZone);
    return timeStamp;
  }
};

export { FormatDate, FormatLocalTimeZone };
