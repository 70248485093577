import {
  DELETE_VEHICLE_HISTORY_EVENT,
  DELETE_VEHICLE_HISTORY_EVENT_SUCCESS,
  DELETE_VEHICLE_HISTORY_EVENT_FAILURE
} from '../../actions/types';

const initialState = {
  info: null
};

// Search for customers associated with a particular vehicle
const deleteVehicleHistoryEventReducer = (state = initialState, action = { payload: {} }) => {
  let newState = { ...state };
  let actionType = (action && action.type) || 'default';
  let responseData = action.payload || {};
  /*
  console.log(`deleteVehicleHistoryEventReducer >> ${actionType}`);

  console.log(
    'action.payload is in deleteVehicleHistoryEventReducer is :::' +
      JSON.stringify(action.payload)
  );
*/
  switch (actionType) {
    case DELETE_VEHICLE_HISTORY_EVENT:
      newState = { ...initialState };
      return newState;

    case DELETE_VEHICLE_HISTORY_EVENT_SUCCESS:
      let { data } = responseData;
      newState['info'] = data;

      return newState;

    case DELETE_VEHICLE_HISTORY_EVENT_FAILURE:
      newState['info'] = null;
      return newState;

    default:
      return newState;
  }
};

export default deleteVehicleHistoryEventReducer;
