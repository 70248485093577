import React from 'react';
import { BuyPlansContext } from '../BuyPlansContext';
import { ServiceResponseStatus, findArticleDisclaimerText } from '../buyPlansUtil';
import { Link } from 'react-router-dom';
import MarketingProducts from '../components/MarketingProducts';
import { UncontrolledAlert } from 'reactstrap';
import { getStorePricing, purchaseZeroCostArticle } from '../../providers/EstoreProvider';
import { findErrorMessage } from '../../../../util/i18n/i18nService';
import Price from '../components/Price';
import Spinner from '../../Spinner';
import { linkChamberlainAccount } from '../../providers/AccountAndSecurityProvider';
import { redirectBrowserTo } from '../../../../util/utilityMethods';
import ThemedToast from '../../../reusable_cmp_lib/ThemedToast/ThemedToast';
import MyQLinkStatus from '../components/MyQLinkStatus';

export default class MyQGaragecontrolTrial extends React.Component {
  static contextType = BuyPlansContext;

  state = {
    loading: false,
    alertMessage: null,
    articleDisclaimer: null,
    planPricing: null,

    trialInitializeSuccessful: false,
    showModalTrialLink: false
  };

  componentDidMount () {
    this.initData();
  }

  initData = async () => {
    let plan = this.context?.selectedPlan;

    if (!plan) {
      const marketingConfigurationId = this.props.match?.params?.marketingConfigurationId;
      const { additionalPlans, selectPlanFromList } = this.context;

      const [, category, subCategory] = this.props.match?.url?.split('/') || [];
      plan = selectPlanFromList(additionalPlans, category, subCategory, marketingConfigurationId);
    }

    const marketingConfiguration = this.context.marketingConfigurationHash?.[plan?.marketingConfigurationId];
    const articleDisclaimer = findArticleDisclaimerText(marketingConfiguration);

    this.setState({ articleDisclaimer });
    this.initPackagePricing(plan);

    window.scrollTo(0, 0);
  };

  initPackagePricing = async plan => {
    const { category, subCategory, provider } = plan || {};

    this.setState({ loading: true });

    try {
      const data = await getStorePricing(category, subCategory, provider?.name, this.context.cart?.cartId);

      if (Array.isArray(data) && data.length > 0) {
        this.setState({ loading: false, planPricing: data[0] });
      }
    } catch (error) {
      const alertMessage = findErrorMessage(error);
      this.setState({ loading: false, alertMessage });
    }
  };

  clearAlerts = () => this.setState({ alertMessage: null });

  getAlerts = () => {
    if (this.state.alertMessage) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts} aria-live='polite' className='w-100'>
          {this.state.alertMessage}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  startTrial = () => {
    const { subCategory } = this.context?.selectedPlan;

    this.setState({ loading: true }, async () => {
      let trialInitializeSuccessful = false,
        showModalTrialLink = false;
      try {
        const { result, status } = await purchaseZeroCostArticle(subCategory);

        if (result === 0 || status === ServiceResponseStatus.SUCCESS) {
          trialInitializeSuccessful = true;
          showModalTrialLink = true;
        } else {
          console.error(`Received failure response while activating trial - Status: '${status}', Result: '${result}'`);
        }

        this.setState({ loading: false, trialInitializeSuccessful, showModalTrialLink });
      } catch (ex) {
        this.setState({ loading: false, alertMessage: findErrorMessage(ex) });
      }
    });
  };

  myQLinkClickAction = () => {
    window.open(
      'https://www.myqservices.com/prelanding?clientid=86465005647266004803562006688341063076811336.apps.myqdevices.com'
    );
  };

  initializeChamberlainAccountLinking = async () => {
    try {
      let url = await linkChamberlainAccount({ endUrl: 'buy-plans' });
      redirectBrowserTo(url);
    } catch (ex) {
      this.setState({ alertMessage: findErrorMessage(ex) });
      console.error('unable to initiate chamberlain account linking');
    }
  };

  render () {
    const {
      translator,
      contentURL,
      showDisclaimer,
      selectPlan,
      selectedPlan: plan,
      marketingConfigurationHash
    } = this.context;
    const marketingConfiguration = marketingConfigurationHash?.[plan?.marketingConfigurationId] || {};

    const planPricing = this.state.planPricing;
    const pricingDisclaimerList = marketingConfiguration?.pricingDisclaimerList;

    return (
      <div role='main' id='main-content' className='py-4'>
        <div className='ml-4 position-absolute'>
          <Link to='/' className='linkBack' role='button' onClick={() => selectPlan(null)}>
            {translator.t('buyplans.button-label.back-to-your-plans')}
          </Link>
        </div>
        <div className='container d-flex flex-column align-items-center text-center mt-4 pb-5'>
          <h1 className='font-weight-light'>
            {marketingConfiguration?.currentPlanContent?.title || marketingConfiguration?.title}
            {this.state.articleDisclaimer && (
              <sup
                className='cwp-disclaimer ml-2 mainTitlePosition'
                tabIndex={0}
                role='button'
                onClick={e =>
                  showDisclaimer(
                    this.state.articleDisclaimer,
                    marketingConfiguration.articleDisclaimerNumber,
                    e.currentTarget
                  )
                }
              >
                {marketingConfiguration?.articleDisclaimerNumber}
              </sup>
            )}
          </h1>

          <article className='text-muted text-center mt-2 mb-5'>
            {marketingConfiguration?.description} {marketingConfiguration?.additionalDescription}
          </article>
          {this.getAlerts()}
          {this.state.loading && <Spinner />}
          {Array.isArray(planPricing) &&
            planPricing.map(pp => {
              const sku = pp?.package?.sku;
              const pricingOptions = pp?.package?.pricingOptions || [];
              return (
                <React.Fragment key={`planPricing-${sku}`}>
                  {pricingOptions.map(po => (
                    <article
                      key={`pricingOption-${po.sku}-${po.priceOptionId}`}
                      className='row mx-0 mt-3 justify-content-between align-items-start border-bottom'
                    >
                      <h2 className='h1 col-12 col-md-auto m-0 font-weight-light'>
                        <Price currency={po.currency} value={po.price} />
                      </h2>
                      <article className='mb-3 mr-auto col text-left d-flex flex-column px-4'>
                        <img
                          src={'' + contentURL + marketingConfiguration?.summary?.providerLogo}
                          alt={marketingConfiguration?.summary?.providerName}
                          className='align-self-start'
                        />
                        <strong className='my-2'>{marketingConfiguration?.title}</strong>
                        <span className='mb-2 small'>{translator.t('buyplans.my-q.5-years-price-after-trial')}</span>
                        {Array.isArray(pricingDisclaimerList) &&
                          pricingDisclaimerList.map(pd => (
                            <span className='small text-muted'>{pd.disclaimerText}</span>
                          ))}
                        {this.state.trialInitializeSuccessful && <MyQLinkStatus translator={translator} />}
                      </article>

                      <button
                        type='button'
                        className={`cwp cwpPrimary mb-3 mb-md-auto ${
                          this.state.trialInitializeSuccessful ? 'inCart' : ''
                        }`}
                        onClick={() => this.startTrial(plan.subCategory)}
                        disabled={this.context.loading || this.state.trialInitializeSuccessful}
                      >
                        {this.state.trialInitializeSuccessful
                          ? translator.t('chamberlain.trialStarted')
                          : translator.t('chamberlain.startMyTrial')}
                      </button>
                    </article>
                  ))}
                </React.Fragment>
              );
            })}

          {this.state.showModalTrialLink && (
            <ThemedToast
              title={translator.t('chamberlain.modallinkMyQTitle')}
              msg={translator.t('chamberlain.modallinkMyQBody01')}
              msg2={translator.t('chamberlain.modallinkMyQBody02')}
              msgLinkText={translator.t('chamberlain.modallinkMyQLink')}
              msgLinkOnClick={this.myQLinkClickAction}
              display={this.state.showModalTrialLink}
              displaySecondButton={true}
              cancelButtonLabel={translator.t('chamberlain.cancel')}
              confirmButtonLabel={translator.t('chamberlain.continue')}
              onCancelClick={() => {
                this.setState({ showModalTrialLink: false });
              }}
              onConfirmClick={this.initializeChamberlainAccountLinking}
            />
          )}
        </div>

        {plan && (
          <MarketingProducts
            contentURL={contentURL}
            plan={plan}
            marketingConfiguration={marketingConfiguration}
            showDisclaimer={this.context.showDisclaimer}
          />
        )}
      </div>
    );
  }
}
