import { combineReducers } from 'redux';

import userInfo from './user/userInfoReducer';
import userPrivilegesDetails from './common/carnetPrivilegesReducer';
import carNickNameInfo from './common/carNickNameReducer';
import preferredDealerDetails from './common/preferredDealerReducer';
import vehicleHealthReportDetails from './vehicleHealth/vehicleHealthByVehicleIdReducer';
import refreshVehilcleHealth from './vehicleHealth/refreshVehicleHealthReducer';
import vehicleHistoryInfo from './vehicleHistory/vehicleHistoryInfoReducer';
import deleteVehicleHistoryEvent from './vehicleHistory/deleteVehicleHistoryEventReducer';
import storePlansCartInfo from './buyPlans/storePlansCartInfoReducer';
import customerAccountDetails from './accountManagement/customerAccountDetailsReducer';
import carnetCustomerStaticData from './common/carnetCustomerStaticDataReducer';
import userSecurityQuestions from './accountManagement/userSecurityQuestionsReducer';
import saveUserSecurityQuestions from './accountManagement/saveUserSecurityQuestionsReducer';
import dealerUrlsDetails from './common/dealerUrlsReducer';
import vehicleHealthByServiceKeyDetails from './vehicleHealth/vehicleHealthByServiceKeyReducer';
import sendVHReportByServiceKey from './vehicleHealth/sendVHReportByServiceKeyReducer';
import vhrCustomerPreferences from './vehicleHealth/vhrCustomerPreferencesReducer';
import sirisxmSubscriptionStatus from './vehicleHealth/sirisxmSubscriptionStatusReducer';
import updateCustomerDetails from './vehicleHealth/updateCustomerDetailsReducer';
import updateVHRCustomerPreferences from './vehicleHealth/updateVHRCustomerPreferencesReducer';
import userVehicleContext from './account/userVehicleContextReducer';
import storePlanPricingInfo from './buyPlans/storePlanPricingInfoReducer';
import storeCartItems from './buyPlans/storeCartItemsReducer';
import storeWalletDetailsInfo from './buyPlans/storeWalletDetailsReducer';

const rootReducer = combineReducers({
  userInfo,
  userSecurityQuestions,
  saveUserSecurityQuestions,
  userPrivilegesDetails,
  carNickNameInfo,
  preferredDealerDetails,
  vehicleHealthReportDetails,
  refreshVehilcleHealth,
  vehicleHistoryInfo,
  deleteVehicleHistoryEvent,
  storePlansCartInfo,
  customerAccountDetails,
  dealerUrlsDetails,
  vehicleHealthByServiceKeyDetails,
  sendVHReportByServiceKey,
  vhrCustomerPreferences,
  sirisxmSubscriptionStatus,
  updateCustomerDetails,
  updateVHRCustomerPreferences,
  userVehicleContext,
  carnetCustomerStaticData,
  storePlanPricingInfo,
  storeCartItems,
  storeWalletDetailsInfo
});

export default rootReducer;
