import React from 'react';
import { bindActionCreators } from 'redux';
import * as actions from '../../../redux/actions';
import { connect } from 'react-redux';
import Axios from 'axios';
import resource from '../../../config/resource.json';
import GoogleMapReact from 'google-map-react';
import GMapPin from './GMapPin';
import { getLocaleSync } from '../../../providers/languageProvider';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import {
  isNullOrEmptyObject,
  isNonEmptyObject,
  isValidUSZipFormat,
  isValidCanadianPostalCode,
  isBlankString
} from '../../../util/utilityMethods';
import { Container, Row, Col, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DealerSearchMatches from './DealerSearchMatches';
import DealerDetailModal from './DealerDetailModal';
import { searchDealersByZipAndRange, saveNewPrefDealer } from '../providers/AccountAndSecurityProvider';
import { getCenter } from 'geolib';
import '../../reusable_cmp_lib/ThemedFormInputs/ThemedForm.scss';
import '../../reusable_cmp_lib/ThemedButtonsAssortment/ThemedButtonsAssortment.scss';
import './AccountManagement.scss';
import '../trips/tripStats.scss';
import { setTitle } from '../../../providers/documentTitleProvider';

class FindADealerNew extends React.Component {
  constructor (props) {
    super(props);

    //These SHOULD remain hardcoded, these wont be coming from any API
    this.dealerDistanceOptions = [
      { code: '5', value: '5' },
      { code: '10', value: '10' },
      { code: '20', value: '20' },
      { code: '30', value: '30' },
      { code: '50', value: '50' }
    ];

    let usersZipOrPostal = this.props.userZip || null;
    //if a U.S. extended 10-digit zip, extract the first 5 digits only:
    if (usersZipOrPostal && usersZipOrPostal.includes('-') && usersZipOrPostal.length === 10) {
      let usZipPieces = usersZipOrPostal.split('-');
      usersZipOrPostal = usZipPieces[0];
    }
    const defaultZipOrPostal = usersZipOrPostal;
    this.DEFAULT_MAP_CENTER = { lat: 42.33143, lng: -83.04575 };

    //These SHOULD be hardcoded: they Wont be coming from any API.
    this.state = {
      serviceOption: 'Sales',
      range: '50',
      zipCode: defaultZipOrPostal,
      searchResults: [],
      displayOutcome: false,
      mapCenter: this.DEFAULT_MAP_CENTER,
      initialSearchComplete: false,
      preferredDealerId: null,
      selectedDealer: null,
      displayDealerDetails: false,
      focusedDealer: null,
      userCountry: null,
      warnInvalidPostalCodeFormat: false
    };
  }

  handleZipEntry = evt => {
    let presentEntry = evt.target.value;
    if (!presentEntry) {
      return;
    }
    this.setState({
      zipCode: presentEntry
    });
  };

  openDealerDetailModal = dealer => {
    this.setState({
      displayDealerDetails: true,
      focusedDealer: dealer
    });
  };

  newDealerSelected = async clickedDealer => {
    let selDealerId = clickedDealer.id;
    try {
      saveNewPrefDealer(selDealerId);
      this.setState({
        selectedDealer: clickedDealer,
        preferredDealerId: selDealerId
      });
    } catch (err) {
      console.log('Error: could not save the newly selected PreferredDealer: ' + err);
      return;
    }
  };

  toggleOutcomeModal = (visible, successful, msg) => {
    this.setState({
      displayOutcome: visible,
      outcomeSuccessful: successful,
      outcomeMsg: msg
    });
  };

  toggleWarningMode = ison => {
    this.setState({
      warnInvalidPostalCodeFormat: ison
    });

    try {
      document.getElementById('dealerSearchZipInput').focus();
    } catch (err) {
      console.log('dealerSearchZipInput not found');
    }
  };

  runDealerSearch = () => {
    if (!this.state.zipCode) {
      return;
    }
    let nullcheckedLocale = this.props.locale ? this.props.locale : 'en-us';
    let localeParts = nullcheckedLocale.split('-');
    let userCountryLow = localeParts && localeParts.length == 2 ? localeParts[1] : 'us';
    const userCountry = userCountryLow.toUpperCase();
    //this variable holds a zipcode for U.S., or a PostalCode for Canada Users
    let searchZipOrPostalCode = this.state.zipCode;
    if (userCountry === 'US') {
      if (isValidUSZipFormat(searchZipOrPostalCode) === false) {
        this.setState({
          warnInvalidPostalCodeFormat: true
        });
        return;
      }
    } else {
      if (isValidCanadianPostalCode(searchZipOrPostalCode) === false) {
        this.setState({
          warnInvalidPostalCodeFormat: true
        });
        return;
      }
      //it IS CA and it IS a valid CA PostalCode, so remove the formatting space:
      searchZipOrPostalCode = searchZipOrPostalCode.replaceAll(/\s/g, '');
    }

    let searchRange = this.state.range;
    this.searchDealers(searchZipOrPostalCode, searchRange);
  };

  recalcMapGeo = () => {
    const newCenter = this.findDefaultMapCenter();
    this.setState({
      mapCenter: newCenter
    });
  };

  searchDealers = async (zipOrPostal, rangeDistance = 50) => {
    const searchCriteria = {
      zipCode: zipOrPostal,
      range: rangeDistance
    };
    let response;
    try {
      response = await searchDealersByZipAndRange(searchCriteria);
      const results = (response && response.data && response.data.dealer) || [];
      this.setState(
        {
          searchResults: results
        },
        this.recalcMapGeo()
      );
    } catch (err) {
      response = { error: err };
      console.log('Error searching Dealers: ' + JSON.stringify(response));
    }
  };

  rangeSelected = evt => {
    let selection = evt.currentTarget.value;

    this.setState({
      range: selection
    });
  };

  findDefaultMapCenter = () => {
    if (this.state.searchResults === null || this.state.searchResults.length === 0) {
      return { lat: 42.354935, lng: -83.151186 };
    }
    const dealersList = JSON.parse(JSON.stringify(this.state.searchResults));

    //we need it in this format for the library methods below
    let dealerLocations = dealersList.map(dealer => {
      return { latitude: dealer.latitude, longitude: dealer.longitude };
    });

    const numDealers = dealerLocations.length;
    if (numDealers === 1) {
      return this.convertToGmapCoord(dealerLocations[0]);
    }
    const calcdCenterPoint = getCenter(dealerLocations);
    return this.convertToGmapCoord(calcdCenterPoint);
  };

  convertToGmapCoord = geolibInCoord => {
    if (isNullOrEmptyObject(geolibInCoord)) {
      return null;
    }
    let latLong = { lat: geolibInCoord.latitude, lng: geolibInCoord.longitude };
    console.log('convertToGmapCoord > ', latLong);
    return latLong;
  };

  // extract the preferred dealer data from the props
  getPreferredDealerDetails = () => {
    let arr = (Array.isArray(this.props.preferredDealerData) && this.props.preferredDealerData) || [];
    let dealer = arr.filter(dealer => dealer.preferredDealerType === 'SERVICE')[0] || {
      dealerDetails: {},
      emptyDefault: true
    };
    // couldn't find the 'SERVICE' dealer, just use whatever is present
    if (dealer.emptyDefault && arr.length) {
      dealer = arr[0];
    }
    let details = dealer.dealerDetails || {};
    if (Object.keys(details).length) {
      return dealer;
    }
    return {};
  };

  updateZipInputField = zipCode => {
    try {
      let el = document.getElementById('dealerSearchZipInput');
      if (el) {
        el.setAttribute('value', zipCode);
      }
    } catch (err) {
      console.log('Failed to update Zip Input Field');
      console.log(err);
    }
  };

  // waiting on the loading of the preferredDealerData
  componentDidUpdate (prevProps) {
    let prev = JSON.stringify(prevProps.preferredDealerData);
    let cur = JSON.stringify(this.props.preferredDealerData);
    // preferred dealer data updated
    if (prev !== cur && !this.state.initialSearchComplete) {
      console.log('FindADealerNew >> componentDidUpdate() >> update detected >>');
      let details = this.getPreferredDealerDetails();
      let keysPresent = Object.keys(details);
      if (keysPresent.length) {
        // there is data present
        let zipCode = details.dealerDetails && details.dealerDetails.address && details.dealerDetails.address.zipCode;
        let preferredDealerId = details.dealerId || null;
        let stateChange = {
          preferredDealerId,
          initialSearchComplete: true
        };
        if (!isBlankString(zipCode)) {
          stateChange['zipCode'] = zipCode;
        }
        this.updateZipInputField(zipCode);
        this.setState(stateChange, () => {
          this.runDealerSearch();
        });
      }
    }
  }

  hideDealerDetail = () => {
    this.setState({
      displayDealerDetails: false,
      focusedDealer: null
    });
  };

  componentDidMount () {
    this.searchDealers(this.props.userZip, 50)
      .then(() => {
        console.log('Did initial DealerSearch.');
      })
      .catch(e => {
        console.error('Error thrown doing the initial DealerSearch: ' + e);
      });

    // get the preferred dealer on component mount
    this.props.actions.getPreferredDealer();
  }

  inModalDealerSelected = dealer => {
    if (isNullOrEmptyObject(dealer)) {
      return;
    }
    this.newDealerSelected(dealer);
  };

  render () {
    this.translator = getLocaleTranslator();
    const maintLabel = this.translator.t('acctmgmt_label_maintenance');
    const repairLabel = this.translator.t('acctmgmt_label_repair');
    const salesLabel = this.translator.t('acctmgmt_label_sales');
    let mapZoomLvl = 9; //the default zoom
    let invalidCodeFmtMsgTitle = this.translator.t('acctmgmt_invalid_postalcd_fmt_title');
    let invalidCodeFmtMsg = this.translator.t('acctmgmt_invalid_postalcd_fmt');
    //These NEED to be hardcoded. There are only 3 of them, not served by any API. Dropdown values.
    this.localDataServices = [
      { code: 'maint', value: maintLabel },
      { code: 'repair', value: repairLabel },
      { code: 'sales', value: salesLabel }
    ];

    setTitle('document_titles.account_management.find_dealer');

    return (
      <Container fluid={true}>
        <Row className='row-soft-underline'>
          <Col md='1'>
            <br />
            {this.state.warnInvalidPostalCodeFormat === true && (
              <Modal isOpen={this.state.warnInvalidPostalCodeFormat} className='carnet-sg-modal'>
                <h2> {invalidCodeFmtMsgTitle} </h2>
                <ModalBody>{invalidCodeFmtMsg}</ModalBody>
                <ModalFooter>
                  <Button
                    className='modal-primary-btn'
                    color='modal_background_color'
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.toggleWarningMode(false);
                    }}
                  >
                    {this.translator.t('acctmgmt_label_ok')}
                  </Button>
                </ModalFooter>
              </Modal>
            )}
            {this.state.displayOutcome && (
              <Modal isOpen={this.state.displayOutcome} className='carnet-sg-modal'>
                <ModalHeader>
                  {this.state.outcomeSuccessful
                    ? this.translator.t('acctmgmt_saved')
                    : this.translator.t('acctmgmt_savefailed')}
                </ModalHeader>
                <ModalBody>{this.state.outcomeMsg}</ModalBody>
                <ModalFooter>
                  <Button
                    className='modal-primary-btn'
                    color='modal_background_color'
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.toggleOutcomeModal(false, null, null);
                    }}
                  >
                    {this.translator.t('acctmgmt_label_ok')}
                  </Button>
                </ModalFooter>
              </Modal>
            )}
          </Col>
          <Col md='2'>
            <div className='editor-diatom-field'>
              {/* for en-CA locale, this label translates to : "Postal Code", for U.S., as "Zip Code" */}
              <fieldset>
                <legend>
                  <label for='dealerSearchZipInput' className='editor-diatom-superscript-lbl'>
                    {this.translator.t('acctmgmt_pi_field_label_zip')}
                  </label>
                </legend>

                <Input
                  id='dealerSearchZipInput'
                  className='cwp'
                  type='text'
                  name='zip_code'
                  onChange={evt => this.handleZipEntry(evt)}
                  placeholder={this.state.zipCode || ''}
                />
              </fieldset>
              <br />
            </div>
          </Col>
          <Col md='3'>
            <div className='editor-diatom-field'>
              <br />
            </div>
          </Col>
          <Col md='2'>
            <div className='editor-diatom-field'>
              <fieldset>
                <legend>
                  {' '}
                  <label htmlFor='distance' className='editor-diatom-superscript-lbl'>
                    {this.translator.t('acctmgmt_distance_label')}
                  </label>
                </legend>
                <select id='distance' value={this.state.range || '5'} onChange={this.rangeSelected} className='cwp'>
                  {this.dealerDistanceOptions.map((dealerDistanceThing, index) => {
                    return (
                      <option key={'dealer_distance_' + index} value={dealerDistanceThing.value}>
                        {dealerDistanceThing.value}
                      </option>
                    );
                  })}
                </select>
              </fieldset>
            </div>
          </Col>

          <Col md='1'></Col>
          <Col md='1'>
            <Button className='cwp' color='primary' onClick={this.runDealerSearch}>
              {this.translator.t('acctmgmt_search_label')}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md='12'>
            {isNonEmptyObject(this.state.focusedDealer) === true && this.state.displayDealerDetails === true && (
              <DealerDetailModal
                closeHandler={this.hideDealerDetail}
                contextDealer={this.state.focusedDealer}
                sendbackPreferredHandler={this.inModalDealerSelected}
                currentlySelected={this.state.preferredDealerId === this.state.focusedDealer.id}
                open={true}
              />
            )}
          </Col>
        </Row>
        <Row className='borderless'>
          <Col md='8'>
            <div className='find-dealer-map-aligner'>
              <div className='rts-map-div'>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: resource.google_map.key,
                    libraries: ['places', 'geometry']
                  }}
                  defaultCenter={this.DEFAULT_MAP_CENTER}
                  onChildMouseEnter={this.onChildMouseEnter}
                  onChildMouseLeave={this.onChildMouseLeave}
                  center={this.state.mapCenter}
                  defaultZoom={mapZoomLvl}
                  yesIWantToUseGoogleMapApiInternals={true}
                  options={{
                    disableDefaultUI: true
                  }}
                >
                  {(this.state.searchResults || []).map((dealer, index) => {
                    return (
                      <GMapPin
                        key={`dealerMap_pin_${Date.now()}_${index}`}
                        lat={dealer.latitude}
                        lng={dealer.longitude}
                        text={index + 1}
                        tooltip={dealer.name}
                      />
                    );
                  })}
                </GoogleMapReact>
              </div>
            </div>
          </Col>
          <Col md='4'>
            <DealerSearchMatches
              preferredDealerId={this.state.preferredDealerId}
              preferredDealerData={this.props.preferredDealerData}
              matches={this.state.searchResults}
              selectHandler={this.newDealerSelected}
              detailsCallback={this.openDealerDetailModal}
              setFocusOnBackAfterModalDismissed={this.props.setFocusOnBackAfterModalDismissed}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getPreferredDealer: actions.getPreferredDealer
      },
      dispatch
    )
  };
};

const mapStateToProps = state => {
  const { preferredDealerDetails = {} } = state;
  return {
    preferredDealerData: preferredDealerDetails.preferredDealerData
  };
};

export { FindADealerNew };
export default connect(mapStateToProps, mapDispatchToProps)(FindADealerNew);
