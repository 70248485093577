import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../redux/actions';
import { isNonEmptyObject } from '../../../util/utilityMethods';
import ThemedToast from '../../reusable_cmp_lib/ThemedToast/ThemedToast';
import { getVehicleContext } from '../../../providers/historyProvider';
import {
  getDeeplinkingUrlPopulated,
  getChamberlainCapabilityAndStatus,
  linkChamberlainAccount,
  unLinkChamberlainAccount
} from '../providers/AccountAndSecurityProvider';
import carnet_constants from '../../../constants/carnet_constants';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { redirectBrowserTo } from '../../../util/utilityMethods';
import { Row, Col, Button } from 'reactstrap';
import '../../reusable_cmp_lib/ThemedButtonsAssortment/ThemedButtonsAssortment.scss';
import './AccountManagement.scss';
import { isUBIAvailable, areTripsAvailable } from '../trips/TripStatsUtil';
import axios from 'axios';
import { setTitle } from '../../../providers/documentTitleProvider';
import { isUserPrimary } from '../../../providers/userRolesProvider';

class ThirdPartyServices extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      editingField: null,
      viewMode: carnet_constants.THIRD_PARTY_MAIN,
      showSiriusXMDetails: false,
      contextVin: null,
      vehicleId: null,
      userId: null,
      myQStatus: null,
      linkingSuccessFeedback: null,
      linkingErrorFeedback: null,
      usedLinkingConfirmations: [],
      linkingStatusUpdatePending: false,
      UBIAvailable: false
    };

    this.getActiveEditingPane = this.getActiveEditingPane.bind(this);
    this.translator = getLocaleTranslator();
  }

  componentDidMount () {
    if (this.props.focus) {
      try {
        document.getElementById('firstActiveFocus').focus();
      } catch (err) {
        console.log('element not found for autofocus');
      }
    }
    this.props.actions.getSirisxmSubscriptionStatus();

    setTitle('document_titles.account_management.third_party_services');

    let { customer = {}, vehicle = {} } = this.props.accountDetails;
    let { userId } = customer;
    let { vehicleId, vin } = vehicle;

    this.setState(
      {
        contextVin: vin,
        userId,
        vehicleId
      },
      () => {
        /*
        capablityStatus: 'AVAILABLE', // (AVAILABLE/NOT_AVAILABLE/NOT_APPLICABLE)
        subscriptionStatus: 'AVAILABLE', //(AVAILABLE/NOT_AVAILABLE/NOT_APPLICABLE)
        accessibilityStatus: 'ACCESSIBLE', //(ACCESSIBLE/ NOT_ACCESSIBLE /NOT_APPLICABLE)
        linkingStatus: 'Inactive' //(Active/ Inactive)
        */
        getChamberlainCapabilityAndStatus(vehicleId).then(responseData => {
          let {
            capablityStatus = '',
            subscriptionStatus = '',
            accessibilityStatus = '',
            linkingStatus = ''
          } = responseData;
          let myQStatus = {
            capablityStatus: capablityStatus.toUpperCase(),
            subscriptionStatus: subscriptionStatus.toUpperCase(),
            accessibilityStatus: accessibilityStatus.toUpperCase() === 'ACCESSIBLE',
            linkingStatus: linkingStatus.toUpperCase()
          };
          this.setState({ myQStatus });
        });
      }
    );

    this.getVehicleTripsFeatureGroup();
  }

  componentDidUpdate (prevProps) {
    let prev = prevProps.accountDetails || {};
    let cur = this.props.accountDetails || {};
    let diff = JSON.stringify(prev) !== JSON.stringify(cur);

    let { customer = {}, vehicle = {} } = this.props.accountDetails;
    let { userId } = customer;
    let { vehicleId, vin } = vehicle;

    // lets see if this is a success callback from linking myQ
    let queryParamStr = String(window.location.href).split('?')[1] || '';
    let paramsArr = (String(queryParamStr) && String(queryParamStr).split('&')) || [];
    let successParamIsPresent = paramsArr.indexOf('status=SUCCESS') > -1;
    let errorParamIsPresent = paramsArr.indexOf('status=ERROR') > -1;
    let vehicleIdParamIsPresent = paramsArr.indexOf(`vehicleId=${vehicleId}`) > -1;
    let userIdParamIsPresent = paramsArr.indexOf(`userId=${userId}`) > -1;
    let chamberlainAccountCodeIsPresent = paramsArr.indexOf('accountCode=chamberlain') > -1;
    let isLinkingSuccess =
      successParamIsPresent && vehicleIdParamIsPresent && userIdParamIsPresent && chamberlainAccountCodeIsPresent;

    let isLinkingError =
      !isLinkingSuccess &&
      errorParamIsPresent &&
      vehicleIdParamIsPresent &&
      userIdParamIsPresent &&
      chamberlainAccountCodeIsPresent;

    let accountCodeKey = String(paramsArr[chamberlainAccountCodeIsPresent]).split('=')[1] || '';
    let statusKey = successParamIsPresent ? 'SUCCESS' : 'ERROR';

    let keyForStateStorage = `${vehicleId}__${userId}__${accountCodeKey}__linking__${statusKey}`;

    const someThingToDoOnDiff = () => {
      if (diff) {
        this.setState(
          {
            contextVin: vin,
            userId,
            vehicleId
          },
          () => {
            getChamberlainCapabilityAndStatus(vehicleId).then(responseData => {
              let {
                capablityStatus = '',
                subscriptionStatus = '',
                accessibilityStatus = '',
                linkingStatus = ''
              } = responseData;
              let myQStatus = {
                capablityStatus: capablityStatus.toUpperCase(),
                subscriptionStatus: subscriptionStatus.toUpperCase(),
                accessibilityStatus: accessibilityStatus.toUpperCase() === 'ACCESSIBLE',
                linkingStatus: linkingStatus.toUpperCase()
              };
              this.setState({ myQStatus });
            });
          }
        );
      }
    };

    // only allow this to happen once
    if (!this.state.usedLinkingConfirmations.includes(keyForStateStorage)) {
      if (isLinkingSuccess && this.state.linkingSuccessFeedback !== keyForStateStorage) {
        this.setState({ linkingSuccessFeedback: keyForStateStorage }, someThingToDoOnDiff);
      } else if (isLinkingError && this.state.linkingErrorFeedback !== keyForStateStorage) {
        this.setState({ linkingErrorFeedback: keyForStateStorage }, someThingToDoOnDiff);
      }
    } else {
      someThingToDoOnDiff();
    }
  }

  handleExitEditMode = () => {
    this.setState({ editingField: null });
  };

  viewSXMDetails = () => {
    window.location.assign('#/sxmdetails');
  };

  viewDriveViewDetails = () => {
    ///link to a different page: Per Nithya: for now, a Placeholder Page for Vidyas Under Construction page....
    if (!this.state.contextVin) {
      return;
    }
    window.location.assign(`#/driveview-enrollment/${this.state.contextVin}`);
  };

  manageDriveView = () => {
    window.location.assign('#/driveview-unenroll-items');
  };

  editField = reference => {
    console.log('editField() invoked for ref: ' + reference);
    this.props.editingCallback(reference);
  };

  getActiveEditingPane () {
    if (this.state.editingField === null) {
      return <span></span>;
    }
  }

  redirectOutDeepLink = () => {
    getDeeplinkingUrlPopulated()
      .then(url => {
        redirectBrowserTo(url);
      })
      .catch(err => {
        console.error('Error thrown in getting the redirectURL: ' + err);
      });
  };

  /*
  capablityStatus: 'AVAILABLE', // (AVAILABLE/NOT_AVAILABLE/NOT_APPLICABLE)
  subscriptionStatus: 'AVAILABLE', //(AVAILABLE/NOT_AVAILABLE/NOT_APPLICABLE)
  accessibilityStatus: 'ACCESSIBLE', //(ACCESSIBLE/ NOT_ACCESSIBLE /NOT_APPLICABLE)
  linkingStatus: 'Inactive' //(Active/ Inactive)
  */

  getAccountStatusMessage = () => {
    if (this.state.myQStatus.capablityStatus !== 'AVAILABLE') {
      return <span className='carnet-theme-field-value'>{''}</span>;
    }

    if (this.state.myQStatus.subscriptionStatus !== 'AVAILABLE' && this.state.myQStatus.accessibilityStatus) {
      return (
        <span className='carnet-theme-field-value'>{this.translator.t('chamberlain.linkingNotAvailableAction')}</span>
      );
    }

    if (this.state.myQStatus.linkingStatus !== 'ACTIVE' && this.state.myQStatus.accessibilityStatus) {
      return (
        <span className='carnet-theme-field-value'>{this.translator.t('chamberlain.linkingAvailableAction')}</span>
      );
    } else if (this.state.myQStatus.linkingStatus !== 'ACTIVE' && !this.state.myQStatus.accessibilityStatus) {
      return (
        <span className='carnet-theme-field-value'>
          {this.translator.t('chamberlain.secondaryUserAccountNotLinked')}
        </span>
      );
    } else if (this.state.myQStatus.linkingStatus === 'ACTIVE' && !this.state.myQStatus.accessibilityStatus) {
      return <span className='carnet-theme-field-value'>{this.translator.t('chamberlain.accountLinked')}</span>;
    }

    return (
      <span className='carnet-theme-field-value'>
        {this.translator.t('chamberlain.inlineDescription')}
        <br />
        <br />
        {this.translator.t('chamberlain.inlineDescriptionPart2')}
      </span>
    );
  };

  // CW37 vehicle Fix
  // Invoke the route to get the trips feature group to determine whether UBI or Trips available
  getVehicleTripsFeatureGroup = () => {
    axios.get(`/vehicle/trips/featuregroup`).then(res => {
      let vehicleTripsFeatures = (res.data && res.data.data) || null;
      let UBIAvailable = vehicleTripsFeatures && isUBIAvailable(vehicleTripsFeatures.features);
      this.setState({ vehicleTripsFeatures, UBIAvailable });
    });
  };

  //End of  CW37 Vehicle fix

  linkChamberlainAccount = () => {
    if (this.state.linkingStatusUpdatePending) {
      return;
    }
    this.setState({ linkingStatusUpdatePending: true }, async () => {
      try {
        let url = await linkChamberlainAccount();
        redirectBrowserTo(url);
      } catch (err) {
        console.error('unable to link chamberlain account');
        this.setState({ linkingStatusUpdatePending: false });
      }
    });
  };

  handUnlinkChamberlainAccount = async () => {
    if (this.state.linkingStatusUpdatePending) {
      return;
    }
    this.setState({ linkingStatusUpdatePending: true }, async () => {
      try {
        let result = await unLinkChamberlainAccount();
        getChamberlainCapabilityAndStatus(this.state.vehicleId).then(responseData => {
          let {
            capablityStatus = '',
            subscriptionStatus = '',
            accessibilityStatus = '',
            linkingStatus = ''
          } = responseData;
          let myQStatus = {
            capablityStatus: capablityStatus.toUpperCase(),
            subscriptionStatus: subscriptionStatus.toUpperCase(),
            accessibilityStatus: accessibilityStatus.toUpperCase() === 'ACCESSIBLE',
            linkingStatus: linkingStatus.toUpperCase()
          };
          this.setState({ myQStatus, linkingStatusUpdatePending: false });
        });
      } catch (err) {
        console.log('unable to unlink chamberlain account or get updated status');
        this.setState({ linkingStatusUpdatePending: false });
      }
    });
  };

  render () {
    const recoverWipedOutBtnBorder = 'cwp recover-the-btn-border';
    let siriusDataAvailability = isNonEmptyObject(this.props.siriusXMEnrollment) ? 'Found' : 'Not Found';
    const keyFlagSXMEnrolled =
      siriusDataAvailability === 'Found' && this.props.siriusXMEnrollment.radioEquipped === true;
    const sxmRadioDetail =
      keyFlagSXMEnrolled === true && this.props.siriusXMEnrollment && this.props.siriusXMEnrollment.radioIdDetails
        ? this.props.siriusXMEnrollment.radioIdDetails.radioIdLabel +
          ' :  ' +
          this.props.siriusXMEnrollment.radioIdDetails.radioIdValue
        : '  ';

    let driveViewEnrolled = false;
    if (isNonEmptyObject(this.props.accountDetails)) {
      driveViewEnrolled = this.props.accountDetails.tripWiseEnrolled;
    }

    let driveViewStatusIndicator =
      driveViewEnrolled === true
        ? this.translator.t('acctmgmt_vi_enroll_status_indicator')
        : this.translator.t('acctmgmt_vi_notenrolled_status_indicator');

    const canadianUser = this.props.isCanadianUser === true;
    const userIsPrimary = isUserPrimary();

    return (
      <div className='acctmgmt-contentpane'>
        <div id='ghost-wrapper'>
          {this.state.linkingSuccessFeedback && (
            <ThemedToast
              title={this.translator.t('chamberlain.myQLinkSuccess.title')}
              msg={this.translator.t('chamberlain.myQLinkSuccess.body')}
              display={this.state.linkingSuccessFeedback}
              onConfirmClick={() => {
                let usedLinkingConfirmations = [
                  ...this.state.usedLinkingConfirmations,
                  this.state.linkingSuccessFeedback
                ];
                this.setState({ usedLinkingConfirmations, linkingSuccessFeedback: null });
              }}
            />
          )}
          {this.state.linkingErrorFeedback && (
            <ThemedToast
              title={this.translator.t('chamberlain.myQLinkError.title')}
              msg={this.translator.t('chamberlain.myQLinkError.body')}
              display={this.state.linkingErrorFeedback}
              onConfirmClick={() => {
                let usedLinkingConfirmations = [
                  ...this.state.usedLinkingConfirmations,
                  this.state.linkingErrorFeedback
                ];
                this.setState({ usedLinkingConfirmations, linkingErrorFeedback: null });
              }}
            />
          )}
          <div className='contentpane-title'>
            <h2 id='firstActiveFocus' tabIndex={-1} className='h6'>
              {this.translator.t('acctmgmt_thirdparty_heading')}
            </h2>
          </div>
          <div>
            {/* NEW EXPERIMENTAL APPROACH   */}
            {/* DEDICATED SIRIUSXM */}
            {this.props.tsp && this.props.tsp !== carnet_constants.TSP_VZT && keyFlagSXMEnrolled === true && (
              <Row>
                <Col>
                  <span className='carnet-theme-field-label'>{this.translator.t('acctmgmt_siriusxm-label')}</span>
                </Col>
                <Col md='6'>
                  <span className='carnet-theme-field-value'>{sxmRadioDetail}</span>
                </Col>
                <Col md='2'>
                  {keyFlagSXMEnrolled && (
                    <span className='theme-updatelink'>
                      <a onClick={this.viewSXMDetails}>{this.translator.t('acctmgmt_view_dtls')}</a>
                    </span>
                  )}
                </Col>
              </Row>
            )}
            {/* DEDICATED DRIVEVIEW */}

            {this.props.tsp === carnet_constants.TSP_ATC && !canadianUser && this.state.UBIAvailable && (
              <Row>
                <Col>
                  <span className='carnet-theme-field-label'>{this.translator.t('acctmgmt_driveview_label')}</span>
                </Col>
                <Col md='6'>
                  <span className='carnet-theme-field-value'>{driveViewStatusIndicator}</span>
                </Col>
                <Col md='2'>
                  {driveViewEnrolled && userIsPrimary && (
                    <span className='theme-updatelink'>
                      <a onClick={this.manageDriveView}>{this.translator.t('acctmgmt_thirdparty_manage_driveView')}</a>
                    </span>
                  )}
                  {!driveViewEnrolled && userIsPrimary && (
                    <span className='theme-updatelink'>
                      <a onClick={this.viewDriveViewDetails}>{this.translator.t('acctmgmt_thirdparty_enroll')}</a>
                    </span>
                  )}
                </Col>
              </Row>
            )}

            {/*
                capablityStatus: 'AVAILABLE', // (AVAILABLE/NOT_AVAILABLE/NOT_APPLICABLE)
                subscriptionStatus: 'AVAILABLE', //(AVAILABLE/NOT_AVAILABLE/NOT_APPLICABLE)
                accessibilityStatus: 'ACCESSIBLE', //(ACCESSIBLE/ NOT_ACCESSIBLE /NOT_APPLICABLE)
                linkingStatus: 'Inactive' //(Active/ Inactive)
              */}

            {this.state.myQStatus && this.state.myQStatus.capablityStatus === 'AVAILABLE' && (
              <Row>
                <Col>
                  <span className='carnet-theme-field-label'>{this.translator.t('chamberlain.title')}</span>
                </Col>
                <Col md='6'>{this.getAccountStatusMessage()}</Col>
                <Col md='2'>
                  {this.state.myQStatus.subscriptionStatus !== 'AVAILABLE' && this.state.myQStatus.accessibilityStatus && (
                    <Link className='theme-updatelink' to='/buy-plans'>
                      {this.translator.t('chamberlain.activateAction')}
                    </Link>
                  )}

                  {this.state.myQStatus.subscriptionStatus === 'AVAILABLE' &&
                    this.state.myQStatus.linkingStatus !== 'ACTIVE' &&
                    this.state.myQStatus.accessibilityStatus && (
                      <span
                        className={
                          this.state.linkingStatusUpdatePending ? 'theme-updatelink disabled' : 'theme-updatelink '
                        }
                        role='button'
                        onClick={this.linkChamberlainAccount}
                      >
                        {this.translator.t('chamberlain.linkAction')}
                      </span>
                    )}
                  {this.state.myQStatus.subscriptionStatus === 'AVAILABLE' &&
                    this.state.myQStatus.linkingStatus === 'ACTIVE' &&
                    this.state.myQStatus.accessibilityStatus && (
                      <span
                        className={
                          this.state.linkingStatusUpdatePending ? 'theme-updatelink disabled' : 'theme-updatelink '
                        }
                        role='button'
                        onClick={this.handUnlinkChamberlainAccount}
                      >
                        {this.translator.t('chamberlain.unlinkAction')}
                      </span>
                    )}
                </Col>
              </Row>
            )}
            <Row>
              <Col></Col>
            </Row>
            {this.props.tsp && this.props.tsp !== carnet_constants.TSP_VZT && (
              <Row>
                <Col md='1'></Col>
                <Col md='4'>
                  <Button className={recoverWipedOutBtnBorder} color='secondary' onClick={this.redirectOutDeepLink}>
                    {this.translator.t('acctmgmt_mg_3p_svcs')}
                  </Button>{' '}
                </Col>
                <Col md='7'></Col>
              </Row>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getSirisxmSubscriptionStatus: actions.getSirisxmSubscriptionStatus
      },
      dispatch
    )
  };
};

const mapStateToProps = state => {
  // (below) "sirisxm"[sic] is the PREEXISTING misspelled varname They gave it in Their redux code.
  //I am Just using it here.
  //it must remain spelled this way, as they spelled it, to work.
  const { sirisxmSubscriptionStatus } = state;
  let coreDetailsData = isNonEmptyObject(sirisxmSubscriptionStatus) && sirisxmSubscriptionStatus.siriusxmStatusData;
  coreDetailsData = coreDetailsData || {};

  //driveview enrolled status is PASSED-IN by parent, read above...
  return {
    siriusXMEnrollment: coreDetailsData
  };
};

export { ThirdPartyServices };
export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyServices);
