import React from 'react';
import PropTypes from 'prop-types';

export default class AutoInsertLinkContent extends React.Component {
  static propTypes = {
    value: PropTypes.shape({
      contentNumber: PropTypes.number.isRequired,
      contentText: PropTypes.string.isRequired,
      urls: PropTypes.arrayOf(
        PropTypes.shape({
          textToHyperlink: PropTypes.string.isRequired,
          URL: PropTypes.string.isRequired
        })
      )
    }).isRequired
  };

  static parseValuesFromPricingDisclaimerList = pricingDisclaimer => {
    return pricingDisclaimer.map(({ disclaimerNumber, disclaimerText, disclaimerContentUrls }) => {
      const value = {
        contentNumber: disclaimerNumber,
        contentText: disclaimerText
      };

      if (Array.isArray(disclaimerContentUrls)) {
        value.urls = disclaimerContentUrls.map(({ disclaimerTextToHyperlink, disclaimerURL }) => ({
          textToHyperlink: disclaimerTextToHyperlink,
          URL: disclaimerURL
        }));
      }

      return value;
    });
  };

  state = {
    htmlContent: ''
  };

  componentDidMount () {
    const { contentText, urls } = this.props?.value || {};

    let htmlContent = contentText;
    if (Array.isArray(urls) && urls.length > 0) {
      for (const url of urls) {
        htmlContent = htmlContent.replace(
          url.textToHyperlink,
          `<a href="${url.URL}" target="_blank" className="cwp" rel="noopener noreferrer">${url.textToHyperlink}</a>`
        );
      }
    }

    this.setState({ htmlContent });
  }

  render () {
    return <article dangerouslySetInnerHTML={{ __html: this.state.htmlContent }}></article>;
  }
}
