import React from 'react';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { isNullOrEmptyObject } from '../../../util/utilityMethods';
import './AccountManagement.scss';
import '../CarnetHome.scss';
import ThemedToast from '../../reusable_cmp_lib/ThemedToast/ThemedToast';
import './ContactDealerModal';
import { getDealerServiceUrl } from '../providers/ThirdPartyProvider';
import ContactDealerModule from './ContactDealerModal';

const formatPhoneNumber = numberStr => {
  return `+ 1(${numberStr.slice(0, 3)}) ${numberStr.slice(3, 6)} - ${numberStr.slice(6)}`;
};

class DealerSearchMatches extends React.Component {
  state = { preferredDealerId: null };

  constructor (props) {
    super(props);
    this.state = {
      preferredDealerId: null,
      modalActive: false,
      serviceUrlNotAvailableForDealer: null
    };
    this.translator = null;
  }

  componentDidMount () {
    let prefDealer = this.props.preferredDealerId || null;

    if (prefDealer === null) {
      let arr = (Array.isArray(this.props.preferredDealerData) && this.props.preferredDealerData) || [];
      let dealer = arr.filter(dealer => dealer.preferredDealerType === 'SERVICE')[0] || {
        dealerDetails: {},
        emptyDefault: true,
        dealerId: 'emptyDefault'
      };
      this.setState({ preferredDealerId: dealer.dealerId });
    } else {
      this.setState({
        preferredDealerId: prefDealer
      });
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.preferredDealerId !== null || this.state.preferredDealerId === 'emptyDefault') {
      if (prevProps.preferredDealerId !== this.props.preferredDealerId) {
        this.setState({
          modalActive: true,
          preferredDealerId: this.props.prePreferredDealerId
        });
      }
    }
  }

  isPreferredServiceDealer = dealerInfo => {
    let preferredDealerId = this.props.preferredDealerId;
    let dealerId = dealerInfo.id || null;
    if (preferredDealerId && dealerId && preferredDealerId === dealerId) {
      return true;
    }
    return false;
  };

  onSelectDealer = dealerInfo => {
    let prefId = dealerInfo.id || null;
    if (this.state.preferredDealerId && this.state.preferredDealerId === prefId) {
      console.log('already selected preferred dealer');
      return;
    }

    if (this.state.preferredDealerId !== prefId) {
      this.props.selectHandler(dealerInfo);
    }
  };

  getDealerUrlLinks = (dealerInfo = {}) => {
    let mainUrlLink = this.findMainUrl(dealerInfo);
    let serviceUrlLink = this.createServiceLink(dealerInfo);
    // getDealerServiceUrl
    return (
      <p className='websiteLink'>
        {mainUrlLink} {serviceUrlLink}
      </p>
    );
  };

  findMainUrl = (dealerInfo = {}) => {
    let url = dealerInfo.url || '';
    if (url) {
      return (
        <a href={url} tabIndex={0} target='_blank' rel='noopener noreferrer'>
          → {this.translator.t('acctmgmt_visit_website')}
        </a>
      );
    }
    return '';
  };

  getServiceUrlFromNits = async dealerInfo => {
    let dealerId = dealerInfo.id;
    try {
      let dealerServiceUrlInfo = await getDealerServiceUrl(dealerId, 'Web_ManageDealer');
      if (dealerServiceUrlInfo && dealerServiceUrlInfo.serviceURL) {
        window.open(dealerServiceUrlInfo.serviceURL);
      }
    } catch (err) {
      this.setState({ serviceUrlNotAvailableForDealer: dealerInfo });
    }
  };

  createServiceLink = (dealerInfo = {}) => {
    return (
      <span
        className='service'
        role='link'
        onClick={() => {
          this.getServiceUrlFromNits(dealerInfo);
        }}
        tabIndex={0}
      >
        {this.translator.t('acctmgmt_schedule_service')}
      </span>
    );
  };

  openDealerDetails = dealer => {
    if (isNullOrEmptyObject(dealer)) {
      return;
    }
    this.props.detailsCallback(dealer);
  };

  render () {
    this.translator = getLocaleTranslator();

    return (
      <div className='acctmgmt-dlr-search-results'>
        <div
          aria-live='polite'
          role='alert'
          aria-label={this.props.matches.length + this.translator.t('acctmgmt_finddealer_results_found')}
        />
        <ul className='acctmgmt-dlr-search-results-ul'>
          {/* Iterate here  */}
          {(this.props.matches || []).map((dealerSearchResult, index) => {
            return (
              <li key={'dealer_search_match_' + index}>
                {/* Result */}
                <div className='dlr-result'>
                  <div className='dlr-result-name'>
                    <div className='dlr-result-name-text'>
                      <span
                        className='dlr-name-text'
                        tabIndex='0'
                        role='button'
                        aria-label={`${index + 1}. ${dealerSearchResult.name}, ${this.translator.t(
                          'acctmgmt_click_for_details'
                        )}`}
                        onKeyPress={e => {
                          if (e.key && (e.key === 'Enter' || e.key === ' ')) {
                            this.openDealerDetails(dealerSearchResult);
                          }
                        }}
                        onClick={() => {
                          this.openDealerDetails(dealerSearchResult);
                        }}
                      >
                        {index + 1 + '. ' + dealerSearchResult.name}
                      </span>
                    </div>
                  </div>
                  <div className='dlr-result-address '>
                    <p className='dlr-result-street '>{dealerSearchResult.address1}</p>
                    {dealerSearchResult.address2 && <p className='dlr-result-street'>{dealerSearchResult.address2}</p>}
                    <p className='dlr-result-citystate '>
                      {dealerSearchResult.city + ', ' + dealerSearchResult.state + '   ' + dealerSearchResult.zip}
                    </p>
                    <p>
                      {this.translator.t('acctmgmt_phone_short_prefix')}{' '}
                      {' ' + formatPhoneNumber(dealerSearchResult.phone)}
                    </p>
                    {this.getDealerUrlLinks(dealerSearchResult)}
                  </div>

                  <div className='dlr-result-selection-option'>
                    <span
                      className={this.isPreferredServiceDealer(dealerSearchResult) ? 'cwpRadio cwpRadioOn' : 'cwpRadio'}
                      onClick={e => {
                        e.preventDefault();
                        this.onSelectDealer(dealerSearchResult);
                      }}
                      onKeyPress={e => {
                        if (e.key && (e.key === 'Enter' || e.key === '')) {
                          e.preventDefault();
                          this.onSelectDealer(dealerSearchResult);
                        }
                      }}
                      tabIndex={0}
                      role='button'
                      aria-label={this.translator.t('acctmgmt_set_preferred_dealer_label')}
                    ></span>
                    <label htmlFor='set_pref_dealer'>
                      <span>{this.translator.t('acctmgmt_set_preferred_dealer_label')}</span>
                    </label>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>

        {this.state.serviceUrlNotAvailableForDealer && (
          <ContactDealerModule
            dealerInfo={this.state.serviceUrlNotAvailableForDealer}
            onDismiss={() => {
              this.setState({ serviceUrlNotAvailableForDealer: null });
            }}
          />
        )}
        {this.state.modalActive && (
          <ThemedToast
            title={this.translator.t('acctmgmt_saved')}
            msg={this.translator.t('acctmgmt_preferred_dealer_set')}
            returnFocusAfterClose={false}
            display={true}
            onClosed={() => {
              this.props.setFocusOnBackAfterModalDismissed();
              this.setState({ modalActive: false });
            }}
          />
        )}
      </div>
    );
  }
}

export default DealerSearchMatches;
