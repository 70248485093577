import Constants from '../../../constants/carnet_constants';
import { isNonEmptyObject } from '../../../util/utilityMethods';

const TRIP_STATS = 'TripStats';
const UBI = 'UBI';
const UBI_PAUSED_MSG = 'UBI_PAUSED_MSG';
const AVAILABLE = 'AVAILABLE';

export const kmToMiles = avgSpeed => {
  return (avgSpeed * Constants.KM_TO_MILES).toFixed(1);
};

export const litersToGallons = avgFuelEconomy => {
  return ((avgFuelEconomy / Constants.AVG_FUEL_DIV_VALUE) * Constants.FUEL_ECONOMY_KM_TO_MILES).toFixed(1);
};

export const fuelEconomylitersToKM = avgFuelEconomy => {
  return avgFuelEconomy === 0 ? 0.0 : (100 / avgFuelEconomy).toFixed(1);
};

export const privilegeCheck = privileges => {
  let privilegeStatus;
  if (isNonEmptyObject(privileges)) {
    if (includesRead(privileges) || includesWrite(privileges) || includesDelete(privileges)) {
      privilegeStatus = true;
    } else if (!includesRead(privileges) || !includesWrite(privileges) || !includesDelete(privileges)) {
      privilegeStatus = false;
    }
  }

  return privilegeStatus;
};

const includesDelete = privileges => {
  return privileges.includes(Constants.DELETE);
};

const includesWrite = privileges => {
  return privileges.includes(Constants.WRITE);
};

const includesRead = privileges => {
  return privileges.includes(Constants.READ);
};

export const isUBIAvailable = features => {
  let ubiAvailable = false;
  let ubiFeature = features.filter(feature => feature.featureCode === UBI)[0];
  if (ubiFeature.capability === AVAILABLE) {
    ubiAvailable = true;
  }
  return ubiAvailable;
};

export const areTripsAvailable = features => {
  let tripsAvailable = false;
  let tripsFeature = features.filter(feature => feature.featureCode === TRIP_STATS)[0];
  if (tripsFeature.capability === AVAILABLE) {
    tripsAvailable = true;
  }
  return tripsAvailable;
};

export const ubiPausedMsgAvailable = features => {
  return (
    Array.isArray(features) &&
    features.some(feature => feature.featureCode === UBI_PAUSED_MSG && feature.capability === AVAILABLE)
  );
};
