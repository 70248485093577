import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
const Tooltip = props => {
  return (
    <div>
      <ReactTooltip id='registerTipPPEV1' place='top' effect='solid'>
        Available on MY21 ID.4 vehicles. Always pay careful attention to the road and do not drive while distracted.
        Certain services require trial or paid subscriptions, which may have their own terms and conditions. VW Car-Net
        requires cellular connectivity, availability of vehicle GPS signal, and acceptance of Terms of Service. Not all
        services and features are available on all vehicles. Certain Car-Net services may connect out to 3rd party
        providers that may require additional payment. Standard text and data rates may apply for app and web features.
        Certain services may collect location and vehicle information. See Terms of Service, Privacy Statement, and
        other important information at www.vw.com/carnet.
      </ReactTooltip>
      <ReactTooltip id='registerTipPP1' place='top' effect='solid'>
        Available on most MY20 and newer vehicles. Always pay careful attention to the road and do not drive while
        distracted. Certain services require trial or paid subscriptions, which may have their own terms and conditions.
        VW Car-Net requires vehicle cellular connectivity and availability of vehicle GPS signal, and not all services
        and features are available on all vehicles. Certain Car-Net services, such as Roadside Call Assist, connect out
        to third-party providers that may require additional payment. Standard text and data rates may apply for app and
        web features. Certain services may collect location and vehicle information. See Terms of Service, Privacy
        Statement, and other important information at www.vw.com/carnet.
      </ReactTooltip>
      <ReactTooltip id='registerTipPP2' place='top' effect='solid'>
        Paid subscription required for Safe & Secure services. Subscription automatically renews and is charged annually
        at end of subscription term unless canceled.
      </ReactTooltip>
      <ReactTooltip id='registerTipPP3' place='top' effect='solid'>
        The Wi-Fi Hotspot feature is intended for passenger use only. 4G LTE coverage is not available in all areas. See
        materials provided for terms, privacy,data security details. Requires trial or paid Wi-Fi plan from third party
        wireless provider.
      </ReactTooltip>
      <ReactTooltip id='registerTipPP4' place='top' effect='solid'>
        Requires pre-existing wireless data plan. Pairing feature not available with all wireless service providers, and
        eligibility is determined by wireless service provider. Wireless service provider terms and conditions apply.
      </ReactTooltip>
      <ReactTooltip id='registerTipPP5' place='top' effect='solid'>
        Requires paid subscription, vehicle to be equipped with MIB3 infotainment system, acceptance of VW Car-Net Terms
        of Service, active data plan, and vehicle cellular and GPS connectivity
      </ReactTooltip>

      <ReactTooltip id='registerTipPP7' place='top' effect='solid'>
        Requires myVW account, paid Car-Net subscription, vehicle to be equipped with MIB3 infotainment system with
        navigation, acceptance of VW Car-Net Terms of Service, and vehicle cellular and GPS connectivity.
      </ReactTooltip>
      <ReactTooltip id='registerTipPPEV7' place='top' effect='solid'>
        Requires vehicle to be equipped with MIB3 infotainment system with navigation, acceptance of VW Car-Net Terms of
        Service, and vehicle cellular and GPS connectivity.
      </ReactTooltip>

      <ReactTooltip id='registerTipPPEV6' place='top' effect='solid'>
        Requires vehicle to be equipped with MIB3 infotainment system with navigation, acceptance of VW Car-Net Terms of
        Service, and vehicle cellular and GPS connectivity. Available voice control functions vary based on vehicle
        equipment.
      </ReactTooltip>
      <ReactTooltip id='registerTipPP6' place='top' effect='solid'>
        Requires myVW account, paid Car-Net subscription, vehicle to be equipped with MIB3 infotainment system,
        acceptance of VW-Car Net Terms of Service, and vehicle cellular and GPS connectivity. Available Voice control
        functions vary based on vehicle equipment.
      </ReactTooltip>

      <ReactTooltip id='registerTipPP8' place='top' effect='solid'>
        Standard text and data rates may apply. See Owner's Manual for further details and important warnings about
        locking and unlocking your vehicle remotely.
      </ReactTooltip>
      <ReactTooltip id='registerTipPP9' place='top' effect='solid'>
        Standard text and data rates may apply.
      </ReactTooltip>
      <ReactTooltip id='registerTipPP10' place='top' effect='solid'>
        Requires VW Car-Net Mobile App and compatible factory-installed or dealer-installed remote start feature. See
        Owner's Manual for further details and important warnings about the keyless ignition feature. Do not leave
        vehicle unattended with the engine running, particularly in enclosed spaces, and consult local laws for any
        limitations on use. Standard text and data rates may apply.
      </ReactTooltip>
      <ReactTooltip id='registerTipPP11' place='top' effect='solid'>
        Refer to your vehicle's warning and indicator lights for the most current diagnostic information. Always consult
        owner's literature for maintenance guidelines.
      </ReactTooltip>
      <ReactTooltip id='registerTipPP12' place='top' effect='solid'>
        Requires compatible factory-installed navigation system. Standard text and data rates may apply.
      </ReactTooltip>
      <ReactTooltip id='registerTipPP13' place='top' effect='solid'>
        Roadside Call Assist connects you to a Roadside Call Assist third-party provider and may require additional
        payment for services rendered.
      </ReactTooltip>
      <ReactTooltip id='registerTipPP14' place='top' effect='solid'>
        Refer to your vehicle's warning and indicator lights for the most current diagnostic information. Always consult
        owner's literature for maintenance guidelines.
      </ReactTooltip>
      <ReactTooltip id='registerTipPP15' place='top' effect='solid'>
        Standard text and data rates may apply.
      </ReactTooltip>
      <ReactTooltip id='registerTipPP16' place='top' effect='solid'>
        Standard text and data rates may apply. Do not use feature to locate stolen vehicle.
      </ReactTooltip>
      <ReactTooltip id='registerTipPP17' place='top' effect='solid'>
        Powered by Parkopedia. Requires parking facility participation. Volkswagen is not responsible for accuracy of
        pricing and payment options, hours of operation, and features. See parkopedia.com for important information and
        details. Standard text and data rates may apply.
      </ReactTooltip>
      <ReactTooltip id='registerTipPP18' place='top' effect='solid'>
        Discounted insurance rates may not be available in all states. Discounts are provided by third-party auto
        insurance companies and are not guaranteed. Use of your vehicle by multiple drivers may impact your driving
        score and your ability to obtain discounted insurance. Always obey all speed and traffic laws.
      </ReactTooltip>

      <ReactTooltip id='registerTipPP19' place='top' effect='solid'>
        Requires paid subscription and may require additional payment for emergency and other third-party services
        rendered.
      </ReactTooltip>
      <ReactTooltip id='registerTipPP20' place='top' effect='solid'>
        Requires paid subscription and VW Car-Net Mobile App. Standard text and data rates may apply.
      </ReactTooltip>
      <ReactTooltip id='registerTipPP21' place='top' effect='solid'>
        Requires paid subscription and may require additional payment for emergency and other third-party services
        rendered.
      </ReactTooltip>
      <ReactTooltip id='registerTipPP22' place='top' effect='solid'>
        Requires paid subscription.
      </ReactTooltip>
      <ReactTooltip id='registerTipPP23' place='top' effect='solid'>
        Requires paid subscription. For use by law enforcement authorities only. See Terms of Service for details.
      </ReactTooltip>
      <ReactTooltip id='registerTipPPREV8' place='top' effect='solid'>
        Standard text and data rates may apply. Always pay careful attention to the road, and do not drive while
        distracted.
      </ReactTooltip>
      <ReactTooltip id='registerTipPPREV9' place='top' effect='solid'>
        Charging station location information provided by a 3rd Party. Volkswagen is not responsible for accuracy of
        location or charger availability for any charging station shown.
      </ReactTooltip>
      <ReactTooltip id='registerTipRASS1' place='top' effect='solid'>
        Available on most MY20 and newer vehicles. Always pay careful attention to the road and do not drive while
        distracted. Certain services require trial or paid subscriptions, which may have their own terms and conditions.
        VW Car-Net Remote Access requires a VW ID, myVW app, and myVW account, cellular connectivity, network compatible
        hardware, availability of vehicle GPS signal, and acceptance of Terms of Service. Not all services and features
        are available on all vehicles and some features may require the most recent software update. Certain Car-Net
        services, such as Roadside Call Assist, connect out to 3rd party providers that may require additional payment.
        Standard text and data rates may apply for app and web features. See Terms of Service, Privacy Statement, and
        other important information at www.vw.com/carnet. Paid subscription required for Safe & Secure services.
        Requires myVW account and acceptance of VW Car-Net Terms of Service. Subscription automatically renews and
        charged annually at end of subscription term unless canceled.
      </ReactTooltip>
      <ReactTooltip id='registerTipRSS2019' place='top' effect='solid' scrollHide={false}>
        Available on select model year 2017-2019 vehicles that are Car-Net equipped with upgraded hardware. Always pay
        careful attention to the road and do not drive while distracted. VW Car-Net services require a VW ID, myVW app,
        and myVW account, cellular connectivity, network compatible hardware, availability of vehicle GPS signal, and
        acceptance of Terms of Service. Requires paid subscription and may require additional payment for emergency and
        other third party services rendered. Subscription automatically renews and charged annually at end of
        subscription term unless canceled. Not all services and features are available on all vehicles and some features
        may require the most recent software update. Certain Car-Net services, such as Roadside Call Assist, connect out
        to 3rd party providers that may require additional payment. Standard text and data rates may apply for app and
        web features. See Terms of Service, Privacy Statement, and other important information at vw.com/carnet.
      </ReactTooltip>
      <ReactTooltip id='registerTipRST2019' place='top' effect='solid'>
        Available on select model year 2017-2019 vehicles that are Car-Net equipped with upgraded hardware. Always pay
        careful attention to the road and do not drive while distracted. Trial starts on date of enrollment . Limit one
        Trial per vehicle. After 30-day trial completes, a paid subscription is required to receive any connected
        services. VW Car-Net services require a VW ID, myVW app, and myVW account, cellular connectivity, network
        compatible hardware, availability of vehicle GPS signal, and acceptance of Terms of Service. Not all services
        and features are available on all vehicles and some features may require the most recent software update.
        Certain Car-Net services, such as Roadside Call Assist, connect out to 3rdparty providers that may require
        additional payment. Standard text and data rates may apply for app and web features. See Terms of Service,
        Privacy Statement, and other important information at www.vw.com/carnet.
      </ReactTooltip>
      <ReactTooltip id='registerTipWIFIT2019' place='top' effect='solid'>
        VW Car-Net wi-fi trial available on select model year 2017-2019 vehicles that are Car-Net equipped with upgraded
        hardware. Requires myVW account and mobile app, acceptance of Car-Net and T-Mobile Terms of Service,vehicle
        cellular connectivity and availability of vehicle GPS signal.Certain services may collect location information.
        30-day/1.5GB(whichever occurs first) hotspot trial begins on activation date and is intended for passenger use
        only. Limit one trial per vehicle. Up to 8 devices can be supported using in-vehicle connectivity. 4G LTE
        coverage is not available in all areas. Use of VW Car-Net hotspot is subject to wireless data provider’s
        customer agreement.
      </ReactTooltip>
      <ReactTooltip id='underCPO1' place='top' effect='solid'>
        Available on select model year 2017-2019 vehicles that are Car-Net equipped with upgraded hardware. Must present
        personalized promotional offer code to dealer during service visit in order to receive offer. Always pay careful
        attention to the road and do not drive while distracted. Trial begins on activation date. After 30-day trial
        completes, a paid subscription is required to receive any connected services. VW Car-Net services require a VW
        ID, myVW app, and myVW account, cellular connectivity, network compatible hardware, availability of vehicle GPS
        signal, and acceptance of Terms of Service. Not all services and features are available on all vehicles and some
        features may require the most recent software update. Certain Car-Net services, such as Roadside Call Assist,
        connect out to 3rd party providers that may require additional payment. Standard text and data rates may apply
        for app and web features. See Terms of Service, Privacy Statement, and other important information at
        www.vw.com/carnet.
      </ReactTooltip>
      <ReactTooltip id='underCPO2' place='top' effect='solid'>
        VW Car-Net Wi-Fi trial is available on select model year 2017-2019 vehicles that are Car-Net equipped with
        upgraded hardware. Requires myVW account and mobile app, acceptance of Car-Net and T-Mobile Terms of Service,
        vehicle cellular connectivity and availability of vehicle GPS signal. Certain services may collect location
        information. 30-day/1.5 GB (whichever occurs first) hotspot trial begins on activation date and is intended for
        passenger use only. Limit one trial per vehicle. Up to 4 devices can be supported using in-vehicle connectivity.
        4G LTE coverage is not available in all areas. Use of VW Car-Net hotspot is subject to wireless data provider’s
        customer agreement.
      </ReactTooltip>
      <ReactTooltip id='underCPO3' place='top' effect='solid'>
        Standard text and data rates may apply. Always pay careful attention to the road, and do not drive while
        distracted.
      </ReactTooltip>
      <ReactTooltip id='ppmyQ' place='top' effect='solid'>
        Requires myQ compatible connected garage door, myQ account, myVW account, garage door and vehicle cellular
        connectivity, network compatible hardware, availability of vehicle GPS signal, and acceptance of Car Net Terms
        of Service. See www.myq.com/app/myq-compatibility for information on myQ compatibility.
      </ReactTooltip>
    </div>
  );
};

export default Tooltip;
