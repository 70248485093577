import {
  GET_STORE_PLANS_AND_CART_INFO,
  GET_STORE_PLANS_AND_CART_INFO_SUCCESS,
  GET_STORE_PLANS_AND_CART_INFO_FAILURE
} from '../../actions/types';

const initialState = {
  storePlansInfo: null,
  marketingInfo: null,
  cartInfo: null,
  dataLoading: false,
  storePlanInfoFailedToLoad: false
};

// Get Store Plans and  Cart Info for a particular vehicle
const storePlansCartInfoReducer = (state = initialState, action = { payload: {} }) => {
  let newState = { ...state };
  let actionType = (action && action.type) || 'default';

  switch (actionType) {
    case GET_STORE_PLANS_AND_CART_INFO:
      console.log(`storePlansCartInfoReducer >> ${actionType}`);
      console.log('action.payload is in storePlansCartInfoReducer is :::' + JSON.stringify(action.payload));
      newState = { ...initialState };
      newState.dataLoading = true;
      newState.storePlanInfoFailedToLoad = false;
      return newState;

    case GET_STORE_PLANS_AND_CART_INFO_SUCCESS:
      console.log(`storePlansCartInfoReducer >> ${actionType}`);
      console.log('action.payload is in storePlansCartInfoReducer is :::' + JSON.stringify(action.payload));
      let responseData = action.payload || {};
      newState.storePlansInfo = responseData[0] || null;
      newState.marketingInfo = responseData[1] || null;
      newState.cartInfo = responseData[2] || null;
      newState.dataLoading = false;
      newState.storePlanInfoFailedToLoad = false;
      return newState;

    case GET_STORE_PLANS_AND_CART_INFO_FAILURE:
      console.log(`storePlansCartInfoReducer >> ${actionType}`);
      console.log('action.payload is in storePlansCartInfoReducer is :::' + JSON.stringify(action.payload));
      newState = { ...initialState };
      newState.dataLoading = false;
      newState.storePlanInfoFailedToLoad = true;
      return newState;

    default:
      return newState;
  }
};

export default storePlansCartInfoReducer;
