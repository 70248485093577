import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';

const Barchart = props => {
  let translator = getLocaleTranslator();
  let barChart = {
    series: [
      {
        name: translator.t('trips.miles_or_Kilometers_label'),
        data: props.barChartData
      }
    ],

    options: {
      chart: {
        type: 'bar',
        height: 376,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          endingShape: 'flat',
          columnWidth: '40%',
          barHeight: '49%',
          distributed: false,
          colors: {
            ranges: [
              {
                from: 0,
                to: 0,
                color: undefined
              }
            ],
            backgroundBarColors: [],
            backgroundBarOpacity: 5
          },
          dataLabels: {
            position: 'top',
            maxItems: 500,
            hideOverflowingLabels: true
          }
        }
      },
      colors: ['#067BC6'],
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: props.xaxisCategories,
        labels: {
          style: {
            fontSize: 12
          }
        },
        tickAmount: 5,
        tickPlacement: 'between'
      },
      yaxis: {
        title: {
          text: ''
        },

        labels: {
          formatter: function (value) {
            return value;
          }
        },

        show: true,
        showAlways: true,
        seriesName: undefined,
        opposite: false,
        reversed: false,
        logarithmic: false,
        tickAmount: 5,
        tickPlacement: 'between',
        min: props.barChartMinValue,
        max: props.barChartMaxValue,
        range: 0,
        decimalsInFloat: 1
      },
      tooltip: {
        theme: 'light',
        x: {
          show: false
        }
      }
    }
  };
  return (
    <div id='barChart' className='rts-graph-chart' aria-label={translator.t('trips.text_left_graph')}>
      <ReactApexChart options={barChart.options} series={barChart.series} type='bar' height={376} />
    </div>
  );
};

export default Barchart;
