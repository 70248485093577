import React from 'react';
import GoogleMap from 'google-map-react';
import Marker from './Marker';
import resource from '../../../config/resource.json';

const TripStopsMap = props => {
  let locations = props.tripStops && props.tripStops.tripSummary && props.tripStops.tripSummary.locations;

  return (
    <div className='rts-map-div'>
      {locations && Array.isArray(locations) && (
        <GoogleMap
          bootstrapURLKeys={{ key: resource.google_map.key, language: 'en' }}
          defaultCenter={{ lat: 0.0, lng: 0.0 }}
          center={{ lat: locations[0].latitude, lng: locations[0].longitude }}
          defaultZoom={18}
        >
          {locations.map((stop, index) => (
            <Marker lat={stop.latitude} lng={stop.longitude} text={index + 1} />
          ))}
        </GoogleMap>
      )}
    </div>
  );
};

export default TripStopsMap;
