/* eslint-disable complexity */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLocaleSync } from '../../../providers/languageProvider';
import * as actions from '../../../redux/actions';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { isNullOrEmptyObject } from '../../../util/utilityMethods';
import { Button } from 'reactstrap';
import ThemedCheckBox from '../../reusable_cmp_lib/ThemedCheckBox/ThemedCheckBox';
import '../../reusable_cmp_lib/ThemedButtonsAssortment/ThemedButtonsAssortment.scss';
import ManageAlertContactsModal from '../common/ManageAlertContactsModal';
import './vehicleHealth.scss';
import ThemedToast from '../../reusable_cmp_lib/ThemedToast/ThemedToast';
class MonthlyReportsPreferences extends Component {
  constructor (props) {
    super(props);
    const userCountry = getLocaleSync().userCountry;
    const caUser = userCountry.toLowerCase() === 'ca';

    this.state = {
      editingField: null,
      stateUpdated: false,
      formDirty: false,
      sendToDealer: true,
      isCanadianUser: caUser,
      recipientEmails:
        (this.props.accountDetails &&
          this.props.accountDetails.customer &&
          this.props.accountDetails.customer.emails) ||
        [],
      recipientPhones:
        (this.props.accountDetails &&
          this.props.accountDetails.customer &&
          this.props.accountDetails.customer.phones) ||
        [],
      recipientIds: [],
      recipientPrefs: {},
      contactsModalVisible: false,
      doSendToDealer: (this.props.vhrPrefIds && this.props.vhrPrefIds.sendToDealer === 'Y') || null
    };
  }

  componentDidMount () {
    this.loadPageData();
  }

  loadPageData = () => {
    this.props.actions.getCustomerAccountDetails();
    this.props.actions.getVHRCustomerPreferences();
  };

  persistChangeToCustomerDetail = mutatedCustomerProfile => {
    if ('{}' === JSON.stringify(mutatedCustomerProfile)) {
      return;
    }
    this.props.actions.updateCustomerDetails(mutatedCustomerProfile);
  };

  saveContactsChanges = () => {
    const finalContacts = JSON.parse(JSON.stringify(this.state.recipientEmails));
    const finalSTDFlag = this.state.sendToDealer === true ? 'Y' : 'N';
    let emailIds = [];
    finalContacts.forEach(record => {
      if (record.checked) {
        if (record.id) {
          emailIds.push(record.id); //Modal-added emails will not have an id yet
        }
      }
    });
    const savePayload = {
      sendToDealer: finalSTDFlag,
      emailIds: emailIds
    };
    this.props.actions.updateVHRCustomerPreferences(savePayload);
  };

  checkForStateChange = () => {
    let vhrPrefIds = this.props.vhrPrefIds || {};
    let sendToDealerFlag = vhrPrefIds.sendToDealer && vhrPrefIds.sendToDealer.toLowerCase();
    let sendToDealer = sendToDealerFlag === 'y';
    let emailIds = (vhrPrefIds.emailIds && Array.isArray(vhrPrefIds.emailIds) && vhrPrefIds.emailIds.sort()) || [];

    // properties from state
    let stateSendToDealer = this.state.sendToDealer;
    let checkedStateEmailIds = [];
    let uncheckedStateEmailIds = [];
    (this.state.recipientEmails || []).forEach(email => {
      if (email.checked === true) {
        checkedStateEmailIds.push(email.id);
      } else {
        uncheckedStateEmailIds.push(email.id);
      }
    });
    // newly checked contact
    let diffInEmailIds = checkedStateEmailIds.filter(emailId => !emailIds.includes(emailId));
    // newly unchecked contact
    let otherDiffInEmailIds = uncheckedStateEmailIds.filter(emailId => emailIds.includes(emailId));

    let formDirty = false;
    if (stateSendToDealer !== sendToDealer || diffInEmailIds.length || otherDiffInEmailIds.length) {
      formDirty = true;
    }

    this.setState({ formDirty });
  };

  handleCheckBoxToggling = (tag, evt) => {
    let checkedState = !!evt.target.checked;

    if (tag === 'pref_dealer') {
      this.setState(
        {
          sendToDealer: checkedState,
          doSendToDealer: checkedState
        },
        this.checkForStateChange
      );
      return;
    }

    let contactEmails = JSON.parse(JSON.stringify(this.state.recipientEmails));
    let indexStr = tag.split('_')[1];
    let index = parseInt(indexStr);
    contactEmails[index].checked = checkedState;
    this.setState(
      {
        recipientEmails: contactEmails
      },
      this.checkForStateChange
    );
  };

  showContactsModal = shown => {
    this.setState({
      contactsModalVisible: shown
    });
  };

  saveContactListUpdates = (adjustedEmails, adjustedPhones) => {
    this.setState(
      {
        recipientEmails: adjustedEmails,
        recipientPhones: adjustedPhones
      },
      () => {
        // Save User Email Updates
        const editedContactsEmails = JSON.parse(JSON.stringify(this.state.recipientEmails));
        const editedContactsPhones = JSON.parse(JSON.stringify(this.state.recipientPhones));
        //merge these into the mutable accountDetail:
        let baseAccountDetailCustomer = JSON.parse(JSON.stringify(this.props.accountDetails.customer)); //(Deep Clone)
        baseAccountDetailCustomer.phones = editedContactsPhones;
        baseAccountDetailCustomer.emails = editedContactsEmails;

        // remove the 'checked' attribute from the payload
        baseAccountDetailCustomer.emails = baseAccountDetailCustomer.emails.map(emailItem => {
          let updated = { ...emailItem };
          delete updated['checked'];
          return updated;
        });
        this.persistChangeToCustomerDetail(baseAccountDetailCustomer);
      }
    );
  };

  componentDidUpdate (prevProps) {
    let prevVhrPrefIds = prevProps.vhrPrefIds || [];
    prevVhrPrefIds = Array.isArray(prevVhrPrefIds) ? prevVhrPrefIds.sort() : prevVhrPrefIds;
    let prevAccountDetails = prevProps.accountDetails;

    let curVhrPrefIds = this.props.vhrPrefIds || [];
    curVhrPrefIds = Array.isArray(curVhrPrefIds) ? curVhrPrefIds.sort() : curVhrPrefIds;
    let curAccountDetails = this.props.accountDetails;

    let prev = JSON.stringify(prevVhrPrefIds) + '_' + JSON.stringify(prevAccountDetails);
    let cur = JSON.stringify(curVhrPrefIds) + '_' + JSON.stringify(curAccountDetails);

    // Props haven't updated just return
    if (isNullOrEmptyObject(curVhrPrefIds) || isNullOrEmptyObject(curAccountDetails) || prev === cur) {
      return;
    }

    let localCbState = [];
    let alertDealer = false;
    alertDealer = this.props.vhrPrefIds && this.props.vhrPrefIds.sendToDealer === 'Y';
    let checkedIds = (this.props.vhrPrefIds && this.props.vhrPrefIds.emailIds) || [];
    let emailsList =
      (this.props.accountDetails && this.props.accountDetails.customer && this.props.accountDetails.customer.emails) ||
      [];
    let contactsPhonesList =
      (this.props.accountDetails && this.props.accountDetails.customer && this.props.accountDetails.customer.phones) ||
      [];

    emailsList.forEach(emailDatum => {
      let isChecked = checkedIds.includes(emailDatum.id);
      let localRecord = {
        email: emailDatum.email,
        id: emailDatum.id,
        checked: isChecked,
        primary: emailDatum.primary
      };
      localCbState.push(localRecord);
    });

    // state updated from props, everything in sync, form clean
    let updatedState = {
      sendToDealer: alertDealer,
      recipientEmails: localCbState,
      recipientPhones: contactsPhonesList,
      formDirty: false
    };

    this.setState(updatedState);
  }

  render () {
    let translator = getLocaleTranslator();
    let rawPhonesList = JSON.parse(JSON.stringify(this.state.recipientPhones));
    let checkstatesData = this.state.recipientEmails || [];
    /* Earlier doSendToDealer is always getting the value from service. Now fetching from state */
    let doSendToDealer = this.state.doSendToDealer;
    if (doSendToDealer === null) {
      doSendToDealer = this.props.vhrPrefIds && this.props.vhrPrefIds.sendToDealer === 'Y';
    }

    return (
      <div className='vehicle-details'>
        <h4 className='monthly-report'>
          {translator.t('vehicleHealth.monthly_reports_heading')}
          &nbsp;&nbsp;
        </h4>
        <p>{translator.t('vehicleHealth.monthly_reports_text')}</p>

        <div className='checkbox-margin'>
          <ThemedCheckBox
            toggleHandler={evt => this.handleCheckBoxToggling('pref_dealer', evt)}
            name='preferred_dlr_recipient'
            id='recipient_chkbox_pref_dealer'
            label={translator.t('vehicleHealth.preferred_dealer_label')}
            isChecked={doSendToDealer}
          />

          {(checkstatesData || []).map((email, index) => {
            return (
              <ThemedCheckBox
                key={'managealerts_emailid_' + index}
                toggleHandler={evt => this.handleCheckBoxToggling('selections_' + index, evt)}
                name={'report_recipients_' + (index + 1)}
                id='recipient_chkbox_custom_1'
                label={email.email}
                isChecked={email.checked}
              />
            );
          })}
        </div>
        <br />
        {this.state.isCanadianUser === false && (
          <button type='button' className='vhr-manage-contacts-btn' onClick={this.showContactsModal}>
            {translator.t('vehicleHealth.manage_alert_contacts_btn_label')}
          </button>
        )}
        {!!this.props.updateStatus && !!this.props.vhrPrefIds && (
          <ThemedToast
            msg={translator.t('vehicleHealth.preferences_saved_success')}
            display={true}
            returnFocusAfterClose={false}
            onClosed={() => document.getElementById('saveBtn').focus()}
          />
        )}
        <Button
          className='cwp width217'
          onClick={this.saveContactsChanges}
          disabled={this.state.formDirty === false}
          color='primary'
          id='saveBtn'
        >
          {translator.t('vehicleHealth.save_btn_label')}
        </Button>
        {this.state.contactsModalVisible && this.state.isCanadianUser === false && (
          <ManageAlertContactsModal
            currentContacts={this.state.recipientEmails}
            anyContactsPhones={rawPhonesList}
            modalShowHandler={this.showContactsModal}
            open={true}
            saveEdits={this.saveContactListUpdates}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getVHRCustomerPreferences: actions.getVHRCustomerPreferences,
        updateVHRCustomerPreferences: actions.updateVHRCustomerPreferences,
        getCustomerAccountDetails: actions.getCustomerAccountDetails,
        updateCustomerDetails: actions.updateCustomerDetails
      },
      dispatch
    )
  };
};

let mapStateCount = 0;
const mapStateToProps = state => {
  const { vhrCustomerPreferences, updateVHRCustomerPreferences, customerAccountDetails = {} } = state;

  mapStateCount += 1;
  return {
    vhrPrefIds: vhrCustomerPreferences.vhrPrefIds,
    vhrPrefData: vhrCustomerPreferences.vhrPrefData,
    updateStatus: updateVHRCustomerPreferences.prefData, // indicates an update success
    accountDetails: customerAccountDetails.customerData || {},
    mapStateCount
  };
};

export { MonthlyReportsPreferences };
export default connect(mapStateToProps, mapDispatchToProps)(MonthlyReportsPreferences);
