import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Player } from 'video-react';
import { getCwpMeta } from '../../../providers/cwpMetaProvider';
import enrollment from '../../../assets/faq/Enrollment.png';
import './Video-react.scss';

export class VideoDisplay extends Component {
  state = {
    videoVisible: false
  };

  componentDidMount = async () => {
    let metaResponse = await getCwpMeta();
    let videoContentURL = metaResponse.static.content.urls.videos;
    this.setState({
      videoContentURL
    });
  };

  toggleopen = id => {
    this.setState({ videoVisible: !this.state.videoVisible });
  };

  render () {
    return (
      <div>
        <a href='javascript:void(0);' onClick={this.toggleopen}>
          <img src={enrollment} width='370px' height='210px' />
        </a>
        <span className='video-title'>Car-Net Enrollment</span>
        <Modal isOpen={this.state.videoVisible} toggle={this.toggleopen} centered={true} className='video'>
          <ModalHeader toggle={this.toggleopen}>Car-Net Enrollment</ModalHeader>
          <ModalBody>
            {this.state.videoContentURL && (
              <Player
                playsInline
                src={`${this.state.videoContentURL}/KYVW20-M2346-009_CarNet-Enroll_121019_10a_LLL.mp4`}
              />
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default VideoDisplay;
