import {
  GET_CARNET_USER_PRIVILEGES,
  GET_CARNET_USER_PRIVILEGES_SUCCESS,
  GET_CARNET_USER_PRIVILEGES_FAILURE
} from '../../actions/types';

const initalState = {
  userPrivilegesData: null
};

const carnetPrivilegesReducer = (state = initalState, action = { payload: {} }) => {
  let newState = { ...state };
  let actionType = (action && action.type) || 'default';
  let responseData = action.payload || {};

  switch (actionType) {
    case GET_CARNET_USER_PRIVILEGES:
      newState = { ...initalState };
      return newState;

    case GET_CARNET_USER_PRIVILEGES_SUCCESS:
      let { data } = responseData;
      newState['userPrivilegesData'] = data || null;

      return newState;
    case GET_CARNET_USER_PRIVILEGES_FAILURE:
      newState['userPrivilegesData'] = null;
      return newState;
    default:
      return newState;
  }
};

export default carnetPrivilegesReducer;
