const VEHICLE_ID_KEY = 'vehicleId',
  FINAL_DESTINATION_KEY = 'finalDestination',
  SESSION_HASH_ID = 'sessionHashId',
  PROFILE_COMPLETION_CHECK = 'profileCompletion';

const QUERY_CONST = {
  VEHICLE_ID_KEY,
  FINAL_DESTINATION_KEY,
  SESSION_HASH_ID,
  PROFILE_COMPLETION_CHECK
};

// locale storage lookup key
let CWP_QUERY_PARAMS_KEY = 'CWP_QUERY_PARAMS';
let storage = window && window.localStorage;
// read into current runtime session
let queryValuesHash = {};

// retrieve from localstorage
const getStoredQueryParams = () => {
  let emptyObjectString = JSON.stringify({});
  let thingToReturn = storage.getItem(CWP_QUERY_PARAMS_KEY) || emptyObjectString;
  try {
    thingToReturn = JSON.parse(thingToReturn);
  } catch (err) {
    thingToReturn = {};
  }

  if (!thingToReturn || typeof thingToReturn !== 'object') {
    thingToReturn = {};
  }
  return thingToReturn;
};

// store the hashy thing in localstorage
const setStoredQueryParams = value => {
  return storage.setItem(CWP_QUERY_PARAMS_KEY, value);
};

// remove the query hashy thing from localstorage
const clearStoredQueryParams = () => {
  console.log('queryActionsProvider >> clearStoredQueryParams ()');
  storage.removeItem(CWP_QUERY_PARAMS_KEY);
};

// retrieve a value from the hash object
const getQueryValue = key => {
  console.log('queryActionsProvider >> getQueryValue ()', key);
  let valueToReturn = queryValuesHash[key];
  return valueToReturn;
};

// delete a key/value pair and update the local store
const clearQueryValue = key => {
  console.log('queryActionsProvider >> clearQueryValue ()', key);
  delete queryValuesHash[key];
  // there still are keys "actions", don't remove it yet
  if (Object.keys(queryValuesHash).length) {
    return setStoredQueryParams(JSON.stringify(queryValuesHash));
  }
  // there are no keys delete it out altogether
  clearStoredQueryParams();
};

// On application startup, read any query params passed and add to existing storage
const queryParamsOnApplicationLoad = () => {
  const NOT_TO_STORE = [SESSION_HASH_ID];
  const REQUIRES_RELOAD = [SESSION_HASH_ID];
  let RELOAD_REQUIRED = false;

  const _window = window || {};
  // First get any routing info and cache it
  let url = String(_window.location.href);
  let urlParts;
  let parameterArr = [];

  queryValuesHash = getStoredQueryParams();

  // GETTING AUTO DRIVE SETTING
  if (url.includes('?')) {
    urlParts = url.split('?');
    parameterArr = urlParts[1].split('&');
  }

  // update the query value hash object based on recent query params
  parameterArr.forEach(paramThing => {
    let parts = paramThing.split('=');
    let key = parts[0];
    let value = parts[1] || 'null';

    if (REQUIRES_RELOAD.includes(key)) {
      RELOAD_REQUIRED = true;
    }

    if (!NOT_TO_STORE.includes(key)) {
      queryValuesHash[key] = value;
    } else {
      console.log('not a queryParam to store', key);
    }
  });

  // store it in locale storage
  if (Object.keys(queryValuesHash).length) {
    setStoredQueryParams(JSON.stringify(queryValuesHash));
  }

  if (RELOAD_REQUIRED) {
    console.log('reload required');
    window.location.href = './';
  }

  console.log('no query params onload');
  return Promise.resolve();
};

export { queryParamsOnApplicationLoad, getQueryValue, clearQueryValue, QUERY_CONST };
