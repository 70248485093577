import React, { Component } from 'react';
import './GMapPin.scss';

class GMapPin extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      isHovered: false
    };
  }

  toggleHovering = flag => {
    this.setState({
      isHovered: flag
    });
  };

  render () {
    return (
      <div
        className='gmap-pin-halo'
        onMouseEnter={() => {
          this.toggleHovering(true);
        }}
        onMouseLeave={() => {
          this.toggleHovering(false);
        }}
      >
        {this.state.isHovered === true && (
          <div class='tooltip-newstyle gmap-pin'>
            <span class='tooltip-text'>{this.props.tooltip}</span>
          </div>
        )}

        <div className='gmap-pin-circle'>
          <span className='gmap-pin-circleText' title={this.props.tooltip}>
            {this.props.text}
          </span>
        </div>
      </div>
    );
  }
}

export default GMapPin;
