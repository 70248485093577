import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { applyPolyfills, defineCustomElements } from '@duetds/date-picker/dist/loader';

import './util/axiosInterceptors';

// Reduxinary Things
import { Provider } from 'react-redux';
import store from './redux/appstore';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

import App from './App';

// required to enable duetds datepicker (applyPolyfills for IE11/Edge support)
applyPolyfills().then(() => {
  defineCustomElements(window);
});

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);
