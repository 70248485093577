import { createLogic } from 'redux-logic';
import axios from 'axios';
import {
  GET_VHR_CUSTOMER_PREFERENCES,
  GET_VHR_CUSTOMER_PREFERENCES_SUCCESS,
  GET_VHR_CUSTOMER_PREFERENCES_FAILURE
} from '../../actions/types';

let dataProvider = axios;

let requestConfig = {
  method: 'GET',
  url: `vhr/customer/preferences`,
  headers: {
    accept: 'application/json'
  }
};

const getVHRCustomerPreferencesLogic = createLogic({
  type: GET_VHR_CUSTOMER_PREFERENCES,
  latest: true,
  processOptions: {
    dispatchReturn: true,
    successType: GET_VHR_CUSTOMER_PREFERENCES_SUCCESS,
    failType: GET_VHR_CUSTOMER_PREFERENCES_FAILURE
  },

  process ({ action }) {
    console.log(
      'getVHRCustomerPreferences is processing an action payload is:: >>>',
      JSON.stringify(action.payload, null, 1)
    );
    console.log('type: ' + action.type);

    return dataProvider(requestConfig)
      .then(response => {
        console.log(
          'got response for getVHRCustomerPreferences GET request >>> ',
          JSON.stringify(response.data, null, 1)
        );
        return response.data;
      })
      .catch(error => {
        console.log('Error while processing getVHRCustomerPreferences');
        console.log(JSON.stringify(error, null, 1));
      });
  }
});

export default [getVHRCustomerPreferencesLogic];
