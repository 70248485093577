import React from 'react';
import { getLocaleTranslator, findErrorMessage } from '../../../util/i18n/i18nService';
import { Input, Form, UncontrolledAlert } from 'reactstrap';
import { createSPIN } from '../providers/GarageProvider';
import { setTitle } from '../../../providers/documentTitleProvider';

class CreateSPIN extends React.Component {
  state = {
    spin: '',
    processing: false,
    alert: null
  };

  constructor (props) {
    super(props);
    this.translator = getLocaleTranslator();
  }

  componentDidMount () {
    setTitle('document_titles.garage.create_pin');
  }

  clearAlerts = () => this.setState({ alert: null });

  getAlerts = () => {
    if (this.state.alert) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts}>
          {this.state.alert}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  onSPINChange = e => {
    e.preventDefault();

    var pin = e.currentTarget.value.replace(/\D/g, '');

    this.setState({ spin: pin });
  };

  onClickSubmit = async e => {
    e.preventDefault();

    this.setState({ processing: true });
    try {
      let data = await createSPIN(this.state.spin);

      if (data.error) {
        this.setState({ processing: false, alert: findErrorMessage(data.error, this.translator) });

        return;
      }

      console.log('Create SPIN response is - ' + data);
      this.props.refreshGarage();

      this.setState({ processing: false });
    } catch (err) {
      console.log('Create SPIN response failed - ' + err);
      this.setState({ processing: false, alert: findErrorMessage(err, this.translator) });
    }
  };

  findFieldLabelStyleClass = value => {
    if (value) {
      return 'cwp';
    }

    return 'cwp floating invisible';
  };

  render () {
    return (
      <Form>
        {this.getAlerts()}
        <h1 className='font-weight-normal'>
          {this.translator.t('create_spin.label_create')} <strong>{this.translator.t('create_spin.label_pin')}</strong>
        </h1>
        <br />
        <span className='pageSubTitle'>{this.translator.t('create_spin.subtitle')}</span>
        <br />
        <br />

        <div className='form-group'>
          <label htmlFor='spin' className={this.findFieldLabelStyleClass(this.state.spin)}>
            {this.translator.t('create_spin.create_4_digit_pin')}
          </label>
          <Input
            className='cwp'
            type='password'
            id='spin'
            placeholder={this.translator.t('create_spin.create_4_digit_pin')}
            maxLength='4'
            autoComplete='off'
            onChange={this.onSPINChange}
            required='true'
            pattern='[0-9]{4}'
            title={this.translator.t('create_spin.4_digit_number_only')}
            value={this.state.spin}
            autoFocus='true'
          />
        </div>
        <br />
        <br />
        <br />
        <input
          type='submit'
          value={this.translator.t('button.submit')}
          disabled={this.state.processing || this.state.spin.length !== 4}
          className='cwp'
          onClick={this.onClickSubmit}
        />
      </Form>
    );
  }
}

export default CreateSPIN;
