import { createLogic } from 'redux-logic';
import axios from 'axios';
import {
  UPDATE_VHR_CUSTOMER_PREFERENCES,
  UPDATE_VHR_CUSTOMER_PREFERENCES_SUCCESS,
  UPDATE_VHR_CUSTOMER_PREFERENCES_FAILURE
} from '../../actions/types';

let dataProvider = axios.put;

const updateVHRCustomerPreferencesLogic = createLogic({
  type: UPDATE_VHR_CUSTOMER_PREFERENCES,
  latest: true,
  processOptions: {
    dispatchReturn: true,
    successType: UPDATE_VHR_CUSTOMER_PREFERENCES_SUCCESS,
    failType: UPDATE_VHR_CUSTOMER_PREFERENCES_FAILURE
  },

  process ({ action }) {
    console.log('updateVHRCustomerPreferencesLogic is processing an action type :' + action.type);

    console.log('Pref Data >>>>>>>>' + JSON.stringify(action.payload, null, 1));

    /*
    // NOT USING - DO WE NEED THIS, IS SOMETHING CHANGING?
    let requestConfig = {
      method: 'PUT',
      url: `vhr/customer/preferences/update`,
      headers: {
        accept: 'application/json',
      },
      data: {
        prefData: action.payload === null ? '' : action.payload,
        //action.payload
      },
    };
    */

    return dataProvider(`vhr/customer/preferences/update`, action.payload)
      .then(response => {
        console.log(
          'got response for updateVHRCustomerPreferences PUT request >>> ',
          JSON.stringify(response.data, null, 1)
        );
        return response.data;
      })
      .catch(error => {
        console.log('Error while processing updateVHRCustomerPreferences');
        console.log(JSON.stringify(error, null, 1));
      });
  }
});

export default [updateVHRCustomerPreferencesLogic];
