import React from 'react';
import MarketingProducts from '../components/MarketingProducts';
import { BuyPlansContext } from '../BuyPlansContext';
import { ServiceResponseStatus, findArticleDisclaimerText } from '../buyPlansUtil';
import { Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledAlert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { findErrorMessage } from '../../../../util/i18n/i18nService';
import { getErrorCodeDesc } from '../../buyPlans/buyPlansUtil';
import Spinner from '../../Spinner';
import { purchaseZeroCostArticle } from '../../providers/EstoreProvider';

export default class AlexaWebapp extends React.Component {
  static contextType = BuyPlansContext;

  state = {
    alertMessage: null,
    processing: false,
    articleDisclaimer: null,

    activated: false,
    showModalPurchaseBlockReason: false
  };

  componentDidMount () {
    this.initData();
  }

  initData = async () => {
    let plan = this.context?.selectedPlan;

    if (!plan) {
      const marketingConfigurationId = this.props.match?.params?.marketingConfigurationId;
      const { additionalPlans, selectPlanFromList } = this.context;

      const [, category, subCategory] = this.props.match?.url?.split('/') || [];
      plan = selectPlanFromList(additionalPlans, category, subCategory, marketingConfigurationId);
    }

    const marketingConfiguration = this.context.marketingConfigurationHash?.[plan?.marketingConfigurationId];
    const articleDisclaimer = findArticleDisclaimerText(marketingConfiguration);

    this.setState({ articleDisclaimer });
    window.scrollTo(0, 0);
  };

  clearAlerts = () => this.setState({ alertMessage: null });

  getAlerts = () => {
    if (this.state.alertMessage) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts} aria-live='polite' className='w-100'>
          {this.state.alertMessage}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  onClickActivate = async () => {
    const plan = this.context?.selectedPlan;

    if (plan.purchaseable === false) {
      this.setState({ showModalPurchaseBlockReason: true });

      return;
    }

    this.setState({ processing: true });
    try {
      const { result, status } = await purchaseZeroCostArticle(plan.subCategory);

      if (result === 0 || status === ServiceResponseStatus.SUCCESS) {
        this.setState({ processing: false, activated: true });
      } else {
        this.setState({
          processing: false,
          alertMessage: this.context.translator.t('buyplans_purchase_error_message')
        });
      }
    } catch (ex) {
      let alertMessage;
      if (ex.error) {
        const { errorCode, errorDescription } = ex;
        alertMessage = getErrorCodeDesc(errorCode) || errorDescription;
      } else {
        alertMessage = findErrorMessage(ex);
      }

      this.setState({ processing: false, alertMessage });
    }
  };

  render () {
    const {
      translator,
      contentURL,
      showDisclaimer,
      selectPlan,
      selectedPlan: plan,
      marketingConfigurationHash
    } = this.context;
    const marketingConfiguration = marketingConfigurationHash?.[plan?.marketingConfigurationId] || {};

    return (
      <div role='main' id='main-content' className='py-4'>
        <div className='ml-4 position-absolute'>
          <Link to='/' className='linkBack' role='button' onClick={() => selectPlan(null)}>
            {translator.t('buyplans.button-label.back-to-your-plans')}
          </Link>
        </div>
        <div className='container d-flex flex-column align-items-center text-center mt-4 pb-5'>
          <h1 className='font-weight-light'>
            {marketingConfiguration?.currentPlanContent?.title || marketingConfiguration?.title}
            {this.state.articleDisclaimer && (
              <button
                type='button'
                className='cwp-disclaimer ml-2 mainTitlePosition'
                tabIndex={0}
                onClick={e =>
                  showDisclaimer(
                    this.state.articleDisclaimer,
                    marketingConfiguration.articleDisclaimerNumber,
                    e.currentTarget
                  )
                }
              >
                {marketingConfiguration?.articleDisclaimerNumber}
              </button>
            )}
          </h1>
          <article className='text-muted text-center mt-2 mb-5'>{marketingConfiguration?.description}</article>
          {this.getAlerts()}
          <section className='row justify-content-md-between col-12 col-md-9'>
            <article className='col-auto d-flex flex-column text-left mb-3'>
              <img
                src={'' + contentURL + marketingConfiguration?.summary?.providerLogo}
                alt={marketingConfiguration?.summary?.providerName}
                className='align-self-start'
              />
              <strong className='my-2'>{marketingConfiguration?.title}</strong>
              {marketingConfiguration?.pricingDisclaimerList?.map(pd => (
                <span className='small text-muted'>{pd.disclaimerText}</span>
              ))}
            </article>
            <article className='col-auto d-flex flex-column'>
              <button
                type='button'
                className={`cwp cwpPrimary ${this.state.activated ? 'inCart' : ''}`}
                onClick={this.onClickActivate}
                disabled={this.state.processing || this.state.activated}
              >
                {translator.t(this.state.activated ? 'buyplans_alexa_activated' : 'buyplans_alexa_activate')}
              </button>
              {this.state.processing && <Spinner />}
            </article>
          </section>
        </div>
        <Modal
          isOpen={this.state.showModalPurchaseBlockReason}
          onHide={() => this.setState({ showModalPurchaseBlockReason: false })}
          centered={true}
          className='carnet-sg-modal'
        >
          <ModalHeader>{translator.t('buyplans_alexa_modal_dataplan_title')}</ModalHeader>
          <ModalBody>{translator.t('buyplans_alexa_modal_dataplan_desc')}</ModalBody>
          <ModalFooter className='d-flex flex-column align-items-center'>
            <button
              type='button'
              className='modal-primary-btn'
              onClick={() => this.setState({ showModalPurchaseBlockReason: false })}
            >
              {translator.t('button.okay')}
            </button>
          </ModalFooter>
        </Modal>
        {plan && (
          <MarketingProducts
            contentURL={contentURL}
            plan={plan}
            marketingConfiguration={marketingConfiguration}
            showDisclaimer={this.context.showDisclaimer}
          />
        )}
      </div>
    );
  }
}
