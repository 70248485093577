/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';

import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { getLocaleSync } from '../../../providers/languageProvider';
import { getCwpMeta } from '../../../providers/cwpMetaProvider';
import { dateFormat } from '../../../util/i18n/localeUtils';

import { findIn } from '../../../util/utilityMethods';

import '../CarnetHome.scss';
import './selfEnrollment.scss';
import './enrollmentSummary.scss';

function EnrollmentSummary (props) {
  const translator = getLocaleTranslator();
  const configurations = (props.staticMarketingInfo && props.staticMarketingInfo.configurations) || [];
  const productDataNodeArr = configurations.filter(config => {
    return (
      config.skus && config.skus.includes('REMOTE') && config.configurationId && config.configurationId === 'BASIC_FREE'
    );
  });
  const productDataNode = (productDataNodeArr && productDataNodeArr[0]) || {};

  let productImagePath = findIn('summary.icon', productDataNode);

  const [productImage, setProductImage] = useState(null);
  const [dataTrialImage, setDataTrialImage] = useState(null);
  const [dataTrialTitle, setDataTrialTitle] = useState(null);
  const [dataTrialDesc, setDataTrialDesc] = useState(null);

  // set initial date
  useEffect(() => {
    let dataTrialConfig = props.dataTrialConfiguration;
    getCwpMeta().then(metaResponse => {
      let imageContentURL = metaResponse.static.content.urls.estore;
      let productImage = imageContentURL + productImagePath;
      setProductImage(productImage);
      if (dataTrialConfig?.summary) {
        setDataTrialImage(imageContentURL + dataTrialConfig?.summary?.icon);
        setDataTrialTitle(dataTrialConfig?.summary?.title);
        setDataTrialDesc(dataTrialConfig?.summary?.shortDescription);
      }
    });
  }); // eslint-disable-line

  const customer = props.enrollmentStatus.customer || {};
  const { firstName = '', lastName = '', email = '' } = customer;
  const address = customer.address || {};
  const { addressLine1 = '', addressLine2 = '', city = '', state = '', zipCode = '' } = address;
  const fullName = firstName + ' ' + lastName;

  const productTitle = productDataNode.title || '';
  const productDescription = productDataNode.description || '';
  const productDurationMonths = findIn('eligibleFreeArticles.freeSubMonths', productDataNode) || 0;
  const productDurationYears = Math.round(productDurationMonths / 12);
  let productDuration = productDurationYears || productDurationMonths;
  const pructionDurationLabel = productDurationYears ? 'YEAR' : 'MONTHS';
  let expiryDate = findIn('eligibleFreeArticles.expiryDate', productDataNode) || '';
  let termsOfArticle = productDataNode.termsOfArticle || [];
  termsOfArticle = Array.isArray(termsOfArticle) ? termsOfArticle : [termsOfArticle];

  const startDateMs = Date.now();
  const startDateToFieldValue = dateFormat(startDateMs);

  // layout testing as these values are being populated by the microservice json
  // productDuration = 11; // testing
  // expiryDate = Date.now(); // testing

  const endDateToFieldValue = (expiryDate && dateFormat(expiryDate)) || '';

  /**
   * Helper to retrieve the correct footer url based on locale
   * Until login, returns the default (en-US)
   */
  const locale = getLocaleSync().locale;
  const getPrivacyPolicyUrl = () => {
    let DEFAULT = 'https://www.vw.com/privacy/';
    let localeToUrlMap = {
      'en-US': 'https://www.vw.com/privacy/',
      'en-CA': 'https://www.vw.ca/en/privacy-policy.html',
      'fr-CA': 'https://www.vw.ca/fr/politique-de-confidentialite.html'
    };
    return localeToUrlMap[locale] || DEFAULT;
  };

  const localeIsFrench = locale.includes('fr') || locale.includes('FR');
  return (
    <div className='tosEnrollmentSummaryCAWrapper'>
      <div className='tosEnrollmentSummaryCA'>
        <div className='col left'>
          <span className='cwpBack' onClick={props.backAction} role='button' tabIndex={0}>
            {translator.t('tos_carnet.back_to_terms')}
          </span>
        </div>
        <div className='col center'>
          <h1 className='font-weight-normal'>
            {translator.t('tos_carnet.vehicle_title')} <strong>{translator.t('tos_carnet.summary_title')}</strong>
          </h1>
          <span className='subhead_txt'>{translator.t('tos_carnet.vehicle_summary_subhead')}</span>
        </div>
        <div className='col right'>
          <span className='helpText'>{translator.t('tos_carnet.carnet_customer_service_assistance')}</span>
        </div>
      </div>

      <div className='tosEnrollmentSummaryCA'>
        <div className='col left'></div>

        <div className='col center divided'>
          <div className='oneThirdLeft'>
            <h2>{translator.t('tos_carnet.vehicle_title')}</h2>
            <img src={props.imgUrl} alt={' '} width='300' />
            <h3 className='font-weight-normal'>{props.vehicleName}</h3>
            <h6>
              {translator.t('tos_carnet.vin')}: {props.vin}
            </h6>
            <hr />
            <h2>{translator.t('tos_carnet.contact_info_label')}</h2>
            <span>
              <strong>{fullName}</strong>
            </span>
            <br />
            <span>{addressLine1}</span>
            <br />
            {addressLine2 && (
              <>
                <span>{addressLine2}</span>
                <br />
              </>
            )}
            <span>
              {city}, {state}
            </span>
            <br />
            <span>{zipCode}</span>
            <br />
            <br />
            <span>{email}</span>
          </div>

          <div className='twoThirdsRight'>
            <h2>{translator.t('tos_carnet.service_plan_title')}</h2>

            <div className='serviceInfoBox'>
              <div className='top sectional'>
                <img src={productImage} alt={''} width='30' />
                <h5 className='inline'>{productTitle}</h5>
              </div>

              <div className='sectional triplets'>
                <div>
                  {Number(productDuration) > 0 && (
                    <span className='productDuration'>
                      <strong>
                        {productDuration} {pructionDurationLabel}
                      </strong>
                    </span>
                  )}
                  <br />
                  <span>
                    {translator.t('tos_carnet.start_date_label')}: {startDateToFieldValue}
                  </span>
                  <br />
                  {expiryDate && (
                    <span>
                      {translator.t('tos_carnet.ends_on_label')}: {endDateToFieldValue}
                    </span>
                  )}
                </div>
                <div>
                  <span className='monthlyPrice'>
                    {!localeIsFrench && <sup className='monthlyPricePrecursor'>$</sup>}
                    <strong>00</strong>
                    <sup>00</sup>
                    {localeIsFrench && <sup className='monthlyPricePrecursor'>$</sup>}
                  </span>
                  <span className='pricePerLabel'>
                    {'/'}
                    {translator.t('tos_carnet.monthLabel')}
                  </span>
                </div>
                <div className='description'>
                  <span>{productDescription}</span>
                </div>
              </div>

              <div className='sectional'>
                {termsOfArticle.map(article => {
                  if (article && article.termsText) {
                    return <span>{article.termsText}</span>;
                  }
                  return '';
                })}
              </div>
              <div className='sectional'>
                <div className='disabledChecks'>
                  <span className='cwpCheckbox cwpCheckboxOn'> </span>
                  <span className='checkedDisclaimerText'>{translator.t('tos_carnet.i_have_read_tos_check')}</span>
                  <br />
                  <br />
                  <span className='cwpCheckbox cwpCheckboxOn'> </span>
                  <span className='checkedDisclaimerText'>{translator.t('tos_carnet.i_had_read_vw_ca_pp_check')}</span>
                </div>
              </div>
              <div className='sectional last'>
                {translator.t('tos_carnet.place_of_contract_label')}: {city}, {state}
              </div>
            </div>
            {props.dataTrialHotspotTos && props.dataTrialPrivacyTos && (
              <div className='mt-4'>
                <h2>{translator.t('self_enrollment.data_trial.wifi_plan')}</h2>
                <div className='serviceInfoBox'>
                  <div className='top sectional'>
                    <img src={dataTrialImage} alt={''} width='30' />
                    <h5 className='inline'>{dataTrialTitle}</h5>
                  </div>
                  <div className='sectional dataTrialSummary triplets'>
                    <div></div>
                    <div>
                      <span className='monthlyPrice'>
                        {!localeIsFrench && <sup className='monthlyPricePrecursor'>$</sup>}
                        <strong>00</strong>
                        <sup>00</sup>
                        {localeIsFrench && <sup className='monthlyPricePrecursor'>$</sup>}
                      </span>
                      <span className='pricePerLabel'>
                        {'/'}
                        {translator.t('tos_carnet.monthLabel')}
                      </span>
                    </div>
                    <div className='description'>
                      <span>{dataTrialDesc}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className='pricingBreakdown'>
              <span className='priceBreakdownLabel'>{translator.t('tos_carnet.subtotal_label')}: </span>
              <span className='priceBreakdownValue'>{localeIsFrench ? '0.00$' : '$0.00'}</span>
              <br />
              <br />
              <span className='priceBreakdownLabel'>{translator.t('tos_carnet.goods_and_services_tax_label')}: </span>
              <span className='priceBreakdownValue'>{localeIsFrench ? '0.00$' : '$0.00'}</span>
              <br />
              <br />
              <span className='priceBreakdownLabel last'>{translator.t('tos_carnet.total_cad_label')}: </span>
              <span className='priceBreakdownLabel last'>{localeIsFrench ? '0.00$' : '$0.00'}</span>
              <br />
            </div>
          </div>
        </div>

        <div className='col right'></div>
      </div>

      <div className='tosEnrollmentSummaryCA'>
        <div className='col left'></div>
        <div className='col center'>
          <div>
            <span className='subhead_txt'>
              {translator.t('tos_carnet.enroll_vehicle_confirmation_01')}
              <a href={props.tosUrl}>{translator.t('tos_carnet.enroll_vehicle_confirmation_02')}</a>
              {translator.t('tos_carnet.enroll_vehicle_confirmation_03')}{' '}
              <a href={getPrivacyPolicyUrl()}>{translator.t('tos_carnet.enroll_vehicle_confirmation_04')}</a>.
            </span>
          </div>

          <div className='tos-cont-btn-wrapper'>
            <button className='cwp mb-5' onClick={props.finalEnrollmentAction} disabled={props.processing}>
              {translator.t('tos_carnet.enroll_vehicle')}
            </button>
          </div>

          <div className='cc_txt'>
            <span className='subhead_txt'>{translator.t('tos_carnet.enrollment_summary_footer')}</span>
          </div>
        </div>
        <div className='col right'></div>
      </div>
    </div>
  );
}

export default EnrollmentSummary;
