import React from 'react';
import { formatDateISO } from '../../util/utilityMethods';
import './CarnetHome.scss';
import Garage from './garage/Garage';
import HeaderPrePinAuth from './garage/HeaderPrePinAuth';

const CarnetHome = props => {
  return (
    <div className='App preVehicleAuth'>
      <HeaderPrePinAuth />
      <br />
      <div className='container-fluid'>{props.userAuthorized && <Garage locale={props.locale} />}</div>
    </div>
  );
};

const formatPhoneNumber = numberStr => {
  if (!numberStr) {
    return '';
  }

  let number = '' + numberStr.replace(/\D/g, '');
  if (number === '') {
    return '';
  }

  let digits = number.split('');
  digits = digits.slice(0, 11);

  let format = '(###) ###-####';
  // handling 11-digits as well
  if (digits.length === 11) {
    format = '#(###) ###-####';
  }
  digits.forEach(function (digit) {
    format = format.replace('#', digit);
  });

  if (digits.length < 10) {
    number = format.substr(0, format.indexOf('#'));
  } else {
    number = format;
  }

  return number;
};

const digitsOnly = number => {
  if (!number || typeof number !== 'string') {
    return '';
  }

  return '' + number.replace(/\D/g, '');
};

const MaxBirthDate = () => {
  let birthDate = new Date();
  birthDate.setFullYear(birthDate.getFullYear() - 18);
  return formatDateISO(birthDate);
};

const MinBirthDate = () => {
  return '1900-01-01';
};

const US_PHONE_NUMBER_PATTERN = '(\\d{3}\\)[ ]\\d{3}[-]?\\d{4}';

export default CarnetHome;

export { CarnetHome, formatPhoneNumber, digitsOnly, MinBirthDate, MaxBirthDate, US_PHONE_NUMBER_PATTERN };
