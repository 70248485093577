import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../VehicleInfo.scss';
import ScrollableContent from './ScrollableContent';
import { TosStatusOption, getSecureContentHTML } from '../../providers/GarageProvider';
import { getLocaleSync } from '../../../../providers/languageProvider';
import Spinner from '../../Spinner';
import { findErrorMessage } from '../../../../util/i18n/i18nService';

export default class CarnetTos extends Component {
  constructor (props) {
    super(props);

    this.state = {
      htmlContent: null,
      isRadioDisabled: true,
      tosStatus: null
    };
  }

  static propTypes = {
    manifest: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    translator: PropTypes.object.isRequired,
    processing: PropTypes.bool,
    showAlert: PropTypes.func.isRequired
  };

  componentDidMount = async () => {
    const { translator, manifest, showAlert } = this.props;
    const { tosUrl, absolute = false } = manifest;

    const { locale } = getLocaleSync();

    try {
      const htmlContent = await getSecureContentHTML(tosUrl, locale, absolute);

      this.setState({ htmlContent });
    } catch (error) {
      const message = findErrorMessage(error, translator);
      showAlert(message);
    }
  };

  onContentScrolled = () => {
    this.setState({
      isRadioDisabled: false
    });
  };

  onSubmit = () => {
    const { tosStatus } = this.state;

    if ([TosStatusOption.ACCEPT, TosStatusOption.DECLINE].includes(tosStatus)) {
      this.props.onSubmit(tosStatus);
    }
  };

  render () {
    const { translator, processing } = this.props;

    return (
      <>
        <section className='d-flex flex-column'>
          <h1 className='h2 font-weight-normal mb-5'>
            {translator.t('tos_carnet_recollect.we-have-updated-our')}
            <strong>{translator.t('tos_carnet_recollect.vw-carnet-tos')}</strong>
          </h1>
          <h2 className='text-left my-3'>{translator.t('tos_carnet_recollect.terms-of-service')}</h2>
          <span className='text-secondary text-left'>
            {translator.t('tos_carnet_recollect.you-must-scroll-to-continue')}
          </span>
          <ScrollableContent onScrolled={this.onContentScrolled}>
            {this.state.htmlContent ? (
              <pre dangerouslySetInnerHTML={{ __html: this.state.htmlContent }} className='pre-tos' />
            ) : (
              <Spinner />
            )}
          </ScrollableContent>

          <span className='text-secondary text-left my-4'>
            {translator.t('tos_carnet_recollect.i-have-read-and-accept-carnet-tos')}
          </span>

          <article className='d-flex flex-row'>
            <input
              type='radio'
              id='radioTosAccept'
              name='scrollRadio'
              disabled={this.state.isRadioDisabled || processing}
              checked={TosStatusOption.ACCEPT === this.state.tosStatus}
              onClick={() => this.setState({ tosStatus: TosStatusOption.ACCEPT })}
            />
            <label htmlFor='radioTosAccept' className='acceptlbl'>
              {translator.t('tos_carnet.accept')}
            </label>
          </article>

          <article className='d-flex flex-row'>
            <input
              type='radio'
              id='radioTosDecline'
              name='scrollRadio'
              disabled={this.state.isRadioDisabled || processing}
              checked={TosStatusOption.DECLINE === this.state.tosStatus}
              onClick={() => this.setState({ tosStatus: TosStatusOption.DECLINE })}
            />
            <label htmlFor='radioTosDecline' className='acceptlbl'>
              {translator.t('tos_carnet.decline')}
            </label>
          </article>

          <button className='cwp center' onClick={this.onSubmit} disabled={!this.state.tosStatus || processing}>
            {translator.t('button.continue')}
          </button>
        </section>
      </>
    );
  }
}
