import invoke from './ServiceProxy';

const getVehicleEventHistory = async (operation, days) => {
  return invoke({ url: '/vehiclehistory', params: { operation, days } });
};

const deleteVehicleEvent = async vehicleEventId => {
  return invoke({ url: '/deletevehiclehistory', method: 'DELETE', params: { deleteEvent: true, vehicleEventId } });
};

export { getVehicleEventHistory, deleteVehicleEvent };
