import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../redux/actions';
import { Container, Row } from 'reactstrap';
import backArrow from '../../../assets/vehicleHealth/back-arrow.png';
import active from '../../../assets/vehicleHealth/active.png';
import active_trial from '../../../assets/vehicleHealth/active_trial.png';
import inactive from '../../../assets/vehicleHealth/inactive.png';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import Loader from '../common/loader';
import './ViewSXMDetails.scss';

class ViewSXMDetails extends Component {
  constructor () {
    super();
    this.translator = getLocaleTranslator();
  }

  componentDidMount () {
    this.props.actions.getSirisxmSubscriptionStatus();
  }

  subscriptionStatusText = statusValue => {
    let subscriptionStatus;

    if (statusValue === 'Active' || statusValue === 'Active Trial') {
      subscriptionStatus = this.translator.t('vehicleHealth.sxm.package_label');
    } else if (statusValue === 'Inactive') {
      subscriptionStatus = this.translator.t('vehicleHealth.sxm.expired_label');
    }

    return subscriptionStatus;
  };

  subscriptionStatusImg = statusValue => {
    let statusImg;

    if (statusValue === 'Active Trial') {
      statusImg = <img src={active_trial} className='status-activetrial' alt='Active Trial' />;
    } else if (statusValue === 'Inactive') {
      statusImg = <img src={inactive} className='status-inactive' alt='Inactive' />;
    } else if (statusValue === 'Active') {
      statusImg = <img src={active} className='status-active' alt='Active' />;
    }
    return statusImg;
  };

  render () {
    const siriusxmStatusData = this.props.siriusxmStatusData || '';
    return this.props.dataLoading ? (
      siriusxmStatusData && siriusxmStatusData.radioEquipped === true && (
        <div className='vhr-background'>
          <Container className='themed-container' fluid='sm'>
            <div>
              <div align='left' className='back-vhr' onClick={() => window.history.back()}>
                <img src={backArrow} className='arrow-style' alt='arrow' /> &nbsp;
                {this.translator.t('vehicleHealth.sxm.back_label')}
              </div>
              <div className='sirisXM-header'>
                <img src={siriusxmStatusData.logo.portal.cwp} className='sirisXM-logo' alt='SirisXM logo' />
              </div>
            </div>
            <div className='sirisXM-header'>
              {siriusxmStatusData.radioIdDetails.radioIdLabel} &nbsp;
              {siriusxmStatusData.radioIdDetails.radioIdValue}
            </div>

            <Row>
              <div className='audioSubDiv infotainmentSub'>
                <table className='tb-margin'>
                  <tbody>
                    <tr class='subscription-data-row'>
                      <td className='audio-text-td'>
                        {this.subscriptionStatusText(
                          siriusxmStatusData.audioSubscription.subscriptionStatus.statusValue
                        )}
                      </td>
                      <td className='audio-img-td'>
                        {this.subscriptionStatusImg(
                          siriusxmStatusData.audioSubscription.subscriptionStatus.statusValue
                        )}
                      </td>
                    </tr>
                    <tr class='subscription-data-row'>
                      <td className='audio-sub-td'>
                        {siriusxmStatusData.audioSubscription.subscriptionDetails &&
                          siriusxmStatusData.audioSubscription.subscriptionDetails.subscriptionValue}
                      </td>
                    </tr>
                    {siriusxmStatusData.audioSubscription.subscriptionStatus.statusValue === 'Active Trial' && (
                      <div>
                        <tr class='subscription-data-row'>
                          <td className='audio-text-td'>
                            {this.translator.t('vehicleHealth.sxm.trial_end_date_label')}
                          </td>
                        </tr>
                        <tr class='subscription-data-row'>
                          <td className='audio-sub-td'>
                            {siriusxmStatusData.audioSubscription.subscriptionEndingDetails.subscriptionEndingValue}
                          </td>
                        </tr>
                      </div>
                    )}
                  </tbody>
                </table>
              </div>

              {/* Infotainment Subscriptions */}
              {siriusxmStatusData.infotainmentSubscriptions &&
                siriusxmStatusData.infotainmentSubscriptions.map((infotainmentSubscription, index) => (
                  <div className='audioSubDiv infotainmentSub'>
                    <table className='tb-margin'>
                      <tbody>
                        <tr class='subscription-data-row'>
                          <td className='audio-text-td'>
                            {this.subscriptionStatusText(infotainmentSubscription.subscriptionStatus.statusValue)}
                          </td>
                          <td className='audio-img-td'>
                            {this.subscriptionStatusImg(infotainmentSubscription.subscriptionStatus.statusValue)}
                          </td>
                        </tr>
                        <tr class='subscription-data-row'>
                          <td className='audio-sub-td'>
                            {infotainmentSubscription.subscriptionDetails.subscriptionValue}
                          </td>
                        </tr>

                        {infotainmentSubscription.subscriptionStatus.statusValue === 'Active Trial' && (
                          <div>
                            <tr class='subscription-data-row'>
                              <td className='audio-text-td'>
                                {this.translator.t('vehicleHealth.sxm.trial_end_date_label')}
                              </td>
                            </tr>
                            <tr class='subscription-data-row'>
                              <td className='audio-sub-td'>
                                {infotainmentSubscription.subscriptionEndingDetails.subscriptionEndingValue}
                              </td>
                            </tr>
                          </div>
                        )}
                      </tbody>
                    </table>
                  </div>
                ))}
            </Row>

            <div align='center'>
              <p className='segment-msg'>{siriusxmStatusData.segmentMsg}</p>
              <div className='sxm-segmentMsg'>
                <a href={siriusxmStatusData.callToActionButton.urlForWebApp} target='_blank' rel='noopener noreferrer'>
                  <button type='button' className='sxmdetails-btn'>
                    {siriusxmStatusData.callToActionButton.buttonName}
                  </button>
                </a>
              </div>
            </div>
          </Container>
          <br />
          <br />
        </div>
      )
    ) : (
      <div>
        <Loader />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getSirisxmSubscriptionStatus: actions.getSirisxmSubscriptionStatus
      },
      dispatch
    )
  };
};

const mapStateToProps = state => {
  const { sirisxmSubscriptionStatus } = state;

  return {
    siriusxmStatusData: sirisxmSubscriptionStatus.siriusxmStatusData,
    dataLoading: sirisxmSubscriptionStatus.dataLoading
  };
};

export { ViewSXMDetails };
export default connect(mapStateToProps, mapDispatchToProps)(ViewSXMDetails);
