import React, { Component } from 'react';
import Faq from 'react-faq-component';
import '../faqCanada/faqCA.scss';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import goback from '../../../assets/faq/goback.png';
import { historyBack } from './faqCAhome';
import { setTitle } from '../../../providers/documentTitleProvider';

export default class AccountFaqCA extends Component {
  componentDidMount () {
    setTitle('document_titles.faq.account');
  }
  render () {
    let translator = getLocaleTranslator();

    return (
      <div className='faqCAheader' role='main' id='main-content'>
        <div id='exit-editing-link'>
          <span
            id='backButton'
            class='cwpBack'
            role='button'
            onClick={historyBack}
            onKeyPress={e => {
              e.preventDefault();
              if (e.key && (e.key === 'Enter' || e.key === ' ')) {
                historyBack(e);
              }
            }}
            tabIndex={0}
          >
            {translator.t('button.back')}
          </span>
        </div>

        <div className='message-header'>
          <div className='td-ms-header'>
            <h1>{translator.t('account_title')}</h1>
          </div>
        </div>
        <Faq data={data} aria-live='polite' />
      </div>
    );
  }
}

const data = {
  rows: [
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          How do I enroll in VW Car-Net?{' '}
        </div>
      ),
      content: ` <span class='mdlyr'>Model Year 2022+:</span> To start your Volkswagen Car-Net® subscription, use the mobile app, or call 1-833-435-1011 for assistance. Download the Volkswagen Car-Net® mobile app from the iTunes® App Store or Google Play™. Open the app, go to login and enter your email address. If you do not already have a Volkswagen ID, you will be prompted to create a password and complete the email verification. Once you have logged in with your Volkswagen ID, complete your customer profile information and create your PIN. (Note: This 4-digit PIN will be required to access your vehicle from your Garage page). </br>
        `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          How do I log into the Volkswagen Car-Net® web portal or the Volkswagen Car-Net® Mobile App?{' '}
        </div>
      ),
      content: `<span class='mdlyr'>Model Year 2022+:</span> Once you have enrolled into VW Car-Net Remote Access, 5-year no charge package, you will login using your VW ID. You should have already created this during the enrollment process.</br>
      <ol>  
        <li>Navigate to <a href="https://www.vw.ca/en/why-vw/innovation-and-technology/car-net.html" target="_blank" rel="noopener noreferrer">www.vw.ca/en/why-vw/innovation-and-technology/car-net.html</a></li>
        <li>Click on Login in the top right upper corner of the page.</li>
        <li>Enter the email and password you originally created for your Volkswagen ID.</li>
        <li>You should now see your vehicle in your Garage.</li>
        <li>Click on your vehicle and enter your 4-digit PIN.</li>
      </ol></br>
        If your vehicle does not appear, reference the FAQ question to Add Vehicle to Garage. To download the mobile app visit the iTunes® App Store or Google Play™

      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          After I enroll, how do I add a vehicle to my Garage/why is my Garage empty?{' '}
        </div>
      ),
      content: `<span class='mdlyr'>Model Year 2022+:</span> You may need to add your vehicle if you have not enrolled at the time of sale. To do so, login to Volkswagen Car-Net® and you will go right to your Garage page.</br>
          If the vehicle already listed in Garage is greyed out, select the vehicle and click on 'Add Vehicle' button
          If the vehicle is not listed, start the 'In Vehicle' enrolment in the app. 
          Locate the Car-Net enrolment App on the head unit and then click “Start Process”
          The 'In Vehicle’ app will display a QR scan code on the head unit screen
          Using your mobile phone, on the Garage page click on 'Add Vehicle'
          Click the button to scan QR code in mobile phone app
          Scan the QR code displayed on vehicle head unit using the mobile phone app
          Please scroll through Terms of Service and Privacy Policy to accept.
          Review and submit for enrolment on the summary page.
          Now your vehicle will appear in the Garage enabled. Select it and enter/setup your PIN.
          </br>
     
      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          How do I cancel my VW Car-Net subscription?
        </div>
      ),
      content: `<span class='mdlyr'>Model Year 2022</span> You may cancel your service at any time by speaking to Volkswagen Car–Net® Response Center Specialist by pressing the “i-Call” button in the cabin of your vehicle, or dialing our Volkswagen Car–Net® Response Center at 1-833-435-1013. You can also cancel services through the Volkswagen Car-Net® web portal or via the mobile app. First login and go to your virtual garage, then click on the three dots next to the vehicle you would like to edit the subscription of, this will bring you to the “edit plans” page. Once on this page you can choose which plans you would like to be unenroled from. If you would like your vehicle to be unenroled from all Volkswagen Car-Net® services select the “Unenrol Vehicle” option. You’ll receive a confirmation of your canceled services by email.
      `
    },

    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          Is my Volkswagen Car-Net subscription transferable?{' '}
        </div>
      ),
      content: `<span class='mdlyr'>Model Year 2022+:</span> Your Volkswagen Car-Net subscription is non-transferable to another vehicle or owner. If you purchase a new vehicle or sell your vehicle, please contact the Volkswagen Car-Net Response Centre at 1-833-435-1013 to discontinue your old service and/or call 1-833-435-1011 to activate your new service. 
      
      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Who do I contact for questions regarding Volkswagen Car-Net?{' '}
        </div>
      ),
      content: ` <span class='mdlyr'>Model Year 2022+:</span> +: You may speak to a Volkswagen Car-Net® Response Centre Specialist by pressing the “i-Call” button in the cabin of your vehicle, or directly calling the Volkswagen Car-Net Response Center at 1-833-435-1013.
      
      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Will Car-Net work while I’m visiting USA?{' '}
        </div>
      ),
      content: ` <span class='mdlyr'>Model Year 2022+:</span> Yes, all of your subscribed Volkswagen Car-Net® services will work while in the USA. Please remember that your mobile phone will need a data plan that covers USA roaming when using the remote services.   `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Will Car-Net work while I’m visiting Mexico?{' '}
        </div>
      ),
      content: ` <span class='mdlyr'>Model Year 2022+:</span> All of your subscribed remote services will work while in Mexico; however, none of the voice calls will work (Information call, Emergency Call or Roadside Call). If you attempt to make a call, you will hear a message saying that the call cannot be completed as dialed. Please remember that your mobile phone will need a data plan that covers Mexico when using the remote services.  `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          How do I update my account information?{' '}
        </div>
      ),
      content: ` 
      <span class='mdlyr'>Model Year 2022+:</span> : You can update your account information in the mobile app by tapping the in the top left corner. You can also update account information through the Volkswagen Car-Net® web portal on www.vw.ca/en/innovation-and-technology/car-net.html by clicking on the Account link in the top navigation bar. Below are a few key things to note when updating these items: Change/update email address – You will be redirected to Volkswagen ID portal to make any change to your email address.</br>
        <ol> 
          <li>Login to Volkswagen ID Portal with credentials (email ID and password)</li>
          <li>Change email and confirm change with password</li>
          <li>You will be sent an email confirmation to old email address or you can select to receive an SMS to your mobile phone number on your Volkswagen Car-Net® Account.</li>
          <li>Confirm new email address in new account by clicking link in either email or SMS.</li>
          <li>Forgot Password/Update Password</li>
          <li>Go to Volkswagen ID Portal and enter your email ID</li>
          <li>Click on “forgot password”</li>
          <li>Click on “resend reset link”</li>
          <li>Email will be sent for you to create a new password</li>
          <li>You can also speak to a Volkswagen Car-Net® Response Centre Specialist by dialing 1-833-435-1013.</li>
        </ol>`
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          What smartphones are compatible with the VW Car-Net mobile app?{' '}
        </div>
      ),
      content: ` <b>Model Year 2014-2022+: </b>TThe Volkswagen Car-Net® mobile app is available on the iPhone® running iOS 12.0 and higher and Android 8.0 and higher. It can be downloaded directly from the iTunes® App store or from Google Play™.
       `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          How does my car transmit data?{' '}
        </div>
      ),
      content: ` <span class='mdlyr'>Model Year 2022+:</span> Your vehicle uses a wireless communication device similar to what is found in cellular phone technology. `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Is my Roadside Assistance Program included in my Car-Net subscription?{' '}
        </div>
      ),
      content: ` <span class='mdlyr'>Model Year 2022+:</span> Your vehicle may come with a 24-Hour Roadside Assistance Service (provided by a third party) for a limited period. Having VW Car-Net allows you to reach a Roadside Assistance provider at the touch of the “Wrench” button. Depending on your vehicle’s coverage and the service you require, the Roadside Assistance provider may charge you for the service provided. Your vehicle location will also be transmitted to the Roadside Assistance provider in the event you are unsure where you are and need assistance. 
       `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          Is my Sirius subscription tied to my Car-Net account?{' '}
        </div>
      ),
      content: `<span class='mdlyr'>Model Year 2022+:</span>  No, your Sirius subscription is separate from your Car-Net subscription. `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Can I use Car-Net to locate my stolen vehicle?{' '}
        </div>
      ),
      content: ` <span class='mdlyr'>Model Year 2022+:</span> As long as you have an active Volkswagen Car-Net® account, we can work with your local police department to locate your Volkswagen vehicle. Always contact law enforcement first. For your safety, we don’t provide stolen vehicle location information directly to you. Call the Volkswagen Car-Net® response center at 1-833-435-1013.</br>
      NOTE: The Car-Net service will be deactivated once the police report is received and the Stolen Vehicle Location services are activated. This is for customer safety purposes. Once the vehicle has been recovered, the account will be re-activated.  `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          What do the three Car-Net buttons in my vehicle do?{' '}
        </div>
      ),
      content: `<span class='mdlyr'>Model Year 2022+:</span> The three buttons in your vehicle can help keep you connected to VW Car-Net, Roadside Assistance, and emergency services. • “i-Call” button - With a VW Safe and Secure paid subscription you have access to the “i-Call” button. That connects you to a VW Car-Net agent who can assist in any billing questions you have,send directions to your VW vehicle if equipped with a navigation system, or provide you account information. • “S.O.S.” Button - With the VW Safe and Secure paid subscription you have access to the “S.O.S” button. Helping connect you with emergency services in the event you need medical assistance, or see an accident. • “Wrench” Button - With the VW Remote Access package you have access to the “Wrench” button. This button connects you directly to Roadside assistance, and provides your location to the agent in the event you are unsure where you are located. `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          How do I get the registration code to verify ownership again?
        </div>
      ),
      content: `  <span class='mdlyr'>Model Year 2022+:</span> To get the registration code, please check your email or call the Volkswagen Car-Net® Response Centre at 1-833-435-1013. `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          I set up my Volkswagen Car-Net Subscription with the Car-Net Response Center but don’t see a vehicle in my
          garage,what do I do?
        </div>
      ),
      content: `  <span class='mdlyr'>Model Year 2022+:</span> Press the “Add Vehicle” button in the Car-Net Mobile App and follow instructions. If your vehicle is still not in your virtual garage call the VW Car-Net Response Center at 1-833-435-1013. 
      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          How do I create an additional driver on my account?{' '}
        </div>
      ),
      content: `  <span class='mdlyr'>Model Year 2022+:</span> Go to the account management page ( ) on the mobile app. Then press “Add Additional Driver” and fill in the required information. Once you are finished, press “Send invitation.” The additional driver should receive an invite shortly. 
      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          What are the differences between roles on my account?{' '}
        </div>
      ),
      content: `  <span class='mdlyr'>Model Year 2022+:</span> Primary Drivers have access to all settings and features for which they are eligible. Additional drivers can use remote features and POIs but cannot edit account information. However, the primary driver can grant them access to certain things like Guardian Services and Vehicle Health Reports. 
      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          What if someone who isn’t on my account makes an i-Call from my vehicle?{' '}
        </div>
      ),
      content: `  <span class='mdlyr'>Model Year 2022+:</span> If they request services that do not require ID verification, then the services will be delivered. If they ask for services that need ID verification, and cannot confirm ID, then the services are not delivered. Anything related to the account or personal information of the subscriber requires ID verification of the caller.


      `
    },

    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          Can I add Remote Start to my older VW Vehicle that has Car-Net?{' '}
        </div>
      ),
      content: ` <span class='mdlyr'>Model Year 2022+:</span> No, only model year 2022 or newer may be equipped with Remote Start using the VW Car-Net mobile app. Not all trims come equipped with the vehicle Remote Start feature - please check your vehicle guide to see if Remote Start is included  
       `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Can I use remote start more than two times in a row?{' '}
        </div>
      ),
      content: ` <span class='mdlyr'>Model Year 2022+:</span> The maximum number of Remote Start commands to execute is two times while the engine is not being used. Once you start/drive the vehicle and turn it off, this will reset and you will be able to use Remote Start again. 
       `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Which MY21 and MY21.5 Models and Trims are equipped with an MIB3 infotainment system?{' '}
        </div>
      ),
      content: `
      <ol> 
          <li>Jetta SEL, SEL Premium</li></br>
          <li>Jetta GLI Autobahn</li></br>
          <li>GTI SE, Autobahn</li></br>
          <li>Arteon – all trims</li></br>
          <li>Tiguan SE, SE R-Line Black, SEL, SEL Premium R-Line</li></br>
          <li>Atlas Cross Sport SE, SE w/Tech, SE w/Tech R-Line, SEL, SEL R-Line, SEL Premium, SEL Premium R-Line</li></br>
          <li>Atlas SE, SE w/Tech, SE w/Tech R-Line, SEL, SEL R-Line, SEL Premium, SEL Premium R-Line</li>
        </ol>
       `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Is an email address required to enroll in VW Car-Net®?
        </div>
      ),
      content: `Yes. In order to enroll in VW Car-Net® an email address is required.`
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          How do I enroll in VW Car-Net® if I don’t have a smartphone?
        </div>
      ),
      content: `For all MY21 models except ID.4 - Proceed to your vehicle and press the “i-button” on the overhead console. A VW Car-Net® Response Center Specialist will then assist you with getting enrolled in VW Car-Net® Remote Access. If you are not near your vehicle you can go to vw.com/carnet, review and accept the Car-Net® Terms of Service, and proceed to your virtual garage.`
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          What are the VW Car-Net® Remote Access EV <sup>1</sup> features?
        </div>
      ),
      content: `
      <ol> 
        <li>Mobile App</li></br>
        <li>Remote Battery Charge</li></br>
        <li>Remote Climate Controls</li></br>
        <li>Find Charging Stations</li></br>
        <li>Remote Charging Profiles and Timers</li></br>
        <li>Parking Info</li>
        </ol>
      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          How do I enroll in VW Car-Net®?
        </div>
      ),
      content: `Download the VW Car-Net® app to your mobile device from the Apple Store or Google Play Store *OR enroll at vw.com/carnet/login then Accept the Car-Net Terms of   Service to finalize enrollment.`
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Do I have to have an email address to enroll in VW Car-Net®?
        </div>
      ),
      content: `Yes, you need to have an email address in order to enroll in VW Car-Net®.`
    }
  ]
};

export { AccountFaqCA };
