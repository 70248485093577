import React, { Component } from 'react';
import Faq from 'react-faq-component';
import '../faqCanada/faqCA.scss';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import goback from '../../../assets/faq/goback_fr.png';
import { historyBack } from './faqCAhome';
import { setTitle } from '../../../providers/documentTitleProvider';

export default class AccountFaqCA extends Component {
  componentDidMount () {
    setTitle('document_titles.faq.account');
  }
  render () {
    let translator = getLocaleTranslator();

    return (
      <div className='faqCAheader' role='main' id='main-content'>
        <div className='goback'>
          <span onClick={historyBack}>
            <img src={goback} alt='viewfaq' width='100'></img>
          </span>
        </div>
        <div className='message-header'>
          <div className='td-ms-header'>
            <h1>{translator.t('account_title')}</h1>
          </div>
        </div>
        <Faq data={data} />
      </div>
    );
  }
}

const data = {
  rows: [
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Comment puis-je m’inscrire à Car-NetMD de Volkswagen?{' '}
        </div>
      ),
      content: ` <span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span> Pour commencer votre abonnement à Car-NetMD de Volkswagen, utilisez l’application mobile ou composez le 1 833-435-1011 pour obtenir de l’aide. Téléchargez l’application mobile Car-NetMD de Volkswagen dans l’App Store d’Apple ou dans Google PlayMC. Ouvrez l’application, ouvrez une session et entrez votre adresse électronique. Si vous n’avez pas d’identifiant Volkswagen, vous serez invité à créer un mot de passe et à effectuer la vérification de l’adresse courriel. Après avoir ouvert une session avec votre identifiant Volkswagen, remplissez les renseignements de votre profil client et créez votre NIP. (Remarque : ce NIP à quatre chiffres sera nécessaire pour accéder à votre véhicule à partir de votre page Garage.) </br>
        `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          Comment puis-je me connecter au portail Web ou à l’application mobile Car-NetMD de Volkswagen?{' '}
        </div>
      ),
      content: `<span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span> Une fois inscrit au programme d’accès à distance sans frais de quatre ans de Car-NetMD de Volkswagen, vous pourrez ouvrir une session avec votre identifiant Volkswagen. Vous devriez l’avoir créé pendant le processus d’inscription.</br>
          <ol> 
            <li>Visitez la page <a href="https://www.vw.ca/fr/pourquoi-vw/innovation-et-technologie/car-net.html" target="_blank" rel="noopener noreferrer">www.vw.ca/fr/pourquoi-vw/innovation-et-technologie/car-net.html</a></br>
            <li>Cliquez sur Connexion dans le coin supérieur droit de la page.</li>
            <li>Entrez le courriel et le mot de passe que vous avez initialement créé pour votre identifiant Volkswagen.</li>
            <li>Vous devriez maintenant voir votre véhicule dans votre garage.</li>
            <li>Cliquez sur votre véhicule et entrez votre NIP à quatre chiffres.</li>
          </ol>
          Si votre véhicule n’apparaît pas, consultez la FAQ pour savoir comment ajouter un véhicule au garage. Pour télécharger l’application mobile, visitez l’App Store d’Apple ou Google PlayMC.
      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Après mon inscription, comment puis-je ajouter un véhicule à mon garage? Pourquoi mon garage est-il vide?{' '}
        </div>
      ),
      content: `<span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span> Vous devrez peut-être ajouter votre véhicule si vous ne vous étiez pas inscrit au moment de la vente. Pour ce faire, ouvrez une session dans Car-NetMD de Volkswagen et accédez directement à la page de votre garage.</br>
          Si le véhicule déjà affiché dans le garage est grisé, sélectionnez-le et cliquez sur « Add Vehicle » (ajouter le véhicule).
          Si le véhicule ne figure pas dans la liste, commencez l’inscription du véhicule au moyen de l’application. </br>
          Repérez l’application d’inscription Car-Net sur le panneau de commande, puis cliquez sur « Start Process » (commencer).
          L’application dans le véhicule affiche un code de balayage QR à l’écran du panneau de commande.
          À l’aide de votre téléphone cellulaire, cliquez sur « Add Vehicle » (ajouter un véhicule) sur la page du garage.
          Cliquez sur le bouton pour balayer le code QR dans l’application mobile.</br>
          Balayez le code QR affiché sur le panneau de commande du véhicule à l’aide de l’application mobile.
          Veuillez faire défiler les Conditions d’utilisation et la Politique de confidentialité pour les accepter.
          Passez les renseignements en revue et soumettez votre inscription sur la page sommaire.
          Votre véhicule sera alors activé dans le Garage. Sélectionnez-le et entrez/configurez votre NIP.
      
     
      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Comment puis-je annuler mon abonnement à Car-NetMD de Volkswagen?
        </div>
      ),
      content: `<span class='mdlyr'>Model Year 2022</span> Vous pouvez annuler votre abonnement en tout temps en communiquant avec un spécialiste du Centre d’appels Car-NetMD de Volkswagen en appuyant sur le bouton « i-Call » dans l’habitacle de votre véhicule ou en appelant le Centre d’appels Car-NetMD de Volkswagen en composant le 1-833-435-1013. Vous pouvez également annuler des services au moyen du portail Web ou de l’application mobile Car-NetMD de Volkswagen. Ouvrez d’abord une session et rendez-vous à votre garage virtuel, puis cliquez sur les trois points à côté du véhicule dont vous souhaitez modifier l’abonnement. Vous serez ensuite redirigé vers la page « Edit Plans » (modifier les forfaits). Une fois sur cette page, vous pouvez sélectionner les forfaits que vous souhaitez annuler. Si vous souhaitez vous désinscrire de tous les services Car-NetMD de Volkswagen, sélectionnez l’option « Unenrol Vehicle » (désinscrire le véhicule). Vous recevrez une confirmation des services annulés par courriel.
      `
    },

    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          Mon abonnement Car-NetMD de Volkswagen est-il transférable?{' '}
        </div>
      ),
      content: `<span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span> Années-modèles 2022 et années ultérieures  : Votre abonnement à Car-Net de Volkswagen n’est pas transférable à un autre véhicule ou propriétaire. Si vous achetez un véhicule neuf ou vendez votre véhicule, veuillez communiquer avec le Centre d’appels Car-Net de Volkswagen au 1-833-435-1013 pour mettre fin à votre ancien service ou composer le 1-833-435-1011 pour activer votre nouveau service.
      
      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Avec qui dois-je communiquer pour toute question au sujet de Car-NetMD de Volkswagen?
        </div>
      ),
      content: ` <span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span> +: Vous pouvez parler à un spécialiste du Centre d’appels Car-NetMD de Volkswagen en appuyant sur le bouton « i-Call » dans l’habitacle de votre véhicule ou en appelant directement le Centre d’appels Car-Net de Volkswagen au 1-833-435-1013.
      
      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Les services Car-Net de Volkswagen fonctionneront-ils pendant mon séjour aux États-Unis?{' '}
        </div>
      ),
      content: ` <span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span> Oui, tous les services Car-NetMD de Volkswagen fonctionneront pendant votre séjour aux États-Unis. N’oubliez pas qu’un forfait de données permettant l’itinérance aux États-Unis sera nécessaire pour utiliser les services à distance de votre cellulaire. `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Les services Car-Net de Volkswagen fonctionneront-ils pendant mon séjour au Mexique?{' '}
        </div>
      ),
      content: ` <span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span> Tous vos services à distance fonctionneront pendant votre séjour au Mexique, mais aucun appel vocal ne pourra être effectué (appel d’information, appel d’urgence ou assistance routière). Si vous tentez de passer un appel, vous entendrez un message indiquant qu’il est impossible de rejoindre le numéro composé. N’oubliez pas que votre téléphone mobile aura besoin d’un forfait de données qui couvre le Mexique lorsque vous utilisez les services à distance. `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Comment puis-je mettre à jour mon profil?{' '}
        </div>
      ),
      content: ` 
      <span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span> : Vous pouvez mettre à jour les renseignements de votre compte dans l’application mobile en touchant le coin supérieur gauche. Vous pouvez également mettre à jour les renseignements de votre compte sur le portail Web Car-NetMD de Volkswagen à www.vw.ca/en/innovation-and-technology/car-net.html en cliquant sur le lien « Account » (compte) dans la barre de navigation supérieure. Voici quelques éléments clés à noter lors de la mise à jour de ces éléments : Modifier/mettre à jour l’adresse électronique. Vous serez redirigé vers le portail d’identification Volkswagen pour apporter tout changement à votre adresse électronique.</br>
      
          <li>Connectez-vous au portail d’identification Volkswagen à l’aide de votre identifiant et de votre mot de passe.</li></br>
          <li>Changez l’adresse électronique et confirmez le changement en entrant votre mot de passe.</li></br>
          <li>Selon votre choix, vous recevrez une confirmation par courriel à l’ancienne adresse électronique ou un SMS au numéro de téléphone mobile enregistré dans votre compte Car-NetMD.</li></br>
          <li>Confirmez la nouvelle adresse électronique dans le nouveau compte en cliquant sur le lien dans le courriel ou le SMS.</li></br>
          <li>Mot de passe oublié/mise à jour du mot de passe</li></br>
          <li>Accédez au portail d’identification Volkswagen et entrez votre adresse électronique.</li></br>
          <li>Cliquez sur « forgot password » (mot de passe oublié).</li></br>
          <li>Cliquez sur « resend reset link » (renvoyer le lien de réinitialisation).</li></br>
          <li>Un courriel vous sera envoyé pour créer un nouveau mot de passe.</li></br>
          <li>Vous pouvez également communiquer avec un spécialiste du Centre d’appels Car-NetMD de Volkswagen en composant le 1-833-435-1013.</li></br> `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Quels téléphones intelligents sont compatibles avec l’application mobile Car-NetMD de Volkswagen?{' '}
        </div>
      ),
      content: ` <b>Model Year 2014-2022+: </b>L’application mobile Car-NetMD de Volkswagen est offerte sur iOS 12.0 et Android 8.0 ainsi que leurs versions plus récentes. Vous pouvez la télécharger directement dans l’App Store d’Apple ou dans Google PlayMC.
       `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Comment mon véhicule transmet-il les données?
        </div>
      ),
      content: ` <span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span> : Votre véhicule utilise un appareil de communication sans fil semblable aux téléphones cellulaires. `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Mon programme d’assistance routière est-il inclus dans mon abonnement à Car-NetMD de Volkswagen?{' '}
        </div>
      ),
      content: ` <span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span> Votre véhicule peut être assorti d’un service d’assistance routière 24 heures (fourni par un tiers) pendant une période limitée. Les services Car-NetMD de Volkswagen vous permettent de joindre un fournisseur d’assistance routière en appuyant sur le bouton de clé anglaise. Selon la couverture de votre véhicule et le service dont vous avez besoin, le fournisseur d’assistance routière peut vous facturer le service fourni. L’emplacement de votre véhicule sera également transmis au fournisseur d’assistance routière si vous n’êtes pas certain de l’endroit où vous vous trouvez et avez besoin d’aide.
       `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          Mon abonnement à Sirius est-il lié à mon compte Car-NetMD de Volkswagen?{' '}
        </div>
      ),
      content: `<span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span> Non, votre abonnement à SiriusXM est distinct de votre abonnement à Car-Net. `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Puis-je utiliser Car-NetMD pour localiser mon véhicule volé?{' '}
        </div>
      ),
      content: ` <span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span> Tant que vous avez un compte Volkswagen Car-NetMD actif, nous pouvons collaborer avec votre service de police local pour trouver votre véhicule Volkswagen. Communiquez toujours avec les forces de l’ordre en premier lieu. Pour votre sécurité, nous ne vous fournissons pas directement de renseignements sur l’emplacement du véhicule volé. Appelez le Centre d’appels Car-NetMD de Volkswagen au 1-833-435-1013.</br>
      REMARQUE : Afin d’assurer la sécurité des clients, le service Car-NetMD de Volkswagen sera désactivé une fois le rapport de police reçu et les services de localisation du véhicule volé activés. Une fois le véhicule récupéré, le compte sera réactivé.
       `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          À quoi servent les trois boutons Car-NetMD de mon véhicule?{' '}
        </div>
      ),
      content: `<span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span> Les trois boutons de votre véhicule peuvent vous aider à rester connecté au système Car-NetMD de Volkswagen, à l’Assistance routière et aux services d’urgence.</br>
          • Bouton « i-Call » – Vos services Car-NetMD de Volkswagen vous permettent d’utiliser le bouton « i-Call ». Il vous permet de vous connecter à un agent Car-NetMD de Volkswagen qui peut répondre à vos questions de facturation, envoyer des directives à votre véhicule Volkswagen s’il est équipé d’un système de navigation ou vous fournir des renseignements sur votre compte. </br>
          • Bouton S.O.S. – Vos services Car-NetMD de Volkswagen vous permettent d’utiliser le bouton S.O.S. Appuyez sur ce bouton pour communiquer avec les services d’urgence si vous avez besoin de soins médicaux ou si vous êtes témoin d’un accident. </br>
          • Bouton de clé anglaise – Vos services Car-NetMD de Volkswagen vous permettent d’utiliser le bouton de clé anglaise. Ce bouton vous relie directement à l’Assistance routière et indique votre emplacement au préposé au cas où vous ne seriez pas certain de l’endroit où vous vous trouvez.

       `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Comment puis-je obtenir le code d’inscription pour vérifier de nouveau la propriété?
        </div>
      ),
      content: `  <span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span> Pour obtenir le code d’inscription, veuillez consulter votre courriel ou communiquer avec le Centre d’appels Car-NetMD de Volkswagen au 1-833-435-1013. `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          J’ai configuré mon abonnement Car-NetMD de Volkswagen avec le Centre d’appels Car-NetMD, mais je ne vois pas
          de véhicule dans mon garage. Que dois-je faire?
        </div>
      ),
      content: `  <span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span> Appuyez sur le bouton « Add Vehicle » (ajouter un véhicule) dans l’application mobile Car-NetMD de Volkswagen et suivez les instructions. Si votre véhicule n’est toujours pas dans votre garage virtuel, appelez le Centre d’appels Car-NetMD de Volkswagen au 1-833-435-1013. 
      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Comment ajouter un conducteur supplémentaire à mon compte?{' '}
        </div>
      ),
      content: `  <span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span> : Allez à la page de gestion des comptes ( ) de l’application mobile. Appuyez ensuite sur « Add Additional Driver » (ajouter un autre conducteur) et inscrivez les renseignements requis. Lorsque vous avez terminé, appuyez sur « Send invitation » (envoyer l’invitation). Le conducteur supplémentaire devrait recevoir une invitation peu après.
      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          Quelles sont les différences entre les rôles sur mon compte?
        </div>
      ),
      content: `  <span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span> Les conducteurs principaux ont accès à tous les paramètres et caractéristiques auxquels ils sont admissibles. Les conducteurs ajoutés peuvent utiliser les fonctions à distance et les points d’intérêt, mais ne peuvent pas modifier les renseignements du compte. Toutefois, le conducteur principal peut leur donner accès à certains éléments comme les services de surveillance et les bilans de santé du véhicule.
      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Que se passe-t-il si quelqu’un qui n’est pas inscrit à mon compte utilise le bouton i-Call de mon véhicule?{' '}
        </div>
      ),
      content: `  <span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span> Actuellement, Rogers Communications est le tiers fournisseur du service de point d’accès sans fil offert dans votre véhicule. Les forfaits de point d’accès sans fil prépayés de Rogers peuvent être achetés sur la page « Buy Plans » (acheter un forfait) du site www.vw.ca/en/innovation-and-technology/car-net.html ou avec l’application mobile Car-NetMD de Volkswagen. Veuillez noter que vous devez vous inscrire à Car-NetMD pour acheter des forfaits WiFi.


      `
    },

    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          Puis-je ajouter le démarrage à distance à mon ancien véhicule Volkswagen avec Car-NetMD?{' '}
        </div>
      ),
      content: ` <span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span> Non, seuls les modèles 2022 ou plus récents peuvent utiliser le démarrage à distance de l’application mobile Car-NetMD de Volkswagen. Le démarrage à distance n’est pas inclus avec tous les modèles. Veuillez consulter votre guide de commande pour savoir si c’est le cas de votre véhicule. 
       `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Puis-je utiliser le démarrage à distance plus de deux fois de suite?{' '}
        </div>
      ),
      content: ` <span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span> Le nombre maximal de commandes de démarrage à distance consécutives sans sollicitation du moteur est de deux. Lorsque vous démarrez, conduisez et arrêtez le véhicule, le système se réinitialise et vous pouvez utiliser de nouveau le démarrage à distance.
       `
    }
  ]
};

export { AccountFaqCA };
