import Axios from 'axios';

import getCwpMeta from './cwpMetaProvider';
import { setPreferredLanguage } from '../util/i18n/i18nService';
// too many 'language-ee' words, different variable word for clarity
const translationSetPreferredLanguage = setPreferredLanguage;

// cached meta data
let metaData = null;

// trumps localeData if set
let vehicleLocaleData = null;
// set when user login happens
let localeData = null;

// Defaults if everything goes terrible
let DEFAULT_LANGUAGE = 'en';
let DEFAULT_COUNTRY = 'us';

// Local Storage Jazz, user context data
let CONTEXT_LOCALE_KEY = 'CWP_CONTEXT_LOCALE';

let storage = window && window.localStorage;

const storeContextInLocalStorage = () => {
  if (vehicleLocaleData) {
    let storeStr = JSON.stringify(vehicleLocaleData);
    storage.setItem(CONTEXT_LOCALE_KEY, storeStr);
  }
};

const getContextFromLocalStorage = () => {
  let strThing = storage.getItem(CONTEXT_LOCALE_KEY);
  let toReturn = null;
  try {
    toReturn = JSON.parse(strThing);
  } catch (e) {
    console.log('unable to parse locale context from locale storage');
  }
  if (toReturn && toReturn.userLanguage) {
    vehicleLocaleData = toReturn;
  }
  return toReturn;
};

// Vehicle Language Context
// if we are in the context of a vehicle (after entering PIN), we go w/the country code of that vehicle
const validateLangCodeAgainstCountryCode = (langCode = '', countryCode = '') => {
  /*
    {
      "code": "ca",
      "label": "Canada",
      "stringValues": ["canada", "ca", "can"],
      "languages": [
        { "code": "en", "label": "English", "stringValues": ["english", "en", "anglais", "anglaise"] },
        { "code": "fr", "label": "French", "stringValues": ["french", "fr", "français", "française"] }
      ]
    },
  */
  let langCodeToValidate = (langCode && langCode.toLowerCase()) || '';
  let countryCodeToValidate = (countryCode && countryCode.toLowerCase()) || '';
  let foundCountryObject =
    (Array.isArray(metaData) && metaData.filter(item => item.code === countryCodeToValidate)[0]) || null;
  if (!foundCountryObject) {
    return false;
  }
  let isValid =
    (foundCountryObject &&
      Array.isArray(foundCountryObject.languages) &&
      foundCountryObject.languages.filter(langItem => langItem.code === langCodeToValidate)[0]) ||
    null;
  if (isValid) {
    return true;
  }
  return false;
};

// assumes the country exists in the meta
const getDefaultLangCodeForCountry = (countryCode = '') => {
  let countryCodeToValidate = (countryCode && countryCode.toLowerCase()) || '';
  let foundCountryObject = Array.isArray(metaData) && metaData.filter(item => item.code === countryCodeToValidate)[0];
  return (foundCountryObject && foundCountryObject.languages[0] && foundCountryObject.languages[0].code) || '';
};

const setLocaleToVehicleContext = (countryCode = '', vehicleThing = {}) => {
  console.log('setLocaleToVehicleContext() >> ', countryCode);

  if (!countryCode) {
    countryCode = vehicleThing['country'] || '';
  }

  // first validate the language code
  let _countryCode = String(countryCode).toLowerCase();
  let isValidCountryCode = (Array.isArray(metaData) && metaData.filter(item => item.code === _countryCode)[0]) || null;
  if (!isValidCountryCode) {
    console.log(
      'languageProvider >> setLocaleToVehicleContext >> not a valid country code, not doing anything with that'
    );
    return;
  }

  // now validate the current language code against the country
  let userLanguageToVerify = (localeData && localeData.userLanguage) || null;
  let isValidCombo = validateLangCodeAgainstCountryCode(userLanguageToVerify, _countryCode);
  if (isValidCombo) {
    vehicleLocaleData = {
      userLanguage: userLanguageToVerify,
      userCountry: _countryCode
    };
  } else {
    // not a valid combo, select a default langauge for that country
    let defaultLangForCountry = getDefaultLangCodeForCountry(_countryCode);
    vehicleLocaleData = {
      userLanguage: defaultLangForCountry || DEFAULT_LANGUAGE,
      userCountry: _countryCode || DEFAULT_COUNTRY
    };
  }
  // now update the translator
  translationSetPreferredLanguage(vehicleLocaleData);
  // return the final result
  storeContextInLocalStorage();
  return vehicleLocaleData;
};

const clearVehicleLocaleContext = () => {
  console.log('languageProvider >> clearVehicleLocaleContext () >> ');
  if (!vehicleLocaleData || !localeData) {
    console.log('context not set, do nothing');
    return;
  }

  let needToReload = vehicleLocaleData && vehicleLocaleData['userCountry'] !== localeData['userCountry'];
  needToReload = needToReload || (vehicleLocaleData && vehicleLocaleData['userCountry'] !== localeData['userLanguage']);

  vehicleLocaleData = null;
  storage.removeItem(CONTEXT_LOCALE_KEY);
  // reload location to re-establish previous context
  if (needToReload) {
    let newLocation = window.location.origin + window.location.pathname + window.location.hash;
    if (window.location.href === newLocation) {
      return window.location.reload();
    }
    return (window.location = newLocation);
  }
};

const setLocale = langCode => {
  if (localeData && localeData.userLanguage && localeData.userLanguage === langCode) {
    return Promise.reject();
  }

  // persist it to the user session on the nodejs server
  let axioConfig = {
    url: 'cwpmeta/locale',
    method: 'POST',
    data: {
      language: langCode
    }
  };
  return Axios(axioConfig)
    .then(response => {
      console.log('successfully persisted language selection to server session');
      console.log(JSON.stringify(response));
      let responseData = (response && response.data) || {};
      localeData = { ...responseData };
      let { userLanguage, userCountry } = localeData;
      // set in vehicleContext if present
      if (vehicleLocaleData && vehicleLocaleData.userLanguage) {
        vehicleLocaleData.userLanguage = userLanguage;
        vehicleLocaleData.userCountry = userCountry;
        storeContextInLocalStorage();
      }
      // set language code in the i18nService
      translationSetPreferredLanguage(localeData);
      return localeData;
    })
    .catch(err => {
      console.log('unable to persist language selection to server session');
      return Promise.reject();
    });
};

let localePromise = null;

const makeLocaleHttpRequest = () => {
  if (localePromise) {
    return localePromise;
  }
  // attempt to get it from node side
  let axioConfig = {
    url: 'cwpmeta/locale',
    method: 'GET'
  };
  localePromise = Axios(axioConfig);
  return localePromise;
};

const getLocaleSync = () => {
  let defaultFallback = {
    userCountry: DEFAULT_COUNTRY,
    userLanguage: DEFAULT_LANGUAGE
  };
  let cachedResponseToReturn = vehicleLocaleData || localeData || defaultFallback;
  cachedResponseToReturn[
    'locale'
  ] = `${cachedResponseToReturn.userLanguage.toLowerCase()}-${cachedResponseToReturn.userCountry.toUpperCase()}`;

  return cachedResponseToReturn;
};

const getLocale = async () => {
  // try getting it from local storage if that is there the 'vehicleLocaleData' will be an object with value
  getContextFromLocalStorage();

  let cachedResponseToReturn = vehicleLocaleData || localeData;

  if (cachedResponseToReturn && localeData) {
    return Promise.resolve(cachedResponseToReturn);
  }

  // get locale data set for the user at login
  let _getLocaleData = await makeLocaleHttpRequest();
  let localeDataFromService = _getLocaleData && _getLocaleData.data;
  let { userCountry = null, userLanguage = null } = localeDataFromService;

  let defaultThing = {
    userCountry: DEFAULT_COUNTRY,
    userLanguage: DEFAULT_LANGUAGE
  };

  // locale xhr didn't return
  if (!userCountry && !userLanguage) {
    // set it based on the 'default' which may be localStorage
    translationSetPreferredLanguage(defaultThing);
    return {
      ...defaultThing
    };
  }

  // only hold onto the data if successfully returned from the node server
  localeData = { ...defaultThing, ...localeDataFromService };
  translationSetPreferredLanguage(localeData);
  return localeData;
};

const startup = async () => {
  let promises = [getCwpMeta(), getLocale()];
  return Promise.all(promises).then(resArr => {
    metaData = resArr[0] && resArr[0].static && resArr[0].static.languageToCountryMapping;
    /*
    [
    {
      "code": "ca",
      "label": "Canada",
      "stringValues": ["canada", "ca", "can"],
      "languages": [
        { "code": "en", "label": "English", "stringValues": ["english", "en", "anglais", "anglaise"] },
        { "code": "fr", "label": "French", "stringValues": ["french", "fr", "français", "française"] }
      ]
    },
    {
      "code": "us",
      "label": "United States",
      "stringValues": ["us", "usa", "unitedstates", "united states"],
      "languages": [
        { "code": "en", "label": "English", "stringValues": ["english", "en", "anglais", "anglaise"] }
      ]
    }
  ]
  */
  });
};

// get meta info and user language info on startup, will set the translation service
(async () => {
  await startup();
})();

export { getLocale, setLocale, setLocaleToVehicleContext, clearVehicleLocaleContext, getLocaleSync };
