import invoke from './ServiceProxy';

const ADDRESS_VERIFICATION_STATUS = Object.freeze({
  INVALID: 'Invalid',
  VERIFIED: 'Verified'
});

const findAddressSuggestions = async address => {
  return await invoke({ url: '/addressSuggestions', method: 'POST', data: address });
};

const validateAddress = async address => {
  return await invoke({ url: '/validateAddress', method: 'POST', data: address });
};

const getDealerServiceUrl = async (dealerId, trackingCode = 'VHR') => {
  return await invoke({ url: `/dealer/${dealerId}/serviceurl?trackingCode=${trackingCode}`, method: 'GET' });
};

const getScheduleServiceUrl = async (dealerId, odometer, trackingCode = 'VHR') => {
  const params = {
    trackingCode,
    odometer
  };

  return invoke({ url: `/dealer/${dealerId}/serviceurl`, method: 'GET', params });
};

export {
  ADDRESS_VERIFICATION_STATUS,
  findAddressSuggestions,
  validateAddress,
  getDealerServiceUrl,
  getScheduleServiceUrl
};
