import React from 'react';
import './CheckBox.scss';
import { Input } from 'reactstrap';

const ThemedCheckBox = props => {
  let labelClassName = props.labelOverride || 'container';
  return (
    <div className='center'>
      <label className={labelClassName}>
        <Input
          type='checkbox'
          onChange={props.toggleHandler}
          name={props.name}
          id={props.id}
          checked={props.isChecked}
        />
        <div className='bootstrap-checkbox-label'>{props.label}</div>
        <span className='checkmark'></span>
      </label>
    </div>
  );
};

export default ThemedCheckBox;
