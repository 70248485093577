import React from 'react';
import reminderIcon from '../../../assets/icons/reminders.svg';
import scheduleIcon from '../../../assets/icons/schedule.svg';
import salesImg from '../../../assets/images/group.jpg';
import array_constants from '../../../constants/array_constants.json';
import { bindActionCreators } from 'redux';
import { isUserPrimary } from '../../../providers/userRolesProvider';
import * as actions from '../../../redux/actions';
import { connect } from 'react-redux';
import { isNonEmptyObject, isValidNAPhoneNumber } from '../../../util/utilityMethods';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { Row, Col, Button, Modal, ModalHeader, Input, ModalBody, ModalFooter } from 'reactstrap';
import { standaloneEnrollConciergePilot } from '../providers/AccountAndSecurityProvider';
import Loader from '../common/loader';
import ThemedCheckBox from '../../reusable_cmp_lib/ThemedCheckBox/ThemedCheckBox';
import '../../reusable_cmp_lib/ThemedButtonsAssortment/ThemedButtonsAssortment.scss';
import './AccountManagement.scss';
import { US_PHONE_NUMBER_PATTERN, formatPhoneNumber, digitsOnly } from '../CarnetHome';

class VSCEnroll extends React.Component {
  static getDerivedStateFromProps (nextProps, prevState) {
    if (isNonEmptyObject(nextProps.loadedUserInfo)) {
      let nxState = {};
      if (prevState.initializationDone === false) {
        nxState['phone'] = nextProps.defaultPhone.value;
        nxState['phoneType'] = nextProps.defaultPhoneType;
      }
      nxState['initializationDone'] = true;
      return nxState;
    }
  }

  constructor (props) {
    super(props);

    //HOME_PHONE_NUM & CELL_PHONE_NUM here:
    this.phoneTypes = array_constants.supportedPhoneTypes;

    this.state = {
      tosAccepted: false,
      enrollmentSaved: false,
      showModal: false,
      apiDetails: null,
      initializationDone: false,
      phone: null,
      typedinPhone: '',
      invalidEntry: false,
      phoneType: 'CELL_PHONE_NUM',
      writeinPhone: false,
      unauthorizedAccess: false
    };
  }

  componentDidMount () {
    const userPrimaryStatus = isUserPrimary();
    if (userPrimaryStatus === false) {
      this.setState({
        unauthorizedAccess: true
      });
    }
  }

  writeinMode = () => {
    this.setState({
      writeinPhone: true
    });
  };

  writeinEntry = evt => {
    let presentTxt = evt.target.value;
    if (presentTxt === '') {
      return;
    }

    let phoneDigits = digitsOnly(presentTxt);
    // do comparison if is the exact same, means an attempt to delete a formatting character happened
    if (phoneDigits && phoneDigits === this.state.typedinPhone) {
      phoneDigits = phoneDigits.slice(0, -1);
    }

    let nxState = { typedinPhone: phoneDigits };
    nxState['invalidEntry'] = !isValidNAPhoneNumber(phoneDigits);
    this.setState(nxState);
  };

  writeinPhoneTypeSelected = evt => {
    let chosenType = evt.currentTarget.value;
    this.setState({
      phoneType: chosenType
    });
  };

  phoneNumberSelected = selection => {
    let selType = '';
    let selNumber = selection.currentTarget.value;
    if (selNumber && this.props.phonesTypes) {
      if (this.props.phonesTypes[selNumber]) {
        selType = this.props.phonesTypes[selNumber];
      }
    }

    this.setState({
      phone: selNumber,
      phoneType: selType
    });
  };

  navBackToPrevPage = () => {
    window.history.back();
  };

  submitEnrollment = () => {
    if (this.state.tosAccepted === false || !this.props.loadedUserInfo) {
      return;
    }

    let enrollmentPhoneNum = null;
    if (this.state.writeinPhone && this.state.typedinPhone) {
      if (this.state.invalidEntry === true) {
        return;
      } else {
        enrollmentPhoneNum = this.state.typedinPhone;
      }
    } else {
      if (!this.state.phone || !this.state.phoneType) {
        const pickAPhoneMsg = this.translator.t('acctmgmt_vsc_pickphone_msg');
        this.displayApiResultModal(false, pickAPhoneMsg);
        return;
      } else {
        enrollmentPhoneNum = this.state.phone;
      }
    }

    if (!enrollmentPhoneNum) {
      const msg = this.translator.t('acctmgmt_vsc_pickphone_msg');
      this.displayApiResultModal(false, msg);
      return;
    }

    let targetPhoneRecord = {
      number: enrollmentPhoneNum,
      type: this.state.phoneType.code,
      primary: true
    };

    standaloneEnrollConciergePilot(targetPhoneRecord)
      .then(response => {
        this.displayApiResultModal(
          true,
          this.translator.t('acctmgmt_vcs_enroll_success_msg') + JSON.stringify(response)
        );
      })
      .catch(err => {
        this.displayApiResultModal(false, this.translator.t('acctmgmt_vcs_enroll_failure_msg') + JSON.stringify(err));
      });
  };

  displayApiResultModal = (apiWorked, msg) => {
    this.setState({
      enrollmentSaved: apiWorked,
      showModal: true,
      apiDetails: msg
    });
  };

  handleCheckBoxToggled = evt => {
    let checkedState = evt.target.checked;
    this.setState({
      tosAccepted: checkedState
    });
  };

  render () {
    this.translator = getLocaleTranslator();
    let apiOutcomeIndicator = null;
    let dropdownData = this.props.phonesDropdownData || [];

    if (this.state.showModal === true) {
      apiOutcomeIndicator = this.state.enrollmentSaved
        ? 'Success: Enrollment Saved'
        : 'Failure. API rejected the request.';
    }

    return this.state.initializationDone === false ? (
      <Loader />
    ) : this.state.unauthorizedAccess === true ? (
      <Row class='borderless'>
        <Col md='3'>
          <div>
            <div id='exit-editing-link' onClick={this.navBackToPrevPage}>
              <a>
                <span class='arrow'> ← </span>
                <span class='editor-navback-arrow-text'>{this.translator.t('acctmgmt_back_label')}</span>
              </a>
            </div>
          </div>
        </Col>
        <Col md='3'>
          <Modal isOpen={this.state.unauthorizedAccess} className='carnet-sg-modal'>
            <ModalHeader>{this.translator.t('acctmgmt_unauthorized')}</ModalHeader>
            <ModalBody>{this.translator.t('acctmgmt_user_not_authorized_msg')}</ModalBody>
            <ModalFooter>
              <Button className='modal-primary-btn' color='modal_background_color' onClick={this.navBackToPrevPage}>
                {this.translator.t('acctmgmt_back_label')}
              </Button>
            </ModalFooter>
          </Modal>
        </Col>
      </Row>
    ) : (
      <Row class='borderless'>
        <Col md='3'>
          <div>
            <div id='exit-editing-link' onClick={this.navBackToPrevPage}>
              <a>
                <span class='arrow'> ← </span>
                <span class='editor-navback-arrow-text'>{this.translator.t('acctmgmt_back_label')}</span>
              </a>
            </div>
          </div>
        </Col>
        <Col md='9'>
          <div id='unenroll-vsc-page'>
            <div class='vertical-spacer-small'>
              <br />
            </div>
            <div id='placeholder-page-title-aligner'>
              <span>{this.translator.t('acctmgmt_vsc_pilot_label')}</span>
            </div>
            <div id='unenroll-vsc-page-description'>
              <span id='unenroll-vsc-page-description-lbl'>{this.translator.t('acctmgmt_vsc_enroll_tempt')}</span>
            </div>
            <div class='vertical-spacer-small'>
              <Modal isOpen={this.state.showModal} className='carnet-sg-modal'>
                <ModalHeader>
                  {this.translator.t('acctmgmt_label_api')} {apiOutcomeIndicator}
                </ModalHeader>
                <ModalBody>{this.state.apiDetails || ''}</ModalBody>
                <ModalFooter>
                  <Button
                    className='modal-primary-btn'
                    color='modal_background_color'
                    onClick={() => {
                      this.setState({
                        showModal: false,
                        apiDetails: null
                      });
                    }}
                  >
                    {this.translator.t('button.ok')}
                  </Button>
                </ModalFooter>
              </Modal>
              <br />
            </div>
            <div id='unenroll-vsc-page-instrux'>
              <div class='vsc-unenroll-page-instrux' id='p1'>
                {this.translator.t('acctmgmt_vsc_enroll_instrux_1')}
              </div>
              <div class='vsc-unenroll-page-instrux' id='p2'>
                {this.translator.t('acctmgmt_vsc_enroll_instrux_2')}
              </div>
              <div class='vsc-unenroll-page-instrux' id='p3'>
                {this.translator.t('acctmgmt_vsc_enroll_instrux_3')}
              </div>
              <div id='vsc-instrux-footnotes'>
                <ol>
                  <li>{this.translator.t('acctmgmt_vsc_enroll_footnote_1')}</li>
                  <li>{this.translator.t('acctmgmt_vsc_enroll_footnote_2')}</li>
                </ol>
              </div>
            </div>
            <div class='vertical-spacer-small'>
              <br />
            </div>
            <div id='vsc-enroll-mketing-pitch-title'>
              {this.translator.t('acctmgmt_vsc_selling_pt_1')}{' '}
              <span id='program-name'>{this.translator.t('acctmgmt_vsc_program_title')}</span>
            </div>
            <div class='vertical-spacer-small'></div>

            {/* SplitSection Begins  */}
            <div class='trow'>
              <div class='tcol'>
                <div class='vcs-selling-points'>
                  <div class='selling-point-label'>
                    <img src={reminderIcon} class='vscenroll-icons' />
                    {this.translator.t('acctmgmt_vsc_selling_pt_2')}
                  </div>
                  <div class='selling-point-prose'>{this.translator.t('acctmgmt_vsc_selling_pt_3')}</div>
                  <div class='selling-point-label'>
                    <img src={scheduleIcon} class='vscenroll-icons' />
                    {this.translator.t('tos_vhc.easy_scheduling')}
                  </div>
                  <div class='selling-point-prose'>{this.translator.t('acctmgmt_vsc_selling_pt_5')}</div>
                </div>
                <div id='vsc-enroll-disclaimer'>{this.translator.t('tos_vhc.short_note')}</div>
              </div>
              <div class='tcol'>
                <div>
                  <img src={salesImg} />
                </div>
              </div>
            </div>
            {/* Split Section Ends */}

            <div id='enroll-consent-statement'>{this.translator.t('acctmgmt_vsc_disclaimer')}</div>
            <br />
            <Row>
              <Col md='3'>
                <div id='phone-dropdown-aligner'>
                  <span class='raw-select-label-text'>{this.translator.t('tos_vhc.preferred_phone_number')}</span>
                  <br />
                  {this.state.writeinPhone === true ? (
                    <div>
                      <Input
                        className='cwp'
                        type='tel'
                        pattern={US_PHONE_NUMBER_PATTERN}
                        maxLength={14}
                        name='writeinPhone'
                        onChange={this.writeinEntry}
                        value={formatPhoneNumber(this.state.typedinPhone)}
                        id='writein-phone-input'
                        placeholder='(enter phone)'
                      />
                      <br />
                      {this.state.invalidEntry === true && (
                        <div class='invalid-indicator'>{this.translator.t('acctmgmt_invalid_phone')}</div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <select className='cwp' onChange={this.phoneNumberSelected} id='leftmost-dropdown'>
                        {dropdownData.map(option => {
                          return (
                            <option phonetype={option.type} value={option.value}>
                              {option.value}
                            </option>
                          );
                        })}
                      </select>
                      <div onClick={this.writeinMode} id='phone-writein-switch'>
                        {this.translator.t('acctmgmt_writein_phone')}
                      </div>
                    </div>
                  )}
                </div>
              </Col>
              <Col md='2'></Col>
              <Col md='3'>
                <div id='phone-type-indicator'>
                  <span class='raw-select-label-text'>{this.translator.t('tos_vhc.phone_type')}</span>
                  <br />
                  {this.state.writeinPhone === true ? (
                    <div>
                      <span class='editor-diatom-superscript-lbl'>{this.translator.t('tos_vhc.phone_type')}</span>
                      <select
                        value={this.state.phoneType}
                        onChange={this.writeinPhoneTypeSelected}
                        className='cwp-input'
                      >
                        <option id='CELL_PHONE_NUM' value='CELL_PHONE_NUM'>
                          {this.translator.t('acctmgmt_label_mobile')}
                        </option>
                      </select>
                    </div>
                  ) : (
                    <Input
                      className='cwp'
                      type='text'
                      name='phoneType'
                      id='phone_type_indicator'
                      value={(this.state.phoneType && this.state.phoneType.label) || ''}
                      placeholder={(this.state.phoneType && this.state.phoneType.label) || ''}
                    />
                  )}
                </div>
              </Col>
            </Row>

            <div id='vsc-unenroll-checkbox-aligner'>
              <ThemedCheckBox
                toggleHandler={this.handleCheckBoxToggled}
                name='enroll-chkbox'
                id='enroll-vsc-chkbox'
                label='Enroll in VSC Pilot(optional)'
                isChecked={false}
              />
            </div>
            <div id='vsc-unenroll-commit-btn'>
              <Button
                onClick={this.submitEnrollment}
                className='cwp'
                color='primary'
                disabled={!this.state.tosAccepted}
              >
                {this.translator.t('submit_label')}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getUserInfo: actions.getUserInfo
      },
      dispatch
    )
  };
};

const mapStateToProps = state => {
  const { userInfo } = state;
  let initialPhone = null;
  let initialPhoneType = '';
  let usersKnownPhones = [];
  let phoneTypesIndex = {};
  const theUserInfoData = userInfo && userInfo.info;
  if (theUserInfoData && theUserInfoData.phones && theUserInfoData.phones.length > 0) {
    usersKnownPhones = theUserInfoData.phones.map(record => {
      return {
        code: record.id,
        value: record.number
      };
    });

    const labelMap = {
      CELL_PHONE_NUM: 'Mobile',
      HOME_PHONE_NUM: 'Home'
    };

    theUserInfoData.phones.forEach(record => {
      const typeLabel = labelMap[record.type];
      phoneTypesIndex[record.number] = { label: typeLabel, code: record.type };
    });

    initialPhone = usersKnownPhones[0];
    if (initialPhone) {
      initialPhoneType = phoneTypesIndex[initialPhone.value];
    }
  }

  return {
    loadedUserInfo: theUserInfoData,
    phonesDropdownData: usersKnownPhones || [],
    phonesTypes: phoneTypesIndex,
    defaultPhone: initialPhone,
    defaultPhoneType: initialPhoneType
  };
};

export { VSCEnroll };
export default connect(mapStateToProps, mapDispatchToProps)(VSCEnroll);
