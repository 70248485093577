import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../redux/actions';
import Loader from '../common/loader';
import { saveCarNickName } from '../providers/AccountAndSecurityProvider';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { Container, Row, Col, Input, Button } from 'reactstrap';
import '../../reusable_cmp_lib/ThemedFormInputs/ThemedForm.scss';
import '../../reusable_cmp_lib/ThemedButtonsAssortment/ThemedButtonsAssortment.scss';
import './AccountManagement.scss';
import { setTitle } from '../../../providers/documentTitleProvider';

class VehicleNicknameEditor extends React.Component {
  constructor (props) {
    super(props);
    this.translator = getLocaleTranslator();
    this.state = {
      workingNickname: '',
      processing: false
    };
  }

  componentDidMount = () => {
    this.props.actions.getCarNickName();
    setTitle('document_titles.account_management.edit_vehicle_nickname');
  };

  componentDidUpdate () {
    setTitle('document_titles.account_management.edit_vehicle_nickname');
  }

  handleTextEditing = evt => {
    let presentTxt = evt.target.value;
    this.setState({
      workingNickname: presentTxt
    });
  };

  exitEditor = () => {
    this.props.exitCallback(null);
  };

  persistSavedNickName = async nickName => {
    let saveResponse;

    const payload = {
      carNameDesc: nickName
    };
    try {
      saveResponse = await saveCarNickName(payload);
    } catch (err) {
      saveResponse = { error: err };
    } finally {
      this.setState(
        {
          processing: false
        },
        this.exitEditor
      );
    }
  };

  saveNickName = () => {
    const submittedNickName = this.state.workingNickname;
    this.setState({
      processing: true
    });
    this.persistSavedNickName(submittedNickName);
  };

  render () {
    let i18nSaveButtonLabel = this.translator.t('save_changes_label');
    let i18nFieldLabel = this.translator.t('acctmgmt_vi_field_nickname');
    let i18nFieldNickName = this.translator.t('acctmgmt_vi_field_nickname');

    return this.state.processing === true ? (
      <div id='acctmgmt-contentpane'>
        <Loader />
      </div>
    ) : (
      <fieldset>
        <Container fluid={true}>
          <Row className='borderless'>
            <Col md='4'></Col>
            <Col md='7'>
              <div className='editor-firstrow-label-aligner'>
                <legend>
                  <span className='first-editor-field-label'>{i18nFieldNickName}</span>
                </legend>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md='4'></Col>
            <Col md='4'>
              <div className='editor-diatom-field'>
                <label htmlFor='vi_nickname_input'>
                  <h2 className='h6'>
                    <span className='editor-diatom-superscript-lbl'>{i18nFieldLabel}</span>
                  </h2>
                </label>
                <Input
                  className='cwp'
                  type='text'
                  name='vi_nickname'
                  id='vi_nickname_input'
                  onChange={evt => this.handleTextEditing(evt)}
                  placeholder={this.props.carNickName}
                />
                <br />
              </div>
            </Col>
            <Col md='2'>
              <br />
              <Button
                className='cwp'
                color='primary'
                onClick={() => {
                  this.saveNickName();
                }}
              >
                {i18nSaveButtonLabel}
              </Button>
            </Col>
          </Row>
        </Container>
      </fieldset>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getCarNickName: actions.getCarNickName
      },
      dispatch
    )
  };
};

const mapStateToProps = state => {
  const { carNickNameInfo } = state;
  let injectedValue =
    (carNickNameInfo && carNickNameInfo.carNickNameData && carNickNameInfo.carNickNameData.carNameDesc) || 'NOT SET';
  let nxState = {
    carNickName: injectedValue
  };

  return nxState;
};

export { VehicleNicknameEditor };
export default connect(mapStateToProps, mapDispatchToProps)(VehicleNicknameEditor);
