import React from 'react';
import PropTypes from 'prop-types';
import YourProfileCA from './YourProfileCA';
import YourProfile from './YourProfile';
import { getLocaleSync } from '../../../providers/languageProvider';
import Spinner from '../Spinner';
import { findTosTypeByManifestId, updateTOS } from '../providers/GarageProvider';

export default class YourProfileLoader extends React.Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    onSuccess: PropTypes.func.isRequired,
    profileStatus: PropTypes.string
  };

  saveTOS = async (tosStatus, manifestId) => {
    const manifest = this.props.tosManifest?.manifest;

    if (Array.isArray(manifest)) {
      const { tosVersion } = manifest.find(m => m.id === manifestId) || {};

      const tosType = findTosTypeByManifestId(manifestId);

      return updateTOS(tosStatus, tosType, tosVersion);
    }
  };

  getTosUrlsFromManifest = () => {
    const manifest = this.props.tosManifest?.manifest;

    if (Array.isArray(manifest)) {
      return manifest.map(m => ({
        [m.id]: m.tosUrl
      }));
    }

    return [];
  };

  render () {
    const { onSuccess, profileStatus, locale } = this.props;

    if (!locale || typeof locale !== 'string') {
      return <Spinner />;
    }

    if (locale?.toUpperCase()?.endsWith('CA')) {
      const manifestTosUrl = this.getTosUrlsFromManifest();

      return (
        <YourProfileCA
          profileStatus={profileStatus}
          onSuccess={onSuccess}
          locale={locale}
          manifestTosUrl={manifestTosUrl}
          saveTOS={this.saveTOS}
        />
      );
    }

    return <YourProfile profileStatus={profileStatus} onSuccess={onSuccess} />;
  }
}
