import React from 'react';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { validateEmail } from '../../../providers/emailValidationProvider';
import { isValidNAPhoneNumber, isEmailShaped } from '../../../util/utilityMethods';
import { US_PHONE_NUMBER_PATTERN, formatPhoneNumber, digitsOnly } from '../CarnetHome';
import { Modal, ModalHeader, ModalBody, Input, ModalFooter, Button, Container, Row, Col } from 'reactstrap';
import '../../reusable_cmp_lib/ThemedFormInputs/ThemedForm.scss';
import '../acctMgmt/AccountManagement.scss';
import '../CarnetHome.scss';
import './ManageAlertContactsModal.scss';

class ManageAlertContactsModal extends React.Component {
  constructor (props) {
    super(props);

    let unsortedPhonesList = this.props.anyContactsPhones;
    let sortedPhonesList = [];
    //the sort function can handle empty arrays, just null-check here:
    if (unsortedPhonesList) {
      sortedPhonesList = this.sortByPrimary(unsortedPhonesList);
    }

    let unsortedEmailsList = this.props.currentContacts;
    let sortedEmailsList = [];
    if (unsortedEmailsList) {
      sortedEmailsList = this.sortByPrimary(unsortedEmailsList);
    }
    this.state = {
      contacts: sortedEmailsList,
      phonesList: sortedPhonesList,
      inProgressEmail: null,
      inProgressPhone: null,
      inprogress_email_invalid: false,
      inprogress_phone_invalid: false,
      formDirty: false
    };
  }

  submitChanges = () => {
    const finalContacts = JSON.parse(JSON.stringify(this.state.contacts));
    const finalPhones = JSON.parse(JSON.stringify(this.state.phonesList));
    this.props.saveEdits(finalContacts, finalPhones);
    this.setState(
      {
        formDirty: false
      },
      () => {
        this.setVisibility(false);
      }
    );
  };

  setVisibility = shown => {
    this.props.modalShowHandler(shown);
  };

  addAContact = type => {
    if (this.state.inProgressEmail !== null || this.state.inProgressPhone !== null) {
      return;
    }
    let designator = 'inProgress' + type;
    let delta = {};
    delta[designator] = '';
    this.setState(delta);
  };

  handleTextEntry = (fieldType, evt, index) => {
    let presentText = evt.target.value;
    let statePropName = 'inProgress' + fieldType;
    let statePatch = {};
    statePatch[statePropName] = presentText;
    this.setState(statePatch);
  };

  handlePhoneEntry = (evt, index) => {
    let fieldType = 'Phone';
    let targetValue = evt.target.value;
    let phoneDigits = digitsOnly(targetValue);
    let statePropName = 'inProgress' + fieldType;

    // do comparison if is the exact same, means an attempt to delete a formatting character happened
    if (phoneDigits && phoneDigits === this.state[statePropName]) {
      phoneDigits = phoneDigits.slice(0, -1);
    }

    let statePatch = {};
    statePatch[statePropName] = phoneDigits;
    this.setState(statePatch);
  };

  handleFieldSubmit = (evt, fieldType) => {
    let keyPressed = evt.keyCode;
    if (keyPressed === 13) {
      let submittedProp = 'inProgress' + fieldType;
      if (this.state[submittedProp] && this.state[submittedProp].length > 0) {
        let addedContact = {};
        addedContact[fieldType] = this.state[submittedProp];
        addedContact['primary'] = false;
        let contactsDeepClone = JSON.parse(JSON.stringify(this.state.contacts));
        contactsDeepClone.push(addedContact);
        this.setState({
          contacts: contactsDeepClone,
          inProgressEmail: null,
          inProgessPhone: null,
          formDirty: true
        });
      }
    }
  };

  deleteContactField = (index, fieldType) => {
    let fieldDataArr = fieldType === 'email' ? this.state.contacts : this.state.phonesList;
    if (fieldDataArr === null || fieldDataArr.length === 0) {
      return;
    }
    let mutableDeepClone = JSON.parse(JSON.stringify(fieldDataArr));
    let targetRecord = mutableDeepClone[index];
    if (targetRecord.primary === true) {
      return;
    }
    mutableDeepClone.splice(index, 1);
    const fieldDataKey = fieldType === 'email' ? 'contacts' : 'phonesList';
    mutableDeepClone = this.sortByPrimary(mutableDeepClone);
    let statePatch = {};
    statePatch[fieldDataKey] = mutableDeepClone;
    statePatch['formDirty'] = true;
    this.setState(statePatch);
  };

  checkIfEmailIsDuplicate = candidate => {
    if (!candidate) {
      return false;
    }
    let collisions = this.state.contacts.filter(contact => {
      return contact.email === candidate;
    });

    return collisions && collisions.length > 0;
  };

  checkIfPhoneIsDuplicate = candidate => {
    if (!candidate) {
      return false;
    }
    let collisions = this.state.phonesList.filter(record => {
      return record.number === candidate;
    });
    return collisions && collisions.length > 0;
  };

  sortByPrimary = unsortedPhones => {
    if (!unsortedPhones || unsortedPhones.length < 2) {
      return unsortedPhones; //no need to sort a list of length 1, or zero
    }
    let orderedList = [];
    let primaryPhones = unsortedPhones.filter(record => record.primary === true);
    let nonprimaryPhones = unsortedPhones.filter(record => {
      return record.primary == null || record.primary === false;
    });
    if (primaryPhones.length === 0) {
      return unsortedPhones; //there ARE NO primaries
    }
    orderedList = [...primaryPhones];
    orderedList = orderedList.concat(nonprimaryPhones);
    return orderedList;
  };

  saveAddedContact = async () => {
    if (!this.state.contacts) {
      return;
    }

    let toaddRecord = {
      checked: false,
      primary: false
    };

    ///YOULL have to change THIS to only set one or another? Maybe necessary?
    let deltas = {
      inProgressEmail: null,
      inProgressPhone: null
    };

    if (this.state.inProgressEmail) {
      const prospectEmail = '' + this.state.inProgressEmail;
      // email format validation
      if (!prospectEmail || !isEmailShaped(prospectEmail)) {
        return this.setState({ inprogress_email_invalid: true });
      }
      const isDuplicate = this.checkIfEmailIsDuplicate(prospectEmail);
      let emailAddressIsValid = false;
      try {
        emailAddressIsValid = await validateEmail(prospectEmail);
      } catch (e) {
        console.log('email address validation failed ', e);
      }
      // email status remains false
      if (!emailAddressIsValid || String(emailAddressIsValid) === 'invalid' || isDuplicate === true) {
        return this.setState({ inprogress_email_invalid: true });
      } else this.setState({ inprogress_email_invalid: false });

      toaddRecord['email'] = prospectEmail;
      let mutableContactsClone = JSON.parse(JSON.stringify(this.state.contacts));
      mutableContactsClone.push(toaddRecord);
      mutableContactsClone = this.sortByPrimary(mutableContactsClone);
      deltas['contacts'] = mutableContactsClone;
    } else if (this.state.inProgressPhone) {
      const prospectPhone = '' + this.state.inProgressPhone;
      if (!prospectPhone) {
        return;
      }
      const dupePhone = this.checkIfPhoneIsDuplicate(prospectPhone);
      if (!isValidNAPhoneNumber(prospectPhone) || dupePhone === true) {
        return this.setState({ inprogress_phone_invalid: true });
      } else {
        this.setState({ inprogress_phone_invalid: false });
      }
      toaddRecord['number'] = prospectPhone;
      toaddRecord['primary'] = false;
      toaddRecord['type'] = 'CELL_PHONE_NUM';
      let mutableClone = JSON.parse(JSON.stringify(this.state.phonesList));
      mutableClone.push(toaddRecord);
      mutableClone = this.sortByPrimary(mutableClone);
      deltas['phonesList'] = mutableClone;
    }
    deltas['formDirty'] = true;
    this.setState(deltas);
  };

  renderModalBodyContent = () => {
    return this.translator.t('acctmgmt_macm_instructions') || '';
  };

  render () {
    this.translator = getLocaleTranslator();
    const instructions = this.renderModalBodyContent();

    return (
      <Modal isOpen={true} className='manageAlertContactsModal'>
        <ModalHeader className='macm-header-title'>{this.translator.t('acctmgmt_alerts_edit_btn')}</ModalHeader>
        <ModalBody>
          <div id='macm-body-instructions'>{instructions}</div>
          <div>
            <Container>
              <Row className='borderless'>
                <Col sm='4'>
                  <div id='vertical-spacer-tiny'></div>
                </Col>
              </Row>
              <Row className='borderless'>
                <Col className='records-table-rows'>
                  <div class='macm-record-column-lbl'>{this.translator.t('acctmgmt_label_eaddress')}</div>
                </Col>
                <Col sm='1'></Col>
                <Col className='records-table-rows'>
                  <div class='macm-record-column-lbl'>{this.translator.t('sms_alerts_label')}</div>
                </Col>
              </Row>
              {this.state.contacts && this.state.contacts.length === 0 && (
                <Row className='borderless'>
                  <Col className='records-table-rows'>
                    {' '}
                    {/* NON-translation-eligible text */}
                    <div class='add-record-suggestion'>example@somedomain.com</div>
                  </Col>
                  <Col sm='1'></Col>
                  <Col className='records-table-rows'>
                    {' '}
                    {/* NON-translation-eligible text */}
                    <div class='add-record-suggestion'>(212)867-5309</div>
                  </Col>
                </Row>
              )}

              {/* DISPLAY LOADED DATA  */}
              <Row>
                <Col>
                  {(this.state.contacts || []).map((contact, index) => {
                    return (
                      <Row className='borderless' key={'contact_' + index}>
                        <Col className='records-table-rows'>
                          <div class='existing-record-text'>
                            {contact.email}
                            {contact.primary === false && (
                              <span
                                onClick={() => {
                                  this.deleteContactField(index, 'email');
                                }}
                                class='macm-delete-button action-link'
                              >
                                &nbsp; &#8854;
                              </span>
                            )}
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
                <Col sm='1'></Col>
                <Col>
                  {(this.state.phonesList || []).map((phoneRecord, index) => {
                    return (
                      <Row className='borderless'>
                        <Col className='records-table-rows'>
                          <div class='existing-record-text'>
                            {(phoneRecord.number && formatPhoneNumber(phoneRecord.number)) || ''}
                            {phoneRecord.primary === false && (
                              <span
                                onClick={() => {
                                  this.deleteContactField(index, 'phone');
                                }}
                                class='macm-delete-button action-link'
                              >
                                &nbsp; &#8854;
                              </span>
                            )}
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
              </Row>

              {(this.state.inProgressEmail !== null || this.state.inProgressPhone !== null) && (
                <Row className='borderless'>
                  <Col className='records-table-rows'>
                    <div
                      class='entry-field'
                      onKeyDown={evt => {
                        this.handleFieldSubmit(evt, 'Email');
                      }}
                    >
                      <Input
                        disabled={this.state.inProgressEmail === null}
                        className={this.state.inprogress_email_invalid ? 'form-text invalid_input' : 'form-text'}
                        onChange={evt => this.handleTextEntry('Email', evt)}
                        type='text'
                        name={'add_contact_email'}
                        id={'add_contact_email_input'}
                        placeholder={this.state.inProgressEmail}
                      />
                    </div>
                  </Col>
                  <Col sm='1'></Col>
                  <Col className='records-table-rows'>
                    <div class='entry-field'>
                      <Input
                        disabled={this.state.inProgressPhone === null}
                        className={this.state.inprogress_phone_invalid ? 'form-text invalid_input' : 'form-text'}
                        onChange={evt => this.handlePhoneEntry(evt)}
                        value={formatPhoneNumber(this.state.inProgressPhone)}
                        type='tel'
                        maxLength={14}
                        pattern={US_PHONE_NUMBER_PATTERN}
                        name={'add_contact_phone'}
                        id={'add_contact_phone_input'}
                        placeholder={formatPhoneNumber(this.state.inProgressPhone)}
                      />
                    </div>
                  </Col>
                </Row>
              )}

              {/* The Row for ADDing a Contact  */}
              <Row className='borderless'>
                <Col className='records-table-rows'>
                  {this.state.contacts.length < 3 && (
                    <div onClick={evt => this.addAContact('Email')} class='add-contact-field-link action-link'>
                      &#8853;&nbsp;
                      {this.translator.t('add_prefix_label') + ' ' + this.translator.t('acctmgmt_pi_field_label_email')}
                    </div>
                  )}
                </Col>
                <Col sm='1'></Col>
                <Col className='records-table-rows'>
                  {this.state.phonesList.length < 3 && (
                    <div onClick={evt => this.addAContact('Phone')} class='add-contact-field-link action-link'>
                      &#8853;&nbsp;
                      {this.translator.t('add_prefix_label') + ' ' + this.translator.t('acctmgmt_pi_field_label_phone')}
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        </ModalBody>
        <ModalFooter>
          {(this.state.inProgressEmail || this.state.inProgressPhone) && (
            <Button
              className='cwp rounded-theme-btn marginRight40'
              color='primary'
              onClick={() => {
                this.saveAddedContact();
              }}
            >
              {this.translator.t('add_prefix_label')}
            </Button>
          )}

          <Button
            className='cwpSecondary'
            onClick={() => {
              this.setVisibility(false);
            }}
          >
            {this.translator.t('cancel_btn_camel')}
          </Button>

          {this.state.formDirty === true && (
            <Button
              className='cwp'
              color='primary'
              onClick={() => {
                this.submitChanges();
              }}
            >
              {// this.translator.t('save_btn_camel')
              this.translator.t('done_label')}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  } //render()
}

export default ManageAlertContactsModal;
