/**
 * Locale-specific utility: formatting for all things non-label
 * Locales supported = en-US, en-CA, fr-CA
 */

import { getLocaleSync } from '../../providers/languageProvider';
import * as momenttz from 'moment-timezone';
const zipToTimeZone = require('zipcode-to-timezone');
const moment = require('moment');

// helper wrapper
const getLocaleFromProvider = () => {
  let thing = getLocaleSync();
  let thingToReturn = thing['locale'];
  return thingToReturn || '';
};

const currencyTypeToLocaleMap = {
  'en-US': 'USD',
  'en-CA': 'CAD',
  'fr-CA': 'CAD'
};
const DEFAULT_CURRENCY_TYPE = 'USD';
const DEFAULT_LOCALE = 'en-US';

const CA_SPEED_MEASUREMENT = 'kilometer-per-hour';
const US_SPEED_MEASUREMENT = 'mile-per-hour';
const CA_FUEL_EFFICIENCY_MEASUREMENT = 'kilometer-per-gallon';
const US_FUEL_EFFICIENCY_MEASUREMENT = 'mile-per-gallon';

const getCountryFromIso = locale => String(locale.split('-')[1]).toUpperCase();
const getLangFromIso = locale => String(locale.split('-')[0]).toLowerCase();

/*
 * Format number based on locale
 * 100000.00 => 'en-US'/'en-CA' = 100,000.00
 * 100000.00 => 'fr-CA' = 100 000,00
 */
const numberFormat = (num = 0, locale = null) => {
  locale = locale || getLocaleFromProvider() || DEFAULT_LOCALE;
  return new Intl.NumberFormat(locale, {}).format(num);
};

/**
 * Returns a string in this format 50 mi/h  or 50 km/h
 * @param {Number} speed
 * @param [String] (optional) locale - utilize getLocaleFromProvider() if not set
 */
const speedFormat = (speed = 0, locale = null) => {
  locale = locale || getLocaleFromProvider() || DEFAULT_LOCALE;
  let country = getCountryFromIso(locale);
  let unit = US_SPEED_MEASUREMENT;
  if (country === 'CA') {
    unit = CA_SPEED_MEASUREMENT;
  }
  return new Intl.NumberFormat(locale, {
    style: 'unit',
    unit
  }).format(speed);
};

/**
 * Returns a string in this format 50 mi/gal or 50 km/gal
 * @param {Number} num
 * @param [String] (optional) locale - utilize getLocaleFromProvider() if not set
 */
const fuelEfficiencyFormat = (num = 0, locale = null) => {
  locale = locale || getLocaleFromProvider() || DEFAULT_LOCALE;
  let country = getCountryFromIso(locale);
  let unit = US_FUEL_EFFICIENCY_MEASUREMENT;
  if (country === 'CA') {
    unit = CA_FUEL_EFFICIENCY_MEASUREMENT;
  }
  return new Intl.NumberFormat(locale, {
    style: 'unit',
    unit
  }).format(num);
};

/**
 * Format currency based on locale
 * @param {Number} currency amount
 * @param [String] (optional) locale - utilize getLocaleFromProvider() if not set
 * @example
 * 100000.00, 'en-US'/'en-CA' = $100,000.00
 * 100000.00, 'fr-CA' = 100 000,00$
 */
const currencyFormat = (num = 0, locale = null) => {
  locale = locale || getLocaleFromProvider() || DEFAULT_LOCALE;
  let currencyType = currencyTypeToLocaleMap[locale] || DEFAULT_CURRENCY_TYPE;
  let formatted = new Intl.NumberFormat(locale, { style: 'currency', currency: currencyType }).format(num);
  return formatted;
};

/**
 * Format numeric date
 * @param {Number} timestamp in ms
 * @param [String] (optional) locale - utilize getLocaleFromProvider() if not set
 * Note: the Intl format uses '-' for the separator for fr-CA & en-CA
 */
const dateFormat = (num = 0, locale = null) => {
  let dateOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  };

  if (num === 0) {
    return '';
  }
  locale = locale || getLocaleFromProvider() || DEFAULT_LOCALE;
  let d = new Date(num);
  let formattedDate = new Intl.DateTimeFormat(locale, dateOptions).format(d);
  return formattedDate.replace(/-/g, '/');
};

/**
 * Format date with Date + Time
 * @param {Number} timestamp in ms
 * @param [String] (optional) locale - utilize getLocaleFromProvider() if not set
 * @example
 * "Sep 25, 09:34 AM" - en-US
 * "Sep. 25, 09:34 a.m." - en-CA
 * "25 sept., 09 h 34" - fr-CA
 */
const dateAndTimeFormat = (num = 0, locale = null, useNumericMonth = false) => {
  if (num === 0) {
    return '';
  }
  locale = locale || getLocaleFromProvider() || DEFAULT_LOCALE;
  let lang = getLangFromIso(locale);
  let d = new Date(num);

  let monthDisplayType = useNumericMonth ? 'numeric' : 'short';
  let dateOptions = { month: monthDisplayType, day: 'numeric' };
  let formattedDate = new Intl.DateTimeFormat(locale, dateOptions).format(d);
  let timeOptions = {
    hour12: lang === 'en' ? true : false,
    hour: '2-digit',
    minute: '2-digit'
  };
  let localizedTime = d.toLocaleTimeString(locale, timeOptions);
  let final = `${formattedDate}, ${localizedTime}`;
  return final;

  /*
  Format: Mmm dd, hh:mm am/pm
  Example: Dec. 30, 11:58 pm

  Canada English Locale
  Format: Mmm dd, hh:mm am/pm
  Example: Dec 30, 11:58 pm

  Canada French Locale
  Format: dd mmm, hh24 h mm (24 hr time)
  Example: 20 déc, 23 h 58
  */
};

const formatDateUsingOneOffUnstandardRequestedFormat = (num = 0, localeCode = 'en-CA') => {
  const dateInUnstandardFormat = new Date(num); //nec. to get a date from epoch ms val
  const fmtPattern = localeCode === 'fr-CA' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
  const unstandardFmtCADate = moment(dateInUnstandardFormat).format(fmtPattern);
  return unstandardFmtCADate;
};

/**
 * Format the date and include day at the beggining
 * @param {Number} num (date epoch time)
 * @param [String] (optional) locale - utilize getLocaleFromProvider() if not set
 * @example
 * "Fri, Sep 25, 2020" - en-US
 * "Fri., Sep. 25, 2020" - en-CA
 * "ven. 25 sept. 2020" - fr-CA
 */
const formatDateWithDay = (num = 0, locale = null) => {
  if (num === 0) {
    return '';
  }
  locale = locale || getLocaleFromProvider() || DEFAULT_LOCALE;
  let d = new Date(num);
  var dateOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
  let formattedDate = new Intl.DateTimeFormat(locale, dateOptions).format(d);
  return formattedDate;

  /*
  Date with Day
  US Locale
  Format: DAY, MMM DD, YYYY
  Example: SUN, DEC 30, 2018
  Canada English Locale
  Format: DAY, MMM DD, YYYY
  Example: SUN, DEC 30, 2018
  Canada French Locale
  Format: DAY DD MMM YYYY
  Example: DIM 30 DÉC 2018
  */
};

/**
 * Format the date with long month name at the beggining
 * @param {Number} num (date epoch time)
 * @param [String] (optional) locale - utilize getLocaleFromProvider() if not set
 * @example
 * "September 25, 2020" - en-US
 * "September 25, 2020" - en-CA
 * "25 september 2020" - fr-CA
 */
const formatDateWithMonth = (num = 0, locale = null) => {
  if (num === 0) {
    return '';
  }
  locale = locale || getLocaleFromProvider() || DEFAULT_LOCALE;
  let d = new Date(num);
  var dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  let formattedDate = new Intl.DateTimeFormat(locale, dateOptions).format(d);
  return formattedDate;

  /*
  Date with long month name
  US Locale
  Format: MMMM DD, YYYY
  Example: DECEMBER 30, 2018
  Canada English Locale
  Format: MMMM DD, YYYY
  Example: DECEMBER 30, 2018
  Canada French Locale
  Format: DD MMMM YYYY
  Example: 30 DÉCEMBER 2018
  */
};

/**
 * Format the Month Year
 * @param {Number} num (date epoch time)
 * @param [String] (optional) locale - utilize getLocaleFromProvider() if not set
 * @example
 * "September 2020" - en-US
 * "September 2020" - en-CA
 * "septembre 2020" - fr-CA
 */
const formatSpelledOutMonthAndYear = (num = 0, locale = null) => {
  if (num === 0) {
    return '';
  }
  locale = locale || getLocaleFromProvider() || DEFAULT_LOCALE;
  let d = new Date(num);
  let dateOptions = { month: 'long', year: 'numeric' };
  let formattedDate = new Intl.DateTimeFormat(locale, dateOptions).format(d);
  return formattedDate;
  /*
  Date: Month and Year
  US Locale
  Format: MMMMM, YYYY
  Example: JANUARY, 2018
  Canada English Locale
  Format: MMMMM YYYY
  Example: JANUARY 2018
  Canada French Locale
  Format: MMMMM YYYY
  Example: JANVIER 2019
  */
};

/**
 * Get a localized date/time string that looks something like this:  11:58 pm, 03/22/2018
 * @param {Number} num (date in ms)
 * @param [String] (optional) locale - utilize getLocaleFromProvider() if not set
 * @example
 * "10:29 AM, 9/25/2020" - en-US
 * "10:30 a.m., 2020/09/25" - en-CA
 * "10 h 30, 2020/09/25" - fr-CA
 */
const formatTimeAndDate = (num = 0, locale = null) => {
  if (num === 0) {
    return '';
  }
  locale = locale || getLocaleFromProvider() || DEFAULT_LOCALE;
  let lang = getLangFromIso(locale);
  let date = new Date(num);

  let options = {
    hour12: lang === 'en' ? true : false,
    hour: '2-digit',
    minute: '2-digit'
  };

  let localizedTime = date.toLocaleTimeString(locale, options);
  let localizedDate = new Intl.DateTimeFormat(locale).format(date);
  let final = `${localizedTime}, ${localizedDate}`;
  final = final.replace(/-/g, '/');
  return final;

  /*
  Time, Date
  US Locale
  Format: hh:mm am/pm, MM/DD/YYYY
  Example: 12:01 am, 03/22/2018
  Canada English Locale
  Format: hh:mm am/pm, MM/DD/YYYY
  Example: 11:58 pm, 03/22/2018
  Canada French Locale
  Format: hh24 h mm, DD/MM/YYYY
  Example: 23 h 58, 22/03/2018
  */
};

/**
 * Get a localized date/time string that looks something like this:  11:58 pm, 03/22/2018
 * @param [Number] (optional) num (date in ms), use Date.now() as fallback
 * @param [String] (optional) locale - utilize getLocaleFromProvider() if not set
 * @example
 * "10:29 AM" - en-US
 * "10:30 a.m." - en-CA
 * "10 h 30" - fr-CA
 */
const formatTime = (num = Date.now(), locale = null, includeSecondsInTimestamp = false) => {
  if (num === 0) {
    return '';
  }
  locale = locale || getLocaleFromProvider() || DEFAULT_LOCALE;
  let lang = getLangFromIso(locale);
  let date = new Date(num);

  let options = {
    hour12: lang === 'en' ? true : false,
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short'
  };

  if (includeSecondsInTimestamp) {
    options.second = '2-digit';
  }

  return date.toLocaleTimeString(locale, options);

  /*
  Time, Date
  US Locale
  Format: hh:mm am/pm, MM/DD/YYYY
  Example: 12:01 am, 03/22/2018
  Canada English Locale
  Format: hh:mm am/pm, MM/DD/YYYY
  Example: 11:58 pm, 03/22/2018
  Canada French Locale
  Format: hh24 h mm, DD/MM/YYYY
  Example: 23 h 58, 22/03/2018
  */
};

/**
 * Get a localized Time Stamp with the TimeZone
 * @param [Number] (optional) num (date in ms), use Date.now() as fallback
 * @param [String] (optional) locale - utilize getLocaleFromProvider() if not set
 * * @param [Number] (optional) zip  - zipCode/postalCode, if not provided use momemnttz
 * @example
 * "11:34 AM EDT" - en-US/America_New-York
 * "11:35 a.m. EDT" - en-CA/America_New-York
 * "11 h 35 HAE" - fr-CA/America_New-York
 */
const formatTimeWithZone = (num = Date.now(), locale = getLocaleFromProvider() || DEFAULT_LOCALE, zip = '') => {
  console.log('formatTimeWithZone >> ', num, locale, zip);
  if (num === 0) {
    return '';
  }
  let lang = getLangFromIso(locale);
  // retrieve a TIMEZONE so we can display in localized time with zone using standard browser toLocaleTimeString method
  let timeZone = null;
  if (zip !== '') {
    timeZone = zipToTimeZone.lookup(zip);
  } else {
    // if the zip isn't provided, use momenttz to guess
    timeZone = momenttz.tz.guess();
  }

  console.log('timezone is');

  let date = new Date(num);
  let options = {
    timeZone,
    timeZoneName: 'short',
    hour12: lang === 'en' ? true : false,
    hour: '2-digit',
    minute: '2-digit'
  };

  let localized = date.toLocaleTimeString(locale, options);

  console.log('localized is ', localized);

  // double check and enforce leading 0
  let arrOfTimePieces = localized.split(':');
  if (String(arrOfTimePieces[0]).length === 1) {
    localized = '0' + localized;
  }

  return localized;

  /*
  Time+Zone
  US Locale
  Format: hh:mm AM/PM zone
  Example: 11:58 AM EDT
  Canada English Locale
  Format: hh:mm AM/PM zone
  Example: 11:58 AM EDT
  Canada French Locale
  Format: hh24 h mm zone (24 hr time; zone translated)
  Example: 23 h 58 HAE
  */
};

const findTimezone = () => {
  return momenttz.tz.guess();
};

const findTimezoneAbbreviation = (timezone = findTimezone()) => {
  return momenttz()
    .tz(timezone)
    .format('z');
};

/**
 * format timeAndDate - cross-browser compatibility.
 *
 * @param {num} timestamp in ms
 * @param (optional) locale
 * @returns
 */
const timeAndDateFormat = (num = 0, locale = null) => {
  if (num === 0) {
    return '';
  }
  locale = locale || getLocaleFromProvider() || DEFAULT_LOCALE;
  let lang = getLangFromIso(locale);
  let d = new Date(num);

  let dateOptions = { month: '2-digit', day: '2-digit', year: 'numeric' };

  let formattedDate = new Intl.DateTimeFormat(locale, dateOptions).format(d);
  formattedDate.replace(/-/g, '/');

  let timeOptions = {
    hour12: lang === 'en' ? true : false,
    hour: '2-digit',
    minute: '2-digit'
  };
  let localizedTime = d.toLocaleTimeString(locale, timeOptions);
  let dateTime = `${localizedTime}, ${formattedDate}`;
  return dateTime;
};

export {
  numberFormat,
  speedFormat,
  fuelEfficiencyFormat,
  currencyFormat,
  dateFormat,
  dateAndTimeFormat,
  formatDateUsingOneOffUnstandardRequestedFormat,
  formatDateWithDay,
  formatDateWithMonth,
  formatSpelledOutMonthAndYear,
  formatTime,
  formatTimeAndDate,
  formatTimeWithZone,
  findTimezone,
  findTimezoneAbbreviation,
  timeAndDateFormat
};
