import React, { Component } from 'react';
import Faq from 'react-faq-component';
import '../faqCanada/faqCA.scss';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import goback from '../../../assets/faq/goback_fr.png';
import { historyBack } from './faqCAhome';
import { setTitle } from '../../../providers/documentTitleProvider';

export default class RemoteFaqCA extends Component {
  componentDidMount () {
    setTitle('document_titles.faq.remote');
  }
  render () {
    let translator = getLocaleTranslator();

    return (
      <div className='faqCAheader' role='main' id='main-content'>
        <div className='goback'>
          <span onClick={historyBack}>
            <img src={goback} alt='viewfaq' width='100'></img>
          </span>
        </div>
        <div className='message-header'>
          <div className='td-ms-header'>
            <h1>{translator.t('remote_title')}</h1>
          </div>
        </div>
        <Faq data={data} />
      </div>
    );
  }
}

const data = {
  rows: [
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Que pouvez-vous voir dans la section « Remote » (fonctions à distance) de l’onglet d’accueil de l’application
          mobile Car-NetMD de Volkswagen?
        </div>
      ),
      content: ` <span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span> Après avoir ouvert une session dans l’application mobile, le premier écran affiché est l’information sur le véhicule sous l’onglet d’accueil. Vous y trouverez trois fonctions principales : « Remote » (fonctions à distance), « Trips » (voyages) et « Buy Plans » (acheter un forfait). Voici une liste des fonctions accessibles à distance :</br>

        <li> Activer le klaxon et faire clignoter les feux du véhicule</li></br>
        <li> Verrouiller et déverrouiller les portes</li></br>
        <li> Démarrage et arrêt à distance (avec certaines versions)</li></br>
        <li> Vérifier le niveau de carburant</li></br>
        <li> Voir le kilométrage au compteur</li></br>
        <li> Vérifier si le véhicule est sécurisé (toutes les vitres sont fermées et toutes les portes sont fermées et verrouillées).</li>

      
      `
    }
  ]
};

export { RemoteFaqCA };
