import React from 'react';
import { getLocaleTranslator, findErrorMessage } from '../../../util/i18n/i18nService';
import { fetchUserProfile, PHONE_TYPE, saveUserProfile } from '../providers/SelfEnrollmentProvider';
import { UncontrolledAlert } from 'reactstrap';

import ToolTip from '../common/ToolTip';
import './YourProfile.scss';
import '../common/cwpRadioInputOverwrite.scss';
import { formatPhoneNumber, MaxBirthDate, MinBirthDate, digitsOnly, US_PHONE_NUMBER_PATTERN } from '../CarnetHome';
import { ADDRESS_VERIFICATION_STATUS, validateAddress } from '../providers/ThirdPartyProvider';
import CwpDatePicker from '../common/CwpDatePicker';
import VerifyAddress from './VerifyAddress';
import { filterInvalidInputCharacters, findIn } from '../../../util/utilityMethods';
import { setTitle } from '../../../providers/documentTitleProvider';
import ThemedToast from '../../reusable_cmp_lib/ThemedToast/ThemedToast';

class YourProfile extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      alert: null,
      processing: false,

      suffix: null,
      firstName: null,
      lastName: null,
      birthDate: '',
      birthDateMin: MinBirthDate(),
      birthDateMax: MaxBirthDate(),
      selectedQuestion1: {},
      selectedQuestion2: {},

      phonePrimary: { primary: true, type: PHONE_TYPE.CELL_PHONE_NUM },
      phoneOptional1: { primary: false, type: PHONE_TYPE.CELL_PHONE_NUM }, // setting a default type for optional phone numbers
      phoneOptional2: { primary: false, type: PHONE_TYPE.CELL_PHONE_NUM }, // setting a default type for optional phone numbers

      addressLine1: null,
      addressLine2: null,
      city: null,
      state: null,
      country: null,
      zipCode: null,
      localeCode: null,

      securityQuestionList: null,
      countries: [],
      states: [],
      languageList: [],

      suggestedAddresses: [],

      submitted: false // one time catch flag to prevent invalid style before submitting
    };

    this.translator = getLocaleTranslator();
  }

  submitProfile = async address => {
    if (!address) {
      return;
    }

    this.setState({
      processing: true,
      suggestedAddresses: [],
      addressLine1: address.address1,
      addressLine2: address.address2,
      city: address.city,
      state: address.state,
      country: address.country,
      zipCode: address.postalCode
    });

    const data = { profileStatus: this.props.profileStatus };

    ({
      address1: data.addressLine1,
      address2: data.addressLine2,
      city: data.city,
      state: data.state,
      country: data.country,
      postalCode: data.zipCode
    } = address);

    ({
      suffix: data.suffix,
      firstName: data.firstName,
      lastName: data.lastName,
      birthDate: data.birthDate,
      selectedQuestion1: data.selectedQuestion1,
      selectedQuestion2: data.selectedQuestion2,
      phonePrimary: data.phonePrimary,
      phoneOptional1: data.phoneOptional1,
      phoneOptional2: data.phoneOptional2,
      localeCode: data.localeCode
    } = this.state);

    // Removing optional attributes if blank
    if (!data.suffix) {
      delete data['suffix'];
    }

    if (!data.birthDate) {
      delete data['birthDate'];
    }

    if (!data.addressLine2) {
      delete data['addressLine2'];
    }

    // remove entries that don't have a phone number assigned
    if (!data.phoneOptional1.number) {
      delete data.phoneOptional1;
    }
    if (!data.phoneOptional2.number) {
      delete data.phoneOptional2;
    }

    try {
      await saveUserProfile(data);

      this.setState({ processing: false });

      this.props.onSuccess();
    } catch (error) {
      this.setState({ processing: false, alert: findErrorMessage(error) });
    }
  };

  getAddress = () => {
    return {
      address1: this.state.addressLine1,
      address2: this.state.addressLine2 || undefined,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
      postalCode: this.state.zipCode
    };
  };

  createFullAddressString = () => {
    return `${this.state.addressLine1} ${this.state.addressLine2 || ''} ${this.state.city} ${this.state.state} ${
      this.state.zipCode
    }`;
  };

  forceUnvalidatedAddressSubmission = () => {
    const address = this.getAddress();
    this.submitProfile(address);
  };

  validateAddress = async () => {
    const valid = this.form.checkValidity();

    if (!valid) {
      this.form.reportValidity();
      return;
    }

    const address = this.getAddress();

    this.setState({ suggestedAddresses: [], processing: true });
    try {
      const addressCheck = await validateAddress(address);

      if (!addressCheck || typeof addressCheck !== 'object') {
        this.setState({ processing: false, alert: 'Failed to validate address' });
      }

      if (Array.isArray(addressCheck.suggestedAddresses) && addressCheck.suggestedAddresses.length > 0) {
        if (JSON.stringify(address) === JSON.stringify(addressCheck.suggestedAddresses[0])) {
          this.submitProfile(address);

          return;
        }

        this.setState({ processing: false, suggestedAddresses: addressCheck.suggestedAddresses });

        return;
      } else if (addressCheck.verificationStatus === ADDRESS_VERIFICATION_STATUS.VERIFIED) {
        this.submitProfile(address);
      } else {
        this.setState({ processing: false, displayUserAddressOverrideOption: true });
      }
    } catch (error) {
      this.setState({ processing: false, alert: findErrorMessage(error) });

      return;
    }
  };

  pullAddressSuggestions = () => {
    if (this.state.country && this.state.state && this.state.addressLine1) {
      /*
      const response = findAddressSuggestions({
        addressLine1: this.state.addressLine1,
        addressLine2: this.state.addressLine2 || undefined,
        city: this.state.city || undefined,
        state: this.state.state,
        country: this.state.country,
        zipCode: this.state.zipCode || undefined
      });

      console.log(response);
      */
    }
  };

  componentDidMount = async () => {
    setTitle('document_titles.profile_page');
    try {
      const data = await fetchUserProfile();

      if (data === null || typeof data !== 'object') {
        return;
      }

      this.initCountryAndStates(data);

      if (findIn('selectedQuestion1.questionType', data)) {
        delete data.selectedQuestion1.questionType;
      }

      if (findIn('selectedQuestion2.questionType', data)) {
        delete data.selectedQuestion2.questionType;
      }

      this.setState({ ...data });
    } catch (error) {
      this.setState({ alert: findErrorMessage(error) });
    }
  };

  componentDidUpdate (prevProps, prevState) {
    let curStateAddress = this.getAddress();
    // looks like we verified address, lets make sure title is correct
    if (!prevState.suggestedAddresses.length && this.state.suggestedAddresses.length) {
      setTitle('document_titles.profile_page_verify_address');
    } else {
      // another case where we verified address and selected differently, doesn't clear suggested list out
      for (let key in curStateAddress) {
        if (curStateAddress[key] !== prevState[key]) {
          setTitle('document_titles.profile_page');
        }
      }
    }
  }

  initCountryAndStates = data => {
    if (Array.isArray(data.countries) && data.countries.length > 0) {
      if (typeof data.country === 'string') {
        const countryInAddress = data.countries.find(c => c.countryCode === data.country);
        if (countryInAddress) {
          data.states = countryInAddress.states;
        }
      }
    }
  };

  parseToResponse = securityQuestion => {
    if (!securityQuestion) {
      return {};
    }

    const selectedQuestion = {};
    ({
      questionId: selectedQuestion.questionId,
      questionResponse: selectedQuestion.questionResponse
    } = securityQuestion);

    return selectedQuestion;
  };

  selectChallengeQuestion = (name, id) => {
    const question = this.state.securityQuestionList.find(q => q.questionId === Number(id));

    if (question) {
      this.setState({ [name]: this.parseToResponse(question) });

      return;
    }

    this.setState({ [name]: {} });
  };

  setQuestionResponse = (name, response) => {
    const question = this.state[name];

    question.questionResponse = response;

    this.setState({ [name]: { ...question } });
  };

  toggleStates = e => {
    const countryCode = e.currentTarget.value;

    if (countryCode && countryCode !== '') {
      const country = this.state.countries.find(country => country.countryCode === countryCode);
      if (country && country.states && Array.isArray(country.states)) {
        this.setState({ country: countryCode, states: country.states });

        return;
      }
    }

    this.setState({ country: countryCode, states: [] });
  };

  togglePhoneType = (phoneName, type) => {
    const phone = this.state[phoneName];
    if (phone) {
      phone.type = type;
      this.setState({ [phoneName]: { ...phone } });
    }
  };

  clearAlerts = () => this.setState({ alert: null });

  getAlerts = () => {
    if (this.state.alert) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts}>
          {this.state.alert}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  findFieldLabelStyleClass = value => {
    if (value) {
      return 'cwp';
    }

    return 'cwp floating';
  };

  birthdateChanged = dateIsoString => {
    let valueToSet = '';
    if (!!dateIsoString) {
      valueToSet = dateIsoString;
    }
    // console.log('YourProfile >> birthdateChanged ' + valueToSet);
    this.setState({ birthDate: valueToSet });
  };

  setCustomValidity = (target, message) => {
    if (!target) {
      return;
    }

    target.setCustomValidity(message);
    if (!target.getAttribute('title')) {
      target.setAttribute('title', message);
    }
  };

  render = () => {
    if (Array.isArray(this.state.suggestedAddresses) && this.state.suggestedAddresses.length > 0) {
      return (
        <VerifyAddress
          originalAddress={this.getAddress()}
          suggestedAddresses={this.state.suggestedAddresses}
          onSuccess={this.submitProfile}
          onAbort={() => this.setState({ suggestedAddresses: [] })}
        />
      );
    }
    {
      /* 
    let optionsSecurityQuestion1 = null;
    let optionsSecurityQuestion2 = null;
    if (this.state.securityQuestionList && Array.isArray(this.state.securityQuestionList)) {
      optionsSecurityQuestion1 = this.state.securityQuestionList
        .filter(question => question.questionId !== this.state.selectedQuestion2.questionId)
        .map(question => (
          <option label={question.questionText} value={question.questionId}>
            {question.questionText}
          </option>
        ));
      optionsSecurityQuestion2 = this.state.securityQuestionList
        .filter(question => question.questionId !== this.state.selectedQuestion1.questionId)
        .map(question => (
          <option label={question.questionText} value={question.questionId}>
            {question.questionText}
          </option>
        ));
    }
  */
    }

    let localeOptions = null;

    if (this.state.locales) {
      localeOptions = this.state.locales.map(locale => (
        <option value={locale.localeCode} label={locale.description} key={'select_locale_option_' + locale.localeCode}>
          {locale.description}
        </option>
      ));
    }

    return (
      <form ref={form => (this.form = form)} className='formYourProfile' role='main' id='main-content'>
        <h1>
          {this.translator.t('your_information.your')}{' '}
          <strong>{this.translator.t('your_information.information')} </strong>
        </h1>
        {this.getAlerts()}

        {this.state.displayUserAddressOverrideOption && (
          <ThemedToast
            title={this.translator.t('generic_modals.unknown_address.title')}
            msg={this.translator.t('generic_modals.unknown_address.body')}
            msg2={this.createFullAddressString()}
            cancelButtonLabel={this.translator.t('generic_modals.unknown_address.cancelButtonLabel')}
            confirmButtonLabel={this.translator.t('generic_modals.unknown_address.confirmButtonLabel')}
            displaySecondButton={true}
            display={true}
            onConfirmClick={() => {
              this.forceUnvalidatedAddressSubmission();
            }}
            onCancelClick={() => {
              this.setState({
                displayUserAddressOverrideOption: false
              });
            }}
          />
        )}

        <div className='row mt-5'>
          <div className='col-md-4 border-right borderOnBigScreenOnly'>
            <div className='cwpCol'>
              <div className='form-group'>
                <label htmlFor='title' className={this.findFieldLabelStyleClass(this.state.suffix) + ' selectBox'}>
                  {this.translator.t('your_information.title_optional')}
                </label>
                <select
                  id='title'
                  value={this.state.suffix}
                  className='cwp'
                  onChange={e => this.setState({ suffix: e.currentTarget.value })}
                >
                  <option label={this.translator.t('your_information.title_optional')} value=''>
                    {''}
                  </option>
                  <option value='MR'>{this.translator.t('your_information.mr')}</option>
                  <option value='MS'>{this.translator.t('your_information.ms')}</option>
                  <option value='MRS'>{this.translator.t('your_information.mrs')}</option>
                </select>
              </div>

              <div className='form-group'>
                <label htmlFor='firstName' className={this.findFieldLabelStyleClass(this.state.firstName)}>
                  {this.translator.t('your_information.first_name')}
                </label>
                <input
                  id='firstName'
                  type='text'
                  placeholder={this.translator.t('your_information.first_name')}
                  value={this.state.firstName}
                  className={this.state.submitted ? 'cwp submitted' : 'cwp'}
                  required={true}
                  onChange={e => this.setState({ firstName: filterInvalidInputCharacters(e.currentTarget.value) })}
                  onInvalid={e =>
                    this.setCustomValidity(e.currentTarget, this.translator.t('validation_message.required_first_name'))
                  }
                  onInput={e => this.setCustomValidity(e.currentTarget, '')}
                />
              </div>

              <div className='form-group'>
                <label htmlFor='lastName' className={this.findFieldLabelStyleClass(this.state.lastName)}>
                  {this.translator.t('your_information.last_name')}
                </label>
                <input
                  id='lastName'
                  type='text'
                  placeholder={this.translator.t('your_information.last_name')}
                  value={this.state.lastName}
                  className={this.state.submitted ? 'cwp submitted' : 'cwp'}
                  required={true}
                  onInvalid={e =>
                    this.setCustomValidity(e.currentTarget, this.translator.t('validation_message.required_last_name'))
                  }
                  onInput={e => this.setCustomValidity(e.currentTarget, '')}
                  onChange={e => this.setState({ lastName: filterInvalidInputCharacters(e.currentTarget.value) })}
                />
              </div>

              <div className='form-group formSectionFourhundred'>
                <label htmlFor='dob' className='cwp'>
                  {this.translator.t('your_information.dob_optinal')}
                </label>
                <CwpDatePicker
                  id={'dob'}
                  date={this.state.birthDate}
                  min={this.state.birthDateMin}
                  max={this.state.birthDateMax}
                  onChange={this.birthdateChanged}
                />
              </div>
              {/* 
              <div className='form-group'>
                <label
                  htmlFor='challengeQuestion1'
                  className={this.findFieldLabelStyleClass(this.state.selectedQuestion1.questionId)}
                >
                  {this.translator.t('your_information.challenge_question_1')}
                </label>
                <select
                  id='challengeQuestion1'
                  className={this.state.submitted ? 'cwp submitted' : 'cwp'}
                  value={this.state.selectedQuestion1.questionId}
                  onChange={e => this.selectChallengeQuestion('selectedQuestion1', e.currentTarget.value)}
                  required={true}
                >
                  <option value={''}>{this.translator.t('your_information.challenge_question_1')}</option>
                  {optionsSecurityQuestion1}
                </select>
              </div>

              <div className='form-group'>
                <label
                  htmlFor='answer1'
                  className={this.findFieldLabelStyleClass(this.state.selectedQuestion1.questionResponse)}
                >
                  {this.translator.t('your_information.answer_to_question_1')}
                </label>
                <input
                  id='answer1'
                  type='password'
                  placeholder={this.translator.t('your_information.answer_to_question_1')}
                  value={this.state.selectedQuestion1.questionResponse}
                  onChange={e => this.setQuestionResponse('selectedQuestion1', e.currentTarget.value)}
                  className={this.state.submitted ? 'cwp submitted' : 'cwp'}
                  required={true}
                  autoComplete='new-password'
                />
              </div>

              <div className='form-group'>
                <label
                  htmlFor='challengeQuestion2'
                  className={this.findFieldLabelStyleClass(this.state.selectedQuestion2.questionId)}
                >
                  {this.translator.t('your_information.challenge_question_2')}
                </label>
                <select
                  id='challengeQuestion2'
                  className={this.state.submitted ? 'cwp submitted' : 'cwp'}
                  value={this.state.selectedQuestion2.questionId}
                  onChange={e => this.selectChallengeQuestion('selectedQuestion2', e.currentTarget.value)}
                  required={true}
                >
                  <option value={''}>{this.translator.t('your_information.challenge_question_2')}</option>
                  {optionsSecurityQuestion2}
                </select>
              </div>

              <div className='form-group'>
                <label
                  htmlFor='answer2'
                  className={this.findFieldLabelStyleClass(this.state.selectedQuestion2.questionResponse)}
                >
                  {this.translator.t('your_information.answer_to_question_2')}
                </label>
                <input
                  id='answer2'
                  type='password'
                  placeholder={this.translator.t('your_information.answer_to_question_2')}
                  value={this.state.selectedQuestion2.questionResponse}
                  onChange={e => this.setQuestionResponse('selectedQuestion2', e.currentTarget.value)}
                  className={this.state.submitted ? 'cwp submitted' : 'cwp'}
                  required={true}
                  autoComplete='new-password'
                />
              </div> */}
            </div>
          </div>
          <div className='col-md-4'>
            <div className='cwpCol'>
              <div className='form-group'>
                <label htmlFor='cellPhoneNum' className={this.findFieldLabelStyleClass(this.state.phonePrimary.number)}>
                  {this.translator.t('your_information.mobile_phone_number')}
                </label>
                <input
                  id='cellPhoneNum'
                  type='tel'
                  placeholder={this.translator.t('your_information.mobile_phone_number')}
                  value={formatPhoneNumber(this.state.phonePrimary.number)}
                  className={this.state.submitted ? 'cwp submitted' : 'cwp'}
                  pattern={US_PHONE_NUMBER_PATTERN}
                  title={this.translator.t('your_information.phone_number_format_info')}
                  required={true}
                  onInvalid={e => {
                    this.setCustomValidity(
                      e.currentTarget,
                      this.translator.t(
                        e.currentTarget.value === ''
                          ? 'validation_message.required_mobile_phone_number'
                          : 'your_information.phone_number_format_info'
                      )
                    );
                  }}
                  onInput={e => this.setCustomValidity(e.currentTarget, '')}
                  maxLength={14}
                  onChange={e => {
                    e.preventDefault();
                    const phonePrimary = { ...this.state.phonePrimary };
                    let phoneDigits = digitsOnly(e.currentTarget.value);
                    // do comparison if is the exact same, means an attempt to delete a formatting character happened
                    if (phoneDigits && phoneDigits === this.state.phonePrimary.number) {
                      phoneDigits = phoneDigits.slice(0, -1);
                    }
                    phonePrimary.number = phoneDigits;
                    this.setState({ phonePrimary });
                  }}
                />
              </div>

              <div className='form-group'>
                <label
                  htmlFor='optionalPhoneNum1'
                  className={this.findFieldLabelStyleClass(this.state.phoneOptional1.number)}
                >
                  {this.translator.t('your_information.phone_number_optional')}
                </label>
                <input
                  id='optionalPhoneNum1'
                  type='tel'
                  placeholder={this.translator.t('your_information.phone_number_optional')}
                  value={formatPhoneNumber(this.state.phoneOptional1.number)}
                  className={this.state.submitted ? 'cwp submitted' : 'cwp'}
                  pattern={US_PHONE_NUMBER_PATTERN}
                  title={this.translator.t('your_information.phone_number_format_info')}
                  maxLength={14}
                  onChange={e => {
                    e.preventDefault();
                    const phone = this.state.phoneOptional1;
                    let phoneDigits = digitsOnly(e.currentTarget.value);
                    // do comparison if is the exact same, means an attempt to delete a formatting character happened
                    if (phoneDigits && phoneDigits === this.state.phoneOptional1.number) {
                      phoneDigits = phoneDigits.slice(0, -1);
                    }
                    phone.number = phoneDigits;
                    this.setState({ phoneOptional1: { ...phone } });
                  }}
                />

                <fieldset id='optionalPhoneNumber01Type'>
                  <legend className='sr-only'>{this.translator.t('your_information.optional_phone_number_1')}</legend>
                  <div
                    className='radioCwpOverwriteWrapper'
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.togglePhoneType('phoneOptional1', PHONE_TYPE.CELL_PHONE_NUM);
                    }}
                  >
                    <input
                      id={'optionalPhoneNumber01Type_CELL_PHONE_NUM'}
                      type='radio'
                      className='radioCwpOverwrite'
                      name='optionalPhoneNumber01Type'
                      checked={this.state.phoneOptional1.type === PHONE_TYPE.CELL_PHONE_NUM}
                      onChange={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.togglePhoneType('phoneOptional1', PHONE_TYPE.CELL_PHONE_NUM);
                      }}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.togglePhoneType('phoneOptional1', PHONE_TYPE.CELL_PHONE_NUM);
                      }}
                      onKeyPress={e => {
                        if (e.key && (/enter/gi.test(e.key.toString()) || e.key === ' ')) {
                          this.togglePhoneType('phoneOptional1', PHONE_TYPE.CELL_PHONE_NUM);
                        }
                      }}
                      value={this.translator.t('your_information.mobile_phone')}
                    />
                    <label className='radioCwpOverwriteLabel' htmlFor={'optionalPhoneNumber01Type_CELL_PHONE_NUM'}>
                      {this.translator.t('your_information.mobile_phone')}
                    </label>
                  </div>

                  <div
                    className='radioCwpOverwriteWrapper'
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.togglePhoneType('phoneOptional1', PHONE_TYPE.HOME_PHONE_NUM);
                    }}
                  >
                    <input
                      id={'optionalPhoneNumber01Type_HOME_PHONE_NUM'}
                      type='radio'
                      className='radioCwpOverwrite'
                      name='optionalPhoneNumber01Type'
                      checked={this.state.phoneOptional1.type === PHONE_TYPE.HOME_PHONE_NUM}
                      onChange={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.togglePhoneType('phoneOptional1', PHONE_TYPE.HOME_PHONE_NUM);
                      }}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.togglePhoneType('phoneOptional1', PHONE_TYPE.HOME_PHONE_NUM);
                      }}
                      onKeyPress={e => {
                        if (e.key && (/enter/gi.test(e.key.toString()) || e.key === ' ')) {
                          this.togglePhoneType('phoneOptional1', PHONE_TYPE.HOME_PHONE_NUM);
                        }
                      }}
                      value={this.translator.t('your_information.home_phone')}
                    />
                    <label className='radioCwpOverwriteLabel' htmlFor={'optionalPhoneNumber01Type_HOME_PHONE_NUM'}>
                      {this.translator.t('your_information.home_phone')}
                    </label>
                  </div>

                  <div
                    className='radioCwpOverwriteWrapper'
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.togglePhoneType('phoneOptional1', PHONE_TYPE.BUS_PHONE_NUM);
                    }}
                  >
                    <input
                      id={'optionalPhoneNumber01Type_BUS_PHONE_NUM'}
                      type='radio'
                      className='radioCwpOverwrite'
                      name='optionalPhoneNumber01Type'
                      checked={this.state.phoneOptional1.type === PHONE_TYPE.BUS_PHONE_NUM}
                      onChange={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.togglePhoneType('phoneOptional1', PHONE_TYPE.BUS_PHONE_NUM);
                      }}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.togglePhoneType('phoneOptional1', PHONE_TYPE.BUS_PHONE_NUM);
                      }}
                      onKeyPress={e => {
                        if (e.key && (/enter/gi.test(e.key.toString()) || e.key === ' ')) {
                          this.togglePhoneType('phoneOptional1', PHONE_TYPE.BUS_PHONE_NUM);
                        }
                      }}
                      value={this.translator.t('your_information.work_phone')}
                    />
                    <label className='radioCwpOverwriteLabel' htmlFor={'optionalPhoneNumber01Type_BUS_PHONE_NUM'}>
                      {this.translator.t('your_information.work_phone')}
                    </label>
                  </div>
                </fieldset>
              </div>

              <div className='form-group'>
                <label
                  htmlFor='optionalPhoneNum2'
                  className={this.findFieldLabelStyleClass(this.state.phoneOptional2.number)}
                >
                  {this.translator.t('your_information.phone_number_optional')}
                </label>
                <input
                  id='optionalPhoneNum2'
                  type='tel'
                  placeholder={this.translator.t('your_information.phone_number_optional')}
                  value={formatPhoneNumber(this.state.phoneOptional2.number)}
                  className={this.state.submitted ? 'cwp submitted' : 'cwp'}
                  pattern={US_PHONE_NUMBER_PATTERN}
                  title={this.translator.t('your_information.phone_number_format_info')}
                  maxLength={14}
                  onChange={e => {
                    e.preventDefault();
                    const phone = this.state.phoneOptional2;
                    let phoneDigits = digitsOnly(e.currentTarget.value);
                    // do comparison if is the exact same, means an attempt to delete a formatting character happened
                    if (phoneDigits && phoneDigits === this.state.phoneOptional2.number) {
                      phoneDigits = phoneDigits.slice(0, -1);
                    }

                    phone.number = phoneDigits;

                    this.setState({ phoneOptional2: { ...phone } });
                  }}
                />

                <fieldset id='optionalPhoneNumber02Type'>
                  <legend className='sr-only'>{this.translator.t('your_information.optional_phone_number_2')}</legend>
                  <div
                    className='radioCwpOverwriteWrapper'
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.togglePhoneType('phoneOptional2', PHONE_TYPE.CELL_PHONE_NUM);
                    }}
                  >
                    <input
                      id={'optionalPhoneNumber02Type_CELL_PHONE_NUM'}
                      type='radio'
                      name='optionalPhoneNumber02Type'
                      checked={this.state.phoneOptional2.type === PHONE_TYPE.CELL_PHONE_NUM}
                      className='radioCwpOverwrite'
                      onChange={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.togglePhoneType('phoneOptional2', PHONE_TYPE.CELL_PHONE_NUM);
                      }}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.togglePhoneType('phoneOptional2', PHONE_TYPE.CELL_PHONE_NUM);
                      }}
                      onKeyPress={e => {
                        if (e.key && (/enter/gi.test(e.key.toString()) || e.key === ' ')) {
                          this.togglePhoneType('phoneOptional2', PHONE_TYPE.CELL_PHONE_NUM);
                        }
                      }}
                      value={this.translator.t('your_information.mobile_phone')}
                    />
                    <label className='radioCwpOverwriteLabel' htmlFor={'optionalPhoneNumber02Type_CELL_PHONE_NUM'}>
                      {this.translator.t('your_information.mobile_phone')}
                    </label>
                  </div>

                  <div
                    className='radioCwpOverwriteWrapper'
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.togglePhoneType('phoneOptional2', PHONE_TYPE.HOME_PHONE_NUM);
                    }}
                  >
                    <input
                      id={'optionalPhoneNumber02Type_HOME_PHONE_NUM'}
                      type='radio'
                      className='radioCwpOverwrite'
                      name='optionalPhoneNumber02Type'
                      checked={this.state.phoneOptional2.type === PHONE_TYPE.HOME_PHONE_NUM}
                      onChange={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.togglePhoneType('phoneOptional2', PHONE_TYPE.HOME_PHONE_NUM);
                      }}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.togglePhoneType('phoneOptional2', PHONE_TYPE.HOME_PHONE_NUM);
                      }}
                      onKeyPress={e => {
                        if (e.key && (/enter/gi.test(e.key.toString()) || e.key === ' ')) {
                          this.togglePhoneType('phoneOptional2', PHONE_TYPE.HOME_PHONE_NUM);
                        }
                      }}
                      value={this.translator.t('your_information.home_phone')}
                    />
                    <label className='radioCwpOverwriteLabel' htmlFor={'optionalPhoneNumber02Type_HOME_PHONE_NUM'}>
                      {this.translator.t('your_information.home_phone')}
                    </label>
                  </div>

                  <div
                    className='radioCwpOverwriteWrapper'
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.togglePhoneType('phoneOptional2', PHONE_TYPE.BUS_PHONE_NUM);
                    }}
                  >
                    <input
                      id={'optionalPhoneNumber02Type_BUS_PHONE_NUM'}
                      type='radio'
                      className='radioCwpOverwrite'
                      name='optionalPhoneNumber02Type'
                      checked={this.state.phoneOptional2.type === PHONE_TYPE.BUS_PHONE_NUM}
                      onChange={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.togglePhoneType('phoneOptional2', PHONE_TYPE.BUS_PHONE_NUM);
                      }}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.togglePhoneType('phoneOptional2', PHONE_TYPE.BUS_PHONE_NUM);
                      }}
                      onKeyPress={e => {
                        if (e.key && (/enter/gi.test(e.key.toString()) || e.key === ' ')) {
                          this.togglePhoneType('phoneOptional2', PHONE_TYPE.BUS_PHONE_NUM);
                        }
                      }}
                      value={this.translator.t('your_information.work_phone')}
                    />
                    <label className='radioCwpOverwriteLabel' htmlFor={'optionalPhoneNumber02Type_BUS_PHONE_NUM'}>
                      {this.translator.t('your_information.work_phone')}
                    </label>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
          <div className='col-md-4 border-left borderOnBigScreenOnly'>
            <div className='cwpCol'>
              <div className='form-group'>
                <label htmlFor='country' className={this.findFieldLabelStyleClass(this.state.country) + ' selectBox'}>
                  {this.translator.t('your_information.country')}
                </label>
                <select
                  id='country'
                  className={this.state.submitted ? 'cwp submitted' : 'cwp'}
                  required={true}
                  onInvalid={e =>
                    this.setCustomValidity(e.currentTarget, this.translator.t('validation_message.required_country'))
                  }
                  onInput={e => this.setCustomValidity(e.currentTarget, '')}
                  onChange={this.toggleStates}
                  value={this.state.country}
                >
                  <option value={''} label={this.translator.t('your_information.country')}>
                    {''}
                  </option>
                  {this.state.countries.map(country => (
                    <option
                      label={country.countryDesc}
                      value={country.countryCode}
                      key={'select_country_' + country.countryCode}
                    >
                      {country.countryDesc}
                    </option>
                  ))}
                </select>
              </div>
              <div className='form-group'>
                <label htmlFor='addressLine1' className={this.findFieldLabelStyleClass(this.state.addressLine1)}>
                  {this.translator.t('your_information.street_address')}
                </label>
                <input
                  id='addressLine1'
                  type='text'
                  placeholder={this.translator.t('your_information.street_address')}
                  className={this.state.submitted ? 'cwp submitted' : 'cwp'}
                  required={true}
                  onInvalid={e =>
                    this.setCustomValidity(
                      e.currentTarget,
                      this.translator.t('validation_message.required_street_address')
                    )
                  }
                  onInput={e => this.setCustomValidity(e.currentTarget, '')}
                  value={this.state.addressLine1}
                  onChange={e => this.setState({ addressLine1: e.currentTarget.value }, this.pullAddressSuggestions)}
                />
              </div>

              <div className='form-group'>
                <label htmlFor='addressLine2' className={this.findFieldLabelStyleClass(this.state.addressLine2)}>
                  {this.translator.t('your_information.suite_apt_building_etc_optional')}
                </label>
                <input
                  id='addressLine2'
                  type='text'
                  placeholder={this.translator.t('your_information.suite_apt_building_etc_optional')}
                  className='cwp'
                  value={this.state.addressLine2}
                  onChange={e => this.setState({ addressLine2: e.currentTarget.value })}
                />
              </div>

              <div className='form-group'>
                <label htmlFor='city' className={this.findFieldLabelStyleClass(this.state.city) + ' w-75'}>
                  {this.translator.t('your_information.city')}
                </label>
                <input
                  id='city'
                  type='text'
                  placeholder={this.translator.t('your_information.city')}
                  className={this.state.submitted ? 'cwp submitted' : 'cwp'}
                  required={true}
                  onInvalid={e =>
                    this.setCustomValidity(e.currentTarget, this.translator.t('validation_message.required_city'))
                  }
                  onInput={e => this.setCustomValidity(e.currentTarget, '')}
                  value={this.state.city}
                  onChange={e => this.setState({ city: e.currentTarget.value })}
                />
              </div>

              <div className='form-group'>
                <label htmlFor='state' className={this.findFieldLabelStyleClass(this.state.state) + ' selectBox'}>
                  {this.translator.t('your_information.state')}
                </label>
                <select
                  id='state'
                  className={this.state.submitted ? 'cwp submitted' : 'cwp'}
                  required={true}
                  onInvalid={e =>
                    this.setCustomValidity(e.currentTarget, this.translator.t('validation_message.required_province'))
                  }
                  onInput={e => this.setCustomValidity(e.currentTarget, '')}
                  value={this.state.state}
                  onChange={e => this.setState({ state: e.currentTarget.value })}
                >
                  <option value={''} label={this.translator.t('your_information.state')}>
                    {''}
                  </option>
                  {this.state.states.map(state => (
                    <option label={state.stateDesc} value={state.stateCode}>
                      {state.stateDesc}
                    </option>
                  ))}
                </select>
              </div>

              <div className='form-group'>
                <label htmlFor='zipCode' className={this.findFieldLabelStyleClass(this.state.zipCode)}>
                  {this.translator.t('your_information.zip_code')}
                </label>
                <input
                  id='zipCode'
                  type='text'
                  placeholder={this.translator.t('your_information.zip_code')}
                  className={this.state.submitted ? 'cwp submitted' : 'cwp'}
                  maxLength={16}
                  required={true}
                  onInvalid={e =>
                    this.setCustomValidity(
                      e.currentTarget,
                      this.translator.t('validation_message.required_postal_code')
                    )
                  }
                  onInput={e => this.setCustomValidity(e.currentTarget, '')}
                  value={this.state.zipCode}
                  onChange={e => this.setState({ zipCode: e.currentTarget.value })}
                />
              </div>
              <div className='form-group'>
                <div>
                  <label htmlFor='language' className='cwp'>
                    {this.translator.t('your_information.communication_language')}
                  </label>
                  <select
                    id='language'
                    className={this.state.submitted ? 'cwp submitted' : 'cwp'}
                    required={true}
                    onInvalid={e =>
                      this.setCustomValidity(e.currentTarget, this.translator.t('validation_message.required_field'))
                    }
                    onInput={e => this.setCustomValidity(e.currentTarget, '')}
                    value={this.state.localeCode}
                    onChange={e => this.setState({ localeCode: e.currentTarget.value })}
                  >
                    <option
                      value={null}
                      label={this.translator.t('your_information.communication_language')}
                      disabled={true}
                    >
                      {this.translator.t('your_information.communication_language')}
                    </option>

                    {localeOptions}
                  </select>
                  <ToolTip hoverText={this.translator.t('your_information.pref_lang_tooltip')} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='form-group'>
          <input
            type='button'
            className='cwp'
            value={this.translator.t('your_information.submit')}
            onClick={e => {
              // noting that user attempted to submit
              e.preventDefault();
              this.setState({ submitted: true });
              this.validateAddress();
              return false;
            }}
            disabled={this.state.processing}
          />
        </div>
      </form>
    );
  };
}

export default YourProfile;
