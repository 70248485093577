import React from 'react';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { isBlankString } from '../../../util/utilityMethods';
import { ADDRESS_VERIFICATION_STATUS, validateAddress } from '../providers/ThirdPartyProvider';
import { Container, Row, Col, Input, Button, ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap';
import ThemedToast from '../../reusable_cmp_lib/ThemedToast/ThemedToast';
import { getStatesOrProvincesByCountry } from '../providers/AccountAndSecurityProvider';
import '../../reusable_cmp_lib/ThemedFormInputs/ThemedForm.scss';
import '../../reusable_cmp_lib/ThemedButtonsAssortment/ThemedButtonsAssortment.scss';
import './AccountManagement.scss';
import { setTitle } from '../../../providers/documentTitleProvider';

const INVALID_FLAG = 'invalid';
const VALID_FLAG = 'valid';

class PersonalAddressEditor extends React.Component {
  constructor (props) {
    super(props);
    this.addressProp = this.props.address || {};
    this.state = {
      selectedCountry: this.addressProp.country || '',
      selectedStateOrProvince: this.addressProp.state || '',
      addressLine1: this.addressProp.addressLine1 || '',
      addressLine2: this.addressProp.addressLine2 || '',
      city: this.addressProp.city || '',
      zip: this.addressProp.zipCode || '',
      processing: false,
      validationResult: null,
      addressSuggestions: null,
      countryScopedStates: null
    };
  }

  componentDidMount () {
    let initialState = { ...this.state };
    let hasInitials = false;
    let countryIsCanada = false;
    if (this.addressProp.country) {
      initialState['selectedCountry'] = this.addressProp.country;
      hasInitials = true;
      if (this.addressProp.country === 'CA' || this.addressProp.country === 'CAN') {
        countryIsCanada = true;
      }
    }
    if (this.addressProp.state) {
      initialState['selectedState'] = this.addressProp.state;
      hasInitials = true;
    }
    if (hasInitials) {
      this.setState(initialState);
      if (countryIsCanada) {
        console.log('Now loading the provinces for the dropdown...');
        this.loadStatesForCountry(this.addressProp.country);
      }
    }
  }

  loadStatesForCountry = isoCountryCode => {
    if (isoCountryCode === 'CAN') {
      isoCountryCode = 'CA';
    }
    getStatesOrProvincesByCountry(isoCountryCode)
      .then(resp => {
        if (!resp.states) {
          throw 'malformed response for country-specific states.';
        }
        const statesOrProvs = resp.states || [];
        //ReactStrap Library Dropdowns require their Records to be in a Different
        //Format for Dropdown Options--diff format than the API sends. So, Map it here:
        let ddItems = statesOrProvs.map(item => {
          let val = item.stateDesc;
          let cd = item.stateCode;
          return { code: cd, value: val };
        });

        this.setState({
          countryScopedStates: ddItems
        });
      })
      .catch(err => {
        console.log('Error trying to load the states/provinces for the Country: ' + isoCountryCode);
        console.log(JSON.stringify(err));
        return;
      });
  };

  handleTextEditing = (fieldName, evt) => {
    let presentTxt = evt.target.value;
    let statePatch = {};
    statePatch[fieldName] = presentTxt;
    statePatch['validationResult'] = null;
    this.setState(statePatch);
  };

  addressSuggestionPicked = indexNum => {
    const pickedKnownAddress = this.state.addressSuggestions[indexNum];
    const clone = { ...pickedKnownAddress };
    //we must populate the form fields from this selection,
    //and clear out the other 2 state vars for next time...
    this.setState(
      {
        addressSuggestions: null,
        validationResult: null,
        addressLine1: clone.address1 || '',
        addressLine2: clone.address2 || '',
        city: clone.city || '',
        selectedStateOrProvince: clone.state,
        zip: clone.postalCode,
        selectedCountry: clone.country
      },
      this.passbackAndSaveSuggestedAddress(clone)
    );
  };

  validateAddressThroughSmartyStreets = async addressObj => {
    console.log('SmartyStreets-validating the address...');
    let validAddress = false;
    try {
      const addressCheck = await validateAddress(addressObj);
      if (!addressCheck || typeof addressCheck !== 'object') {
        console.log('Smarty Streets validation failed. Couldnt validate.');
        return { valid: false, error: 'SmartyStreets validation failed.Couldnt validate.' };
      }
      console.log('SmartyStreets validation result obj: ' + JSON.stringify(addressCheck));
      if (Array.isArray(addressCheck.suggestedAddresses) && addressCheck.suggestedAddresses.length > 0) {
        if (JSON.stringify(addressObj) === JSON.stringify(addressCheck.suggestedAddresses[0])) {
          validAddress = true;
        } else {
          return { valid: false, suggestions: addressCheck.suggestedAddresses };
        }
      }

      if (validAddress === false && addressCheck.verificationStatus) {
        if (addressCheck.verificationStatus === ADDRESS_VERIFICATION_STATUS.VERIFIED) {
          validAddress = true;
        }
      }
    } catch (err) {
      //try
      //Below String is NOT a label shown to the User. It only gets written to the Console logging. For us.
      const failureMsg = 'SmartyStreets validation could not complete. It failed. Error: ' + JSON.stringify(err);
      return { valid: false, error: failureMsg };
    }
    return { valid: validAddress };
  };

  passbackAndSaveSuggestedAddress = suggestedAddress => {
    let tosaveAddress = {
      addressLine1: suggestedAddress.address1,
      addressLine2: suggestedAddress.address2 || undefined,
      city: suggestedAddress.city,
      country: suggestedAddress.country,
      state: suggestedAddress.state,
      zipCode: suggestedAddress.postalCode
    };

    this.passbackAndSaveAddressObj(tosaveAddress);
  };

  passbackAndSaveAddressObj = addressObj => {
    this.props.refreshCallback(addressObj, 'address');
    let mutableCustomerProfile = JSON.parse(JSON.stringify(this.props.accountData));
    mutableCustomerProfile.address = addressObj;
    this.props.saveHandler(mutableCustomerProfile);
  };

  // user decides to save an address that smarty streets doesn't recognize
  userElectsToSaveUnvalidatedAddress = () => {
    let mutatedAddress = {
      addressLine1: this.state.addressLine1,
      addressLine2: this.state.addressLine2 || undefined,
      city: this.state.city,
      country: this.state.selectedCountry,
      state: this.state.selectedStateOrProvince,
      zipCode: this.state.zip
    };

    this.passbackAndSaveAddressObj(mutatedAddress);

    this.setState({
      validationResult: null
    });
  };

  saveEditorChanges = () => {
    let validationObject = {
      address1: this.state.addressLine1,
      address2: this.state.addressLine2 || undefined,
      city: this.state.city,
      state: this.state.selectedStateOrProvince,
      country: this.state.selectedCountry,
      postalCode: this.state.zip
    };

    this.validateAddressThroughSmartyStreets(validationObject)
      .then(validationResult => {
        if (validationResult != null) {
          if (validationResult.valid === false) {
            console.log('Address validation did not clearly recognize this address...');
            if (validationResult.suggestions && validationResult.suggestions.length > 0) {
              this.setState({
                processing: false,
                addressSuggestions: validationResult.suggestions
              });
              return;
            }

            if (validationResult.error) {
              console.log('Validation process failed: ' + validationResult.error);
              this.setState({
                processing: false,
                validationResult: INVALID_FLAG
              });
              return;
            }
          }
        } else {
          //no validation result: Validator method threw an error!
          console.log('Validator choked. Cannot validate this address. Aborting save...');
          this.setState({
            processing: false,
            validationResult: INVALID_FLAG
          });
          return;
        }
        if (validationResult.valid !== true) {
          console.log('This was determined to be an invalid address.');
          this.setState({
            processing: false,
            validationResult: INVALID_FLAG
          });
          return;
        }

        //OK, if we make it Here, weve validated the address
        let mutatedAddress = {
          addressLine1: this.state.addressLine1,
          addressLine2: this.state.addressLine2 || undefined,
          city: this.state.city,
          country: this.state.selectedCountry,
          state: this.state.selectedStateOrProvince,
          zipCode: this.state.zip
        };

        this.passbackAndSaveAddressObj(mutatedAddress);

        this.setState({
          validationResult: VALID_FLAG
        });
      })
      .catch(err => {
        console.log('Smarty Streets validation failed: ' + err);
        this.setState({
          processing: false,
          validationResult: INVALID_FLAG
        });
        return;
      });
  };

  countrySelected = item => {
    let slCountry = item.currentTarget.childNodes[0].nodeValue;
    let nxCountryCode = 'US';
    if (slCountry == 'Canada') {
      nxCountryCode = 'CA';
    }
    if (slCountry == 'United States') {
      nxCountryCode = 'US';
    }

    this.setState(
      {
        selectedCountry: slCountry,
        validationResult: null
      },
      this.loadStatesForCountry(nxCountryCode)
    );
  };

  stateOrProvinceSelected = item => {
    let slxn = item.currentTarget.value;

    this.setState({
      selectedStateOrProvince: slxn,
      validationResult: null
    });
  };

  createFullAddressString = () => {
    return `${this.state.addressLine1} ${this.state.addressLine2 || ''} ${this.state.city} ${
      this.state.selectedStateOrProvince
    } ${this.state.zip}`;
  };

  render () {
    this.translator = getLocaleTranslator();
    setTitle('document_titles.account_management.profileAddress');
    let countrySpecificSubunitLabel =
      this.addressProp.country === 'CA' ? 'acctmgmt_pi_field_label_province' : 'acctmgmt_pi_field_label_state';

    let loadedStatesItems = [];
    if (this.state.countryScopedStates && this.state.countryScopedStates.length > 0) {
      loadedStatesItems = this.state.countryScopedStates;
    } else {
      loadedStatesItems = this.props.states || [];
    }

    return (
      <Container fluid={true} id='edit-address-form'>
        {this.state.addressSuggestions && this.state.addressSuggestions.length > 0 && (
          <div className='vertical-spacer-small'>
            <Modal
              isOpen={this.state.addressSuggestions && this.state.addressSuggestions.length > 0}
              className='carnet-sg-modal'
            >
              <ModalHeader>{this.translator.t('acctmgmt_address_possible_suggs')}: </ModalHeader>
              <ModalBody>
                <br />
                {(this.state.addressSuggestions || []).map((suggestion, index) => {
                  return (
                    <div className='address-suggestion' onClick={() => this.addressSuggestionPicked(index)}>
                      <br />
                      <div className='address-suggestion-lineitem'>{suggestion.address1 || ''}</div>
                      {!isBlankString(suggestion.address2) && (
                        <div className='address-suggestion-lineitem'>{suggestion.address2 || ''}</div>
                      )}
                      <div className='address-suggestion-lineitem'>
                        {suggestion.city + ' ,' + suggestion.state + '  ' + suggestion.postalCode}
                      </div>
                      <div className='address-suggestion-lineitem-select'>
                        <span>{this.translator.t('acctmgmt_pick_suggested_address')}</span>
                      </div>
                    </div>
                  );
                })}
                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  className='modal-primary-btn'
                  color='modal_background_color'
                  onClick={() => {
                    this.setState({
                      addressSuggestions: null
                    });
                  }}
                >
                  {this.translator.t('cancel_btn_camel')}
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        )}
        <Row>
          <Col md='4'>
            {this.state.validationResult === INVALID_FLAG && (
              <ThemedToast
                title={this.translator.t('generic_modals.unknown_address.title')}
                msg={this.translator.t('generic_modals.unknown_address.body')}
                msg2={this.createFullAddressString()}
                cancelButtonLabel={this.translator.t('generic_modals.unknown_address.cancelButtonLabel')}
                confirmButtonLabel={this.translator.t('generic_modals.unknown_address.confirmButtonLabel')}
                displaySecondButton={true}
                display={true}
                onConfirmClick={() => {
                  this.userElectsToSaveUnvalidatedAddress();
                }}
                onCancelClick={() => {
                  this.setState({
                    validationResult: null
                  });
                }}
              />
            )}
          </Col>
          <Col md='8'>
            <div className='editor-firstrow-label-aligner'>
              <h2 className='first-editor-field-label'>{this.translator.t('acctmgmt_address_title')}</h2>
            </div>
          </Col>
        </Row>
        {this.state.validationResult === VALID_FLAG && (
          <Row>
            <div className='vertical-spacer-tiny' id='valid-address-indicator'>
              {this.translator.t('acctmgmt_address_validated')}
            </div>
          </Row>
        )}
        <Row>
          <Col md='4'></Col>
          <Col md='3'>
            <div className='editor-diatom-field'>
              <label htmlFor='lstCountry' className='editor-diatom-superscript-lbl'>
                {this.translator.t('acctmgmt_pi_field_label_co')}
              </label>
              <br />
              <select
                id='lstCountry'
                className='cwp'
                onChange={this.countrySelected}
                value={this.state.selectedCountry}
              >
                {this.props.countries.map(countryThing => {
                  return <option value={countryThing.code}>{countryThing.value}</option>;
                })}
              </select>
              <br />
              <br />
              <label htmlFor='pi_address_input' className='editor-diatom-superscript-lbl'>
                {this.translator.t('acctmgmt_pi_field_label_street')}
              </label>
              <Input
                className='cwp'
                onChange={evt => this.handleTextEditing('addressLine1', evt)}
                type='text'
                name='addressLine1'
                id='pi_address_input'
                value={this.state.addressLine1 || ''}
                placeholder={this.state.addressLine1 || ''}
              />
              <br />
              <br />
              <label htmlFor='pi_address_input-2' className='editor-diatom-superscript-lbl'>
                {this.translator.t('acctmgmt_pi_field_label_street_2')}
              </label>
              <Input
                className='cwp'
                type='text'
                onChange={evt => this.handleTextEditing('addressLine2', evt)}
                name='addressLine2'
                id='pi_address_input-2'
                placeholder={this.state.addressLine2 || ''}
                value={this.state.addressLine2 || ''}
              />
            </div>
          </Col>
          <Col md='1'></Col>
          <Col md='3'>
            <div className='editor-diatom-field'>
              <label htmlFor='pi_city_input' className='editor-diatom-superscript-lbl'>
                {this.translator.t('acctmgmt_pi_field_label_city')}
              </label>
              <Input
                className='cwp'
                type='text'
                name='pi_city'
                onChange={evt => this.handleTextEditing('city', evt)}
                id='pi_city_input'
                placeholder={this.state.city || ''}
                value={this.state.city || ''}
              />
              <br />
              <label htmlFor='lstProvince' className='editor-diatom-superscript-lbl'>
                {this.translator.t(countrySpecificSubunitLabel)}
              </label>
              <br />
              <select
                id='lstProvince'
                className='cwp'
                onChange={this.stateOrProvinceSelected}
                value={this.state.selectedStateOrProvince}
              >
                {loadedStatesItems.map(stateThing => {
                  return <option value={stateThing.code}>{stateThing.value}</option>;
                })}
              </select>
              <br />
              <br />
            </div>
            <div className='editor-diatom-field'>
              <label htmlFor='pi_zip_input' className='editor-diatom-superscript-lbl'>
                {this.translator.t('acctmgmt_pi_field_label_zip')}
              </label>
              <Input
                className='cwp'
                type='text'
                name='pi_zip'
                onChange={evt => this.handleTextEditing('zip', evt)}
                id='pi_zip_input'
                placeholder={this.state.zip || ''}
                value={this.state.zip || ''}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md='11'>
            <div className='buttonToTheRight'>
              <Button onClick={this.saveEditorChanges} className='cwp' color='primary'>
                {this.translator.t('save_changes_label')}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default PersonalAddressEditor;
