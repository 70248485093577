import React, { useState } from 'react';

import rightArrow from '../../../assets/driveView/right_arrow.png';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';

const InsuranceMessagePanel = (props = {}) => {
  const [isExpanded, expand] = useState(false);
  let { ad = null, program = null } = props;

  let isAd = !!ad;
  let dataSource = ad || program || {};

  let imageLogo = dataSource['programLogo'] || dataSource['smallImageUrl'];
  let programName = dataSource['programName'] || '';
  let contactInfo = dataSource['contactInfo'] || '';
  let clickUrl = dataSource['clickUrl'] || '';

  const translator = getLocaleTranslator();

  const expandPanel = () => expand(true);

  const callaction = e => {
    e.preventDefault();
    e.stopPropagation();
    // testing
    // clickUrl = 'http://www.google.com';
    // isAd = true;
    if (clickUrl && isAd) {
      return window.open(clickUrl);
    }

    if (typeof props.callback === 'function') {
      return props.callback(dataSource);
    }
    console.log('no callback defined for insurance message offer panel');
  };

  return (
    <div className='driveViewComponentSnapShotPadding '>
      <div className='adBox' onClick={isExpanded ? callaction : () => {}}>
        <div>
          <img className='adImageSize' src={imageLogo} />
        </div>
        {!isExpanded && (
          <div className='infoPanel' onClick={expandPanel}>
            <span className='learnMoreText'>
              {translator.t('driveview.insurancemessages.label.learn-more')}
              <img className='rightArrowImageSize' src={rightArrow} />
            </span>
          </div>
        )}
        {isExpanded && (
          <div className='infoPanel' onClick={callaction}>
            <span className='learnMoreText'>
              {translator.t('driveview.insurancemessages.label.explore-benefits')}{' '}
              <img className='rightArrowImageSize' src={rightArrow} />
            </span>
          </div>
        )}
        {isExpanded && (
          <div className='expandedText'>
            <span>{programName}</span>
            <span className='contactInfo'>{contactInfo}</span>
            <br />
          </div>
        )}
      </div>
    </div>
  );
};

export default InsuranceMessagePanel;
