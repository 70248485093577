import React from 'react';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import openEye from '../../../assets/icons/open-eye-icon.svg';
import closedEye from '../../../assets/icons/crossed-eye-icon.svg';
import { validateSpin } from '../providers/GarageProvider';
import { updatePIN } from '../providers/AccountAndSecurityProvider';
import { Container, Row, Col, Input, Button } from 'reactstrap';
import '../../reusable_cmp_lib/ThemedFormInputs/ThemedForm.scss';
import '../../reusable_cmp_lib/ThemedModal/ThemedModal.scss';
import '../../reusable_cmp_lib/ThemedButtonsAssortment/ThemedButtonsAssortment.scss';
import './AccountManagement.scss';
import { setTitle } from '../../../providers/documentTitleProvider';
import ThemedToast from '../../reusable_cmp_lib/ThemedToast/ThemedToast';
/**
 *   Incoming props:   customerEnrollment: the customer Enrollment Data
 */
class PINEditor extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      enteredCurrentPIN: '',
      enteredNewPIN: '',
      PINValid: false,
      invalidPIN: false,
      PINResetResult: null,
      currentIsVisible: false,
      newIsVisible: false,
      processing: false
    };
    this.translator = getLocaleTranslator();
  }

  enteringCurrentPIN = evt => {
    let scratch = evt.target.value;
    if (scratch == null || scratch.length > 4) {
      return;
    }
    this.setState(
      {
        enteredCurrentPIN: scratch
      },
      () => {
        if (scratch.length === 4) {
          this.validatePINentered();
        }
      }
    );
  };

  handleVisibilityToggle = which => {
    let stateHash = {};
    let statePropName = which + 'IsVisible';
    let extantVal = this.state[statePropName];
    stateHash[statePropName] = !extantVal;
    this.setState(stateHash);
  };

  enteringNewPIN = evt => {
    let scratch = String(evt.target.value).replace(/[^0-9]/gi, '');
    if (scratch === null || scratch.length > 4) {
      return;
    }
    this.setState({
      enteredNewPIN: scratch
    });
  };

  makeUpdatePinRequest = async updatePINRequest => {
    try {
      let data = await updatePIN(updatePINRequest);
      if (data.error) {
        console.log('updatePIN request failed.: ' + data.error);
        return this.setState({ PINResetResult: false, processing: false });
      }
      this.setState({ PINResetResult: true, processing: false });
    } catch (err) {
      console.log('updatePIN response failed - ' + err);
      this.setState({ PINResetResult: false, processing: false });
    }
  };

  saveButtonShouldBeDisabled = () => {
    const oldPIN = this.state.enteredCurrentPIN;
    const newPIN = this.state.enteredNewPIN;
    if (!newPIN || newPIN.length !== 4 || !oldPIN || oldPIN.length !== 4) {
      return true;
    }
    return this.state.processing;
  };

  saveNewPIN = async () => {
    if (!this.state.PINValid) {
      return;
    }
    const oldPIN = this.state.enteredCurrentPIN;
    const newPIN = this.state.enteredNewPIN;
    if (!newPIN || newPIN.length !== 4 || !oldPIN || oldPIN.length !== 4) {
      return;
    }
    let updatePINRequest = {
      newPIN,
      oldPIN
    };
    // turn off any previous error messages before making request
    this.setState({ PINResetResult: null, processing: true }, this.makeUpdatePinRequest.bind(null, updatePINRequest));
    try {
      if (document.getElementById('backToAccount') != null) {
        document.getElementById('backToAccount').focus();
      }
    } catch (err) {
      console.log('backToAccount not found');
    }
  };

  validatePINentered = async () => {
    let spinRequest = {
      vehicleId: this.props.customerEnrollment.vehicleEnrollmentStatus[0].vehicle.vehicleId,
      spin: this.state.enteredCurrentPIN,
      tspProvider: this.props.customerEnrollment.vehicleEnrollmentStatus[0].vehicle.tspProvider
    };
    if (this.props.customerEnrollment.vehicleEnrollmentStatus[0].vehicle.tspProvider === 'VZT') {
      spinRequest.tspAccountNum = this.props.customerEnrollment.vehicleEnrollmentStatus[0].rolesAndRights.tspAccountNum;
    }
    /*
    let data = null;
    try {
      data = await validateSpin(spinRequest);
    } catch (err) {
      data = { error: err };
    }
    */
    try {
      await validateSpin(spinRequest);
      this.setState({
        invalidPIN: false,
        PINValid: true
      });
    } catch (err) {
      console.log('Error response back from validation: ' + err);
      this.setState({
        invalidPIN: true,
        PINValid: false
      });
    }
  };

  render () {
    setTitle('document_titles.account_management.edit_pin');
    let PINResetStatusIndicator = '';
    let PINResetResultMsg = this.state.PINResetResult
      ? this.translator.t('acctmgmt_pin_success_header')
      : this.translator.t('acctmgmt_pin_failure_header');

    if (this.state.PINResetResult !== null) {
      PINResetStatusIndicator = this.state.PINResetResult ? 'pin-reset-success' : 'pin-reset-failure';
    }

    return (
      <fieldset>
        <Container fluid={true}>
          <Row>
            <Col md='3'></Col>
            <Col md='8'>
              <div class='editor-firstrow-label-aligner'>
                <h2>{this.translator.t('acctmgmt_lns_title_pin')}</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md='3'></Col>
            <Col md='3'>
              <div class='editor-diatom-field'>
                <span class='editor-diatom-superscript-lbl'>
                  <label htmlFor='pi_current_pin_input'>
                    {this.translator.t('acctmgmt_lns_field_label_current_pin')}
                  </label>
                </span>
                <br />
                <Input
                  className='cwp pin-entry'
                  maxLength={4}
                  onChange={this.enteringCurrentPIN}
                  type={this.state.currentIsVisible ? 'text' : 'password'}
                  name='pi_current_pin'
                  id='pi_current_pin_input'
                />
                <img
                  src={this.state.currentIsVisible ? openEye : closedEye}
                  alt='toggle visibility of the entry'
                  height='18'
                  width='18'
                  className='mb-1 mr-1 inline reverseLeftMargin'
                  onClick={evt => this.handleVisibilityToggle('current')}
                />
                {this.state.invalidPIN && (
                  <span class='invalid-pin-flag-indicator'>{this.translator.t('acctmgmt_lns_invalid_pin')}</span>
                )}
              </div>
            </Col>
            <Col md='3'>
              <div class='editor-diatom-field'>
                <span class='editor-diatom-superscript-lbl'>
                  <label htmlFor='pi_new_pin_input'>{this.translator.t('acctmgmt_lns_field_label_new_pin')}</label>
                </span>
                <br />
                <Input
                  readOnly={!this.state.PINValid}
                  className='cwp pin-entry'
                  onChange={this.enteringNewPIN}
                  maxLength={4}
                  type={this.state.newIsVisible ? 'text' : 'password'}
                  name='pi_new_pin'
                  id='pi_new_pin_input'
                  value={this.state.enteredNewPIN}
                />
                <img
                  src={this.state.newIsVisible ? openEye : closedEye}
                  alt='toggle visibility of the entry'
                  height='18'
                  width='18'
                  className='mb-1 mr-1 inline reverseLeftMargin'
                  onClick={evt => this.handleVisibilityToggle('new')}
                />
              </div>
            </Col>
            <Col md='1'></Col>
          </Row>
          <Row className='pinEditorRow'>
            <Col md='3'></Col>
            <Col md='3'></Col>
            <Col md='3'>
              {this.state.PINResetResult !== null && (
                <ThemedToast
                  title={this.translator.t('acctmgmt_saved')}
                  msg={PINResetResultMsg}
                  display={true}
                  returnFocusAfterClose={false}
                  onClosed={this.props.setFocusOnBackAfterModalDismissed}
                />
              )}
            </Col>
            <Col md='1'></Col>
          </Row>
          <Row>
            <Col md='12'></Col>
          </Row>
          <Row className='pinEditorRow'>
            <Col md='8'>&nbsp;</Col>
            <Col md='2' className='extraRight'>
              <Button
                disabled={this.saveButtonShouldBeDisabled()}
                className='cwp'
                color='primary'
                onClick={this.saveNewPIN}
              >
                {this.translator.t('save_changes_label')}
              </Button>
            </Col>
          </Row>
        </Container>
      </fieldset>
    );
  }
}

export default PINEditor;
