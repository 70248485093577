import React from 'react';
import SelfEnrollmentUS from './SelfEnrollmentUS';
import SelfEnrollmentCA from './SelfEnrollmentCanada';
import { getLocaleSync } from '../../../providers/languageProvider';

const SelfEnrollment = props => {
  let localeInfo = getLocaleSync();

  let { userCountry } = localeInfo;
  let isCanada = userCountry.toLowerCase() === 'ca';

  return (
    <div>{isCanada ? <SelfEnrollmentCA locale={props.locale} /> : <SelfEnrollmentUS locale={props.locale} />}</div>
  );
};

export default SelfEnrollment;
