/* eslint-disable complexity */
import React from 'react';
import DriveViewScoreReading from './driveViewScoreReading';
import idleTime from '../../../assets/driveView/idle_navy.png';
import excessOfSpeed from '../../../assets/driveView/speed-navy.png';
import nightTimeDriving from '../../../assets/driveView/nighttime-driving_navy.png';
import hardBraking from '../../../assets/driveView/brakes_navy.png';
import { Table } from 'react-bootstrap';
import rightArrow from '../../../assets/driveView/right_arrow.png';
import leftArrow from '../../../assets/driveView/left_arrow.png';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { getDayDisplayDate, getWeekDisplayDate, getMonthDisplayDate } from '../common/commonDateUtil';
import { findIn } from '../../../util/utilityMethods';

const ConsolidateTripSummary = props => {
  let consolidateTripSummary = props.consolidateTripSummary;
  let translator = getLocaleTranslator();
  const DAY = 'daily';
  const WEEK = 'weekly';
  const MONTH = 'monthly';
  let displayDate = '';

  if (props.daySelected) {
    displayDate = getDayDisplayDate(props.daySelected, props.previousSelected, props.nextSelected, props.trackDate);
  } else if (props.weekSelected) {
    displayDate = getWeekDisplayDate(props.weekSelected, props.previousSelected, props.nextSelected, props.trackDate);
  } else {
    displayDate = getMonthDisplayDate(props.monthSelected, props.previousSelected, props.nextSelected, props.trackDate);
  }

  return (
    <div>
      <div id='snapShotInfo' className='driveViewComponentAggregatedSnapshot'>
        <div class='container center'>
          <div
            className='driveViewPrevNext'
            onClick={() =>
              props.handlePrevNext(
                props.daySelected,
                props.weekSelected,
                props.monthSelected,
                true,
                false,
                props.trackDate
              )
            }
          >
            <img
              className='dateImageSize'
              src={leftArrow}
              alt={translator.t('driveview.snapshot.label.night-time-driving')}
            />{' '}
            {translator.t('driveview.aggregated-snapshot.label.previous')}
          </div>
          <div className='dateSpacing'>{displayDate}</div>
          <div
            class='driveViewPrevNext'
            onClick={() =>
              props.handlePrevNext(
                props.daySelected,
                props.weekSelected,
                props.monthSelected,
                false,
                true,
                props.trackDate
              )
            }
          >
            {translator.t('driveview.aggregated-snapshot.label.next')}{' '}
            <img
              className='dateImageSize'
              src={rightArrow}
              alt={translator.t('driveview.snapshot.label.night-time-driving')}
            />
          </div>
        </div>
        <br />
        <div class='container center'>
          <div
            className={props.daySelected ? 'selectedTypeBackground' : 'notSelectedTypeBackground'}
            onClick={() => props.handleViewType(DAY)}
          >
            {translator.t('driveview.aggregated-snapshot.label.day')}
          </div>
          <div
            className={props.weekSelected ? 'selectedTypeBackground' : 'notSelectedTypeBackground'}
            onClick={() => props.handleViewType(WEEK)}
          >
            {translator.t('driveview.aggregated-snapshot.label.week')}
          </div>
          <div
            className={props.monthSelected ? 'selectedTypeBackground' : 'notSelectedTypeBackground'}
            onClick={() => props.handleViewType(MONTH)}
          >
            {translator.t('driveview.aggregated-snapshot.label.month')}
          </div>
        </div>

        <br />
        <br />
        <div class='driveViewComponentSnapShotInfo'>
          <DriveViewScoreReading score={findIn('tripWise.score', consolidateTripSummary) || 0} />
          <div className='driveComponentCTS'>
            <div class='container-fluid'>
              <div class='row'>
                <div class='col-sm-4'>
                  <div className='background'>
                    <Table>
                      <tr>
                        <td className='imageCenter'>
                          <img
                            src={nightTimeDriving}
                            alt={translator.t('driveview.aggregated-snapshot.label.nighttime-driving')}
                          />
                          <br />
                          <div className='percentage'>
                            {consolidateTripSummary
                              ? findIn('tripWise.nightTimeDrivingPercent', consolidateTripSummary) || 0
                              : 0}
                            %
                          </div>
                        </td>
                        <td>
                          <div className='vl'></div>
                        </td>
                        <td>
                          <span className='titleSize'>
                            {translator.t('driveview.aggregated-snapshot.label.nighttime-driving')}
                          </span>
                          <br />
                          <div className='descSize'>
                            {translator.t('driveview.aggregated-snapshot.label.nighttime-driving-desc')}
                          </div>
                        </td>
                      </tr>
                    </Table>
                  </div>
                </div>

                <div class='col-sm-4'>
                  <div className='background'>
                    <Table>
                      <tr>
                        <td className='imageCenter'>
                          <img
                            src={hardBraking}
                            alt={translator.t('driveview.aggregated-snapshot.label.hard-braking')}
                          />
                          <br />
                          <div className='percentage'>
                            {consolidateTripSummary
                              ? findIn('tripWise.hardBrakeCount', consolidateTripSummary) || 0
                              : 0}
                          </div>
                        </td>
                        <td>
                          <div class='vl'></div>
                        </td>
                        <td>
                          <span className='titleSize'>
                            {translator.t('driveview.aggregated-snapshot.label.hard-braking')}
                          </span>
                          <br />
                          <div className='descSize'>
                            {props.daySelected &&
                              translator.t('driveview.aggregated-snapshot.label.hard-brakes-in-day-desc')}
                            {props.weekSelected &&
                              translator.t('driveview.aggregated-snapshot.label.hard-brakes-in-week-desc')}
                            {props.monthSelected &&
                              translator.t('driveview.aggregated-snapshot.label.hard-brakes-in-month-desc')}
                          </div>
                        </td>
                      </tr>
                    </Table>
                  </div>
                </div>

                <div class='col-sm-4'>
                  <div className='background'>
                    <Table>
                      <tr>
                        <td className='imageCenter'>
                          <img
                            src={excessOfSpeed}
                            alt={translator.t('driveview.aggregated-snapshot.label.high-speed-driving')}
                          />
                          <br />
                          <div className='percentage'>
                            {consolidateTripSummary
                              ? findIn('tripWise.excessOfSpeedPercent', consolidateTripSummary) || 0
                              : 0}
                            %
                          </div>
                        </td>
                        <td>
                          <div class='vl'></div>
                        </td>
                        <td>
                          <span className='titleSize'>
                            {translator.t('driveview.aggregated-snapshot.label.high-speed-driving')}
                          </span>
                          <br />
                          <div className='descSize'>
                            {translator.t('driveview.aggregated-snapshot.label.high-speed-driving-desc')}
                          </div>
                        </td>
                      </tr>
                    </Table>
                  </div>
                </div>

                <div class='col-sm-4'>
                  <div className='background'>
                    <Table>
                      <tr>
                        <td className='imageCenter'>
                          <img src={idleTime} alt={translator.t('driveview.aggregated-snapshot.label.idle-time')} />
                          <br />
                          <div className='percentage'>
                            {findIn('tripWise.idleTimePercent', consolidateTripSummary) || 0}%
                          </div>
                        </td>
                        <td>
                          <div class='vl'></div>
                        </td>
                        <td>
                          <span className='titleSize'>
                            {translator.t('driveview.aggregated-snapshot.label.idle-time')}
                          </span>
                          <br />
                          <div className='descSize'>
                            {translator.t('driveview.aggregated-snapshot.label.idle-time-desc')}
                          </div>
                        </td>
                      </tr>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsolidateTripSummary;
