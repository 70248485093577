import React from 'react';
import { setTitle } from '../../../providers/documentTitleProvider';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';

class VerifyAddress extends React.Component {
  state = {
    selectedAddress: null
  };

  constructor (props) {
    super(props);

    this.translator = getLocaleTranslator();
  }

  componentDidMount = () => {
    setTitle('document_titles.profile_page_verify_address');
  };

  onAbort = e => {
    e.preventDefault();
    this.props.onAbort();
  };

  onSubmit = e => {
    if (this.props.onSuccess) {
      this.props.onSuccess(this.state.selectedAddress);
    }
  };

  buildAddressComponent = (address, textLabel) => {
    const isSelectedAddress =
      this.state.selectedAddress && JSON.stringify(address) === JSON.stringify(this.state.selectedAddress)
        ? true
        : false;

    return (
      <div
        id={`option-${textLabel}`}
        tabIndex={0}
        role='radio'
        aria-checked={isSelectedAddress}
        className={`cwpFocusVisible ${isSelectedAddress ? 'addressVerificationBoxSelected' : 'addressVerificationBox'}`}
        onClick={() => this.setState({ selectedAddress: address })}
        onKeyPress={e =>
          e.key === ' ' || e.key === 'Enter' ? this.setState({ selectedAddress: address }) : e.preventDefault()
        }
      >
        <label for={`option-${textLabel}`} className='cwpLabel small d-inline-block mb-2' hidden={!textLabel}>
          {textLabel}:
        </label>
        <div className='d-block'>
          {address.address1} <br hidden={!address.address2} />
          {address.address2} <br />
          {address.city}
          <br />
          {address.state}, {address.postalCode}
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <div id='main-content' role='main'>
        <span
          className='cwpBack d-block float-left small'
          onClick={this.onAbort}
          onKeyPress={e => (e.key === ' ' || e.key === 'Enter' ? this.onAbort(e) : e.preventDefault())}
          tabIndex={0}
          role='button'
        >
          {this.translator.t('your_information.back_to_your_information')}
        </span>
        <br />
        <h1>
          {this.translator.t('your_information.your')} <strong>{this.translator.t('your_information.address')}</strong>
        </h1>
        <fieldset aria-live='polite'>
          <legend className='cwp'>{this.translator.t('your_information.select_the_correct_address')}</legend>
          <div className='row mt-5'>
            <div className='col-md-2'></div>
            <div className='col-md-3'>
              {this.buildAddressComponent(
                this.props.suggestedAddresses[0],
                this.translator.t('your_information.we_suggest')
              )}
            </div>
            <div className='col-md-2'></div>
            <div className='col-md-3'>
              {this.buildAddressComponent(
                this.props.originalAddress,
                this.translator.t('your_information.you_entered')
              )}
            </div>
            <div className='col-md-2'></div>
          </div>
          <button className='cwp mt-5' onClick={this.onSubmit} disabled={!this.state.selectedAddress}>
            Continue
          </button>
        </fieldset>
      </div>
    );
  };
}

export default VerifyAddress;
