import carnet_constants from '../../../constants/carnet_constants';

const UBI_GEICO_MSG = 'GeicoBanner';
const AVAILABLE = 'AVAILABLE';

export const kmToMiles = avgSpeed => {
  return (avgSpeed * carnet_constants.KM_TO_MILES).toFixed(2);
};

export const litersToGallons = avgFuelEconomy => {
  return (avgFuelEconomy * carnet_constants.FUEL_ECONOMY_KM_TO_MILES).toFixed(2);
};

export const getUpdatedTripHistoryList = tripDetails => {
  let tripDetailsUpdated = [];

  tripDetails &&
    tripDetails.forEach((tripHistory, index) => {
      tripDetailsUpdated.push({
        tripId: index,
        startTime: tripHistory.startTime,
        endTime: tripHistory.endTime,
        tripWise: tripHistory.tripWise
      });
    });

  console.log('tripDetailsUpdated :::::', JSON.stringify(tripDetailsUpdated, null, 1));
  return tripDetailsUpdated;
};
