import React, { Component } from 'react';
import './faqCA.scss';
import download from '../../../assets/faq/download.png';
import chat from '../../../assets/faq/chat.png';
import mobile from '../../../assets/faq/mobile.png';
import offers from '../../../assets/faq/offers.png';
import pin from '../../../assets/faq/pin.png';
import play from '../../../assets/faq/play.png';
import profile from '../../../assets/faq/profile.png';
import safety from '../../../assets/faq/safety.png';
import vehiclehelth from '../../../assets/faq/vehiclehealth.png';
import getCwpMeta from '../../../providers/cwpMetaProvider';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { setTitle } from '../../../providers/documentTitleProvider';

export default class FAQCA extends Component {
  mounted = true;
  state = {
    staticContentBase: '',
    filesUrl: ''
  };

  componentDidMount () {
    setTitle('document_titles.faq.faq_main');
    // get the staticContentBase
    const getMeta = async () => {
      let meta = await getCwpMeta();

      let filesUrl = meta.static.content.urls.files;
      this.setState({
        filesUrl
      });

      if (this.mounted) {
        this.setState({ filesUrl });
      }
    };
    getMeta();
  }

  componentWillUnmount () {
    this.mounted = false;
  }
  render () {
    let translator = getLocaleTranslator();

    return (
      <div className='AppCA' role='main' id='main-content'>
        <div id='exit-editing-link'>
          <span
            id='backButton'
            class='cwpBack'
            role='button'
            onClick={historyBack}
            onKeyPress={e => {
              e.preventDefault();
              if (e.key && (e.key === 'Enter' || e.key === ' ')) {
                historyBack(e);
              }
            }}
            tabIndex={0}
          >
            {translator.t('button.back')}
          </span>
        </div>
        <h1 className='faq-header'>
          Volkswagen Car-Net® <span className='faq-back-header'>FAQs</span>
        </h1>
        <div className='faq-sub-header'>
          <span>
            Explore the FAQs for answers to the most asked questions. <br />
            If you need further assistance, call one of the numbers below."{' '}
          </span>
          <span className='faq-sub-header'>
            ModelYear 2022+ : <a href='tel:833-435-1011'>833-435-1011</a>
          </span>
        </div>

        <div className='download'>
          <span>
            <a href={`${this.state.filesUrl}/qrg-my21.pdf`} target='_blank' rel='noreferrer'>
              <img src={download} alt='download' width='14'></img> Quick Reference Guide (2022+)
            </a>
          </span>
        </div>

        <table className='first-row-data'>
          <tr>
            <td className='first-row'>
              <div className='help cwpFocusVisible'>
                <div className='help-img'>
                  <img src={profile} alt='' width='24'></img>
                </div>

                <div className='faq-head'>Account</div>

                <div className='article-title'>
                  <p>
                    Get helpful account information and answers to <br />
                    other general questions
                  </p>
                </div>
                <div className='viewFaqLink'>
                  <a href='#/en/ca/account-faq-ca'>View FAQs &#129106;</a>
                </div>
              </div>
            </td>

            <td className='first-row'>
              <div className='help cwpFocusVisible'>
                <div className='help-img'>
                  <img src={mobile} alt='' width='24'></img>
                </div>

                <div className='faq-head'>Remote Services</div>

                <div className='article-title'>
                  <p>
                    Remotely check the status of your car's doors,
                    <br />
                    lights, fuel level, and more.
                  </p>
                </div>
                <div className='viewFaqLink'>
                  <a href='#/en/ca/remote-services-faq-ca'>View FAQs &#129106;</a>
                </div>
              </div>
            </td>

            <td className='first-row'>
              <div class='help cwpFocusVisible'>
                <div className='help-img'>
                  <img src={chat} alt='' width='24'></img>
                </div>
                <div className='faq-head'>Message Center/Vehicle Activity</div>
                <div className='article-title'>
                  <p>
                    Check out the interactions you've made with your <br />
                    car. Like boundary entries/exits, speed alert <br />
                    information, door unlock requests, and more.
                  </p>
                </div>
                <div className='viewFaqLink'>
                  <a href='#/en/ca/message-center-faq-ca'>View FAQs &#129106;</a>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td className='first-row'>
              <div className='help cwpFocusVisible'>
                <div className='help-img'>
                  <img src={offers} alt='' width='24'></img>
                </div>

                <div className='faq-head'>Buy Plans</div>

                <div className='article-title'>
                  <p>
                    Get helpful account information and answers to <br />
                    other general questions
                  </p>
                </div>
                <div className='viewFaqLink'>
                  <a href='#/en/ca/plan-and-pricing-faq-ca'>View FAQs &#129106;</a>
                </div>
              </div>
            </td>

            <td className='first-row'>
              <div className='help cwpFocusVisible'>
                <div className='help-img'>
                  <img src={safety} alt='' width='24'></img>
                </div>

                <div className='faq-head'>Family Guardian</div>

                <div className='article-title'>
                  <p>
                    Remotely check the status of your car's doors,
                    <br />
                    lights, fuel level, and more.
                  </p>
                </div>
                <div className='viewFaqLink'>
                  <a href='#/en/ca/family-guardian-faq-ca'>View FAQs &#129106;</a>
                </div>
              </div>
            </td>

            <td className='first-row'>
              <div className='help cwpFocusVisible'>
                <div className='help-img'>
                  <img src={pin} alt='' width='24'></img>
                </div>
                <div className='faq-head'>Navigate/Search & Send POI</div>
                <div className='article-title'>
                  <p>
                    Check out the interactions you've made with your <br />
                    car. Like boundary entries/exits, speed alert <br />
                    information, door unlock requests, and more.
                  </p>
                </div>
                <div className='viewFaqLink'>
                  <a href='#/en/ca/navigate-faq-ca'>View FAQs &#129106;</a>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td className='first-row'>
              <div className='help cwpFocusVisible'>
                <div className='help-img'>
                  <img src={vehiclehelth} alt='' width='24'></img>
                </div>

                <div className='faq-head'>Vehicle Health</div>

                <div className='article-title'>
                  <p>
                    Get helpful account information and answers to <br />
                    other general questions
                  </p>
                </div>
                <div className='viewFaqLink'>
                  <a href='#/en/ca/vhr-faq-ca'>View FAQs &#129106;</a>
                </div>
              </div>
            </td>

            <td className='first-row'>
              <div class='help cwpFocusVisible'>
                <div className='help-img'>
                  <img src={play} alt='' width='24'></img>
                </div>
                <div className='faq-head'>How-to Videos</div>
                <div className='article-title'>
                  <p>Watch VW Car-Net® video tutorials to learn how to get the most out of your vehicle.</p>
                </div>
                <div className='viewFaqLink'>
                  <a href='#/en/ca/marketing-video-ca'>View FAQs &#129106;</a>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    );
  }
}

const historyBack = e => {
  e.preventDefault();
  e.stopPropagation();
  window && window.history && window.history.back();
};

export { FAQCA, historyBack };
