import React, { useState } from 'react';
import './vehicleHealth.scss';
import { dateFormat, timeAndDateFormat, formatTime } from '../../../util/i18n/localeUtils';
import { Modal, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../redux/actions';
import { css } from '@emotion/core';
import BarLoader from 'react-spinners/BarLoader';
import PriorityCodeLegend from './PriorityCodeLegend';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';

const barloaderOverride = css`
  display: block;
  margin: auto;
  position: absolute;
  margin-left: 40%;
  margin-top: 5px;
`;

function VehicleHealthHistory (props) {
  let translator = getLocaleTranslator();

  const [modal, setModal] = useState(false);
  const [vhrConfirmModal, setVhrConfirmModal] = useState(false);
  const [serviceKey, setServiceKey] = useState('');
  const toggle = () => {
    setModal(!modal);
  };

  const vhrConfirmModalToggle = () => {
    setVhrConfirmModal(!vhrConfirmModal);
  };

  const showVHRHistory = serviceKey => {
    props.actions.getVehicleHealthByServiceKey({ serviceKey: serviceKey });
    setServiceKey(serviceKey);
    setModal(!modal);
  };

  const sendVHRReport = () => {
    props.actions.sendVHRReportByServiceKey({ serviceKey: serviceKey });
    setModal(!modal);
    setVhrConfirmModal(!vhrConfirmModal);
  };

  const historyVhrTimeStamp = (props.vhrData && props.vhrData.vhrTimeStamp) || '';
  const applicableCategories = (props.vhrData && props.vhrData.applicableCategories) || '';

  return (
    <div>
      <h2 className='cwp-page vhr-history-div vhr-history-title'>
        {translator.t('vehicleHealth.history.title_label')}
      </h2>

      <table className='history-data-table'>
        <tr className='history-data-tr'>
          <th className='history-data-td'>{translator.t('vehicleHealth.history.date_label')}</th>
          <th className='history-data-td'>{translator.t('vehicleHealth.history.time_label')}</th>
          <th className='history-data-td'>{translator.t('vehicleHealth.history.status_label')}</th>
        </tr>
        {Array.isArray(props.summarizedVHHistory) &&
          props.summarizedVHHistory.map((vhHistory, index) => (
            <tr style={{ borderBottom: '1px solid #ccc' }} id={index}>
              <td className='history-data-td'>
                <div
                  className='service-key'
                  tabIndex='0'
                  role='button'
                  onClick={() => showVHRHistory(vhHistory.serviceKeyUID)}
                  onKeyPress={e => {
                    if (e.key && (e.key === 'Enter' || e.key === ' ')) {
                      showVHRHistory(vhHistory.serviceKeyUID);
                    }
                  }}
                >
                  {dateFormat(vhHistory.vhrTimeStamp)}
                </div>
              </td>
              <td className='history-data-td'>{formatTime(vhHistory.vhrTimeStamp)}</td>
              <td className='history-data-td'>
                <div
                  className={`vhrhistory vhr-prioritycode-img priorityCode${vhHistory.overallPriorityCode}`}
                  role='img'
                  aria-label={translator.t(`vehicleHealth.category_priority_${vhHistory.overallPriorityCode}_desc`)}
                  title={translator.t(`vehicleHealth.category_priority_${vhHistory.overallPriorityCode}_desc`)}
                />
              </td>
            </tr>
          ))}
      </table>
      <div>
        <Modal isOpen={modal} size='lg' toggle={toggle} centered={true} className='history-modal'>
          <Row>
            <Col>
              <h2 className='modal-header'>{translator.t('vehicleHealth.history.modal.header_label')}</h2>
            </Col>
            <Col>
              <div
                className='vhr-modal-exit'
                tabIndex='0'
                role='button'
                onClick={toggle}
                onKeyPress={e => {
                  if (e.key && (e.key === 'Enter' || e.key === ' ')) {
                    toggle();
                  }
                }}
              />
            </Col>
          </Row>

          {props.dataLoading && props.dataLoading === true ? (
            <ModalBody>
              <div className='modal-message'>
                {translator.t('vehicleHealth.history.modal.message')}&nbsp;
                {timeAndDateFormat(historyVhrTimeStamp)}
              </div>

              {Array.isArray(applicableCategories) ? (
                applicableCategories.map(applicableCategory => (
                  <div className='categories-margin'>
                    <div
                      className={`vhrRow vhr-details-img priorityCode${applicableCategory.categoryPriorityCode}`}
                      role='img'
                      aria-label={translator.t(
                        `vehicleHealth.category_priority_${applicableCategory.categoryPriorityCode}_desc`
                      )}
                      title={translator.t(
                        `vehicleHealth.category_priority_${applicableCategory.categoryPriorityCode}_desc`
                      )}
                    >
                      <span className='modal-category-message'>{applicableCategory.categoryDesc}</span>
                    </div>

                    {applicableCategory &&
                      Array.isArray(applicableCategory.diagnosticMessages) &&
                      applicableCategory.diagnosticMessages.map((diagnosticMessage, i) => (
                        <div>
                          <div
                            className={
                              i === applicableCategory.diagnosticMessages.length - 1
                                ? `vhrRowDetails noBorder vhr-details-img priorityCode${diagnosticMessage.priorityCode}`
                                : `vhrRowDetails vhr-details-img priorityCode${diagnosticMessage.priorityCode}`
                            }
                          >
                            <div className='historySubcategoryDesc'>{diagnosticMessage.msgSubCategoryDesc}</div>
                            <div className='historyCategoryDesc'>{diagnosticMessage.msgText}</div>
                          </div>
                        </div>
                      ))}
                    <div class='vhrRowDetails'></div>
                  </div>
                ))
              ) : (
                <div className='vhr-error'>{translator.t('vehicleHealth.error_text')}</div>
              )}

              <PriorityCodeLegend />
              <br />
              <div>
                <span className='modal-text-enable'>
                  <div
                    className='link-color'
                    tabIndex='0'
                    role='button'
                    onClick={sendVHRReport}
                    onKeyPress={e => {
                      if (e.key && (e.key === 'Enter' || e.key === ' ')) {
                        sendVHRReport();
                      }
                    }}
                  >
                    &#129106; &nbsp;
                    {translator.t('vehicleHealth.history.modal.request_report_label')}
                  </div>
                </span>
              </div>

              <br />
            </ModalBody>
          ) : (
            <ModalBody>
              <div>
                <BarLoader css={barloaderOverride} sizeUnit={'%'} size={20} color={'#001e50'} />
                <br />
              </div>
            </ModalBody>
          )}
        </Modal>
      </div>
      <Modal
        aria-live='polite'
        isOpen={vhrConfirmModal}
        size='sm'
        centered={true}
        toggle={vhrConfirmModalToggle}
        labelledBy='reportSentCnfId'
        onOpened={e => document.getElementById('gotitBtn').focus()}
      >
        <ModalBody className='vhr-reportcnf-modal' id='reportSentCnfId'>
          <div className='vhr-reportcnf-header' aria-label={translator.t('vehicleHealth.report_sent')}>
            {translator.t('vehicleHealth.report_sent')}
          </div>
          <div className='vhr-reportcnf-text'>
            <p>{translator.t('vehicleHealth.report_confirm_text')}</p>
          </div>
        </ModalBody>
        <ModalFooter className='vhr-reportcnf-modal'>
          <Button className='gotit-btn' onClick={vhrConfirmModalToggle} id='gotitBtn'>
            {translator.t('vehicleHealth.got_it_label')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getVehicleHealthByServiceKey: actions.getVehicleHealthByServiceKey,
        sendVHRReportByServiceKey: actions.sendVHRReportByServiceKey
      },
      dispatch
    )
  };
};

const mapStateToProps = state => {
  const { vehicleHealthByServiceKeyDetails, sendVHReportByServiceKey } = state;

  return {
    vhrData: vehicleHealthByServiceKeyDetails.vhrData,
    dataLoading: vehicleHealthByServiceKeyDetails.dataLoading,
    vhReportData: sendVHReportByServiceKey.vhReportData
  };
};

export { VehicleHealthHistory };
export default connect(mapStateToProps, mapDispatchToProps)(VehicleHealthHistory);
