let HISTORY_KEY = 'CWP_LAST_VIEWED_PAGE';
let VEHICLE_CONTEXT_KEY = 'CWP_CONTEXT_KEY';
let storage = window && window.localStorage;

const getHistory = () => {
  return storage.getItem(HISTORY_KEY);
};

const setHistory = value => {
  return storage.setItem(HISTORY_KEY, value);
};

const clearHistory = () => {
  storage.removeItem(HISTORY_KEY);
};

const setVehicleContext = value => {
  let stringified = JSON.stringify(value);
  return storage.setItem(VEHICLE_CONTEXT_KEY, stringified);
};

const getVehicleContext = value => {
  let stringified = storage.getItem(VEHICLE_CONTEXT_KEY);
  return JSON.parse(stringified);
};

const clearVehicleContext = () => {
  storage.removeItem(VEHICLE_CONTEXT_KEY);
};

const restoreStartingLocation = () => {
  let location = storage.getItem(HISTORY_KEY);
  // CNCA-1505 (firefox handles popstate differently than chrome, firefox would not repopulate this value on repeated browser refreshes)
  // storage.removeItem(HISTORY_KEY);
  return location;
};

window.addEventListener('popstate', function (event) {
  // The URL changed...
  let newLocation = event.currentTarget.location.href;
  let hashArr = newLocation.split('/#/');
  // clear out last stored view if logging out
  if (newLocation.includes('/logout')) {
    clearHistory();
  } else {
    setHistory(newLocation);
  }
});

export { restoreStartingLocation, setVehicleContext, getVehicleContext, clearVehicleContext, clearHistory };
