import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../redux/actions';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import ThemedToast from '../../reusable_cmp_lib/ThemedToast/ThemedToast';
import { Container, Row, Col, Input, Button } from 'reactstrap';
import { isNonEmptyObject } from '../../../util/utilityMethods';
import '../../reusable_cmp_lib/ThemedFormInputs/ThemedForm.scss';
import '../../reusable_cmp_lib/ThemedButtonsAssortment/ThemedButtonsAssortment.scss';
import './AccountManagement.scss';
import { setTitle } from '../../../providers/documentTitleProvider';

/* NOW: JUST ADD JSX TO DETECT THE VALIDATION WARNINGS, IN THE STATE  */
class SecurityQuestionsEditor extends React.Component {
  constructor (props) {
    super(props);
    // setup the instance 'allQuestionsSorted' array
    this.allQuestionsSorted = [];
    if (this.props.securityQuestions && this.props.securityQuestions.length > 0) {
      this.props.securityQuestions.forEach(question => {
        let qId = question.code;
        this.allQuestionsSorted[qId] = question;
      });
    }

    const usersSecurityQuestions = JSON.parse(JSON.stringify(this.props.userSecurityQuestions)) || [];

    if (!usersSecurityQuestions[0]) {
      usersSecurityQuestions.push({});
    }
    if (!usersSecurityQuestions[1]) {
      usersSecurityQuestions.push({});
    }
    this.state = {
      usersSecurityQuestions
    };
  }

  componentDidUpdate (prevProps) {
    setTitle('document_titles.account_management.edit_challenge_question');
    let prev = JSON.stringify(prevProps.securityQuestions);
    let cur = JSON.stringify(this.props.securityQuestions);
    if (prev !== cur) {
      this.allQuestionsSorted = [];
      if (this.props.securityQuestions && this.props.securityQuestions.length > 0) {
        this.props.securityQuestions.forEach(question => {
          let qId = question.code;
          this.allQuestionsSorted[qId] = question;
        });
        this.forceUpdate();
      }
    }
  }

  handleTextEditing = (evt, qIndex) => {
    let workingTxt = evt.target.value;
    let mutableQsList = JSON.parse(JSON.stringify(this.state.usersSecurityQuestions));
    mutableQsList[qIndex].questionResponse = workingTxt;
    this.setState(
      {
        usersSecurityQuestions: mutableQsList
      },
      this.userSecurityQuestionsAreValid
    );
  };

  userSecurityQuestionsAreValid = (usersNewSelections = this.state.usersSecurityQuestions) => {
    let answerOneThere = usersNewSelections[0] && !!usersNewSelections[0].questionResponse;
    let questionOneThere = usersNewSelections[0] && !!usersNewSelections[0].questionId;
    let answerTwoThere = usersNewSelections[1] && !!usersNewSelections[1].questionResponse;
    let questionTwoThere = usersNewSelections[1] && !!usersNewSelections[1].questionId;

    let missing_answer = [];
    let missing_question = [];
    let isValid = true;

    if (!answerOneThere) {
      missing_answer[0] = 0;
    }
    if (!answerTwoThere) {
      missing_answer[1] = 1;
    }
    if (!questionOneThere) {
      missing_question[0] = 0;
    }
    if (!questionTwoThere) {
      missing_question[1] = 1;
    }

    if (missing_answer.length || missing_question.length) {
      isValid = false;
    }

    let missingAnswerDifferent = JSON.stringify(missing_answer) !== JSON.stringify(this.state.missing_answer);
    let missingQuestionDifferent = JSON.stringify(missing_question) !== JSON.stringify(this.state.missing_question);

    if (missingAnswerDifferent || missingQuestionDifferent) {
      this.setState({
        missing_answer,
        missing_question
      });
    }

    return isValid;
  };

  saveFinalUsersChallengeQuestions = evt => {
    const usersNewSelections = this.state.usersSecurityQuestions;

    if (!this.userSecurityQuestionsAreValid(usersNewSelections)) {
      console.log('User Security Questions INVALID!');
      return;
    }

    const savePayload = {
      securityQuestions: usersNewSelections
    };

    this.props.refreshHandler(usersNewSelections);
    this.props.actions.saveUserSecurityQuestions(savePayload);
  };

  getUserQuestionByIndex = index => {
    let indexQuestionId = this.state.usersSecurityQuestions[index].questionId;
    let indexQuestionResponse = this.state.usersSecurityQuestions[index].questionResponse;
    let indexQuestion = this.allQuestionsSorted[indexQuestionId];
    let retval = {};
    if (!indexQuestion) {
      console.error(
        'Invalid data from microservice: Users ' +
          index +
          'th challenge question Id has an unknown value: ' +
          indexQuestionId
      );
      return retval;
    } else {
      retval = { question: indexQuestion.value, id: indexQuestionId, response: indexQuestionResponse };
      return retval;
    }
  };

  questionSelected = (index, chosenValue) => {
    console.log('questionSelected', index, chosenValue);
    let questionSelected = this.allQuestionsSorted.filter(item => {
      return item && item.value && item.value === chosenValue;
    })[0];

    let usersQsArr = JSON.parse(JSON.stringify(this.state.usersSecurityQuestions));
    let newQuestionThing = { questionId: '', questionResponse: '' };
    if (!usersQsArr[index]) {
      usersQsArr[index] = newQuestionThing;
    }

    if (questionSelected && questionSelected.code) {
      usersQsArr[index].questionId = questionSelected.code;
    } else {
      // didn't select a question, selected the drop-down options header
      usersQsArr[index] = {};
    }

    this.setState(
      {
        usersSecurityQuestions: usersQsArr
      },
      this.userSecurityQuestionsAreValid
    );
  };

  truncateQuestionText = str => {
    return typeof str === 'string' && str.length <= 25 ? str : str.substring(0, 24);
  };

  render () {
    const questionIdOne =
      (this.state.usersSecurityQuestions[0] && this.state.usersSecurityQuestions[0]['questionId']) || null;
    const questionIdTwo =
      (this.state.usersSecurityQuestions[1] && this.state.usersSecurityQuestions[1]['questionId']) || null;
    let firstQuestionsList = this.allQuestionsSorted.filter(question => question.code !== questionIdTwo);
    let secondQuestionsList = this.allQuestionsSorted.filter(question => question.code !== questionIdOne);

    let firstAnswer =
      (this.state.usersSecurityQuestions[0] && this.state.usersSecurityQuestions[0]['questionResponse']) || '';
    let secondAnswer =
      (this.state.usersSecurityQuestions[1] && this.state.usersSecurityQuestions[1]['questionResponse']) || '';

    this.translator = getLocaleTranslator();
    let i18nSaveIndicatorToastTitle = this.translator.t('acctmgmt_save_indicator_title');
    let i18nSaveIndicatorToastText = this.translator.t('acctmgmt_save_indicator_text');

    return (
      <fieldset>
        <Container fluid={true}>
          <Row>
            <Col md='4'></Col>
            <Col md='8'>
              <div className='editor-firstrow-label-aligner'>
                <legend>
                  <span className='first-editor-field-label'>{this.translator.t('acctmgmt_secq_title')}</span>
                </legend>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md='4'></Col>
            <Col md='4'>
              <div className='vertical-spacer-small'></div>
              <div className='editor-diatom-field'>
                <label htmlFor='question_1' className='editor-diatom-superscript-lbl'>
                  {this.translator.t('acctmgmt_question_1')}
                </label>
                <br />
                <select
                  id='question_1'
                  className='cwp'
                  onChange={e => {
                    let chosenId = e.currentTarget.value;
                    this.questionSelected(0, chosenId);
                  }}
                  value={this.allQuestionsSorted[questionIdOne] && this.allQuestionsSorted[questionIdOne].value}
                >
                  <option value={''}>{this.translator.t('your_information.challenge_question_1')}</option>
                  {firstQuestionsList.map((question, index) => {
                    if (question && question.code) {
                      return (
                        <option key={'firstQuestionsList_' + index} label={question.value}>
                          {question.value}
                        </option>
                      );
                    }
                  })}
                </select>
                {this.state.missing_question && this.state.missing_question.includes(0) && (
                  <div className='invalid-indicator'>{this.translator.t('acctmgmt_secq_question')}</div>
                )}

                <label htmlFor='qanswer_0' className='editor-diatom-superscript-lbl d-block mt-3'>
                  {this.translator.t('acctmgmt_answer')}
                </label>
                <Input
                  className='cwp'
                  type='text'
                  name={'qanswer_0'}
                  id={'qanswer_0'}
                  onChange={evt => this.handleTextEditing(evt, 0)}
                  placeholder={firstAnswer}
                  value={firstAnswer}
                />
                {this.state.missing_answer && this.state.missing_answer.includes(0) && (
                  <div className='invalid-indicator'>{this.translator.t('acctmgmt_enter_secq_prompt')}</div>
                )}
              </div>
            </Col>
            <Col md='3'></Col>
            <Col md='1'></Col>
          </Row>

          <Row>
            <Col md='4'></Col>
            <Col md='4'>
              <div className='vertical-spacer-small'></div>
              <div className='editor-diatom-field'>
                <label htmlFor='question_2' className='editor-diatom-superscript-lbl'>
                  {this.translator.t('acctmgmt_question_2')}
                </label>
                <br />
                <select
                  id='question_2'
                  className='cwp'
                  onChange={e => {
                    let chosenId = e.currentTarget.value;
                    this.questionSelected(1, chosenId);
                  }}
                  value={this.allQuestionsSorted[questionIdTwo] && this.allQuestionsSorted[questionIdTwo].value}
                >
                  <option value={''}>{this.translator.t('your_information.challenge_question_2')}</option>
                  {secondQuestionsList.map((question, index) => {
                    if (question && question.code) {
                      return (
                        <option key={'secondQuestionsList_' + index} label={question.value}>
                          {question.value}
                        </option>
                      );
                    }
                  })}
                </select>
                {this.state.missing_question && this.state.missing_question.includes(1) && (
                  <div className='invalid-indicator'>{this.translator.t('acctmgmt_secq_question')}</div>
                )}

                <label htmlFor='qanswer_1' className='editor-diatom-superscript-lbl d-block mt-3'>
                  {this.translator.t('acctmgmt_answer')}
                </label>
                <Input
                  className='cwp'
                  type='text'
                  name={'qanswer_1'}
                  id={'qanswer_1'}
                  onChange={evt => this.handleTextEditing(evt, 1)}
                  placeholder={secondAnswer}
                  value={secondAnswer}
                />
                {this.state.missing_answer && this.state.missing_answer.includes(1) && (
                  <div className='invalid-indicator'>{this.translator.t('acctmgmt_enter_secq_prompt')}</div>
                )}
              </div>
            </Col>
            <Col md='3'></Col>
            <Col md='1'></Col>
          </Row>

          <Row>
            <Col md='12'>
              <ThemedToast
                title={i18nSaveIndicatorToastTitle}
                msg={i18nSaveIndicatorToastText}
                display={this.props.saveExecuted === true}
                returnFocusAfterClose={false}
                onClosed={this.props.setFocusOnBackAfterModalDismissed}
              />
            </Col>
          </Row>
          <Row>
            <Col md='10'>&nbsp;</Col>
            <Col md='2'>
              <Button
                disabled={
                  (this.state.missing_question && this.state.missing_question.length > 0) ||
                  (this.state.missing_answer && this.state.missing_answer.length > 0) ||
                  (this.state.usersSecurityQuestions &&
                    !this.userSecurityQuestionsAreValid(this.state.usersSecurityQuestions))
                }
                onClick={evt => {
                  this.saveFinalUsersChallengeQuestions();
                }}
                className='cwp'
                color='primary'
              >
                {this.translator.t('save_changes_label')}
              </Button>
            </Col>
          </Row>
        </Container>
      </fieldset>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        saveUserSecurityQuestions: actions.saveUserSecurityQuestions
      },
      dispatch
    )
  };
};

const mapStateToProps = state => {
  let { saveUserSecurityQuestions } = state;
  let hashObj = {
    sqeData: saveUserSecurityQuestions
  };
  if (isNonEmptyObject(saveUserSecurityQuestions) && isNonEmptyObject(saveUserSecurityQuestions.data)) {
    hashObj['saveExecuted'] = true;
    state.saveUserSecurityQuestions = {};
  }
  return hashObj;
};

export { SecurityQuestionsEditor };
export default connect(mapStateToProps, mapDispatchToProps)(SecurityQuestionsEditor);
