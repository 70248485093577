import React from 'react';
import PropTypes from 'prop-types';
import { TosStatusOption } from '../../providers/GarageProvider';
import HtmlToReact, { Parser } from 'html-to-react';
import ReactShowMoreText from 'react-show-more-text';

export default class AutoLabelCheckbox extends React.Component {
  static propTypes = {
    value: PropTypes.shape({
      checkboxNumber: PropTypes.number.isRequired,
      checkboxText: PropTypes.string.isRequired,
      urls: PropTypes.arrayOf(
        PropTypes.shape({
          textToHyperlink: PropTypes.string.isRequired,
          URL: PropTypes.string.isRequired
        })
      ),
      displayTextLimit: PropTypes.number
    }).isRequired,
    onChange: PropTypes.func,
    onClickHyperLink: PropTypes.func.isRequired,
    unClickedLinks: PropTypes.array.isRequired,
    disableCheckboxUntilAllLinksClicked: PropTypes.bool.isRequired,
    translator: PropTypes.object.isRequired
  };

  static parseValuesFromTosAcceptances = tosAcceptances => {
    return tosAcceptances.map(({ tosText, tosContentUrls, tosDisplayTextLimit }, index) => {
      const value = {
        checkboxNumber: index,
        checkboxText: tosText,
        displayTextLimit: tosDisplayTextLimit
      };

      if (Array.isArray(tosContentUrls)) {
        value.urls = tosContentUrls.map(({ tosTextToHyperlink, tosURL }) => ({
          textToHyperlink: tosTextToHyperlink,
          URL: tosURL
        }));
      }

      return value;
    });
  };

  static parseValuesFromConsentCheckbox = consentCheckbox => {
    return consentCheckbox.map(
      ({ consentCheckboxNumber, consentCheckboxText, consentCheckboxUrls, tosDisplayTextLimit }) => {
        const value = {
          checkboxNumber: consentCheckboxNumber,
          checkboxText: consentCheckboxText,
          displayTextLimit: tosDisplayTextLimit
        };

        if (Array.isArray(consentCheckboxUrls)) {
          value.urls = consentCheckboxUrls.map(({ consentCheckboxTextToHyperlink, ConsentCheckboxURL }) => ({
            textToHyperlink: consentCheckboxTextToHyperlink,
            URL: ConsentCheckboxURL
          }));
        }

        return value;
      }
    );
  };

  static getInnerUrls = consents => {
    if (Array.isArray(consents)) {
      const urlsToVisit = new Set();
      for (const { urls } of consents) {
        if (Array.isArray(urls)) {
          urls.forEach(url => urlsToVisit.add(url.URL));
        }
      }

      return Array.from(urlsToVisit);
    }

    return [];
  };

  state = {
    htmlContent: '',
    wrapShowMore: false
  };

  componentDidMount () {
    const { checkboxText, urls, displayTextLimit } = this.props.value || {};

    let htmlContent = checkboxText;
    if (Array.isArray(urls) && urls.length > 0) {
      for (const url of urls) {
        htmlContent = htmlContent.replace(
          url.textToHyperlink,
          `<a href="${url.URL}" target="_blank" className="cwp" rel="noopener noreferrer">${url.textToHyperlink}</a>`
        );
      }
    }

    let linksToVisit = new Set();
    if (Array.isArray(urls)) {
      urls.forEach(url => linksToVisit.add(url.URL));
    }

    let wrapShowMore =
      Number.isInteger(displayTextLimit) && typeof checkboxText === 'string' && checkboxText.length > displayTextLimit;

    this.setState({ htmlContent, linksToVisit: Array.from(linksToVisit), wrapShowMore });
  }

  onChangeTosStatus = e => {
    if (this.props.onChange) {
      const tosStatus = e.currentTarget?.checked ? TosStatusOption.ACCEPT : null;

      this.props.onChange(tosStatus);
    }
  };

  onClickHandler = e => {
    const href = e.currentTarget.getAttribute('href');

    this.props.onClickHyperLink(href);
  };

  renderHtmlContent = (htmlContent = this.state.htmlContent) => {
    const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions();
    const htmlToReactParser = new Parser();

    const onClickHandler = this.onClickHandler;
    const processingInstructions = [
      {
        shouldProcessNode: function (node) {
          return node.name === 'a';
        },
        processNode: function (node, children, index) {
          return (
            <a key={`consentLink-${index}`} {...node.attribs} onClick={onClickHandler}>
              {children}
            </a>
          );
        }
      },
      {
        shouldProcessNode: function (node) {
          return true;
        },
        processNode: processNodeDefinitions.processDefaultNode
      }
    ];

    return htmlToReactParser.parseWithInstructions(htmlContent, () => true, processingInstructions);
  };

  render () {
    const { translator, unClickedLinks, value, disableCheckboxUntilAllLinksClicked } = this.props;
    const linksToVisit = this.state.linksToVisit || [];
    const linksNotClicked =
      Array.isArray(unClickedLinks) && linksToVisit.some(linkToVisit => unClickedLinks.includes(linkToVisit));

    const checkboxNumber = value?.checkboxNumber;
    const uniqueId = `chkAcceptConsent-${checkboxNumber}`;

    return (
      <article className='form-check'>
        <input
          type='checkbox'
          id={uniqueId}
          className='form-check-input mt-2 scale125'
          value={TosStatusOption.ACCEPT}
          onChange={this.onChangeTosStatus}
          required={true}
          disabled={disableCheckboxUntilAllLinksClicked && linksNotClicked}
        />

        <label className='form-check-label' htmlFor={uniqueId}>
          {this.state.wrapShowMore ? (
            <ReactShowMoreText
              lines={7}
              more={translator.t('buyplans.label.read-more')}
              less={translator.t('buyplans.label.read-less')}
              className='text-muted small text-justify checkboxLabelcorrectedWidth'
              anchorClass='cwp-blue small'
              onClick={this.executeOnClick}
              expanded={false}
            >
              {this.renderHtmlContent()}
            </ReactShowMoreText>
          ) : (
            this.renderHtmlContent()
          )}
        </label>
      </article>
    );
  }
}
