import React, { Component } from 'react';
import './faqCA.scss';
import download from '../../../assets/faq/download.png';
import chat from '../../../assets/faq/chat.png';
import mobile from '../../../assets/faq/mobile.png';
import offers from '../../../assets/faq/offers.png';
import pin from '../../../assets/faq/pin.png';
import play from '../../../assets/faq/play.png';
import profile from '../../../assets/faq/profile.png';
import safety from '../../../assets/faq/safety.png';
import vehiclehelth from '../../../assets/faq/vehiclehealth.png';
import getCwpMeta from '../../../providers/cwpMetaProvider';
import { setTitle } from '../../../providers/documentTitleProvider';

export default class FAQCA extends Component {
  mounted = true;
  state = {
    staticContentBase: ''
  };

  componentDidMount () {
    setTitle('document_titles.faq.faq_main');
    // get the staticContentBase
    const getMeta = async () => {
      let meta = await getCwpMeta();

      let staticContentBase = meta.static.content.urls.staticContentBase;

      if (this.mounted) {
        this.setState({ staticContentBase });
      }
    };
    getMeta();
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  render () {
    return (
      <div className='AppCA' role='main' id='main-content'>
        <div className='gobackfaq'>
          <a href='#' onClick={historyBack}>
            <span className='arrow'> &larr; </span>
            <span className='back'>Précédent</span>
          </a>
        </div>
        <h1 className='faq-header'>
          <span className='faq-back-header'>FAQs</span> générale sur le compte Car-Net
        </h1>
        <div className='faq-sub-header'>
          <span>
            Consultez la FAQ pour obtenir des réponses aux questions les plus fréquemment posées. <br />
            Si vous avez besoin d’aide supplémentaire, appelez l’un des numéros ci-dessous. Année-modèle 2022 et plus :
            <a href='tel:833-435-1011'> 833-435-1011</a>
          </span>
        </div>
        <div className='download'>
          <span>
            <a href={`${this.state.staticContentBase}fr-CA/files/qrg-my21.pdf`} target='_blank' rel='noreferrer'>
              <img src={download} alt='download' width='14'></img>Guide de référence rapide (2022 et plus)
            </a>
          </span>
        </div>
        <table className='first-row-data'>
          <tr>
            <td className='first-row'>
              <div className='help'>
                <div className='help-img'>
                  <img src={profile} alt='' width='24'></img>
                </div>

                <div className='faq-head'>Compte</div>

                <div className='article-title'>
                  <p>
                    Obtenez des renseignements utiles sur le compte et <br />
                    des réponses à d’autres questions générales
                  </p>
                </div>
                <div className='viewFaqLink'>
                  <a href='#/fr/ca/account-faq-ca'>
                    <div className='viewFaqText'>Voir la FAQ &#129106;</div>
                  </a>
                </div>
              </div>
            </td>

            <td className='first-row'>
              <div className='help'>
                <div className='help-img'>
                  <img src={mobile} alt='' width='24'></img>
                </div>

                <div className='faq-head'>Services à distance</div>

                <div className='article-title'>
                  <p>
                    Vérifiez à distance l’état des portières, des phares,
                    <br /> du niveau de carburant, etc. de votre véhicule.
                  </p>
                </div>
                <div className='viewFaqLink'>
                  <a href='#/fr/ca/remote-services-faq-ca'>
                    <div className='viewFaqText'>Voir la FAQ &#129106;</div>
                  </a>
                </div>
              </div>
            </td>

            <td className='first-row'>
              <div class='help'>
                <div className='help-img'>
                  <img src={chat} alt='' width='24'></img>
                </div>
                <div className='faq-head'>Centre de messages/Activité du véhicule</div>
                <div className='article-title'>
                  <p>
                    Vérifiez les interactions que vous avez eues avec votre <br />
                    véhicule, comme les entrées et les sorties de périmètre, <br />
                    les informations d’alerte de vitesse, les demandes <br />
                    de déverrouillage de porte, et bien plus.
                  </p>
                </div>
                <br />
                <div className='viewFaqLink'>
                  <a href='#/fr/ca/message-center-faq-ca'>
                    <div className='viewFaqText'>Voir la FAQ &#129106;</div>
                  </a>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td className='first-row'>
              <div className='help'>
                <div className='help-img'>
                  <img src={offers} alt='' width='24'></img>
                </div>

                <div className='faq-head'>Plans d’achat</div>

                <div className='article-title'>
                  <p>
                    Obtenez des renseignements utiles sur le compte <br />
                    et des réponses à d’autres questions générales
                  </p>
                </div>
                <div className='viewFaqLink'>
                  <a href='#/fr/ca/plan-and-pricing-faq-ca'>
                    <div className='viewFaqText'>Voir la FAQ &#129106;</div>
                  </a>
                </div>
              </div>
            </td>

            <td className='first-row'>
              <div className='help'>
                <div className='help-img'>
                  <img src={safety} alt='' width='24'></img>
                </div>

                <div className='faq-head'>Alertes de surveillance parentale</div>

                <div className='article-title'>
                  <p>
                    Vérifiez à distance l’état des portières, des phares, du niveau
                    <br /> de carburant, etc. de votre véhicule.
                  </p>
                </div>
                <div className='viewFaqLink'>
                  <a href='#/fr/ca/family-guardian-faq-ca'>
                    <div className='viewFaqText'>Voir la FAQ &#129106;</div>
                  </a>
                </div>
              </div>
            </td>

            <td className='first-row'>
              <div className='help'>
                <div className='help-img'>
                  <img src={pin} alt='' width='24'></img>
                </div>
                <div className='faq-head'>Naviguer/rechercher et envoyer des points d’intérêt</div>
                <div className='article-title'>
                  <p>
                    Vérifiez les interactions que vous avez eues avec votre <br />
                    véhicule, comme les entrées et les sorties de périmètre, les i<br />
                    nformations d’alerte de vitesse, les demandes de déverrouillage <br />
                    de porte, et bien plus.
                  </p>
                </div>
                <div className='viewFaqLink'>
                  <a href='#/fr/ca/navigate-faq-ca'>
                    <div className='viewFaqText'>Voir la FAQ &#129106;</div>
                  </a>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td className='first-row'>
              <div className='help'>
                <div className='help-img'>
                  <img src={vehiclehelth} alt='' width='24'></img>
                </div>

                <div className='faq-head'>Bilan de santé du véhicule</div>

                <div className='article-title'>
                  <p>
                    Obtenez des renseignements utiles sur le compte et des
                    <br /> réponses à d’autres questions générales
                  </p>
                </div>
                <div className='viewFaqLink'>
                  <a href='#/fr/ca/vhr-faq-ca'>
                    <div className='viewFaqText'>Voir la FAQ &#129106;</div>
                  </a>
                </div>
              </div>
            </td>

            <td className='first-row'>
              <div class='help'>
                <div className='help-img'>
                  <img src={play} alt='' width='24'></img>
                </div>
                <div className='faq-head'>Vidéos pratiques</div>
                <div className='article-title'>
                  <p>
                    Regardez les didacticiels vidéo VW Car-Net<sup>MD</sup> pour <br />
                    apprendre à tirer le meilleur parti de votre véhicule.
                  </p>
                </div>
                <div className='viewFaqLink'>
                  <a href='#/fr/ca/marketing-video-ca-fr'>
                    <div className='viewFaqText'>Voir la FAQ &#129106;</div>
                  </a>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    );
  }
}

const historyBack = e => {
  e.preventDefault();
  e.stopPropagation();
  window && window.history && window.history.back();
};

export { FAQCA, historyBack };
