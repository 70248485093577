import invoke from './ServiceProxy';

const AVAILABLE = 'AVAILABLE';
const UBI_PAUSED_MSG = 'UBI_PAUSED_MSG';
const UBI_GEICO_MSG = 'GeicoBanner';
const INSURANCE_OFFERS_AVAILABLE = 'InsuranceOffers';

const getVehicleTripsFeatureGroup = async () => {
  return invoke({ url: '/vehicle/trips/featuregroup' });
};

const isAvailableUbiPausedMessage = async () => {
  const response = await getVehicleTripsFeatureGroup();

  const features = response.data?.features;

  return (
    Array.isArray(features) &&
    features.some(feature => feature.featureCode === UBI_PAUSED_MSG && feature.capability === AVAILABLE)
  );
};

const isGeicoBannerAvailable = async () => {
  const response = await getVehicleTripsFeatureGroup();
  const features = response.data?.features;

  let geicoMsgIsAvailable =
    Array.isArray(features) &&
    features.some(feature => feature.featureCode === UBI_GEICO_MSG && feature.capability === AVAILABLE);

  return { geicoMsgIsAvailable };
};

const combinedDriveViewFasFlags = async () => {
  const response = await getVehicleTripsFeatureGroup();
  const features = response.data?.features;

  let geicoMsgIsAvailable =
    Array.isArray(features) &&
    features.some(feature => feature.featureCode === UBI_GEICO_MSG && feature.capability === AVAILABLE);

  let insuranceOffersAvailable =
    Array.isArray(features) &&
    features.some(feature => feature.featureCode === INSURANCE_OFFERS_AVAILABLE && feature.capability === AVAILABLE);

  return { geicoMsgIsAvailable, insuranceOffersAvailable };
};

export { getVehicleTripsFeatureGroup, isAvailableUbiPausedMessage, combinedDriveViewFasFlags, isGeicoBannerAvailable };
