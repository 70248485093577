import PropTypes from 'prop-types';
import React from 'react';
import { ChamberlainLinkingStatus, SubCategory, findArticleDisclaimerText, pickByStatus } from '../buyPlansUtil';
import { dateFormat } from '../../../../util/i18n/localeUtils';
import { BuyPlansContext } from '../BuyPlansContext';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

export default class CardCurrentPlan extends React.Component {
  static contextType = BuyPlansContext;

  static propTypes = {
    plan: PropTypes.object.isRequired,
    marketingConfiguration: PropTypes.object
  };

  state = {
    articleDisclaimer: null
  };

  componentDidMount () {
    const marketingConfiguration = this.props.marketingConfiguration;
    const articleDisclaimer = findArticleDisclaimerText(marketingConfiguration);

    this.setState({ articleDisclaimer });
  }

  isInactiveGaragecontrolPlan = () => {
    const { linkingStatus = '', subCategory = '' } = this.props.plan || {};

    return (
      linkingStatus.toUpperCase() === ChamberlainLinkingStatus.INACTIVE &&
      [SubCategory.GARAGECONTROL_TRIAL, SubCategory.GARAGECONTROL].includes(subCategory)
    );
  };

  findCssClassByPlanStatus = planStatus => {
    if (this.isInactiveGaragecontrolPlan()) {
      return 'planStatus bgIconPending';
    }

    return pickByStatus(
      planStatus,
      () => 'planStatus bgIconActive',
      () => 'planStatus bgIconPending',
      () => 'planStatus bgIconExpired'
    );
  };

  findCopyByPlanStatus = (planStatus, translator) => {
    if (this.isInactiveGaragecontrolPlan()) {
      return translator.t('chamberlain.linkAccountStatus');
    }

    return pickByStatus(
      planStatus,
      () => translator.t('buyplans_purchase_status_active'),
      () => translator.t('buyplans_purchase_status_pending'),
      () => translator.t('buyplans_purchase_status_expired')
    );
  };

  render () {
    const { translator, contentURL, showDisclaimer, selectPlan } = this.context;
    const { plan, marketingConfiguration } = this.props;

    return (
      <section className='d-flex flex-column cardCurrentPlan'>
        <article className='d-flex flex-row align-items-start'>
          {marketingConfiguration?.summary?.icon && (
            <img
              src={contentURL + marketingConfiguration?.summary?.icon}
              alt={marketingConfiguration?.summary?.icon}
              className='mr-2 iconPlan'
            />
          )}
          <h2 className='h4 font-weight-bold'>
            {marketingConfiguration?.currentPlanContent?.title || marketingConfiguration?.title}
            {this.state.articleDisclaimer && (
              <button
                className='cwp-disclaimer'
                onClick={e =>
                  showDisclaimer(
                    this.state.articleDisclaimer,
                    marketingConfiguration?.articleDisclaimerNumber,
                    e.currentTarget
                  )
                }
              >
                {marketingConfiguration?.articleDisclaimerNumber}
              </button>
            )}
          </h2>
          <span className='flex-grow-1'></span>
          <span className={this.findCssClassByPlanStatus(plan?.status)}>
            {this.findCopyByPlanStatus(plan?.status, translator)}
          </span>
        </article>
        <article className='cwpFontSmall'>
          {plan.activatedOn && (
            <span>
              {translator.t('buyplans_landing_current_plans_start_date', { date: dateFormat(plan.activatedOn) })}
            </span>
          )}
          {plan.expirationDate && (
            <span className='ml-3'>
              {translator.t('buyplans_landing_current_plans_end_date', { date: dateFormat(plan.expirationDate) })}
            </span>
          )}
        </article>
        <article className='flex-grow-1 pt-3'>
          {plan.currentPlanContent?.descriptionPending ||
            plan.currentPlanContent?.descriptionActive ||
            marketingConfiguration?.summary?.shortDescription ||
            marketingConfiguration?.summary?.title}
        </article>
        {marketingConfiguration?.summary?.providerLogo && (
          <article>
            <img
              src={'' + contentURL + marketingConfiguration?.summary?.providerLogo}
              alt={marketingConfiguration?.summary?.providerName}
            ></img>
          </article>
        )}
        <article>
          <Link
            to={`/plan/${plan.category}/${plan.subCategory}/${plan.marketingConfigurationId}`.toLowerCase()}
            className='p-0 mt-4'
          >
            <Button
              className='btn btn-link carnetLink bgArrowRight'
              color='primary'
              onClick={async () => selectPlan(plan)}
            >
              {marketingConfiguration?.summary?.activeArticleActionText ||
                translator.t('buyplans.button-label.learn-more')}
            </Button>
          </Link>
        </article>
      </section>
    );
  }
}
