import React from 'react';
import { BuyPlansContext } from '../BuyPlansContext';
import { findArticleDisclaimerText } from '../buyPlansUtil';
import { Link } from 'react-router-dom';
import MarketingProducts from '../components/MarketingProducts';
import { UncontrolledAlert } from 'reactstrap';
import { enrollZeroCostArticle, getZeroCostEligibility } from '../../providers/AccountAndSecurityProvider';
import { findErrorMessage } from '../../../../util/i18n/i18nService';
import Spinner from '../../Spinner';

export default class SafetyFree extends React.Component {
  static contextType = BuyPlansContext;

  state = {
    marketingProducts: [],
    articleDisclaimer: null,
    processing: false,

    duration: ''
  };

  componentDidMount () {
    this.initData();
  }

  initData = async () => {
    let plan = this.context?.selectedPlan;

    if (!plan) {
      const marketingConfigurationId = this.props.match?.params?.marketingConfigurationId;
      const { additionalPlans, selectPlanFromList } = this.context;

      const [, category, subCategory] = this.props.match?.url?.split('/') || [];
      plan = selectPlanFromList(additionalPlans, category, subCategory, marketingConfigurationId);
    }

    const marketingConfiguration = this.context.marketingConfigurationHash?.[plan?.marketingConfigurationId];
    const articleDisclaimer = findArticleDisclaimerText(marketingConfiguration);

    this.setState({ articleDisclaimer });

    this.initEligibilityInfo();

    window.scrollTo(0, 0);
  };

  initEligibilityInfo = async () => {
    const subCategory = this.context?.selectedPlan?.subCategory;

    try {
      const response = await getZeroCostEligibility(subCategory);

      const subscriptionInfos = response?.applicableSubscriptionInformation;

      if (Array.isArray(subscriptionInfos) && subscriptionInfos.length > 0) {
        const freeSubMonths = subscriptionInfos[0].freeSubMonths;
        const freeSubDays = subscriptionInfos[0].freeSubDays;

        const duration = this.getDurationText(freeSubMonths, freeSubDays);
        this.setState({ duration });
      }
    } catch (error) {
      const alert = findErrorMessage(error);

      this.setState({ alert });
    }
  };

  getDurationText = (freeSubMonths, freeSubDays) => {
    const translator = this.context.translator;

    let duration = '';
    if (freeSubMonths > 12) {
      duration = translator.t('buyplans.safe-and-secure.num_years', {
        smart_count: Math.floor(freeSubMonths / 12)
      });
    }

    if (freeSubMonths > 0 && freeSubMonths % 12 !== 0) {
      duration =
        duration + ' ' + translator.t('buyplans.safe-and-secure.num_months', { smart_count: freeSubMonths % 12 });
    }

    if (freeSubDays) {
      duration = ' ' + translator.t('buyplans.safe-and-secure.num_days', { smart_count: freeSubDays });
    }

    return duration;
  };

  onClickEnroll = async e => {
    const subCategory = this.context.selectedPlan?.subCategory;

    this.setState({ processing: true });
    try {
      await enrollZeroCostArticle(subCategory);

      this.context.redirectTo('/');
    } catch (error) {
      const alert = findErrorMessage(error);

      this.setState({ alert, processing: false });
    }
  };

  clearAlerts = () => this.setState({ alert: null });

  getAlerts = () => {
    if (this.state.alert) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts} aria-live='polite' className='w-100'>
          {this.state.alert}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  render () {
    const {
      translator,
      contentURL,
      showDisclaimer,
      selectPlan,
      selectedPlan: plan,
      marketingConfigurationHash
    } = this.context;
    const marketingConfiguration = marketingConfigurationHash?.[plan?.marketingConfigurationId] || {};

    return (
      <div role='main' id='main-content' className='py-4'>
        <div className='ml-4 position-absolute'>
          <Link to='/' className='linkBack' role='button' onClick={() => selectPlan(null)}>
            {translator.t('buyplans.button-label.back-to-your-plans')}
          </Link>
        </div>
        <div className='d-flex flex-column align-items-center text-center mt-4 pb-5'>
          <h1 className='font-weight-light'>
            {marketingConfiguration?.currentPlanContent?.title || marketingConfiguration?.title}
            {this.state.articleDisclaimer && (
              <sup
                className='cwp-disclaimer ml-2 mainTitlePosition'
                tabIndex={0}
                role='button'
                onClick={e =>
                  showDisclaimer(
                    this.state.articleDisclaimer,
                    marketingConfiguration.articleDisclaimerNumber,
                    e.currentTarget
                  )
                }
              >
                {marketingConfiguration?.articleDisclaimerNumber}
              </sup>
            )}
          </h1>
          {this.state.duration && (
            <span className='col-12 col-md-6 titleDescription'>
              {translator.t('buyplans.safe-and-secure.title-complimentary-desc', {
                duration: this.state.duration
              })}
            </span>
          )}
          {this.getAlerts()}
          {this.state.processing && <Spinner />}
          <button className='cwp mt-5' onClick={this.onClickEnroll} disabled={this.state.processing}>
            {marketingConfiguration?.summary?.submitButtonText}
          </button>
        </div>

        {plan && (
          <MarketingProducts
            contentURL={contentURL}
            plan={plan}
            marketingConfiguration={marketingConfiguration}
            showDisclaimer={this.context.showDisclaimer}
          />
        )}
      </div>
    );
  }
}
