// Date format adapter for duetds date picker
/*
expected localized date formats
  const localeDateFormatMap = {
    'en-US': 'MM/dd/yyyy',
    'en-CA': 'MM/dd/yyyy',
    'fr-CA': 'dd/MM/yyyy'
  };
*/

const DATE_FORMAT = /^(\d{1,2})(\/|\.|-)(\d{1,2})(\/|\.|-)(\d{4})$/;
// create mappings for formatting to locale
export const dateAdapters = {
  'en-us': {
    parse (value = '', createDate) {
      const matches = value.match(DATE_FORMAT);
      if (matches) {
        return createDate(matches[3], matches[2], matches[1]);
      }
    },
    format (date) {
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    }
  },
  'en-ca': {
    parse (value = '', createDate) {
      const matches = value.match(DATE_FORMAT);
      if (matches) {
        return createDate(matches[3], matches[2], matches[1]);
      }
    },
    format (date) {
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    }
  },
  'fr-ca': {
    parse (value = '', createDate) {
      const matches = value.match(DATE_FORMAT);
      if (matches) {
        return createDate(matches[3], matches[2], matches[1]);
      }
    },
    format (date) {
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }
  }
};
