import { getLocaleTranslator } from '../../../../util/i18n/i18nService';
import { getLocaleSync } from '../../../../providers/languageProvider';

// Provider methods to assist in chatting with cwp payment iframe, now embedded in react app for added level of obfuscation
const getPaymentProviderModeBasedOnUrl = () => {
  // production url
  const locationIsProd = window.location.href.includes('carnet.vw.com');
  if (locationIsProd) {
    return 'live';
  }
  return 'test';
};

const initiatePaymentForm = (apiURL, authorizationToken, paymentOptionCode, callback = null) => {
  console.log('initiatePaymentForm >> ');
  /*
  console.log(apiURL);
  console.log(authorizationToken);
  console.log(paymentOptionCode);
  */

  let translator = getLocaleTranslator();
  let localeData = getLocaleSync();
  let { locale = 'en-US' } = localeData;

  var cw = window && window.cw;
  var initializationConfig = {
    apiURL,
    enableCyberSource: false, // Required as true for CyberSource provider.
    cardPaymentProvider: 'cyberSourceWithTokenEx'
  };

  console.log('calling initialize config');
  cw.initialize(initializationConfig);

  function initializeFormWithData () {
    console.log('internal initializeFormWithData >> ');
    try {
      document.getElementById('cwPaymentFormDiv').src = '';
      cw.PaymentForm(document.getElementById('cwPaymentFormDiv'), {
        submitButtonTitle: translator.t('SAVE_PAYMENT'),
        authorizationToken: authorizationToken,
        paymentOptionCodes: [paymentOptionCode],
        paymentProviderMode: getPaymentProviderModeBasedOnUrl(), // "test" | "live". Required. Switch to "live" only for production use.
        showCVVHint: false,
        locale: locale
      });
      // make everything visible if not
      document.getElementById('paymentFormBackground').style.visibility = 'visible';
      document.getElementById('cwPaymentFormDiv').style.visibility = 'visible';
      document.getElementById('cwPaymentFormDiv').firstElementChild.style.visibility = 'visible';

      if (callback && typeof callback === 'function') {
        callback();
      }
    } catch (e) {
      console.log('cw.PaymentForm failed');
      console.log(e);
      window.confirm('Unable to initialize new payment form. Please contact support.');
      hidePaymentForm();
    }
  }

  initializeFormWithData();
};

const hidePaymentForm = () => {
  var el = document.getElementById('cwPaymentFormDiv');
  if (el) {
    if (el.firstElementChild) {
      el.firstElementChild.style.visibility = 'hidden';
    }
    el.style.visibility = 'hidden';
  }
  // remove two elements injected by cwPaymentJS if present
  if (el && el.childNodes[1]) {
    el.removeChild(el.childNodes[1]);
  }
  if (el && el.childNodes[1]) {
    el.removeChild(el.childNodes[1]);
  }
  try {
    document.getElementById('paymentFormBackground').style.visibility = 'hidden';
  } catch (err) {
    console.log(err);
    console.log('unabled to find payment form background');
  }
};

export { initiatePaymentForm, hidePaymentForm };
