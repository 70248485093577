/* eslint-disable complexity */
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import Barchart from './Barchart';
import LineChart from './Linechart';
import LineMpg from '../../../assets/tripStats/line-mpg.svg';
import LineMph from '../../../assets/tripStats/line-mph.svg';
import { fuelEconomylitersToKM, kmToMiles, litersToGallons } from './TripStatsUtil';
import { isNonEmptyObject } from '../../../util/utilityMethods';

class TripStatsGraph extends Component {
  constructor () {
    super();
    this.state = {};
    this.translator = getLocaleTranslator();
  }

  render () {
    const tripSummary = this.props.remoteTripDetails && this.props.remoteTripDetails.consolidateTripSummary;
    const graphStatistics = this.props.graphData && this.props.graphData.statisticsForThisPeroid;
    const userIsFromUS = String(this.props.userCountry).toUpperCase() === 'US';
    const avgFuelEconomy = tripSummary && tripSummary.avgFuelEconomy;
    const avgSpeed = tripSummary && tripSummary.avgSpeed;
    const totalDistanceDriven = tripSummary && tripSummary.totalDistanceDriven;

    let xaxisCategories = [];
    let barChartData = [];
    let lineChartMphData = [];
    let lineChartMpgData = [];
    let barChartMinValue;
    let barChartMaxValue;

    if (this.props.viewType === 'weekly') {
      xaxisCategories = [
        this.translator.t('trips.day_sun'),
        this.translator.t('trips.day_mon'),
        this.translator.t('trips.day_tue'),
        this.translator.t('trips.day_wed'),
        this.translator.t('trips.day_thu'),
        this.translator.t('trips.day_fri'),
        this.translator.t('trips.day_sat')
      ];
      this.extractGraphData(graphStatistics, barChartData, lineChartMpgData, lineChartMphData, userIsFromUS);
      barChartMinValue = 0;
      barChartMaxValue = 500;
    } else {
      xaxisCategories = [
        this.translator.t('trips.week_1'),
        this.translator.t('trips.week_2'),
        this.translator.t('trips.week_3'),
        this.translator.t('trips.week_4'),
        this.translator.t('trips.week_5'),
        this.translator.t('trips.week_6')
      ];
      this.extractGraphData(graphStatistics, barChartData, lineChartMpgData, lineChartMphData, userIsFromUS);
      barChartMinValue = 0;
      barChartMaxValue = 1000;
    }

    return (
      <div>
        <Row>
          <Col md={6}>
            <div className='rts-card'>
              <br />
              <div>
                <span
                  className={
                    this.props.locale.includes('fr') ? 'graph-avgspeed-summary-fr' : 'graph-avgspeed-summary-en'
                  }
                >
                  <span className='graph-avgspeed'>
                    {avgSpeed && (userIsFromUS ? kmToMiles(avgSpeed) : avgSpeed.toFixed(2))}
                  </span>
                  &nbsp;{this.translator.t('trips.average_mph_or_kmph_label')}
                </span>
                {!avgFuelEconomy && <span className='summary-no-data'></span>}
                <span
                  className={this.props.locale.includes('fr') ? 'graph-avgfuel-summary-fr' : 'graph-avgfuel-summary-en'}
                >
                  <span className='graph-avgfuel'>
                    {avgFuelEconomy &&
                      (userIsFromUS ? litersToGallons(avgFuelEconomy) : fuelEconomylitersToKM(avgFuelEconomy))}
                  </span>
                  &nbsp;{this.translator.t('trips.average_mpg_or_kml_label')}
                </span>
              </div>
              <div>
                <img
                  src={LineMph}
                  className='line-mph-icon'
                  alt={this.translator.t('trips.text_avg_speed_' + this.props.viewType)}
                  title={this.translator.t('trips.text_avg_speed_' + this.props.viewType)}
                />
                <img
                  src={LineMpg}
                  className='line-mpg-icon'
                  alt={this.translator.t('trips.text_avg_fuel_' + this.props.viewType)}
                  title={this.translator.t('trips.text_avg_fuel_' + this.props.viewType)}
                />
              </div>
              <LineChart
                xaxisCategories={xaxisCategories}
                lineChartMphData={lineChartMphData}
                lineChartMpgData={lineChartMpgData}
              />
            </div>
          </Col>
          <div></div>
          <Col md={6}>
            <div className='rts-card'>
              <br />
              <div>
                <span className='graph-totaldistance-summary'>
                  <span className='graph-totaldistance'>
                    {totalDistanceDriven &&
                      (userIsFromUS ? kmToMiles(totalDistanceDriven) : totalDistanceDriven.toFixed(2))}
                  </span>
                  {this.props.viewType === 'weekly' ? (
                    <span>{this.translator.t('trips.driven_this_week_label')} </span>
                  ) : (
                    <span>{this.translator.t('trips.driven_this_month_label')}</span>
                  )}
                </span>
                <span className='graph-totaltrips-summary'>
                  <span className='graph-totaltrips'>{tripSummary && tripSummary.totalTrips}</span>
                  <span>{this.translator.t('trips.total_trips_label')}</span>
                </span>
              </div>
              <div>
                <Barchart
                  xaxisCategories={xaxisCategories}
                  barChartData={barChartData}
                  barChartMinValue={barChartMinValue}
                  barChartMaxValue={barChartMaxValue}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  extractGraphData (graphStatistics, barChartData, lineChartMpgData, lineChartMphData, userIsFromUS) {
    if (isNonEmptyObject(graphStatistics)) {
      graphStatistics.map(graphStats => {
        if (graphStats.type === 'DISTANCE') {
          graphStats.stats.map(distance => {
            let distance_value = distance && distance.value ? distance.value : 0;
            return barChartData.push(userIsFromUS ? kmToMiles(distance_value) : distance_value);
          });
        }

        if (graphStats.type === 'AVG_FUEL_ECONOMY') {
          graphStats.stats.map(fuelEconomy => {
            let fuelEconomy_value = fuelEconomy && fuelEconomy.value ? fuelEconomy.value : 0;
            return lineChartMpgData.push(userIsFromUS ? litersToGallons(fuelEconomy_value) : fuelEconomy_value);
          });
        }

        if (graphStats.type === 'AVG_SPEED') {
          graphStats.stats.map(speed => {
            let speed_value = speed && speed.value ? speed.value : 0;
            return lineChartMphData.push(userIsFromUS ? kmToMiles(speed_value) : speed_value);
          });
        }
        return null;
      });
    }
  }
}

export default TripStatsGraph;
