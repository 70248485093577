import * as moment from 'moment';

import { formatDateWithDay, formatSpelledOutMonthAndYear } from '../../../util/i18n/localeUtils';

var date = new Date();
var currentDate = moment(date);
let displayDate = '';

export const getDayDisplayDate = (daySelected, previousSelected, nextSelected, trackdate) => {
  if (daySelected) {
    if (!previousSelected && !nextSelected) {
      displayDate = getFormattedDayDisplayDate(moment(currentDate));
    } else if (previousSelected) {
      displayDate = getFormattedDayDisplayDate(moment(trackdate));
    } else {
      displayDate = getFormattedDayDisplayDate(moment(trackdate));
    }
  }
  return displayDate;
};

export const getWeekDisplayDate = (weekSelected, previousSelected, nextSelected, trackdate) => {
  if (weekSelected) {
    if (!previousSelected && !nextSelected) {
      displayDate = getFormattedWeeklyDisplayDate(moment().startOf('week'), moment().endOf('week'));
    } else if (previousSelected) {
      displayDate = getFormattedWeeklyDisplayDate(moment(trackdate).startOf('week'), moment(trackdate).endOf('week'));
    } else {
      displayDate = getFormattedWeeklyDisplayDate(moment(trackdate).startOf('week'), moment(trackdate).endOf('week'));
    }
  }
  return displayDate;
};

export const getMonthDisplayDate = (monthSelected, previousSelected, nextSelected, trackdate) => {
  if (monthSelected) {
    if (!previousSelected && !nextSelected) {
      displayDate = getFormattedMonthlyDisplayDate(moment(currentDate));
    } else if (previousSelected) {
      displayDate = getFormattedMonthlyDisplayDate(moment(trackdate));
    } else {
      displayDate = getFormattedMonthlyDisplayDate(moment(trackdate));
    }
  }
  return displayDate;
};

export const getFormattedDayDisplayDate = date => {
  return date.format('ddd, MMM DD, YYYY');
};

export const getFormattedWeeklyDisplayDate = (startOfWeek, endOfWeek) => {
  //return startOfWeek.format('ddd, MMM DD, YYYY') + ' - ' + endOfWeek.format('ddd, MMM DD, YYYY');
  return formatDateWithDay(startOfWeek) + ' - ' + formatDateWithDay(endOfWeek);
};

export const getFormattedMonthlyDisplayDate = date => {
  // return date.format('MMMM YYYY');
  return formatSpelledOutMonthAndYear(date);
};

export const getDayTrackingDate = (daySelected, previousSelected, nextSelected, date) => {
  let trackDate;

  if (daySelected && !previousSelected && !nextSelected) {
    var todayDate = new Date();
    trackDate = moment(todayDate);
    let currentDay = trackDate.format('MM/DD/YYYY');
    return currentDay;
  } else if (daySelected && previousSelected) {
    trackDate = moment(date);
    let previousDay = trackDate.subtract(1, 'day').format('MM/DD/YYYY');
    return previousDay;
  } else if (daySelected && nextSelected) {
    trackDate = moment(date);
    let nextDay = trackDate.add(1, 'day').format('MM/DD/YYYY');
    return nextDay;
  }
};

export const getWeekTrackingDate = (weekSelected, previousSelected, nextSelected, date) => {
  let trackDate;
  if (weekSelected && !previousSelected && !nextSelected) {
    let startOfWeek = moment().startOf('week');
    let currentWeek = startOfWeek.format('MM/DD/YYYY');
    return currentWeek;
  } else if (weekSelected && previousSelected) {
    trackDate = moment(date);
    let previousWeekStart = trackDate.subtract(1, 'week').format('MM/DD/YYYY');
    return previousWeekStart;
  } else {
    trackDate = moment(date);
    let nextWeekStart = trackDate.add(1, 'week').format('MM/DD/YYYY');
    return nextWeekStart;
  }
};

export const getMonthTrackingDate = (monthSelected, previousSelected, nextSelected, date) => {
  let trackDate;
  if (monthSelected && !previousSelected && !nextSelected) {
    var todayDate = new Date();
    trackDate = moment(todayDate);
    let currentMonth = trackDate.format('MM/DD/YYYY');
    return currentMonth;
  } else if (monthSelected && previousSelected) {
    trackDate = moment(date);
    let previousMonth = trackDate.subtract(1, 'month').format('MM/DD/YYYY');
    return previousMonth;
  } else if (monthSelected && nextSelected) {
    trackDate = moment(date);
    let nextMonth = trackDate.add(1, 'month').format('MM/DD/YYYY');
    return nextMonth;
  }
};

export const getTodaysDate = () => {
  var todayDate = new Date();
  var d = moment(todayDate);
  let extractedDate = d.format('MM/DD/YYYY');
  return extractedDate;
};

export const cwpFormatDate = dateObj => {
  var d = moment(dateObj);
  let fmtDate = d.format('MM-DD-YYYY');
  return fmtDate;
};

export const getFormattedDateFromEpochMs = epochTimeMs => {
  let d = moment(epochTimeMs);
  return d.format('MM/DD/YYYY');
};

export const getWeeklyStartDate = () => {
  let startOfWeek = moment().startOf('week');
  let extractedDate = startOfWeek.format('MM/DD/YYYY');
  return extractedDate;
};

export const getEpochStartWeek = startOfWeek => {
  return moment(startOfWeek).valueOf();
};

export const getEpochEndWeek = endOfWeek => {
  let endWeek = moment(endOfWeek).endOf('week');
  return moment(endWeek).valueOf();
};

export const getEpochMonthStart = monthDate => {
  return moment(monthDate)
    .startOf('month')
    .valueOf();
};

export const getEpochMonthEnd = monthDate => {
  return moment(monthDate)
    .endOf('month')
    .valueOf();
};

export const getEpochDayStartDate = date => {
  return moment(date)
    .startOf('day')
    .valueOf();
};

export const getEpochDayEndDate = date => {
  return moment(date)
    .endOf('day')
    .valueOf();
};

// Will Cleanup this later just for my reference

/*export const getDisplayDate = (daySelected, weekSelected, monthSelected, previousSelected, nextSelected, trackdate) => {
    var date = new Date();
    var currentDate = moment(date);
    let displayDate = '';
    if (daySelected) {
      if (!previousSelected && !nextSelected) {
        displayDate = getFormattedDayDisplayDate(moment(currentDate));
      } else if (previousSelected) {
        displayDate = getFormattedDayDisplayDate(moment(trackdate));
      } else {
        displayDate = getFormattedDayDisplayDate(moment(trackdate));
      }
    } else if (weekSelected) {
      if (!previousSelected && !nextSelected) {
        displayDate = getFormattedWeeklyDisplayDate(moment().startOf('week'), moment().endOf('week'));
      } else if (previousSelected) {
        displayDate = getFormattedWeeklyDisplayDate(moment(trackdate).startOf('week'), moment(trackdate).endOf('week'));
      } else {
        displayDate = getFormattedWeeklyDisplayDate(moment(trackdate).startOf('week'), moment(trackdate).endOf('week'));
      }
    } else {
      if (monthSelected && !previousSelected && !nextSelected) {
        displayDate = getFormattedMonthlyDisplayDate(moment(currentDate));
      } else if (previousSelected) {
        displayDate = getFormattedMonthlyDisplayDate(moment(trackdate));
      } else {
        displayDate = getFormattedMonthlyDisplayDate(moment(trackdate));
      }
    }
    return displayDate;
  };*/

/*export const getTrackingDate = (daySelected, weekSelected, monthSelected, previousSelected, nextSelected, date) => {
    if (monthSelected && !previousSelected && !nextSelected) {
      var todayDate = new Date();
      var trackDate = moment(todayDate);
      let currentMonth = trackDate.format('MM/DD/YYYY');
      return currentMonth;
    } else if (monthSelected && previousSelected) {
      var trackDate = moment(date);
      let previousMonth = trackDate.subtract(1, 'month').format('MM/DD/YYYY');
      return previousMonth;
    } else if (monthSelected && nextSelected) {
      var trackDate = moment(date);
      let nextMonth = trackDate.add(1, 'month').format('MM/DD/YYYY');
      return nextMonth;
    } else if (daySelected && !previousSelected && !nextSelected) {
      var todayDate = new Date();
      var trackDate = moment(todayDate);
      let currentDay = trackDate.format('MM/DD/YYYY');
      return currentDay;
    } else if (daySelected && previousSelected) {
      var trackDate = moment(date);
      let previousDay = trackDate.subtract(1, 'day').format('MM/DD/YYYY');
      return previousDay;
    } else if (daySelected && nextSelected) {
      var trackDate = moment(date);
      let nextDay = trackDate.add(1, 'day').format('MM/DD/YYYY');
      return nextDay;
    } else if (weekSelected && !previousSelected && !nextSelected) {
      let startOfWeek = moment().startOf('week');
      let currentWeek = startOfWeek.format('MM/DD/YYYY');
      return currentWeek;
    } else if (weekSelected && previousSelected) {
      var trackDate = moment(date);
      let previousWeekStart = trackDate.subtract(1, 'week').format('MM/DD/YYYY');
      return previousWeekStart;
    } else {
      var trackDate = moment(date);
      let nextWeekStart = trackDate.add(1, 'week').format('MM/DD/YYYY');
      return nextWeekStart;
    }
  };*/
