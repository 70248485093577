import React from 'react';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { login } from '../../../providers/logInNOutProvider';
import { refreshSession, endSession } from '../../../providers/sessionProvider';

import { getQueryValue, clearQueryValue, QUERY_CONST } from '../../../providers/queryActionsProvider';
import { actionHookProfileCompletion } from '../providers/GarageProvider';
import './displayTimeoutWarning.scss';

const initialState = {
  showTimeoutWarning: false,
  timeLeft: null,
  sessionExpired: false,
  forceHide: false
};

class DisplayTimeoutWarning extends React.Component {
  constructor () {
    super();
    this.endTime = null;
    this.interval = null;
    this.blather = getLocaleTranslator();
  }

  state = initialState;

  // create the end time (3 minutes from now) and start up the timer
  componentDidMount () {
    // 3 minutes from now
    let timeoutDuration = 1000 * 60 * 3;
    this.endTime = Date.now() + timeoutDuration;
    this.setState({ timeLeft: '03:00' }, this.runTimer);
  }

  componentWillUnmount () {
    this.nullifyTimerInterval();
    this.setState(initialState);
  }

  nullifyTimerInterval = () => {
    clearInterval(this.interval);
    this.interval = null;
  };

  stopWarning = () => {
    this.nullifyTimerInterval();
  };

  calculateTimeLeft = async () => {
    let currentTime = Date.now();
    let diff = this.endTime - currentTime;
    let seconds = Math.floor(diff / 1000);
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = seconds - minutes * 60;

    // keep it at 0
    seconds = seconds <= 0 ? 0 : seconds;
    minutes = minutes <= 0 ? 0 : minutes;

    // add the leading 0 if single digit
    let secondString = seconds < 10 ? '0' + seconds : seconds;
    let remainingSecondsString = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;

    let final = '';
    if (minutes) {
      final = `0${minutes}:${remainingSecondsString}`;
    } else {
      final = `00:${secondString}`;
    }

    if (seconds <= 8 && minutes <= 0) {
      let profileCompletionInProgressState = getQueryValue(QUERY_CONST.PROFILE_COMPLETION_CHECK);
      // looks like a profile completion flow is in progress but the session is timing out, abort it
      if (profileCompletionInProgressState) {
        actionHookProfileCompletion('abort', profileCompletionInProgressState);
        clearQueryValue(QUERY_CONST.PROFILE_COMPLETION_CHECK);
      }
    }

    // no time left, stop the timer, make it red
    if (seconds <= 4 && minutes <= 0) {
      let timeToLogin = 1000 * 60 * 30; // thirty seconds to reroute
      this.nullifyTimerInterval();

      try {
        await endSession();
      } catch (e) {
        endSession();
      } finally {
        this.setState({ sessionExpired: true }, () => {
          setTimeout(() => {
            login();
          }, 30000); // reroute in 30 seconds to idk
        });
      }
      return;
    }

    this.setState({ timeLeft: final });
  };

  runTimer = () => {
    this.nullifyTimerInterval();
    // run the interval every quarter second to keep the countdown smooth
    this.interval = setInterval(this.calculateTimeLeft, 500);
  };

  refreshSession = e => {
    e.preventDefault();
    e.stopPropagation();
    if (this.state.timeLeft) {
      refreshSession();
    }
  };

  excuseWarning = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ forceHide: true }, () => {
      this.nullifyTimerInterval();
    });
  };

  render () {
    return (
      <div>
        {!this.state.forceHide && (
          <div className={this.state.sessionExpired ? 'sessionWarning expired' : 'sessionWarning'}>
            <span onClick={this.excuseWarning} className='cwpExit exitRight'></span>
            {!this.state.sessionExpired && (
              <span>
                {this.blather.t('sessionWarning.warning_line_1', { expirationTime: this.state.timeLeft })}
                <br />
                {this.blather.t('sessionWarning.warning_line_2')}
                <i>{this.blather.t('sessionWarning.warning_link')}</i> {this.blather.t('sessionWarning.link')}
                <br />
              </span>
            )}
            {!this.state.sessionExpired && (
              <span className='extendLink' onClick={this.refreshSession}>
                {this.blather.t('sessionWarning.warning_link')}
              </span>
            )}
            {this.state.sessionExpired && <span>{this.blather.t('sessionWarning.expired')}</span>}
          </div>
        )}
      </div>
    );
  }
}

export { DisplayTimeoutWarning };
