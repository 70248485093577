import { createLogic } from 'redux-logic';
import axios from 'axios';

import {
  SEND_VHR_REPORT_BY_SERVICEKEY,
  SEND_VHR_REPORT_BY_SERVICEKEY_SUCCESS,
  SEND_VHR_REPORT_BY_SERVICEKEY_FAILURE
} from '../../actions/types';

let dataProvider = axios;

const sendVHReportByServiceKeyLogic = createLogic({
  type: SEND_VHR_REPORT_BY_SERVICEKEY,
  latest: true,
  processOptions: {
    dispatchReturn: true,
    successType: SEND_VHR_REPORT_BY_SERVICEKEY_SUCCESS,
    failType: SEND_VHR_REPORT_BY_SERVICEKEY_FAILURE
  },

  process ({ action }) {
    console.log('sendVHReportByServiceKeyLogic is processing an action >> ');
    console.log('type: ' + action.type);
    console.log('payload data from sendVHReportByServiceKeyLogic: ' + JSON.stringify(action.payload));

    let requestConfig = {
      method: 'POST',
      url: `/vhreportbyservicekey`,
      headers: {
        accept: 'application/json'
      },
      params: {
        serviceKey: action.payload === null ? '' : action.payload.serviceKey
      }
    };

    return dataProvider(requestConfig).then(response => {
      console.log('got response for sendVHReportByServiceKeyLogic GET request >>> ');
      console.log(JSON.stringify(response.data, null, 1));
      return response.data;
    });
  }
});

export default [sendVHReportByServiceKeyLogic];
