import {
  SEND_VHR_REPORT_BY_SERVICEKEY,
  SEND_VHR_REPORT_BY_SERVICEKEY_SUCCESS,
  SEND_VHR_REPORT_BY_SERVICEKEY_FAILURE
} from '../../actions/types';

const initalState = {
  vhReportData: null
};

const sendVHReportByServiceKeyReducer = (state = initalState, action = { payload: {} }) => {
  let newState = { ...state };
  let actionType = (action && action.type) || 'default';
  let responseData = action.payload || {};

  switch (actionType) {
    case SEND_VHR_REPORT_BY_SERVICEKEY:
      newState = { ...initalState };
      return newState;

    case SEND_VHR_REPORT_BY_SERVICEKEY_SUCCESS:
      let { data } = responseData;
      newState['vhReportData'] = data || null;
      return newState;
    case SEND_VHR_REPORT_BY_SERVICEKEY_FAILURE:
      newState['vhReportData'] = null;
      return newState;
    default:
      return newState;
  }
};

export default sendVHReportByServiceKeyReducer;
