import { createLogic } from 'redux-logic';
import axios from 'axios';
import {
  PUT_USER_SECURITY_QUESTIONS,
  PUT_USER_SECURITY_QUESTIONS_SUCCESS,
  PUT_USER_SECURITY_QUESTIONS_FAILURE
} from '../../actions/types';

let dataProvider = axios;

const saveUserSecurityQuestionsLogic = createLogic({
  type: PUT_USER_SECURITY_QUESTIONS,
  latest: true,
  processOptions: {
    dispatchReturn: true,
    successType: PUT_USER_SECURITY_QUESTIONS_SUCCESS,
    failType: PUT_USER_SECURITY_QUESTIONS_FAILURE
  },

  process ({ action }) {
    let requestConfig = {
      method: 'POST',
      url: `saveuserquestions`,
      headers: {
        accept: 'application/json'
      },
      data: action.payload
    };

    return dataProvider(requestConfig)
      .then(response => {
        console.log(
          'got response for saveUserSecurityQuestions POST request >>> ',
          JSON.stringify(response.data, null, 1)
        );
        return response.data;
      })
      .catch(error => {
        console.log('The REDUX Logic failed: ' + JSON.stringify(error, null, 1));
        console.log('Error while processing saveUserSecurityQuestions');
        console.log(JSON.stringify(error, null, 1));
      });
  }
});

export default [saveUserSecurityQuestionsLogic];
