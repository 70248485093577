import React, { Component } from 'react';
import VehicleHealthHome from './VehicleHealthHome';
import './vehicleHealth.scss';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { bindActionCreators } from 'redux';
import * as actions from '../../../redux/actions';
import { connect } from 'react-redux';
import { isUserPrimary } from '../../../providers/userRolesProvider';
import VehicleHealthHeader from './VHRTitle';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { isNonEmptyObject } from '../../../util/utilityMethods';
import { privilegeCheck } from '../trips/TripStatsUtil';

class VehicleHealthRolesCheck extends Component {
  constructor () {
    super();
    this.state = {
      privilegesModal: true,
      subscriptionModal: true
    };
    this.translator = getLocaleTranslator();
    this.vhCapabilityStatus = '';
    this.vhSubscriptionStatus = '';
    this.vhPrivilege = '';
  }

  componentDidMount () {
    this.props.actions.getCarnetUserPrivileges();
  }

  togglePrivilegesModal = () => {
    this.setState({ privilegesModal: !this.state.privilegesModal });
  };

  toggleSubscriptionModal = () => {
    this.setState({ subscriptionModal: !this.state.subscriptionModal });
  };

  getRolesCheck = () => {
    let vhService;
    let userPrivilegesData = this.props.userPrivilegesData;
    let privileges;

    if (isNonEmptyObject(userPrivilegesData)) {
      vhService = userPrivilegesData.services.filter(service => service.shortCode === 'VH')[0] || {};
    }

    if (isNonEmptyObject(vhService)) {
      vhService.operations.filter(operation => {
        return (this.vhCapabilityStatus = operation.capabilityStatus);
      });

      vhService.operations.filter(operation => {
        return (this.vhSubscriptionStatus = operation.subscriptionStatus);
      });

      vhService.operations.filter(operation => {
        return (privileges = operation.privilege);
      });
    }

    // Verizon users get to user everything, just stop there
    const tsp = String(this.props.tspProvider).toLowerCase();
    if (tsp === 'vzt') {
      return (this.vhPrivilege = true);
    }

    console.log('capabilityStatus>>>>' + this.vhCapabilityStatus);
    console.log('subscriptionStatus>>>>' + this.vhSubscriptionStatus);
    console.log('privileges>>>>' + JSON.stringify(privileges));

    let checkedPrivileges = privilegeCheck(privileges);
    this.vhPrivilege = !!checkedPrivileges;
  };

  componentDidUpdate (prevProps) {
    let prev = JSON.stringify(prevProps);
    let now = JSON.stringify(this.props);
    if (prev !== now) {
      this.getRolesCheck();
      // have to force an update as a change in an instance variable won't force a render
      this.forceUpdate();
    }
  }

  render () {
    return (
      <div>
        {this.vhPrivilege === false && (
          <div>
            <VehicleHealthHeader />
            <Modal isOpen={this.state.privilegesModal} centered={true} className='vhr'>
                     
              <ModalHeader>
                <span className='modalheader-txt'>{this.translator.t('vehicleHealth.error_label')}</span>
              </ModalHeader>
                     
              <ModalBody>
                <p className='modalbody-txt'>{this.translator.t('vehicleHealth.msg_privilege_not_available')}</p>  
              </ModalBody>{' '}
               
              <ModalFooter>
                <Button className='gotit-btn' onClick={this.togglePrivilegesModal}>
                  {this.translator.t('vehicleHealth.got_it_label')}
                </Button>
                       
              </ModalFooter>
                 
            </Modal>
          </div>
        )}

        {this.vhSubscriptionStatus && this.vhSubscriptionStatus.toUpperCase() === 'NOT_AVAILABLE' && (
          <div>
            <VehicleHealthHeader />
            <Modal isOpen={this.state.subscriptionModal} centered={true} className='vhr'>
                         
              <ModalHeader>
                <span className='modalheader-txt'>{this.translator.t('vehicleHealth.error_label')}</span>
              </ModalHeader>
                         
              <ModalBody>
                             
                <p className='modalbody-txt'>{this.translator.t('vehicleHealth.msg_subscription_not_available')}</p>   
                       
              </ModalBody>
                       
              <ModalFooter>
                <a href='/#/buy-plans'>
                  <Button className='gotit-btn' onClick={this.toggleSubscriptionModal}>
                    {this.translator.t('vehicleHealth.continue_label')}
                  </Button>
                </a>
                           
              </ModalFooter>
                       
            </Modal>
          </div>
        )}

        {this.vhCapabilityStatus && this.vhCapabilityStatus.toUpperCase() === 'NOT_AVAILABLE' && (
          <div>
            <VehicleHealthHeader />
            <div className='capability-status'>{this.translator.t('vehicleHealth.msg_capability_check')}</div>
          </div>
        )}

        {this.CheckRolesAndRights()}
      </div>
    );
  }

  CheckRolesAndRights = () => {
    if (this.props.subscriptionNotAvailable) {
      return;
    }

    return (
      (this.vhCapabilityStatus.toUpperCase() === 'AVAILABLE' ||
        this.vhCapabilityStatus.toUpperCase() === 'NOT_APPLICABLE') &&
      (this.vhSubscriptionStatus.toUpperCase() === 'AVAILABLE' ||
        this.vhSubscriptionStatus.toUpperCase() === 'NOT_APPLICABLE') &&
      this.vhPrivilege && <VehicleHealthHome locale={this.props.locale} />
    );
  };
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getCarnetUserPrivileges: actions.getCarnetUserPrivileges
      },
      dispatch
    )
  };
};

const mapStateToProps = state => {
  const { userPrivilegesDetails } = state;

  return {
    userPrivilegesData: userPrivilegesDetails.userPrivilegesData
  };
};

export { VehicleHealthRolesCheck };
export default connect(mapStateToProps, mapDispatchToProps)(VehicleHealthRolesCheck);
