import React from 'react';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { Container, Row, Col, Input, Button } from 'reactstrap';
import '../../reusable_cmp_lib/ThemedFormInputs/ThemedForm.scss';
import ThemedRadioPlus from '../../reusable_cmp_lib/ThemedRadio/ThemedRadioPlus';
import { isValidNAPhoneNumber } from '../../../util/utilityMethods';
import '../../reusable_cmp_lib/ThemedButtonsAssortment/ThemedButtonsAssortment.scss';
import './AccountManagement.scss';
import { US_PHONE_NUMBER_PATTERN, formatPhoneNumber, digitsOnly } from '../CarnetHome';
import { setTitle } from '../../../providers/documentTitleProvider';
import addDriver from '../../../assets/accountManagement/add_driver.png';
class PhoneNumberEditor extends React.Component {
  constructor (props) {
    super(props);

    this.phoneTypes = ['acctmgmt_label_mobile', 'acctmgmt_label_home'];
    this.phoneTypeCodes = ['CELL_PHONE_NUM', 'HOME_PHONE_NUM'];

    let initialPhonesArr = this.props.accountData.phones || [];
    this.validationsMap = { 'addon-phone': 0 };
    initialPhonesArr.forEach((record, index) => {
      const mapKey = '' + index;
      this.validationsMap[mapKey] = 0; //all initialized to zero
    });

    this.state = {
      selectedClade: 'mobile',
      inProgressNumber: null,
      phoneNumbers: initialPhonesArr,
      allowAddNumber: false,
      validationStati: this.validationsMap,
      addonPhoneType: 'CELL_PHONE_NUM'
    };
  }

  validatePhoneNumber = entry => {
    return isValidNAPhoneNumber(entry);
  };

  addonPhoneTypeSelected = event => {
    let slPhoneType = event.target.offsetParent.id;
    this.setState({
      addonPhoneType: slPhoneType
    });
  };

  removeAuxPhone = index => {
    let extantPhones = [...this.state.phoneNumbers];
    if (index < 0) {
      this.setState({
        allowAddNumber: false
      });
    } else {
      let splicedArr = extantPhones.splice(index, 1);
      this.setState({
        phoneNumbers: extantPhones
      });
    }
  };

  cladeSelected = item => {
    let type = item.currentTarget.childNodes[0].nodeValue;
    console.log('Phonenum clade selected:' + type);
    this.setState({
      selectedClade: type
    });
  };

  setValidationStatusForIndex = (index, status) => {
    let mutableStatiMap = JSON.parse(JSON.stringify(this.state.validationStati));
    let accessKey = index === 'addon-phone' ? index : '' + index;
    mutableStatiMap[accessKey] = status;
    this.setState({
      validationStati: mutableStatiMap
    });
  };

  handleExistingNumberEdit = (evt, index) => {
    let workingText = evt.target.value;
    if (workingText === '') {
      return;
    }
    let phoneDigits = digitsOnly(workingText);
    // do comparison if is the exact same, means an attempt to delete a formatting character happened
    if (phoneDigits && phoneDigits === this.state.phoneNumbers[index].number) {
      phoneDigits = phoneDigits.slice(0, -1);
    }

    let validEntry = this.validatePhoneNumber(phoneDigits);
    let veStatus = validEntry === true ? 1 : -1;
    this.setValidationStatusForIndex(index, veStatus);
    let presentPhonesList = this.state.phoneNumbers;
    let mutablePhonesList = JSON.parse(JSON.stringify(presentPhonesList));
    mutablePhonesList[index].number = phoneDigits;
    this.setState({
      phoneNumbers: mutablePhonesList
    });
  };

  handleNewPhoneEntry = evt => {
    let workingText = evt.target.value;
    if (workingText === '') {
      return;
    }
    let phoneDigits = digitsOnly(workingText);
    // do comparison if is the exact same, means an attempt to delete a formatting character happened
    if (phoneDigits && phoneDigits === this.state.inProgressNumber) {
      phoneDigits = phoneDigits.slice(0, -1);
    }

    let validEntry = this.validatePhoneNumber(phoneDigits);
    let veStatus = validEntry === true ? 1 : -1;
    this.setValidationStatusForIndex('addon-phone', veStatus);
    this.setState({
      inProgressNumber: phoneDigits
    });
  };

  phoneEntriesValid = () => {
    console.log('phoneEntriesValid() ');
    let keys = Object.keys(this.state.validationStati);
    let isValid = true;
    keys.forEach(key => {
      console.log('key is', key);
      console.log(this.state.validationStati[key]);
      if (this.state.validationStati.hasOwnProperty(key) && this.state.validationStati[key] === -1) {
        isValid = false;
      }
    });
    return isValid;
  };

  triggerAddANumber = () => {
    if (this.state.phoneNumbers && this.state.phoneNumbers.length >= 3) {
      return;
    }

    this.setState(
      {
        allowAddNumber: true
      },
      () => {
        document.getElementById('pi_another_phone_input').focus();
      }
    );
  };

  clearForm = () => {
    let freshState = {
      selectedClade: 'mobile',
      inProgressNumber: null,
      allowAddNumber: false,
      validationStati: this.validationsMap,
      addonPhoneType: 'CELL_PHONE_NUM'
    };
    this.setState(freshState);
  };

  saveSubmitFormEntry = () => {
    let presentPhonesList = this.state.phoneNumbers;
    let mutablePhonesList = JSON.parse(JSON.stringify(presentPhonesList));

    if (this.state.inProgressNumber) {
      let isValidNAPhone = this.validatePhoneNumber(this.state.inProgressNumber);

      if (isValidNAPhone) {
        const clonedInProgressNumber = this.state.inProgressNumber + '';
        const addonPhoneType = this.state.addonPhoneType || 'CELL_PHONE_NUM';

        let addonRecord = {
          number: clonedInProgressNumber,
          type: addonPhoneType,
          primary: false
        };
        mutablePhonesList.push(addonRecord);
      } else {
        //we still must do this:
        let veStatus = isValidNAPhone === true ? 1 : -1;
        this.setValidationStatusForIndex('addon-phone', veStatus);
      }
    } //end case where a number is being added

    this.props.refreshCallback(mutablePhonesList, 'phones');
    let mutableCustomerProfile = JSON.parse(JSON.stringify(this.props.accountData));
    mutableCustomerProfile.phones = mutablePhonesList;
    this.props.saveHandler(mutableCustomerProfile);
    this.setState(
      {
        phoneNumbers: mutablePhonesList
      },
      this.clearForm()
    );
  };

  render () {
    setTitle('document_titles.account_management.edit_phone_number');
    this.translator = getLocaleTranslator();
    let phonesList = this.state.phoneNumbers;
    let radioButtonItems = this.phoneTypeCodes.map((code, index) => {
      const labelKey = this.phoneTypes[index];
      const radioLabel = this.translator.t(labelKey);
      return { label: radioLabel, value: code };
    });

    return (
      <Container fluid={true}>
        <Row>
          <Col md='3'></Col>
          <Col md='7'>
            <div
              class='editor-firstrow-label-aligner'
              onClick={this.triggerAddANumber}
              onKeyPress={e => {
                if (e.key && (e.key === 'Enter' || e.key === ' ')) {
                  this.triggerAddANumber();
                }
              }}
            >
              <fieldset>
                <legend>
                  <span class='first-editor-field-label'>{this.translator.t('acctmgmt_pi_field_label_phone')}</span>
                </legend>

                {/* Add a phone link: display it only if User has < 3 phone numbers */}
                {phonesList && phonesList.length < 3 && (
                  <div class='add-phone-link-item right' tabIndex='0' role='button'>
                    <img src={addDriver} className='addDriver' alt='' />{' '}
                    <span class='add-phone-link'>{this.translator.t('acctmgmt_pi_field_label_addphone')}</span>
                  </div>
                )}
              </fieldset>
            </div>
          </Col>
        </Row>
        {/* BEGIN LOOP HERE  */}
        {(this.state.phoneNumbers || []).map((phone, index) => {
          let rowRef = 'phone-' + index;
          const statusMapKey = '' + index;
          let phoneTypeLabel =
            phone.type && phone.type === 'CELL_PHONE_NUM'
              ? this.translator.t('acctmgmt_pi_field_label_mphone')
              : this.translator.t('acctmgmt_pi_field_label_hphone');
          if (phone.type === 'WORK_PHONE_NUM') {
            phoneTypeLabel = this.translator.t('acctmgmt_pi_field_label_wphone');
          }

          return (
            <fieldset>
              <Row ref={rowRef} key={index}>
                <Col md='3'></Col>
                <Col md='2'>
                  <div class='editor-diatom-field'>
                    <span class='design-deviation-field-label'>
                      <legend>
                        <label htmlFor='pi_prime_phone_input'>{phoneTypeLabel}</label>
                      </legend>
                    </span>
                  </div>
                </Col>
                <Col md='3'>
                  <div className='editor-diatom-field phone-field'>
                    <Input
                      className='cwp'
                      type='tel'
                      onChange={evt => {
                        this.handleExistingNumberEdit(evt, index);
                      }}
                      pattern={US_PHONE_NUMBER_PATTERN}
                      name='pi_prime_phone'
                      id='pi_prime_phone_input'
                      placeholder={(phone.number && formatPhoneNumber(phone.number)) || ''}
                      value={(phone.number && formatPhoneNumber(phone.number)) || ''}
                      required
                    />
                    {this.state.validationStati && this.state.validationStati[statusMapKey] === -1 && (
                      <div id='invalid-phonenumber-indicator'>
                        {this.translator.t('acctmgmt_invalid_phone_indicator')}
                      </div>
                    )}
                  </div>
                </Col>

                <Col md='2'>
                  {phone.primary === false && (
                    <div
                      id='remove-phone-link-aligner'
                      className='removePhoneLink'
                      tabIndex='0'
                      role='button'
                      onKeyPress={e => {
                        if (e.key && (e.key === 'Enter' || e.key === ' ')) {
                          this.removeAuxPhone(index);
                        }
                      }}
                      onClick={() => {
                        this.removeAuxPhone(index);
                      }}
                    >
                      <span id='remove-phone-link'>{this.translator.t('remove_label')}</span>
                    </div>
                  )}
                </Col>
              </Row>
            </fieldset>
          );
        })}

        <Row>
          <Col md='3'></Col>
          <Col md='7'>
            <div class='editor-firstrow-label-aligner'></div>
          </Col>
        </Row>

        {this.state.allowAddNumber && this.state.allowAddNumber !== false && (
          <fieldset>
            <Row ref='additional_phone'>
              <Col md='3'></Col>
              <Col md='2'>
                <br />
                <br />
                <div class='editor-diatom-field phone-field'>
                  <span class='design-deviation-field-label'>
                    <legend>
                      {this.translator.t('acctmgmt_pi_field_label_phone')}{' '}
                      {this.translator.t('acctmgmt_optional_suffix')}
                    </legend>
                  </span>
                </div>
              </Col>
              <Col md='2'>
                <br />

                <br />
                <div class='editor-diatom-field phone-field'>
                  <Input
                    className='cwp'
                    type='tel'
                    name='pi_additional_phone'
                    id='pi_another_phone_input'
                    maxLength={14}
                    pattern={US_PHONE_NUMBER_PATTERN}
                    value={(this.state.inProgressNumber && formatPhoneNumber(this.state.inProgressNumber)) || ''}
                    onChange={evt => this.handleNewPhoneEntry(evt)}
                  />
                  {this.state.validationStati && this.state.validationStati['addon-phone'] === -1 && (
                    <div id='invalid-phonenumber-indicator'>
                      {this.translator.t('acctmgmt_invalid_phone_indicator')}
                    </div>
                  )}

                  <br />
                  <ThemedRadioPlus
                    headerLegend={this.translator.t('acctmgmt_phone_type')}
                    items={radioButtonItems}
                    defaultSelection={this.state.addonPhoneType}
                    selectionHandler={this.addonPhoneTypeSelected}
                  />
                </div>
              </Col>
            </Row>
          </fieldset>
        )}

        <Row>
          <Col md='4'></Col>
          <Col md='2'></Col>
          <Col md='2'></Col>
        </Row>

        <Row>
          <Col md='8'>&nbsp;</Col>
          <Col md='2'>
            <Button
              className='cwp'
              color='primary'
              disabled={!this.state.addonPhoneType || !this.phoneEntriesValid()}
              onClick={() => {
                this.saveSubmitFormEntry();
              }}
            >
              {this.translator.t('save_changes_label')}
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default PhoneNumberEditor;
