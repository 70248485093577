import React from 'react';
import './vehicleHealth.scss';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';

const VHRTitle = () => {
  const translator = getLocaleTranslator();

  return (
    <div className='vhr-title'>
      <span className='vhr-header-title1'>{translator.t('vehicleHealth.header.title_label1')}</span>
      <span className='vhr-header-title2'>{translator.t('vehicleHealth.header.title_label2')}</span>
    </div>
  );
};

export default VHRTitle;
