import React from 'react';
import { BuyPlansContext } from '../BuyPlansContext';
import { findArticleDisclaimerText } from '../buyPlansUtil';
import { Link } from 'react-router-dom';
import MarketingProducts from '../components/MarketingProducts';
import { UncontrolledAlert } from 'reactstrap';
import PackagePricing from '../components/PackagePricing';

export default class DefaultPreview extends React.Component {
  static contextType = BuyPlansContext;

  state = {
    articleDisclaimer: null
  };

  componentDidMount () {
    this.initData();
  }

  initData = async () => {
    let plan = this.context?.selectedPlan;

    if (!plan) {
      const { category, subCategory, marketingConfigurationId } = this.props.match?.params;
      const { additionalPlans, selectPlanFromList } = this.context;

      plan = selectPlanFromList(additionalPlans, category, subCategory, marketingConfigurationId);
    }

    const marketingConfiguration = this.context.marketingConfigurationHash?.[plan?.marketingConfigurationId];
    const articleDisclaimer = findArticleDisclaimerText(marketingConfiguration);

    this.setState({ articleDisclaimer });
    window.scrollTo(0, 0);
  };

  clearAlerts = () => this.setState({ alert: null });

  getAlerts = () => {
    if (this.state.alert) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts} aria-live='polite' className='w-100'>
          {this.state.alert}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  render () {
    const {
      translator,
      contentURL,
      showDisclaimer,
      selectPlan,
      selectedPlan: plan,
      marketingConfigurationHash
    } = this.context;
    const marketingConfiguration = marketingConfigurationHash?.[plan?.marketingConfigurationId] || {};

    return (
      <div role='main' id='main-content' className='py-4'>
        <div className='ml-4 position-absolute'>
          <Link to='/' className='linkBack' role='button' onClick={() => selectPlan(null)}>
            {translator.t('buyplans.button-label.back-to-your-plans')}
          </Link>
        </div>
        <div className='container d-flex flex-column align-items-center text-center mt-4 pb-5'>
          <h1 className='font-weight-light'>
            {marketingConfiguration?.currentPlanContent?.title || marketingConfiguration?.title}
            {this.state.articleDisclaimer && (
              <sup
                className='cwp-disclaimer ml-2 mainTitlePosition'
                tabIndex={0}
                role='button'
                onClick={e =>
                  showDisclaimer(
                    this.state.articleDisclaimer,
                    marketingConfiguration.articleDisclaimerNumber,
                    e.currentTarget
                  )
                }
              >
                {marketingConfiguration?.articleDisclaimerNumber}
              </sup>
            )}
          </h1>

          <article className='text-muted text-center mt-2 mb-5'>
            {marketingConfiguration?.description} {marketingConfiguration?.additionalDescription}
          </article>
          {this.getAlerts()}

          {plan?.purchaseable === true && (
            <PackagePricing showAlert={errorMessage => this.setState({ alert: errorMessage })} />
          )}
        </div>

        {plan && (
          <MarketingProducts
            contentURL={contentURL}
            plan={plan}
            marketingConfiguration={marketingConfiguration}
            showDisclaimer={this.context.showDisclaimer}
          />
        )}
      </div>
    );
  }
}
