import React, { useState, useEffect } from 'react';

import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { Container, Row, Col, Input, Button } from 'reactstrap';

import {
  getWifiConfiguration,
  createWifiConfiguration,
  updateWifiConfiguration,
  resetWifiConfiguration
} from '../providers/WifiConfigProvider';

import '../../reusable_cmp_lib/ThemedButtonsAssortment/ThemedButtonsAssortment.scss';
import '../../reusable_cmp_lib/ThemedFormInputs/ThemedForm.scss';
import './AccountManagement.scss';
import '../CarnetHome.scss';

const WifiSettingsEditor = () => {
  let translator = getLocaleTranslator();

  const [newSSID, setNewSSID] = useState('');
  const [newPassphrase, setNewPhasphrase] = useState('');

  const [currentHotspotEnabled, setCurrentHotSpotEnabled] = useState(true);
  const [currentSSID, setCurrentSSID] = useState('');
  const [currentPassphrase, setCurrentPassphrase] = useState('');

  const [wifiInErrorState, setErrorState] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const onNewSSIDChange = e => {
    let _newSSID = e.target.value;
    setNewSSID(_newSSID);
  };

  const onNewPassPhraseChange = e => {
    let _newPassphrase = e.target.value;
    setNewPhasphrase(_newPassphrase);
  };

  const saveUpdates = async e => {
    e.preventDefault();
    setIsUpdating(true);
    let action = !!currentSSID || !!currentPassphrase ? updateWifiConfiguration : createWifiConfiguration;
    let payload = {
      hotSpot: {
        isEnabled: currentHotspotEnabled
      },
      configuration: {
        ssId: newSSID || currentSSID,
        password: newPassphrase || currentPassphrase
      }
    };
    try {
      let updateResponse = await action(payload);
      let wifiInfo = updateResponse.data || updateResponse;
      let isEnabled = wifiInfo && wifiInfo.hotSpot && wifiInfo.hotSpot.isEnabled;
      setCurrentHotSpotEnabled(isEnabled);
      let theWifiConfigInfo = (wifiInfo && wifiInfo.configuration) || {};
      let existingSSID = theWifiConfigInfo.ssId || '';
      let existingPassphrase = theWifiConfigInfo.password || '';
      setCurrentSSID(existingSSID);
      setCurrentPassphrase(existingPassphrase);
      setIsUpdating(false);
    } catch (err) {
      console.log('unable to update wifi configuration');
      console.log(err);
    } finally {
      setIsUpdating(false);
    }
  };

  const resetConfiguration = async e => {
    e.preventDefault();
    setIsUpdating(true);
    try {
      let resetResponse = await resetWifiConfiguration();
      let wifiInfo = resetResponse.data || resetResponse;
      let isEnabled = wifiInfo && wifiInfo.hotSpot && wifiInfo.hotSpot.isEnabled;
      setCurrentHotSpotEnabled(isEnabled);
      let theWifiConfigInfo = (wifiInfo && wifiInfo.configuration) || {};
      let existingSSID = theWifiConfigInfo.ssId || '';
      let existingPassphrase = theWifiConfigInfo.password || '';
      setCurrentSSID(existingSSID);
      setCurrentPassphrase(existingPassphrase);
      setIsUpdating(false);
    } catch (err) {
      console.log('unable to reset wifi configuration');
      console.log(err);
    } finally {
      setIsUpdating(false);
    }
  };

  const isSaveEligible = () => {
    // ssid 8-32 characters, may contain spaces
    // passphrase 8-63 characters, no spaces
    // newSSID
    // newPassphrase
    let ssidIsValid = newSSID.length >= 8 && newSSID.length <= 32;
    ssidIsValid = ssidIsValid && newSSID.trim() !== '';
    let passphraseIsValid = newPassphrase.length >= 8 && newPassphrase.length <= 63;
    passphraseIsValid = passphraseIsValid && newPassphrase.trim() !== '';

    let oldPassPhraseIsValid = !!currentPassphrase;
    let oldSSIDisValid = !!currentSSID;

    return (ssidIsValid && (passphraseIsValid || oldPassPhraseIsValid)) || (oldSSIDisValid && passphraseIsValid);
  };

  useEffect(() => {
    let mounted = true;
    const getWifiConfigInfo = async () => {
      try {
        let wifiInfo = await getWifiConfiguration();
        wifiInfo = wifiInfo.data || wifiInfo;
        if (mounted) {
          let theWifiConfigInfo = (wifiInfo && wifiInfo.configuration) || {};
          let isEnabled = wifiInfo && wifiInfo.hotSpot && wifiInfo.hotSpot.isEnabled;
          setCurrentHotSpotEnabled(isEnabled);
          let existingSSID = theWifiConfigInfo.ssId || '';
          let existingPassphrase = theWifiConfigInfo.password || '';
          setCurrentSSID(existingSSID);
          setCurrentPassphrase(existingPassphrase);
          setIsUpdating(false);
          setErrorState(false);
        }
      } catch (err) {
        if (mounted) {
          setErrorState(true);
          setIsUpdating(false);
        }
      }
    };

    getWifiConfigInfo();
    return function cleanup () {
      mounted = false;
    };
  }, []);

  return (
    <Container fluid={true}>
      <Row>
        <Col md='4'></Col>
        <Col md='8'>
          <div className='editor-firstrow-label-aligner'>
            <span className='first-editor-field-label'>{translator.t('acctmgmt_wifi_edit_settings_title')}</span>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md='3'></Col>
        <Col md='3'>
          <span className='carnet-theme-field-label'>{translator.t('acctmgmt_wifi_ssid_label')}</span>
        </Col>
        <Col md='1'></Col>
        <Col md='3'>
          <div className='editor-diatom-field margin-top-10'>
            <span className='editor-diatom-superscript-lbl'>{translator.t('acctmgmt_wifi_current_ssid_label')}</span>
            <br />
            {!wifiInErrorState && <span className='carnet-theme-field-value grayish'>{currentSSID}</span>}
            {wifiInErrorState && (
              <span className='carnet-theme-field-value erroneous'>
                {translator.t('acctmgmt_wifi_get_settings_failure')}
              </span>
            )}
            <br />
            <br />
            <span className='editor-diatom-superscript-lbl'>{translator.t('acctmgmt_wifi_new_ssid_label')}</span>
            <Input
              disabled={isUpdating}
              className='cwp'
              type='text'
              onChange={onNewSSIDChange}
              placeholder={translator.t('acctmgmt_wifi_new_ssid_placeholder')}
              minLength={8}
              maxLength={32}
            />
          </div>
          <br />
          <div className='buttonToTheLeft'>
            <Button disabled={isUpdating} className='cwpSecondary' onClick={resetConfiguration}>
              {translator.t('acctmgmt_wifi_reset_btn_label')}
            </Button>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md='4'></Col>
        <Col md='8'>
          <div className='editor-firstrow-label-aligner'></div>
        </Col>
      </Row>

      <Row>
        <Col md='3'></Col>
        <Col md='3'>
          <span className='carnet-theme-field-label'>{translator.t('acctmgmt_wifi_passphrase_label')}</span>
        </Col>
        <Col md='1'></Col>
        <Col md='3'>
          <div className='editor-diatom-field margin-top-10'>
            <span className='editor-diatom-superscript-lbl'>
              {translator.t('acctmgmt_wifi_current_passphrase_label')}
            </span>
            <br />
            {!wifiInErrorState && <span className='carnet-theme-field-value grayish'>{currentPassphrase}</span>}
            {wifiInErrorState && (
              <span className='carnet-theme-field-value erroneous'>
                {translator.t('acctmgmt_wifi_get_settings_failure')}
              </span>
            )}
            <br />
            <br />
            <span className='editor-diatom-superscript-lbl'>{translator.t('acctmgmt_wifi_new_passphrase_label')}</span>
            <Input
              disabled={isUpdating}
              className='cwp'
              type='text'
              onChange={onNewPassPhraseChange}
              placeholder={translator.t('acctmgmt_wifi_new_passphrase_placeholder')}
              minLength={8}
              maxLength={63}
            />
            <br />
            <span className='acctgmt_wifi_config_explanation_text'>{translator.t('acctmgmt_wifi_new_ssid_text')}</span>
            <br />
            <span className='acctgmt_wifi_config_explanation_text'>
              {translator.t('acctmgmt_wifi_new_passphrase_text_01')}
            </span>
            <br />
            <span className='acctgmt_wifi_config_explanation_text'>
              {translator.t('acctmgmt_wifi_new_passphrase_text_02')}
            </span>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md='4'></Col>
        <Col md='8'>
          <div className='editor-firstrow-label-aligner'></div>
        </Col>
      </Row>

      <Row className='borderless normalized'>
        <Col md='11'>
          <div className='buttonToTheRight'>
            <Button disabled={isUpdating || !isSaveEligible()} className='cwp' color='primary' onClick={saveUpdates}>
              {translator.t('save_changes_label')}
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default WifiSettingsEditor;
