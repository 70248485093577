import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../VehicleInfo.scss';
import { TosStatusOption } from '../../providers/GarageProvider';
import resource from '../../../../config/resource.json';

export default class MarketingConsent extends Component {
  constructor (props) {
    super(props);

    this.state = {
      tosStatus: null
    };
  }

  static propTypes = {
    locale: PropTypes.string.isRequired,
    manifest: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    translator: PropTypes.object.isRequired,
    processing: PropTypes.bool,
    showAlert: PropTypes.func.isRequired
  };

  onSubmit = () => {
    const { tosStatus } = this.state;

    if ([TosStatusOption.ACCEPT, TosStatusOption.DECLINE].includes(tosStatus)) {
      this.props.onSubmit(tosStatus);
    }
  };

  render () {
    const { translator, processing, locale } = this.props;
    const tos_url = resource?.tos_url;

    return (
      <>
        <section className='d-flex flex-column text-left'>
          <h1 className='text-center font-weight-normal'>
            {translator.t('tos_mkt_Consent.we-have-updated-our')}
            <strong>{translator.t('tos_mkt_Consent.marketing_consent')}</strong>
          </h1>
          <h2 className='mt-5 font-weight-bold'>{translator.t('tos_mkt_Consent.marketing_consent')}</h2>
          <article
            className='my-3'
            dangerouslySetInnerHTML={{
              __html: translator.t('tos_mkt_Consent.marketing_consent_p', {
                contact: tos_url?.contact[locale],
                find_dealer: tos_url?.find_dealer[locale],
                privacy_policy: tos_url?.privacy_policy[locale]
              })
            }}
          ></article>
          <article className='d-flex flex-row'>
            <input
              type='radio'
              id='radioTosAccept'
              name='scrollRadio'
              disabled={this.state.isRadioDisabled || processing}
              checked={TosStatusOption.ACCEPT === this.state.tosStatus}
              onClick={() => this.setState({ tosStatus: TosStatusOption.ACCEPT })}
            />
            <label htmlFor='radioTosAccept' className='acceptlbl'>
              {translator.t('tos_mkt_Consent.accept')}
            </label>
          </article>
          <article className='d-flex flex-row'>
            <input
              type='radio'
              id='radioTosDecline'
              name='scrollRadio'
              disabled={this.state.isRadioDisabled || processing}
              checked={TosStatusOption.DECLINE === this.state.tosStatus}
              onClick={() => this.setState({ tosStatus: TosStatusOption.DECLINE })}
            />
            <label htmlFor='radioTosDecline' className='acceptlbl'>
              {translator.t('tos_mkt_Consent.decline')}
            </label>
          </article>
          <button class='cwp center mt-4' onClick={this.onSubmit} disabled={!this.state.tosStatus || processing}>
            {translator.t('button.continue')}
          </button>
        </section>
      </>
    );
  }
}
