import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import './ThemedRadio.scss';

const ThemedRadioPlus = props => {
  return (
    <FormGroup tag='fieldset'>
      <legend>{props.headerLegend || ''}</legend>
      {(props.items || []).map((item, index) => {
        return (
          <FormGroup check className='custom-control custom-radio' id={item.value || ''}>
            <Label check id={item.value || ''}>
              {!isNaN(props.mandateChecked) ? (
                <Input
                  type='radio'
                  onChange={props.selectionHandler}
                  className='custom-control-input'
                  name='selection'
                  checked={props.mandateChecked === index}
                />
              ) : (
                <Input
                  type='radio'
                  onChange={props.selectionHandler}
                  className='custom-control-input'
                  name='selection'
                />
              )}

              <span className='radioLabelText custom-control-label'>{item.label}</span>
            </Label>
          </FormGroup>
        );
      })}
    </FormGroup>
  );
};

export default ThemedRadioPlus;
