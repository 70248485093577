import Axios from 'axios';

let cwpMeta;

const meta_data_retrieval_failure = 'FAILED_TO_GET_CWP_METADATA';

let promiseToResolve = null;

const getCwpMeta = async () => {
  if (cwpMeta) {
    return Promise.resolve(cwpMeta);
  }

  let axioConfig = {
    url: 'cwpmeta/static',
    method: 'GET'
  };

  // return same promise if already in progress
  if (promiseToResolve) {
    return promiseToResolve;
  }

  // resolve promise and nullify after resolution if anything meta-related changes
  promiseToResolve = new Promise(async (resolve, reject) => {
    let metaDataResponse = await Axios(axioConfig);
    if (metaDataResponse) {
      cwpMeta = metaDataResponse && metaDataResponse.data;
    }
    if (cwpMeta) {
      resolve(cwpMeta);
      promiseToResolve = null;
      return;
    }
    reject(meta_data_retrieval_failure);
    promiseToResolve = null;
  });

  return promiseToResolve;
};

export { getCwpMeta };

export default getCwpMeta;
