import React, { Component } from 'react';
import { Modal, ModalBody, Container, Table, Row, Col, Button } from 'reactstrap';
import { FormatDate, FormatLocalTimeZone } from '../common/epochDateTimeConvertUtil';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { getUpdatedTripHistoryList } from './driveViewUtil';
import DriveViewScoreReading from './driveViewScoreReading';
import nightTimeDrivingWhite from '../../../assets/driveView/nighttime-driving-white.png';
import idleWhite from '../../../assets/driveView/idle-white.png';
import speedWhite from '../../../assets/driveView/speed-white.png';
import hardBreakWhite from '../../../assets/driveView/brakes-white.png';
import { findIn } from '../../../util/utilityMethods';
import hardBraking from '../../../assets/driveView/brakes_navy.png';
import excessOfSpeed from '../../../assets/driveView/speed-navy.png';
import carnet_constants from '../../../constants/carnet_constants';

class TripsHistory extends Component {
  constructor () {
    super();
    this.state = {
      tripDetails: [],
      viewTripModal: false,
      tripInfo: {}
    };
    this.translator = getLocaleTranslator();
  }

  componentDidMount () {
    this.setState({ tripDetails: getUpdatedTripHistoryList(this.props.tripDetails) });
  }

  // handler for updating trips info on component update
  componentDidUpdate (prevProps = {}) {
    let prev = prevProps.tripDetails && JSON.stringify(prevProps.tripDetails);
    let cur = this.props.tripDetails && JSON.stringify(this.props.tripDetails);
    if (prev !== cur) {
      this.setState({ tripDetails: getUpdatedTripHistoryList(this.props.tripDetails) });
    }
  }

  viewTrip = tripId => {
    const trip = this.state.tripDetails.filter(trip => trip.tripId === tripId)[0];
    this.setState({
      viewTripModal: !this.state.viewTripModal,
      tripInfo: trip
    });
  };

  toggleViewTripModal = () => {
    this.setState({ viewTripModal: !this.state.viewTripModal, tripInfo: {} });
  };

  // eslint-disable-next-line complexity
  render () {
    return (
      <div>
        <Row>
          <Col>
            {this.state.tripDetails && Array.isArray(this.state.tripDetails) && !!this.state.tripDetails.length && (
              <div className='rts-history-title'>{this.translator.t('driveview.trip-history.heading')}</div>
            )}
          </Col>
        </Row>

        <Table striped className='rts-history-table'>
          {this.state.tripDetails && Array.isArray(this.state.tripDetails) && !!this.state.tripDetails.length && (
            <thead>
              <tr>
                <th>{this.translator.t('driveview.trip-history.label.start')}</th>
                <th>{this.translator.t('driveview.trip-history.label.end')}</th>
                <th>{this.translator.t('driveview.trip-history.label.driver-score')}</th>
                {/*<th>{this.translator.t('driveview.trip-history.label.trips')}</th> */}
                <th></th>
              </tr>
            </thead>
          )}
          <tbody>
            {this.state.tripDetails &&
              Array.isArray(this.state.tripDetails) &&
              this.state.tripDetails.map((tripHistory, index) => (
                <tr key={index}>
                  <td>
                    {FormatDate(tripHistory.startTime, 'MMM DD')},&nbsp;
                    {FormatLocalTimeZone(tripHistory.startTime, 'hh:mm A')}
                  </td>
                  <td>
                    {FormatDate(tripHistory.endTime, 'MMM DD')},&nbsp;
                    {FormatLocalTimeZone(tripHistory.endTime, 'hh:mm A')}
                  </td>
                  <td>{tripHistory.tripWise.score}</td>

                  {/* Tips Code details not returing from SP
                  <td>
                    {Array.isArray(tripHistory.tripWise.tips) &&
                      tripHistory.tripWise.tips.map((tip, index) => (
                        <span>
                          {tip.tipCode === carnet_constants.TIPCODE_HARD_BREAKING ? (
                            <img
                              src={hardBraking}
                              alt={this.translator.t('driveview.aggregated-snapshot.label.hard-braking')}
                            />
                          ) : (
                            tip.tipName
                          )}
                          {tip.tipCode === carnet_constants.TIPCODE_ACCELERATION && (
                            <span>
                              &nbsp;
                              <img
                                src={excessOfSpeed}
                                alt={this.translator.t('driveview.aggregated-snapshot.label.high-speed-driving')}
                              />
                            </span>
                          )}
                          {index !== tripHistory.tripWise.tips.length - 1 ? <span>&nbsp;</span> : ''}
                        </span>
                      ))}
                    {!Array.isArray(tripHistory.tripWise.tips) ||
                      (!tripHistory.tripWise.tips.length && <span>&nbsp;</span>)}
                    </td> */}

                  <td>
                    <Button color='link' className='rts-tripid' onClick={() => this.viewTrip(index)}>
                      {this.translator.t('driveview.trip-history.label.view-trip')}
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>

        <Modal
          isOpen={this.state.viewTripModal}
          toggle={this.toggleViewTripModal}
          centered={true}
          className='driveview-viewtrip-modal'
        >
          <ModalBody>
            {this.state.tripInfo && this.state.tripInfo.tripWise && (
              <div>
                <span className='driveview-viewtrip-modal-exit' onClick={this.toggleViewTripModal} />
                <Row>
                  <div className='viewTripTipsSection'>
                    <div className='mx-auto'>
                      <h2>
                        <span className='headingSection'>{this.translator.t('driveview.view-trip.heading')}</span>
                      </h2>
                    </div>

                    <div className='sectionPadding'>
                      <Table>
                        <tbody>
                          <tr>
                            <td className='dateTDWidth'>
                              <span className='AMPMSize'>
                                {FormatDate(this.state.tripInfo.startTime, 'MMM.DD, hh:mm A')} -<br />
                                {FormatDate(this.state.tripInfo.endTime, 'MMM.DD, hh:mm A')}
                              </span>
                              <br />
                            </td>
                            <td className='driveScroreTDSize'>
                              <DriveViewScoreReading
                                noIcon={true}
                                score={findIn('tripInfo.tripWise.score', this.state) || 0}
                              />
                            </td>
                            <td>
                              <div className='driveViewViewTripWiseBackground'>
                                <span className='textSize'>
                                  {this.translator.t('driveview.view-trip.label.night-time-driving')}
                                </span>
                                <br />
                                <span className='numberSize'>
                                  {this.state.tripInfo.tripWise.nightTimeDrivingPercent || 0}%
                                </span>
                                <img
                                  src={nightTimeDrivingWhite}
                                  className='imageMargin'
                                  alt={this.translator.t('driveview.aggregated-snapshot.label.nighttime-driving')}
                                />
                              </div>
                            </td>
                            <td>
                              <div className='driveViewViewTripWiseBackground'>
                                <span className='textSize'>
                                  {this.translator.t('driveview.view-trip.label.hard-breaking')}
                                </span>
                                <br />
                                <span className='numberSize'>{this.state.tripInfo.tripWise.hardBrakeCount || 0} </span>
                                <img
                                  src={hardBreakWhite}
                                  className='imageMargin'
                                  alt={this.translator.t('driveview.aggregated-snapshot.label.hard-braking')}
                                />
                              </div>
                            </td>
                            <td>
                              <div className='driveViewViewTripWiseBackground'>
                                <span className='textSize'>
                                  {this.translator.t('driveview.view-trip.label.high-speed-driving')}
                                </span>
                                <br />
                                <span className='numberSize'>
                                  {this.state.tripInfo.tripWise.excessOfSpeedPercent || 0}%
                                </span>
                                <img
                                  src={speedWhite}
                                  className='imageMargin'
                                  alt={this.translator.t('driveview.aggregated-snapshot.label.high-speed-driving')}
                                />
                              </div>
                            </td>
                            <td>
                              <div className='driveViewViewTripWiseBackground'>
                                <span className='textSize'>
                                  {this.translator.t('driveview.view-trip.label.idle-time')}
                                </span>
                                <br />
                                <span className='numberSize'>{this.state.tripInfo.tripWise.idleTimePercent || 0}%</span>
                                <img
                                  src={idleWhite}
                                  className='imageMargin'
                                  alt={this.translator.t('driveview.aggregated-snapshot.label.idle-time')}
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    {/* Commented Tips Info*/}
                    {/*<div className='driveview-modal-tips'>{this.translator.t('driveview.view-trip.label.tips')}</div>
                    <Container fluid={true}>
                      <Row>
                        <Col>
                          <div className='driveview-modal-tips-background'>
                            <span className='driveview-modal-tips-heading'>
                              {this.translator.t('driveview.view-trip.label.overall')}
                            </span>
                            <br />
                            <span className='driveview-modal-tips-desc'>
                              {this.translator.t('driveview.view-trip.label.overall-desc')}
                            </span>
                          </div>
                        </Col>

                        <Col className='ml-4'>
                          <div className='driveview-modal-tips-background'>
                            <span className='driveview-modal-tips-heading'>
                              {this.translator.t('driveview.view-trip.label.hard-breaking')}
                            </span>
                            <br />
                            <span className='driveview-modal-tips-desc'>
                              {this.translator.t('driveview.view-trip.label.hard-breaking-desc')}
                            </span>
                          </div>
                        </Col>

                        <Col className='ml-4'>
                          <div className='driveview-modal-tips-background'>
                            <span className='driveview-modal-tips-heading'>
                              {this.translator.t('driveview.view-trip.label.acceleration')}
                            </span>
                            <br />
                            <span className='driveview-modal-tips-desc'>
                              {this.translator.t('driveview.view-trip.label.acceleration-desc')}
                            </span>
                          </div>
                        </Col>
                      </Row>
            </Container> */}
                  </div>
                </Row>
              </div>
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default TripsHistory;
