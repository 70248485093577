import { getLocaleSync } from '../providers/languageProvider';
import { refreshSessionTimer } from '../providers/sessionProvider';

import axios from 'axios';

axios.interceptors.request.use(req => {
  console.log('>> cwp - client-side - xhr request: ');
  console.log(`${req.method} ${req.url}`);

  let localeInfo = getLocaleSync();
  let { userLanguage, userCountry } = localeInfo;

  let localeHeaderAlreadySet = req.headers['x-user-locale'];
  let localeHeader = `${userLanguage.toLowerCase()}-${userCountry.toUpperCase()}`;
  let countryHeader = userCountry.toUpperCase();

  req.headers['x-user-country'] = countryHeader;
  req.headers['x-user-locale'] = localeHeaderAlreadySet ? localeHeaderAlreadySet : localeHeader;

  // restart the session timer on every request (relevant user activity to keep session running)
  // except when the session is being expired due to inactivity
  if (!req.url.includes('cwpmeta/session/expire')) {
    console.log('xhr applicable to refreshSessionTimer');
    refreshSessionTimer();
  }
  return req;
});

axios.interceptors.response.use(
  res => res,
  err => {
    let errStatusCode = (err && err.response && err.response.status) || '';

    // Standard unauthorized request response, route to 'login'
    if (errStatusCode === 401) {
      setTimeout(() => {
        window.location.href = '/login';
      }, 250);
    }

    // Special unauthorized request response
    if (errStatusCode === 403) {
      // No recovery for this error type, reroute to the login flow
      if (err.response.data && err.response.data.custom && String(err.response.data.custom).includes('/login')) {
        setTimeout(() => {
          window.location.href = '/login';
        }, 250);
      }
    }

    console.log(errStatusCode, JSON.stringify(err.response));
    // always let the error return down the rejection path
    return Promise.reject(err);
  }
);
