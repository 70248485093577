import './Footer.scss';
import React, { Component } from 'react';
import getCwpMeta from '../../../providers/cwpMetaProvider';
import { getLocaleSync } from '../../../providers/languageProvider';
import { getLocaleTranslator, setPreferredLanguage } from '../../../util/i18n/i18nService';
import { isUrlLocalized } from '../../../util/utilityMethods';
import { getTosManifest, getTosDocUri, getTosDocById } from '../../../providers/tosProvider';
import { VWCOM_CARNET_TOS_URL, VWCOM_CARNETAPP_FAQ_URL } from '../../../constants/carnet_constants';

const PRIM_USER_TOS = 'PRIM_USER_TOS';

class Footer extends Component {
  state = { tosUrl: null, localeOverride: null, componentWasMounted: false };

  componentDidMount = async () => {
    // determine url conditions here
    let url = window.location.href;
    let localeOverride = null;
    let urlLocalizedResult = isUrlLocalized(url);
    let userLanguage = null;
    let userCountry = null;

    if (urlLocalizedResult) {
      userLanguage = urlLocalizedResult.split('/')[0].toLowerCase();
      userCountry = urlLocalizedResult.split('/')[1].toUpperCase();
      localeOverride = `${userLanguage}-${userCountry}`;
    }

    let tosUrl = await this.getTosUrl(localeOverride);
    let locale = localeOverride || getLocaleSync().locale;

    this.setState(
      {
        tosUrl,
        userLocale: locale,
        localeOverride,
        componentWasMounted: true
      },
      () => {
        if (urlLocalizedResult) {
          console.log('Footer > componentDidMount > with localeOverride >> setting language');
          setPreferredLanguage({ userLanguage, userCountry });
        }
      }
    );
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevProps.locale !== this.props.locale && !this.state.localeOverride) {
      let toggleLangTosUrl = await this.getTosUrl();
      this.setState({
        tosUrl: toggleLangTosUrl,
        userLocale: this.props.locale
      });
    }
  };

  getTosUrl = async (localeOverride = null) => {
    // setup the default path
    let meta = await getCwpMeta();
    // let basePathUrl = meta && meta.static && meta.static.content.urls.baseUrl;
    let staticPath = meta && meta.static && meta.static.content.urls.securecontent;
    let defaultTosPath = staticPath + '/tos/primaryProgTOS.html';

    let setLocale = localeOverride || this.state.localeOverride || '';
    // also try to get the tosManifest
    let tosManifest = {};
    try {
      tosManifest = await getTosManifest(setLocale);
    } catch (err) {
      console.log('err getting tos manifest');
      console.log(err);
    }
    let naturalTosManifestLocale = tosManifest && tosManifest.locale;

    ///We Must Support Language Toggle at Runtime by the User here:
    if (this.props.locale && !setLocale) {
      setLocale = this.props.locale;
    } else if (naturalTosManifestLocale) {
      setLocale = naturalTosManifestLocale;
    }
    let manifestList = (tosManifest && tosManifest.manifest) || [];

    let tosItem = getTosDocById(manifestList, PRIM_USER_TOS) || {};
    // just set it to the default
    let tosUrl = defaultTosPath;

    if (tosItem.tosUrl) {
      // check if the url is an absolute path
      let isAbsolutePath = tosItem.absolute && String(tosItem.absolute).toLocaleLowerCase() === true;
      tosUrl = isAbsolutePath ? tosItem.tosUrl : getTosDocUri(tosItem.tosUrl, setLocale);
    }

    // CR-371 redirect
    if (setLocale.toLowerCase() === 'en-us') {
      tosUrl = VWCOM_CARNET_TOS_URL;
    }

    return tosUrl;
  };

  /**
   * Helpers to retrieve the correct footer url based on locale
   * Until login, returns the default (en-US)
   */
  getPrivacyPolicyUrl = override => {
    let locale = override || this.state.localeOverride || getLocaleSync().locale;
    let DEFAULT = 'https://www.vw.com/privacy/';
    let localeToUrlMap = {
      'en-US': 'https://www.vw.com/privacy/',
      'en-CA': 'https://www.vw.ca/en/privacy-policy.html',
      'fr-CA': 'https://www.vw.ca/fr/politique-de-confidentialite.html'
    };
    return localeToUrlMap[locale] || DEFAULT;
  };

  getFaqUrl = override => {
    let locale = override || this.state.localeOverride || getLocaleSync().locale;
    let DEFAULT = VWCOM_CARNETAPP_FAQ_URL;
    let localeToUrlMap = {
      'en-US': VWCOM_CARNETAPP_FAQ_URL,
      'en-CA': '/#/en/ca/faq',
      'fr-CA': '/#/fr/ca/faq'
    };
    return localeToUrlMap[locale] || DEFAULT;
  };

  donotselllink = override => {
    let locale = override || this.state.localeOverride || getLocaleSync().locale;
    let DEFAULT = '';
    let localeToUrlMap = {
      'en-US': 'Do Not Sell My Personal Information |',
      'en-CA': '',
      'fr-CA': ''
    };
    return localeToUrlMap[locale] || DEFAULT;
  };

  vwlink = override => {
    let locale = override || this.state.localeOverride || getLocaleSync().locale;
    let DEFAULT = 'http://www.vw.com/';
    let localeToUrlMap = {
      'en-US': 'http://www.vw.com/',
      'en-CA': 'https://www.vw.ca/en.html',
      'fr-CA': 'https://www.vw.ca/fr.html'
    };
    return localeToUrlMap[locale] || DEFAULT;
  };

  render () {
    let translator = getLocaleTranslator();
    return (
      <footer role='contentinfo' className='main-footer'>
        {this.state.componentWasMounted && (
          <div className='row'>
            <p className='col'>
              &copy;{new Date().getFullYear()} <span>{translator.t('footer.copyright')}</span>
            </p>
            <p className='colfooter'>
              <nav id='footer' aria-label='footer'>
                <a href={this.state.tosUrl} disabled={!this.state.tosUrl} target='_blank' rel='noreferrer'>
                  <span>{translator.t('footer.tos')}</span>
                </a>
                |
                <a href={this.getPrivacyPolicyUrl()} target='_blank' rel='noreferrer'>
                  <span>{translator.t('footer.pp')}</span>
                </a>
                |
                <a
                  href={this.getFaqUrl()}
                  target={this.state?.userLocale?.toLowerCase() === 'en-us' ? '_blank' : '_self'}
                  rel='noreferrer'
                >
                  <span>FAQ</span>
                </a>{' '}
                |
                <>
                  {this.state.userLocale.toLowerCase() === 'en-ca' && (
                    <>
                      <a href={'https://www.vw.ca/en/cookies.html'} target='_blank' rel='noreferrer'>
                        <span>{translator.t('footer.cookie.policy')}</span>
                      </a>
                      |
                    </>
                  )}
                  {this.state.userLocale.toLowerCase() === 'fr-ca' && (
                    <>
                      <a
                        href={'https://www.vw.ca/fr/politique-concernant-les-temoins.html'}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <span>{translator.t('footer.cookie.policy')}</span>
                      </a>
                      |
                    </>
                  )}
                  {this.state.userLocale.toLowerCase() === 'en-us' && (
                    <>
                      <a className='optanon-toggle-display'>
                        <span>{translator.t('footer.cookie.policy')}</span>
                      </a>
                      |
                    </>
                  )}
                </>
                {this.state.userLocale === 'en-US' && (
                  <a href='https://privacyportal.volkswagengroupofamerica.com/donotsell'> {this.donotselllink()} </a>
                )}
                <a href={this.vwlink()}>
                  <span>{translator.t('footer.vw')}</span>
                </a>
                |
              </nav>
            </p>
            <div className='row'>
              <p className='sm'>
                <span>{translator.t('footer.text')}</span>
              </p>
            </div>
          </div>
        )}
      </footer>
    );
  }
}

export default Footer;
