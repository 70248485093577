import React from 'react';
import UncontrolledAlert from 'reactstrap/lib/UncontrolledAlert';
import { getLocaleSync } from '../../../providers/languageProvider';
import { findErrorMessage, getLocaleTranslator } from '../../../util/i18n/i18nService';
import { findEnrollmentProcedure } from '../providers/SelfEnrollmentProvider';
import TOSCarnetUS from '../self_enrollment/TosCarnet';
import TosCarnetCA from '../self_enrollment/TosCarnetCanada';
import { findIn } from '../../../util/utilityMethods';
import LandingPage from './LandingPage';
import HeaderPrePinAuth from './HeaderPrePinAuth';
import { findVehicleByVehicleId } from '../providers/GarageProvider';
import PropTypes from 'prop-types';

class TOSCarnet extends React.Component {
  constructor (props) {
    super(props);

    let localeInfo = getLocaleSync();
    let { userCountry } = localeInfo;

    this.state = {
      processing: false,
      alert: null,
      userCountry: userCountry ? userCountry.toLowerCase() : 'us',
      vehicle: null
    };

    this.translator = getLocaleTranslator();
  }

  componentDidMount = async () => {
    const vehicleId = findIn('match.params.vehicleId', this.props);

    if (!vehicleId) {
      this.setState({ alert: this.translator.t('invalid-request') });

      return;
    }

    this.setState({ processing: true });

    try {
      const vehicle = await findVehicleByVehicleId(vehicleId);
      await findEnrollmentProcedure();
      this.setState({ processing: false, alert: null, vehicle });
    } catch (error) {
      this.setState({ processing: false, alert: findErrorMessage(error, this.translator) });
    }
  };

  afterSubmit = (tosStatus, response) => {
    document.location.href = LandingPage.GARAGE;
  };

  render = () => {
    return (
      <main>
        <div className='preVehicleAuth text-center selfEnrollment'>
          <HeaderPrePinAuth />
          <br />
          {this.state.alert && (
            <UncontrolledAlert color='danger' toggle={() => this.setState({ alert: null })}>
              {this.state.alert}
            </UncontrolledAlert>
          )}
          <br />
          {this.state.processing && (
            <div className='spinner-border' role='status'>
              <span className='sr-only'></span>
            </div>
          )}

          {this.state.vehicle && this.state.userCountry === 'us' && (
            <TOSCarnetUS
              vin={this.state.vehicle.vin}
              imgURL={this.state.vehicle.representativeImgURLComplete}
              modelYear={this.state.vehicle.modelYear}
              modelName={this.state.vehicle.modelName}
              tspProvider={this.state.vehicle.tspProvider}
              afterSubmit={this.afterSubmit}
              locale={this.props.locale}
            />
          )}

          {this.state.vehicle && this.state.userCountry === 'ca' && (
            <TosCarnetCA
              vin={this.state.vehicle.vin}
              imgURL={this.state.vehicle.representativeImgURLComplete}
              modelYear={this.state.vehicle.modelYear}
              modelName={this.state.vehicle.modelName}
              tspProvider={this.state.vehicle.tspProvider}
              afterSubmit={this.afterSubmit}
              locale={this.props.locale}
            />
          )}
        </div>
      </main>
    );
  };

  static propTypes = {
    locale: PropTypes.string.isRequired
  };
}

export default TOSCarnet;
