import React from 'react';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import {
  isNullOrEmptyObject,
  isEmailShaped,
  isBlankString,
  isValidNAPhoneNumberNumeric,
  isValidNAPhoneNumber
} from '../../../util/utilityMethods';
import { validateEmail } from '../../../providers/emailValidationProvider';
import { Container, Row, Col, Input, Button } from 'reactstrap';
import '../../reusable_cmp_lib/ThemedFormInputs/ThemedForm.scss';
import '../../reusable_cmp_lib/ThemedButtonsAssortment/ThemedButtonsAssortment.scss';
import './AccountManagement.scss';
import { US_PHONE_NUMBER_PATTERN, formatPhoneNumber, digitsOnly } from '../CarnetHome';
import { setTitle } from '../../../providers/documentTitleProvider';

class EmergencyContactEditor extends React.Component {
  constructor (props) {
    super(props);
    //passed-in from parent
    let contact =
      (this.props.details && this.props.details.customer && this.props.details.customer.emergencyContact) || {};
    // replace any nulls w. empty strings for display purposes
    contact['name'] = contact['name'] || '';
    contact['email'] = contact['email'] || '';
    contact['phones'] = contact['phones'] || [];
    contact['phones'][0] = contact['phones'][0] || '';
    contact['phones'][1] = contact['phones'][1] || '';
    contact['phones'][2] = contact['phones'][2] || '';

    this.state = {
      contactDetails: contact,
      entered_email_invalid: false,
      isvalidPhone0: true,
      isvalidPhone1: true,
      isvalidPhone2: true
    };
  }

  componentDidMount () {
    setTitle('document_titles.account_management.edit_emergency_contact');
  }

  isEmailPatternValid = emailEntry => {
    if (!emailEntry || emailEntry === '') {
      return true;
    }
    return isEmailShaped(emailEntry);
  };

  isPhoneNumberValid = numberEntry => {
    if (numberEntry === '') {
      return true;
    }
    const formatValid = isValidNAPhoneNumber(numberEntry);
    let extantContact = { ...this.state.contactDetails } || {};
    let extantPhones = extantContact.phones || [];
    let isDuplicatePhone = false;
    if (extantPhones.length > 0) {
      isDuplicatePhone = extantPhones.includes(numberEntry);
    }
    return formatValid && !isDuplicatePhone;
  };

  handleTextInput = (evt, fieldName) => {
    let presentTxt = evt.target.value;
    let stateGraft = {};
    let workingContact = this.state.contactDetails;
    let nameParts = fieldName.split('-');
    if (fieldName.startsWith('phone-')) {
      presentTxt = digitsOnly(presentTxt);
      let arrIndx = parseInt(nameParts[1]);
      if (presentTxt && presentTxt === workingContact.phones[arrIndx]) {
        presentTxt = presentTxt.slice(0, -1);
      }
      const entryValid = this.isPhoneNumberValid(presentTxt);
      let validations = {};
      let vkey = 'isvalidPhone' + arrIndx;
      validations[vkey] = entryValid;
      this.setState(validations, () => {
        this.updateStatePhoneAtIndex(arrIndx, presentTxt);
      });
      return;
    } else {
      const propertyName = nameParts[0];
      let flagInvalidEmailEntry = false;
      if (propertyName === 'email') {
        let emailEntryValid = this.isEmailPatternValid(presentTxt);
        flagInvalidEmailEntry = emailEntryValid === false;
      }
      workingContact[propertyName] = presentTxt;
      stateGraft = { contactDetails: workingContact };
      stateGraft['entered_email_invalid'] = flagInvalidEmailEntry;
    }
    this.setState(stateGraft);
  };

  updateStatePhoneAtIndex = (indexInt, nxPhone) => {
    let workingContact = { ...this.state.contactDetails } || {};
    let workingPhones = workingContact.phones || [];
    if (indexInt < 0 || indexInt > workingPhones.length) {
      return;
    }
    workingPhones[indexInt] = nxPhone;
    workingContact.phones = workingPhones;
    this.setState({
      contactDetails: workingContact
    });
  };

  saveState = async () => {
    let contactInfo = this.state.contactDetails || {};
    let phonesList = contactInfo.phones;
    let hasAContactPhone = this.atLeastOneValidPhone(phonesList);
    if (!contactInfo.email && hasAContactPhone === false) {
      this.setState({
        isvalidPhone0: false
      });
      return;
    }
    // validate email before proceeding
    if (contactInfo.email) {
      let emailAddressIsValid = false;
      try {
        emailAddressIsValid = await validateEmail(contactInfo.email);
      } catch (e) {
        console.log('email address validation failed ', e);
        this.setState({ entered_email_invalid: true });
        return;
      }
      this.setState({ entered_email_invalid: false });
    } else {
      if (!this.atLeastOneValidPhone(contactInfo.phones)) {
        this.setState({
          isValidPhone0: false
        });
        return;
      }
    }
    // clone the state
    let infoToPotentiallySave = JSON.parse(JSON.stringify(contactInfo));
    infoToPotentiallySave['phones'] = [...this.state.contactDetails.phones];

    // clean out empty phone numbers
    infoToPotentiallySave['phones'] = infoToPotentiallySave['phones'].filter(number => {
      return String(number).trim() !== '' && (String(number).length === 10 || String(number).length === 11);
    });

    let shouldSave = infoToPotentiallySave['name'] || infoToPotentiallySave['email'];
    shouldSave = shouldSave
      ? shouldSave
      : infoToPotentiallySave['phones'][0] || infoToPotentiallySave['phones'][1] || infoToPotentiallySave['phones'][2];

    if (shouldSave) {
      let clonedSourceToUpdate = JSON.parse(JSON.stringify(this.props.details.customer));
      clonedSourceToUpdate['emergencyContact'] = infoToPotentiallySave;
      this.props.refreshCallback(infoToPotentiallySave);
      this.props.saveHandler(clonedSourceToUpdate);
    }
  };

  atLeastOneValidPhone = phonesList => {
    if (!phonesList || phonesList.length === 0) {
      return false;
    }
    let hasOneValidPhone = false;
    //forEach does not serve our needs, here. We need to use break, if 1 valid phone is found. Using for-of loop:
    for (var phone of phonesList) {
      if (isValidNAPhoneNumberNumeric(phone)) {
        hasOneValidPhone = true;
        break; //we NEED to use break: so forEach would not work, here.
      }
    }
    return hasOneValidPhone;
  };

  componentDidUpdate () {
    setTitle('document_titles.account_management.edit_emergency_contact');
  }

  render () {
    this.translator = getLocaleTranslator();
    // placeholder
    let contact = this.state.contactDetails || { name: '', email: '', phones: ['', '', ''] };
    let providedContact =
      !isBlankString(contact.email) || (contact.phones != null && this.atLeastOneValidPhone(contact.phones));
    let unresolvedEntries =
      this.state.isvalidPhone0 === false ||
      this.state.isvalidPhone1 === false ||
      this.state.isvalidPhone2 === false ||
      this.state.entered_email_invalid === true;
    let notSaveReady = unresolvedEntries === true || providedContact === false;
    //Label translations (uses all preexisting entries)
    //No New Translation File Entries Were Created Here. Uses pre-existing labels.
    const optSuffixTrans = this.translator.t('acctmgmt_optional_suffix');
    const labelWordPhone = this.translator.t('acctmgmt_pi_field_label_phone');
    const emailHintLabel = '(' + this.translator.t('acctmgmt_pi_field_label_email') + ')';
    const phone1HintLabel = '(' + labelWordPhone + '1)';
    const phone2HintLabel = '(' + labelWordPhone + '2) ' + optSuffixTrans;
    const phone3HintLabel = '(' + labelWordPhone + '3) ' + optSuffixTrans;
    //    const phoneNumberOptional = `${labelWordPhone} ${optSuffixTrans}`;
    const phoneNumberOptional = this.translator.t('acctmgmt_pi_field_label_phone_optional');

    return (
      <fieldset>
        <Container fluid={true}>
          <Row>
            <Col md={{ offset: 4, size: 8 }}>
              <div className='editor-firstrow-label-aligner'>
                <legend>
                  <span className='first-editor-field-label'>
                    {this.translator.t('acctmgmt-editor-emergen-contact')}
                  </span>
                </legend>
                <div className='emergency-contact-subtitle-div'>
                  <span className='emergency-contact-subtitle-text'>
                    {this.translator.t('acctmgmt_one_contact_required_prompt')}
                  </span>
                </div>
              </div>
              <Row noGutters={true} className='mx-0'>
                <Col md={6}>
                  <div className='editor-diatom-field my-4'>
                    <label className='editor-diatom-superscript-lbl' htmlFor='contact_name_input'>
                      {this.translator.t('acctmgmt_pi_field_label_fullname')}
                    </label>
                    <Input
                      className='cwp'
                      type='text'
                      name='contact-name'
                      onChange={evt => this.handleTextInput(evt, 'name')}
                      id='contact_name_input'
                      placeholder={contact.name}
                    />
                  </div>

                  <div className='editor-diatom-field my-4'>
                    <label className='editor-diatom-superscript-lbl' htmlFor='contact_email_input'>
                      {this.translator.t('acctmgmt_pi_field_label_email')}
                    </label>
                    <Input
                      className={this.state.entered_email_invalid ? 'cwp invalid_input' : 'cwp'}
                      type='email'
                      name='contact-email'
                      onChange={evt => this.handleTextInput(evt, 'email')}
                      id='contact_email_input'
                      placeholder={contact.email || emailHintLabel}
                    />
                    {this.state.entered_email_invalid === true && (
                      <div id='invalid-phonenumber-indicator'>
                        {this.translator.t('acctmgmt_invalid_email_indicator')}
                      </div>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className='editor-diatom-field my-4'>
                    <label className='editor-diatom-superscript-lbl' htmlFor='phone-0-input'>
                      {this.translator.t('acctmgmt_pi_field_label_phone_1')}
                    </label>
                    <Input
                      className='cwp ml-0'
                      type='tel'
                      name='phone-0'
                      id='phone-0-input'
                      value={(contact.phones[0] && formatPhoneNumber(contact.phones[0])) || ''}
                      placeholder={(contact.phones[0] && formatPhoneNumber(contact.phones[0])) || phoneNumberOptional}
                      pattern={US_PHONE_NUMBER_PATTERN}
                      maxLength={14}
                      onChange={evt => this.handleTextInput(evt, 'phone-0')}
                    />

                    {this.state.isvalidPhone0 === false && (
                      <div id='invalid-phonenumber-indicator'>
                        {this.translator.t('acctmgmt_invalid_phone_indicator')}
                      </div>
                    )}
                  </div>

                  <div className='editor-diatom-field my-4'>
                    <label className='editor-diatom-superscript-lbl' htmlFor='phone-1-input'>
                      {this.translator.t('acctmgmt_pi_field_label_phone_2')}
                    </label>
                    <Input
                      className='cwp ml-0'
                      type='tel'
                      name='phone-1'
                      id='phone-1-input'
                      value={(contact.phones[1] && formatPhoneNumber(contact.phones[1])) || ''}
                      placeholder={(contact.phones[1] && formatPhoneNumber(contact.phones[1])) || phoneNumberOptional}
                      pattern={US_PHONE_NUMBER_PATTERN}
                      maxLength={14}
                      onChange={evt => this.handleTextInput(evt, 'phone-1')}
                    />
                    {this.state.isvalidPhone1 === false && (
                      <div id='invalid-phonenumber-indicator'>
                        {this.translator.t('acctmgmt_invalid_phone_indicator')}
                      </div>
                    )}
                  </div>

                  <div className='editor-diatom-field my-4 phone-field'>
                    <label className='editor-diatom-superscript-lbl' htmlFor='phone-2-input'>
                      {this.translator.t('acctmgmt_pi_field_label_phone_3')}
                    </label>
                    <Input
                      className='cwp ml-0'
                      type='tel'
                      name='phone-2'
                      id='phone-2-input'
                      value={(contact.phones[2] && formatPhoneNumber(contact.phones[2])) || ''}
                      placeholder={(contact.phones[2] && formatPhoneNumber(contact.phones[2])) || phoneNumberOptional}
                      pattern={US_PHONE_NUMBER_PATTERN}
                      maxLength={14}
                      onChange={evt => this.handleTextInput(evt, 'phone-2')}
                    />
                    {this.state.isvalidPhone2 === false && (
                      <div id='invalid-phonenumber-indicator'>
                        {this.translator.t('acctmgmt_invalid_phone_indicator')}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={{ offset: 8, size: 3 }}>
              <Button
                className='cwp my-4'
                disabled={notSaveReady}
                color='primary'
                onClick={() => {
                  if (isNullOrEmptyObject(this.state.contactDetails) || this.state.contactDetails.name === '') {
                    return; //no contact created, to save.
                  }
                  this.saveState();
                }}
              >
                {this.translator.t('save_changes_label')}
              </Button>
            </Col>
          </Row>
        </Container>
      </fieldset>
    );
  }
}

export default EmergencyContactEditor;
