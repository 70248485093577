import React from 'react';
import { formatDateWithMonth } from '../../../util/i18n/localeUtils';
import { findIn } from '../../../util/utilityMethods';

const ChangeServicesPlan = (props = {}) => {
  const { configuration = {} } = props;
  let title = configuration && configuration.title;
  let description = configuration && configuration.description;
  const purchaseDate = formatDateWithMonth(props.plan.purchaseDate);
  const endDate = formatDateWithMonth(props.plan.expirationDate);
  /*
  const purchaseDate = new Date(0);
  purchaseDate.setTime(props.plan.purchaseDate);
  const endDate = new Date(0);
  endDate.setTime(props.plan.expirationDate);
  */
  let prices = null;
  let pricingOptions = findIn('plan.package.pricingOptions', props);

  if (Array.isArray(pricingOptions) && pricingOptions.length > 0) {
    pricingOptions = props.plan.package.pricingOptions.filter(
      pricing => pricing.price && (pricing.timePeriodType === 'MONTHLY' || pricing.timePeriodType === 'YEARLY')
    );
    if (pricingOptions && pricingOptions.length > 0) {
      prices = pricingOptions.map(pricing => (
        <h6 hidden={!pricing.price}>
          ${pricing.price}
          {props.translator.t('pricing_period_' + pricing.timePeriodType)}
        </h6>
      ));
    }
  }

  const sku = findIn('package.sku', props.plan);

  const shouldDisplayPlansDateBlock = findIn('plan.purchaseDate', props) || findIn('plan.expirationDate', props);

  const getPlanDateBlock = () => {
    return (
      <div>
        <span hidden={!findIn('plan.purchaseDate', props)} className='alignChangeServicesTextBlockLeft'>
          {props.translator.t('change_services.start_date')} {purchaseDate}
        </span>
        <span hidden={!findIn('plan.expirationDate', props)} className='alignChangeServicesTextBlockLeft'>
          {props.translator.t('change_services.ends_on')} {endDate}
        </span>
      </div>
    );
  };

  return (
    <div className='my-4'>
      <input
        type='checkbox'
        checked={props.selected(sku)}
        className={`cwpCheckbox ${props.selected(sku) ? 'cwpCheckboxOn' : 'cwpCheckboxOff'}`}
        onChange={e => props.toggle(sku)}
        tabindex='0'
        id={`chkInput${sku}`}
      />
      <label htmlFor={`chkInput${sku}`} className='h4'>
        {title || findIn('plan.package.name', props)}
      </label>
      {prices}
      {shouldDisplayPlansDateBlock && getPlanDateBlock()}
      <div>
        <span className='small'>{description || findIn('plan.package.shortDescription', props)}</span>
      </div>
    </div>
  );
};

export default ChangeServicesPlan;
