const LandingPage = Object.freeze({
  GARAGE: '/#/',
  BUY_PLANS: '/#/buy-plans',
  CHANGE_SERVICES: '/#/changeServices',
  EMOBILITY: '/#/emobility',
  SELF_ENROLLMENT: '/#/selfEnrollment',
  GUARDIAN_SERVICES: '/#/guardianServices',
  VHR: '/#/vhr',
  DEFAULT: '/#/',
  TOS_CARNET: '/#/carnetTOS',
  HISTORY: '/#/history',
  TRIPS: '/#/trips',
  MESSAGECENTER: '/#/messagecenter',
  ACCOUNT: '/#/acct-mgmt'
});

// Could map directly to 'e-golf.'
// However, this assumes we will have other electric vehicles.
const ModelTypes = Object.freeze({
  EV: ['e-golf']
});

const LandingPageForModelType = Object.freeze({
  EV: LandingPage.EMOBILITY
});

const LandingPageForTSP = Object.freeze({
  VZT: LandingPage.VHR,
  ATC: LandingPage.BUY_PLANS,
  WCT: LandingPage.BUY_PLANS
});

// Supporting deeplinkning for myVW, VZT not applicable
const ValidDeeplinkLandingPagesPerTSP = Object.freeze({
  ATC: {
    BUY_PLANS: LandingPage.BUY_PLANS,
    CHANGE_SERVICES: LandingPage.CHANGE_SERVICES,
    VHR: LandingPage.VHR,
    HISTORY: LandingPage.HISTORY,
    TRIPS: LandingPage.TRIPS,
    MESSAGECENTER: LandingPage.MESSAGECENTER,
    ACCOUNT: LandingPage.ACCOUNT
  },
  WCT: {
    BUY_PLANS: LandingPage.BUY_PLANS,
    CHANGE_SERVICES: LandingPage.CHANGE_SERVICES,
    MESSAGECENTER: LandingPage.MESSAGECENTER,
    ACCOUNT: LandingPage.ACCOUNT
  }
});

const findLandingPageBasedOnVehicleType = (modelName = '') => {
  if (!modelName) {
    return false;
  }
  let keys = Object.keys(ModelTypes);
  let i = 0;
  let lng = keys.length;
  for (; i < lng; i++) {
    let key = keys[i];
    let modelArr = ModelTypes[key];
    if (modelArr.includes(modelName.toLowerCase())) {
      return LandingPageForModelType[key];
    }
  }
  return false;
};

const findPostGarageLandingPage = (tspProvider = null, modelName) => {
  if (!tspProvider) {
    return LandingPage.DEFAULT;
  }

  let tspBasedLandingPage = LandingPageForTSP[tspProvider];
  let modelBasedLandingPage = findLandingPageBasedOnVehicleType(modelName);

  return modelBasedLandingPage || tspBasedLandingPage;
};

export default LandingPage;
export { LandingPage, findPostGarageLandingPage, ValidDeeplinkLandingPagesPerTSP };
