import React from 'react';
import PubSub from 'pubsub-js';
import getCwpMeta from '../../providers/cwpMetaProvider';
import { getLocale, setLocale } from '../../providers/languageProvider';
import { getLocaleTranslator } from '../../util/i18n/i18nService';

import './toggle.scss';
import { createBrowserHistory } from 'history';

const DEFAULT_LANGUAGE_LIST = { languages: [{ code: 'en', label: 'english' }] };

// list of urls that require a reload (to make xhr afresh for localized content) after a language swap
const reloadUrlList = ['#/buy-plans', '#/carnetTOS', '#/vhr'];

const foundInReloadList = path => {
  let found = false;
  reloadUrlList.forEach(url => {
    found = found || String(path).includes(url);
  });
  return found;
};

class LanguageToggle extends React.Component {
  _isMounted = false;

  state = {
    hidden: true,
    expanded: false, // showing/hiding language options menu
    language: null, // language that is being used by the client
    prevLangSelection: null, // previous language setting, used for failure recovery
    languageOptions: null, // options from which to select languages
    path: ''
  };

  subscriptions = [
    PubSub.subscribe('userSession.start', () => {
      if (this._isMounted) {
        this.setState({ hidden: false });
      }
    }),
    PubSub.subscribe('userSession.stop', () => {
      if (this._isMounted) {
        this.setState({ hidden: true });
      }
    })
  ];

  componentDidMount () {
    const history = createBrowserHistory();
    this.setState({ path: history.location.hash });
    this._isMounted = true;
    this.getTranslation = getLocaleTranslator().t;
    return Promise.all([getCwpMeta(), getLocale()]).then(responses => {
      let isResponseArray = Array.isArray(responses);
      let language = isResponseArray && responses[1] && responses[1].userLanguage;
      let _userCountry = (isResponseArray && responses[1] && responses[1].userCountry) || 'us';

      let _languageToCountryMapping = (responses[0] &&
        responses[0].static &&
        responses[0].static.languageToCountryMapping) || [DEFAULT_LANGUAGE_LIST];

      let _languageList = _languageToCountryMapping.filter(
        mapping => mapping.code.toLowerCase() === _userCountry.toLowerCase()
      ) || [DEFAULT_LANGUAGE_LIST];

      let languageOptions = _languageList[0].languages;

      if (this._isMounted) {
        this.setState({
          language,
          languageOptions
        });
      }
    });
  }

  componentWillUnmount () {
    this._isMounted = false;
    this.subscriptions.forEach(PubSub.unsubscribe);
  }

  changeLocale = langCode => {
    const history = createBrowserHistory();
    let path = history.location.hash;

    let prevLangSelection = String(this.state.language);
    this.setState({ prevLangSelection }, () => {
      setLocale(langCode).then(res => {
        this.setState({ language: res.userLanguage }, () => {
          let urlNeedsReload = reloadUrlList.includes(path) || foundInReloadList(path);
          if (urlNeedsReload) {
            window.location.reload();
          }
        });
      });
    });
  };

  render () {
    if (this.state.path.includes('faq')) {
      return <div></div>;
    } else if (Array.isArray(this.state.languageOptions) && this.state.languageOptions.length > 1) {
      return (
        <div
          aria-label={this.getTranslation('language_toggle.languageSwitchLabel')}
          className={this.props.inverted ? 'languageToggle inverted' : 'languageToggle'}
        >
          <ul>
            {this.state.languageOptions.map((localeThing, index) => {
              let id = localeThing.code + '_' + index;
              let isActiveLanguage = this.state.language === localeThing.code;

              if (isActiveLanguage) {
                return (
                  <li key={id}>
                    <span className='selected'>{localeThing.code.toUpperCase()}</span>
                  </li>
                );
              }

              return (
                <li key={id}>
                  <a
                    href='javascript:void(0)'
                    aria-label={this.getTranslation(
                      `language_toggle.languageSwitchTitle.${localeThing.code.toUpperCase()}`
                    )}
                    title={this.getTranslation(`language_toggle.languageSwitchTitle.${localeThing.code.toUpperCase()}`)}
                    onClick={() => {
                      this.changeLocale(localeThing.code.toLowerCase());
                    }}
                  >
                    {localeThing.code.toUpperCase()}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default LanguageToggle;
