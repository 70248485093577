import React, { Component } from 'react';
import Faq from 'react-faq-component';
import '../faqCanada/faqCA.scss';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import goback from '../../../assets/faq/goback.png';
import { historyBack } from './faqCAhome';
import { setTitle } from '../../../providers/documentTitleProvider';

export default class NavigateFaqCA extends Component {
  componentDidMount () {
    setTitle('document_titles.faq.poi');
  }
  render () {
    let translator = getLocaleTranslator();

    return (
      <div className='faqCAheader' role='main' id='main-content'>
        <div id='exit-editing-link'>
          <span
            id='backButton'
            class='cwpBack'
            role='button'
            onClick={historyBack}
            onKeyPress={e => {
              e.preventDefault();
              if (e.key && (e.key === 'Enter' || e.key === ' ')) {
                historyBack(e);
              }
            }}
            tabIndex={0}
          >
            {translator.t('button.back')}
          </span>
        </div>
        <div className='message-header'>
          <div className='td-ms-header'>
            <h1>{translator.t('navigate_title')}</h1>
          </div>
        </div>
        <Faq data={data} />
      </div>
    );
  }
}

const data = {
  rows: [
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          What is the Navigate tab for on the Volkswagen Car-Net® web portal or mobile app?{' '}
        </div>
      ),
      content: ` 
      <span class='mdlyr'>Model Year 2022+:</span>The Navigate page on the Volkswagen Car-Net® web portal and mobile app allows you to send points of interest directly to your vehicle. Your vehicle must be equipped with a factory-installed navigation system to take advantage of this service. Once on the Navigate page, you are able to search and select the destination of your choice. Once you have chosen your point of interest, press “Send to my VW” to have the address sent to directly to your vehicle’s navigation system. If it fails to send, a “Resend to my VW” button should appear. Pressing this will resend the previously selected point of interest to your vehicle. 
      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          What do the different statuses mean on the Navigate tab?{' '}
        </div>
      ),
      content: ` <span class='mdlyr'>Model Year 2022+:</span> 
        <ol> 
          <li>Successful — Point of interest has successfully been sent to your Volkswagen vehicle.</li>
          <li>Pending — Point of interest has been sent and is waiting for your vehicle’s navigation system to accept and download it.</li>
          <li>Failed —Point of interest did not make it to your vehicle’s navigation system, and should you should attempt to resend it.</li>
        </ol>
              
      `
    }
  ]
};

export { NavigateFaqCA };
