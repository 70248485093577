/* eslint-disable complexity */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import * as actions from '../../../redux/actions';
import { connect } from 'react-redux';
import VHRHeader from './VehicleHealthHeader';
import './vehicleHealth.scss';
import VehicleHealthReport from './VehicleHealthReport';
import VehicleHealthHistory from './VehicleHealthHistory';
import PriorityCodeLegend from './PriorityCodeLegend';
import MonthlyReportsPreferences from './MonthlyReportsPreferences';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import SirisXMStatus from './SirisXMStatus';
import carnet_constants from '../../../constants/carnet_constants';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import axios from 'axios';
import arrow from '../../../assets/vehicleHealth/arrow.png';
import add from '../../../assets/vehicleHealth/add.png';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import NumberFormat from 'react-number-format';
import { getLocaleSync } from '../../../providers/languageProvider';
import { isNonEmptyObject, isBlankString, findIn } from '../../../util/utilityMethods';
import { getScheduleServiceUrl } from '../providers/ThirdPartyProvider';

const cliploaderOverride = css`
  display: block;
  margin: auto;
  position: absolute;
  left: 45%;
  top: 170px;
  margin-bottom: 300px;
`;

class VehicleHealthHome extends Component {
  constructor () {
    super();
    this.state = {
      vehicleDetails: {},
      schduleServiceModal: false
    };
    this.userCountry = '';
  }

  componentDidMount () {
    this.props.actions.getCarNickName();
    this.props.actions.getPreferredDealer();
    this.props.actions.getVehicleHealthByVehicleID();
    this.props.actions.getDealerUrls();
    this.props.actions.getSirisxmSubscriptionStatus();
    this.getVehicleDetails();
    return Promise.resolve(getLocaleSync()).then(response => {
      this.userCountry = response && response.userCountry ? response.userCountry.toUpperCase() : 'US';
    });
  }

  getServiceUrlFromNits = async () => {
    const preferredDealer = (Array.isArray(this.props.preferredDealerData) && this.props.preferredDealerData) || '';
    const dealerId = isNonEmptyObject(preferredDealer) && preferredDealer[0] && preferredDealer[0].dealerId;
    const odoReadCount = this.props.vehicleHealthReportData?.odoReadCount;
    try {
      let dealerServiceUrlInfo = await getScheduleServiceUrl(dealerId, odoReadCount);
      if (dealerServiceUrlInfo && dealerServiceUrlInfo.serviceURL) {
        window.open(dealerServiceUrlInfo.serviceURL);
      }
    } catch (err) {
      this.setState({ schduleServiceModal: true });
    }
  };

  getVehicleDetails = () => {
    axios.get(`/vehicle`).then(res => {
      this.setState({ vehicleDetails: res.data.data });
    });
  };

  render () {
    let translator = getLocaleTranslator();

    const tspProvider = this.state.vehicleDetails && this.state.vehicleDetails.tspProvider;

    const toggleSchduleServiceModal = () => {
      this.setState({ schduleServiceModal: !this.state.schduleServiceModal });
    };

    const dealerName =
      (this.props.preferredDealerData &&
        Array.isArray(this.props.preferredDealerData) &&
        this.props.preferredDealerData.map(prefDealer => prefDealer.dealerDetails && prefDealer.dealerDetails.name)) ||
      '';

    const preferredDealer = (Array.isArray(this.props.preferredDealerData) && this.props.preferredDealerData) || '';

    const offersURL = (this.props.dealerUrlsData && this.props.dealerUrlsData.offersURL) || '';

    const odoReadCount = (this.props.vehicleHealthReportData && this.props.vehicleHealthReportData.odoReadCount) || '';
    const overallPriorityCode =
      (this.props.vehicleHealthReportData && this.props.vehicleHealthReportData.overallPriorityCode) || '';
    const overAllPriorityDescription =
      (this.props.vehicleHealthReportData && this.props.vehicleHealthReportData.overAllPriorityDescription) || '';
    const vhrTimeStamp = (this.props.vehicleHealthReportData && this.props.vehicleHealthReportData.vhrTimeStamp) || '';
    const applicableCategories =
      (this.props.vehicleHealthReportData && this.props.vehicleHealthReportData.applicableCategories) || '';

    const summarizedVHHistory =
      (this.props.vehicleHealthReportData && this.props.vehicleHealthReportData.summarizedVehicleHealthHistory) || '';

    const engineTypeCode = this.state.vehicleDetails && this.state.vehicleDetails.engineTypeCode;

    const maintenanceDaysAndMiles = getServiceMilesAndDays(
      this.props.vehicleHealthReportData,
      this.userCountry,
      engineTypeCode
    );

    const sirisXMDetails = this.props.siriusxmStatusData || '';
    const dealerDetails = isNonEmptyObject(preferredDealer) && preferredDealer[0].dealerDetails;

    let overallStatus = vehicleStatus(overallPriorityCode, translator);

    function getServiceIsDaysOverdueMsg (deadlineMarkersAvail, maintanceDays) {
      if (deadlineMarkersAvail) {
        return (
          <span className='fontSize'>
            {maintanceDays} {translator.t('vehicleHealth.days_overdue_label')}
          </span>
        );
      } else {
        return <span className='fontSize'>{translator.t('vehicleHealth.unknown_overdue_label')}</span>;
      }
    }

    function getUnavailMsg () {
      return `${translator.t('vehicleHealth.unavailable_label')}`;
    }

    function getVehicleNeedsAttentionMsg () {
      return <span className='vhr-schedule-service'>{translator.t('vehicleHealth.priority_code_b_desc')}</span>;
    }

    function getDoNotDriveMsg () {
      return <span className='vhr-schedule-service'>{translator.t('vehicleHealth.service_required_label')}</span>;
    }

    function getServiceDueInDaysOrUnitsMsg (deadlineMarkersAvail, maintanceMiles, maintanceDays) {
      if (deadlineMarkersAvail) {
        return (
          <div className='fontSize'>
            {translator.t('vehicleHealth.in_text')}
            <NumberFormat value={maintanceMiles} displayType={'text'} thousandSeparator={true} />
            &nbsp;{translator.t('vehicleHealth.mi_or_km_text')} {maintanceDays}{' '}
            {translator.t('vehicleHealth.days_text')}
          </div>
        );
      } else {
        return getUnavailMsg();
      }
    }

    function getServiceMessage (overallPriorityCode, overallPriorityDescription, maintenanceDaysAndMiles) {
      let maintanceDays = '';
      let maintanceMiles = '';
      if (maintenanceDaysAndMiles && maintenanceDaysAndMiles.length > 1) {
        maintanceMiles = maintenanceDaysAndMiles[0];
        maintanceDays = maintenanceDaysAndMiles[1];
      }
      let deadlineMarkersAvail = !(isBlankString(maintanceMiles) || isBlankString(maintanceDays));
      if (
        overallPriorityCode === carnet_constants.OVERALL_PRIORITY_CODE_C &&
        overallPriorityDescription === carnet_constants.PRIORITY_DESC_SERVICE_OVERDUE
      ) {
        return getServiceIsDaysOverdueMsg(deadlineMarkersAvail, maintanceDays);
      }
      if (
        overallPriorityCode === carnet_constants.OVERALL_PRIORITY_CODE_C &&
        overallPriorityDescription === carnet_constants.PRIORITY_DESC_SERVICE_DUE
      ) {
        return getServiceDueInDaysOrUnitsMsg(deadlineMarkersAvail, maintanceMiles, maintanceDays);
      }
      if (
        overallPriorityCode === carnet_constants.OVERALL_PRIORITY_CODE_B &&
        overallPriorityDescription === carnet_constants.PRIORITY_DESC_NEED_ATTENTION
      ) {
        return getVehicleNeedsAttentionMsg();
      }
      if (
        overallPriorityCode === carnet_constants.OVERALL_PRIORITY_CODE_A &&
        overallPriorityDescription === carnet_constants.PRIORITY_DESC_DONOT_DRIVE
      ) {
        return getDoNotDriveMsg();
      }
      if (overallPriorityCode !== carnet_constants.OVERALL_PRIORITY_CODE_C) {
        return getServiceDueInDaysOrUnitsMsg(deadlineMarkersAvail, maintanceMiles, maintanceDays);
      }
    }

    return this.props.dataLoading && this.props.dataLoading === true ? (
      <div role='main' id='main-content'>
        <VHRHeader vhrTimeStamp={vhrTimeStamp} tspProvider={tspProvider} />
        <div className='vhr-home-div'>
          {/* First Column Start*/}

          <Row>
            <Col md={4}>
              <div className='leftCol'>
                <div>{overallStatus}</div>

                <div className='vehicle-details'>
                  {this.state.vehicleDetails && (
                    <div className='vin-details'>
                      <p className='vehicledetails-margin'>
                        {this.state.vehicleDetails.modelName}&nbsp;
                        {this.state.vehicleDetails.modelYear}
                      </p>
                      <span>VIN: {this.state.vehicleDetails.vin}</span>{' '}
                    </div>
                  )}
                  {offersURL && offersURL !== '' && (
                    <span className='link'>
                      <a href={offersURL} target='_blank' rel='noopener noreferrer' className='link-color'>
                        &#129106; &nbsp;
                        {translator.t('vehicleHealth.see_special_offers_label')}
                      </a>
                    </span>
                  )}
                </div>

                <br />
                <br />

                <div className='vehicle-details'>
                  <h3 className='vhr-preferred-dealer'>{translator.t('vehicleHealth.preferred_dealer_label')}</h3>
                  <div>
                    <h5 className='fontSize'>{dealerName}</h5>
                    <span className='link'>
                      <a href='/#/acct-mgmt/vehicle' className='link-color'>
                        <img src={add} alt='' className='add' />
                        {!!dealerName ? (
                          <span>{translator.t('vehicleHealth.update_a_dealer_label')}</span>
                        ) : (
                          <span>{translator.t('vehicleHealth.add_a_dealer_label')}</span>
                        )}
                      </a>
                    </span>
                  </div>
                </div>
                <br />
                <br />

                <div className='cwp-column-full-size'>
                  <h3 className='vhr-preferred-dealer'>{translator.t('vehicleHealth.schedule_next_service_label')}</h3>

                  {getServiceMessage(overallPriorityCode, overAllPriorityDescription, maintenanceDaysAndMiles)}

                  {isNonEmptyObject(dealerDetails) && (
                    <div>
                      <span className='link'>
                        <div onClick={this.getServiceUrlFromNits} className='link-color'>
                          &#129106; &nbsp;
                          {translator.t('vehicleHealth.schedule_service_label')}
                        </div>
                      </span>
                    </div>
                  )}

                  <div>
                    <Modal isOpen={this.state.schduleServiceModal} centered={true} className='vhr'>
                      <ModalHeader cssModule={{ 'modal-title': 'w-100 text-center' }}>
                        <span className='modalheader-txt'>{translator.t('vehicleHealth.schedule_service_label')}</span>
                      </ModalHeader>
                      <ModalBody>
                        <div>
                          {isNonEmptyObject(preferredDealer) &&
                            preferredDealer.map(
                              prefDealer =>
                                prefDealer.dealerDetails && (
                                  <div>
                                    <div className='service-modal-text'>{prefDealer.dealerDetails.name}</div>
                                    <div className='service-modal-text'>
                                      {findIn('dealerDetails.address.addressLine1', prefDealer)}
                                    </div>
                                    <div className='service-modal-text'>
                                      <span>
                                        {findIn('dealerDetails.address.city', prefDealer)}
                                        ,&nbsp;
                                        {findIn('dealerDetails.address.state', prefDealer)}, &nbsp;
                                        {findIn('dealerDetails.address.zipCode', prefDealer)}
                                      </span>
                                    </div>
                                    <div className='service-modal-urltext'>{prefDealer.dealerDetails.url}</div>
                                    <div className='service-modal-text'>
                                      {translator.t('vehicleHealth.schedule_appointment_label')}
                                      <br />
                                      {prefDealer.dealerDetails.phones &&
                                        prefDealer.dealerDetails.phones.map(
                                          phone =>
                                            phone.type === 'BUS_PHONE_NUM' && (
                                              <NumberFormat
                                                value={phone.number}
                                                displayType={'text'}
                                                format='(###) ###-####'
                                              />
                                            )
                                        )}
                                    </div>
                                  </div>
                                )
                            )}
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <Button className='gotit-btn' onClick={toggleSchduleServiceModal}>
                          {translator.t('vehicleHealth.got_it_label')}
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </div>
                </div>

                <br />
                <br />
                <div className='cwp-column-full-size'>
                  <span>{translator.t('vehicleHealth.odometer_label')}</span>
                  <br />

                  <span className='fontSize'>
                    <NumberFormat
                      value={localizedOdometerReading(odoReadCount, this.userCountry)}
                      displayType={'text'}
                      thousandSeparator={true}
                      renderText={value => <div>{value}</div>}
                    />
                  </span>
                </div>
              </div>
              <div className='bottom-row'></div>
            </Col>

            <div className='line'></div>

            <Col className='col-md-4'>
              <div className='vhr-details middleCol'>
                <h4>{translator.t('vehicleHealth.vehicle_health_report_label')}</h4>
                <p className='imp-notice'>{translator.t('vehicleHealth.important_notice_text')}</p>
                <VehicleHealthReport applicableCategories={applicableCategories} />
              </div>

              <PriorityCodeLegend />
              <div className='bottom-row'></div>
            </Col>

            <div className='line'></div>

            <Col md={3}>
              <div className='rightCol'>
                <MonthlyReportsPreferences locale={this.props.locale} userCountry={this.userCountry} />
              </div>
            </Col>
          </Row>

          <br />
          {tspProvider === carnet_constants.TSP_ATC && sirisXMDetails && sirisXMDetails.radioEquipped === true && (
            <div className='sxm-status'>
              <SirisXMStatus locale={this.props.locale} sirisXMDetails={sirisXMDetails} />
            </div>
          )}
        </div>
        <VehicleHealthHistory locale={this.props.locale} summarizedVHHistory={summarizedVHHistory} />
      </div>
    ) : (
      <div className='vhr-loader'>
        <ClipLoader css={cliploaderOverride} sizeUnit={'px'} size={35} color={'#001e50'} />
      </div>
    );
  }
}

const localizedOdometerReading = (odoReadCount, userCountry) => {
  return userCountry === 'US' ? Math.round(odoReadCount * carnet_constants.KM_TO_MILES) : odoReadCount;
};

const vehicleStatus = (overallPriorityCode, translator) => {
  let overallStatus;
  if (overallPriorityCode === '') {
    overallStatus = <div className='vhr-status-title'>{translator.t('vehicleHealth.unavailable_label')}</div>;
  } else {
    if (overallPriorityCode === carnet_constants.OVERALL_PRIORITY_CODE_D) {
      overallStatus = (
        <div
          className='vhr-status-title vhr-prioritycode-img priorityCodeD'
          aria-label={translator.t('vehicleHealth.priority_code_d_desc')}
        >
          {translator.t('vehicleHealth.priority_code_d_desc')}
        </div>
      );
    } else if (overallPriorityCode === carnet_constants.OVERALL_PRIORITY_CODE_C) {
      overallStatus = (
        <div
          className='vhr-status-title vhr-prioritycode-img priorityCodeC'
          aria-label={translator.t('vehicleHealth.priority_code_c_desc')}
        >
          {translator.t('vehicleHealth.priority_code_c_desc')}
        </div>
      );
    } else if (overallPriorityCode === carnet_constants.OVERALL_PRIORITY_CODE_B) {
      overallStatus = (
        <div
          className='vhr-status-title vhr-prioritycode-img priorityCodeB'
          aria-label={translator.t('vehicleHealth.priority_code_b_desc')}
        >
          {translator.t('vehicleHealth.priority_code_b_desc')}
        </div>
      );
    } else if (overallPriorityCode === carnet_constants.OVERALL_PRIORITY_CODE_A) {
      overallStatus = (
        <div
          className='vhr-status-title vhr-prioritycode-img priorityCodeA'
          aria-label={translator.t('vehicleHealth.priority_code_a_desc')}
        >
          {translator.t('vehicleHealth.priority_code_a_desc')}
        </div>
      );
    }
  }

  return overallStatus;
};

const getServiceMilesAndDays = (vehicleHealthReportData, userCountry, engineTypeCode = '') => {
  let miles;
  let days;
  let milesDays = [];

  if (vehicleHealthReportData && vehicleHealthReportData.vhrMaintEvents) {
    let vhrMaintEvents = vehicleHealthReportData.vhrMaintEvents;

    let maintenanceEvent1 = vhrMaintEvents[0];
    let maintenanceEvent2 = vhrMaintEvents[1];

    let applicableEvent;
    if (vhrMaintEvents.length > 1) {
      if (engineTypeCode.toUpperCase() === carnet_constants.ENGINE_TYPE_BEV) {
        applicableEvent =
          maintenanceEvent1.eventType.toUpperCase() === carnet_constants.EVENTTYPE_INSPECTION
            ? maintenanceEvent1
            : maintenanceEvent2;
      } else if (engineTypeCode.toUpperCase() === carnet_constants.ENGINE_TYPE_ICE) {
        applicableEvent =
          maintenanceEvent2.eventType.toUpperCase() === carnet_constants.EVENTTYPE_OIL
            ? maintenanceEvent2
            : maintenanceEvent1;
      }
    } else if (vhrMaintEvents.length > 0) {
      applicableEvent = vhrMaintEvents[0];
    }

    if (applicableEvent && applicableEvent.eventStatusDesc) {
      if ('Service in' === applicableEvent.eventStatusDesc || 'Service Due' === applicableEvent.eventStatusDesc) {
        miles = localizedOdometerReading(applicableEvent.distance, userCountry);
        days = applicableEvent.daysCount;
      }
      console.log('Miles::' + miles);
      console.log('days ::' + days);
      milesDays.push(parseInt(miles));
      milesDays.push(days);
      console.log('milesDays ::' + milesDays);
      return milesDays;
    }
  }
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getCarNickName: actions.getCarNickName,
        getPreferredDealer: actions.getPreferredDealer,
        getVehicleHealthByVehicleID: actions.getVehicleHealthByVehicleID,
        getDealerUrls: actions.getDealerUrls,
        getSirisxmSubscriptionStatus: actions.getSirisxmSubscriptionStatus
      },
      dispatch
    )
  };
};

const mapStateToProps = state => {
  const {
    carNickNameInfo,
    preferredDealerDetails,
    vehicleHealthReportDetails,
    dealerUrlsDetails,
    sirisxmSubscriptionStatus
  } = state;

  return {
    carNickNameData: carNickNameInfo.carNickNameData,
    preferredDealerData: preferredDealerDetails.preferredDealerData,
    vehicleHealthReportData: vehicleHealthReportDetails.vehicleHealthReportData,
    dataLoading: vehicleHealthReportDetails.dataLoading,
    dealerUrlsData: dealerUrlsDetails.dealerUrlsData,
    siriusxmStatusData: sirisxmSubscriptionStatus.siriusxmStatusData
  };
};

export { VehicleHealthHome };
export default connect(mapStateToProps, mapDispatchToProps)(VehicleHealthHome);
