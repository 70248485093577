import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Player } from 'video-react';
import { getCwpMeta } from '../../../providers/cwpMetaProvider';
import EnrollVideo from './Enrollment';
import WifiVideo from './WifiHotSpot';
import AppTour from './AppTour';
import AppPlan from './AddPlan';
import AppSecondary from './AddSecondary';
import AddVechicle from './AddVehicle';
import CreateVWID from './CreateVWID';
import PurchaseWifi from './PurchaseWifi';
import SetUpWifi from './SetUpWifi';
import HotspotUpgrades from './HotspotUpgrades';
import SafeAndSecureCustomerStory from './SafeAndSecureCustomerStory';
import goback from '../../../assets/faq/goback.png';
import remoteStart from '../../../assets/faq/remoteStart.png';
import './Video-react.scss';

export class VideoDisplay extends Component {
  //const VideoDisplay = props => {

  state = {
    videoVisible: false
  };

  toggleopen = id => {
    this.setState({ videoVisible: !this.state.videoVisible });
  };

  componentDidMount = async () => {
    let metaResponse = await getCwpMeta();
    let videoContentURL = metaResponse.static.content.urls.videos;
    this.setState({
      videoContentURL
    });
  };

  render () {
    const historyBack = e => {
      e.preventDefault();
      e.stopPropagation();
      window && window.history && window.history.go(-1);
    };

    return (
      <div>
        <div className='goback'>
          <a href='#' onClick={historyBack}>
            <img src={goback} alt='viewfaq' width='60'></img>
          </a>
        </div>
        <div className='tutorials'>
          Car-Net <span className='faq-back-header'>Videos and Tutorials</span>
        </div>

        <table className='first-row-data'>
          <tr>
            <td className='first-row-video'>
              <a href='javascript:void(0);' onClick={this.toggleopen}>
                <img src={remoteStart} width='370px' height='210px' />
              </a>
              <span className='video-title'>Car-Net Remote Start</span>
            </td>
            <td className='first-row-video'>
              <EnrollVideo />
            </td>
            <td className='first-row-video'>
              <WifiVideo />
            </td>
          </tr>
          <tr>
            <td className='first-row-video'>
              <AppTour />
            </td>
            <td className='first-row-video'>
              <AddVechicle />
            </td>
            <td className='first-row-video'>
              <AppPlan />
            </td>
          </tr>
          <tr>
            <td className='first-row-video'>
              <PurchaseWifi />
            </td>
            <td className='first-row-video'>
              <CreateVWID />
            </td>
            <td className='first-row-video'>
              <AppSecondary />
            </td>
          </tr>
          <tr>
            <td className='first-row-video'>
              <SetUpWifi />
            </td>
            <td className='first-row-video'>
              <div>
                <HotspotUpgrades />
              </div>
            </td>
            <td className='first-row-video'>
              <div>
                <SafeAndSecureCustomerStory />
              </div>
            </td>
          </tr>
        </table>

        <Modal isOpen={this.state.videoVisible} toggle={this.toggleopen} centered={true} className='video'>
          <ModalHeader toggle={this.toggleopen}>Car-Net Remote Start</ModalHeader>
          <ModalBody>
            {this.state.videoContentURL && (
              <Player
                playsInline
                src={`${this.state.videoContentURL}/KYVW20-M2346-011-J2265_ATL_CarNet- Remote Start_031320_1230p_LLL_480p.mp4`}
              />
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default VideoDisplay;
