import React from 'react';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import driverScore from '../../../assets/tripStats/driver-score.png';
import './tripStats.scss';

const TripWiseAndTripsNotEnrolled = props => {
  let translator = getLocaleTranslator();
  let message;

  if (props.userRole === 'PU') {
    message = translator.t('trips.primaryuser_not_enroll_txt');
  } else {
    message = translator.t('trips.secoundaryuser_driveview_notenrolled_txt');
  }

  return (
    <div>
      <div className='rts-header'>
        <span className='rts-title1'>{translator.t('trips.title_label1')}</span>
        <span className='rts-title2'>{translator.t('trips.title_label2')}</span>
      </div>

      <div className='rts-permission'>{message}</div>

      <div className='rts-driveview-score'>
        <img src={driverScore} className='rts-driveview-icon' alt={translator.t('trips.driveview_score_icon')} />
      </div>
    </div>
  );
};

export default TripWiseAndTripsNotEnrolled;
