import React from 'react';
import spinner from '../../assets/images/spinner.gif';
import './Spinner.css';

const Spinner = props => {
  if (props.message) {
    return (
      <div className='cwp-loading' hidden={props.hidden}>
        {props.message}
        <br />
        <br />
        <img src={spinner} alt={props.message}></img>
        <br />
      </div>
    );
  }

  return (
    <div className='cwp-loading' hidden={props.hidden}>
      <img src={spinner} alt={props.message}></img>
      <br />
    </div>
  );
};

export default Spinner;
