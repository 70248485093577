import React from 'react';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import driverScore from '../../../assets/tripStats/driver-score.png';
import './tripStats.scss';

const FeatureNotAvailable = () => {
  let translator = getLocaleTranslator();
  return (
    <div>
      <div className='rts-header'>
        <span className='rts-title1'>{translator.t('trips.title_label1')}</span>
        <span className='rts-title2'>{translator.t('trips.title_label2')}</span>
      </div>
      <div className='rts-permission'>{translator.t('trips.feature_not_vaiable')}</div>
      <div className='rts-driveview-score'>
        <img src={driverScore} className='rts-driveview-icon' alt={translator.t('trips.driveview_score_icon')} />
      </div>
    </div>
  );
};

export default FeatureNotAvailable;
