import { createLogic } from 'redux-logic';
import axios from 'axios';

import {
  GET_CURRENT_WALLET_DETAILS,
  GET_CURRENT_WALLET_DETAILS_SUCCESS,
  GET_CURRENT_WALLET_DETAILS_FAILURE
} from '../../actions/types';

// If we want to just test w/out making an API call we can create a mock response
// import mockResponse from './__mockdata__/mockData';
let dataProvider = axios;

const getStoreCurrentWalletDetailsLogic = createLogic({
  type: GET_CURRENT_WALLET_DETAILS, // only apply this "logic" to this action type
  latest: true, // only provide the lastest response if fired off many times (though should never happen)
  processOptions: {
    dispatchReturn: true, // more auto-magic configuration, dispatch success/failure action types immediately
    successType: GET_CURRENT_WALLET_DETAILS_SUCCESS,
    failType: GET_CURRENT_WALLET_DETAILS_FAILURE
  },

  // define our async promise within a logic 'process'
  process ({ action }) {
    console.log('getStoreCurrentWalletDetailsLogic is processing an action >> ');
    console.log('type: ' + action.type);
    console.log('payload data from getStoreCurrentWalletDetailsLogic: ' + JSON.stringify(action.payload));

    // This would actually just point to our hapi server endpoint

    let requestConfig = {
      method: 'GET',
      url: `/user/store/walletdetails`,
      headers: {
        accept: 'application/json'
      },
      params: {
        cartId: action.payload === null ? '' : action.payload
      }
    };

    console.log('requestConfig is *****' + requestConfig);
    return dataProvider(requestConfig).then(response => {
      console.log('got response for getStoreCurrentWalletDetailsLogic GET request >>> ');
      console.log(JSON.stringify(response.data, null, 1));
      return response.data;
    });
  }
});

export default [getStoreCurrentWalletDetailsLogic];
