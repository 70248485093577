import React, { Component } from 'react';
import Faq from 'react-faq-component';
import '../faqCanada/faqCA.scss';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import goback from '../../../assets/faq/goback_fr.png';
import { historyBack } from './faqCAhome';
import { setTitle } from '../../../providers/documentTitleProvider';

export default class PlanFaqCA extends Component {
  componentDidMount () {
    setTitle('document_titles.faq.buy_plans');
  }
  render () {
    let translator = getLocaleTranslator();

    return (
      <div className='faqCAheader' role='main' id='main-content'>
        <div className='goback'>
          <span onClick={historyBack}>
            <img src={goback} alt='viewfaq' width='100'></img>
          </span>
        </div>
        <div className='message-header'>
          <div className='td-ms-header'>
            <h1>{translator.t('buyplan_title')}</h1>
          </div>
        </div>
        <Faq data={data} />
      </div>
    );
  }
}

const data = {
  rows: [
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          Que sont les statistiques de trajet et quelles sont les données enregistrées?{' '}
        </div>
      ),
      content: `<span class='mdlyr'>AM2022+:</span> Cette fonctionnalité d'application mobile est offerte sur les véhicules équipés d'un système d'infodivertissement MIB3. Vous pouvez utiliser la fonctionnalité de statistiques de trajet lorsque vous vous inscrivez à l’accès à distance Car-Net de Volkswagen. Elle vous permet de faire le suivi de vos trajets courts. Un « trajet » est généré à partir du moment où vous démarrez votre véhicule, et il se termine une fois que votre véhicule est demeuré en position stationnaire pendant plus de 2 heures. Lors d'un trajet, chaque fois que votre véhicule s'arrête pendant moins de 2 heures, un « arrêt » est créé. Vous pouvez consulter les états quotidiens, hebdomadaires et mensuels des « trajets » que vous avez effectués. Vous y trouverez votre consommation moyenne de carburant en litres pour 100 km (l/100 km), votre distance totale parcourue et votre vitesse moyenne en km/h. Les statistiques de trajet indiquent également vos heures de début et de fin de conduite ainsi que votre nombre d'arrêts en cours de route.`
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          Comment les données pour mes statistiques de trajet sont-elles collectées?{' '}
        </div>
      ),
      content: `<span class='mdlyr'>AM2022+:</span> Les données de votre véhicule sont collectées pour vous donner une vue d’ensemble de vos trajets. Cette fonctionnalité est offerte sur les véhicules équipés d’un système d’infodivertissement MIB3.`
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          Qui peut consulter les données de mes statistiques de trajet?{' '}
        </div>
      ),
      content: `<span class='mdlyr'>AM2022+:</span> Seuls le conducteur principal et les conducteurs supplémentaires de votre véhicule peuvent consulter les données de trajet dans l’application Car-Net de Volkswagen. Vous pouvez assigner ou supprimer des conducteurs supplémentaires en tout temps sous l’onglet de gestion des comptes de l’application mobile ou sur notre site Web à l’adresse www.vw.ca/en/innovation-and-technology/car-net.html.`
    },

    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Comment puis-je accéder au SSID et à la phrase passe de mon réseau Wi-Fi?{' '}
        </div>
      ),
      content: ` <span class='mdlyr'>AM2022+:</span> Vous trouverez le SSID et la phrase passe dans l’unité principale de votre véhicule. Il suffit d’accéder à Menu -> Réglages -> Point d’accès sans fil -> Infodivertissement pour trouver les renseignements sur votre véhicule. Ces renseignements ne sont pas accessibles dans l’application Car-Net de Volkswagen.`
    }
  ]
};

export { PlanFaqCA };
