import React, { useState } from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';

import Axios from 'axios';

import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { findIn } from '../../../util/utilityMethods';
import InsuranceMessagePanel from './insuranceMessagePanel';

const submitConsentToProgram = programId => {
  return Axios.post(`/driveview/offers/${programId}/consent`);
};

const InsuranceMessages = props => {
  const [pendingOfferOfInterest, setPendingOffer] = useState(null);
  const [errorMsg, setErrorMessage] = useState(null);
  const [inProgress, setInProgress] = useState(null);

  const translator = getLocaleTranslator();

  const doNothing = () => {
    setPendingOffer(null);
  };

  const doStuff = async e => {
    // send http rquest
    let consentResponse = await submitConsentToProgram(pendingOfferOfInterest.programId);
    let programUrl = findIn('data.programUrl', consentResponse) || null;
    setInProgress(true);
    if (programUrl) {
      setPendingOffer(null);
      setInProgress(false);
      window.open(programUrl);
    } else {
      setInProgress(false);
      setPendingOffer(null);
      setErrorMessage({
        title: translator.t('driveview.insurancemessages.modal.title-error'),
        body: translator.t('driveview.insurancemessages.modal.body-error')
      });
    }
  };

  const clearErrorMessage = () => {
    setErrorMessage(null);
  };

  let ads = (Array.isArray(props.ads) && props.ads.length && props.ads) || null;
  let offers = (Array.isArray(props.offers) && props.offers.length && props.offers) || null;

  let carrierName =
    (pendingOfferOfInterest &&
      pendingOfferOfInterest.carrierName &&
      ` ${pendingOfferOfInterest.carrierName} ${translator.t('driveview.insurancemessages.modal.and')} `) ||
    '';

  return (
    <div>
      <Modal isOpen={!!pendingOfferOfInterest} className='carnet-sg-modal driveViewInsOffer'>
        <ModalHeader>{translator.t('driveview.insurancemessages.modal.title-confirmation')}</ModalHeader>
        <ModalBody>{translator.t('driveview.insurancemessages.modal.body-confirmation', { carrierName })}</ModalBody>
        <ModalFooter>
          <div>
            <Button
              disabled={inProgress}
              className='modal-primary-btn'
              color='modal_background_color'
              onClick={doStuff}
            >
              {translator.t('driveview.insurancemessages.modal.buttons.yes')}
            </Button>
          </div>
          <div>
            <Button
              disabled={inProgress}
              className='modal-secondary-btn'
              color='modal_background_color'
              onClick={doNothing}
            >
              {translator.t('driveview.insurancemessages.modal.buttons.no')}
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      <Modal isOpen={!!errorMsg} className='carnet-sg-modal driveViewInsOffer'>
        <ModalHeader>{errorMsg && errorMsg.title}</ModalHeader>
        <ModalBody>{errorMsg && errorMsg.body}</ModalBody>
        <ModalFooter>
          <div>
            <Button className='modal-primary-btn' color='modal_background_color' onClick={clearErrorMessage}>
              {translator.t('driveview.insurancemessages.modal.buttons.ok')}
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      <div id='snapShotHeading' className='driveViewComponentSectionHeading'>
        {translator.t('driveview.insurancemessages.heading')}
      </div>
      <div class='driveViewComponentInsuranceMessagesInfo'>
        {offers &&
          offers.map(
            (offer, index) =>
              offer.programs &&
              offer.programs.map((program, index) => {
                // add the carrierName to the program on the fly
                let programData = { ...program };
                programData['carrierName'] = offer['carrierName'] || '';
                return <InsuranceMessagePanel callback={setPendingOffer} program={programData} />;
              })
          )}
      </div>
      <div class='driveViewComponentInsuranceMessagesInfo'>
        {ads && ads.map((ad, index) => <InsuranceMessagePanel ad={ad} />)}
      </div>
      <br />
      <br />
    </div>
  );
};

export default InsuranceMessages;
