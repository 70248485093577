import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import carnet_constants from '../../../constants/carnet_constants';
import { bindActionCreators } from 'redux';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { isNullOrEmptyObject } from '../../../util/utilityMethods';
import { Row, Col, Button } from 'reactstrap';
import ThemedCheckBox from '../../reusable_cmp_lib/ThemedCheckBox/ThemedCheckBox';
import ManageAlertContactsModal from '../common/ManageAlertContactsModal';
import '../../reusable_cmp_lib/ThemedButtonsAssortment/ThemedButtonsAssortment.scss';
import './AccountManagement.scss';

class ManageAlerts extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      editingField: null,
      propsLoaded: false,
      stateUpdated: false,
      formDirty: false,
      sendToDealer: true,
      recipientEmails:
        (this.props.accountDetails &&
          this.props.accountDetails.customer &&
          this.props.accountDetails.customer.emails) ||
        [],
      recipientPhones:
        (this.props.accountDetails &&
          this.props.accountDetails.customer &&
          this.props.accountDetails.customer.phones) ||
        [],
      recipientIds: [],
      recipientPrefs: {},
      contactsModalVisible: false
    };
    this.getActiveEditingPane = this.getActiveEditingPane.bind(this);
  }

  static getDerivedStateFromProps (nextProps, curState) {
    if (curState.propsLoaded) {
      return;
    }
    //(both ARE objects (or null))
    if (isNullOrEmptyObject(nextProps.vhrPrefIds)) {
      return;
    }
    if (
      isNullOrEmptyObject(nextProps.accountDetails) ||
      isNullOrEmptyObject(nextProps.accountDetails.customer) ||
      nextProps.accountDetails.customer.emails === null
    ) {
      return;
    }
    let localCbState = [];
    let alertDealer = false;
    alertDealer = nextProps.vhrPrefIds && nextProps.vhrPrefIds.sendToDealer === 'Y';
    let checkedIds = (nextProps.vhrPrefIds && nextProps.vhrPrefIds.emailIds) || [];
    let emailsList = nextProps.accountDetails.customer.emails || [];
    let contactsPhonesList =
      (nextProps.accountDetails && nextProps.accountDetails.customer && nextProps.accountDetails.customer.phones) || [];

    emailsList.forEach(emailDatum => {
      let isChecked = checkedIds.includes(emailDatum.id);
      let localRecord = {
        email: emailDatum.email,
        id: emailDatum.id,
        checked: isChecked,
        primary: emailDatum.primary
      };
      localCbState.push(localRecord);
    });

    let nxState = {
      sendToDealer: alertDealer,
      recipientEmails: localCbState,
      recipientPhones: contactsPhonesList,
      propsLoaded: true
    };
    return nxState;
  }

  componentDidMount () {
    this.props.actions.getVHRCustomerPreferences();
  }

  handleExitEditMode = () => {
    this.setState({ editingField: null });
  };

  editField = reference => {
    console.log('editField() invoked for ref: ' + reference);
    this.props.editingCallback(reference);
  };

  getActiveEditingPane () {
    if (this.state.editingField === null) {
      return <span></span>;
    }
  }

  setFormDirtyStatus = status => {
    this.setState({
      formDirty: status
    });
  };

  reloadPageData = () => {
    this.props.actions.getVHRCustomerPreferences();
  };

  saveContactsChanges = () => {
    const finalContacts = JSON.parse(JSON.stringify(this.state.recipientEmails));
    const finalSTDFlag = this.state.sendToDealer === true ? 'Y' : 'N';
    let emailIds = [];
    finalContacts.forEach(record => {
      if (record.checked) {
        if (record.id) {
          emailIds.push(record.id); //Modal-added emails will not have an id yet
        }
      }
    });
    const savePayload = {
      sendToDealer: finalSTDFlag,
      emailIds: emailIds
    };

    this.props.actions.updateVHRCustomerPreferences(savePayload);
    //you need to add a Save operation for the newly-added emails and phones
    const editedContactsEmails = JSON.parse(JSON.stringify(this.state.recipientEmails));
    const editedContactsPhones = JSON.parse(JSON.stringify(this.state.recipientPhones));
    //merge these into the mutable accountDetail:
    let baseAccountDetailCustomer = JSON.parse(JSON.stringify(this.props.accountDetails.customer)); //(Deep Clone)
    baseAccountDetailCustomer.phones = editedContactsPhones;
    baseAccountDetailCustomer.emails = editedContactsEmails;
    this.props.editsSaveHandler(baseAccountDetailCustomer);
    this.setFormDirtyStatus(false);
  };

  handleCheckBoxToggling = (tag, evt) => {
    let checkedState = evt.target.checked;

    if (tag === 'pref_dealer') {
      this.setState({
        sendToDealer: checkedState,
        formDirty: true
      });
      return;
    }
    let contactEmails = JSON.parse(JSON.stringify(this.state.recipientEmails));
    let indexStr = tag.split('_')[1];
    let index = parseInt(indexStr);
    contactEmails[index].checked = checkedState;
    this.setState({
      recipientEmails: contactEmails,
      formDirty: true
    });
  };

  showContactsModal = shown => {
    this.setState({
      contactsModalVisible: shown
    });
  };

  showEdits = (adjustedEmails, adjustedPhones) => {
    this.setState({
      recipientEmails: adjustedEmails,
      recipientPhones: adjustedPhones,
      formDirty: true
    });
  };

  updatePreferences = newContacts => {
    if (!newContacts) {
      return;
    }

    this.setState({
      recipientEmails: newContacts
    });
  };

  checkstatesDataFromPropState = () => {
    if (
      isNullOrEmptyObject(this.props.vhrPrefIds) ||
      isNullOrEmptyObject(this.props.vhrPrefData) ||
      isNullOrEmptyObject(this.props.vhrPrefData.emails)
    ) {
      return [];
    }
    const checkedEmailIds = this.props.vhrPrefIds.emailIds;
    const allEmailsData = this.props.vhrPrefData.emails;
    let checkstatesData = allEmailsData.map(emailRecord => {
      let isEmailChecked = checkedEmailIds.includes(emailRecord.id);
      return { email: emailRecord.email, isPrime: emailRecord.primary, checked: isEmailChecked };
    });
    return checkstatesData;
  };

  render () {
    this.translator = getLocaleTranslator();
    let rawPhonesList = JSON.parse(JSON.stringify(this.state.recipientPhones));
    let checkstatesData = this.state.formDirty ? this.state.recipientEmails : this.checkstatesDataFromPropState();

    return (
      <div className='acctmgmt-contentpane'>
        <div id='ghost-wrapper' class='vehicle-info-parent'>
          <div className='contentpane-title'>
            <header>{this.translator.t('acctmgmt_alerts_pane_title')}</header>
          </div>
          <div>
            <Row className='borderless'>
              <Col md='2'>
                <div class='vertical-spacer'></div>
                <span class='carnet-theme-field-label'>
                  {/* Vehicle Alerts */}
                  {this.translator.t('acctmgmt_alerts_vehicle_label')}
                </span>
              </Col>
              <Col md='3'>
                {this.state.contactsModalVisible && (
                  <ManageAlertContactsModal
                    currentContacts={this.state.recipientEmails}
                    anyContactsPhones={rawPhonesList}
                    modalShowHandler={this.showContactsModal}
                    open={true}
                    saveEdits={this.showEdits}
                  />
                )}
              </Col>
              <Col md='4'>
                <div id='health-report-indicator-aligner'>
                  <span id='health-report-indicator'>{this.translator.t('acctmgmt_alerts_send_to_label')}</span>
                </div>
              </Col>
              <Col md='3'></Col>
            </Row>

            <Row className='borderless'>
              <Col md='5'></Col>
              <Col md='4'>
                <ThemedCheckBox
                  toggleHandler={evt => this.handleCheckBoxToggling('pref_dealer', evt)}
                  name='preferred_dlr_recipient'
                  id='recipient_chkbox_pref_dealer'
                  label='Preferred Dealer'
                  isChecked={this.props.doSendToDealer}
                />
              </Col>
              <Col md='3'></Col>
            </Row>

            {checkstatesData.map((email, index) => {
              return (
                <Row className='borderless'>
                  <Col md='5'></Col>
                  <Col md='4'>
                    <ThemedCheckBox
                      toggleHandler={evt => this.handleCheckBoxToggling('selections_' + index, evt)}
                      name={'report_recipients_' + (index + 1)}
                      id='recipient_chkbox_custom_1'
                      label={email.email}
                      isChecked={email.checked}
                    />
                  </Col>
                  <Col md='3'></Col>
                </Row>
              );
            })}

            <Row>
              <Col md='12'></Col>
            </Row>
            <Row className='borderless'>
              <Col md='6'>
                <div>
                  <br />
                  <Button
                    className='cwp'
                    color='primary'
                    onClick={() => {
                      this.showContactsModal(true);
                    }}
                  >
                    {this.translator.t('acctmgmt_alerts_edit_btn')}
                  </Button>{' '}
                </div>
              </Col>
              <Col md='3'>
                <div>
                  <br />
                  <Button
                    className='cwp'
                    onClick={this.saveContactsChanges}
                    disabled={this.state.formDirty === false}
                    color='primary'
                  >
                    {this.translator.t('save_btn_camel')}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getVHRCustomerPreferences: actions.getVHRCustomerPreferences,
        updateVHRCustomerPreferences: actions.updateVHRCustomerPreferences
      },
      dispatch
    )
  };
};

const mapStateToProps = state => {
  const { vhrCustomerPreferences, updateVHRCustomerPreferences } = state;
  console.log(':::::::::');
  console.log('insside mapStateToProps(), the rcvd vhrCustomerPrefs object is: ');
  console.log(JSON.stringify(vhrCustomerPreferences));
  console.log(':::::::::::::');

  let s2dFlag =
    (vhrCustomerPreferences && vhrCustomerPreferences.vhrPrefIds && vhrCustomerPreferences.vhrPrefIds.sendToDealer) ||
    'Y';
  let s2dValue = s2dFlag === 'Y';
  return {
    vhrPrefIds: vhrCustomerPreferences.vhrPrefIds,
    doSendToDealer: s2dValue,
    vhrPrefData: vhrCustomerPreferences.vhrPrefData,
    prefData: updateVHRCustomerPreferences.prefData
  };
};

export { ManageAlerts };
export default connect(mapStateToProps, mapDispatchToProps)(ManageAlerts);
