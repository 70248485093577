import axios from 'axios';

const twoWaySend = async requestConfig => {
  if (!requestConfig || !requestConfig.url) return;

  if (!requestConfig.method) requestConfig.method = 'GET';

  if (!requestConfig.headers) requestConfig.headers = {};

  if (!requestConfig.headers.accept) requestConfig.headers.accept = 'application/json';

  console.log(
    `[AccountAndSecurityProvider]: Invoking middleman endpoint [${requestConfig.method}]:${requestConfig.url}`
  );
  try {
    let response = await axios(requestConfig);
    if (response && response.data) {
      return response.data;
    } else if (response) {
      return response;
    } else {
      console.error('***** No Response rcvd back from client provider request ');
    }
  } catch (err) {
    console.error(
      `[AccountAndSecurityProvider]: Error while invoking URL: [${requestConfig.method}] ${
        requestConfig.url
      } RESPONSE - ${JSON.stringify(err)}`
    );

    return Promise.reject(err);
  }

  return Promise.reject('Unknown error occured while making a request to backend.');
};

const oneWaySend = async requestConfig => {
  if (!requestConfig || !requestConfig.url) return;

  if (!requestConfig.method) requestConfig.method = 'GET';

  if (!requestConfig.headers) requestConfig.headers = {};

  if (!requestConfig.headers.accept) requestConfig.headers.accept = 'application/json';

  console.log(
    `[AccountAndSecurityProvider]: Invoking middleman endpoint [${requestConfig.method}]:${requestConfig.url}`
  );
  try {
    let response = await axios(requestConfig);

    if (response) {
      const statusCode = response.statusCode;
      const statusMsg = response.statusText;
      return { status: statusCode, msg: statusMsg };
    }
  } catch (err) {
    console.log(
      `[AccountAndSecurityProvider]: Error while invoking URL: [${requestConfig.method}] ${
        requestConfig.url
      } RESPONSE - ${JSON.stringify(err)}`
    );

    return Promise.reject(err);
  }

  return Promise.reject('Unknown error occured while making a request to backend.');
};

const triggerPasswordResetEmail = async resetData => {
  return await oneWaySend({
    url: '/user/resetpass',
    method: 'POST',
    data: resetData
  });
};

const getEnrollmentStatus = async () => {
  return await twoWaySend({ url: '/garage/getEnrollmentStatus' });
};

const getTosVersionsMarketingConsent = async () => {
  return await twoWaySend({ url: '/getMarketingConsentTosVersionStrings' });
};

const getTosEnrollmentsMarketingConsent = async () => {
  return await twoWaySend({ url: '/fetchMarketingManifestEntries' });
};

const updateTosEnrollmentsMarketingConsent = async marketingCheckstates => {
  return await oneWaySend({
    url: 'update/mc',
    method: 'POST',
    data: marketingCheckstates
  });
};

const getContextVin = async () => {
  return await twoWaySend({ url: '/contextVin' });
};

const validatePIN = async spinRequest => {
  console.log('YOUR OWN AcctSecurityProvider hit...validatePIN()....the Request data passed in is: ');
  console.log(JSON.stringify(spinRequest));
  return await twoWaySend({
    url: '/garage/validateSpin',
    method: 'POST',
    data: spinRequest
  });
};

const updatePIN = async updateRequest => {
  return await twoWaySend({
    url: '/garage/updatePin',
    method: 'POST',
    data: updateRequest
  });
};

const saveAdditionalDriver = async saveRequest => {
  return await oneWaySend({
    url: '/acct/adddrivers',
    method: 'POST',
    data: saveRequest
  });
};

const saveCarNickName = async saveRequest => {
  return await oneWaySend({
    url: '/acct/carnickname',
    method: 'POST',
    data: saveRequest
  });
};

const getDriveViewEnrollmentPrefs = async () => {
  return await twoWaySend({ url: '/acct/ubiprefs' });
};

const getDefaultLocales = async () => {
  console.log('about to request out to ur locales route...');
  return await twoWaySend({ url: '/acct/locales' });
};

const getStatesOrProvincesByCountry = async iso2LetterCountry => {
  if (!iso2LetterCountry) {
    return Promise.reject('The 2 letter ISO country code must be provided');
  }
  return await twoWaySend({ url: `/acct/statesorprovs?countryCode=${iso2LetterCountry}` });
};

const saveDriveViewEnrollmentPrefs = async prefs => {
  return await oneWaySend({
    url: '/acct/saveubiprefs',
    method: 'POST',
    data: prefs
  });
};

const unenrollFromConciergePilot = async tosVersion => {
  return await oneWaySend({
    url: '/unenroll/vcs',
    method: 'POST',
    data: { version: tosVersion }
  });
};

const standaloneEnrollConciergePilot = async enrollmentPhoneRecord => {
  return await oneWaySend({
    url: 'enroll/vcs',
    method: 'POST',
    data: enrollmentPhoneRecord
  });
};

const saveNewPrefDealer = async dealerId => {
  return await twoWaySend({
    url: 'prefdealer/update',
    method: 'POST',
    data: { dealerId }
  });
};

const getDeeplinkingUrlPopulated = async () => {
  return await twoWaySend({
    url: 'acct/deeplinkUrl'
  });
};

const getUserVehicleRolesAndRights = async () => {
  return await twoWaySend({
    url: '/privileges'
  });
};

const searchDealersByZipAndRange = async criteria => {
  return await twoWaySend({
    url: '/dealersearch',
    method: 'POST',
    data: criteria
  });
};

const deleteDriverFromVehicle = async theDriverId => {
  let payload = {
    driverId: theDriverId
  };

  return await twoWaySend({
    url: '/acct/deletedriver',
    method: 'POST',
    data: payload
  });
};

const formulateTheUsernameResetUrl = async () => {
  return await twoWaySend({ url: '/changeusername/step1' });
};

const updateSecondaryUserPriveleges = async data => {
  return await oneWaySend({ url: '/acct/secondary/update', method: 'POST', data });
};

const deleteDevicePairing = async data => {
  return await twoWaySend({ url: '/deletepairing', method: 'POST', data });
};

const getVZTAccountNumber = async () => {
  return await twoWaySend({ url: '/getVztAccount' });
};

const getVZTVehicleSubscriptions = async () => {
  return await twoWaySend({ url: '/vztsubs' });
};

// CHAMBERLAIN!!
const startChamberlainTrial = async () => {
  return await twoWaySend({
    url: '/estore/purchase/zerocost/article',
    method: 'PUT',
    data: {
      subCategoryId: 'GARAGECONTROL_TRIAL'
    }
  });
};

const getChamberlainCapabilityAndStatus = async () => {
  return await twoWaySend({
    url: `/chamberlain/status/vehicle`
  });
};

const linkChamberlainAccount = async (data = { endUrl: 'acct-mgmt/thirdparty' }) => {
  return await twoWaySend({
    url: 'chamberlain/link',
    method: 'POST',
    data
  });
};

const unLinkChamberlainAccount = async () => {
  return await twoWaySend({
    url: 'chamberlain/unlink',
    method: 'POST'
  });
};

//  find zerocost eligibility
const getZeroCostEligibility = async subCategory => {
  return await twoWaySend({
    url: `/estore/subscription/zerocost/eligibility?subCategory=${subCategory}`,
    method: 'GET'
  });
};

const enrollZeroCostArticle = async subCategoryId => {
  return await twoWaySend({
    url: '/estore/purchase/zerocost/article',
    method: 'PUT',
    data: { subCategoryId }
  });
};

//  All constants listed below are referred from service specification URL https://vwgoa.atlassian.net/wiki/spaces/VC/pages/752261444/Customer+Account+Enrollment+Service#CustomerAccount%26EnrollmentService-GetExternalStatus
const CapablityStatus = Object.freeze({
  AVAILABLE: 'AVAILABLE',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  NOT_APPLICABLE: 'NOT_APPLICABLE'
});

const SubscriptionStatus = Object.freeze({
  AVAILABLE: 'AVAILABLE',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  NOT_APPLICABLE: 'NOT_APPLICABLE'
});

const AccessibilityStatus = Object.freeze({
  ACCESSIBLE: 'ACCESSIBLE',
  NOT_ACCESSIBLE: 'NOT_ACCESSIBLE',
  NOT_APPLICABLE: 'NOT_APPLICABLE'
});

const LinkingStatus = Object.freeze({
  Active: 'Active',
  Inactive: 'Inactive'
});

const ValetMode = Object.freeze({
  Active: 'Active',
  Inactive: 'Inactive'
});

export {
  CapablityStatus,
  SubscriptionStatus,
  AccessibilityStatus,
  LinkingStatus,
  ValetMode,
  triggerPasswordResetEmail,
  getDeeplinkingUrlPopulated,
  searchDealersByZipAndRange,
  saveCarNickName,
  getEnrollmentStatus,
  validatePIN,
  updatePIN,
  saveAdditionalDriver,
  deleteDriverFromVehicle,
  getContextVin,
  formulateTheUsernameResetUrl,
  getDriveViewEnrollmentPrefs,
  saveDriveViewEnrollmentPrefs,
  standaloneEnrollConciergePilot,
  unenrollFromConciergePilot,
  getDefaultLocales,
  getTosEnrollmentsMarketingConsent,
  getTosVersionsMarketingConsent,
  updateTosEnrollmentsMarketingConsent,
  saveNewPrefDealer,
  getStatesOrProvincesByCountry,
  updateSecondaryUserPriveleges,
  getUserVehicleRolesAndRights,
  deleteDevicePairing,
  getVZTAccountNumber,
  getVZTVehicleSubscriptions,
  startChamberlainTrial,
  getChamberlainCapabilityAndStatus,
  linkChamberlainAccount,
  unLinkChamberlainAccount,
  getZeroCostEligibility,
  enrollZeroCostArticle
};
