import React from 'react';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { isBlankString, isNullOrEmptyObject, isNonEmptyObject } from '../../../util/utilityMethods';
import { getVZTAccountNumber, getVZTVehicleSubscriptions } from '../providers/AccountAndSecurityProvider';
import { isUserPrimary } from '../../../providers/userRolesProvider';
import { Row, Col } from 'reactstrap';
import { getFormattedDateFromEpochMs } from '../common/commonDateUtil';
import Loader from '../common/loader';
import '../../reusable_cmp_lib/ThemedFormInputs/ThemedForm.scss';
import '../../reusable_cmp_lib/ThemedButtonsAssortment/ThemedButtonsAssortment.scss';
import './AccountManagement.scss';
import './SubscriptionInfo.scss';

// props.primaryUser
class SubscriptionInfo extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      subscriptions: [],
      vztAccountNumber: null,
      loadingAccountNumber: false,
      loadingSubs: false
    };
  }

  componentDidMount () {
    const ctxVehicleId = this.props.contextVehicleId;
    this.setState({
      loadingAccountNumber: true,
      loadingSubs: true
    });
    this.loadVztAccountNumber();
  }

  loadVztAccountNumber = async () => {
    let vztAcctInfo = await getVZTAccountNumber();
    if (isNullOrEmptyObject(vztAcctInfo)) {
      //flag a failed load here
      console.error('Null or empty object returned from cliside getVZTAccountNumber() now (data)');
      return null;
    }
    let acctNumber = vztAcctInfo.accountNumber;

    if (!isBlankString(acctNumber)) {
      this.setState(
        {
          vztAccountNumber: acctNumber,
          loadingAccountNumber: false
        },
        () => {
          this.loadSubscriptionsData();
        }
      );
    }
  };

  loadSubscriptionsData = async () => {
    let subscriptionsData = await getVZTVehicleSubscriptions();
    if (isNonEmptyObject(subscriptionsData) && subscriptionsData.data) {
      console.log('Here are the LOADED SUBS:  ');
      console.log(JSON.stringify(subscriptionsData.data));
      this.setState({
        subscriptions: subscriptionsData.data,
        loadingSubs: false
      });
    }
  };

  componentDidUpdate (prevProps = {}) {
    if (JSON.stringify(prevProps) == JSON.stringify(this.props)) {
      return;
    }
    if (this.props.storePlansInfo) {
      let subbedPlans = [];
      let marketingConfigurations =
        (this.props.marketingInfo &&
          this.props.marketingInfo.configurations &&
          Array.isArray(this.props.marketingInfo.configurations) &&
          this.props.marketingInfo.configurations) ||
        [];

      this.props.storePlansInfo.subscribedPlans.forEach(planThing => {
        let legitStatus = planThing && ('' + planThing.status).toLowerCase() === 'active';
        let expiryEpoch = planThing && planThing.expirationDate;
        let fmtExpirationDate = expiryEpoch ? getFormattedDateFromEpochMs(expiryEpoch) : '';

        let legitViewable = planThing && ('' + planThing.viewable).toLowerCase() === 'true';
        let name = legitStatus && legitViewable && planThing.package && planThing.package.name;
        let marketingConfigId = planThing.marketingConfigurationId;
        if (!isBlankString(marketingConfigId)) {
          let matchingConfigs = marketingConfigurations.filter(config => {
            return config.configurationId === marketingConfigId;
          });
          let matchingConfig = matchingConfigs[0];
          if (matchingConfig) {
            if (matchingConfig.title) {
              name = matchingConfig.title;
            }
          }
        }

        let currentRecord = {
          planName: name,
          expDate: fmtExpirationDate
        };
        subbedPlans.push(currentRecord);
      });
      this.setState({
        subscriptions: subbedPlans
      });
    }
  }

  render () {
    this.translator = getLocaleTranslator();
    const driverIsPrimary = isUserPrimary();
    let loadedSubscriptions = this.state.subscriptions || [];

    return (
      <div className='acctmgmt-contentpane'>
        <div id='ghost-wrapper' className='vehicle-info-parent'>
          <div className='contentpane-title'>
            <header id='oneoff-deviant-header-style'>{this.translator.t('acctmgmt_carnet_subs_label')}</header>
          </div>
          <div>
            <Row>
              <Col md='2'>
                <span className='carnet-theme-field-label'>{this.translator.t('account_title')}</span>
              </Col>
              <Col md='2'></Col>
              <Col md='6'>
                {this.state.loadingAccountNumber === true ? (
                  <Loader />
                ) : (
                  <span>{this.state.vztAccountNumber || ''} </span>
                )}
              </Col>
              <Col md='2'></Col>
            </Row>
            <Row>
              <Col md='2'>
                <span className='carnet-theme-field-label'>{this.translator.t('acctmgmt_subs_label')}</span>
              </Col>
              <Col md='2'></Col>

              {this.state.loadingSubs === true ? (
                <Col md='8'>
                  <Loader />
                </Col>
              ) : (
                <Col md='8'>
                  {loadedSubscriptions.map(sub => {
                    return (
                      <div>
                        <div>{sub.name}</div>
                        <br />
                        {sub.subscriptionEndDate && (
                          <div>{this.translator.t('acctmgmt_subs_expiry_label') + '  ' + sub.subscriptionEndDate}</div>
                        )}
                      </div>
                    );
                  })}
                </Col>
              )}
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default SubscriptionInfo;
