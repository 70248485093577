import {
  GET_VEHICLE_HEALTH_BY_SERVICEKEY,
  GET_VEHICLE_HEALTH_BY_SERVICEKEY_SUCCESS,
  GET_VEHICLE_HEALTH_BY_SERVICEKEY_FAILURE
} from '../../actions/types';

const initalState = {
  vhrData: null,
  dataLoading: false
};

const vehicleHealthByServiceKeyReducer = (state = initalState, action = { payload: {} }) => {
  let newState = { ...state };
  let actionType = (action && action.type) || 'default';
  let responseData = action.payload || {};

  switch (actionType) {
    case GET_VEHICLE_HEALTH_BY_SERVICEKEY:
      newState = { ...initalState };
      return newState;

    case GET_VEHICLE_HEALTH_BY_SERVICEKEY_SUCCESS:
      let { data } = responseData;
      newState['vhrData'] = data || null;
      newState['dataLoading'] = true;
      return newState;
    case GET_VEHICLE_HEALTH_BY_SERVICEKEY_FAILURE:
      newState = { ...initalState };
      return newState;
    default:
      return newState;
  }
};

export default vehicleHealthByServiceKeyReducer;
