import Axios from 'axios';

import { findIn } from '../util/utilityMethods';

const INVALID_EMAIL_FORMAT = 'INVALID_EMAIL_FORMAT';
const EMAIL_VALIDATION_FAILURE = 'EMAIL_VALIDATION_FAILED';

const validateEmail = async (emailAddressToValidate = '') => {
  return new Promise(async (resolve, reject) => {
    if (!emailAddressToValidate || !emailAddressToValidate.match(/^\S+@\S+\.[\S]+$/gi)) {
      console.log('email address provided to emailValidationProvider is invalid');
      return reject(INVALID_EMAIL_FORMAT);
    }

    let email = emailAddressToValidate;
    let axioConfig = {
      url: 'validation/email',
      method: 'POST',
      data: {
        email
      }
    };

    let emailValidationResult = await Axios(axioConfig);

    let responseData = emailValidationResult && emailValidationResult.data;
    let status = responseData && findIn('emailValidation.status', responseData);

    if (responseData) {
      if (status && status.toLowerCase() === 'invalid') {
        return reject(EMAIL_VALIDATION_FAILURE);
      }
      if (status) {
        return resolve(status);
      }
    }

    reject(EMAIL_VALIDATION_FAILURE);
  });
};

export { validateEmail };
