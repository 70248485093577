import React from 'react';
import { BuyPlansContext } from '../BuyPlansContext';
import { PurchaseBlockReason, findArticleDisclaimerText, isNotPurchasableOrRestricted } from '../buyPlansUtil';
import { Link } from 'react-router-dom';
import MarketingProducts from '../components/MarketingProducts';
import { UncontrolledAlert } from 'reactstrap';
import PackagePricing from '../components/PackagePricing';
import ModalPurchaseBlocked from '../components/ModalPurchaseBlocked';
import { addToCart } from '../../providers/EstoreProvider';
import { findErrorMessage } from '../../../../util/i18n/i18nService';

export default class DataPrePaid extends React.Component {
  static contextType = BuyPlansContext;

  state = {
    alertMessage: null,
    articleDisclaimer: null,
    showModalPurchaseBlock: false,
    cartItem: null
  };

  componentDidMount () {
    this.initData();
  }

  initData = async () => {
    let plan = this.context?.selectedPlan;

    if (!plan) {
      const marketingConfigurationId = this.props.match?.params?.marketingConfigurationId;
      const { additionalPlans, selectPlanFromList } = this.context;

      const [, category, subCategory] = this.props.match?.url?.split('/') || [];
      plan = selectPlanFromList(additionalPlans, category, subCategory, marketingConfigurationId);
    }

    const marketingConfiguration = this.context.marketingConfigurationHash?.[plan?.marketingConfigurationId];

    const articleDisclaimer = findArticleDisclaimerText(marketingConfiguration);
    const consentCheckbox = marketingConfiguration?.consentCheckbox;

    const linksToVisit = new Set();
    const tosStatusMap = {};
    if (Array.isArray(consentCheckbox)) {
      for (const cc of consentCheckbox) {
        tosStatusMap[cc.consentCheckboxNumber] = null;

        if (Array.isArray(cc.consentCheckboxUrls)) {
          cc.consentCheckboxUrls
            .filter(element => element?.ConsentCheckboxURL)
            .forEach(element => linksToVisit.add(element.ConsentCheckboxURL));
        }
      }
    }

    this.setState({ articleDisclaimer, tosStatusMap, linksToVisit: Array.from(linksToVisit) });

    window.scrollTo(0, 0);
  };

  clearAlerts = () => this.setState({ alertMessage: null });

  getAlerts = () => {
    if (this.state.alertMessage) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts} aria-live='polite' className='w-100'>
          {this.state.alertMessage}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  setCartItem = async cartItem => {
    let plan = this.context?.selectedPlan;

    if (isNotPurchasableOrRestricted(plan) && plan.purchaseBlockReason === PurchaseBlockReason.ACTIVATE_TRIAL_FIRST) {
      this.setState({ showModalPurchaseBlock: true, cartItem });

      return;
    }

    this.addToCart(cartItem);
  };

  addToCart = async (cartItem = this.state.cartItem) => {
    try {
      await this.context.addToCart(cartItem);
    } catch (error) {
      console.log(error.message || error);
      this.setState({ alertMessage: findErrorMessage(error) || error.message || error });
    }
  };

  hideModalPurchaseBlock = () => this.setState({ showModalPurchaseBlock: false });

  render () {
    const {
      translator,
      contentURL,
      showDisclaimer,
      selectPlan,
      selectedPlan: plan,
      marketingConfigurationHash
    } = this.context;
    const marketingConfiguration = marketingConfigurationHash?.[plan?.marketingConfigurationId] || {};

    return (
      <div role='main' id='main-content' className='py-4'>
        <div className='ml-4 position-absolute'>
          <Link to='/' className='linkBack' role='button' onClick={() => selectPlan(null)}>
            {translator.t('buyplans.button-label.back-to-your-plans')}
          </Link>
        </div>
        <div className='container d-flex flex-column align-items-center text-center mt-4 pb-5'>
          <h1 className='font-weight-light'>
            {marketingConfiguration?.currentPlanContent?.title || marketingConfiguration?.title}
            {this.state.articleDisclaimer && (
              <sup
                className='cwp-disclaimer ml-2 mainTitlePosition'
                tabIndex={0}
                role='button'
                onClick={e =>
                  showDisclaimer(
                    this.state.articleDisclaimer,
                    marketingConfiguration.articleDisclaimerNumber,
                    e.currentTarget
                  )
                }
              >
                {marketingConfiguration?.articleDisclaimerNumber}
              </sup>
            )}
          </h1>

          <article className='mt-2 mb-5 text-muted text-center'>
            {marketingConfiguration?.description} {marketingConfiguration?.additionalDescription}
          </article>
          {this.getAlerts()}

          {plan && (
            <PackagePricing
              showAlert={errorMessage => this.setState({ alertMessage: errorMessage })}
              onClickAddToCart={this.setCartItem}
            />
          )}
        </div>
        {plan && (
          <MarketingProducts
            contentURL={contentURL}
            plan={plan}
            marketingConfiguration={marketingConfiguration}
            showDisclaimer={this.context.showDisclaimer}
          />
        )}

        {this.state.showModalPurchaseBlock && (
          <ModalPurchaseBlocked
            translator={translator}
            purchaseBlockReason={plan.purchaseBlockReason}
            isOpen={this.state.showModalPurchaseBlock}
            onClickClose={this.hideModalPurchaseBlock}
            onClickPrimary={() => {
              this.hideModalPurchaseBlock();
              this.addToCart();
            }}
            onClickSecondary={this.hideModalPurchaseBlock}
          />
        )}
      </div>
    );
  }
}
