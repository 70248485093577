import React, { useState, useEffect } from 'react';

import infoIcon from '../../../assets/icons/info.svg';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import './tool-tip.scss';

function ToolTip (props) {
  const [isVisible, showToolTip] = useState(false);
  const translator = getLocaleTranslator();
  return (
    <div className='toolTipWrapper'>
      <div id='activeToolTipBubble' className={isVisible ? 'hoverState show' : 'hoverState'} aria-live={'polite'}>
        <div className='bubble'>
          <span>{props.hoverText}</span>
        </div>
        <div className='triangle-down'></div>
      </div>
      <div className='info-icon'>
        <img
          tabIndex={0}
          src={infoIcon}
          alt={translator.t('your_information.information')}
          width={24}
          onMouseEnter={showToolTip.bind(null, true)}
          onMouseLeave={showToolTip.bind(null, false)}
          onFocus={showToolTip.bind(null, true)}
          onBlur={showToolTip.bind(null, false)}
        />
      </div>
    </div>
  );
}

export default ToolTip;
