import React from 'react';
import PropTypes from 'prop-types';
import ReviewArticle from './ReviewArticle';
import { BuyPlansContext } from '../BuyPlansContext';
import Spinner from '../../Spinner';
import { maskPaymentCardNumber } from '../buyPlansUtil';

export default class Review extends React.Component {
  static contextType = BuyPlansContext;

  static propTypes = {
    showAlert: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    card: PropTypes.object.isRequired,
    placeOrder: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  componentDidMount () {
    window.scrollTo(0, 0);
  }

  render () {
    const { translator, contentURL, marketingConfigurationHash, loading } = this.context;
    const items = this.props.items || [];

    return (
      <>
        <section className='w-100 d-flex flex-column'>
          {items.map(item => (
            <ReviewArticle
              key={item.sku}
              item={item}
              configuration={marketingConfigurationHash[item.marketingConfigurationId]}
              contentURL={contentURL}
              translator={translator}
            />
          ))}
          <article className='cartItem p-3 d-flex justify-content-between'>
            <span className='font-weight-bold'>{translator.t('buyplans_payment_text')}</span>
            <span>{`${this.props.card?.name} ${maskPaymentCardNumber(this.props.card?.carrierNumber, 2)}`}</span>
          </article>
        </section>

        {loading === true && <Spinner />}
        <button
          type='button'
          className='cwp cwpPrimary mt-5'
          onClick={() => this.props.placeOrder()}
          disabled={loading}
        >
          {translator.t('buyplans.button-label.place-order')}
        </button>
        <button type='button' className='carnetLink mt-5' onClick={() => this.props.onCancel()} disabled={loading}>
          {translator.t('button.cancel')}
        </button>
      </>
    );
  }
}
