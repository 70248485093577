import React, { Component } from 'react';
import Faq from 'react-faq-component';
import '../faqCanada/faqCA.scss';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import goback from '../../../assets/faq/goback.png';
import { historyBack } from './faqCAhome';
import { setTitle } from '../../../providers/documentTitleProvider';

export default class RemoteFaqCA extends Component {
  componentDidMount () {
    setTitle('document_titles.faq.remote');
  }
  render () {
    let translator = getLocaleTranslator();

    return (
      <div className='faqCAheader' role='main' id='main-content'>
        <div id='exit-editing-link'>
          <span
            id='backButton'
            class='cwpBack'
            role='button'
            onClick={historyBack}
            onKeyPress={e => {
              e.preventDefault();
              if (e.key && (e.key === 'Enter' || e.key === ' ')) {
                historyBack(e);
              }
            }}
            tabIndex={0}
          >
            {translator.t('button.back')}
          </span>
        </div>
        <div className='message-header'>
          <div className='td-ms-header'>
            <h1>{translator.t('remote_title')}</h1>
          </div>
        </div>
        <Faq data={data} />
      </div>
    );
  }
}

const data = {
  rows: [
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          What can you see on the “Remote section” of the HOME tab for in the Car-Net mobile app?{' '}
        </div>
      ),
      content: ` <span class='mdlyr'>Model Year 2022+:</span> After logging into the mobile app the first screen you will see is the Remote Vehicle information that is under the HOME tab. There are three main functions here: Remote, Trips and Buy Plans. Below is a list of features accessed on the Remote function of this section:</br>
       <ol> 
        <li>Honk and Flash lights of vehicle</li>
        <li>Lock and Unlock Doors</li>
        <li>Remote Start and Stop (available on select trim levels)</li>
        <li>View fuel level</li>
        <li>View amount of miles on vehicle</li>
        <li>View if vehicle is secure (vehicle is secure when all windows are closed and all doors are closed and locked).</li>
      </ol>`
    }
  ]
};

export { RemoteFaqCA };
