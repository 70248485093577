import React from 'react';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';

export default function Spinner () {
  const translator = getLocaleTranslator();

  return (
    <section className='backdrop'>
      <article className='spinner-border spinner-color' role='status'>
        <span className='sr-only'>{translator.t('sr_only_spinner_label') || 'loading'}</span>
      </article>
    </section>
  );
}
