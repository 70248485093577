import React from 'react';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { MaxBirthDate, MinBirthDate } from '../CarnetHome';
import { Container, Row, FormGroup, Col, Button } from 'reactstrap';
import '../../reusable_cmp_lib/ThemedFormInputs/ThemedForm.scss';
import './AccountManagement.scss';
import CwpDatePicker from '../common/CwpDatePicker';
import { setTitle } from '../../../providers/documentTitleProvider';

class BirthdateEditor extends React.Component {
  state = {
    workingDate: ''
  };

  constructor (props) {
    super(props);
    this.birthDateMin = MinBirthDate();
    this.birthDateMax = MaxBirthDate();
    this.widgetOnlyDefaultDate = MaxBirthDate();
    if (props.birthDate) {
      this.state = {
        workingDate: props.birthDate
      };
    }
  }

  componentDidMount () {
    let workingDate = this.props.birthDate;
    if (workingDate) {
      this.setState({
        workingDate
      });
    }
  }

  componentDidUpdate () {
    setTitle('document_titles.account_management.edit_birth_date');
  }

  birthdateChanged = dateStringFromWidget => {
    this.setState({ workingDate: dateStringFromWidget });
  };

  render () {
    const translator = getLocaleTranslator();
    let workingDate = this.state.workingDate;
    return (
      <Container fluid={true}>
        <fieldset>
          <Row>
            <Col md='4'></Col>
            <Col md='8'>
              <div className='editor-firstrow-label-aligner'>
                <legend>
                  <span className='first-editor-field-label'>{translator.t('acctmgmt_dob_label')}</span>
                </legend>
              </div>
            </Col>
          </Row>
          <Row className='extendedCalendarHeight'>
            <Col md='4'></Col>
            <Col md='3'>
              <div className='editor-diatom-field'>
                <label htmlFor='dob' className='editor-diatom-superscript-lbl'>
                  {translator.t('acctmgmt_dob_label')} {translator.t('acctmgmt_optional_suffix')}
                </label>
                <FormGroup>
                  <CwpDatePicker
                    id={'dob'}
                    min={this.birthDateMin}
                    max={this.birthDateMax}
                    date={this.state.workingDate}
                    onChange={this.birthdateChanged}
                  />
                </FormGroup>
                {!this.state.workingDate && <div className='invalid-indicator'>{translator.t('required_lbl_dob')}</div>}
              </div>
            </Col>
            <Col md='2'>&nbsp;</Col>
            <Col md='1'>
              <Button
                className='cwp'
                disabled={!/^(\d{1,2})-(\d{1,2})-(19|20)(\d{2})$/.test(this.state.workingDate)}
                color='primary'
                onClick={() => {
                  if (!this.state.workingDate) {
                    return;
                  }
                  const formattedDate = this.state.workingDate;
                  const edits = { birthDate: formattedDate };
                  this.props.refreshCallback(edits, null);
                  let mutableCustomerProfile = JSON.parse(JSON.stringify(this.props.accountData));
                  mutableCustomerProfile.birthDate = formattedDate;
                  this.props.saveHandler(mutableCustomerProfile);
                }}
              >
                {translator.t('save_btn_camel')}
              </Button>
            </Col>
          </Row>
        </fieldset>
      </Container>
    );
  }
}

export default BirthdateEditor;
