import React from 'react';
import LanguageToggle from '../../language_toggle/toggle';
import LoginOut from '../loginOut/LoginOut';
import carnetLogo from '../../../assets/images/logo.svg';

import './loginLangToggleOverrides.scss';

const HeaderPrePinAuth = props => {
  return (
    <header className='cwp-header loginOutOverride langToggleOverride'>
      <img src={carnetLogo} alt='car-net-logo' width='130' className='prelogin'></img>
      <br />
      <div className='wrapperRight'>
        <LoginOut />
        <LanguageToggle />
      </div>
      <br />
      <hr aria-hidden='true' className='mt-0' />
      <span id='vwlogo' />
    </header>
  );
};

export default HeaderPrePinAuth;
