import React, { Component } from 'react';
import { css } from '@emotion/core';
import { connect } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import Loader from '../common/loader';
import { findIn } from '../../../util/utilityMethods';
import FormatTimeToDate from '../common/formatTimeToDate';
import { formatTime, formatDateUsingOneOffUnstandardRequestedFormat } from '../../../util/i18n/localeUtils';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import Constants from '../../../constants/carnet_constants';
import deleteIcon from '../../../assets/icons/close-circle.svg';
import { deleteVehicleEvent, getVehicleEventHistory } from '../providers/VehicleEventHistoryProvider';
import { setTitle } from '../../../providers/documentTitleProvider';
import './vehicleHistoryInfo.scss';
const ACTION_DELETED = 'ACTION_DELETED';

const inlineClipLoader = css`
  margin: 0;
  padding: 2px 0 0 0;
  display: inline-block;
`;

class VehicleHistoryInfo extends Component {
  constructor (props) {
    super(props);

    this.state = {
      data: null,
      loading: false,
      vehicleOperation: 'all',
      days: '7',
      deleting: []
    };

    this.translator = getLocaleTranslator();
  }

  componentDidMount () {
    this.fetchData(this.state.vehicleOperation, this.state.days);
  }

  fetchData = async (operation = this.state.vehicleOperation, days = this.state.days) => {
    let data = null;

    if (this.state.data) {
      data = [...this.state.data];
    }

    this.setState({ vehicleOperation: operation, days: days, loading: true, data }, async () => {
      try {
        const { data } = await getVehicleEventHistory(operation, days);
        this.setState({ data, loading: false });
      } catch (error) {
        this.setState({ loading: false });
      }
    });
  };

  deleteSingleEvent = vehicleEventId => {
    let deleting = [...this.state.deleting, vehicleEventId];
    this.setState({ deleting }, async () => {
      try {
        await deleteVehicleEvent(vehicleEventId);
        const updatedData = this.state.data.map(_vehicleHistory => {
          // clone the item
          let vehicleHistory = { ..._vehicleHistory };
          if (vehicleHistory.vechileEventId === vehicleEventId) {
            vehicleHistory.operation = ACTION_DELETED;
            vehicleHistory.requestType = ACTION_DELETED;
            vehicleHistory.eventTimestamp = '';
          }
          return vehicleHistory;
        });
        this.setState({ data: updatedData, deleting });
      } catch (error) {
        let deletingAfterProcess = this.state.deleting.filter(id => id !== vehicleEventId);
        this.setState({ deleting: deletingAfterProcess });
      }
    });
  };

  getRowActionTranslated = (labelIdentifier, fallback) => {
    let fullColumnLabelItdentifier = `history.action-label.${labelIdentifier}`;
    let translated = this.translator.t(fullColumnLabelItdentifier);
    // means no translation was available for that identifier, return the fallback
    if (translated === fullColumnLabelItdentifier) {
      return fallback;
    }
    return translated;
  };

  render () {
    setTitle('document_titles.vehicle_history');
    const { vehicleOperation, days } = this.state;

    return (
      <div role='main' id='main-content' className='container cwpContentHeightMin'>
        <div className='row justify-content-md-center'>
          <div className='col-md-10 text-center my-4'>
            <h1>{this.translator.t('history_view_activity_label')}</h1>
            <span className='d-block my-3'>{this.translator.t('history_view_activity_subheading_label')}</span>
          </div>
          <div className='col-md-5'>
            <div class='cwpCol d-block m-auto float-md-right'>
              <label className='cwp' for='operation'>
                {this.translator.t('history.dropdown.action-type')}
              </label>
              <select
                id='operation'
                value={vehicleOperation}
                onChange={e => this.fetchData(e.target.value, this.state.days)}
                className='cwp'
              >
                <>
                  <option value='all'>{this.translator.t('history.dropdown.option-all-types')}</option>
                  <option value='doorTrunk'>
                    {this.translator.t('history.dropdown.option-lock-and-unlock-request')}
                  </option>
                  <option value='poi'>{this.translator.t('history.dropdown.option-destination-request')}</option>
                  <option value='hornLights'>
                    {this.translator.t('history.dropdown.option-honk-or-flash-request')}
                  </option>
                  <option value='vehicleStatus'>
                    {this.translator.t('history.dropdown.option-remote-status-check-request')}
                  </option>
                  <option value='boundaryAlert'>{this.translator.t('history.dropdown.option-boundary-alert')}</option>
                  <option value='speedAlert'>{this.translator.t('history.dropdown.option-speed-alert')}</option>
                  <option value='curfewAlert'>{this.translator.t('history.dropdown.option-curfew-alert')}</option>
                  <option value='valetAlert'>{this.translator.t('history.dropdown.option-valet-alert')}</option>
                  <option value='intrusionAlert'>{this.translator.t('history.dropdown.option-anti-theft')}</option>
                  <option value='climateControl'>{this.translator.t('history.dropdown.option-remote-start')}</option>
                  <option value='wifiControl'>{this.translator.t('history.dropdown.option-wifi-control')}</option>
                  <option value='wifiStatus'>{this.translator.t('history.dropdown.option-wifi-status')}</option>
                  <option value='Paired'>{this.translator.t('history.dropdown.option-device-pairing')}</option>
                </>
              </select>
            </div>
          </div>
          <div className='col-md-5'>
            <div className='cwpCol d-block m-auto float-md-left'>
              <label className='cwp' for='days'>
                {this.translator.t('history.dropdown.time-frame')}
              </label>
              <select
                id='days'
                value={days}
                onChange={e => this.fetchData(this.state.vehicleOperation, e.target.value)}
                className='cwp'
              >
                <option value='7'>{this.translator.t('history.dropdown.option-last-7-days')}</option>
                <option value='14'>{this.translator.t('history.dropdown.option-last-14-days')}</option>
                <option value='30'>{this.translator.t('history.dropdown.option-last-30-days')}</option>
                <option value='90'>{this.translator.t('history.dropdown.option-last-90-days')}</option>
                <option value='all'>{this.translator.t('history.dropdown.option-all-available-notifications')}</option>
              </select>
            </div>
          </div>

          {this.state.loading && (
            <div className='col-md-10 text-center my-4'>
              <Loader />
            </div>
          )}

          <div className='col-lg-8 my-5'>
            <br />
            <table id='vehicleHistory' className='cwp table table-hover table-striped'>
              <thead>
                <tr>
                  <th className='vehicleHistoryHeader'>{this.translator.t('history_action_label')}</th>
                  <th className='vehicleHistoryHeader'>{this.translator.t('history_date_label')}</th>
                  <th className='vehicleHistoryHeader'>{this.translator.t('history_time_label')}</th>
                  <td className='vehicleHistoryHeader'></td>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(this.state.data) ? (
                  this.state.data.map(row => {
                    if (row.operation === ACTION_DELETED) {
                      return (
                        <tr>
                          <td className='text-center text-danger' colSpan='4'>
                            {' '}
                            {this.translator.t('history_delete_action')}
                          </td>
                        </tr>
                      );
                    }
                    return (
                      <tr>
                        <td>{this.getRowActionTranslated(row.requestType, row.operation)}</td>
                        <td>{formatDateUsingOneOffUnstandardRequestedFormat(row.eventTimestamp, this.props.locale)}</td>
                        <td>{formatTime(row.eventTimestamp)}</td>
                        <td className='text-right inlineLoaderWrapper'>
                          {this.state.deleting.includes(row.vechileEventId) ? (
                            <ClipLoader css={inlineClipLoader} />
                          ) : (
                            <input
                              type='image'
                              src={deleteIcon}
                              className='cwp historyDelete'
                              role='button'
                              title={this.translator.t('history_event_delete')}
                              onClick={e => this.deleteSingleEvent(row.vechileEventId)}
                              onKeyPress={e => {
                                if (e.key && (e.key === 'Enter' || e.key === ' ')) {
                                  this.deleteSingleEvent(row.vechileEventId);
                                }
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <div className='vehicleHistoryNoResultsFound'>{this.translator.t('history_no_results_found')}</div>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default VehicleHistoryInfo;
