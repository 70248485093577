import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { BuyPlansContext } from '../BuyPlansContext';
import { Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledAlert } from 'reactstrap';
import { getCartById, parseCartItem, removeFromCart } from '../../providers/EstoreProvider';
import { findErrorMessage } from '../../../../util/i18n/i18nService';
import CartArticle from './CartArticle';
import AutoLabelCheckbox from '../components/AutoLabelCheckbox';

export default class Cart extends React.Component {
  static contextType = BuyPlansContext;

  state = {
    selectedItem: null,
    redirectPath: null,
    openMustReadTermsModal: false,
    cartTosAcceptances: {}
  };

  componentDidMount () {
    window.scrollTo(0, 0);
    this.initCartTosAcceptances();
  }

  initCartTosAcceptances = cartData => {
    const cart = cartData || this.context.cart;
    const marketingConfigurationHash = this.context.marketingConfigurationHash || {};

    const cartTosAcceptances = {};
    if (Array.isArray(cart?.items)) {
      for (const { marketingConfigurationId } of cart.items) {
        let tosAcceptances = marketingConfigurationHash?.[marketingConfigurationId]?.cartTermsOfService?.tosAcceptances;
        if (Array.isArray(tosAcceptances) && tosAcceptances.length > 0) {
          cartTosAcceptances[marketingConfigurationId] = AutoLabelCheckbox.parseValuesFromTosAcceptances(
            tosAcceptances
          );
        }
      }
    }

    this.setState({ cartTosAcceptances });
  };

  setItemToRemoveFromCart = (item = null) => this.setState({ selectedItem: item });

  confirmBeforeRemove = cartItem => {
    this.setState({ selectedItem: cartItem });
  };

  removeItemFromCart = async () => {
    const { sku, priceOptionId } = { ...this.state.selectedItem };

    this.context.set({ loading: true });

    let { items = [], cartId } = this.context?.cart || {};

    if (Array.isArray(items) && items.length > 0) {
      items = items.filter(item => item.sku !== sku || item.priceOptionId !== priceOptionId);
      items = items.map(item => parseCartItem(item));
    } else {
      items = [];
    }

    try {
      await removeFromCart(items, cartId);
      const { data } = await getCartById(cartId);
      this.initCartTosAcceptances(data);
      this.context.set({ loading: false, cart: data });
    } catch (error) {
      this.context.set({ loading: false });
      this.setState({ alert: findErrorMessage(error) });
    } finally {
      this.setItemToRemoveFromCart();
    }
  };

  clearAlerts = () => this.setState({ alert: null });

  getAlerts = () => {
    if (this.state.alert) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts} aria-live='polite' className='w-100'>
          {this.state.alert}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  redirectTo = path => {
    this.setState({ redirectPath: path });
  };

  onSubmit = e => {
    e.preventDefault();
    const form = e.target.form;

    if (form?.checkValidity() === false) {
      form?.reportValidity();

      return;
    }

    if (form.querySelector('input[disabled]')) {
      this.setState({ openMustReadTermsModal: true });

      return;
    }

    this.redirectTo('/checkout/payment');
  };

  closeModalMustReadTerms = () => {
    this.setState({ openMustReadTermsModal: false });
  };

  render () {
    if (this.state.redirectPath) {
      return <Redirect to={this.state.redirectPath} />;
    }

    const { translator, cart, contentURL, userCountry } = this.context;
    const items = Array.isArray(cart?.items) ? cart.items : [];

    return (
      <div role='main' id='main-content' className='py-4'>
        <div className='ml-4 position-absolute'>
          <Link to='/' className='linkBack' role='button'>
            {translator.t('buyplans.button-label.back-to-your-plans')}
          </Link>
        </div>
        <form
          className='container w-100 d-flex flex-column align-items-center text-center mt-4 pb-5'
          onSubmit={this.onSubmit}
        >
          <h1 className='mb-5 font-weight-light'>
            {translator.t('buyplans_landing_your_label')}{' '}
            <strong className='font-weight-bold'>{translator.t('buyplans_common_cart')}</strong>
          </h1>
          {this.getAlerts()}
          {items.map(item => (
            <CartArticle
              key={item.sku}
              item={item}
              configuration={this.context.marketingConfigurationHash[item.marketingConfigurationId]}
              cartTosAcceptances={this.state.cartTosAcceptances?.[item.marketingConfigurationId]}
              contentURL={contentURL}
              userCountry={userCountry}
              translator={translator}
              onClickRemove={() => this.confirmBeforeRemove(item)}
            />
          ))}
          {items.length > 0 && (
            <button type='button' className='cwp cwpPrimary mt-3' onClick={this.onSubmit}>
              {translator.t('buyplans.button-label.proceed-to-checkout')}
            </button>
          )}
          {items.length < 1 && <span className='h5 mt-5'>{translator.t('buyplans_cart_item_empty_text')}</span>}
        </form>
        {this.state.selectedItem && (
          <Modal
            className='carnet-sg-modal text-center'
            isOpen={this.state.selectedItem ? true : false}
            onHide={() => this.setItemToRemoveFromCart()}
            centered={true}
          >
            <ModalHeader className='mx-auto'>
              <span className='h1'>{translator.t('buyplans_cart_dialog_remove_item_title')}</span>
            </ModalHeader>
            <ModalBody>
              <div className='similarPlanCart'></div>
              <br />
              <div className=''>{translator.t('buyplans_cart_dialog_remove_item_text')}</div>
            </ModalBody>
            <ModalFooter className='d-flex flex-column align-items-center'>
              <button type='button' className='modal-primary-btn w-100' onClick={this.removeItemFromCart}>
                {translator.t('buyplans_remove_item_text')}
              </button>
              <button
                type='button'
                className='modal-secondary-btn w-100'
                onClick={() => this.setItemToRemoveFromCart()}
              >
                {translator.t('buyplans_cancel_text')}
              </button>
            </ModalFooter>
          </Modal>
        )}

        {this.state.openMustReadTermsModal && (
          <Modal
            isOpen={this.state.openMustReadTermsModal}
            centered={true}
            className='carnet-sg-modal text-center'
            onHide={() => this.closeModalMustReadTerms()}
          >
            <ModalHeader className='mx-auto'>
              <span className='h1'>{translator.t('buyplans_must_read_terms_header')}</span>
            </ModalHeader>
            <ModalBody>{translator.t('buyplans_must_read_terms_body')}</ModalBody>
            <ModalFooter className='d-flex flex-column align-items-center'>
              <button type='button' className='modal-primary-btn w-100' onClick={this.closeModalMustReadTerms}>
                {translator.t('button.ok')}
              </button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    );
  }
}
