import {
  GET_SIRISXM_SUBSCRIPTION_STATUS,
  GET_SIRISXM_SUBSCRIPTION_STATUS_SUCCESS,
  GET_SIRISXM_SUBSCRIPTION_STATUS_FAILUE
} from '../../actions/types';

const initalState = {
  siriusxmStatusData: null,
  dataLoading: false
};

const sirisxmSubscriptionStatusReducer = (state = initalState, action = { payload: {} }) => {
  let newState = { ...state };
  let actionType = (action && action.type) || 'default';
  let responseData = action.payload || {};

  switch (actionType) {
    case GET_SIRISXM_SUBSCRIPTION_STATUS:
      newState = { ...initalState };
      return newState;

    case GET_SIRISXM_SUBSCRIPTION_STATUS_SUCCESS:
      let { data } = responseData;
      console.log('siriusxmStatusData in Reducer', JSON.stringify(data, null, 1));
      newState['siriusxmStatusData'] = data || null;
      newState['dataLoading'] = true;
      return newState;
    case GET_SIRISXM_SUBSCRIPTION_STATUS_FAILUE:
      newState['siriusxmStatusData'] = null;
      newState['dataLoading'] = false;
      return newState;
    default:
      return newState;
  }
};

export default sirisxmSubscriptionStatusReducer;
