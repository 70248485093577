import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Player } from 'video-react';
import { getCwpMeta } from '../../../providers/cwpMetaProvider';
import EnrollVideo from './Enrollment';
import WifiVideo from './WifiHotSpot';
import remoteStart from '../../../assets/images/create-vwid-enca.JPG';
import Wifi from './Wifi';
import WifiSetUp from './WifiSetUp';
import './Video-react.scss';
import { setTitle } from '../../../providers/documentTitleProvider';
import Transcript from './Transcript';

class VideoDisplay extends Component {
  //const VideoDisplay = props => {

  state = {
    videoVisible: false,
    subContentUrl: null
  };

  transcriptClick = subContentUrl => {
    this.setState({ subContentUrl });
  };

  transcriptbackClick = () => {
    this.setState({ subContentUrl: null });
  };

  toggleopen = id => {
    this.setState({ videoVisible: !this.state.videoVisible });
  };

  historyBack = e => {
    e.preventDefault();
    e.stopPropagation();
    window && window.history && window.history.go(-1);
  };

  componentDidMount = async () => {
    setTitle('document_titles.faq.faq_videos');
    let metaResponse = await getCwpMeta();
    let videoContentURL = metaResponse.static.content.urls.videos;
    this.setState({
      videoContentURL
    });
    console.log('videoContentURL-------------::' + videoContentURL);
  };

  render () {
    return (
      <div role='main' id='main-content'>
        <div id='showFeatures' className='divFeatureswifi' hidden={this.state.subContentUrl}>
          <div className='goback'>
            <span
              className='cwpBack ml-3'
              role='link'
              tabIndex={0}
              onClick={this.historyBack}
              onKeyPress={this.historyBack}
            >
              Go Back
            </span>
          </div>

          <h1 className='tutorials'>
            Car-Net <span className='faq-back-header'>Videos and Tutorials</span>
          </h1>

          {/* 1st Row Video component */}

          <div class='videoFAQ-flex-container'>
            {/*  1st Video component */}

            <div class='flex-item-left'>
              <div>
                <a href='javascript:void(0);' onClick={this.toggleopen}>
                  <img src={remoteStart} width='370px' height='210px' alt='How to create your VW ID' />
                  <span className='video-title'>How to create your VW ID</span>
                </a>
              </div>
              <span
                class='carnetLink'
                role='link'
                onClick={() =>
                  this.transcriptClick(
                    `${this.state.videoContentURL}/${encodeURIComponent('How to Create your VWID transcript.txt')}`
                  )
                }
                tabIndex={0}
              >
                View transcript
              </span>
            </div>

            {/*  Video component */}

            <div class='flex-item-center'>
              <EnrollVideo />

              <span
                class='carnetLink'
                role='link'
                onClick={() =>
                  this.transcriptClick(
                    `${this.state.videoContentURL}/${encodeURIComponent('How to add a vehicle transcript.txt')}`
                  )
                }
                tabIndex={0}
              >
                View transcript
              </span>
            </div>

            {/*  Video component */}

            <div class='flex-item-right'>
              <WifiVideo />

              <span
                class='carnetLink'
                role='link'
                onClick={() =>
                  this.transcriptClick(
                    `${this.state.videoContentURL}/${encodeURIComponent(
                      'How to add a secondary driver transcript.txt'
                    )}`
                  )
                }
                tabIndex={0}
              >
                View transcript
              </span>
            </div>
          </div>

          {/* 2nd Row Video component */}

          <div class='videoFAQ-flex-container'>
            {/*  Video component */}
            <div class='flex-item-left-1'>
              <Wifi />
              <span
                class='carnetLink'
                role='link'
                onClick={() =>
                  this.transcriptClick(
                    `${this.state.videoContentURL}/${encodeURIComponent('How to access Wi-Fi hotspot transcript.txt')}`
                  )
                }
                tabIndex={0}
              >
                View transcript
              </span>
            </div>
            {/*  Video component */}
            <div class='flex-item-center-1'>
              <WifiSetUp />
              <span
                class='carnetLink'
                role='link'
                onClick={() =>
                  this.transcriptClick(
                    `${this.state.videoContentURL}/${encodeURIComponent('How to Set Up WiFi transcript EN.txt')}`
                  )
                }
                tabIndex={0}
              >
                View transcript
              </span>
            </div>
            {/*  Video component */}
            <div class='flex-item-right-1'></div>
          </div>

          <Modal isOpen={this.state.videoVisible} toggle={this.toggleopen} centered={true} className='video'>
            <ModalHeader toggle={this.toggleopen}>How to create your VW ID</ModalHeader>
            <ModalBody>
              {this.state.videoContentURL && (
                <Player playsInline src={`${this.state.videoContentURL}/HowtoCreateyourVWID_v8.mp4`} />
              )}
            </ModalBody>
          </Modal>
        </div>

        {/*  Showing Transcript on same page */}

        <div className='goback' hidden={!this.state.subContentUrl}>
          <span className='cwpBack ml-3' role='link' tabIndex={0} onClick={this.transcriptbackClick}>
            Go Back
          </span>
        </div>
        <div id='showFeatures' className='divFeatureswifi' hidden={!this.state.subContentUrl}>
          {this.state.subContentUrl && <Transcript contentUrl={this.state.subContentUrl}></Transcript>}
          <br />
        </div>
      </div>
    );
  }
}

export default VideoDisplay;
