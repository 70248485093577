import React, { Component } from 'react';
import Faq from 'react-faq-component';
import '../faqCanada/faqCA.scss';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import goback from '../../../assets/faq/goback_fr.png';
import { historyBack } from './faqCAhome';
import { setTitle } from '../../../providers/documentTitleProvider';

export default class FamilyGuardianFaqCA extends Component {
  componentDidMount () {
    setTitle('document_titles.faq.guardian');
  }
  render () {
    let translator = getLocaleTranslator();

    return (
      <div className='faqCAheader' role='main' id='main-content'>
        <div className='goback'>
          <span onClick={historyBack}>
            <img src={goback} alt='viewfaq' width='100'></img>
          </span>
        </div>
        <div className='message-header'>
          <div className='td-ms-header'>
            <h1>{translator.t('guardian_title')}</h1>
          </div>
        </div>
        <Faq data={data} />
      </div>
    );
  }
}

const data = {
  rows: [
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Combien d’alertes de périmètre vais-je recevoir?
        </div>
      ),
      content: ` 
      <span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span> 
          L’alerte de périmètre vous permet de définir une zone géographique sur une carte et vous avertit par message texte ou par courriel lorsque votre voiture entre dans cette zone ou en sort. Vous pouvez choisir l’heure, la date et la fréquence des alertes que vous souhaitez recevoir pour chaque alerte de périmètre créée. Il est possible de créer jusqu’à 10 alertes de périmètre par véhicule. Toutefois, un maximum de 4 alertes peuvent être activées en même temps. Pour créer une alerte de périmètre, veuillez suivre les étapes suivantes : </br>
          <ol> 
           <li>Connectez-vous à votre compte Car-NetMD de Volkswagen sur l’application mobile ou le portail Web. </br>
           <li>Allez à l’onglet « Guardian » (alertes de surveillance parentale). Sélectionnez « Boundary Alerts » (alertes de périmètre) et appuyez sur le bouton « Create new Boundary Alert » (créer une nouvelle alerte de périmètre).</li>
           <li>Sélectionnez maintenant la zone géographique pour la nouvelle alerte de périmètre en déplaçant le carré rouge sur la carte. Il est possible de remplacer le carré par un cercle. </li>
           <li> Ensuite, vous pouvez planifier la durée de l’alerte ou l’activer en tout temps en sélectionnant « Everyday » (tous les jours) pour la fréquence et « All Day » (toute la journée) pour l’heure.</li>
           <li> Entrez les coordonnées de la personne qui recevra les alertes et sélectionnez la fréquence des notifications.</li>
           <li>Lorsque vous avez terminé la configuration de l’alerte, cliquez sur « Set Boundary Alert » (activer l’alerte de périmètre). Elle devrait maintenant être visible dans la section des alertes de périmètre de la page des services de surveillance parentale.</li>
          </ol> 
      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Pourquoi puis-je avoir une seule alerte de vitesse à la fois?{' '}
        </div>
      ),
      content: ` <span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span> 
              Une alerte de vitesse est une notification qui vous avertit lorsque votre véhicule dépasse une vitesse prédéfinie. Vous pouvez choisir n’importe quelle vitesse jusqu’à 130 km/h. Vous pouvez également personnaliser vos préférences de notification pour l’alerte de vitesse. Une seule alerte de vitesse peut être activée à la fois. Pour créer une alerte de vitesse, veuillez suivre les étapes suivantes : </br>
            <ol> 
              <li>Connectez-vous à votre compte Car-NetMD de Volkswagen sur l’application mobile ou le portail Web.</li>
              <li>Allez à l’onglet « Guardian » (alertes de surveillance parentale). Sélectionnez « Speed Alerts » (alertes de vitesse) et appuyez sur le bouton « Create new Speed Alert » (créer une nouvelle alerte de vitesse). </li>
              <li>Indiquez maintenant la vitesse à laquelle vous souhaitez être avisé si votre véhicule dépasse cette limite. </li>
              <li>Ensuite, vous pouvez planifier la durée de l’alerte ou l’activer en tout temps en sélectionnant « Everyday » (tous les jours) pour la fréquence et « All Day » (toute la journée) pour l’heure. </li>
              <li>Entrez les coordonnées de la personne qui recevra les alertes et sélectionnez la fréquence des notifications. </li>
              <li>Lorsque vous avez terminé la configuration de l’alerte, cliquez sur « Set Speed Alert » (activer l’alerte de vitesse). Elle devrait maintenant être visible dans la section des alertes de vitesse de la page des services de surveillance parentale.</li>
            </ol> 
      

      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Qu’est-ce qu’une alerte de couvre-feu?{' '}
        </div>
      ),
      content: ` <span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span> Une alerte de couvre-feu vous avisera lorsque votre véhicule est conduit pendant une période en particulier. Vous pouvez choisir de recevoir une alerte par message texte, par courriel ou les deux. Veuillez noter que pendant que cette alerte est active, votre alerte de vitesse existante sera désactivée et elle sera réactivée lors de la désactivation de l’alerte de couvre-feu. Pour créer une alerte de couvre-feu, veuillez suivre les étapes suivantes : </br>
            <ol>  
              <li>Connectez-vous à votre compte Car-NetMD de Volkswagen sur l’application mobile ou le portail Web.</li>
              <li>Vous pouvez accéder à toutes les alertes de surveillance parentale sur l’application mobile en appuyant sur l’onglet « Guardian » (alertes de surveillance parentale) en bas de l’écran. Une fois sur la page des alertes de surveillance parentale, sélectionnez « Curfew Alerts » (alertes de couvre-feu) et appuyez sur le bouton « Create new Curfew Alert » (créer une nouvelle alerte de couvre-feu). Si vous utilisez le portail Web Car-NetMD de Volkswagen, pour accéder aux alertes de surveillance parentale, cliquez sur le lien des services de surveillance en haut de la page. Cliquez sur « Create new Curfew Alert » (créer une nouvelle alerte de couvre-feu) dans la section des alertes de couvre-feu.</li>
              <li>Une fois à la page pour créer une alerte de couvre-feu, inscrivez les renseignements requis.</li>
              <li>Lorsque vous avez terminé la configuration de l’alerte, cliquez sur « Set Curfew Alert » (activer l’alerte de couvre-feu). Elle devrait maintenant être visible dans la section des alertes de couvre-feu de la page des services de surveillance parentale.</li>
            </ol> 
      
      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Qu’est-ce qu’une alerte voiturier?{' '}
        </div>
      ),
      content: ` 
      <span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span>  L’alerte voiturier vous permet de définir une zone géographique sur une carte et vous avertit par message texte ou par courriel lorsque votre véhicule entre dans cette zone ou en sort. Vous pouvez choisir la fréquence des alertes et indiquer si vous souhaitez les recevoir par message texte, par courriel ou les deux. </br>
       <ol>  
          <li>Connectez-vous à votre compte Car-NetMD de Volkswagen sur l’application mobile. </li>
          <li>Allez à l’onglet « Guardian » (alertes de surveillance parentale) et appuyez sur le bouton « Create new Valet Alert » (créer une nouvelle alerte voiturier). </li>
          <li>Vous verrez alors une carte montrant l’emplacement de votre véhicule. Vous pouvez passer de l’emplacement de votre véhicule à votre emplacement actuel pour configurer votre alerte voiturier. </li>
          <li>Lorsque vous avez terminé la configuration de l’alerte, cliquez sur « Set Valet Alert » (activer l’alerte voiturier). L’alerte a maintenant été activée.</li>
      </ol>         
      `
    }
  ]
};

export { FamilyGuardianFaqCA };
