import React from 'react';
import PropTypes from 'prop-types';
import iconCloseCircle from '../../../../assets/buyPlans/close-circle.svg';

export default class Disclaimer extends React.Component {
  static propTypes = {
    translator: PropTypes.object.isRequired,
    onExit: PropTypes.func.isRequired,
    disclaimerText: PropTypes.string.isRequired,
    disclaimerNumber: PropTypes.oneOfType(PropTypes.number, PropTypes.string)
  };

  componentDidMount () {
    document.getElementById('buyPlansDisclaimer').focus();
  }

  render = () => {
    const { translator, disclaimerText, disclaimerNumber, onExit } = this.props;

    return (
      <section
        id='buyPlansDisclaimer'
        className='container row px-0 py-3 mx-auto align-items-start bg-dark text-light position-fixed fixed-bottom'
        tabIndex={0}
        aria-labelledby='disclaimerContent'
      >
        <h3 className='co-12 col-md-1 h5 font-weight-bold my-0'>{disclaimerNumber}</h3>
        <article id='disclaimerContent' className='col-12 col-md-10 small text-left pb-3 px-md-0'>
          {translator.t('buyplans.button-label.footnote')}
          {disclaimerText}
        </article>
        <article className='col-12 col-md-1 text-md-right'>
          <input
            type='image'
            src={iconCloseCircle}
            className='rounded-circle'
            onClick={() => onExit()}
            alt={translator.t('buyplans.button-label.close-footnote')}
          />
        </article>
      </section>
    );
  };
}
