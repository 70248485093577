import React from 'react';
import { fetchSecureContent } from './marketingProviders';

export default class Transcript extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      content: ''
    };
  }

  componentDidMount = async () => {
    let locale = this.props.locale || 'en-CA';
    try {
      const response = await fetchSecureContent(this.props.contentUrl, locale);
      this.setState({ content: response.data });
    } catch (error) {
      console.log(error);
    }
  };

  render () {
    return (
      <div id='main-content' role='main'>
        <pre className='container'>{this.state.content}</pre>
      </div>
    );
  }
}
