import React from 'react';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { Container, Row, Col, Input, Button } from 'reactstrap';
import './AccountManagement.scss';
import { setTitle } from '../../../providers/documentTitleProvider';
import { filterInvalidInputCharacters } from '../../../util/utilityMethods';
class PersonalNameEditor extends React.Component {
  constructor (props) {
    super(props);
    let passedInFirstName = this.props.accountData.firstName || '';
    let passedInLastName = this.props.accountData.lastName || '';

    this.state = {
      presentFirstName: passedInFirstName,
      presentLastName: passedInLastName
    };
  }

  handleTextEditing = (fieldName, evt) => {
    const presentTxt = filterInvalidInputCharacters(evt.target.value);
    let statePatch = {};
    statePatch[fieldName] = presentTxt;
    this.setState(statePatch);
  };

  render () {
    setTitle('document_titles.account_management.edit_name');
    this.translator = getLocaleTranslator();

    return (
      <fieldset>
        <Container fluid={true}>
          <Row>
            <Col md='4'></Col>
            <Col md='8'>
              <div class='editor-firstrow-label-aligner'>
                <legend>
                  <span class='first-editor-field-label'>{this.translator.t('acctmgmt_pi_field_label_name')}</span>
                </legend>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md='4'></Col>
            <Col md='3'></Col>
            <Col md='3'>
              <div class='editor-diatom-field margin-top-10'>
                <label htmlFor='pi_first_name_input' className='editor-diatom-superscript-lbl'>
                  {this.translator.t('acctmgmt_pi_field_label_fname')}
                </label>
                <Input
                  className='cwp'
                  type='text'
                  name='pi_first_name'
                  id='pi_first_name_input'
                  onChange={evt => this.handleTextEditing('presentFirstName', evt)}
                  placeholder={this.state.presentFirstName}
                  value={this.state.presentFirstName}
                  required
                />
                <br />
                <label className='editor-diatom-superscript-lbl' htmlFor='pi_last_name_input'>
                  {this.translator.t('acctmgmt_pi_field_label_lname')}
                </label>
                <Input
                  className='cwp'
                  type='text'
                  name='pi_last_name'
                  id='pi_last_name_input'
                  onChange={evt => this.handleTextEditing('presentLastName', evt)}
                  placeholder={this.state.presentLastName}
                  value={this.state.presentLastName}
                  required
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md='11'>
              <div className='buttonToTheRight'>
                <Button
                  className='cwp'
                  color='primary'
                  onClick={() => {
                    let mutableCustomerProfile = JSON.parse(JSON.stringify(this.props.accountData));
                    const edits = {
                      firstName: this.state.presentFirstName,
                      lastName: this.state.presentLastName
                    };
                    this.props.refreshCallback(edits, null);
                    mutableCustomerProfile.firstName = this.state.presentFirstName;
                    mutableCustomerProfile.lastName = this.state.presentLastName;
                    this.props.saveHandler(mutableCustomerProfile);
                  }}
                >
                  {this.translator.t('save_changes_label')}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </fieldset>
    );
  }
}

export default PersonalNameEditor;
