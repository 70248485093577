import { createLogic } from 'redux-logic';
import axios from 'axios';

import {
  GET_CARNET_USER_PRIVILEGES,
  GET_CARNET_USER_PRIVILEGES_SUCCESS,
  GET_CARNET_USER_PRIVILEGES_FAILURE
} from '../../actions/types';

let dataProvider = axios;

let requestConfig = {
  method: 'GET',
  url: `/privileges`,
  headers: {
    accept: 'application/json'
  }
};

const getCarnetUserPrivilegesLogic = createLogic({
  type: GET_CARNET_USER_PRIVILEGES,
  latest: true,
  processOptions: {
    dispatchReturn: true,
    successType: GET_CARNET_USER_PRIVILEGES_SUCCESS,
    failType: GET_CARNET_USER_PRIVILEGES_FAILURE
  },

  process ({ action }) {
    console.log('getCarnetUserPrivileges is processing an action >>>');
    console.log('type: ' + action.type);
    console.log('payload: ' + JSON.stringify(action.payload));

    return dataProvider(requestConfig).then(response => {
      console.log('got response for getCarnetUserPrivileges GET request >>> ');
      console.log(JSON.stringify(response.data, null, 1));
      return response.data;
    });
  }
});

export default [getCarnetUserPrivilegesLogic];
