import { createLogic } from 'redux-logic';
import axios from 'axios';
import {
  REFRESH_VEHICLE_HEALTH,
  REFRESH_VEHICLE_HEALTH_SUCCESS,
  REFRESH_VEHICLE_HEALTH_FAILURE
} from '../../actions/types';

let dataProvider = axios;

let requestConfig = {
  method: 'POST',
  url: `/vhr/refresh`,
  headers: {
    accept: 'application/json'
  }
};

const refreshVehicleHealthLogic = createLogic({
  type: REFRESH_VEHICLE_HEALTH,
  latest: true,
  processOptions: {
    dispatchReturn: true,
    successType: REFRESH_VEHICLE_HEALTH_SUCCESS,
    failType: REFRESH_VEHICLE_HEALTH_FAILURE
  },

  process ({ action }) {
    console.log('refreshVehicleHealth is processing an action >>>');
    console.log('type: ' + action.type);
    console.log('payload: ' + JSON.stringify(action.payload));

    return dataProvider(requestConfig)
      .then(response => {
        console.log('got response for refreshVehicleHealth >>> ');
        console.log(JSON.stringify(response.data, null, 1));
        return response.data;
      })
      .catch(error => {
        console.log('Error while processing refreshVehicleHealth');
        console.log(JSON.stringify(error, null, 1));
      });
  }
});

export default [refreshVehicleHealthLogic];
