import React, { Component } from 'react';
import Faq from 'react-faq-component';
import '../faqCanada/faqCA.scss';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import goback from '../../../assets/faq/goback.png';
import { historyBack } from './faqCAhome';
import { setTitle } from '../../../providers/documentTitleProvider';

export default class FamilyGuardianFaqCA extends Component {
  componentDidMount () {
    setTitle('document_titles.faq.guardian');
  }

  render () {
    let translator = getLocaleTranslator();
    return (
      <div className='faqCAheader' role='main' id='main-content'>
        <div id='exit-editing-link'>
          <span
            id='backButton'
            class='cwpBack'
            role='button'
            onClick={historyBack}
            onKeyPress={e => {
              e.preventDefault();
              if (e.key && (e.key === 'Enter' || e.key === ' ')) {
                historyBack(e);
              }
            }}
            tabIndex={0}
          >
            {translator.t('button.back')}
          </span>
        </div>
        <div className='message-header'>
          <div className='td-ms-header'>
            <h1>{translator.t('guardian_title')}</h1>
          </div>
        </div>
        <Faq data={data} />
      </div>
    );
  }
}

const data = {
  rows: [
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          How many Boundary Alerts will I receive?
        </div>
      ),
      content: ` 
      <span class='mdlyr'>Model Year 2022+:</span> A Boundary Alert lets you set a defined geographic zone on a map and when your car enters or exits the zone, you will be alerted via text and/or email. You can choose the time, date, and frequency of alerts you wish to receive on each boundary you set. Volkswagen Car-Net® allows you to create up to 10 Boundary Alerts per vehicle. However, only 4 can be active at a given time. To create a Boundary Alert, follow the steps below: 1. Log into your Volkswagen Car-Net® account on the mobile app or Volkswagen Car-Net® Web Portal. 2. Go to Guardian tab. Select Boundary Alerts and tap the “Create new Boundary Alert” button. 3. Now select the geographic zone you would like to create the boundary for by moving the red box on the map. This can also be changed to a circle. 4. Next, you can schedule the timeframe of the alert to be active or have it always on by selecting “Everyday” for the occurrence and “All Day” for the time. 5. Complete the contact information where you want the alerts to be sent and select how often you want to be notified. 6. Once you are satisfied with the alert, click “Set Boundary Alert.” It should be now visible under the Boundary Alerts section on the Guardian Services page. 
      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Why can I have only one Speed Alert set at one time?{' '}
        </div>
      ),
      content: ` <span class='mdlyr'>Model Year 2022+:</span> A Speed Alert is a notification that allows you to know when your vehicle exceeds a predefined speed. You are able to choose any speed up to 130 km/h, you are also able to customize your notification preferences for the speed alert. You are only able to have 1 Speed Alert active at a time. To create a Speed Alert, follow the steps below: 1. Log into your Volkswagen Car-Net® account on the mobile app or Volkswagen Car-Net® Web Portal. 2. Go to Guardian tab. Select Speed Alerts and tap the “Create new Speed Alert” button. 3. Now select the speed at which you like to be notified if your vehicle exceeds it. 4. Next, you can schedule the timeframe of the alert to be active or have it always on by selecting “Everyday” for the occurrence and “All Day” for the time. 5. Complete the contact information where you want the alerts to be sent and select how often you want to be notified. 6. Once you are satisfied with the alert, click “Set Speed Alert.” It should now be visible under the Speed Alerts section on the Guardian Services page.
      

      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          What is a Curfew Alert?{' '}
        </div>
      ),
      content: ` <span class='mdlyr'>Model Year 2022+:</span> A Curfew Alert will notify you when your vehicle is being driven during a specified time. You can choose to be alerted via text, email, or both. Please note while this alert is active your existing Speed Alert will be disabled, it will turn back on when the Curfew Alert is turned off. To create a Curfew Alert follow the steps below: </br>
      <ol>  
        <li>Log into your Volkswagen Car-Net® account on the mobile app or Volkswagen Car-Net Web Portal.</li>
        <li>All Guardian Alerts can be accessed through the mobile app by tapping on the Guardian tab at the bottom of the screen. Once on the Guardian page, select Curfew Alerts and tap the “Create new Curfew Alert” button. If you are using the Volkswagen Car-Net® Web Portal, to access Guardian alerts, click the Guardian Services link at the top of the page. Click “Create new Alert” within the Curfew Alerts section.</li>
        <li>Once you are on the “Create Curfew Alert” page fill in the required information.</li> 
        <li>Once you are satisfied with the alert, click “Set Curfew Alert.” It should be now visible under the Curfew Alerts section on the Guardian Services page.</li>
      </ol></br>
      
      
      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          What is a Valet Alert?{' '}
        </div>
      ),
      content: ` 
      <span class='mdlyr'>Model Year 2022+:</span>  A Valet Alert lets you set a defined geographic zone on a map and when your vehicle exits the zone you will be alerted via text and/or email. You can choose the frequency of alert and whether you would like to receive them via text, email, or both.</br>
       <ol>  
        <li>Log into your Volkswagen Car-Net® account on the mobile app.</li>
        <li>Go to Guardian tab and tap the “Create new Valet Alert” button </li>
        <li>Now view the map where your vehicle was last parked. You can toggle between your last vehicle location or your phone location to set your Valet alert.</li>
        <li>Once you are satisfied with the alert, click “Set Valet Alert”. It is now active. </li>
      </ol>      
      `
    }
  ]
};

export { FamilyGuardianFaqCA };
