/* eslint-disable complexity */
import React from 'react';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { fuelEconomylitersToKM, kmToMiles, litersToGallons } from './TripStatsUtil';
import { findIn, isNonEmptyObject } from '../../../util/utilityMethods';

const StatsType = Object.freeze({
  AVG_FUEL_ECONOMY: 'AVG_FUEL_ECONOMY',
  AVG_SPEED: 'AVG_SPEED',
  DRIVING_SCORE: 'DRIVING_SCORE',
  DISTANCE: 'DISTANCE'
});

const DaysOfWeek = Object.freeze(['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']);

export default class TripStatsTableWeekly extends React.Component {
  constructor () {
    super();
    this.translator = getLocaleTranslator();
  }

  render () {
    const translatedDayOfWeek = {
      [DaysOfWeek[0]]: this.translator.t('trips.day_sun'),
      [DaysOfWeek[1]]: this.translator.t('trips.day_mon'),
      [DaysOfWeek[2]]: this.translator.t('trips.day_tue'),
      [DaysOfWeek[3]]: this.translator.t('trips.day_wed'),
      [DaysOfWeek[4]]: this.translator.t('trips.day_thu'),
      [DaysOfWeek[5]]: this.translator.t('trips.day_fri'),
      [DaysOfWeek[6]]: this.translator.t('trips.day_sat')
    };

    const userIsFromUS = String(this.props.userCountry).toUpperCase() === 'US';

    let { avgFuelEconomy, avgSpeed, totalDistanceDriven, totalTrips } = findIn(
      'remoteTripDetails.consolidateTripSummary',
      this.props
    );

    if (userIsFromUS) {
      avgFuelEconomy = litersToGallons(avgFuelEconomy);
      avgSpeed = kmToMiles(avgSpeed);
      totalDistanceDriven = kmToMiles(totalDistanceDriven);
    } else {
      avgFuelEconomy = avgFuelEconomy ? fuelEconomylitersToKM(avgFuelEconomy) : avgFuelEconomy;
      avgSpeed = avgSpeed ? avgSpeed.toFixed(2) : avgSpeed;
      totalDistanceDriven = totalDistanceDriven ? totalDistanceDriven.toFixed(2) : totalDistanceDriven;
    }

    const { periodEnd, periodStart, statisticsForThisPeroid = [] } = this.props.graphData;

    const statistics = {};

    statisticsForThisPeroid.forEach(({ stats = [], type = '' }) => {
      statistics[type] = {};
      stats.forEach(({ key, value = null }) => {
        statistics[type][key] = value;
      });
    });

    return (
      <>
        <table className='table table-stripped'>
          <thead>
            <tr>
              <th>{this.translator.t('trips.day')}</th>
              <th>{this.translator.t('trips.average_mph_or_kmph_label')}</th>
              <th>{this.translator.t('trips.average_mpg_or_kml_label')}</th>
              <th>{this.translator.t('trips.distance_driven')}</th>
            </tr>
          </thead>
          <tbody>
            {userIsFromUS &&
              DaysOfWeek.map(dayOfWeek => {
                let speed_value = null,
                  fuelEconomy_value = null,
                  distance_value = null;
                if (statistics[StatsType.AVG_SPEED] && statistics[StatsType.AVG_SPEED][dayOfWeek]) {
                  speed_value = kmToMiles(statistics[StatsType.AVG_SPEED][dayOfWeek]);
                }

                if (statistics[StatsType.AVG_FUEL_ECONOMY] && statistics[StatsType.AVG_FUEL_ECONOMY][dayOfWeek]) {
                  fuelEconomy_value = litersToGallons(statistics[StatsType.AVG_FUEL_ECONOMY][dayOfWeek]);
                }

                if (statistics[StatsType.DISTANCE] && statistics[StatsType.DISTANCE][dayOfWeek]) {
                  distance_value = kmToMiles(statistics[StatsType.DISTANCE][dayOfWeek]);
                }

                return (
                  <tr>
                    <td>{translatedDayOfWeek[dayOfWeek]}</td>
                    <td>{speed_value && `${speed_value} ${this.translator.t('trips.speed_unit')}`}</td>
                    <td>{fuelEconomy_value && `${fuelEconomy_value} ${this.translator.t('trips.average_unit')}`}</td>
                    <td>
                      {distance_value && `${distance_value} ${this.translator.t('trips.miles_or_Kilometers_label')}`}
                    </td>
                  </tr>
                );
              })}
            {!userIsFromUS &&
              DaysOfWeek.map(dayOfWeek => (
                <tr>
                  <td>{translatedDayOfWeek[dayOfWeek]}</td>
                  <td>
                    {statistics[StatsType.AVG_SPEED] &&
                      statistics[StatsType.AVG_SPEED][dayOfWeek] &&
                      `${statistics[StatsType.AVG_SPEED][dayOfWeek]} ${this.translator.t('trips.speed_unit')}`}
                  </td>
                  <td>
                    {statistics[StatsType.AVG_FUEL_ECONOMY] &&
                      statistics[StatsType.AVG_FUEL_ECONOMY][dayOfWeek] &&
                      `${statistics[StatsType.AVG_FUEL_ECONOMY][dayOfWeek]} ${this.translator.t('trips.average_unit')}`}
                  </td>
                  <td>
                    {statistics[StatsType.DISTANCE] &&
                      statistics[StatsType.DISTANCE][dayOfWeek] &&
                      `${statistics[StatsType.DISTANCE][dayOfWeek]} ${this.translator.t(
                        'trips.miles_or_Kilometers_label'
                      )}`}
                  </td>
                </tr>
              ))}
          </tbody>
          <tfoot>
            <tr>
              <th>
                {totalTrips && (
                  <>
                    <span className='h3'>{totalTrips}</span>
                    <span className='small'>{this.translator.t('trips.total_trips')}</span>
                  </>
                )}
              </th>
              <th>
                {avgSpeed && (
                  <>
                    <span className='h3'>{userIsFromUS ? kmToMiles(avgSpeed) : avgSpeed}</span>
                    <span className='small'>{this.translator.t('trips.average_mph_or_kmph_label')}</span>
                  </>
                )}
              </th>
              <th>
                {avgFuelEconomy && (
                  <>
                    <span className='h3'>
                      {userIsFromUS ? litersToGallons(avgFuelEconomy) : fuelEconomylitersToKM(avgFuelEconomy)}
                    </span>
                    <span className='small'>{this.translator.t('trips.average_mpg_or_kml_label')}</span>
                  </>
                )}
              </th>
              <th>
                {totalDistanceDriven && (
                  <>
                    <span className='h3'>{userIsFromUS ? kmToMiles(totalDistanceDriven) : totalDistanceDriven}</span>
                    <span className='small'>{this.translator.t('trips.distance_driven')}</span>
                  </>
                )}
              </th>
            </tr>
          </tfoot>
        </table>
      </>
    );
  }
}
