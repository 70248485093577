import React from 'react';
import PropTypes from 'prop-types';
import { getStorePricing } from '../../providers/EstoreProvider';
import { findErrorMessage } from '../../../../util/i18n/i18nService';
import { BuyPlansContext } from '../BuyPlansContext';
import Price from './Price';
import { Parser } from 'html-to-react';
import Spinner from '../../Spinner';
import { getPriceDescription } from '../buyPlansUtil';
import { BuyPlanPages } from '..';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import iconShoppingCart from '../../../../assets/buyPlans/shopping-cart.svg';

export default class PackagePricing extends React.Component {
  static contextType = BuyPlansContext;

  static propTypes = {
    showAlert: PropTypes.func.isRequired,
    onClickAddToCart: PropTypes.func
  };

  constructor (props) {
    super(props);

    this.parser = new Parser();
  }

  state = {
    planPricing: [],
    additionalDisclaimers: [],
    loading: false,

    cartItemToReplace: null,
    showModalGotoCart: false
  };

  componentDidMount () {
    this.initData();
  }

  initData = async () => {
    const { category, subCategory, provider } = this.context.selectedPlan || {};

    this.setState({ loading: true });
    this.initAdditionalDisclaimers();

    try {
      const data = await getStorePricing(category, subCategory, provider?.name, this.context.cart?.cartId);

      if (Array.isArray(data) && data.length > 0) {
        this.setState({ loading: false, planPricing: data[0] });
      }
    } catch (error) {
      this.setState({ loading: false });
      const message = findErrorMessage(error);
      this.props.showAlert(message);
    }
  };

  initAdditionalDisclaimers = async () => {
    const plan = this.context.selectedPlan;
    const marketingConfiguration = this.context.marketingConfigurationHash?.[plan?.marketingConfigurationId] || {};
    const additionalDisclaimerList = marketingConfiguration?.additionalDisclaimerList;

    if (!Array.isArray(additionalDisclaimerList)) {
      return;
    }

    const additionalDisclaimers = [];
    for (const additionalDisclaimer of additionalDisclaimerList) {
      const { disclaimerNumber, disclaimerText, disclaimerContentUrls } = additionalDisclaimer;

      if (Array.isArray(disclaimerContentUrls) && typeof disclaimerText === 'string') {
        const disclaimerHtml = disclaimerContentUrls.reduce((htmlText, contentUrl) => {
          const { disclaimerTextToHyperlink, disclaimerURL } = contentUrl;
          return htmlText.replace(
            disclaimerTextToHyperlink,
            `<a href="${disclaimerURL}" target="_blank" class="cwp" rel="noopener noreferrer">${disclaimerTextToHyperlink}</a>`
          );
        }, disclaimerText);

        additionalDisclaimers.push({ disclaimerNumber, disclaimerHtml });
      }
    }

    this.setState({ additionalDisclaimers });
  };

  addToCart = async cartItem => {
    if (this.props.onClickAddToCart) {
      this.props.onClickAddToCart(cartItem);

      return;
    }

    try {
      await this.context.addToCart(cartItem);
      this.setState({ showModalGotoCart: true });
    } catch (ex) {
      this.props.showAlert(findErrorMessage(ex) || ex.message || ex);
    }
  };

  onClickAddToCart = cartItem => {
    if (this.hasInCartWithDifferentPricingOption(cartItem)) {
      this.setState({ cartItemToReplace: cartItem });

      return;
    }

    this.addToCart(cartItem);
  };

  onClickReplaceItemInCart = () => {
    this.addToCart(this.state.cartItemToReplace);
    this.setState({ cartItemToReplace: null });
  };

  onClickGoToCart = () => this.context.redirectTo(BuyPlanPages.Cart);
  closeModalGoToCart = () => this.setState({ showModalGotoCart: false });
  closeModalReplaceItemInCart = () => this.setState({ cartItemToReplace: null });

  hasInCartWithDifferentPricingOption = cartItem => {
    const items = this.context?.cart?.items;

    return Array.isArray(items) && items.length > 0 && items.some(item => item.sku === cartItem.sku);
  };

  hasInCart = (sku, priceOptionId) => {
    const items = this.context?.cart?.items;

    return (
      Array.isArray(items) &&
      items.length > 0 &&
      items.some(item => item.sku === sku && item.priceOptionId === priceOptionId)
    );
  };

  render () {
    const { contentURL, translator, marketingConfigurationHash, selectedPlan: plan } = this.context;
    const marketingConfiguration = marketingConfigurationHash?.[plan?.marketingConfigurationId] || {};
    const planPricing = this.state.planPricing;
    const pricingDisclaimerList = marketingConfiguration?.pricingDisclaimerList;

    if (this.state.loading) {
      return <Spinner />;
    }

    return (
      <div className='container d-flex flex-column'>
        {Array.isArray(planPricing) &&
          planPricing.map((pp, ppIndex) => {
            const sku = pp?.package?.sku;
            const pricingOptions = pp?.package?.pricingOptions || [];
            return (
              <React.Fragment key={`planPricing-${ppIndex}`}>
                {pricingOptions.map((po, poIndex) => {
                  const inCart = this.hasInCart(sku, po.priceOptionId);

                  return (
                    <article
                      key={`pricingOption-${poIndex}`}
                      className='row mx-0 mt-3 justify-content-between align-items-start border-bottom'
                    >
                      <h2 className='h1 col-12 col-md-auto m-0 font-weight-light'>
                        <Price currency={po.currency} value={po.price} />
                      </h2>
                      <article className='mb-3 mr-auto col text-left d-flex flex-column px-4'>
                        <img
                          src={'' + contentURL + marketingConfiguration?.summary?.providerLogo}
                          alt={marketingConfiguration?.summary?.providerName}
                          className='align-self-start'
                        />
                        <strong className='my-2'>{marketingConfiguration?.title}</strong>
                        <span className='mb-2 small'>{getPriceDescription(translator, po)}</span>
                        {Array.isArray(pricingDisclaimerList) &&
                          pricingDisclaimerList.map(pd => (
                            <span className='small text-muted'>{pd.disclaimerText}</span>
                          ))}
                      </article>
                      <button
                        type='button'
                        className={`cwp cwpPrimary mb-3 mb-md-auto ${inCart ? 'inCart' : ''}`}
                        onClick={() => this.onClickAddToCart({ sku, priceOptionId: po.priceOptionId, quantity: 1 })}
                        disabled={this.context.loading || inCart}
                      >
                        {inCart ? translator.t('buyplans_added_to_cart') : translator.t('buyplans_add_to_cart')}
                      </button>
                    </article>
                  );
                })}
              </React.Fragment>
            );
          })}
        {Array.isArray(this.state.additionalDisclaimers) &&
          this.state.additionalDisclaimers.map(ad => (
            <article key={`additionalDisclaimer-${ad.disclaimerNumber}`} className='text-muted small text-justify'>
              {this.parser?.parse(ad.disclaimerHtml)}
            </article>
          ))}

        <Modal
          isOpen={this.state.showModalGotoCart}
          onHide={this.closeModalGoToCart}
          centered={true}
          className='carnet-sg-modal'
          aria-live='polite'
        >
          <ModalBody className='d-flex flex-row align-items-center justify-content-between px-4'>
            <input
              type='image'
              src={iconShoppingCart}
              className='yellowShoppingCart'
              onClick={this.onClickGoToCart}
              alt={translator.t('buyplans_go_to_cart_text')}
            />
            <span className='h5'>{translator.t('buyplans_pricing_plans_item_added_to_cart_text')}</span>
          </ModalBody>
          <ModalFooter className='row mx-0 justify-content-between px-4'>
            <button type='button' className='modal-secondary-btn col-12 col-md-5' onClick={this.onClickGoToCart}>
              {translator.t('buyplans_go_to_cart_text')}
            </button>
            <button type='button' className='modal-primary-btn col-12 col-md-5' onClick={this.closeModalGoToCart}>
              {translator.t('buyplans_keep_shopping_text')}
            </button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.cartItemToReplace}
          onHide={this.closeModalReplaceItemInCart}
          centered={true}
          className='carnet-sg-modal'
          aria-live='polite'
        >
          <ModalHeader>{translator.t('buyplans_pricing_plans_similar_plan_in_cart_text')}</ModalHeader>
          <ModalBody className='h5'>{translator.t('buyplans_pricing_plans_similar_plan_alert_info')}</ModalBody>
          <ModalFooter className='d-flex flex-column align-items-center'>
            <button type='button' className='modal-primary-btn w-100' onClick={this.onClickReplaceItemInCart}>
              {translator.t('buyplans_yes_text')}
            </button>
            <button type='button' className='modal-secondary-btn w-100' onClick={this.closeModalReplaceItemInCart}>
              {translator.t('buyplans_cancel_text')}
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
