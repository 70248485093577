import {
  GET_STORE_PLAN_PRICING_INFO,
  GET_STORE_PLAN_PRICING_INFO_SUCCESS,
  GET_STORE_PLAN_PRICING_INFO_FAILURE
} from '../../actions/types';

const initialState = {
  planPricingInfo: null,
  cartItems: null,
  dataLoading: false
};

// Get Store Plans and  Cart Info for a particular vehicle
const storePlanPricingInfoReducer = (state = initialState, action = { payload: {} }) => {
  let newState = { ...state };
  let actionType = (action && action.type) || 'default';

  switch (actionType) {
    case GET_STORE_PLAN_PRICING_INFO:
      console.log(`storePlansPricingInfoReducer >> ${actionType}`);
      console.log('action.payload is in storePlansPricingInfoReducer is :::' + JSON.stringify(action.payload));
      newState = { ...initialState };
      return newState;

    case GET_STORE_PLAN_PRICING_INFO_SUCCESS:
      console.log(`storePlansPricingInfoReducer >> ${actionType}`);
      console.log('action.payload is in storePlansPricingInfoReducer is :::' + JSON.stringify(action.payload));
      let responseData = action.payload || {};
      newState.planPricingInfo = responseData[0] || null;
      newState.cartItems = responseData[1] || null;
      newState.dataLoading = true;
      return newState;

    case GET_STORE_PLAN_PRICING_INFO_FAILURE:
      console.log(`storePlansPricingInfoReducer >> ${actionType}`);
      console.log('action.payload is in storePlansPricingInfoReducer is :::' + JSON.stringify(action.payload));
      newState = { ...initialState };
      return newState;

    default:
      return newState;
  }
};

export default storePlanPricingInfoReducer;
