import {
  UPDATE_VHR_CUSTOMER_PREFERENCES,
  UPDATE_VHR_CUSTOMER_PREFERENCES_SUCCESS,
  UPDATE_VHR_CUSTOMER_PREFERENCES_FAILURE,
  GET_VHR_CUSTOMER_PREFERENCES
} from '../../actions/types';

const initalState = {
  prefData: null
};

const updateVHRCustomerPreferencesReducer = (state = initalState, action = { payload: {} }) => {
  let newState = { ...state };
  let actionType = (action && action.type) || 'default';
  let responseData = action.payload || {};

  switch (actionType) {
    case GET_VHR_CUSTOMER_PREFERENCES:
    case UPDATE_VHR_CUSTOMER_PREFERENCES:
      newState = { ...initalState };
      newState['prefData'] = null; // reset upon update action
      return newState;

    case UPDATE_VHR_CUSTOMER_PREFERENCES_SUCCESS:
      let { data } = responseData;
      newState['prefData'] = data || null;
      return newState;

    case UPDATE_VHR_CUSTOMER_PREFERENCES_FAILURE:
      newState['prefData'] = false;
      return newState;
    default:
      return newState;
  }
};

export default updateVHRCustomerPreferencesReducer;
