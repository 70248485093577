import { getLocaleSync } from './languageProvider';
import { isUrlLocalized } from '../util/utilityMethods';
import Axios from 'axios';

// Manage all the things that need to be done around logging in/out
const login = () => {
  let newLocation = window.location.origin;

  let url = window.location.href;
  let localeOverride = null;
  let urlLocalizedResult = isUrlLocalized(url);
  if (urlLocalizedResult) {
    let userLanguage = urlLocalizedResult.split('/')[0].toLowerCase();
    let userCountry = urlLocalizedResult.split('/')[1].toUpperCase();
    localeOverride = `${userLanguage}-${userCountry}`;
    newLocation = newLocation + '/login?ui_locales=' + localeOverride;
    window.location = newLocation;
    return Promise.resolve();
  }

  newLocation = newLocation + '/login';
  window.location = newLocation;
  return Promise.resolve();
};

// make API request to clear
const logout = () => {
  // check locale first
  let localeData = getLocaleSync();
  let userCountryIsCA = localeData.userCountry.toLowerCase() === 'ca';
  let userLanguage = localeData.userLanguage.toLowerCase();

  // clear out any bits of session stuff being kept in localStorage
  window.localStorage.clear();

  return Axios.get('logout')
    .then(() => {
      console.log('success on logout API request');
    })
    .catch(err => {
      console.log('failure on logout API request');
      console.log(err);
    })
    .finally(() => {
      let newLocation = window.location.origin + '/logout';
      // special logout redirection behavior for CA users
      if (userCountryIsCA) {
        newLocation = 'https://www.vw.ca/en/why-vw/innovation-and-technology/car-net.html';
        if (userLanguage === 'fr') {
          newLocation = 'https://www.vw.ca/fr/pourquoi-vw/innovation-et-technologie/car-net.html';
        }
      }
      window.location.href = newLocation;
    });
};

export { login, logout };
