import React, { Component } from 'react';

import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { getEnrollmentStatus } from '../providers/GarageProvider';
import ModalInputPIN from './ModalInputPIN';
import ModalInputOTP from './ModalInputOTP';

const PIN_ENTRY_CANCELLED = 'PIN_ENTRY_CANCELLED';
const PIN_ENTRY_COMPLETE = 'PIN_ENTRY_COMPLETE';

const INITIAL_STATE = {
  PIN_ENTRY_REQUIRED: false,
  PIN_RESET_REQUIRED: false,
  ENROLLMENT_STATUS: {},
  PIN_ENTRY_RESOLUTION: null,
  PIN_ENTRY_REJECTION: null,
  RETRY_ON_SUCCESS: null
};

const retryDefault = () => Promise.resolve(PIN_ENTRY_COMPLETE);

const withSpinEntry = WrappedComponent => {
  return class extends Component {
    state = { ...INITIAL_STATE };

    constructor (props) {
      super(props);
      this.translator = getLocaleTranslator();
    }

    /* INTERNALS ALL RELATING TO TOGGLING PIN ENTRY ON/OFF, REQUESTING OTP, ETC. */
    // TOGGLE BETWEEN PIN ENTRY AND OTP
    togglePinEntryType = () => {
      if (this.state.PIN_ENTRY_REQUIRED) {
        return this.setState({
          PIN_ENTRY_REQUIRED: false,
          PIN_RESET_REQUIRED: true
        });
      }
      this.setState({
        PIN_ENTRY_REQUIRED: true,
        PIN_RESET_REQUIRED: false
      });
    };

    // HIDE PIN ENTRY AND RETURN TO ORIGINAL STATE
    toggleModalPIN = () => {
      console.log('withSpinEntry >> toggleModalPIN');
      // rejecting promise returned to requestor
      this.state.PIN_ENTRY_REJECTION(PIN_ENTRY_CANCELLED);
      this.setState({ ...INITIAL_STATE });
    };

    toggleModalPinAndCallback = () => {
      console.log('withSpinEntry >> toggleModalPinAndCallback');
      let retryPromise = this.state.RETRY_ON_SUCCESS();
      // resolve promise returned to requestor w/another promise (assuming the provided retry is async)
      this.state.PIN_ENTRY_RESOLUTION(retryPromise);
      this.setState({ ...INITIAL_STATE });
    };
    /* END OF INTERNALS */

    registerPromiseCatchers = async ({ asyncPromiseToMake, vehicleId = null, retry = retryDefault }) => {
      // return state to initial state for every new request
      this.setState({ ...INITIAL_STATE });

      if (!asyncPromiseToMake || !vehicleId) {
        return Promise.reject('registerPromiseCatchers__INVALID__PARAMETERS');
      }

      return new Promise((resolve, reject) => {
        asyncPromiseToMake
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            let errorData = (err && err.response && err.response.data) || 'Undefined Error';

            // async request required new PIN ENTRY
            if (errorData && errorData.custom === 'PIN_ENTRY_REQUIRED') {
              return getEnrollmentStatus()
                .then(result => {
                  // not able to get enrollment status, so just throw error
                  if (!result.vehicleEnrollmentStatus || !Array.isArray(result.vehicleEnrollmentStatus)) {
                    return reject(errorData);
                  }
                  let enrollmentStatusData = result.vehicleEnrollmentStatus.filter(
                    item => item.vehicleId === vehicleId
                  )[0];
                  if (!enrollmentStatusData || !enrollmentStatusData.vehicleId) {
                    return reject(errorData);
                  }
                  // set the state for PIN entry
                  this.setState({
                    ENROLLMENT_STATUS: { ...enrollmentStatusData },
                    PIN_ENTRY_REQUIRED: true,
                    RETRY_ON_SUCCESS: retry,
                    PIN_ENTRY_RESOLUTION: resolve,
                    PIN_ENTRY_REJECTION: reject
                  });
                })
                .catch(error => {
                  reject({ error });
                });
            }
            // REJECT OTHER ERROR TYPES
            reject({ errorData });
          });
      });
    };

    /* LIFE CYCLE CLEAN UP */
    componentWillUnmount () {
      if (this.state.PIN_ENTRY_RESOLUTION) {
        this.state.PIN_ENTRY_RESOLUTION.reject(PIN_ENTRY_CANCELLED);
      }
      this.setState({ ...INITIAL_STATE });
    }

    render () {
      return (
        <div className='withSpinEntryWrapper'>
          <WrappedComponent {...this.props} registerPromiseCatchers={this.registerPromiseCatchers} />

          {this.state.PIN_ENTRY_REQUIRED && (
            <ModalInputPIN
              toggle={this.toggleModalPIN}
              visible={this.state.PIN_ENTRY_REQUIRED}
              translator={this.translator}
              onSuccess={this.toggleModalPinAndCallback}
              toggleModalOTP={this.togglePinEntryType}
              enrollmentStatus={this.state.ENROLLMENT_STATUS}
            />
          )}

          {this.state.PIN_RESET_REQUIRED && (
            <ModalInputOTP
              toggle={this.togglePinEntryType}
              visible={this.state.PIN_RESET_REQUIRED}
              translator={this.translator}
              onSuccess={this.togglePinEntryType}
            />
          )}
        </div>
      );
    }
  };
};

export default withSpinEntry;
