import { createLogic } from 'redux-logic';
import axios from 'axios';
import {
  UPDATE_CUSTOMER_DETAILS,
  UPDATE_CUSTOMER_DETAILS_SUCCESS,
  UPDATE_CUSTOMER_DETAILS_FAILURE
} from '../../actions/types';

let dataProvider = axios;

const updateCustomerDetailsLogic = createLogic({
  type: UPDATE_CUSTOMER_DETAILS,
  latest: true,
  processOptions: {
    dispatchReturn: true,
    successType: UPDATE_CUSTOMER_DETAILS_SUCCESS,
    failType: UPDATE_CUSTOMER_DETAILS_FAILURE
  },

  process ({ action }) {
    let requestConfig = {
      method: 'PUT',
      url: `customer/update`,
      headers: {
        accept: 'application/json'
      },
      data: action.payload
    };

    return dataProvider(requestConfig)
      .then(response => {
        console.log('got response for updateCustomerDetails POST request >>> ', JSON.stringify(response.data, null, 1));
        return response.data;
      })
      .catch(error => {
        console.log('The REDUX Logic failed: ' + JSON.stringify(error, null, 1));
        console.log('Error while processing updateCustomerDetails');
        console.log(JSON.stringify(error, null, 1));
      });
  }
});

export default [updateCustomerDetailsLogic];
