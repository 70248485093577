import {
  GET_VHR_CUSTOMER_PREFERENCES,
  GET_VHR_CUSTOMER_PREFERENCES_SUCCESS,
  GET_VHR_CUSTOMER_PREFERENCES_FAILURE
} from '../../actions/types';

const initalState = {
  vhrPrefIds: null,
  vhrPrefData: null
};

const vhrCustomerPreferencesReducer = (state = initalState, action = { payload: {} }) => {
  let newState = { ...state };
  let actionType = (action && action.type) || 'default';
  let responseData = action.payload || {};

  switch (actionType) {
    case GET_VHR_CUSTOMER_PREFERENCES:
      newState = { ...initalState };
      return newState;

    case GET_VHR_CUSTOMER_PREFERENCES_SUCCESS:
      console.log('vhrCustomerPreferences in Reducer', JSON.stringify(responseData, null, 1));
      newState['vhrPrefIds'] = responseData[0] || null;
      newState['vhrPrefData'] = responseData[1] || null;
      return newState;
    case GET_VHR_CUSTOMER_PREFERENCES_FAILURE:
      newState['vhrPrefIds'] = null;
      newState['vhrPrefData'] = null;
      return newState;
    default:
      return newState;
  }
};

export default vhrCustomerPreferencesReducer;
