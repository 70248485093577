import React from 'react';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { Row, Col, Button } from 'reactstrap';
import Loader from '../common/loader';
import {
  getTosEnrollmentsMarketingConsent,
  updateTosEnrollmentsMarketingConsent
} from '../providers/AccountAndSecurityProvider';
import '../../reusable_cmp_lib/ThemedFormInputs/ThemedForm.scss';
import '../../reusable_cmp_lib/ThemedButtonsAssortment/ThemedButtonsAssortment.scss';
import './AccountManagement.scss';
import '../garage/YourProfile.scss';
import '../common/cwpRadioInputOverwrite.scss';
import { setTitle } from '../../../providers/documentTitleProvider';
import ThemedToast from '../../reusable_cmp_lib/ThemedToast/ThemedToast';
import resource from '../../../config/resource.json';
import { getLocaleSync } from '../../../providers/languageProvider';

class MarketingConsentEditor extends React.Component {
  constructor (props) {
    super(props);

    this.translator = getLocaleTranslator();
    const acceptLabel = this.translator.t('tos_carnet.accept');
    const declineLabel = this.translator.t('tos_carnet.decline');
    this.commonConsentOptions = [
      { label: acceptLabel, value: 'accept-radio-option1' },
      { label: declineLabel, value: 'decline-radio-option' }
    ];
    this.state = {
      consented: false,
      formDirty: false,
      loadingData: true,
      tost: null
    };
  }

  componentDidMount () {
    setTitle('document_titles.account_management.edit_marketing_consent');
    getTosEnrollmentsMarketingConsent()
      .then(data => {
        console.log('Successfull response back from getting MarketingConsent enrollment status... ');
        console.log(JSON.stringify(data));
        let acceptedMc = false;
        if (data && data.length > 0) {
          const recordZero = data[0];
          acceptedMc = recordZero && recordZero.tosStatus && recordZero.tosStatus === 'Accept';
        }
        this.setState({
          loadingData: false,
          consented: acceptedMc
        });
      })
      .catch(err => {
        console.log('Error thrown trying to get MarketingConsent enrollment: ');
        console.log(JSON.stringify(err));
        this.setState({
          loadingData: false
        });
        return;
      });
  }

  consentToggled = consentValue => {
    this.setState({
      consented: consentValue,
      formDirty: true
    });
  };

  saveChanges = () => {
    this.setState({
      loadingData: true
    });
    let savePayload = {
      accepted: this.state.consented
    };
    console.log('The savePayload is: ' + JSON.stringify(savePayload));
    updateTosEnrollmentsMarketingConsent(savePayload)
      .then(response => {
        console.log('Successful marketingConsents update');
        this.toggleSaveResultModel(true);
      })
      .catch(err => {
        console.log('Failed response to Save!');
        console.log(JSON.stringify(err));
        this.toggleSaveResultModel(false);
      })
      .finally(() => {
        setTimeout(() => {
          this.setState({
            loadingData: false
          });
        }, 2000);
      });

    //  this.setFocusBackToAccount();
  };

  setFocusBackToAccount = () => {
    try {
      document.getElementById('backButton').focus();
    } catch (err) {
      console.log('backToAccount not found');
    }
  };

  toggleSaveResultModel (success = false) {
    const tost = {};
    if (success) {
      tost.toastTitle = this.translator.t('acctmgmt_saved');
      tost.toastMsg = this.translator.t('acctmgmt_api_save_ok_msg');
    } else {
      tost.toastTitle = this.translator.t('acctmgmt_savefailed');
      tost.toastMsg = this.translator.t('acctmgmt_api_failed_msg');
    }
    this.setState({ tost });
  }

  updateRadioLabelsUserLang = currentLang => {
    const acceptLabel = this.translator.t('tos_carnet.accept');
    const declineLabel = this.translator.t('tos_carnet.decline');
    this.commonConsentOptions[0].label = acceptLabel;
    this.commonConsentOptions[1].label = declineLabel;
  };

  render () {
    //the way to create a Deep Clone (nested props)
    const usersLocaleSetting = this.translator.getCurrentLanguageCode();
    let usersLang = 'en'; //default value
    let initialChecked = this.state.consented === true ? 0 : 1;
    if (usersLocaleSetting) {
      if (usersLocaleSetting.startsWith('fr-')) {
        usersLang = 'fr';
      }
    }

    this.updateRadioLabelsUserLang(usersLang);

    const locale = getLocaleSync()?.locale;
    const tos_url = resource?.tos_url;

    return this.state.loadingData === true ? (
      <div id='acctmgmt-contentpane'>
        <Loader />
      </div>
    ) : (
      <div className='acctmgmt-contentpane'>
        {this.state.tost && (
          <ThemedToast
            title={this.state.tost.toastTitle || ''}
            msg={this.state.tost.toastMsg || ''}
            display={true}
            returnFocusAfterClose={false}
            onClosed={() => {
              this.setState({ tost: null });
              this.setFocusBackToAccount();
            }}
          />
        )}
        <div id='ghost-wrapper' class='vehicle-info-parent'>
          <div>
            <Row className='borderless'>
              <Col md='3'></Col>
              <Col md='9'>
                <div className='contentpane-title'>
                  <h2>{this.translator.t('acctmgmt_consent_forms')}</h2>
                </div>
              </Col>
            </Row>
            <Row className='borderless'>
              <Col md='3'></Col>
              <Col md='9'>
                <div class='editor-diatom-field'>
                  <h3 className='consent-title'>{this.translator.t('overall-mkting-consent')}</h3>
                  <br />
                  <article>
                    <div
                      className='consent-copy'
                      dangerouslySetInnerHTML={{
                        __html: this.translator.t('overall-mkting-consent-copy', {
                          contact: tos_url?.contact[locale],
                          find_dealer: tos_url?.find_dealer[locale],
                          privacy_policy: tos_url?.privacy_policy[locale]
                        })
                      }}
                    ></div>
                    {locale && !locale?.endsWith('CA') && (
                      <a
                        href={this.translator.t('overall-mkting-consent-info-url-base')}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='link-color'
                      >
                        {this.translator.t('overall-mkting-consent-info-url-base')}
                      </a>
                    )}
                  </article>
                </div>
              </Col>
            </Row>

            <Row className='borderless marginLeftAlot'>
              <Col md='3'></Col>
              <Col md='9'>
                <fieldset id='optionalPhoneNumber01Type'>
                  <legend>{this.translator.t('overall-consent-radio-label')}</legend>
                  <div
                    className='radioCwpOverwriteWrapper'
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.consentToggled(true);
                    }}
                  >
                    <input
                      id={'marketing_consent_accept'}
                      type='radio'
                      className='radioCwpOverwrite'
                      name='optionalPhoneNumber01Type'
                      checked={this.state.consented}
                      onChange={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.consentToggled(true);
                      }}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.consentToggled(true);
                      }}
                      onKeyPress={e => {
                        if (e.key && (/enter/gi.test(e.key.toString()) || e.key === ' ')) {
                          this.consentToggled(true);
                        }
                      }}
                      value={this.translator.t(this.commonConsentOptions[0].label)}
                    />
                    <label className='radioCwpOverwriteLabel' htmlFor={'marketing_consent_accept'}>
                      {this.translator.t(this.commonConsentOptions[0].label)}
                    </label>
                  </div>

                  <div
                    className='radioCwpOverwriteWrapper'
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.consentToggled(false);
                    }}
                  >
                    <input
                      id={'marketing_consent_decline'}
                      type='radio'
                      className='radioCwpOverwrite'
                      name='optionalPhoneNumber01Type'
                      checked={!this.state.consented}
                      onChange={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.consentToggled(false);
                      }}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.consentToggled(false);
                      }}
                      onKeyPress={e => {
                        if (e.key && (/enter/gi.test(e.key.toString()) || e.key === ' ')) {
                          this.consentToggled(false);
                        }
                      }}
                      value={this.translator.t(this.commonConsentOptions[1].label)}
                    />
                    <label className='radioCwpOverwriteLabel' htmlFor={'marketing_consent_decline'}>
                      {this.translator.t(this.commonConsentOptions[1].label)}
                    </label>
                  </div>
                </fieldset>
              </Col>
            </Row>

            <Row className='borderless'>
              <Col md='12'>
                <div class='vertical-spacer'></div>
              </Col>
            </Row>
            <Row className='borderless'>
              <Col md='12'>
                <div class='vertical-spacer'></div>
              </Col>
            </Row>
            <Row>
              <Col md='11'>
                <div className='buttonToTheRight'>
                  <Button
                    disabled={false === this.state.formDirty}
                    className='cwp'
                    color='primary'
                    onClick={this.saveChanges}
                  >
                    {this.translator.t('save_changes_label')}
                  </Button>
                </div>
              </Col>
              <Col md='1'></Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default MarketingConsentEditor;
