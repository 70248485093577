import React from 'react';
import TosUBI from '../self_enrollment/TosUBI';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { findVehicle, findEnrollmentProcedure } from '../providers/SelfEnrollmentProvider.js';
import { getVehicleContext } from '../../../providers/historyProvider';
import Loader from '../common/loader';
import './driveViewEnrollment.scss';

class DriveViewEnrollment extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      initialized: false
    };
  }

  componentDidMount () {
    const {
      match: { params }
    } = this.props;
    let vin = params.contextVin;
    this.callPrecursors(vin)
      .then(() => {
        this.setState({
          initialized: true
        });
      })
      .catch(err => {
        console.log('Error: DriveView TOs could not call precursor methods');
      });
  }

  callPrecursors = async vin => {
    await findVehicle(vin);
    await findEnrollmentProcedure();
  };

  handleSubmit = () => {
    window.location.assign('#/acct-mgmt/thirdparty');
  };

  render () {
    this.translator = getLocaleTranslator();
    return (
      (this.state.initialized && <TosUBI afterSubmit={this.handleSubmit} />) || (
        <div className='driveViewWaitingToLoad'>
          <Loader />
        </div>
      )
    );
  }
}

export default DriveViewEnrollment;
