import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PurchaseBlockReason } from '../buyPlansUtil';

export default function ModalPurchaseBlocked (props) {
  const {
    translator,
    purchaseBlockReason,
    isOpen = false,
    onClickClose = () => {},
    onClickPrimary = () => {},
    onClickSecondary = () => {}
  } = props;

  switch (purchaseBlockReason) {
    case PurchaseBlockReason.TRIAL_IN_CURRENT_PLAN:
      return (
        <Modal
          className='carnet-sg-modal'
          isOpen={isOpen}
          onHide={() => onClickClose()}
          centered={true}
          aria-label={translator.t('buyplans_arialabel_trial_text')}
        >
          <ModalHeader>{translator.t('buyplans_active_trial_text')}</ModalHeader>
          <ModalBody>{translator.t(PurchaseBlockReason.TRIAL_IN_CURRENT_PLAN)}</ModalBody>
          <ModalFooter>
            <button className='modal-primary-btn' onClick={() => onClickPrimary()}>
              {translator.t('button.okay')}
            </button>
          </ModalFooter>
        </Modal>
      );

    case PurchaseBlockReason.ACTIVATE_TRIAL_FIRST:
      return (
        <Modal
          className='carnet-sg-modal'
          isOpen={isOpen}
          onHide={() => onClickClose()}
          centered={true}
          aria-live='polite'
        >
          <ModalHeader>{translator.t('buyplans_free_wifi_trial_available')}</ModalHeader>
          <ModalBody>{translator.t('buyplans_activate_trial_first')}</ModalBody>
          <ModalFooter className='d-flex flex-column align-items-center'>
            <button className='modal-primary-btn w-100' onClick={() => onClickPrimary()}>
              {translator.t('buyplans_skip_trial')}
            </button>
            <button className='modal-secondary-btn w-100' onClick={() => onClickSecondary()}>
              {translator.t('cancel_btn_camel')}
            </button>
          </ModalFooter>
        </Modal>
      );

    default:
      return (
        <Modal className='carnet-sg-modal' isOpen={isOpen} onHide={() => onClickClose()} centered={true}>
          <ModalHeader>{purchaseBlockReason}</ModalHeader>
          <ModalBody>{purchaseBlockReason}</ModalBody>
          <ModalFooter>
            <button className='modal-primary-btn' onClick={() => onClickPrimary()}>
              {translator.t('button.okay')}
            </button>
          </ModalFooter>
        </Modal>
      );
  }
}

ModalPurchaseBlocked.propTypes = {
  translator: PropTypes.object.isRequired,
  purchaseBlockReason: PropTypes.oneOf(Object.values(PurchaseBlockReason)).isRequired,
  isOpen: PropTypes.bool,
  onClickClose: PropTypes.func.isRequired,
  onClickPrimary: PropTypes.func.isRequired,
  onClickSecondary: PropTypes.func
};
