import React, { Component } from 'react';
import TripStatsHeader from './TripStatsHeader';
import { getLocaleSync } from '../../../providers/languageProvider';
import axios from 'axios';

class MQBTripStats extends Component {
  constructor () {
    super();
    this.state = {
      vehicleDetails: '',
      userPrivileges: ''
    };
  }

  componentDidMount () {
    let locale = getLocaleSync();
    this.userCountry = locale ? locale.userCountry.toUpperCase() : 'US';
    axios.get(`/vehicle`).then(res => {
      this.setState({ vehicleDetails: res.data.data });
    });

    axios.get(`/privileges`).then(res => {
      this.setState({ userPrivileges: res.data.data });
    });
  }
  render () {
    let userRole = this.state.userPrivileges && this.state.userPrivileges.roles;
    let vin = this.state.vehicleDetails && this.state.vehicleDetails.vin;
    return (
      <div className='rts-home'>
        <TripStatsHeader userRole={userRole} userCountry={this.userCountry} vin={vin} locale={this.props.locale} />
      </div>
    );
  }
}

export default MQBTripStats;
