import React from 'react';
import PropTypes from 'prop-types';
import iconAdd from '../../../../assets/icons/add.svg';
import iconRemove from '../../../../assets/icons/remove.svg';
import { findErrorMessage } from '../../../../util/i18n/i18nService';
import { getCountries, getStatesByCountryCode, initAddNewPayment } from '../../providers/EstoreProvider';
import { getLocaleSync } from '../../../../providers/languageProvider';
import { ADDRESS_VERIFICATION_STATUS, validateAddress } from '../../providers/ThirdPartyProvider';
import { Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledAlert } from 'reactstrap';
import { hidePaymentForm, initiatePaymentForm } from '../../buyPlans/providers/cwPaymentProxyProvider';
import { isNonEmptyObject } from '../../../../util/utilityMethods';
import { getErrorCodeDesc } from '../../buyPlans/buyPlansUtil';

const PostalCodeInputProps = Object.freeze({
  US: {
    minLength: 9,
    maxLength: 10
  },
  CA: {
    minLength: 6
  }
});

export default class FormAddNewPayment extends React.Component {
  static propTypes = {
    translator: PropTypes.object.isRequired,
    availablePaymentoptions: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  state = {
    alertMessage: null,
    countries: [], //  Dropdown options for country.
    states: {}, //  Dropdown options for states/provience. This is hashed object where key is coutnryCode and value is array of states/provience.

    useNewAddress: false,
    newAddress: {
      //  newAddress is object that captures the input of new address form.
      cardHolderName: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      postalCode: ''
    },
    isNewAddressReady: false, // This is set to true once input address has been validated and ready to be sent to CW.
    postalCodeValidationRequired: false,

    suggestedAddresses: null, //  Attribute of address verification response from TPI service.
    verificationStatus: null //  Attribute of address verification response from TPI service.
  };

  componentDidMount () {
    this.initData();
  }

  initData = async () => {
    const locale = getLocaleSync();
    this.setState({ userCountry: locale?.userCountry?.toUpperCase() });

    try {
      const { countries } = await getCountries();
      this.setState({ countries });

      const stateHash = {};
      if (Array.isArray(countries)) {
        for (const { countryCode } of countries) {
          const { states } = await getStatesByCountryCode(countryCode);

          if (Array.isArray(states)) {
            stateHash[countryCode] = states;

            this.setState({ states: stateHash });
          }
        }
      }
    } catch (error) {
      this.setState({ alertMessage: findErrorMessage(error) });
    }
  };

  toggleNewAddressForm = () => {
    this.setState({
      useNewAddress: !this.state.useNewAddress,
      isNewAddressReady: false,
      suggestedAddresses: null,
      verificationStatus: null
    });
  };

  onSubmitNewAddress = () => {
    const isValid = this.validateForm();

    if (isValid) {
      this.validateAddressWithTPI();
    }
  };

  //  Validates addresss input HTML form.
  validateForm = () => {
    const form = document.getElementById('newAddressForm');

    const isValid = form?.checkValidity();
    if (isValid !== true) {
      form?.reportValidity();
    }

    return isValid;
  };

  //  Validates address from backend using TPI service.
  validateAddressWithTPI = async () => {
    const { cardHolderName, ...address } = this.state.newAddress || {};

    if (!address?.address2) {
      delete address.address2;
    }

    try {
      const { suggestedAddresses, verificationStatus } = await validateAddress(address);

      this.setState({ suggestedAddresses, verificationStatus, postalCodeValidationRequired: true });
    } catch (error) {
      this.setState({ alertMessage: findErrorMessage(error) });
    }
  };

  onChangeNewAddress = e => {
    const { name, value } = e.currentTarget;

    const newAddress = { ...this.state.newAddress };

    newAddress[name] = value;

    this.setState({ newAddress, suggestedAddresses: null, verificationStatus: null, isNewAddressReady: false });
  };

  setNewAddress = address => {
    const newAddress = Object.assign({}, this.state.newAddress, address);
    this.setState({ newAddress, suggestedAddresses: [] });

    setTimeout(() => this.setState({ isNewAddressReady: this.validateForm() }), 500);
  };

  isSuggestedAddressSameAsEntered = () => {
    const { newAddress, suggestedAddresses } = this.state;

    return (
      Array.isArray(suggestedAddresses) &&
      suggestedAddresses.length === 1 &&
      JSON.stringify(newAddress).toUpperCase() === JSON.stringify(suggestedAddresses[0]).toUpperCase()
    );
  };

  clearAlerts = () => this.setState({ alertMessage: null });

  getAlerts = () => {
    if (this.state.alertMessage) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts} aria-live='polite' className='w-100'>
          {this.state.alertMessage}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  onChangePaymentType = async e => {
    const optionValue = e.currentTarget.value;
    const useDifferentAddress = this.state.useNewAddress;

    const callbackURL = window.location.origin + '/user/store/completewallet';
    //const callbackURL = 'https://w-h-s.spr.us00.uat.con-veh.net' + '/user/store/completewallet';

    let initiateWalletData = {
      paymentOptionCode: optionValue,
      useDifferentAddress,
      callbackURL
    };

    if (useDifferentAddress) {
      const newAddress = this.state.newAddress || {};

      initiateWalletData.billingAddress = {
        cardHolderName: newAddress.cardHolderName,
        addressLine1: newAddress.address1,
        addressLine2: newAddress.address2 || undefined,
        zipCode: newAddress.postalCode,
        city: newAddress.city,
        state: newAddress.state,
        country: newAddress.country
      };
    } else {
      const { firstName, lastName, address } = this.props.customer || {};

      initiateWalletData.billingAddress = {
        cardHolderName: `${firstName} ${lastName}`,
        addressLine1: address.addressLine1,
        addressLine2: address.addressLine2 || undefined,
        zipCode: address.zipCode,
        city: address.city,
        state: address.state,
        country: address.country
      };
    }

    try {
      const response = await initAddNewPayment(initiateWalletData);
      /* // testing code
      response = {
        data: {
          data: {
            baseURL: 'https://uat-fspay.vwcredit.com/web-api/',
            paymentOptionCode: 'AMEX',
            authorizationToken:
              'eyJ0b2tlbkV4SUQiOiIzMzc4NzY0MjI0ODY5MjcwIiwib3JpZ2luIjoiaHR0cHM6Ly91YXQtZnNwYXkudndjcmVkaXQuY29tLGh0dHA6Ly9sb2NhbGhvc3QiLCJ0aW1lc3RhbXAiOiIyMDIwMDgyNTE5MzQyMSIsInRva2VuU2NoZW1lIjoiR1VJRCIsImF1dGhlbnRpY2F0aW9uS2V5IjoiS0wvdUtHd3RpeUN6YTVBN202WUIraEd4TGxUYTk1eHRBUHEvMTNCd3dhMD0iLCJzdG9yZU9wdGlvbk1vZGUiOnRydWUsInVuaXF1ZVJlZmVyZW5jZSI6bnVsbCwiY2FsbGJhY2tVcmwiOiJodHRwOi8vbG9jYWxob3N0OjgwMDAvIy9idXktcGxhbnMvNGUxNGJhYWYtMDRkNC0zYWE4LWJkNGYtNzM1Y2ZmMTU4ZmU3dXNlci9zdG9yZS9jb21wbGV0ZXdhbGxldCJ9',
            paymentProviderResponse:
              'eyJ0b2tlbkV4SUQiOiIzMzc4NzY0MjI0ODY5MjcwIiwib3JpZ2luIjoiaHR0cHM6Ly91YXQtZnNwYXkudndjcmVkaXQuY29tLGh0dHA6Ly9sb2NhbGhvc3QiLCJ0aW1lc3RhbXAiOiIyMDIwMDgyNTE5MzQyMSIsInRva2VuU2NoZW1lIjoiR1VJRCIsImF1dGhlbnRpY2F0aW9uS2V5IjoiS0wvdUtHd3RpeUN6YTVBN202WUIraEd4TGxUYTk1eHRBUHEvMTNCd3dhMD0iLCJzdG9yZU9wdGlvbk1vZGUiOnRydWUsInVuaXF1ZVJlZmVyZW5jZSI6bnVsbCwiY2FsbGJhY2tVcmwiOiJodHRwOi8vbG9jYWxob3N0OjgwMDAvIy9idXktcGxhbnMvNGUxNGJhYWYtMDRkNC0zYWE4LWJkNGYtNzM1Y2ZmMTU4ZmU3dXNlci9zdG9yZS9jb21wbGV0ZXdhbGxldCJ9'
          }
        }
      };
      */
      // console.log('what is the wallet initiate response >>> ');
      // console.log(JSON.stringify(response));

      this.setState({ initAddPaymentResponseData: response?.data });

      const { baseURL, authorizationToken, paymentOptionCode } = response?.data?.data || response?.data || {};

      if (baseURL) {
        initiatePaymentForm(baseURL, authorizationToken, paymentOptionCode);

        return;
      } else if (isNonEmptyObject(response?.data?.error)) {
        this.setState({ alertMessage: getErrorCodeDesc(response?.data?.error?.errorCode) });
      }
    } catch (err) {
      this.setState({ alertMessage: findErrorMessage(err) });
    }
  };

  render () {
    const { translator, availablePaymentoptions, customer } = this.props;
    const {
      userCountry,
      useNewAddress = false,
      newAddress = {},
      verificationStatus,
      suggestedAddresses,
      isNewAddressReady
    } = this.state;

    const postalCodeValidationProps =
      this.state.postalCodeValidationRequired === true
        ? PostalCodeInputProps[this.state.newAddress?.country || userCountry]
        : {};

    return (
      <section className='container-fluid my-5 py-4 border'>
        <h2 className='font-weight-light mx-auto my-3'>{translator.t('buyplans.label.add-a-new-payment')}</h2>
        {this.getAlerts()}
        <section className='row w-100 mt-5 justify-content-between'>
          <article className='col-12 col-md-4 d-flex flex-column py-3'>
            <h3 className='h5'>{translator.t('buyplans.label.billing-address')}</h3>
            {customer && !this.state.useNewAddress && (
              <>
                <span>{`${customer?.firstName} ${customer?.lastName}`}</span>
                <span>{customer?.address?.addressLine1}</span>
                {customer?.address?.addressLine2 && <span>{customer?.address?.addressLine2}</span>}
                <span>{`${customer?.address?.city}, ${customer?.address?.state}`}</span>
                <span>{customer?.address?.zipCode}</span>
                <span>{customer?.address?.country}</span>
              </>
            )}
            <button
              type='button'
              className='carnetLink col-auto align-self-start px-0 mt-4'
              onClick={this.toggleNewAddressForm}
            >
              <img src={this.state.useNewAddress ? iconRemove : iconAdd} />
              &nbsp;
              <u>{translator.t('buyplans.label.add-a-new-address')}</u>
            </button>
            {this.state.useNewAddress === true && (
              <form
                id='newAddressForm'
                className='d-flex flex-column align-items-stretch mt-5 mx-auto'
                onSubmit={e => e.preventDefault()}
              >
                <div className='form-group'>
                  <label htmlFor='txtCardHolderName' className='small'>
                    {translator.t('buyplans.label.full-name')}
                  </label>
                  <input
                    name='cardHolderName'
                    id='txtCardHolderName'
                    type='text'
                    value={newAddress.cardHolderName}
                    required={true}
                    onChange={this.onChangeNewAddress}
                    className='cwp'
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='lstCountry' className='small'>
                    {translator.t('buyplans.label.country')}
                  </label>
                  <select
                    name='country'
                    id='lstCountry'
                    value={newAddress.country}
                    required={true}
                    onChange={this.onChangeNewAddress}
                    className='cwp'
                  >
                    <option value=''></option>
                    {this.state.countries?.map(c => (
                      <option value={c.countryCode}>{c.countryDesc}</option>
                    ))}
                  </select>
                </div>
                <div className='form-group'>
                  <label htmlFor='txtAddressLine1' className='small'>
                    {translator.t('buyplans.label.address-line-1')}
                  </label>
                  <input
                    name='address1'
                    id='txtAddressLine1'
                    type='text'
                    value={newAddress.address1}
                    required={true}
                    onChange={this.onChangeNewAddress}
                    className='cwp'
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='txtAddressLine2' className='small'>
                    {translator.t('buyplans.label.address-line-2')}
                  </label>
                  <input
                    name='address2'
                    id='txtAddressLine2'
                    type='text'
                    value={newAddress.address2}
                    onChange={this.onChangeNewAddress}
                    className='cwp'
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='txtCity' className='small'>
                    {translator.t('buyplans.label.city')}
                  </label>
                  <input
                    name='city'
                    id='txtCity'
                    type='text'
                    value={newAddress.city}
                    required={true}
                    onChange={this.onChangeNewAddress}
                    className='cwp'
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='lstState' className='small'>
                    {translator.t('buyplans.label.state')}
                  </label>
                  <select
                    name='state'
                    id='lstState'
                    value={newAddress.state}
                    required={true}
                    onChange={this.onChangeNewAddress}
                    className='cwp'
                  >
                    <option value=''></option>
                    {this.state.newAddress?.country &&
                      this.state.states[this.state.newAddress?.country]?.map(p => (
                        <option value={p.stateCode}>{p.stateDesc}</option>
                      ))}
                  </select>
                </div>
                <div className='form-group'>
                  <label htmlFor='txtPostalCode' className='small'>
                    {translator.t('buyplans.label.zip-code')}
                  </label>
                  <input
                    name='postalCode'
                    id='txtPostalCode'
                    type='text'
                    value={newAddress.postalCode}
                    required={true}
                    onChange={this.onChangeNewAddress}
                    className='cwp'
                    onInput={e => e.currentTarget.setCustomValidity('')}
                    onInvalid={e => e.currentTarget.setCustomValidity(translator.t('buyplans_invalid_zipcode'))}
                    {...postalCodeValidationProps}
                  />
                </div>
                <button type='button' className='cwp cwpPrimary mx-auto' onClick={this.onSubmitNewAddress}>
                  {translator.t('buyplans_verify_address')}
                </button>
              </form>
            )}
          </article>
          {(useNewAddress === false || isNewAddressReady) && (
            <article className='col-12 col-md-6 d-flex flex-column py-3'>
              <span>{translator.t('buyplans.label.prepaid-not-accepted-part1')}</span>
              <span>{translator.t('buyplans.label.prepaid-not-accepted-part2')}</span>
              {Array.isArray(availablePaymentoptions) && (
                <select className='cwp mt-4' required={true} autoFocus={true} onChange={this.onChangePaymentType}>
                  <option value={''}>{translator.t('buyplans.label.card-type')}</option>
                  {availablePaymentoptions.map(({ name, code }) => (
                    <option value={code}>{name}</option>
                  ))}
                </select>
              )}
              <div id='paymentFormBackground' onClick={() => hidePaymentForm()}>
                <div id='cwPaymentFormDiv'></div>
              </div>
            </article>
          )}
        </section>
        {verificationStatus === ADDRESS_VERIFICATION_STATUS.INVALID && (
          <Modal
            className='carnet-sg-modal'
            isOpen={true}
            onHide={() => this.setState({ verificationStatus: null })}
            centered={true}
          >
            <ModalHeader>{translator.t('generic_modals.unknown_address.title')}</ModalHeader>
            <ModalBody className='d-flex flex-column'>
              <span className='mb-4'>{translator.t('generic_modals.unknown_address.body')}</span>
              <span>{newAddress.address1}</span>
              {newAddress.address2 && <span>{newAddress.address2}</span>}
              <span>{`${newAddress.city}`}</span>
              <span>{`${newAddress.state} - ${newAddress.postalCode}`}</span>
              <span>{newAddress.country}</span>
            </ModalBody>
            <ModalFooter>
              <button className='modal-primary-btn' onClick={() => this.setState({ verificationStatus: null })}>
                {translator.t('button.okay')}
              </button>
            </ModalFooter>
          </Modal>
        )}
        {verificationStatus === ADDRESS_VERIFICATION_STATUS.VERIFIED &&
          Array.isArray(suggestedAddresses) &&
          suggestedAddresses.length > 0 && (
            <Modal className='carnet-sg-modal' isOpen={true} centered={true}>
              <ModalHeader>
                <span className='h2'>{translator.t('buyplans_verify_address')}</span>
              </ModalHeader>
              <ModalBody className='row justify-content-around'>
                {!this.isSuggestedAddressSameAsEntered() && (
                  <article className='col-auto col-md-6 d-flex flex-column mb-3 border border-light p-3'>
                    <span>{newAddress.address1}</span>
                    {newAddress.address2 && <span>{newAddress.address2}</span>}
                    <span>{newAddress.city}</span>
                    <span>{`${newAddress.state} ${newAddress.postalCode}`}</span>
                    <span>{newAddress.country}</span>
                    <button className='modal-secondary-btn mt-3' onClick={() => this.setNewAddress(newAddress)}>
                      {translator.t('buyplans_use_this_address')}
                    </button>
                  </article>
                )}
                {suggestedAddresses.map(suggestedAddress => (
                  <article className='col-auto col-md-6 d-flex flex-column mb-3 border border-light p-3'>
                    <span>{suggestedAddress.address1}</span>
                    {suggestedAddress.address2 && <span>{suggestedAddress.address2}</span>}
                    <span>{suggestedAddress.city}</span>
                    <span>{`${suggestedAddress.state} ${suggestedAddress.postalCode}`}</span>
                    <span>{suggestedAddress.country}</span>
                    <button className='modal-secondary-btn mt-3' onClick={() => this.setNewAddress(suggestedAddress)}>
                      {translator.t('buyplans_use_this_address')}
                    </button>
                  </article>
                ))}
              </ModalBody>
            </Modal>
          )}
      </section>
    );
  }
}
