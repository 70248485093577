import Axios from 'axios';
import PubSub from 'pubsub-js';
import timer from '../util/timer';

const SESSION_INACIVITY_TIME_OUT = 1000 * 60 * 12;
const sessionTimer = new timer(SESSION_INACIVITY_TIME_OUT);
let promiseInProgress = null;

const getSessionInfoRequest = async () => {
  let axioConfig = {
    url: 'cwpmeta/session',
    method: 'GET'
  };

  try {
    let sessionResponse = await Axios(axioConfig);
    sessionTimer.start(callbackAfterTimeout);
    PubSub.publish('userSession.start');
    return Promise.resolve(sessionResponse);
  } catch (e) {
    return Promise.reject('ERROR_NO_SESSION_AVAILABLE');
    PubSub.publish('userSession.stop');
  } finally {
    promiseInProgress = null;
  }
};

const getCwpSessionInfo = async () => {
  if (promiseInProgress) {
    return promiseInProgress;
  }
  promiseInProgress = getSessionInfoRequest();
  return promiseInProgress;
};

// restart the session timer (everytime we xhr)
const refreshSessionTimer = async () => {
  console.log('\nsessionProvider >> refreshSessionTimer >> \n');
  sessionTimer.stop();
  if (sessionRefreshCallbacks.length) {
    callbackAfterRefresh();
    sessionTimer.start(callbackAfterTimeout);
  }
};
const sessionTimeoutCallbacks = [];
const registerWithSessionTimeout = cb => {
  sessionTimeoutCallbacks.push(cb);
};
const callbackAfterTimeout = () => {
  sessionTimeoutCallbacks.forEach(cb => cb());
};
const sessionRefreshCallbacks = [];
const registerWithSessionRefresh = cb => {
  sessionRefreshCallbacks.push(cb);
};
const callbackAfterRefresh = () => {
  sessionRefreshCallbacks.forEach(cb => cb());
};

// hard update on session (both timer and session)
const refreshSession = async () => {
  console.log('\nsessionProvider >> refreshSession >> \n');
  sessionTimer.stop();
  try {
    await Axios({ url: 'cwpmeta/session/refresh', method: 'POST' });
    if (sessionRefreshCallbacks.length) {
      callbackAfterRefresh();
      sessionTimer.start(callbackAfterTimeout);
    }
  } catch (e) {
    console.log('unable to refreshSession');
  }
};

// runs server logout in background
const endSession = async () => {
  sessionTimer.stop();
  PubSub.publish('userSession.stop');
  try {
    await Axios({ url: 'cwpmeta/session/expire', method: 'POST' });
    console.log('successfully expired session');
  } catch (e) {
    console.log('unable to expire session');
  }
};

export {
  getCwpSessionInfo,
  refreshSessionTimer,
  registerWithSessionTimeout,
  registerWithSessionRefresh,
  refreshSession,
  endSession
};
