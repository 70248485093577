import {
  KM_TO_MILES,
  PRIMARY_DRIVER_ROLE,
  SECONDARY_DRIVER_ROLE,
  UNKNOWN_DRIVER_ROLE
} from '../constants/carnet_constants';
import { getLocaleSync } from '../providers/languageProvider';
import { dateFormat, formatDateUsingOneOffUnstandardRequestedFormat } from './i18n/localeUtils';
import formatDate from 'date-fns/format';
import parseDate from 'date-fns/parse';
import array_constants from '../constants/array_constants.json';

const { invalidInputCharacters } = array_constants;
export const regExpOfInvalidInputCharacters = new RegExp(`[\\${invalidInputCharacters.join('\\')}]`, 'g');

export const isNullOrEmptyObject = anyObj => {
  return !anyObj || '{}' === JSON.stringify(anyObj);
};

export const isNonEmptyObject = anyObj => {
  if (!anyObj) {
    return false;
  }
  return Object.keys(anyObj).length > 0;
};

export const isBlankString = candidate => {
  return !candidate || String(candidate).trim() === '';
};

export const isEmailShaped = candidate => {
  if (!candidate) {
    return false;
  }
  const emailishRegex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
  return emailishRegex.test(candidate);
};

export const isValidNAPhoneNumber = candidate => {
  if (!candidate) {
    return false;
  }
  const NAPhoneRegex = new RegExp(/^1?\s?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
  return NAPhoneRegex.test(candidate);
};

export const isValidNAPhoneNumberNumeric = candidate => {
  if (!candidate) {
    return false;
  }
  const regex = new RegExp(/^[1]?([0-9]{10})$/);
  return regex.test(candidate);
};

export const redirectBrowserTo = url => {
  if (!url) {
    return;
  }
  window.location.replace(url);
};

export const parseAndLocaleFormatDBFormattedDate = (loadedDobStr, isoLocaleCode) => {
  if (isBlankString(loadedDobStr)) {
    return '';
  }
  if (!isoLocaleCode) {
    isoLocaleCode = 'en-US'; //default to this. User would be happier to see a date, albeit foreign format.
  }
  // console.log('in dobParsingFunction, read a loaded DOB string of: ' + loadedDobStr);
  const dateStrParts = loadedDobStr.split('-');
  const loadedYr = parseInt(dateStrParts[2]);
  let oneBasedMonth = parseInt(dateStrParts[0]);
  let zBasedMonth = oneBasedMonth - 1; //JS Date obj constructor requires a zero-based month
  const loadedDOM = parseInt(dateStrParts[1]);

  /*
  console.log(
    'After parsing of the loaded date str value, got: Y:' +
      loadedYr +
      ', Month: ' +
      zBasedMonth +
      ', Date: ' +
      loadedDOM
  );
  */
  //0: MM  (1-based),  1: dd , 2: yyyy
  let jsBirthDate = new Date(loadedYr, zBasedMonth, loadedDOM);
  if (!jsBirthDate) {
    console.log('ERROR: Could not parse the loaded birthdate for this User: ' + loadedDobStr);
    return '';
  }
  let epochBirthDate = jsBirthDate.getTime();
  if (isoLocaleCode === 'en-CA' || isoLocaleCode === 'fr-CA') {
    return formatDateUsingOneOffUnstandardRequestedFormat(epochBirthDate, isoLocaleCode);
  }
  const formattedBirthDate = epochBirthDate ? dateFormat(epochBirthDate, isoLocaleCode) : '';
  // console.log('dob-parsing-fxn derived a formattedBirthDate of: ' + formattedBirthDate);
  return formattedBirthDate;
};

/**
 * Find and return an item sought after within a structure, return false if not found
 * @param {String} objectPath - dot separated path to nested item within object
 * @param {*} findInObject - object to be searched (can be {} or [])
 */
export const findIn = (objectPath, findInObject = {}) => {
  if (typeof objectPath !== 'string' || !findInObject || typeof findInObject !== 'object') {
    console.log('bad objectPath or findInObject argument provided to the findIn method');
    return false;
  }

  let arrOfLocations = objectPath.split('.');
  let found = {};
  let stillLooking = true;
  while (stillLooking && arrOfLocations.length) {
    // grab the first location
    let d1 = arrOfLocations.shift();
    // find the first thing
    if (found[d1] || findInObject[d1]) {
      found = found[d1] || findInObject[d1];
    } else {
      // wasnot found, stop searching
      stillLooking = false;
      found = false;
    }
  }
  return found;
};

export const extractDealerUrlByType = (dlrUrlsList, typeCode) => {
  if (!dlrUrlsList || dlrUrlsList.length === 0) {
    return null;
  }
  let filtered = dlrUrlsList.filter(urlThing => urlThing.urlTypeCode === typeCode);
  if (!filtered || filtered.length === 0) {
    return null;
  }
  return filtered[0].url;
};

export const convertTime24ToTime12 = timeStr24 => {
  if (isBlankString(timeStr24)) {
    return '';
  }
  const time24Fmt = /^(\d{1,2})\:(\d{1,2})$/;
  let matchGroups = timeStr24.match(time24Fmt);
  if (!matchGroups || matchGroups.length < 3) {
    return '';
  }
  let hrsPart = matchGroups[1];
  let minsPart = matchGroups[2];
  let hours = parseInt(hrsPart);
  let mins = parseInt(minsPart);
  if (isNaN(hours) || isNaN(mins)) {
    return '';
  }
  let isPM = hours > 12;
  let designator = isPM ? 'PM' : 'AM';
  let clockHours = isPM ? hours % 12 : hours;
  let retval = clockHours + ':' + minsPart + designator;
  return retval;
};

export const isValidCanadianPostalCode = candidate => {
  if (isBlankString(candidate)) {
    return false;
  }
  const systemAcceptedPCFormat = new RegExp(/^([0-9A-Za-z]{3}\s[0-9A-Za-z]{3})$/);
  let validationResult = systemAcceptedPCFormat.test(candidate);
  return validationResult;
};

export const isValidUSZipFormat = candidate => {
  if (isBlankString(candidate)) {
    return false;
  }
  const fiveDigitTrad = new RegExp(/^([0-9]{5})$/);
  if (fiveDigitTrad.test(candidate) === true) {
    return true; //OK, its a 5-digit zip
  }
  //otherwise, it better be the Extended type format:
  const extendedModern = new RegExp(/^(\d{6}\-\d{4})$/);
  return extendedModern.test(candidate);
};

/**
 * Returns true if in-scope locale falls under the region that follows Metric-System
 */
export const isMetricSystem = () => {
  const locale = getLocaleSync();

  if (typeof locale === 'object' && typeof locale.locale === 'string') {
    return locale.locale.includes('CA');
  }

  return false;
};

export const kmToMiles = km => {
  return Math.round(km * KM_TO_MILES);
};

export const milesToKm = miles => {
  return Math.round(miles / KM_TO_MILES);
};

export const poll = (test, onTimeout, interval = 5000, retries = 24) => {
  setTimeout(async () => {
    if (await test()) {
      return;
    }

    retries--;

    if (retries > 0) {
      poll(test, onTimeout, interval, retries);
    } else if (onTimeout) {
      onTimeout();
    }
  }, interval);
};

export const celsiusToFahrenheit = temperature => Math.round((9 * temperature) / 5 + 32);

export const fahrenheitToCelsius = temperature => Math.round(((temperature - 32) * 5) / 9);

export const formatDateISO = date => formatDate(date, 'yyyy-MM-dd');

export const parseDateISO = dateISO => parseDate(dateISO, 'yyyy-MM-dd', new Date());

export const isUrlLocalized = url => {
  const urlsThatIndicateLocalization = ['en/ca', 'fr/ca'];
  const partialToReturn = urlsThatIndicateLocalization.filter(urlPartial => url.includes(urlPartial))[0] || false;
  return partialToReturn;
};

export const hasInvalidInputCharacters = inputString => {
  return regExpOfInvalidInputCharacters.test(inputString);
};

export const filterInvalidInputCharacters = inputString => {
  return inputString.replace(regExpOfInvalidInputCharacters, '');
};
