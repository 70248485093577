import React from 'react';
import { BuyPlansContext } from '../BuyPlansContext';
import { UncontrolledAlert } from 'reactstrap';
import Payment from './Payment';
import Review from './Review';
import Confirmation from './Confirmation';
import { findErrorMessage } from '../../../../util/i18n/i18nService';
import { submitOrder } from '../../providers/EstoreProvider';
import { BuyPlanPages } from '..';
import { getErrorCodeDesc } from '../../buyPlans/buyPlansUtil';

const CheckoutStep = Object.freeze({
  PAYMENT: 'PAYMENT',
  REVIEW: 'REVIEW',
  CONFIRMATION: 'CONFIRMATION'
});

export default class Checkout extends React.Component {
  static contextType = BuyPlansContext;

  state = {
    alertMessage: null,
    card: null,
    redirectPath: null,
    items: []
  };

  componentDidMount () {
    const items = this.context.cart?.items;
    // keepting a copy of cart.items for checkout scope. Once purchase will complete cart in context will be updated. During that time this local copy will be required in confiration page.
    if (Array.isArray(items)) {
      this.setState({ items: [...items] });
    }
  }

  clearAlerts = () => this.setState({ alertMessage: null });

  getAlerts = () => {
    if (this.state.alertMessage) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts} aria-live='polite' className='w-100'>
          {this.state.alertMessage}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  placeOrder = async () => {
    this.context.set({ loading: true });

    const { token, cvv } = this.state.card || {};

    try {
      const response = await submitOrder(this.context.cart?.cartId, token, cvv);

      if (response?.data?.errorCode) {
        const alertMessage = getErrorCodeDesc(response?.data?.errorCode) || response?.data?.errorDescription;
        this.setState({ alertMessage });
      } else {
        this.context.initPlansAndCart();
        this.context.redirectTo(BuyPlanPages.CheckoutConfirmation);
      }
    } catch (error) {
      this.setState({ alertMessage: findErrorMessage(error) });
    } finally {
      this.context.set({ loading: false });
    }
  };

  render () {
    const step = this.props.match?.params?.step?.toUpperCase() || CheckoutStep.PAYMENT;
    const { translator, redirectTo } = this.context;

    return (
      <section className='checkout container d-flex flex-column align-items-center'>
        <h1 className='text-center font-weight-light my-4'>
          {step === CheckoutStep.CONFIRMATION
            ? translator.t('buyplans_checkout_confirmation_text')
            : translator.t('buyplans_checkout_text')}
        </h1>
        {this.getAlerts()}
        <article className='my-3 mx-0 w-100 row no-gutters justify-content-between'>
          <h2 className={`col h6 pb-2 ${step === CheckoutStep.PAYMENT ? 'border-bottom-active' : 'border-bottom'}`}>
            {translator.t('buyplans_01_payment')}
          </h2>
          <h2 className={`col h6 pb-2 ${step === CheckoutStep.REVIEW ? 'border-bottom-active' : 'border-bottom'}`}>
            {translator.t('buyplans_02_review')}
          </h2>
          <h2
            className={`col h6 pb-2 ${step === CheckoutStep.CONFIRMATION ? 'border-bottom-active' : 'border-bottom'}`}
          >
            {translator.t('buyplans_03_confirmation')}
          </h2>
        </article>

        {step === CheckoutStep.PAYMENT && this.state.items.length > 0 && (
          <Payment
            showAlert={alertMessage => this.setState({ alertMessage })}
            items={this.state.items}
            card={this.state.card}
            setCard={card => this.setState({ card })}
            onContinue={() => redirectTo(BuyPlanPages.CheckoutReview)}
            onCancel={() => redirectTo(BuyPlanPages.Cart)}
          />
        )}

        {step === CheckoutStep.REVIEW && this.state.card && (
          <Review
            showAlert={alertMessage => this.setState({ alertMessage })}
            items={this.state.items}
            card={this.state.card}
            placeOrder={this.placeOrder}
            onCancel={() => redirectTo(BuyPlanPages.CheckoutPayment)}
          />
        )}

        {step === CheckoutStep.CONFIRMATION && this.state.card && (
          <Confirmation
            showAlert={alertMessage => this.setState({ alertMessage })}
            items={this.state.items}
            card={this.state.card}
          />
        )}
      </section>
    );
  }
}
