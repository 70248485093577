import React, { Component } from 'react';
import imageBorder from '../../../assets/landingPageOld/imageBorder.png';
import logovw from '../../../assets/landingPageOld/white.png';
import turndown from '../../../assets/landingPageOld/3g-turndown.png';
import getCwpMeta from '../../../providers/cwpMetaProvider';

class Capabilities extends Component {
  mounted = true;
  state = {
    filesUrl: ''
  };
  componentDidMount () {
    // get the filesUrl
    const getMeta = async () => {
      let meta = await getCwpMeta();
      let filesUrl = meta.static.content.urls.files;
      this.setState({
        filesUrl
      });
      if (this.mounted) {
        this.setState({ filesUrl });
      }
    };
    getMeta();
  }
  componentWillUnmount () {
    this.mounted = false;
  }
  render () {
    return (
      <div>
        <div className='container-img'>
          <div className='hero-img'>
            <div className='helptopic-3g d-flex flex-column pt-3  w-60'>
              <a href='#home'>
                <img
                  src={logovw}
                  alt='remote'
                  width='130px'
                  className='img-vw-logo d-flex flex-column ml-4 pt-1  w-60'
                ></img>
              </a>

              <img src={imageBorder} alt='remote' className='img-border mt-2 pt-1 w-100'></img>
            </div>

            <div className='helptopic'>
              <span className='VWHead-light-vzt'>Everything you need to know about</span>

              <span className='VWHead-bold-vzt'> 3G Sunset for your connected vehicle.</span>
            </div>
          </div>
        </div>

        <table className='turndown'>
          <tr>
            <td className='sunset-3g-td'>
              <span>
                <img src={turndown} alt='download' width='42' className='turn-down-img'></img>
                <br />
                <span className='turndown-span'>
                  <b>3G Sunset</b>
                </span>
              </span>
            </td>
            <td className='sunset-3g-td-right'>
              <span>
                Telecommunication providers are consistently improving their networks, as seen with the transitions from
                2G to 3G to 4G to 5G. In order to free up resources for the newer and faster 5G services,
                telecommunication providers are turning off their 3G networks. This is known as technological
                obsolescence. This means that any device relying on the 3G network, including the SIM hardware provided
                by AT&T and found on most 2014-2019 Volkswagen models can no longer transmit or receive telematics data
                through the Car-Net system as of February 22, 2022. Depending on your vehicle model year, Volkswagen is
                working to identify a technical solution that will continue to offer some telematics services.
                <br />
              </span>
            </td>
          </tr>
        </table>
      </div>
    );
  }
}
export default Capabilities;
