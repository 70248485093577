import React, { useState } from 'react';

import DriveViewInfoModal from '../trips/DriveViewInfoModal';

import driverScore0 from '../../../assets/driveView/driverScore_0.png';
import driverScore10 from '../../../assets/driveView/driverScore_10.png';
import driverScore20 from '../../../assets/driveView/driverScore_20.png';
import driverScore30 from '../../../assets/driveView/driverScore_30.png';
import driverScore40 from '../../../assets/driveView/driverScore_40.png';
import driverScore50 from '../../../assets/driveView/driverScore_50.png';
import driverScore60 from '../../../assets/driveView/driverScore_60.png';
import driverScore70 from '../../../assets/driveView/driverScore_70.png';
import driverScore80 from '../../../assets/driveView/driverScore_80.png';
import driverScore90 from '../../../assets/driveView/driverScore_90.png';
import driverScore100 from '../../../assets/driveView/driverScore_100.png';

import './driveView.scss';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';

const imgScoreHash = {
  0: driverScore0,
  10: driverScore10,
  20: driverScore20,
  30: driverScore30,
  40: driverScore40,
  50: driverScore50,
  60: driverScore60,
  70: driverScore70,
  80: driverScore80,
  90: driverScore90,
  100: driverScore100
};

const DriveViewScoreReading = (props = {}) => {
  const [aboutDriveViewModalDisplayed, toggleAboutModal] = useState(false);

  const toggleAboutDriveViewModal = e => {
    e.preventDefault();
    e.stopPropagation();
    toggleAboutModal(!aboutDriveViewModalDisplayed);
  };

  let noIcon = props.noIcon || false;
  let score = props.score || 0;
  // takes care of things like '90.1' which should be considered greater than 90
  // Number to be displayed in reading
  let roundedUpScore = Math.ceil(props.score);
  // Number to be used to determine image
  let finalImgScore = 0;
  let imgSrc = '';
  // anything greater than 90 is set to 100
  // anything greater than 80 is set to 90
  // ...
  // anything greater than 0 is set to 10
  if (roundedUpScore >= 100) {
    finalImgScore = 100;
  } else {
    let remainder = roundedUpScore % 10;
    // divisible by 10 so that means the finalScore (as translated to the image) is 10 less
    if (remainder === 0) {
      finalImgScore = roundedUpScore - 10;
    } else {
      finalImgScore = roundedUpScore - remainder + 10;
    }
  }

  imgSrc = imgScoreHash[finalImgScore] || imgScoreHash[0];

  let roundedUpScoreStringLength = String(roundedUpScore).length;
  // double is the default style as 98 numbers are double-digits
  let classNameToApply = 'doubleDigits';
  // style modified for scores with 3 & single digits
  if (roundedUpScoreStringLength >= 3) {
    classNameToApply = 'tripleDigits';
  } else if (roundedUpScoreStringLength === 1) {
    classNameToApply = 'singleDigit';
  }

  if (roundedUpScore === 0) {
    classNameToApply = 'noData';
  }

  let translator = getLocaleTranslator();

  return (
    <>
      <div className='driveViewScoreReading'>
        {!noIcon && <span className='infoIcon' onClick={toggleAboutDriveViewModal} />}
        <span className={classNameToApply}>
          {roundedUpScore === 0 ? translator.t('driveview.label.noData') : roundedUpScore}
        </span>
        <img src={imgSrc} />
      </div>
      <DriveViewInfoModal
        toggleAboutDriveViewModal={toggleAboutDriveViewModal}
        aboutDriveViewModal={aboutDriveViewModalDisplayed}
      />
    </>
  );
};

export default DriveViewScoreReading;
