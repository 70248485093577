import React from 'react';
import { BuyPlansContext } from '../BuyPlansContext';
import ThemedToast from '../../../reusable_cmp_lib/ThemedToast/ThemedToast';

export default class MyQNotifications extends React.Component {
  static contextType = BuyPlansContext;

  state = {
    linkingSuccessFeedback: null,
    linkingErrorFeedback: null,
    usedLinkingConfirmations: []
  };

  componentDidMount () {
    this.checkForQueryCondition();
  }

  checkForQueryCondition = () => {
    // only do this once the necessary details are obtained
    const { vehicleId, userId } = this.context;

    const [, queryParamStr] = window.location.href.split('?');

    if (!queryParamStr) {
      return;
    }

    const queryParams = queryParamStr.split('&');

    const isLinkingSuccess = [
      'status=SUCCESS',
      `vehicleId=${vehicleId}`,
      `userId=${userId}`,
      'accountCode=chamberlain'
    ].every(kv => queryParams.includes(kv));
    const isLinkingError =
      !isLinkingSuccess &&
      ['status=ERROR', `vehicleId=${vehicleId}`, `userId=${userId}`, 'accountCode=chamberlain'].every(kv =>
        queryParams.includes(kv)
      );

    const [, statusValue = ''] = queryParams.find(kv => kv.startsWith('status='))?.split('=');
    const [, accountCodeValue = ''] = queryParams.find(kv => kv.startsWith('accountCode='))?.split('=');

    const keyForStateStorage = `${vehicleId}__${userId}__${accountCodeValue}__linking__${statusValue}`;

    if (!this.state.usedLinkingConfirmations.includes(keyForStateStorage)) {
      if (isLinkingSuccess && this.state.linkingSuccessFeedback !== keyForStateStorage) {
        this.setState({ linkingSuccessFeedback: keyForStateStorage });
      } else if (isLinkingError && this.state.linkingErrorFeedback !== keyForStateStorage) {
        this.setState({ linkingErrorFeedback: keyForStateStorage });
      }
    }
  };

  render () {
    const translator = this.context.translator;

    return (
      <>
        {this.state.linkingSuccessFeedback && (
          <ThemedToast
            title={translator.t('chamberlain.myQLinkSuccess.title')}
            msg={translator.t('chamberlain.myQLinkSuccess.body')}
            display={this.state.linkingSuccessFeedback}
            onConfirmClick={() => {
              let usedLinkingConfirmations = [
                ...this.state.usedLinkingConfirmations,
                this.state.linkingSuccessFeedback
              ];
              this.setState({ usedLinkingConfirmations, linkingSuccessFeedback: null });
            }}
          />
        )}
        {this.state.linkingErrorFeedback && (
          <ThemedToast
            title={translator.t('chamberlain.myQLinkError.title')}
            msg={translator.t('chamberlain.myQLinkError.body')}
            display={this.state.linkingErrorFeedback}
            onConfirmClick={() => {
              let usedLinkingConfirmations = [...this.state.usedLinkingConfirmations, this.state.linkingErrorFeedback];
              this.setState({ usedLinkingConfirmations, linkingErrorFeedback: null });
            }}
          />
        )}
      </>
    );
  }
}
