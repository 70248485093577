import React, { Component } from 'react';
import Faq from 'react-faq-component';
import '../faqCanada/faqCA.scss';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import goback from '../../../assets/faq/goback_fr.png';
import { historyBack } from './faqCAhome';
import { setTitle } from '../../../providers/documentTitleProvider';

export default class MessageFaqCA extends Component {
  componentDidMount () {
    setTitle('document_titles.faq.message_center');
  }
  render () {
    let translator = getLocaleTranslator();

    return (
      <div className='faqCAheader' role='main' id='main-content'>
        <div className='goback'>
          <span onClick={historyBack}>
            <img src={goback} alt='viewfaq' width='100'></img>
          </span>
        </div>
        <div className='message-header'>
          <div className='td-ms-header'>
            <h1>{translator.t('message_title')}</h1>
          </div>
        </div>
        <Faq data={data} />
      </div>
    );
  }
}

const data = {
  rows: [
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          Comment puis-je actualiser l’information sur l’état du véhicule?{' '}
        </div>
      ),
      content: ` 
      <span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span> Années-modèles 2022 et années ultérieures : Il suffit d’appuyer sur un bouton pour rafraîchir les données sur votre véhicule. Sur le portail Web Car-NetMD de Volkswagen, appuyez sur le symbole de rafraîchissement (flèche en demi-cercle) à côté de l’horodatage pour obtenir des renseignements à jour. Dans l’application mobile Car-NetMD de Volkswagen, balayez la date et l’heure vers le bas pour actualiser les données sur l’état de votre véhicule. 
       `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Que sont les « notifications poussées » et comment s’en sert-on?{' '}
        </div>
      ),
      content: ` 
      <span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span>Les notifications poussées sont des alertes que vous recevez par l’entremise de l’application mobile concernant l’état de votre véhicule. Par exemple, Car-NetMD de Volkswagen vérifiera l’état des portes environ 30 secondes après l’arrêt du moteur pour vérifier si elles sont déverrouillées. Si un temps pluvieux est prévu, Car-NetMD de Volkswagen vérifiera l’état des vitres et vous avertira si elles sont ouvertes.
        `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          Comment puis-je ajouter un deuxième conducteur à mon compte?{' '}
        </div>
      ),
      content: ` 
      <span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span>Appuyez sur le bouton « Add Vehicle » (ajouter un véhicule) dans l’application mobile Car-NetMD de Volkswagen et suivez les instructions. Si votre véhicule n’est toujours pas dans votre garage virtuel, appelez le Centre d’appels Car-NetMD de Volkswagen au 1-833-435-1013.</br>

      <span class='mdlyr'Années-modèles 2022 et années ultérieures :</span> Allez à la page de gestion des comptes de l’application mobile. Cliquez ensuite sur « Add Secondary Driver » (ajouter un conducteur secondaire) et entrez les renseignements requis. Lorsque vous avez terminé, appuyez sur « Send invitation » (envoyer l’invitation). Le conducteur supplémentaire devrait recevoir une invitation peu après.

      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Pourquoi est-ce que je reçois une notification poussée longtemps après avoir éteint mon véhicule Volkswagen?
        </div>
      ),
      content: ` 
      Année-modèle 2022 et années ultérieures : Appuyez sur le bouton « Add Vehicle » (ajouter un véhicule) dans l’application mobile Car-NetMD de Volkswagen et suivez les instructions. Si votre véhicule n’est toujours pas dans votre garage virtuel, appelez le Centre d’appels Car-NetMD de Volkswagen au 1-833-435-1013. </br>
      Année-modèle 2022 et années ultérieures : Les conducteurs principaux ont accès à tous les paramètres et caractéristiques auxquels ils sont admissibles. Les conducteurs ajoutés peuvent utiliser les fonctions à distance et les points d’intérêt, mais ne peuvent pas modifier les renseignements du compte. Toutefois, le conducteur principal peut leur donner accès à certains éléments comme les services de surveillance et les bilans de santé du véhicule.

      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Comment puis-je activer/désactiver les notifications poussées?{' '}
        </div>
      ),
      content: `<span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span>Si des services demandés ne nécessitent pas de vérification de l’identité, ils seront fournis. Si les services nécessitent une vérification de l’identité et que la pièce d’identité ne peut être confirmée, les services ne seront pas fournis. Tout ce qui concerne le compte ou les renseignements personnels de l’abonné nécessite une vérification de l’identité de l’appelant.   
      
      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Où puis-je consulter toutes les activités de mon compte?{' '}
        </div>
      ),
      content: `<span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span>Le service Car-NetMD de Volkswagen s’est associé à Rogers Communications afin d’offrir des forfaits WiFi mobiles abordables, qui sont nécessaires pour obtenir un point d’accès sans fil Car-NetMD de Volkswagen. Les forfaits de point d’accès sans fil prépayés de Rogers peuvent être achetés sur la page « Buy Plans » (acheter un forfait) du site www.vw.ca/fr/pourquoi-vw/innovation-et-technologie/car-net.html ou avec l’application mobile Car-NetMD de Volkswagen. Veuillez noter que vous devez vous inscrire à Car-NetMD pour acheter des forfaits WiFi. D’autres exploitants de services mobiles devraient s’associer à Car-NetMD de Volkswagen dans un proche avenir.
      
      `
    }
  ]
};

export { MessageFaqCA };
