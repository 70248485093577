/* eslint-disable complexity */
import React, { Component } from 'react';
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import getCwpMeta from '../../../providers/cwpMetaProvider';
import { getVehicleContext } from '../../../providers/historyProvider';
import { addToCart, getCartById, getStoreSummary, parseCartItem } from '../providers/EstoreProvider';
import { getLocaleSync } from '../../../providers/languageProvider';
import { BuyPlansContext } from './BuyPlansContext';
import Spinner from '../common/Spinner';
import BuyPlansHome from './BuyPlansHome';
import { findFirstFromList } from './buyPlansUtil';
import './styles/main.scss';
import { isNullOrEmptyObject } from '../../../util/utilityMethods';
import Cart from './checkout/Cart';
import Checkout from './checkout/Checkout';
import DataTrial from './previews/DataTrial';
import DataPTP from './previews/DataPTP';
import DataPrePaid from './previews/DataPrepaid';
import DefaultPreview from './previews/DefaultPreview';
import MarketingView from './previews/MarketingView';
import SafetyFree from './previews/SafetyFree';
import cartButtonSvg from '../../../assets/buyPlans/cart.svg';
import Disclaimer from './components/Disclaimer';
import AlexaWebapp from './previews/AlexaWebapp';
import MyQGaragecontrolTrial from './previews/MyQGaragecontrolTrial';

class BuyPlans2 extends Component {
  constructor (props) {
    super(props);

    this.state = {
      loading: true,
      contentURL: null,
      vehicleId: null,
      mnoProvider: null,

      marketingConfigurationHash: null,
      currentPlans: null,
      additionalPlans: null,
      cart: null,

      selectedPlan: null,

      userCountry: null,
      userLang: null,

      disclaimerText: null,
      disclaimerNumber: null,
      disclaimerTargetDomNode: null
    };

    this.translator = getLocaleTranslator();
  }

  componentDidMount () {
    this.initPlansAndCart();
    this.initContentUrl();
    this.initVehicle();
    this.initLocale();

    window.scrollTo(0, 0);
  }

  initContentUrl = async () => {
    try {
      const response = await getCwpMeta();
      this.setState({
        contentURL: response?.static?.content?.urls?.estore
      });
    } catch (error) {}
  };

  initVehicle = () => {
    const vehicle = getVehicleContext();
    this.setState({ vehicleId: vehicle?.vehicleId, mnoProvider: vehicle?.ocuSim?.ocuSimProfile?.mnoProvider });
  };

  initPlansAndCart = async () => {
    this.setState({ loading: true });
    try {
      const [estoreSummary = {}, marketingInfo = null, cart = null] = await getStoreSummary();

      const { subscribedPlans = [], allPlans = [] } = estoreSummary;

      const currentPlans = subscribedPlans.filter(plan => plan.viewable);
      const additionalPlans = allPlans.filter(plan => plan.viewable);

      const configurations = Array.isArray(marketingInfo?.configurations) ? marketingInfo?.configurations : [];
      const configurationIds = [...subscribedPlans, ...allPlans].map(plan => plan.marketingConfigurationId);

      const marketingConfigurationHash = {};
      for (const configuration of configurations) {
        if (configurationIds.includes(configuration.configurationId)) {
          marketingConfigurationHash[configuration.configurationId] = configuration;
        }
      }

      this.setState({
        loading: false,
        marketingConfigurationHash,
        currentPlans,
        additionalPlans,
        cart
      });
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  initLocale = async () => {
    const locale = getLocaleSync();

    this.setState({
      userCountry: locale?.userCountry?.toUpperCase() || 'US',
      userLang: locale?.userLanguage || 'en'
    });
  };

  showDisclaimer = (disclaimerText, disclaimerNumber, disclaimerTargetDomNode) =>
    this.setState({ disclaimerText, disclaimerNumber, disclaimerTargetDomNode });

  selectPlan = plan => {
    this.setState({ selectedPlan: plan });
  };

  selectPlanFromList = (plans, category, subCategory, marketingConfigurationId) => {
    if (!Array.isArray(plans)) {
      this.selectPlan(null);

      return null;
    }

    const filters = { category, subCategory, marketingConfigurationId };
    const plan = findFirstFromList(plans, filters);

    this.selectPlan(plan);

    return plan;
  };

  addToCart = async cartItem => {
    let items = this.state.cart?.items;
    if (!Array.isArray(items)) {
      items = [];
    }

    //  Removing any existing item in cart with same SKU
    items = items.filter(el => el.sku !== cartItem.sku);
    items = items.map(el => parseCartItem(el));

    items.push(cartItem);
    try {
      this.setState({ loading: true });
      const response = await addToCart(items);
      const cartId = response?.data?.cartId;

      const { data } = await getCartById(cartId);

      this.setState({ loading: false, cart: data });

      return Promise.resolve();
    } catch (error) {
      this.setState({ loading: false });

      return Promise.reject(error);
    }
  };

  set = obj => {
    if (isNullOrEmptyObject(obj)) {
      return;
    }

    this.setState({ ...obj });
  };

  redirectTo = path => (window.location.href = path?.toLowerCase());

  render () {
    if (!this.state.marketingConfigurationHash && this.state.loading) {
      return <Spinner />;
    }

    const buyPlanContext = Object.freeze({
      loading: this.state.loading,
      translator: this.translator,
      contentURL: this.state.contentURL,
      userId: this.props.userVehicleContext?.userId,
      vehicleId: this.state.vehicleId,
      mnoProvider: this.state.mnoProvider,
      userCountry: this.state.userCountry,
      userLang: this.state.userLang,
      currentPlans: this.state.currentPlans,
      additionalPlans: this.state.additionalPlans,
      selectedPlan: this.state.selectedPlan,
      marketingConfigurationHash: this.state.marketingConfigurationHash,
      cart: this.state.cart,
      redirectTo: this.redirectTo,
      set: this.set,
      selectPlan: this.selectPlan,
      selectPlanFromList: this.selectPlanFromList,
      showDisclaimer: this.showDisclaimer,
      addToCart: this.addToCart,
      initPlansAndCart: this.initPlansAndCart
    });

    return (
      <div id='main-content' role='main' className='my-3 cwpContentHeightMin buyPlans'>
        <BuyPlansContext.Provider value={buyPlanContext}>
          <Router basename='/buy-plans'>
            <Switch>
              <Route path='/' exact={true}>
                <BuyPlansHome />
              </Route>
              <Route path='/checkout/cart' component={Cart} />
              <Route path='/checkout/:step' component={Checkout} />
              <Redirect from='/checkout' to='/checkout/cart' />
              <Route path='/plan/:category/:subCategory/:marketingConfigurationId' component={MarketingView} />
              <Route path='/data/trial/:marketingConfigurationId' component={DataTrial} />
              <Route path='/data/ptp/:marketingConfigurationId' component={DataPTP} />
              <Route path='/data/prepaid/:marketingConfigurationId' component={DataPrePaid} />
              <Route path='/service/safety_free/:marketingConfigurationId' component={SafetyFree} />
              <Route path='/service/alexa_webapp/:marketingConfigurationId' component={AlexaWebapp} />
              <Route path='/service/garagecontrol_trial/:marketingConfigurationId' component={MyQGaragecontrolTrial} />
              <Route path='/:category/:subCategory/:marketingConfigurationId' component={DefaultPreview} />
            </Switch>
          </Router>
        </BuyPlansContext.Provider>

        {!window.location.href.includes('/checkout') && Array.isArray(this.state.cart?.items) && (
          <article className='cartButton'>
            <input
              id='btnCart'
              type='image'
              src={cartButtonSvg}
              alt={this.translator.t('buyplans_common_cart')}
              onClick={() => this.redirectTo(BuyPlanPages.Cart)}
            />
            <label htmlFor='btnCart' aria-label={this.translator.t('buyplans_common_cart')}>
              {this.state.cart.items.length}
            </label>
          </article>
        )}
        {this.state.disclaimerText && (
          <Disclaimer
            translator={this.translator}
            disclaimerText={this.state.disclaimerText}
            disclaimerNumber={this.state.disclaimerNumber}
            disclaimerTargetDomNode={this.state.disclaimerTargetDomNode}
            onExit={() => {
              if (this.state.disclaimerTargetDomNode) {
                this.state.disclaimerTargetDomNode.focus();
              }
              this.setState({ disclaimerText: null, disclaimerNumber: null, disclaimerTargetDomNode: null });
            }}
          />
        )}
      </div>
    );
  }
}

export const BuyPlanPages = Object.freeze({
  Home: '/#/buy-plans',
  Cart: '/#/buy-plans/checkout/cart',
  CheckoutPayment: `/#/buy-plans/checkout/payment`,
  CheckoutReview: `/#/buy-plans/checkout/review`,
  CheckoutConfirmation: `/#/buy-plans/checkout/confirmation`
});

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({}, dispatch)
  };
};

const mapStateToProps = state => {
  const { userVehicleContext } = state;

  return {
    ...userVehicleContext
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyPlans2);
