import React from 'react';
import PropTypes from 'prop-types';
import { findDisclaimerByNumber } from '../buyPlansUtil';

const COLUMNS = 3;

export default class MarketingProducts extends React.Component {
  static propTypes = {
    plan: PropTypes.object.isRequired,
    marketingConfiguration: PropTypes.object,
    contentURL: PropTypes.string.isRequired,
    showDisclaimer: PropTypes.func.isRequired
  };

  state = {
    marketingProducts: []
  };

  componentDidMount () {
    const { plan, marketingConfiguration } = this.props;

    let marketingProducts = [];
    if (Array.isArray(marketingConfiguration?.marketingProducts) && Array.isArray(plan?.marketingProductIds)) {
      marketingProducts = marketingConfiguration.marketingProducts.filter(mp =>
        plan.marketingProductIds.includes(mp.marketingProductId)
      );
    }

    this.setState({ marketingProducts });
  }

  pullDisclaimer = (disclaimerNumber, target) => {
    const marketingConfiguration = this.props.marketingConfiguration;

    const disclaimerText = findDisclaimerByNumber(marketingConfiguration, disclaimerNumber);

    this.props.showDisclaimer(disclaimerText, disclaimerNumber, target);
  };

  render () {
    const { contentURL } = this.props;
    const productCount = this.state.marketingProducts.length;

    return (
      <section className='row no-gutters mt-5 pt-4 mx-0'>
        {this.state.marketingProducts.map(({ marketingProductId, title, description, imageURL, disclaimerNumber }) => (
          <div key={`marketingProductId-${marketingProductId}`} className='focusBorderTop mb-5 col-12 col-md-4'>
            <div className='cwpCol d-block mx-auto text-left shift-little-up'>
              <img src={`${contentURL}${imageURL}`} alt={title} className='px-4 bg-white'></img>
              <h2 className='h5 font-weight-bold my-3'>
                {title}
                {disclaimerNumber && (
                  <button
                    type='button'
                    className='cwp-disclaimer ml-2'
                    aria-label={title}
                    tabIndex={0}
                    onClick={e => this.pullDisclaimer(disclaimerNumber, e.currentTarget)}
                  >
                    {disclaimerNumber}
                  </button>
                )}
              </h2>
              <p className='pl-4'>{description}</p>
            </div>
          </div>
        ))}
        {/* Creates placeholder to fill blank space in last row and expands top border in large screen. */}
        {productCount % COLUMNS > 0 && (
          <div
            className={`focusBorderTop mb-5 col-12 col-md-${(COLUMNS - (productCount % COLUMNS)) *
              4} d-none d-md-block`}
          />
        )}
      </section>
    );
  }
}
