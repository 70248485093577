import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { findErrorMessage, getLocaleTranslator } from '../../../util/i18n/i18nService';
import * as garageProvider from '../providers/GarageProvider';
import { TSP_PROVIDER } from '../providers/SelfEnrollmentProvider';
import { findIn } from '../../../util/utilityMethods';

class ModalPinLocked extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      processing: false,
      alert: null
    };

    this.translator = getLocaleTranslator();
  }

  sendOTP = async e => {
    e.preventDefault();

    if (this.state.processing) {
      return;
    }

    if (findIn('enrollmentStatus.vehicle.tspProvider', this.props) === TSP_PROVIDER.VZT) {
      this.props.toggleModalOTP();
      return;
    }

    this.setState({ processing: true, alert: null });
    try {
      await garageProvider.sendResetSpinSecurityCode();

      this.setState({ processing: false });
      this.props.toggleModalOTP();
    } catch (error) {
      this.setState({ processing: false, alert: findErrorMessage(error, this.translator) });
    }
  };

  render () {
    return (
      <Modal isOpen={this.props.visible} toggle={this.props.toggle} centered={true} className='preVehicleAuth'>
        <ModalHeader toggle={this.props.toggle}>{this.translator.t('pin_prompt.pin_locked')}</ModalHeader>
        <ModalBody>
          <div className='text-center'>
            <div className='alert alert-warning' role='alert'>
              {this.translator.t('pin_prompt.locked_message')}
            </div>

            <span className='arrowRight cwp-blue my-4' onClick={this.sendOTP} hidden={this.state.processing}>
              {this.translator.t('pin_prompt.reset_pin')}
            </span>
            <div
              className={`spinner-border text-secondary my-4 mx-auto ${this.state.processing ? 'd-block' : 'd-none'}`}
              role='status'
            >
              <span className='sr-only' />
            </div>
            <br />
            <button className='cwp' onClick={this.props.toggle} disabled={this.state.processing}>
              {this.translator.t('button.ok')}
            </button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default ModalPinLocked;
