import { getLocaleTranslator } from '../util/i18n/i18nService';

// key/value hashing for setting title on document based on hash location
const locationToTranslationKeyHash = {
  'buy-plans': 'document_titles.buy_plans.main',
  changeServices: 'document_titles.change_services',
  trips: 'document_titles.trips',
  'acct-mgmt': 'document_titles.profile_page',
  'acct-mgmt/personal': 'document_titles.profile_page',
  '/acct-mgmt/security': 'document_titles.account_management.login_and_security',
  '/acct-mgmt/marketing': 'document_titles.account_management.marketing_consent', // CA ONLY
  'acct-mgmt/vehicle': 'document_titles.account_management.vehicle_information',
  'acct-mgmt/additionaldrivers': 'document_titles.account_management.additional_drivers',
  'acct-mgmt/wifisettings': 'document_titles.account_management.wifi_account',
  'acct-mgmt/thirdparty': 'document_titles.account_management.third_party_services'
};

const getLocationHashTitle = newLocation => {
  let hash = newLocation.split('#/')[1] || '';
  hash = hash.trim();
  let mapping = (hash && locationToTranslationKeyHash[hash]) || '';
  return mapping;
};

/*
 * Method provides a manual way to set the document title
 * @param str {string} - key for translation, if not defined in translations will fallback
 */
const setTitle = str => {
  const translate = getLocaleTranslator().t;
  const defaultTitle = translate('document_titles.default');
  const translatedFromParam = (str && translate(str)) || '';
  let titleToShow = translatedFromParam || defaultTitle;
  document.title = titleToShow;
};

window.addEventListener('popstate', function (event) {
  // The URL changed...
  let newLocation = event.currentTarget.location.href;
  let mappingStr = getLocationHashTitle(newLocation);
  setTitle(mappingStr);
});

export { setTitle };
