import { createLogic } from 'redux-logic';
import axios from 'axios';

import {
  DELETE_VEHICLE_HISTORY_EVENT,
  DELETE_VEHICLE_HISTORY_EVENT_SUCCESS,
  DELETE_VEHICLE_HISTORY_EVENT_FAILURE
} from '../../actions/types';

// If we want to just test w/out making an API call we can create a mock response
// import mockResponse from './__mockdata__/mockData';
let dataProvider = axios;

const deleteVehicleHistoryEventLogic = createLogic({
  type: DELETE_VEHICLE_HISTORY_EVENT, // only apply this "logic" to this action type
  latest: true, // only provide the lastest response if fired off many times (though should never happen)
  processOptions: {
    dispatchReturn: true, // more auto-magic configuration, dispatch success/failure action types immediately
    successType: DELETE_VEHICLE_HISTORY_EVENT_SUCCESS,
    failType: DELETE_VEHICLE_HISTORY_EVENT_FAILURE
  },

  // define our async promise within a logic 'process'
  process ({ action }) {
    console.log('deleteVehicleHistoryEventLogic is processing an action >> ');
    console.log('type: ' + action.type);
    console.log('payload data from deleteVehicleHistoryEventLogic: ' + JSON.stringify(action.payload));

    // This would actually just point to our hapi server endpoint

    let requestConfig = {
      method: 'DELETE',
      url: `/deletevehiclehistory`,
      headers: {
        accept: 'application/json'
      },
      params: {
        deleteEvent: action.payload === null ? '' : action.payload.deleteEvent,
        vehicleEventId: action.payload === null ? '' : action.payload.vehicleEventId
      }
    };

    console.log('requestConfig is *****' + requestConfig);
    return dataProvider(requestConfig).then(response => {
      console.log('got response for getVehicleHistoryInfo GET request >>> ');
      console.log(JSON.stringify(response.data, null, 1));
      return response.data;
    });
  }
});

export default [deleteVehicleHistoryEventLogic];
