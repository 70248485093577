import axios from 'axios';
import invoke from './ServiceProxy';

const getStoreSummary = async () => {
  return invoke({ url: '/storesummary' });
};

const getStorePricing = async (category, subCategory, provider, cartId) => {
  return invoke({
    url: '/storeplanpricing',
    params: {
      category,
      subCategory,
      provider,
      cartId
    }
  });
};

const submitTosDataTrial = async tosStatus => {
  return invoke({
    method: 'PUT',
    url: `/estore/data/trial`,
    data: { tosStatus }
  });
};

const checkEligibilityForPTP = async (ownerPhoneCarrier, ownerPhoneNumber, ownerZipCode) => {
  return invoke({
    method: 'POST',
    url: `/ptp/checkeligibility`,
    data: {
      ownerPhoneCarrier,
      ownerPhoneNumber,
      ownerZipCode
    }
  });
};

const submitPTP = async (ownerPhoneCarrier, ownerPhoneNumber, ownerZipCode, ptpRef) => {
  return invoke({
    method: 'PUT',
    url: `/ptp/eligible/submit`,
    data: {
      ownerPhoneCarrier,
      ownerPhoneNumber,
      ownerZipCode,
      ptpRef
    }
  });
};

const addToCart = async items => {
  return invoke({
    method: 'POST',
    url: `/addtocart`,
    data: { items }
  });
};

const removeFromCart = async (items, cartId) => {
  return invoke({
    method: 'PUT',
    url: `/removefromcart`,
    data: { items: { items }, cartId }
  });
};

const getCartById = async cartId => {
  return invoke({
    url: `/storecartitems`,
    params: { cartId }
  });
};

const getWalletDetails = async cartId => {
  return invoke({
    url: `/user/store/walletdetails`,
    params: { cartId }
  });
};

const deletePaymentByWalletId = async walletId => {
  return invoke({
    method: 'DELETE',
    url: `/user/store/deletepayment`,
    data: { walletId }
  });
};

const submitOrder = (cartId, token, cvv) => {
  return invoke({
    method: 'POST',
    url: '/user/store/placeorder',
    data: { cart: { cartId }, card: { token, cvv } }
  });
};

const getCountries = async () => {
  return invoke({ url: '/customer/static/countries' });
};

const getStatesByCountryCode = async countryCode => {
  return invoke({ url: `/customer/static/countries/${countryCode}/states` });
};

const parseCartItem = ({ sku, priceOptionId, quantity }) => buildCartItem(sku, priceOptionId, quantity);

const buildCartItem = (sku, priceOptionId, quantity) => ({ sku, priceOptionId, quantity });

const initAddNewPayment = async payload => {
  return axios({
    method: 'POST',
    url: `/user/store/paymentinitiate`,
    data: payload
  });
};

const purchaseZeroCostArticle = subCategory => {
  return invoke({
    method: 'PUT',
    url: `/estore/purchase/zerocost/article`,
    data: {
      subCategoryId: subCategory
    }
  });
};

export {
  getStoreSummary,
  getStorePricing,
  submitTosDataTrial,
  checkEligibilityForPTP,
  submitPTP,
  addToCart,
  removeFromCart,
  getCartById,
  parseCartItem,
  buildCartItem,
  getWalletDetails,
  deletePaymentByWalletId,
  submitOrder,
  getCountries,
  getStatesByCountryCode,
  initAddNewPayment,
  purchaseZeroCostArticle
};
