import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Player } from 'video-react';
import { getCwpMeta } from '../../../providers/cwpMetaProvider';
import goback from '../../../assets/faq/goback.png';
import '../marketingVideoCA/Video-react.scss';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import remoteStart from '../../../assets/images/create-vwid-frca.JPG';
import AppTour from './AppTour';
import AddVechicle from './AddVehicle';
import Wifi from './Wifi';
import WifiSetUp from './WifiSetUp';
import { setTitle } from '../../../providers/documentTitleProvider';
import Transcript from '../marketingVideoCA/Transcript';

export class VideoDisplay extends Component {
  //const VideoDisplay = props => {

  state = {
    videoVisible: false,
    subContentUrl: null
  };

  transcriptClick = subContentUrl => {
    this.setState({ subContentUrl });
  };

  transcriptbackClick = () => {
    this.setState({ subContentUrl: null });
  };

  toggleopen = id => {
    this.setState({ videoVisible: !this.state.videoVisible });
  };

  historyBack = e => {
    e.preventDefault();
    e.stopPropagation();
    window && window.history && window.history.go(-1);
  };

  componentDidMount = async () => {
    setTitle('document_titles.faq.faq_videos');
    let metaResponse = await getCwpMeta();
    let videoContentURL = metaResponse.static.content.urls.videos;
    // manually swapping en-CA for fr-CA for French
    videoContentURL = videoContentURL.replace('en-CA', 'fr-CA');
    this.setState({
      videoContentURL
    });
  };

  render () {
    let translator = getLocaleTranslator();
    return (
      <div role='main' id='main-content'>
        <div id='showFeatures' className='divFeatureswifi' hidden={this.state.subContentUrl}>
          <div className='goback'>
            <a href='#' role='link' tabIndex={0} onClick={this.historyBack} onKeyPress={this.historyBack}>
              <img src={goback} alt='viewfaq' width='60'></img>
            </a>
          </div>
          <h1 className='tutorials'>
            Car-Net <span>{translator.t('videos_tutorials')}</span>
          </h1>

          <table className='first-row-data'>
            <tr>
              <td className='first-row-video'>
                <a href='javascript:void(0);' onClick={this.toggleopen}>
                  <img src={remoteStart} width='370px' height='210px' />
                </a>
                <span className='video-title'>Comment créer votrre VW ID</span>
                <span
                  class='carnetLink'
                  role='link'
                  onClick={() =>
                    this.transcriptClick(
                      `${this.state.videoContentURL}/${encodeURIComponent(
                        'Comment créer votre VWID transcription.txt'
                      )}`
                    )
                  }
                  tabIndex={0}
                >
                  Voir la transcription
                </span>
              </td>
              <td className='first-row-video'>
                <AppTour />
                <span
                  class='carnetLink'
                  role='link'
                  onClick={() =>
                    this.transcriptClick(
                      `${this.state.videoContentURL}/${encodeURIComponent(
                        'Comment ajouter votre véhicule transcription.txt'
                      )}`
                    )
                  }
                  tabIndex={0}
                >
                  Voir la transcription
                </span>
              </td>
              <td className='first-row-video'>
                <AddVechicle />
                <span
                  class='carnetLink'
                  role='link'
                  onClick={() =>
                    this.transcriptClick(
                      `${this.state.videoContentURL}/${encodeURIComponent(
                        'How to add a secondary driver transcript_FR.txt'
                      )}`
                    )
                  }
                  tabIndex={0}
                >
                  Voir la transcription
                </span>
              </td>
            </tr>
            <tr>
              <td className='first-row-video'>
                <Wifi />
                <span
                  class='carnetLink'
                  role='link'
                  onClick={() =>
                    this.transcriptClick(
                      `${this.state.videoContentURL}/${encodeURIComponent(
                        'Comment rejoindre un point d’accès Wi-Fi transcription.txt'
                      )}`
                    )
                  }
                  tabIndex={0}
                >
                  Voir la transcription
                </span>
              </td>
              <td className='first-row-video'>
                <WifiSetUp />
                <span
                  class='carnetLink'
                  role='link'
                  onClick={() =>
                    this.transcriptClick(
                      `${this.state.videoContentURL}/${encodeURIComponent(
                        'Comment configurer le Wi-Fi transcription.txt'
                      )}`
                    )
                  }
                  tabIndex={0}
                >
                  Voir la transcription
                </span>
              </td>
            </tr>
          </table>

          <Modal isOpen={this.state.videoVisible} toggle={this.toggleopen} centered={true} className='video'>
            <ModalHeader toggle={this.toggleopen}>Comment ajouter votre véhicule</ModalHeader>
            <ModalBody>
              {this.state.videoContentURL && (
                <Player playsInline src={`${this.state.videoContentURL}/CreateyourVWID-FR.mp4`} />
              )}
            </ModalBody>
          </Modal>
        </div>

        <div className='goback' hidden={!this.state.subContentUrl}>
          <span role='link' tabIndex={0} className='cwpBack ml-3' onClick={this.transcriptbackClick}>
            Précédent
          </span>
        </div>

        <div id='showFeatures' className='divFeatureswifi' hidden={!this.state.subContentUrl}>
          {this.state.subContentUrl && <Transcript contentUrl={this.state.subContentUrl} locale='fr-CA'></Transcript>}
          <br />
        </div>
      </div>
    );
  }
}

export default VideoDisplay;
