import React, { Component } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import sirisXMLogo from '../../../assets/vehicleHealth/sirius_xm.png';

class SirisXMStatus extends Component {
  render () {
    const siriusxmStatusData = this.props.sirisXMDetails || '';

    let translator = getLocaleTranslator();
    return (
      siriusxmStatusData &&
      siriusxmStatusData.radioEquipped === true && (
        <div>
          <Container className='themed-container' fluid='true'>
            <br />
            <Row>
              <Col xs='6' sm='4'>
                <div className='sxm-logo-align'>
                  <img src={sirisXMLogo} className='sirisXM-logo' alt='Sirius XM Logo' />
                </div>
              </Col>
              <Col xs='6' sm='4'>
                <div className='sxm-stausheader'>{siriusxmStatusData.statusHeader}</div>
              </Col>
              <Col sm='4'>
                <div className='sxm-btn-align'>
                  <a href='/#/sxmdetails'>
                    <button type='button' className='vhr-viewdetails-btn'>
                      {translator.t('vehicleHealth.view_details_btn_label')}
                    </button>
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )
    );
  }
}

export default SirisXMStatus;
