import React from 'react';
import PropTypes from 'prop-types';
import {
  AccessibilityStatus,
  LinkingStatus,
  SubscriptionStatus,
  getChamberlainCapabilityAndStatus,
  linkChamberlainAccount
} from '../../providers/AccountAndSecurityProvider';
import { redirectBrowserTo } from '../../../../util/utilityMethods';
import { findErrorMessage } from '../../../../util/i18n/i18nService';
import { UncontrolledAlert } from 'reactstrap';
import Spinner from '../../Spinner';

export default class MyQLinkStatus extends React.Component {
  static propTypes = {
    translator: PropTypes.object.isRequired,
    plan: PropTypes.object.isRequired
  };

  state = {
    alertMessage: null,
    loading: false,

    subscriptionStatus: null,
    accessibilityStatus: null,
    linkingStatus: null
  };

  componentDidMount () {
    this.initData();
  }

  initData = async () => {
    this.setState({ loading: true });
    try {
      const {
        subscriptionStatus,
        accessibilityStatus,
        linkingStatus = this.props.plan?.linkingStatus
      } = await getChamberlainCapabilityAndStatus();

      this.setState({ loading: false, subscriptionStatus, accessibilityStatus, linkingStatus });
    } catch (ex) {
      this.setState({ loading: false, alertMessage: findErrorMessage(ex) });
    }
  };

  onClickLinkAccount = async () => {
    const endUrl = window.location.hash.replace('#/', '');
    this.setState({ loading: true });

    try {
      const url = await linkChamberlainAccount({ endUrl });
      this.setState({ loading: false });

      redirectBrowserTo(url);
    } catch (ex) {
      this.setState({ loading: false, alertMessage: findErrorMessage(ex) });
    }
  };

  clearAlerts = () => this.setState({ alert: null });

  getAlerts = () => {
    if (this.state.alert) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts} aria-live='polite' className='w-100'>
          {this.state.alert}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  render () {
    const translator = this.props.translator;
    const { subscriptionStatus = null, accessibilityStatus = null, linkingStatus } = this.state;
    const subscriptionAvailable = subscriptionStatus?.toUpperCase() === SubscriptionStatus.AVAILABLE;

    return (
      <>
        {this.getAlerts()}
        {this.state.loading && <Spinner />}
        {!this.state.loading && subscriptionAvailable && (
          <article className='d-flex flex-column myQLinkStatus'>
            {LinkingStatus.Active.toUpperCase() === linkingStatus?.toUpperCase() && (
              <span>{translator.t('chamberlain.accountLinked')}</span>
            )}

            {LinkingStatus.Inactive.toUpperCase() === linkingStatus?.toUpperCase() && (
              <>
                <strong>{translator.t('chamberlain.accountNotLinked')}</strong>

                {accessibilityStatus === AccessibilityStatus.ACCESSIBLE && (
                  <article className='mt-3'>
                    {translator.t('chamberlain.accountLinkingDescriptionPart1')}
                    <button
                      type='button'
                      className='carnetLink myQLinkButton'
                      onClick={this.onClickLinkAccount}
                      disabled={this.state.loading}
                    >
                      {translator.t('chamberlain.accountLinkingDescriptionPart2')}
                    </button>
                    {translator.t('chamberlain.accountLinkingDescriptionPart3')}
                  </article>
                )}
              </>
            )}
          </article>
        )}
      </>
    );
  }
}
