// timer helper class
class timer {
  constructor (timeoutDuration) {
    this.timeoutDuration = timeoutDuration;
    this.timerId = null;
  }

  // start the timer, on timeout all registered callbacks will get called
  start = callback => {
    console.log('timer start >> ');
    clearTimeout(this.timerId);
    this.timerId = null;
    if (typeof callback !== 'function') {
      return;
    }
    this.timerId = setTimeout(callback, this.timeoutDuration);
  };

  // stop the timer, clear out any registered callbacks w/out calling them
  stop = () => {
    console.log('timer stop >> ');
    clearTimeout(this.timerId);
    this.timerId = null;
  };
}

export default timer;
