import React from 'react';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import NumberFormat from 'react-number-format';
import './AccountManagement.scss';
import '../CarnetHome.scss';
import './contactDealerModal.scss';

function ContactDealerModule ({ dealerInfo, onDismiss }) {
  let dealerInfoAvailable = !!dealerInfo;
  let translator = getLocaleTranslator();
  return (
    <Modal isOpen={dealerInfoAvailable} centered={true} className='acct-mgmt-contact-dealer-modal vhr' autoFocus={true}>
      <ModalHeader cssModule={{ 'modal-title': 'w-100 text-center' }}>
        <span className='modalheader-txt'>{translator.t('vehicleHealth.schedule_service_label')}</span>
      </ModalHeader>
      <ModalBody>
        <div>
          <div>
            <div className='service-modal-text'>{dealerInfo.name}</div>
            <div className='service-modal-text'>{dealerInfo.address1}</div>
            {dealerInfo.address2 && <div className='service-modal-text'>{dealerInfo.address2}</div>}
            <div className='service-modal-text'>
              <span>
                {dealerInfo.city}
                ,&nbsp;
                {dealerInfo.state}
                {dealerInfo.zip}
              </span>
            </div>
            <div className='service-modal-urltext'>{dealerInfo.url}</div>
            <div className='service-modal-text'>
              {translator.t('vehicleHealth.schedule_appointment_label')}

              {dealerInfo.phone && (
                <>
                  {' '}
                  <NumberFormat value={dealerInfo.phone} displayType={'text'} format='(###) ###-####' />
                </>
              )}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button className='gotit-btn' onClick={onDismiss}>
          {translator.t('vehicleHealth.got_it_label')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ContactDealerModule;
