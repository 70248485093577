import React from 'react';
import { BuyPlansContext } from '../BuyPlansContext';
import { MnoPorvider, findArticleDisclaimerText, findDataTrialPlan } from '../buyPlansUtil';
import { Link } from 'react-router-dom';
import MarketingProducts from '../components/MarketingProducts';
import { Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledAlert } from 'reactstrap';
import infoIcon from '../../../../assets/buyPlans/info-blue.svg';
import ReactTooltip from 'react-tooltip';
import AutoInsertLinkContent from '../components/AutoInsertLinkContent';
import { digitsOnly, formatPhoneNumber } from '../../CarnetHome';
import { BuyPlanPages } from '../';
import { checkEligibilityForPTP, submitPTP } from '../../providers/EstoreProvider';
import { findErrorMessage } from '../../../../util/i18n/i18nService';
import { getErrorCodeDesc } from '../../buyPlans/buyPlansUtil';
import Spinner from '../../Spinner';

export default class DataPTP extends React.Component {
  static contextType = BuyPlansContext;

  state = {
    loading: false,
    alertMessage: null,
    articleDisclaimer: null,
    pricingDisclaimers: null,
    primaryPhoneNumber: '',
    billingZipCode: '',

    providerName: null,
    dataTrailPlan: null,
    eligibility: null,
    ptpSuccess: false
  };

  componentDidMount () {
    this.initDataPTP();
  }

  initDataPTP = async () => {
    let plan = this.context?.selectedPlan;

    if (!plan) {
      const marketingConfigurationId = this.props.match?.params?.marketingConfigurationId;
      const { additionalPlans, selectPlanFromList } = this.context;

      const [, category, subCategory] = this.props.match?.url?.split('/') || [];
      plan = selectPlanFromList(additionalPlans, category, subCategory, marketingConfigurationId);
    }

    const marketingConfiguration = this.context.marketingConfigurationHash?.[plan?.marketingConfigurationId];
    const articleDisclaimer = findArticleDisclaimerText(marketingConfiguration);

    let pricingDisclaimers = null;
    if (Array.isArray(marketingConfiguration?.pricingDisclaimerList)) {
      pricingDisclaimers = AutoInsertLinkContent.parseValuesFromPricingDisclaimerList(
        marketingConfiguration.pricingDisclaimerList
      );
    }

    this.setState({ articleDisclaimer, pricingDisclaimers, providerName: plan?.provider?.name });

    window.scrollTo(0, 0);
  };

  inputAutoSet = e => {
    const { name, value } = e.currentTarget;

    this.setState({ [name]: digitsOnly(value) });
  };

  clearAlerts = () => this.setState({ alertMessage: null });

  getAlerts = () => {
    if (this.state.alertMessage) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts} aria-live='polite' className='w-100'>
          {this.state.alertMessage}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  validateForm = () => {
    const form = document.getElementById('formPTP');

    const isValid = form?.checkValidity();
    if (isValid !== true) {
      form?.reportValidity();
    }

    return isValid;
  };

  onSubmit = e => {
    e.preventDefault();

    const allInputValid = this.validateForm();

    if (!allInputValid) {
      return;
    }

    const dataTrailPlan = findDataTrialPlan(this.context.additionalPlans);

    if (dataTrailPlan) {
      this.setState({ dataTrailPlan });

      return;
    }

    this.checkEligibility();
  };

  checkEligibility = async () => {
    const { providerName, primaryPhoneNumber, billingZipCode } = this.state;

    this.setState({ loading: true });
    try {
      const responseData = await checkEligibilityForPTP(providerName, primaryPhoneNumber, billingZipCode);

      this.setState({ loading: false, eligibility: responseData?.data });
    } catch (ex) {
      let errorMessage = null;
      if (ex.error?.errorCode) {
        errorMessage = getErrorCodeDesc(ex?.error?.errorCode);
      }

      this.setState({ loading: false, alertMessage: errorMessage || findErrorMessage(ex) });
    }
  };

  attemptSubmitPTP = async () => {
    const { providerName, primaryPhoneNumber, billingZipCode, eligibility } = this.state;
    try {
      await submitPTP(providerName, primaryPhoneNumber, billingZipCode, eligibility?.ptpRef);
      this.setState({ ptpSuccess: true });
    } catch (ex) {
      let errorMessage = null;
      if (ex.error?.errorCode) {
        errorMessage = getErrorCodeDesc(ex?.error?.errorCode);
      }
      this.setState({ alertMessage: errorMessage || findErrorMessage(ex) });
    } finally {
      this.setState({ loading: false, eligibility: null });
    }
  };

  onClickActivateDataTrial = () => {
    const { category, subCategory, marketingConfigurationId } = this.state.dataTrailPlan || {};

    this.setState({ dataTrailPlan: null });
    this.context.selectPlan(null);

    this.context.redirectTo(`${BuyPlanPages.Home}/${category}/${subCategory}/${marketingConfigurationId}`);
  };

  onClickSkipTrial = () => {
    this.setState({ dataTrailPlan: null }, this.checkEligibility);
  };

  redirectToAlternatePlan = alternatePlan => {
    const { category, subCategory, marketingConfigurationId } = alternatePlan || {};

    this.context.selectPlan(null);
    if (category && subCategory && marketingConfigurationId) {
      this.context.redirectTo(`${BuyPlanPages.Home}/${category}/${subCategory}/${marketingConfigurationId}`);
    } else {
      this.context.redirectTo(BuyPlanPages.Home);
    }
  };

  render () {
    const {
      translator,
      contentURL,
      showDisclaimer,
      selectPlan,
      selectedPlan: plan,
      marketingConfigurationHash,
      redirectTo
    } = this.context;
    const marketingConfiguration = marketingConfigurationHash?.[plan?.marketingConfigurationId] || {};

    const pricingDisclaimers = this.state.pricingDisclaimers;

    return (
      <div role='main' id='main-content' className='py-4 dataPTP'>
        <div className='ml-4 position-absolute'>
          <Link to='/' className='linkBack' role='button' onClick={() => selectPlan(null)}>
            {translator.t('buyplans.button-label.back-to-your-plans')}
          </Link>
        </div>
        <div className='container d-flex flex-column align-items-center text-center mt-4 pb-5'>
          <h1 className='font-weight-light'>
            {marketingConfiguration?.summary?.title || marketingConfiguration?.title}
          </h1>
          <section className='text-muted text-center my-2'>
            <article>
              {marketingConfiguration?.summary?.shortDescription || marketingConfiguration?.title}
              {this.state.articleDisclaimer && (
                <button
                  className='cwp-disclaimer ml-2'
                  tabIndex={0}
                  type='button'
                  onClick={e =>
                    showDisclaimer(
                      this.state.articleDisclaimer,
                      marketingConfiguration.articleDisclaimerNumber,
                      e.currentTarget
                    )
                  }
                >
                  {marketingConfiguration?.articleDisclaimerNumber}
                </button>
              )}
            </article>
            <article>{marketingConfiguration?.additionalDescription}</article>
          </section>
          {this.getAlerts()}
          {this.state.ptpSuccess && (
            <section className='col-12 col-md-8 d-flex flex-column align-items-start mt-5'>
              <img
                src={'' + contentURL + marketingConfiguration?.summary?.providerLogo}
                alt={marketingConfiguration?.summary?.providerName}
                className='bgIconSuccess'
              />

              <strong className='text-left my-4 text-primary h5 font-weight-bold'>
                {this.state.providerName?.toUpperCase() === MnoPorvider.TMO.toUpperCase()
                  ? `${translator.t('tmo-ptp-submit-success-part1')} ${this.state.providerName} ${translator.t(
                      'tmo-ptp-submit-success-part2'
                    )} ${this.state.primaryPhoneNumber} ${translator.t('tmo-ptp-submit-success-part3')}`
                  : `${translator.t('verizon-ptp-submit-success-part1')} ${
                      this.state.primaryPhoneNumber
                    } ${translator.t('verizon-ptp-submit-success-part2')}`}
              </strong>

              <button type='button' className='cwp cwpPrimary' onClick={() => redirectTo(BuyPlanPages.Home)}>
                {translator.t('buyplans.button-label.finished')}
              </button>
            </section>
          )}

          {!this.state.ptpSuccess && (
            <>
              <section className='col-12 col-md-8 d-flex flex-column align-items-start mb-5 text-left'>
                <strong className='text-left my-4 h5 font-weight-bold'>{marketingConfiguration?.description}</strong>
                <img
                  src={'' + contentURL + marketingConfiguration?.summary?.providerLogo}
                  alt={marketingConfiguration?.summary?.providerName}
                  className='my-3'
                />

                <form id='formPTP' className='form-row w-100 no-gutters' onSubmit={e => e.preventDefault()}>
                  <article className='col-12 col-md-6 my-4'>
                    <input
                      id='primaryPhoneNumber'
                      type='tel'
                      name='primaryPhoneNumber'
                      onChange={this.inputAutoSet}
                      value={formatPhoneNumber(this.state.primaryPhoneNumber)}
                      maxLength={14}
                      placeholder={translator.t('buyplans_ptp_primary_phone_number')}
                      className='cwp pr-4'
                      required={true}
                    />
                    <input
                      type='image'
                      src={infoIcon}
                      alt={translator.t('buyplans_info_logo')}
                      className='infoIcon'
                      onClick={e => e.preventDefault()}
                      data-tip
                      data-for='toolTipPrimaryPhoneNumber'
                      tabIndex={-1}
                    />
                    <ReactTooltip id='toolTipPrimaryPhoneNumber' place='top' effect='solid'>
                      {translator.t('buyplans_ptp_phone_number_tip')}
                    </ReactTooltip>
                  </article>

                  <article className='col-12 col-md-6 my-3'>
                    <input
                      id='billingZipCode'
                      type='text'
                      name='billingZipCode'
                      onChange={this.inputAutoSet}
                      value={this.state.billingZipCode}
                      placeholder={translator.t('buyplans_ptp_billing_zip_code')}
                      className='cwp pr-4'
                      required={true}
                    />

                    <input
                      type='image'
                      src={infoIcon}
                      alt={translator.t('buyplans_info_logo')}
                      className='infoIcon'
                      onClick={e => e.preventDefault()}
                      data-tip
                      data-for='toolTipBillingZipCode'
                      tabIndex={-1}
                    />
                    <ReactTooltip id='toolTipBillingZipCode' place='top' effect='solid'>
                      {translator.t('buyplans_ptp_zipcode_tip')}
                    </ReactTooltip>
                  </article>

                  <article>
                    {this.state.loading && <Spinner />}
                    <button
                      type='button'
                      className='cwp cwpPrimary mt-4'
                      onClick={this.onSubmit}
                      disabled={this.state.loading}
                    >
                      {translator.t('buyplans.button-label.verify-account')}
                    </button>
                  </article>
                </form>
              </section>
              {Array.isArray(pricingDisclaimers) &&
                pricingDisclaimers.map((pd, index) => (
                  <article key={`pricingDisclaimer${index}`} className='small text-muted text-left my-2'>
                    <AutoInsertLinkContent value={pd} />
                  </article>
                ))}
            </>
          )}

          {this.state.dataTrailPlan && (
            <Modal className='carnet-sg-modal' isOpen={true} centered={true} aria-live='polite'>
              <ModalHeader className='font-weight-bold'>
                {translator.t('buyplans_free_wifi_trial_available')}
              </ModalHeader>
              <ModalBody>{translator.t('buyplans_free_wifi_trial_available_desc')}</ModalBody>
              <ModalFooter className='d-flex flex-column align-items-center'>
                <button type='button' className='modal-primary-btn w-100' onClick={this.onClickActivateDataTrial}>
                  {translator.t('buyplans_activate_trial')}
                </button>
                <button type='button' className='modal-secondary-btn w-100' onClick={this.onClickSkipTrial}>
                  {translator.t('buyplans_skip_trial')}
                </button>
              </ModalFooter>
            </Modal>
          )}

          <Modal isOpen={this.state.eligibility?.eligible === false} centered={true} className='carnet-sg-modal'>
            <ModalHeader className='font-weight-bold'>{translator.t('buyplans_ptp_oops')}</ModalHeader>
            <ModalBody>
              <span className='font-weight-light'>{translator.t(this.state.eligibility?.reasonCode)}</span>
            </ModalBody>
            <ModalFooter className='d-flex flex-column'>
              {this.state.eligibility?.alternatePlan?.eligible === true && (
                <button
                  type='button'
                  className='modal-primary-btn w-100'
                  onClick={() => this.redirectToAlternatePlan(this.state.eligibility?.alternatePlan)}
                >
                  {translator.t('buyplans_ptp_monthlyplan_button_text')}
                </button>
              )}

              <button
                type='button'
                className='modal-secondary-btn w-100'
                onClick={() => this.setState({ eligibility: null })}
              >
                {translator.t('buyplans.button-label.try-again')}
              </button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={this.state.eligibility?.eligible === true} centered={true} className='carnet-sg-modal'>
            <ModalHeader className='font-weight-bold'>{translator.t('buyplans_ptp_account_verified')}</ModalHeader>
            <ModalBody className='font-weight-light d-flex flex-column'>
              {this.state.providerName === MnoPorvider.TMO ? (
                <>{translator.t('buyplans_ptp_account_elgibility_tmo_success')}</>
              ) : (
                <>
                  {translator.t('buyplans_ptp_account_elgibility_success_part1')}
                  {translator.t('buyplans_ptp_account_elgibility_success_part2')} {this.state.primaryPhoneNumber}
                  &nbsp;{translator.t('buyplans_ptp_account_elgibility_success_part3')}
                </>
              )}
              <div>{translator.t('buyplans_ptp_account_elgibility_legal_notice')}</div>
              <div>{translator.t('buyplans_ptp_account_elgibility_legal_notice_desc')}</div>
            </ModalBody>
            <ModalFooter className='d-flex flex-column'>
              <button type='button' className='modal-primary-btn w-100' onClick={this.attemptSubmitPTP}>
                {translator.t('button.submit')}
              </button>

              <button
                type='button'
                className='modal-secondary-btn w-100'
                onClick={() => this.setState({ eligibility: null })}
              >
                {translator.t('button.cancel')}
              </button>
            </ModalFooter>
          </Modal>
        </div>

        {plan && (
          <MarketingProducts
            contentURL={contentURL}
            plan={plan}
            marketingConfiguration={marketingConfiguration}
            showDisclaimer={this.context.showDisclaimer}
          />
        )}
      </div>
    );
  }
}
