import React from 'react';
import carnet_constants from '../../../constants/carnet_constants';
import { BuyPlansContext } from './BuyPlansContext';
import ReactTooltip from 'react-tooltip';
import infoBlue from '../../../assets/buyPlans/info-blue.svg';
import CardCurrentPlan from './components/CardCurrentPlan';
import CardAdditionalPlan from './components/CardAdditionalPlan';
import { hasActiveDataPlan } from './buyPlansUtil';

const MnoProviderLogo = Object.freeze({
  [carnet_constants.MNO_PROVIDER_TMO]: 'img_v2/tmobile.png',
  [carnet_constants.MNO_PROVIDER_VERIZON]: 'img_v2/verizon.png',
  [carnet_constants.MNO_PROVIDER_ROGERS]: 'img_v2/rogers.png'
});

export default class BuyPlansHome extends React.Component {
  static contextType = BuyPlansContext;

  componentDidMount () {
    const { loading, currentPlans } = this.context;
    const hasActivePlans = Array.isArray(currentPlans) && currentPlans.length > 0;

    if (!loading && hasActivePlans) {
      this.context.initPlansAndCart();
    }
  }

  render () {
    const {
      translator,
      contentURL,
      mnoProvider,
      userCountry,
      userLang,
      currentPlans = [],
      additionalPlans = [],
      marketingConfigurationHash
    } = this.context;

    let mnoLogo = null;
    if (contentURL && mnoProvider && MnoProviderLogo[mnoProvider]) {
      mnoLogo = contentURL + MnoProviderLogo[mnoProvider];
    }

    const hasActivePlans = Array.isArray(currentPlans) && currentPlans.length > 0;

    return (
      <>
        {/* Page Title, subtitle & logo */}
        <article className='d-flex flex-column justify-content-center align-items-center'>
          {mnoLogo && (
            <>
              <span className='text-muted my-2'>{translator.t('buyplans.label.your-current-carrier')}</span>
              <img src={mnoLogo} alt={mnoProvider} className={`mb-5 providerIcon${mnoProvider}`}></img>
            </>
          )}
          <h1 className='h2 font-weight-normal text-center'>
            {translator.t('buyplans_landing_your_label')}&nbsp;
            <strong>{translator.t('buyplans_landing_current_plans_label')}</strong>
          </h1>
          <span className='text-muted'>
            {translator.t(
              hasActivePlans ? 'buyplans_landing_current_plans_subheading_label' : 'buyplans.label.no-active-plans'
            )}
          </span>
        </article>
        {/* Current Plans */}
        {hasActivePlans && (
          <div className='container'>
            <section className='row justify-content-between align-items-stretch'>
              {currentPlans.map((plan, index) => (
                <section key={`currentPlan${index}`} className='col-12 col-md-6 mx-auto text-left mb-4'>
                  <CardCurrentPlan
                    plan={plan}
                    marketingConfiguration={marketingConfigurationHash?.[plan?.marketingConfigurationId]}
                  />
                </section>
              ))}
              {userCountry === 'US' && hasActiveDataPlan(this.context.currentPlans) && (
                <article className='col-12'>
                  <span className='dataPlanCancelInfo'>{translator.t('buyplans_cancel_data_plan')}</span>
                  <img data-tip data-for='registerTip1' src={infoBlue} alt={translator.t('buyplans_info_icon')} />
                  <ReactTooltip id='registerTip1' place='top' effect='solid'>
                    <span className=''>
                      {translator.t('buyplans_data_plan_vzt_cancel_info')}
                      <br />
                      {translator.t('buyplans_data_plan_t-mobile_cancel_info')}
                    </span>
                  </ReactTooltip>
                </article>
              )}
            </section>
          </div>
        )}
        {/* Additional Plans */}
        {Array.isArray(additionalPlans) && additionalPlans.length > 0 && (
          <>
            <section className='d-flex flex-row justify-content-between align-items-center mt-5'>
              <hr className='flex-grow-1 cwp-border-lagacy' />
              <h1 className='px-3 px-md-5 mx-md-5'>
                {['fr'].includes(userLang) ? (
                  <>
                    <span className='font-weight-normal'>{translator.t('buyplans_landing_plans_label')}&nbsp;</span>
                    {translator.t('buyplans_landing_additional_label')}
                  </>
                ) : (
                  <>
                    {translator.t('buyplans_landing_additional_label')}
                    <span className='font-weight-normal'>&nbsp;{translator.t('buyplans_landing_plans_label')}</span>
                  </>
                )}
              </h1>
              <hr className='flex-grow-1 cwp-border-lagacy' />
            </section>
            <article className='text-muted text-center'>
              {translator.t('buyplans_landing_additional_plans_subheading_label')}
            </article>
            <div className='container'>
              <section className='row no-gutters justify-content-between align-items-stretch mt-4'>
                {additionalPlans.map(plan => (
                  <section
                    key={plan?.marketingConfigurationId}
                    className='col-12 col-md-6 col-lg-4 mx-auto text-left mb-4 cardAdditionalPlan'
                  >
                    <CardAdditionalPlan
                      plan={plan}
                      marketingConfiguration={marketingConfigurationHash?.[plan?.marketingConfigurationId]}
                    />
                  </section>
                ))}
              </section>
            </div>
          </>
        )}
      </>
    );
  }
}
