import axios from 'axios';

const hasValidImageUrl = async vehicleId => {
  if (!vehicleId) {
    return false;
  }

  try {
    await axios.post('/common/utils/headCheckVehicleImageUrl', { vehicleId });
    return true;
  } catch (error) {
    return false;
  }
};

const invoke = async requestConfig => {
  if (!requestConfig || !requestConfig.url) return;

  if (!requestConfig.method) requestConfig.method = 'GET';

  if (!requestConfig.headers) requestConfig.headers = {};

  if (!requestConfig.headers.accept) requestConfig.headers.accept = 'application/json';

  try {
    let response = await axios(requestConfig);

    if (!response.data) {
      return response;
    }

    const { data } = response;

    if (data.error || data.errorCode) {
      return Promise.reject(data);
    }

    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export { invoke, hasValidImageUrl };
export default invoke;
