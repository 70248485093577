import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { Parser as HtmlToReactParser } from 'html-to-react';
import '../marketingVideo/Video-react.scss';
import disclaimer from '../../../assets/disclaimer/disclaimer.png';
import back from '../../../assets/faq/okay.png';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import { findErrorMessage } from '../../../util/i18n/i18nService';
import './landingPage.scss';
import Tooltip from '../landingPageEV/tooltip';

const MY17_19_UNDER_WARRANTY = 'my17-19_under_warranty';
const MY17_19_NOT_UNDER_WARRANTY = 'my17-19_not_under_warranty';
const MY14_16_UNDER_WARRANTY = 'my14-16_under_warranty';
const MY14_16_NOT_UNDER_WARRANTY = 'my14-16_not_under_warranty';

export class VinLookUp extends Component {
  state = {
    vin: '',
    loading: false,
    modelVisible1: false,
    modelVisible2: false,
    modelVisible3: false,
    modelVisible4: false,
    modelVisible5: false,
    captchaRequired: false,
    captchaData: null,
    captchaInput: ''
  };

  constructor (props) {
    super(props);
  }

  handleClose1 = () => {
    this.setState({ modelVisible1: false });
  };
  handleClose2 = () => {
    this.setState({ modelVisible2: false });
  };
  handleClose3 = () => {
    this.setState({ modelVisible3: false });
  };
  handleClose4 = () => {
    this.setState({ modelVisible4: false });
  };
  handleClose5 = () => {
    this.setState({ modelVisible5: false });
  };

  checkVinEntry = () => {
    if (!this.state.vin || this.state.vin.length < 17) {
      return false;
    }
    return true;
  };

  parseSvgContent = () => {
    const parser = new HtmlToReactParser();
    let parsed = parser.parse(this.state.captchaData);
    return parsed;
  };

  vinCheckEligibility = async () => {
    this.setState({ loading: true }, async () => {
      try {
        let url = `/cwp/3gsunset/vin/${this.state.vin}`;
        if (this.state.captchaInput) {
          url = `/cwp/3gsunset/vin/${this.state.vin}?captcha=${this.state.captchaInput}`;
        }
        let response = await axios.get(url);

        let responseData = response && response.data;
        let responseIsCaptcha = responseData && /^<svg/.test(responseData);
        let status = (response && response.data && response.data.data && response.data.data.status) || '';

        if (responseIsCaptcha) {
          this.setState({
            loading: false,
            captchaRequired: true,
            captchaData: responseData,
            captchaInput: ''
          });
          return;
        }
        if (status === MY17_19_UNDER_WARRANTY) {
          this.setState({ modelVisible1: true });
        } else if (status === MY17_19_NOT_UNDER_WARRANTY) {
          this.setState({ modelVisible2: true });
        } else if (status === MY14_16_UNDER_WARRANTY) {
          this.setState({ modelVisible3: true });
        } else if (status === MY14_16_NOT_UNDER_WARRANTY) {
          this.setState({ modelVisible4: true });
        } else {
          this.setState({ modelVisible5: true });
        }
        this.setState({ loading: false, vin: '', captchaInput: '', captchaData: null, captchaRequired: false });
      } catch (error) {
        /* Error during vin Eligibility Check */
        console.log('Error During Eligibility Check ::' + findErrorMessage(error));
        this.setState({ loading: false, captchaInput: '' });
      }
    });
  };

  render () {
    return (
      <div className='landingPageWrapper'>
        <Tooltip />
        <div className='vinlookup'>
          <div className='vinlookup-header'>
            <span>Is there a replacement option available for my vehicle?</span> <br />
            <span className='vinlookup-sub-header'>
              <span>Enter your VIN to check replacement option.</span>
              <sup data-tip data-for='vinlookup' className='tooltip-vinlookup'>
                <img src={disclaimer} alt='remote' width='15px'></img>
              </sup>
            </span>
          </div>

          {this.state.loading && (
            <>
              <div className='spinner-border' role='status'>
                <span className='sr-only'></span>
              </div>
              <div> &nbsp;</div>
            </>
          )}

          {this.state.captchaRequired && !this.state.loading && this.state.captchaData && (
            <div>
              <>
                {this.parseSvgContent()}
                <div>
                  <input
                    className={'vin-cwp-input'}
                    placeholder='Enter Captcha Value'
                    id='user_captcha_input'
                    name='user_captcha_input'
                    type='text'
                    onChange={e => {
                      let captchaInput = e.target.value;
                      this.setState({ captchaInput });
                    }}
                  ></input>
                  <button className='checkEligiblityBtn' onClick={this.vinCheckEligibility}>
                    Submit
                  </button>
                </div>
              </>
            </div>
          )}
          {!this.state.captchaRequired && !this.state.loading && (
            <div>
              <input
                className='vin-cwp-input'
                type='text'
                onChange={e => {
                  let cleanedVin = String(e.target.value).replace(/[^0-9a-z]/i, '');
                  this.setState({
                    vin: cleanedVin
                  });
                }}
                placeholder='VIN Number'
                maxLength='17'
                value={this.state.vin}
                autoComplete='off'
              />
              <button
                disabled={!this.checkVinEntry()}
                className={!this.checkVinEntry() ? 'checkEligiblityBtn disabled' : 'checkEligiblityBtn'}
                onClick={this.vinCheckEligibility}
              >
                Check Eligibility
              </button>
            </div>
          )}
          <br />
          <br />
        </div>

        {/* ======================= Modal 1th PopUp for my17-19_under_CPO_warranty ================= */}

        <Modal
          isOpen={this.state.modelVisible1}
          toggle={this.toggleopen}
          centered={true}
          onHide={this.handleClose1}
          className='vinlookup'
        >
          <ModalHeader toggle={this.toggleopen} className='vinlookupmdheader'>
            {' '}
          </ModalHeader>
          <ModalBody>
            <span className='mdl-wd'>
              {' '}
              <b>
                Due to unanticipated difficulties, the timing to release any technical solution is uncertain at this
                time. We will update this page when we have more information.
              </b>
              <br />
              <br />
              <ModalFooter>
                <img src={back} onClick={this.handleClose1} alt='keep shopping' className='eStoreCursorPointer' />
              </ModalFooter>
            </span>
          </ModalBody>
        </Modal>

        {/* ======================= Modal 2st PopUp for my17-19_Not_under_CPO_warranty================= */}

        <Modal
          isOpen={this.state.modelVisible2}
          toggle={this.toggleopen}
          centered={true}
          onHide={this.handleClose2}
          className='vinlookupCPO'
        >
          <ModalHeader toggle={this.toggleopen} className='vinlookupmdheader'>
            {' '}
          </ModalHeader>
          <ModalBody>
            <span className='mdl-wd'>
              <b>
                Due to unanticipated difficulties, the timing to release any technical solution is uncertain at this
                time. We will update this page when we have more information.
              </b>
              <br />
              <br />

              <ModalFooter>
                <img src={back} onClick={this.handleClose2} alt='keep shopping' className='eStoreCursorPointer' />
              </ModalFooter>
            </span>
          </ModalBody>
        </Modal>

        {/* ======================= Modal 3nd PopUp for my14-16_under_CPO_warranty================= */}

        <Modal
          isOpen={this.state.modelVisible3}
          toggle={this.toggleopen}
          centered={true}
          onHide={this.handleClose3}
          className='vinlookup-noteligible'
        >
          <ModalHeader toggle={this.toggleopen} className='vinlookupmdheader'>
            {' '}
          </ModalHeader>
          <ModalBody>
            <span className='mdl-wd'>
              {' '}
              <b>
                Due to unanticipated difficulties, the timing to release any technical solution is uncertain at this
                time. We will update this page when we have more information.
              </b>
              <br />
              <br />
              <ModalFooter>
                <img src={back} onClick={this.handleClose3} alt='' className='eStoreCursorPointer' />
              </ModalFooter>
            </span>
          </ModalBody>
        </Modal>
        {/* ======================= Modal 4nd PopUp for my14-16_Not_under_CPO_warranty================= */}
        <Modal
          isOpen={this.state.modelVisible4}
          toggle={this.toggleopen}
          centered={true}
          onHide={this.handleClose4}
          className='vinlookup-noteligible'
        >
          <ModalHeader toggle={this.toggleopen} className='vinlookupmdheader'>
            {' '}
          </ModalHeader>
          <ModalBody>
            <span className='mdl-wd'>
              {' '}
              <b>
                Due to unanticipated difficulties, the timing to release any technical solution is uncertain at this
                time. We will update this page when we have more information.
              </b>
              <br />
              <br />
              <ModalFooter>
                <img src={back} onClick={this.handleClose4} alt='' className='eStoreCursorPointer' />
              </ModalFooter>
            </span>
          </ModalBody>
        </Modal>

        {/* ======================= Modal 5nd PopUp for not_eligible================= */}

        <Modal
          isOpen={this.state.modelVisible5}
          toggle={this.toggleopen}
          centered={true}
          onHide={this.handleClose5}
          className='vinlookup-noteligible'
        >
          <ModalHeader toggle={this.toggleopen} className='vinlookupmdheader'>
            {' '}
          </ModalHeader>
          <ModalBody>
            <span>
              We're sorry. The VIN entered is not associated with a vehicle that is equipped with Car-Net. If you think
              you have received this message in error, please contact CARE at &nbsp;
              <a href='https://www.vw.com/en/contact.html'> www.vw.com/contact.</a>
              eGolf vehicles are not eligible for any technical solution that would continue to offer telematics
              services.
              <br />
              <ModalFooter>
                <img src={back} onClick={this.handleClose5} alt='' className='eStoreCursorPointer' />
              </ModalFooter>
            </span>
          </ModalBody>
        </Modal>
        {/* ======================= Modal 5nd PopUp for my17-19_Notunder_CPO_warranty================= */}

        <ReactTooltip id='vinlookup' place='top' effect='solid'>
          Please note that the VIN (Vehicle Identification Number) does not use the letters “O, ” “I, ” or “Q.” The VIN
          can usually be found on the driver's side dashboard, near or under the bottom.
        </ReactTooltip>
      </div>
    );
  }
}

export default VinLookUp;
