import React, { Component } from 'react';
import Faq from 'react-faq-component';
import '../faqCanada/faqCA.scss';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import goback from '../../../assets/faq/goback_fr.png';
import { historyBack } from './faqCAhome';
import { setTitle } from '../../../providers/documentTitleProvider';

export default class VHRFaqCA extends Component {
  componentDidMount () {
    setTitle('document_titles.faq.vhr');
  }
  render () {
    let translator = getLocaleTranslator();

    return (
      <div className='faqCAheader' role='main' id='main-content'>
        <div className='goback'>
          <span onClick={historyBack}>
            <img src={goback} alt='viewfaq' width='100'></img>
          </span>
        </div>
        <div className='message-header'>
          <div className='td-ms-header'>
            <h1>{translator.t('vhr_title')}</h1>
          </div>
        </div>
        <Faq data={data} />
      </div>
    );
  }
}

const data = {
  rows: [
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          Qu’est-ce qu’un bilan de santé du véhicule?{' '}
        </div>
      ),
      content: `<span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span> Le bilan de santé du véhicule donne un aperçu de l’état de votre véhicule. Cette information est recueillie chaque fois que votre véhicule est éteint. En demandant un nouveau bilan de santé du véhicule à partir de l’application mobile ou du site Web, vous obtiendrez de l’information sur votre véhicule dès que le moteur est éteint.
      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Que signifient les différents codes de couleurs du bilan de santé du véhicule?
        </div>
      ),
      content: `<li>Vert – Aucun problème décelé pour le moment</li></br>
                <li>Jaune – Entretien ou réparations nécessaires</li></br>
                <li>Rouge – Éteignez le moteur immédiatement et ne prenez pas la route. Communiquez sans plus tarder avec un concessionnaire Volkswagen agréé.</li>`
    },

    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          Comment puis-je obtenir le bilan de santé de mon véhicule?{' '}
        </div>
      ),
      content: ` <span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span> Vous recevrez automatiquement un bilan de santé du véhicule à chaque anniversaire mensuel de votre inscription à Car-NetMD de Volkswagen. Vous pouvez également demander un bilan de santé ponctuel à partir de l’application mobile ou du portail Web. Sur le portail Web Car-NetMD de Volkswagen, vous trouverez les bilans de santé du véhicule des 12 derniers mois. 
      `
    }
  ]
};

export { VHRFaqCA };
