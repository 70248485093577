import {
  REFRESH_VEHICLE_HEALTH,
  REFRESH_VEHICLE_HEALTH_SUCCESS,
  REFRESH_VEHICLE_HEALTH_FAILURE
} from '../../actions/types';

const initalState = {
  adhocVHRData: null
};

const refreshVehicleHealthReducer = (state = initalState, action = { payload: {} }) => {
  let newState = { ...state };
  let actionType = (action && action.type) || 'default';
  let responseData = action.payload || {};

  switch (actionType) {
    case REFRESH_VEHICLE_HEALTH:
      newState = { ...initalState };

      return newState;

    case REFRESH_VEHICLE_HEALTH_SUCCESS:
      let { data } = responseData;
      newState['adhocVHRData'] = data || null;

      return newState;
    case REFRESH_VEHICLE_HEALTH_FAILURE:
      newState['adhocVHRData'] = null;

      return newState;
    default:
      return newState;
  }
};

export default refreshVehicleHealthReducer;
