import React, { Component } from 'react';
import './Navbar.scss';
import '../CarnetHome.scss';
import axios from 'axios';
import Navbar from './Navbar';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LanguageToggle from '../../language_toggle/toggle';
import LoginOut from '../loginOut/LoginOut';
import HeaderPrePinAuth from '../garage/HeaderPrePinAuth';

class Banner extends Component {
  constructor (props) {
    super(props);
  }

  render () {
    if (!this.props.userVehicleContext || !this.props.userVehicleContext.vin) {
      return <HeaderPrePinAuth />;
    }
    if (this.props.userVehicleContext.vin) {
      {
        return <Navbar />;
      }
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({}, dispatch)
  };
};

const mapStateToProps = state => {
  const { userVehicleContext } = state;

  return {
    ...userVehicleContext
  };
};

export default connect(mapStateToProps, null)(Banner);
