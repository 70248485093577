import React from 'react';
import { HashRouter as Router, Switch, Route, Link } from 'react-router-dom';
import carnet_constants from '../../../constants/carnet_constants';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { isUserPrimary } from '../../../providers/userRolesProvider';
import './AccountManagement.scss';

const AccountManagementLinkRoutes = Object.freeze({
  personalInformation: '/acct-mgmt/personal',
  security: '/acct-mgmt/security',
  marketing: '/acct-mgmt/marketing',
  vehicleInformation: '/acct-mgmt/vehicle',
  subscriptions: '/acct-mgmt/subscriptions',
  wifi: '/acct-mgmt/wifisettings',
  managealerts: '/acct-mgmt/managealerts',
  additionalDrivers: '/acct-mgmt/additionaldrivers',
  thirdparty: '/acct-mgmt/thirdparty'
});

const getCurrentLocation = () => {
  let sectionArr = window.location.href && window.location.href.split('/acct-mgmt/');
  let section = sectionArr[sectionArr.length - 1];
  section = '/acct-mgmt/' + section;
  let itemInHash;
  for (const [key, value] of Object.entries(AccountManagementLinkRoutes)) {
    if (value === section) {
      itemInHash = AccountManagementLinkRoutes[key];
    }
  }
  if (!itemInHash) {
    return AccountManagementLinkRoutes.personalInformation;
  }
  return itemInHash;
};

class Leftnav extends React.Component {
  constructor (props) {
    super(props);
    this.translator = null;
    this.state = { currentSection: '' };
  }

  componentDidMount () {
    this.setState({ currentSection: getCurrentLocation() });
  }

  componentDidUpdate () {
    if (this.state.currentSection !== getCurrentLocation()) {
      this.setState({ currentSection: getCurrentLocation() });
    }
  }

  render () {
    this.translator = getLocaleTranslator();
    const userIsPrimary = isUserPrimary();
    return (
      <nav className='carnet-acctmgmt-nav'>
        <div className='acctmgmt-nav-header'>
          {/* Your Profile */}
          <h2 className='nav-header-label'>{this.translator.t('acctmgmt_profile_heading')}</h2>
        </div>
        <ul className='nav flex-column'>
          <li>
            <Link
              to={AccountManagementLinkRoutes.personalInformation}
              className={
                this.state.currentSection === AccountManagementLinkRoutes.personalInformation
                  ? 'nav-item acctmgmt-navitem cwpFocusVisible active'
                  : 'nav-item acctmgmt-navitem cwpFocusVisible'
              }
              aria-current={this.state.currentSection === AccountManagementLinkRoutes.personalInformation}
            >
              {/* Personal Info */}
              {this.translator.t('acctmgmt_pi_heading')}
            </Link>
          </li>
          <li>
            {/* Login & Security */}
            <Link
              to={AccountManagementLinkRoutes.security}
              className={
                this.state.currentSection === AccountManagementLinkRoutes.security
                  ? 'nav-item acctmgmt-navitem cwpFocusVisible active'
                  : 'nav-item acctmgmt-navitem cwpFocusVisible'
              }
              aria-current={this.state.currentSection === AccountManagementLinkRoutes.security}
            >
              {this.translator.t('acctmgmt_lns_heading')}
            </Link>
          </li>

          {this.props.isCanadian === true && (
            <li>
              <Link
                to={AccountManagementLinkRoutes.marketing}
                className={
                  this.state.currentSection === AccountManagementLinkRoutes.marketing
                    ? 'nav-item acctmgmt-navitem cwpFocusVisible active'
                    : 'nav-item acctmgmt-navitem cwpFocusVisible'
                }
                aria-current={this.state.currentSection === AccountManagementLinkRoutes.marketing}
              >
                {/* Marketing Consent */}
                {this.translator.t('acctmgmt_mc_heading')}
              </Link>
            </li>
          )}
        </ul>
        <br />
        <br />
        {/* Second Section: Vehicle */}
        <div className='acctmgmt-nav-header'>
          {/* Vehicle */}
          <h2 className='nav-header-label'>{this.translator.t('acctmgmt_vehicle_heading')}</h2>
        </div>
        <ul className='nav flex-column'>
          <li>
            {/* Vehicle Info */}
            <Link
              to={AccountManagementLinkRoutes.vehicleInformation}
              className={
                this.state.currentSection === AccountManagementLinkRoutes.vehicleInformation
                  ? 'nav-item acctmgmt-navitem cwpFocusVisible active'
                  : 'nav-item acctmgmt-navitem cwpFocusVisible'
              }
              aria-current={this.state.currentSection === AccountManagementLinkRoutes.vehicleInformation}
            >
              {this.translator.t('acctmgmt_vi_heading')}
            </Link>
          </li>

          {/* Carnet Subscriptions is for VZT-Only!! */}
          {this.props.tsp && this.props.tsp === carnet_constants.TSP_VZT && (
            <li>
              <Link
                to={AccountManagementLinkRoutes.subscriptions}
                className={
                  this.state.currentSection === AccountManagementLinkRoutes.subscriptions
                    ? 'nav-item acctmgmt-navitem cwpFocusVisible active'
                    : 'nav-item acctmgmt-navitem cwpFocusVisible'
                }
                aria-current={this.state.currentSection === AccountManagementLinkRoutes.subscriptions}
              >
                {this.translator.t('acctmgmt_carnet_subs_label')}
              </Link>
            </li>
          )}

          {this.props.tsp && this.props.tsp !== carnet_constants.TSP_VZT && (
            <li>
              {/* Wi-Fi Settings & Plans */}
              <Link
                to={AccountManagementLinkRoutes.wifi}
                className={
                  this.state.currentSection === AccountManagementLinkRoutes.wifi
                    ? 'nav-item acctmgmt-navitem cwpFocusVisible active'
                    : 'nav-item acctmgmt-navitem cwpFocusVisible'
                }
                aria-current={this.state.currentSection === AccountManagementLinkRoutes.wifi}
              >
                {this.translator.t('acctmgmt_wifi_settings_heading')}
              </Link>
            </li>
          )}

          {this.props.isCanadian === false && (
            <li>
              <Link
                to={AccountManagementLinkRoutes.managealerts}
                className={
                  this.state.currentSection === AccountManagementLinkRoutes.managealerts
                    ? 'nav-item acctmgmt-navitem cwpFocusVisible active'
                    : 'nav-item acctmgmt-navitem cwpFocusVisible'
                }
                aria-current={this.state.currentSection === AccountManagementLinkRoutes.managealerts}
              >
                {this.translator.t('acctmgmt_alerts_heading')}
              </Link>
            </li>
          )}

          {/* Additional Drivers */}
          {this.props.tsp && this.props.tsp !== carnet_constants.TSP_VZT && userIsPrimary === true && (
            <li>
              <Link
                to={AccountManagementLinkRoutes.additionalDrivers}
                className={
                  this.state.currentSection === AccountManagementLinkRoutes.additionalDrivers
                    ? 'nav-item acctmgmt-navitem cwpFocusVisible active'
                    : 'nav-item acctmgmt-navitem cwpFocusVisible'
                }
                aria-current={this.state.currentSection === AccountManagementLinkRoutes.additionalDrivers}
              >
                {this.translator.t('acctmgmt_drivers_heading')}
              </Link>
            </li>
          )}

          {/* THIRD PARTY SERVICES  */}
          {this.props.tsp && this.props.tsp !== carnet_constants.TSP_VZT && (
            <li>
              <Link
                to={AccountManagementLinkRoutes.thirdparty}
                className={
                  this.state.currentSection === AccountManagementLinkRoutes.thirdparty
                    ? 'nav-item acctmgmt-navitem cwpFocusVisible active'
                    : 'nav-item acctmgmt-navitem cwpFocusVisible'
                }
                aria-current={this.state.currentSection === AccountManagementLinkRoutes.thirdparty}
              >
                {this.translator.t('acctmgmt_thirdparty_heading')}
              </Link>
            </li>
          )}
        </ul>
      </nav>
    );
  }
}

export default Leftnav;
