import React, { Component } from 'react';
import './vehicleHealth.scss';
import refreshImge from '../../../assets/vehicleHealth/refresh.png';
import { timeAndDateFormat } from '../../../util/i18n/localeUtils';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import BeatLoader from 'react-spinners/BeatLoader';
import { css } from '@emotion/core';
import axios from 'axios';
import Constants from '../../../constants/carnet_constants';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { setTitle } from '../../../providers/documentTitleProvider';

const beatloaderOverride = css`
  display: block;
  margin: auto;
  position: absolute;
  left: 15%;
  top: 160px;
  margin-bottom: 350px;
`;
class VehicleHealthHeader extends Component {
  constructor () {
    super();
    this.state = {
      loader: false,
      refreshRequestFail: false,
      showVZTRefreshModal: false
    };
    this.translator = getLocaleTranslator();
  }

  refreshVHR = () => {
    if (this.props.tspProvider === Constants.TSP_ATC) {
      this.setState({ loader: true, refreshRequestFail: false });
      axios
        .post(`vhr/refresh`)
        .then(res => {
          let response = res && res.data.data;
          console.log('ATC Response ::' + JSON.stringify(response));

          if (response && response.responseOutcome === 2) {
            this.setState({ loader: false });
            window.location.reload();
          } else if (response === undefined) {
            this.setState({ loader: false, refreshRequestFail: true });
          }
        })
        .catch(err => {
          console.log('ATC VHR Refresh Request Failed ::' + err);
        });
    } else {
      this.setState({ showVZTRefreshModal: true });
      axios
        .post(`vhr/vzt/refresh`)
        .then(res => {
          console.log('VZT Response::' + JSON.stringify(res.data.data));
        })
        .catch(err => {
          console.log('VZT VHR Refresh Request Failed ::' + err);
        });
    }
  };

  toggleVZTRefreshModal = () => {
    this.setState({ showVZTRefreshModal: !this.state.showVZTRefreshModal });
  };

  render () {
    setTitle(this.translator.t('document_titles.vehicle_health'));
    return (
      <div className='vhr-background'>
        <br />
        <div>
          <span className='refresh-div'>
            <button onClick={this.refreshVHR} className='refresh-text'>
              <img src={refreshImge} alt='Refresh' className='refresh-img' />
              &nbsp; {this.translator.t('vehicleHealth.header.updated_label')} &nbsp;
              {this.props.vhrTimeStamp && timeAndDateFormat(this.props.vhrTimeStamp)} -
              {this.translator.t('vehicleHealth.header.refresh_label')}
            </button>
          </span>
          {this.state.refreshRequestFail && (
            <div className='vhr-refresh-failed'>{this.translator.t('vehicleHealth.header.refresh_failed')}</div>
          )}

          <h1 className='vhr-header'>
            <span className='vhr-header-title1'>{this.translator.t('vehicleHealth.header.title_label1')}</span>
            <span className='vhr-header-title2'>{this.translator.t('vehicleHealth.header.title_label2')}</span>
          </h1>
        </div>
        {this.state.loader && <BeatLoader css={beatloaderOverride} sizeUnit={'px'} size={9} color={'#001e50'} />}

        <Modal isOpen={this.state.showVZTRefreshModal} toggle={this.toggleVZTRefreshModal} centered={true}>
          <ModalBody>
            <span className='rts-modal-exit' onClick={this.toggleVZTRefreshModal} />
            <p>{this.translator.t('vehicleHealth.vzt_refresh_msg')}</p>
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={this.toggleVZTRefreshModal}>
              {this.translator.t('trips.ok_label')}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default VehicleHealthHeader;
