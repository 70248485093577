import React from 'react';
import { BuyPlansContext } from '../BuyPlansContext';
import {
  ChamberlainSubCategoryList,
  SubCategory,
  findArticleDisclaimerText,
  isDescriptionForActivePTPorHotSpotUnlimitedPlan
} from '../buyPlansUtil';
import { Link } from 'react-router-dom';
import MarketingProducts from '../components/MarketingProducts';
import MyQLinkStatus from '../components/MyQLinkStatus';
import ModalUbiPaused from '../components/ModalUbiPaused';
import MyQNotifications from '../components/MyQNotifications';

export default class MarketingView extends React.Component {
  static contextType = BuyPlansContext;

  state = {
    hideDiscription: true,
    articleDisclaimer: null
  };

  componentDidMount () {
    this.initData();
  }

  initData = async () => {
    let plan = this.context?.selectedPlan;

    if (!plan) {
      const { category, subCategory, marketingConfigurationId } = this.props.match?.params || {};
      const { currentPlans, additionalPlans, selectPlanFromList } = this.context;

      plan = selectPlanFromList([...currentPlans, ...additionalPlans], category, subCategory, marketingConfigurationId);
    }

    const marketingConfiguration = this.context.marketingConfigurationHash?.[plan?.marketingConfigurationId];
    const articleDisclaimer = findArticleDisclaimerText(marketingConfiguration);
    const hideDiscription = isDescriptionForActivePTPorHotSpotUnlimitedPlan(plan, marketingConfiguration);

    this.setState({ hideDiscription, articleDisclaimer });
    window.scrollTo(0, 0);
  };

  render () {
    const {
      translator,
      contentURL,
      showDisclaimer,
      selectPlan,
      selectedPlan: plan,
      marketingConfigurationHash
    } = this.context;
    const marketingConfiguration = marketingConfigurationHash?.[plan?.marketingConfigurationId] || {};

    return (
      <div role='main' id='main-content' className='py-4'>
        <div className='ml-4 position-absolute'>
          <Link to='/' className='linkBack' role='button' onClick={() => selectPlan(null)}>
            {translator.t('buyplans.button-label.back-to-your-plans')}
          </Link>
        </div>
        <div className='container d-flex flex-column align-items-center text-center mt-4 pb-5'>
          <h1 className='font-weight-light'>
            {marketingConfiguration?.currentPlanContent?.title || marketingConfiguration?.title}
            {this.state.articleDisclaimer && (
              <sup
                className='cwp-disclaimer ml-2 mainTitlePosition'
                tabIndex={0}
                role='button'
                onClick={e =>
                  showDisclaimer(
                    this.state.articleDisclaimer,
                    marketingConfiguration.articleDisclaimerNumber,
                    e.currentTarget
                  )
                }
              >
                {marketingConfiguration?.articleDisclaimerNumber}
              </sup>
            )}
          </h1>
          <section className='text-muted text-center my-2'>
            {!this.state.hideDiscription && <article>{marketingConfiguration?.description}</article>}
            {plan?.subCategory === 'UBI' && Array.isArray(marketingConfiguration.disclaimerTextList) && (
              <article>{marketingConfiguration.disclaimerTextList.map(dt => dt.disclaimerText).join(' ')}</article>
            )}
            <article>{marketingConfiguration?.additionalDescription}</article>
          </section>
          {ChamberlainSubCategoryList.includes(marketingConfiguration?.subCategory?.toUpperCase()) && (
            <section className='d-flex flex-column'>
              <MyQLinkStatus translator={translator} plan={plan} />
              <MyQNotifications />
            </section>
          )}
        </div>

        {plan?.subCategory === SubCategory.UBI && <ModalUbiPaused translator={translator} plan={plan} />}

        {plan && (
          <MarketingProducts
            contentURL={contentURL}
            plan={plan}
            marketingConfiguration={marketingConfiguration}
            showDisclaimer={this.context.showDisclaimer}
          />
        )}
      </div>
    );
  }
}
