import React from 'react';
import { UncontrolledAlert, Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { dateAndTimeFormat } from '../../../util/i18n/localeUtils';
import HeaderPrePinAuth from '../garage/HeaderPrePinAuth';
import {
  findEnrollmentProcedure,
  TOS_TYPE,
  TOS_STATUS,
  ENROLLMENT_PROCEDURE
} from '../providers/SelfEnrollmentProvider';
import Spinner from '../Spinner';
import '../CarnetHome.scss';
import './selfEnrollment.scss';
import SearchVehicle from './SearchVehicle';
import TosCarnetCanada from './TosCarnetCanada';
// import TosUBI from './TosUBI';
// import TosConcierge from './TosConcierge';
import LandingPage from '../garage/LandingPage';
import defaultVehicleImage from '../../../assets/images/defaultVehicleImage.png';

class SelfEnrollmentCA extends React.Component {
  state = {
    spinnerMessage: null,
    processing: false,
    alert: null,
    data: null,
    applicableTos: null
  };

  constructor (props) {
    super(props);

    this.translator = getLocaleTranslator();
  }

  clearAlerts = () => this.setState({ alert: null });

  getAlerts = () => {
    if (this.state.alert) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts} aria-live={'polite'}>
          {this.state.alert}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  componentWillMount = () => {
    findEnrollmentProcedure()
      .then(data => {
        if (data && data.typesOfTOS) {
          this.setEnrollmentContext(data);
        }
      })
      .catch(error => {
        console.log('Error while init findEnrollmentProcedure - ' + error);
      });
  };

  componentDidMount = () => window.scrollTo(0, 0);

  setEnrollmentContext = data => {
    this.setState({ data, applicableTos: Array.from(new Set(data.typesOfTOS)) });
  };

  afterSubmit = (tosType, tosStatus, submitResponse) => {
    const applicableTos = new Set(this.state.applicableTos);

    if (tosType === TOS_TYPE.CARNET && tosStatus === TOS_STATUS.DECLINE) {
      applicableTos.clear();

      window.location.href = LandingPage.GARAGE;

      return;
    }

    applicableTos.delete(tosType);

    this.setState({ applicableTos: Array.from(applicableTos), [tosType]: submitResponse });
  };

  findNextStepComponent = () => {
    if (!this.state.applicableTos) {
      return <SearchVehicle onSuccess={this.setEnrollmentContext} />;
    }

    if (
      [
        ENROLLMENT_PROCEDURE.LOGIN_TO_VEHICLE,
        ENROLLMENT_PROCEDURE.NOT_ALLOWED_DUE_FLEET_OWNERSHIP,
        ENROLLMENT_PROCEDURE.THIS_USER_IS_ALREADY_ENROLLED
      ].includes(this.state.data.enrollmentProcedure)
    ) {
      const imgUrl = this.state.data.representativeImgURLComplete || defaultVehicleImage;
      const vehicleName = `${this.state.data.modelYear} ${this.state.data.modelName}`;

      return (
        <>
          <div className='enrollmentFullWidthLeft'>
            <span className='cwpBack' onClick={this.onBackClick}>
              {this.translator.t('button.back')}
            </span>
          </div>

          <h1 className='font-weight-normal mb-0'>
            {this.translator.t('self_enrollment.login_to_vehicle.your')}{' '}
            <strong>{this.translator.t('self_enrollment.login_to_vehicle.vehicle')}</strong>
          </h1>
          <div className='text-center'>
            <img src={imgUrl} alt={' '} width='300' />
            <br />
            <span className='text-left d-inline-block mx-auto mt-0 mb-5'>
              <h1 className='font-weight-normal'>{vehicleName}</h1>
              <span class='font-weight-bold'>
                {this.translator.t('self_enrollment.login_to_vehicle.vin')}: {this.state.data.vin}
              </span>
            </span>
            <br />
          </div>

          <div className='container'>
            <div className='row justify-content-md-center'>
              {this.state.data.enrollmentProcedure === ENROLLMENT_PROCEDURE.LOGIN_TO_VEHICLE && (
                <div className='col-md-8'>
                  <p class='text-justify font-weight-bold'>
                    {this.translator.t('self_enrollment.login_to_vehicle.p1')}
                  </p>
                  <p class='text-justify'>{this.translator.t('self_enrollment.login_to_vehicle.p2')}</p>
                  <p class='text-justify'>{this.translator.t('self_enrollment.login_to_vehicle.p3')}</p>
                  <p class='text-justify'>{this.translator.t('self_enrollment.login_to_vehicle.p4')}</p>
                </div>
              )}
              {this.state.data.enrollmentProcedure === ENROLLMENT_PROCEDURE.NOT_ALLOWED_DUE_FLEET_OWNERSHIP && (
                <div className='col-md-8 text-center text-danger' aria-live='polite'>
                  {this.translator.t('self_enrollment.not_allowed_due_fleet_ownership')}
                </div>
              )}
              {this.state.data.enrollmentProcedure === ENROLLMENT_PROCEDURE.THIS_USER_IS_ALREADY_ENROLLED && (
                <div className='col-md-8 text-center text-danger' aria-live='polite'>
                  <h3>{this.translator.t('self_enrollment.vin_already_enrolled')}</h3>
                  {this.translator.t('self_enrollment.not_allowed_due_already_enrolled')}
                </div>
              )}
            </div>
          </div>

          <button onClick={() => (window.location.href = LandingPage.GARAGE)} className='cwp mt-3 mb-5'>
            {this.translator.t('button.continue')}
          </button>
        </>
      );
    }

    if (this.state.data.enrollmentProcedure === ENROLLMENT_PROCEDURE.QR_SCAN) {
      return (
        <div className='card my-5 w-50 mx-auto' aria-live='polite'>
          <div className='card-header'>
            <h1>{this.translator.t('self_enrollment.qr_code.title')}</h1>
          </div>
          <div className='card-body'>
            <p className='col-12 col-lg-8 mx-auto'>
              {this.translator.t('self_enrollment.qr_code.info_line_1')}
              {this.translator.t('self_enrollment.qr_code.info_line_2')}
            </p>
            <br />
            <br />
            <button onClick={() => (window.location.href = LandingPage.GARAGE)} className='cwp'>
              {this.translator.t('button.okay')}
            </button>
          </div>
        </div>
      );
    }

    console.log('Finding next component from applicableTOS: ' + this.state.applicableTos);
    if (this.state.applicableTos.includes(TOS_TYPE.CARNET)) {
      return (
        <TosCarnetCanada
          enrollmentProcedure={this.state.data.enrollmentProcedure}
          vin={this.state.data.vin}
          imgURL={this.state.data.representativeImgURLComplete}
          modelYear={this.state.data.modelYear}
          modelName={this.state.data.modelName}
          tspProvider={this.state.data.tspProvider}
          afterSubmit={(tosStatus, response) => this.afterSubmit(TOS_TYPE.CARNET, tosStatus, response)}
          locale={this.props.locale}
        />
      );
    }

    /*
    // UBI (DriveView) not applicable to CANADA
    if (this.state.applicableTos.includes(TOS_TYPE.UBI)) {
      return <TosUBI afterSubmit={(tosStatus, response) => this.afterSubmit(TOS_TYPE.UBI, tosStatus, response)} />;
    }
    */

    /*
    // Vehicle Health Concierge not applicable to CANADA
    if (this.state.applicableTos.includes(TOS_TYPE.VEHICLE_HEALTH_CONCIERGE)) {
      return (
        <TosConcierge
          afterSubmit={(tosStatus, response) =>
            this.afterSubmit(TOS_TYPE.VEHICLE_HEALTH_CONCIERGE, tosStatus, response)
          }
        />
      );
    }
    */

    if (this.state.data.enrollmentProcedure === ENROLLMENT_PROCEDURE.REG_CODE) {
      const info = this.state[TOS_TYPE.CARNET];
      if (info && info.regCode) {
        const exp = new Date(0);
        exp.setTime(info.expiresAt);
        return (
          <Modal isOpen={true} className='carnet-sg-modal selfEnrollmentRegCodeModal'>
            <ModalHeader className='noBottomBorder'>
              {this.translator.t('self_enrollment.verify_ownership')}
            </ModalHeader>
            <ModalBody>
              <p>{this.translator.t(`self_enrollment.reg_code.${info.regCodeEntryChannel}`)}</p>
              <h5>{info.regCode}</h5>
              <span>
                {this.translator.t('self_enrollment.reg_code.expires_at')}: {dateAndTimeFormat(exp, null, true)}
              </span>
            </ModalBody>
            <ModalFooter className='noTopBorder'>
              <Button
                className='modal-primary-btn'
                color='modal_background_color'
                onClick={() => (window.location.href = LandingPage.GARAGE)}
              >
                {this.translator.t('button.okay')}
              </Button>
            </ModalFooter>
          </Modal>
        );
      }
    }

    if (this.state.data.enrollmentProcedure === ENROLLMENT_PROCEDURE.NKEY_FOB) {
      return (
        <div className='card my-5 w-50 mx-auto'>
          <div className='card-header'>
            <h5>{this.translator.t('self_enrollment.verify_ownership')}</h5>
          </div>
          <div className='card-body'>
            <span className='my-5 d-inline-block'>{this.translator.t('self_enrollment.nkey_fob.info_line_1')}</span>
            <button onClick={() => (window.location.href = LandingPage.GARAGE)} className='cwp'>
              {this.translator.t('button.ok')}
            </button>
          </div>
        </div>
      );
    }

    window.location.href = LandingPage.GARAGE;
  };

  render () {
    return (
      <>
        <div className='preVehicleAuth text-center selfEnrollment'>
          <HeaderPrePinAuth />
          <br />
          <br />
          <main id='main-content' role='main'>
            {this.getAlerts()}
            {this.findNextStepComponent()}
            <Spinner message={this.state.spinnerMessage} hidden={!this.state.processing} />
          </main>
        </div>
      </>
    );
  }
}

export default SelfEnrollmentCA;
