import { createLogic } from 'redux-logic';
import axios from 'axios';
import {
  GET_SIRISXM_SUBSCRIPTION_STATUS,
  GET_SIRISXM_SUBSCRIPTION_STATUS_SUCCESS,
  GET_SIRISXM_SUBSCRIPTION_STATUS_FAILUE
} from '../../actions/types';

let dataProvider = axios;

let requestConfig = {
  method: 'GET',
  url: `sirisxm/subscription/status`,
  headers: {
    accept: 'application/json'
  }
};

const getSirisxmSubscriptionStatusLogic = createLogic({
  type: GET_SIRISXM_SUBSCRIPTION_STATUS,
  latest: true,
  processOptions: {
    dispatchReturn: true,
    successType: GET_SIRISXM_SUBSCRIPTION_STATUS_SUCCESS,
    failType: GET_SIRISXM_SUBSCRIPTION_STATUS_FAILUE
  },

  process ({ action }) {
    console.log('getSirisxmSubscriptionStatus is processing an action type :' + action.type);

    return dataProvider(requestConfig)
      .then(response => {
        console.log(
          'got response for getSirisxmSubscriptionStatus GET request >>> ',
          JSON.stringify(response.data, null, 1)
        );
        return response.data;
      })
      .catch(error => {
        console.log('Error while processing getSirisxmSubscriptionStatus');
        console.log(JSON.stringify(error, null, 1));
      });
  }
});

export default [getSirisxmSubscriptionStatusLogic];
