import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import './tripStats.scss';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';

const DriveViewInfoModal = props => {
  const translator = getLocaleTranslator();

  return (
    <div>
      <Modal
        isOpen={props.aboutDriveViewModal}
        toggle={props.toggleAboutDriveViewModal}
        centered={true}
        className='rts-stops-modal'
      >
        <ModalBody>
          <div>
            <div className='rts-modal-exit' onClick={props.toggleAboutDriveViewModal} />
            <div className='about-driveview-score'>{translator.t('trips.about_driveview_score')}</div>
            <div className='driveview-modal-body'>
              <div className='driveview-modal-txt'>{translator.t('trips.eligibility_text')}</div>
              <div className='driveview-modal-txt'>{translator.t('trips.driveview_score_text')}</div>
              <div className='driveview-modal-txt'>{translator.t('trips.overall_score_text')}</div>
              <div className='driveview-modal-txt'>{translator.t('trips.daily_score_text')}</div>
              <div className='driveview-modal-txt'>{translator.t('trips.weekly_score_text')}</div>
              <div className='driveview-modal-txt'>{translator.t('trips.monthly_score_text')}</div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DriveViewInfoModal;
