import React from 'react';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import {
  isBlankString,
  isNonEmptyObject,
  extractDealerUrlByType,
  convertTime24ToTime12
} from '../../../util/utilityMethods';
import closeCircleIcon from '../../../assets/accountManagement/close-circle.png';
import { Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col } from 'reactstrap';
import '../../reusable_cmp_lib/ThemedFormInputs/ThemedForm.scss';
import '../acctMgmt/AccountManagement.scss';
import './DealerDetailModal.scss';
import '../CarnetHome.scss';
import { getDealerServiceUrl } from '../providers/ThirdPartyProvider';

class DealerDetailModal extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      dealerServiceUrlNotAvailable: false
    };
  }

  closeButtonClicked = () => {
    this.props.closeHandler();
  };

  getServiceUrlFromNits = async () => {
    let dealerId = this.props.contextDealer.id;
    try {
      let dealerServiceUrlInfo = await getDealerServiceUrl(dealerId, 'Web_ManageDealer');
      if (dealerServiceUrlInfo && dealerServiceUrlInfo.serviceURL) {
        window.open(dealerServiceUrlInfo.serviceURL);
      }
    } catch (err) {
      this.setState({ dealerServiceUrlNotAvailable: true });
    }
  };

  formatRawPhoneAsNAPhone = rawNumber => {
    if (isBlankString(rawNumber)) {
      return '';
    }
    if (rawNumber.length != 10) {
      return rawNumber;
    }
    let areaCode = rawNumber.substring(0, 3);
    let xchange = rawNumber.substring(3, 6);
    let suffix = rawNumber.substring(6, 10);
    return '(' + areaCode + ')' + xchange + '-' + suffix;
  };

  processAndSortHoursData = dayRecordsList => {
    const dayOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    dayRecordsList.sort((dayRecordA, dayRecordB) => {
      let indexA = dayOrder.indexOf(dayRecordA.day);
      let indexB = dayOrder.indexOf(dayRecordB.day);
      if (indexA < indexB) {
        return -1;
      } else if (indexA > indexB) {
        return 1;
      } else {
        return 0;
      }
    });
    dayRecordsList.forEach(dayRecord => {
      let abbrevDayName = dayRecord.day.substring(0, 3) + '.';
      dayRecord['day'] = abbrevDayName;
      if (dayRecord.isClosed === 'N') {
        let startTime12 = convertTime24ToTime12(dayRecord.start);
        let endTime12 = convertTime24ToTime12(dayRecord.end);
        dayRecord['start'] = startTime12;
        dayRecord['end'] = endTime12;
      }
    });
    return dayRecordsList;
  };

  render () {
    this.translator = getLocaleTranslator();
    const theDealer = this.props.contextDealer || {};
    let fmtPhone = '';
    if (isNonEmptyObject(theDealer) && theDealer.phone) {
      fmtPhone = this.formatRawPhoneAsNAPhone(theDealer.phone);
    }
    let dealersStateAndZipLine = '';
    if (!isBlankString(theDealer.state)) {
      dealersStateAndZipLine += ', ' + theDealer.state;
    }
    if (!isBlankString(theDealer.zip)) {
      dealersStateAndZipLine += '     ' + theDealer.zip;
    }
    let dlrServiceUrl = null;
    if (isNonEmptyObject(theDealer) && theDealer.dealerUrls) {
      dlrServiceUrl = extractDealerUrlByType(theDealer.dealerUrls, 'SSOURL');
      if (isBlankString(dlrServiceUrl)) {
        dlrServiceUrl = extractDealerUrlByType(theDealer.dealerUrls, 'SRVURL');
      }
    }
    let targetDeptForHours = null;
    let hoursData = [];
    if (theDealer.departments && theDealer.departments.length > 0) {
      let results = theDealer.departments.filter(dept => {
        return dept.departmentCode === 'SALES';
      });
      if (results && results.length > 0) {
        targetDeptForHours = results[0];
      } else {
        results = theDealer.departments.filter(dept => {
          return dept.departmentCode === 'SVC';
        });
        targetDeptForHours = results[0];
      }
    }
    if (isNonEmptyObject(targetDeptForHours)) {
      let hoursList = targetDeptForHours.hoursOfOperation || [];

      for (let day of hoursList) {
        let closedDay = day.closedIndicator;
        let hoursDatum = { day: day.name, isClosed: closedDay };
        if (closedDay === 'N') {
          let detailsArr = day.hours;
          if (detailsArr && detailsArr.length > 0) {
            let onlyRecord = detailsArr[0];
            hoursDatum['start'] = onlyRecord.openTime;
            hoursDatum['end'] = onlyRecord.closeTime;
          }
        }
        hoursData.push(hoursDatum);
      }
      hoursData = this.processAndSortHoursData(hoursData);
    }

    const dlrServicesList = theDealer.servicesOffered || [];

    return (
      <Modal isOpen={true} className='carnet-modal-design2' aria-labelledby='dealerDetailModalId'>
        <ModalHeader className='dealer-detail-modal-header' id='dealerDetailModalId'>
          <div className='modalTitleRow'>
            <div id='dealer-detail-modal-title'>
              <h2 class='h6'>{theDealer.name || ''}</h2>
            </div>
            <div
              id='dealer-detail-modal-x'
              className='dealerDetailCloseBtn'
              tabIndex='0'
              role='button'
              onClick={this.closeButtonClicked}
              onKeyPress={e => {
                if (e.key && (e.key === 'Enter' || e.key === ' ')) {
                  this.closeButtonClicked();
                }
              }}
            >
              <img
                src={closeCircleIcon}
                id='dlr-detail-close-btn-icon'
                alt={this.translator.t('acctmgmt_modal_close')}
              />
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row className='borderless'>
              <Col sm='6'>
                <div id='vertical-spacer-tiny'></div>
              </Col>
            </Row>
            <Row className='borderless'>
              <Col sm='6' className='records-table-rows'>
                <div className='dealer-address-text'>{theDealer.address1 || ''}</div>
                {!isBlankString(theDealer.address2) && <div className='dealer-address-text'>{theDealer.address2}</div>}
                <div className='dealer-address-text'>
                  {theDealer.city || ''}
                  {dealersStateAndZipLine}
                </div>
                <div></div>
                <div className='dealer-address-text'>{fmtPhone}</div>
              </Col>
              <Col sm='5' id='dealer-links-col' className='records-table-rows'>
                <div className='websiteLink'>
                  {!isBlankString(theDealer.url) && (
                    <a href={theDealer.url} target='_blank' rel='noopener noreferrer' className='dealerSite'>
                      → {this.translator.t('acctmgmt_visit_website')}
                    </a>
                  )}
                  <span onClick={this.getServiceUrlFromNits} role='button' className='service dealerSite'>
                    {this.translator.t('acctmgmt_schedule_service')}
                  </span>
                </div>
                {this.state.dealerServiceUrlNotAvailable && (
                  <div>
                    <span className='erroneous'>
                      {this.translator.t('vehicleHealth.schedule_appointment_label')}
                      {' ' + fmtPhone}
                    </span>
                  </div>
                )}

                <div class='vertical-spacer'></div>
                <div class='vertical-spacer'></div>

                {this.props.currentlySelected === false && (
                  <div id='inmodal-select-radio' className='dlr-result-selection-option'>
                    <span
                      role='button'
                      className='cwpRadio'
                      onClick={e => {
                        e.preventDefault();
                        this.props.sendbackPreferredHandler(theDealer);
                        this.props.closeHandler();
                      }}
                      aria-label={this.translator.t('acctmgmt_set_preferred_dealer_label')}
                    ></span>
                    <span>{this.translator.t('acctmgmt_set_preferred_dealer_label')}</span>
                  </div>
                )}
              </Col>
            </Row>
            {/* DISPLAY LOADED DATA  */}
            <Row className='borderless'>
              <Col sm='6'>
                {hoursData ? (
                  <div>
                    <span class='dealer-detail-hours-label'>{this.translator.t('acctmgmt_dlr_hours_label')}</span>
                    <div className='dlr-dtl-hours-table'>
                      {hoursData.map(record => {
                        return (
                          <ul className='dlr-dtl-hours-row'>
                            <li className='dlr-hours-col'>
                              <span>{record.day}</span>
                              {record.isClosed === 'Y' ? (
                                <span>{this.translator.t('acctmgmt_closed_uppercase')}</span>
                              ) : (
                                <span>{record.start + ' - ' + record.end}</span>
                              )}
                            </li>
                          </ul>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div>
                    <span id='dealer-detail-hours-label'>
                      {this.translator.t('acctmgmt_dlr_hours_label') +
                        ' ' +
                        this.translator.t('acctmgmt_unavailable_label')}
                    </span>
                  </div>
                )}
              </Col>
              <Col sm='5'>
                <div>
                  <span class='dealer-detail-hours-label'>{this.translator.t('acctmgmt_svcs_label')}</span>
                  <div class='dlr-dtl-hours-table'>
                    {dlrServicesList.map(record => {
                      if (record.description) {
                        return (
                          <ul class='dlr-dtl-hours-row'>
                            <li>{record.description || ''}</li>
                          </ul>
                        );
                      }
                    })}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    );
  } //render()
}

export default DealerDetailModal;
