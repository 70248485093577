import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../VehicleInfo.scss';
import { TosStatusOption } from '../../providers/GarageProvider';
import resource from '../../../../config/resource.json';

export default class PrivacyPolicy extends Component {
  constructor (props) {
    super(props);

    this.state = {
      tosStatus: null,
      checkboxEnabled: false
    };
  }

  static propTypes = {
    locale: PropTypes.string.isRequired,
    manifest: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    translator: PropTypes.object.isRequired,
    processing: PropTypes.bool,
    showAlert: PropTypes.func.isRequired
  };

  toggleTosAccept = () => {
    this.setState({
      tosStatus: !this.state.tosStatus ? TosStatusOption.ACCEPT : null
    });
  };

  onSubmit = () => {
    const { tosStatus } = this.state;

    if ([TosStatusOption.ACCEPT, TosStatusOption.DECLINE].includes(tosStatus)) {
      this.props.onSubmit(tosStatus);
    }
  };

  render () {
    const { translator, processing, locale } = this.props;
    const { checkboxEnabled } = this.state;

    const tos_url = resource?.tos_url;

    return (
      <>
        <section className='d-flex flex-column'>
          <h1 className='text-center font-weight-normal'>
            {translator.t('tos_PP.we-have-updated-our')}
            <strong>{translator.t('tos_PP.privacy-policy')}</strong>
          </h1>

          <div className='open-link'>{translator.t('tos_PP.open_link')}</div>

          <article className='d-flex-vw'>
            <input
              type='checkbox'
              id='radioTosAccept'
              name='scrollRadio'
              disabled={!checkboxEnabled}
              checked={TosStatusOption.ACCEPT === this.state.tosStatus}
              onClick={this.toggleTosAccept}
            />
            <label htmlFor='radioTosAccept' className='acceptlbl'>
              {translator.t('tos_PP.accept')}
              &nbsp;
              <a
                href={tos_url?.privacy_policy[locale]}
                target='_blank'
                onClick={() => this.setState({ checkboxEnabled: true })}
                rel='noreferrer'
              >
                {translator.t('tos_PP.accept-link')}
              </a>
            </label>
          </article>

          <button class='cwp center' onClick={this.onSubmit} disabled={!this.state.tosStatus || processing}>
            {translator.t('button.continue')}
          </button>
        </section>
      </>
    );
  }
}
