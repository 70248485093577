import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../redux/actions';
import carnet_constants from '../../../constants/carnet_constants';
import { Col, Row } from 'reactstrap';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { isNonEmptyObject, findIn } from '../../../util/utilityMethods';
import { getWifiConfiguration, toggleWifi } from '../providers/WifiConfigProvider';
import { getUserVehicleRolesAndRights } from '../providers/AccountAndSecurityProvider';
import '../../reusable_cmp_lib/ThemedButtonsAssortment/ThemedButtonsAssortment.scss';
import '../../reusable_cmp_lib/ThemedFormInputs/ThemedForm.scss';
import './AccountManagement.scss';
import '../CarnetHome.scss';

const WifiSettings = props => {
  const [isUpdating, setIsUpdating] = useState(true);
  const [vehicleWifiAvailable, setVehicleWifiAvailable] = useState(true);
  const [wifiEnabled, setWifiEnabled] = useState(null);
  const [mnoProvider, setMnoProvider] = useState(null);
  const [wifiConfigInfo, setWifiConfigInfo] = useState(null);
  const [planNames, setPlanNames] = useState([]);
  const SECURITY_PROTOCOL = 'WPA2_AES';

  const [wifiInErrorState, setErrorState] = useState(false);

  let translator = getLocaleTranslator();

  useEffect(() => {
    let mounted = true;

    let plansAreThere = props.storePlansInfo && Array.isArray(props.storePlansInfo.subscribedPlans);
    if (!plansAreThere) {
      // no plan info available, retrieve it
      props.actions.getStorePlansAndCartInfo();
    }

    const getWifiConfigInfo = async () => {
      let wifiCapable = false;
      let wifiSubscribed = false;
      //first, getUserVehicleRolesAndRights
      try {
        let uvRolesAndRights = await getUserVehicleRolesAndRights();
        if (isNonEmptyObject(uvRolesAndRights)) {
          console.log('Got the rolesnrights: ');
          console.log(JSON.stringify(uvRolesAndRights));
          let vehicleSvcsList = uvRolesAndRights.data.services || [];
          if (vehicleSvcsList.length === 0) {
            setIsUpdating(false);
            setErrorState(true);
            return;
          }

          let wifiSvc = vehicleSvcsList.filter(svc => {
            return svc.shortCode === 'WS';
          });
          if (!wifiSvc || wifiSvc.length === 0) {
            setIsUpdating(false);
            setVehicleWifiAvailable(false);
            return;
          }
          let matchingWifiSvc = wifiSvc[0];
          wifiCapable =
            matchingWifiSvc.operations &&
            matchingWifiSvc.operations[0].capabilityStatus &&
            matchingWifiSvc.operations[0].capabilityStatus === carnet_constants.RNR_STATUS_AVAILABLE;
          wifiSubscribed =
            matchingWifiSvc.operations &&
            matchingWifiSvc.operations[0].subscribedStatus &&
            matchingWifiSvc.operations[0].subscriptionStatus === carnet_constants.RNR_STATUS_AVAILABLE;
        } else {
          console.log('null response back from uvRolesAndRights: ');
          console.log(JSON.stringify(uvRolesAndRights));
          throw new Error('RNR_API_SERVICE_FAILURE');
        }
      } catch (err) {
        if (mounted) {
          setIsUpdating(false);
          setErrorState(true);
        }
      }
      if (!wifiCapable || !wifiSubscribed) {
        setIsUpdating(false);
        setVehicleWifiAvailable(false); //we must resiter the fact wifi is not avail
        return; ///So, we would exit out here, as the below Request becomes unnecessary
      }

      try {
        let wifiInfo = await getWifiConfiguration();
        wifiInfo = wifiInfo.data || wifiInfo;
        let isWifiEnabled = wifiInfo && wifiInfo.hotSpot && wifiInfo.hotSpot.isEnabled;
        if (mounted) {
          setWifiEnabled(isWifiEnabled);
          let theMnoProvider = props.quotedMno || '';
          setMnoProvider(theMnoProvider);
          let theWifiConfigInfo = (wifiInfo && wifiInfo.configuration) || {};
          setWifiConfigInfo(theWifiConfigInfo);
          setIsUpdating(false);
          setErrorState(false);
        }
      } catch (err) {
        if (mounted) {
          setIsUpdating(false);
          setErrorState(true);
        }
      }
    };

    getWifiConfigInfo();
    return function cleanup () {
      mounted = false;
    };
  }, [props.actions, props.quotedMno, props.storePlansInfo]);

  useEffect(() => {
    if (props.focus) {
      try {
        document.getElementById('firstActiveFocus').focus();
      } catch (err) {
        console.log('element not found for autofocus');
      }
    }
  }, [props.focus]);

  useEffect(() => {
    let mounted = true;
    let returnedNames = [];
    let plansAreThere = props.storePlansInfo && Array.isArray(props.storePlansInfo.subscribedPlans);
    if (plansAreThere) {
      props.storePlansInfo.subscribedPlans.forEach(planThing => {
        let legitStatus = planThing && String(planThing.status).toLowerCase() === 'active';
        let legitViewable = planThing && String(planThing.viewable).toLowerCase() === 'true';
        let name = legitStatus && legitViewable && planThing.package && planThing.package.name;
        let { marketingConfigurationId = '' } = planThing;
        let marketingDataThing = {};

        let marketingConfigurations =
          (props.marketingInfo &&
            props.marketingInfo.configurations &&
            Array.isArray(props.marketingInfo.configurations) &&
            props.marketingInfo.configurations) ||
          [];

        if (marketingConfigurationId) {
          marketingDataThing =
            marketingConfigurations.filter(
              marketingConfig => marketingConfig.configurationId === marketingConfigurationId
            )[0] || {};
        }
        if (marketingDataThing.title) {
          name = marketingDataThing.title;
        }
        if (name) {
          returnedNames.push(name);
        }
      });
    }
    if (mounted) {
      setPlanNames(returnedNames);
    }
    return function cleanup () {
      mounted = false;
    };
  }, [props.marketingInfo, props.storePlansInfo]);

  const toggleWifiOn = async e => {
    setIsUpdating(true);
    e.preventDefault();
    e.stopPropagation();
    let toggleResponse = await toggleWifi(true);
    toggleResponse = (toggleResponse && toggleResponse.data) || toggleResponse;
    let isSuccess = toggleResponse['result'] === 0;
    if (isSuccess) {
      setWifiEnabled(true);
    }
    setIsUpdating(false);
  };

  const toggleWifiOff = async e => {
    setIsUpdating(true);
    e.preventDefault();
    e.stopPropagation();
    let toggleResponse = await toggleWifi(false);
    toggleResponse = (toggleResponse && toggleResponse.data) || toggleResponse;
    let isSuccess = toggleResponse['result'] === 0;
    if (isSuccess) {
      setWifiEnabled(false);
    }
    setIsUpdating(false);
  };

  return (
    <div className='acctmgmt-contentpane'>
      <div id='ghost-wrapper' className='vehicle-info-parent'>
        <div className='contentpane-title'>
          <h2 id='firstActiveFocus' tabIndex={-1} className='h6'>
            {translator.t('acctmgmt_wifi_settings_heading')}
          </h2>
        </div>
        <div>
          {props.tsp && props.tsp === carnet_constants.TSP_ATC && (
            <Row>
              <Col md='3'>
                <span className='carnet-theme-field-label'>{translator.t('acctmgmt_in_vehicle_wifiacct')}</span>
              </Col>
              <Col md='1'></Col>
              <Col md='6'>
                {wifiInErrorState && (
                  <span className='carnet-theme-field-value erroneous'>
                    {translator.t('acctmgmt_wifi_get_settings_failure')}
                  </span>
                )}
                <span className='carnet-theme-field-value vi-data'>{props.quotedMno || mnoProvider || ''}</span>
                <br />

                {vehicleWifiAvailable === true && (
                  <span className='carnet-theme-field-value vi-data grayish'>
                    {(wifiConfigInfo && wifiConfigInfo.ssId) || ''}
                  </span>
                )}
                <br />
                {vehicleWifiAvailable === true && (
                  <span className='carnet-theme-field-value vi-data grayish'>
                    {(wifiConfigInfo && wifiConfigInfo.password) || ''}
                  </span>
                )}
                <br />

                <a href='#buy-plans'>
                  <span className='add-more-drivers-link paddedBottom'>
                    {translator.t('acctmgmt_buy_wifi_plan') + ' '}
                  </span>
                  <span className='add-more-drivers-link paddedBottom smaller'>→</span>
                </a>
                <br />
              </Col>
              <Col md='2'>
                {vehicleWifiAvailable === true && (
                  <div>
                    <button
                      disabled={isUpdating}
                      className='btn btn-link carnetLinkLight'
                      type='button'
                      onClick={e => props.editingCallback(carnet_constants.WIFI_HOTSPOT_SETTINGS)}
                    >
                      {translator.t('acctmgmt_pi_update_link_label')}
                    </button>
                  </div>
                )}
              </Col>
            </Row>
          )}

          {/* Wifi Hotspot  */}

          {props.tsp && props.tsp === carnet_constants.TSP_ATC && vehicleWifiAvailable === true && (
            <Row>
              <Col md='3'>
                <span className='carnet-theme-field-label'>{translator.t('acctmgmt_wifi_hotspot')}</span>
              </Col>
              <Col md='1'></Col>
              {wifiEnabled !== null && (
                <Col md='6'>
                  <span
                    disabled={isUpdating}
                    className={String(wifiEnabled) === 'true' ? 'cwpRadio cwpRadioOn' : 'cwpRadio'}
                    onClick={toggleWifiOn}
                  ></span>
                  <span className='carnet-theme-field-value radioSelection'>
                    {translator.t('acctmgmt_wifi_on_label')}
                  </span>
                  <br />
                  <span
                    disabled={isUpdating}
                    className={String(wifiEnabled) === 'false' ? 'cwpRadio cwpRadioOn' : 'cwpRadio'}
                    onClick={toggleWifiOff}
                  ></span>
                  <span className='carnet-theme-field-value radioSelection'>
                    {translator.t('acctmgmt_wifi_off_label')}
                  </span>
                  <br />
                </Col>
              )}
            </Row>
          )}

          {/* Security Type */}
          {props.tsp && props.tsp === carnet_constants.TSP_ATC && vehicleWifiAvailable === true && (
            <Row>
              <Col md='3'>
                <span className='carnet-theme-field-label'>{translator.t('acctmgmt_wifi_security_type')}</span>
              </Col>
              <Col md='1'></Col>
              <Col md='6'>
                <span className='carnet-theme-field-value vi-data'>{SECURITY_PROTOCOL}</span>
                <br />
              </Col>
            </Row>
          )}

          {/* Enrolled Plans  */}
          {props.tsp && props.tsp !== carnet_constants.TSP_VZT && (
            <Row className='borderless normalized'>
              <Col md='3'>
                <span className='carnet-theme-field-label'>{translator.t('acctmgmt_wifi_enrolled_plans')}</span>
              </Col>
              <Col md='1'></Col>
              <Col md='6'>
                <Col>
                  {planNames.map((planName, index) => {
                    return (
                      <>
                        <span key={planName + '_' + index} className='carnet-theme-field-value vi-data'>
                          {planName}
                        </span>
                        <br />
                      </>
                    );
                  })}
                </Col>
              </Col>
              <Col md='2'>
                <div>
                  <a href='#buy-plans' className='carnetLinkLight'>
                    {translator.t('acctmgmt_manage_plans')}
                  </a>
                </div>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getStorePlansAndCartInfo: actions.getStorePlansAndCartInfo
      },
      dispatch
    )
  };
};

const mapStateToProps = state => {
  const { storePlansCartInfo } = state;

  console.log('***** In WifiSettings Component ***** >> ');
  console.log(JSON.stringify(storePlansCartInfo));

  return {
    storePlansInfo: storePlansCartInfo.storePlansInfo,
    marketingInfo: storePlansCartInfo.marketingInfo
  };
};

export { WifiSettings };
export default connect(mapStateToProps, mapDispatchToProps)(WifiSettings);
