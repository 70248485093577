import React from 'react';
import './ubiPausedMsg.scss';

export default function UbiPausedMsg (props) {
  const { available = false, translator = {} } = props;

  if (available) {
    return (
      <section id='articleUbiPausedMsg'>
        <article className='container py-3 d-flex flex-column'>
          <h2 className='h6 font-weight-bold'>{translator.t('driveview.ubi_paused_msg.title')}</h2>
          <span>{translator.t('driveview.ubi_paused_msg.description')}</span>
        </article>
      </section>
    );
  }

  return <></>;
}
