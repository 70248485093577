import React from 'react';
import { getDriveViewEnrollmentPrefs, saveDriveViewEnrollmentPrefs } from '../providers/AccountAndSecurityProvider';
import { isUserPrimary } from '../../../providers/userRolesProvider';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { isNullOrEmptyObject } from '../../../util/utilityMethods';
import { Row, Col, Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import '../../reusable_cmp_lib/ThemedButtonsAssortment/ThemedButtonsAssortment.scss';
import Loader from '../common/loader';
import './AccountManagement.scss';

class ServicesEditor extends React.Component {
  constructor (props) {
    super(props);
    let pageContent = this.getPageContent();
    this.dataPropIndex = {
      '0': 'mrktgOffersHistDataOptInd',
      '1': 'mrktgOffersCurrDataOptInd',
      '2': 'ongoingDataOptInd',
      '3': 'modelingOptInd'
    };
    let startingCheckstates = [true, true, true, true];
    this.state = {
      serviceAddons: pageContent,
      threshholdWarnings: null,
      displayWarningsModal: false,
      displayFailedSaveModal: false,
      failureMsg: null,
      loadingData: true,
      liveCheckstates: startingCheckstates,
      unenrollCusp: false,
      unauthorizedAccess: false
    };
  }

  loadDriveViewPrefsData = () => {
    getDriveViewEnrollmentPrefs()
      .then(data => {
        console.log('Got a successful data response back from new getUbiEnrollmentPrefs(): ');
        console.log(JSON.stringify(data.response));
        if (isNullOrEmptyObject(data.response)) {
          console.log('Empty response, no data returned from endpoint, for ubipreferences');
          throw 'Empty response, no data returned from endpoint, for ubipreferences';
        }
        this.updateStateWithCurrentEnrollments(data.response);
      })
      .catch(err => {
        console.log('Failed to load the saved ubi prefs data:  ' + JSON.stringify(err));
        this.setState({
          loadingData: false
        });
      });
  };

  componentDidMount () {
    const isPrimaryDriver = isUserPrimary();
    let nonPrimary = isPrimaryDriver === false;
    this.setState({
      unauthorizedAccess: nonPrimary,
      loadingData: false
    });
    this.loadDriveViewPrefsData();
  }

  hideSaveFailureModal = () => {
    this.setState(
      {
        displayFailedSaveModal: false,
        failureMsg: null
      },
      this.navBackToPrevPage
    );
  };

  updateStateWithCurrentEnrollments = enrollmentCheckStates => {
    //a mutable deepClone. Avoids state-mutation.
    let checkstatesClone = JSON.parse(JSON.stringify(this.state.liveCheckstates));

    checkstatesClone.forEach((item, index) => {
      const checkstateLookupKey = this.dataPropIndex['' + index];
      let loadedCheckVal = enrollmentCheckStates[checkstateLookupKey];
      checkstatesClone[index] = loadedCheckVal;
    });

    this.setState({
      liveCheckstates: checkstatesClone,
      loadingData: false
    });
  };

  cancelUnenrollment = () => {
    this.setState({
      unenrollCusp: false
    });
  };

  commitPendingUncheck = commitFlag => {
    let action = commitFlag === true ? 'commit' : 'rollback';
    //This MUST be a DIRECT comparison against null!
    //Do Not convert to a Not-check or else value 0 will confuse it
    if (this.state.pendingUncheck === null) {
      return;
    }
    let allCheckStates = JSON.parse(JSON.stringify(this.state.liveCheckstates));
    //we must invert the boolean value from commitFlag, because What we are commiting is: an UN-check
    //in other words, if we should commit the UN-Check, then the value of .checked needs to be: false
    allCheckStates[this.state.pendingUncheck] = commitFlag === false;

    this.setState({
      displayWarningsModal: false,
      threshholdWarnings: null,
      pendingUncheck: null,
      liveCheckstates: allCheckStates
    });
  };

  displayWarningUnchecked = toUncheckIndex => {
    let presentCheckStates = JSON.parse(JSON.stringify(this.state.liveCheckstates));
    presentCheckStates[toUncheckIndex] = false; //uncheck it
    let nowUncheckedServices = this.state.serviceAddons.filter((item, index) => {
      return presentCheckStates[index] === false;
    });

    let warningRecords = nowUncheckedServices.map(item => {
      return {
        name: item.header,
        msg: item.caveat
      };
    });

    this.setState({
      threshholdWarnings: warningRecords,
      displayWarngingsModal: true,
      liveCheckstates: presentCheckStates,
      pendingUncheck: toUncheckIndex
    });
  };

  getPageContent = () => {
    this.translator = getLocaleTranslator();

    return [
      {
        id: 0,
        header: this.translator.t('acctmgmt_driveview_ins_msgs'),
        caveat: this.translator.t('acctmgmt_insmsgs_caveat_prose'),
        text: this.translator.t('acctmgmt_insmsgs_prose')
      },
      {
        id: 1,
        header: this.translator.t('acctmgmt_underwriting_label'),
        caveat: this.translator.t('acctmgmt_underwriting_caveat'),
        text: this.translator.t('acctmgmt_underwriting_dontgo')
      },
      {
        id: 2,
        header: this.translator.t('acctmgmt_monitoring_label'),
        caveat: this.translator.t('acctmgmt_monitoring_caveat'),
        text: this.translator.t('acctmgmt_monitoring_dontgo')
      },
      {
        id: 3,
        header: this.translator.t('acctmgmt_modelling_label'),
        caveat: this.translator.t('acctmgmt_modelling_caveat'),
        text: this.translator.t('acctmgmt_modelling_dontgo')
      }
    ];
  };

  unenrollUser = () => {
    this.setState({
      unenrollCusp: false
    });
  };

  displaySaveFailureModal = err => {
    const msg = JSON.stringify(err);
    this.setState({
      displayFailedSaveModal: true,
      failureMsg: msg
    });
  };

  saveCheckstates = () => {
    if (!this.state.serviceAddons || this.state.serviceAddons.length < 2) {
      return;
    }
    this.setState({
      loadingData: true
    });

    //The Consensus is: ubiOptInd must always be true here
    let finalCheckStates = {
      ubiOptInd: true
    };
    let deepClone = JSON.parse(JSON.stringify(this.state.liveCheckstates));
    deepClone.forEach((item, index) => {
      const checkVal = item;
      const checkKey = this.dataPropIndex[index + ''];
      finalCheckStates[checkKey] = checkVal;
    });
    let epochInstant = new Date().getTime();
    finalCheckStates['optTimestamp'] = epochInstant;
    saveDriveViewEnrollmentPrefs(finalCheckStates)
      .then(() => {
        console.log('Appears to be a successful SAVE of enrollment prefs!');
        this.setState(
          {
            loadingData: false
          },
          this.navBackToPrevPage
        );
      })
      .catch(err => {
        console.log('Appears to be a failure of the NEW pathway save.: ' + JSON.stringify(err));
        this.setState(
          {
            loadingData: false
          },
          this.displaySaveFailureModal(err)
        );
      });
  };

  isCuspingUnenroll = () => {
    let presentCheckStates = JSON.parse(JSON.stringify(this.state.liveCheckstates));
    let nowUnchecked = presentCheckStates.filter((item, index) => {
      return presentCheckStates[index] === false;
    });
    return nowUnchecked.length >= 3;
  };

  handleCheckBoxToggling = (arrayIndex, evt) => {
    let checkedState = evt.target.checked;
    if (checkedState === false) {
      if (this.isCuspingUnenroll()) {
        this.setIndexCheckboxTo(arrayIndex, false, true);
        return;
      } else {
        this.displayWarningUnchecked(arrayIndex);
        return;
      }
    }
    this.setIndexCheckboxTo(arrayIndex, checkedState);
  };

  navBackToPrevPage = () => {
    window.history.back();
  };

  setIndexCheckboxTo = (checkboxIndex, checkstate, wouldUnenroll = false) => {
    let allCheckStates = JSON.parse(JSON.stringify(this.state.liveCheckstates));
    allCheckStates[checkboxIndex] = checkstate;
    let nxState = {
      liveCheckstates: allCheckStates
    };

    if (wouldUnenroll === true) {
      nxState['unenrollCusp'] = true;
    }

    this.setState(nxState);
  };

  render () {
    this.translator = getLocaleTranslator();
    let currentCheckstates = this.state.liveCheckstates;

    return this.state.loadingData === true ? (
      <div id='third-party-svcs-editor'>
        <Loader />
      </div>
    ) : this.state.unauthorizedAccess === true ? (
      <div class='carnet-acctmgmt-parent'>
        <div id='third-party-svcs-editor'>
          <Row class='borderless'>
            <Col md='3'>
              <div>
                <div id='exit-editing-link' onClick={this.navBackToPrevPage}>
                  <a>
                    <span class='cwpBack editor-navback-arrow-text'>{this.translator.t('acctmgmt_back_label')}</span>
                  </a>
                </div>
              </div>
            </Col>
            <Col md='9'>
              <Modal isOpen={this.state.unauthorizedAccess === true} className='carnet-sg-modal'>
                <ModalHeader>{this.translator.t('acctmgmt_unauthorized')}</ModalHeader>
                <ModalBody>{this.translator.t('acctmgmt_user_not_authorized_msg')}</ModalBody>
                <ModalFooter>
                  <Button className='modal-primary-btn' color='modal_background_color' onClick={this.navBackToPrevPage}>
                    {this.translator.t('acctmgmt_back_label')}
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
        </div>
      </div>
    ) : (
      <div class='carnet-acctmgmt-parent'>
        <div id='third-party-svcs-editor'>
          <Row class='borderless'>
            <Col md='3'>
              <div>
                <div id='exit-editing-link' onClick={this.navBackToPrevPage}>
                  <a>
                    <span class='cwpBack editor-navback-arrow-text'>{this.translator.t('acctmgmt_back_label')}</span>
                  </a>
                </div>
              </div>
            </Col>
            <Col md='9'>
              <div>
                <div id='placeholder-page-title-aligner'>
                  <span>{this.translator.t('acctmgmt_pi_edit_link_label')}</span>
                </div>
              </div>
            </Col>
          </Row>
          <Row class='borderless'>
            <Col md='2'></Col>
            <Col md='10'>
              <div class='heading-aligner'>
                <span id='services-editor-heading'>{this.translator.t('acctmgmt_driveview_settings_label')}</span>
              </div>
            </Col>
          </Row>
          <Row class='borderless'>
            <Col md='2'></Col>
            <Col md='10'>
              <div id='services-editor-preamble'>{this.translator.t('acctmgmt_services3p_instrux')}</div>
            </Col>
          </Row>
          <Row>
            <Col md='2'></Col>
            <Col md='10'>
              <div id='driveview-hint'>{this.translator.t('acctmgmt_driveview_hint')}</div>
            </Col>
          </Row>

          {/* LOOP */}
          {(this.state.serviceAddons || []).map((service, index) => {
            if (index === 1 || index === 3) {
              return;
            }
            let oneAheadIndex = index + 1;
            let oneAhead =
              oneAheadIndex < this.state.serviceAddons.length ? this.state.serviceAddons[oneAheadIndex] : null;
            return (
              <Row className='borderless'>
                <Col md='2'></Col>
                <Col md='8'>
                  <Row>
                    <Col md='6'>
                      <div class='quad_cells_row'>
                        <br />
                        <label>
                          <input
                            type='checkbox'
                            name='createRegistration'
                            id={'service_addon_' + index}
                            onChange={evt => this.handleCheckBoxToggling(index, evt)}
                            value={currentCheckstates[index]}
                            checked={currentCheckstates[index]}
                          />
                          {service.header}
                        </label>
                        <br />
                        <span>{service.text}</span>
                      </div>
                    </Col>
                    {oneAhead && (
                      <Col md='6'>
                        <div class='quad_cells_row'>
                          <br />
                          <label>
                            <input
                              type='checkbox'
                              name='createRegistration'
                              id={'service_addon_' + oneAheadIndex}
                              onChange={evt => this.handleCheckBoxToggling(oneAheadIndex, evt)}
                              value={currentCheckstates[oneAheadIndex]}
                              checked={currentCheckstates[oneAheadIndex]}
                            />
                            {oneAhead.header}
                          </label>
                          <br />
                          <span>{oneAhead.text}</span>
                        </div>
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col md='1'></Col>
              </Row>
            );
          })}

          <Row>
            <Col md='12'>
              <div class='vertical-spacer-small'>
                {/* Toggleable Failure Modal   */}
                {this.state.displayFailedSaveModal === true && (
                  <Modal isOpen={this.state.displayFailedSaveModal} className='carnet-sg-modal'>
                    <ModalHeader>{this.translator.t('acctmgmt_api_failed_msg')}</ModalHeader>
                    <ModalBody>
                      <div>
                        {this.translator.t('acctmgmt_saveop_failed')}: {this.state.failureMsg || '(cause unknown)'}
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        className='modal-primary-btn'
                        color='modal_background_color'
                        onClick={this.hideSaveFailureModal}
                      >
                        {this.translator.t('button.ok')}
                      </Button>
                    </ModalFooter>
                  </Modal>
                )}

                {/* Toggleable display Warning Modal */}

                {this.state.threshholdWarnings && (
                  <Modal isOpen={this.state.displayWarngingsModal} className='carnet-sg-modal'>
                    <ModalHeader>{this.translator.t('acctmgmt_r_u_sure')}</ModalHeader>
                    <ModalBody>
                      <div>
                        {this.translator.t('acctmgmt_caveat_multi_unenroll')}
                        {(this.state.threshholdWarnings || []).map((warning, index) => {
                          let finalInList = index === this.state.threshholdWarnings.length - 1;
                          let lineItem = warning.name;
                          if (finalInList) {
                            lineItem = ' ' + this.translator.t('tos_carnet.and') + ' ' + lineItem;
                          } else {
                            lineItem += ', ';
                          }
                          return <span>{lineItem}</span>;
                        })}
                      </div>
                      <div>
                        <br />
                        {this.translator.t('acctmgmt_caveat_ubi_preamble')}
                        <ul>
                          {(this.state.threshholdWarnings || []).map(warning => {
                            return (
                              <li>
                                {' '}
                                {warning.name} : {warning.msg}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </ModalBody>
                    <ModalFooter className='design-deviation-footer'>
                      <Button
                        id='confirm-uncheck-ubi-service'
                        className='modal-primary-btn'
                        color='modal-background-color'
                        onClick={() => {
                          this.commitPendingUncheck(true);
                        }}
                      >
                        {this.translator.t('confirm_label')}
                      </Button>
                      <Button
                        id='cancel-uncheck-ubi-service'
                        className='modal-secondary-btn'
                        color='modal-text-color'
                        onClick={() => {
                          this.commitPendingUncheck(false); //clears warnings
                        }}
                      >
                        {this.translator.t('cancel_btn_camel')}
                      </Button>
                    </ModalFooter>
                  </Modal>
                )}

                {this.state.unenrollCusp && (
                  <Modal isOpen={this.state.unenrollCusp} className='carnet-sg-modal'>
                    <ModalHeader>{this.translator.t('acctmgmt_r_u_sure')}</ModalHeader>
                    <ModalBody>
                      <div>
                        {this.translator.t('acctmgmt_caveat_ubi_unenroll')}
                        <br />
                      </div>
                    </ModalBody>
                    <ModalFooter className='design-deviation-footer'>
                      <Button
                        id='confirm-unenroll-ubi-service'
                        className='modal-primary-btn'
                        color='modal-background-color'
                        onClick={this.unenrollUser}
                      >
                        {this.translator.t('unenroll_label')}
                      </Button>
                      <Button
                        id='cancel-unenroll-ubi-service'
                        className='modal-secondary-btn'
                        color='modal-text-color'
                        onClick={this.cancelUnenrollment}
                      >
                        {this.translator.t('cancel_btn_camel')}
                      </Button>
                    </ModalFooter>
                  </Modal>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md='12'>
              <div class='vertical-spacer-small'></div>
            </Col>
          </Row>
          <Row>
            <Col md='9'>
              <div class='vertical-spacer-small'></div>
            </Col>
            <Col md='2'>
              <Button className='carnet-theme-btn' color='#001e50' onClick={this.saveCheckstates}>
                {this.translator.t('save_changes_label')}
              </Button>
            </Col>
            <Col md='1'></Col>
          </Row>
        </div>
      </div>
    ); //outer JSX block wrap
  } //end of render fxn block
} //component

export default ServicesEditor;
