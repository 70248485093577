import {
  GET_RANDOM_DAD_JOKE,
  GET_RANDOM_DAD_JOKE_SUCCESS,
  GET_RANDOM_DAD_JOKE_FAILURE,
  GET_USER_INFO,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILURE,
  GET_CARNET_USER_PRIVILEGES,
  GET_CARNET_USER_PRIVILEGES_SUCCESS,
  GET_CARNET_USER_PRIVILEGES_FAILURE,
  GET_CAR_NICK_NAME,
  GET_CAR_NICK_NAME_SUCCESS,
  GET_CAR_NICK_NAME_FAILURE,
  GET_PREFERRED_DEALER,
  GET_PREFERRED_DEALER_SUCCESS,
  GET_PREFERRED_DEALER_FAILURE,
  GET_VEHICLE_HEALTH_BY_VEHICLEID,
  GET_VEHICLE_HEALTH_BY_VEHICLEID_SUCCESS,
  GET_VEHICLE_HEALTH_BY_VEHICLEID_FAILURE,
  REFRESH_VEHICLE_HEALTH,
  REFRESH_VEHICLE_HEALTH_SUCCESS,
  REFRESH_VEHICLE_HEALTH_FAILURE,
  GET_VEHICLE_HISTORY_INFO,
  GET_VEHICLE_HISTORY_INFO_SUCCESS,
  GET_VEHICLE_HISTORY_INFO_FAILURE,
  DELETE_VEHICLE_HISTORY_EVENT,
  DELETE_VEHICLE_HISTORY_EVENT_SUCCESS,
  DELETE_VEHICLE_HISTORY_EVENT_FAILURE,
  GET_CUSTOMER_ACCOUNT_DETAILS,
  GET_CUSTOMER_ACCOUNT_DETAILS_SUCCESS,
  GET_CUSTOMER_ACCOUNT_DETAILS_FAILURE,
  GET_USER_SECURITY_QUESTIONS,
  GET_USER_SECURITY_QUESTIONS_SUCCESS,
  GET_USER_SECURITY_QUESTIONS_FAILURE,
  PUT_USER_SECURITY_QUESTIONS,
  PUT_USER_SECURITY_QUESTIONS_SUCCESS,
  PUT_USER_SECURITY_QUESTIONS_FAILURE,
  GET_CARNET_CUSTOMER_STATIC_DATA,
  GET_CARNET_CUSTOMER_STATIC_DATA_SUCCESS,
  GET_CARNET_CUSTOMER_STATIC_DATA_FAILURE,
  GET_STORE_PLANS_AND_CART_INFO,
  GET_STORE_PLANS_AND_CART_INFO_SUCCESS,
  GET_STORE_PLANS_AND_CART_INFO_FAILURE,
  GET_DEALER_URLS,
  GET_DEALER_URLS_SUCCESS,
  GET_DEALER_URLS_FAILURE,
  GET_VEHICLE_HEALTH_BY_SERVICEKEY,
  GET_VEHICLE_HEALTH_BY_SERVICEKEY_SUCCESS,
  GET_VEHICLE_HEALTH_BY_SERVICEKEY_FAILURE,
  SEND_VHR_REPORT_BY_SERVICEKEY,
  SEND_VHR_REPORT_BY_SERVICEKEY_SUCCESS,
  SEND_VHR_REPORT_BY_SERVICEKEY_FAILURE,
  GET_VHR_CUSTOMER_PREFERENCES,
  GET_VHR_CUSTOMER_PREFERENCES_SUCCESS,
  GET_VHR_CUSTOMER_PREFERENCES_FAILURE,
  GET_SIRISXM_SUBSCRIPTION_STATUS,
  GET_SIRISXM_SUBSCRIPTION_STATUS_SUCCESS,
  GET_SIRISXM_SUBSCRIPTION_STATUS_FAILUE,
  UPDATE_CUSTOMER_DETAILS,
  UPDATE_CUSTOMER_DETAILS_SUCCESS,
  UPDATE_CUSTOMER_DETAILS_FAILURE,
  UPDATE_ACCT_AND_VEHICLE_PREFS,
  UPDATE_ACCT_AND_VEHICLE_PREFS_SUCCESS,
  UPDATE_ACCT_AND_VEHICLE_PREFS_FAILURE,
  UPDATE_VHR_CUSTOMER_PREFERENCES,
  UPDATE_VHR_CUSTOMER_PREFERENCES_SUCCESS,
  UPDATE_VHR_CUSTOMER_PREFERENCES_FAILURE,
  SET_USER_VEHICLE_CONTEXT,
  GET_STORE_PLAN_PRICING_INFO,
  GET_STORE_PLAN_PRICING_INFO_SUCCESS,
  GET_STORE_PLAN_PRICING_INFO_FAILURE,
  GET_STORE_CART_ITEMS,
  GET_STORE_CART_ITEMS_SUCCESS,
  GET_STORE_CART_ITEMS_FAILURE,
  GET_CURRENT_WALLET_DETAILS,
  GET_CURRENT_WALLET_DETAILS_SUCCESS,
  GET_CURRENT_WALLET_DETAILS_FAILURE,
  GET_MESSAGE_CENTER_INFO,
  GET_MESSAGE_CENTER_INFO_SUCCESS,
  GET_MESSAGE_CENTER_INFO_FAILURE,
  DELETE_MESSAGE_CENTER_EVENT,
  DELETE_MESSAGE_CENTER_EVENT_SUCCESS,
  DELETE_MESSAGE_CENTER_EVENT_FAILURE,
  DELETE_ALL_MESSAGE_CENTER_EVENT,
  DELETE_ALL_MESSAGE_CENTER_EVENT_SUCCESS,
  DELETE_ALL_MESSAGE_CENTER_EVENT_FAILURE,
  READ_MESSAGE_CENTER_EVENT,
  READ_MESSAGE_CENTER_EVENT_SUCCESS,
  READ_MESSAGE_CENTER_EVENT_FAILURE
} from './types';

export const getChuckNorrisRandomJoke = payload => {
  return {
    type: GET_RANDOM_DAD_JOKE,
    payload
  };
};

export const getChuckNorrisRandomJokeSuccess = payload => {
  return {
    type: GET_RANDOM_DAD_JOKE_SUCCESS,
    payload
  };
};

export const getChuckNorrisRandomJokeFailure = payload => {
  return {
    type: GET_RANDOM_DAD_JOKE_FAILURE,
    payload
  };
};

export const getUserInfo = payload => {
  return {
    type: GET_USER_INFO,
    payload
  };
};

export const getUserInfoSuccess = payload => {
  return {
    type: GET_USER_INFO_SUCCESS,
    payload
  };
};

export const getUserInfoFailure = payload => {
  return {
    type: GET_USER_INFO_FAILURE,
    payload
  };
};

//GET CARNET USER PRIVILEGES
export const getCarnetUserPrivileges = payload => {
  return {
    type: GET_CARNET_USER_PRIVILEGES,
    payload
  };
};

export const getCarnetUserPrivilegesSuccess = payload => {
  return {
    type: GET_CARNET_USER_PRIVILEGES_SUCCESS,
    payload
  };
};

export const getCarnetUserPrivilegesFailure = payload => {
  return {
    type: GET_CARNET_USER_PRIVILEGES_FAILURE,
    payload
  };
};

//GET CAR NICK NAME
export const getCarNickName = payload => {
  return {
    type: GET_CAR_NICK_NAME,
    payload
  };
};

export const getCarNickNameSuccess = payload => {
  return {
    type: GET_CAR_NICK_NAME_SUCCESS,
    payload
  };
};

export const getCarNickNameFailure = payload => {
  return {
    type: GET_CAR_NICK_NAME_FAILURE,
    payload
  };
};

//GET PREFERRED DEALER
export const getPreferredDealer = payload => {
  return {
    type: GET_PREFERRED_DEALER,
    payload
  };
};

export const getPreferredDealerSuccess = payload => {
  return {
    type: GET_PREFERRED_DEALER_SUCCESS,
    payload
  };
};

export const getPreferredDealerFailure = payload => {
  return {
    type: GET_PREFERRED_DEALER_FAILURE,
    payload
  };
};

//GET VEHICLE HEALTH BY VEHICLEID
export const getVehicleHealthByVehicleID = payload => {
  return {
    type: GET_VEHICLE_HEALTH_BY_VEHICLEID,
    payload
  };
};

export const getVehicleHealthByVehicleIDSuccess = payload => {
  return {
    type: GET_VEHICLE_HEALTH_BY_VEHICLEID_SUCCESS,
    payload
  };
};

export const getVehicleHealthByVehicleIDFailure = payload => {
  return {
    type: GET_VEHICLE_HEALTH_BY_VEHICLEID_FAILURE,
    payload
  };
};

//REFRESH VEHICLE HEALTH
export const refreshVehicleHealth = payload => {
  return {
    type: REFRESH_VEHICLE_HEALTH,
    payload
  };
};

export const refreshVehicleHealthSuccess = payload => {
  return {
    type: REFRESH_VEHICLE_HEALTH_SUCCESS,
    payload
  };
};

export const refreshVehicleHealthFailure = payload => {
  return {
    type: REFRESH_VEHICLE_HEALTH_FAILURE,
    payload
  };
};

// Start of Vehicle History Info

export const getVehicleHistoryInfo = payload => {
  return {
    type: GET_VEHICLE_HISTORY_INFO,
    payload
  };
};

export const getVehicleHistoryInfoSuccess = payload => {
  return {
    type: GET_VEHICLE_HISTORY_INFO_SUCCESS,
    payload
  };
};

export const getVehicleHistoryInfoFailure = payload => {
  return {
    type: GET_VEHICLE_HISTORY_INFO_FAILURE,
    payload
  };
};

export const deleteVehicleHistoryEvent = payload => {
  return {
    type: DELETE_VEHICLE_HISTORY_EVENT,
    payload
  };
};

export const deleteVehicleHistoryEventSuccess = payload => {
  return {
    type: DELETE_VEHICLE_HISTORY_EVENT_SUCCESS,
    payload
  };
};

export const deleteVehicleHistoryEventFailure = payload => {
  return {
    type: DELETE_VEHICLE_HISTORY_EVENT_FAILURE,
    payload
  };
};

//Get Customer Account Details
export const getCustomerAccountDetails = payload => {
  return {
    type: GET_CUSTOMER_ACCOUNT_DETAILS,
    payload
  };
};

export const getCustomerAccountDetailsSuccess = payload => {
  return {
    type: GET_CUSTOMER_ACCOUNT_DETAILS_SUCCESS,
    payload
  };
};

export const getCustomerAccountDetailsFailure = payload => {
  return {
    type: GET_CUSTOMER_ACCOUNT_DETAILS_FAILURE,
    payload
  };
};

export const getUserSecurityQuestions = payload => {
  return {
    type: GET_USER_SECURITY_QUESTIONS,
    payload
  };
};

export const getUserSecurityQuestionsSuccess = payload => {
  return {
    type: GET_USER_SECURITY_QUESTIONS_SUCCESS,
    payload
  };
};

export const getUserSecurityQuestionsFailure = payload => {
  return {
    type: GET_USER_SECURITY_QUESTIONS_FAILURE,
    payload
  };
};

//Save Users Security Questions
export const saveUserSecurityQuestions = payload => {
  return {
    type: PUT_USER_SECURITY_QUESTIONS,
    payload
  };
};

export const saveUserSecurityQuestionsSuccess = payload => {
  return {
    type: PUT_USER_SECURITY_QUESTIONS_SUCCESS,
    payload
  };
};

export const saveUserSecurityQuestionsFailure = payload => {
  return {
    type: PUT_USER_SECURITY_QUESTIONS_FAILURE,
    payload
  };
};

//Save Users Security Questions
export const saveAcctAndVehiclePrefs = payload => {
  return {
    type: UPDATE_ACCT_AND_VEHICLE_PREFS,
    payload
  };
};

export const saveAcctAndVehiclePrefsSuccess = payload => {
  return {
    type: UPDATE_ACCT_AND_VEHICLE_PREFS_SUCCESS,
    payload
  };
};

export const saveAcctAndVehiclePrefsFailure = payload => {
  return {
    type: UPDATE_ACCT_AND_VEHICLE_PREFS_FAILURE,
    payload
  };
};

//Get Carnet Customer Static Data (States, Countries, Secuirty Questions)
export const getCarnetCustomerStaticData = payload => {
  return {
    type: GET_CARNET_CUSTOMER_STATIC_DATA,
    payload
  };
};

// End of Vehicle History Info

// Buy Plans

export const getStorePlansAndCartInfo = payload => {
  return {
    type: GET_STORE_PLANS_AND_CART_INFO,
    payload
  };
};

export const getBuyPlansLandingPageSuccess = payload => {
  return {
    type: GET_STORE_PLANS_AND_CART_INFO_SUCCESS,
    payload
  };
};

export const getBuyPlansLandingPageFailure = payload => {
  return {
    type: GET_STORE_PLANS_AND_CART_INFO_FAILURE,
    payload
  };
};

export const getCarnetCustomerStaticDataSuccess = payload => {
  return {
    type: GET_CARNET_CUSTOMER_STATIC_DATA_SUCCESS,
    payload
  };
};

export const getCarnetCustomerStaticDataFailure = payload => {
  return {
    type: GET_CARNET_CUSTOMER_STATIC_DATA_FAILURE,
    payload
  };
};

//GET DEALER URL's
export const getDealerUrls = payload => {
  return {
    type: GET_DEALER_URLS,
    payload
  };
};

export const getDealerUrlsSuccess = payload => {
  return {
    type: GET_DEALER_URLS_SUCCESS,
    payload
  };
};

export const getDealerUrlsFailure = payload => {
  return {
    type: GET_DEALER_URLS_FAILURE,
    payload
  };
};

//Get Vehicle Health by ServiceKey
export const getVehicleHealthByServiceKey = payload => {
  console.log('getVehicleHealthByServiceKey payload is ::' + payload);
  return {
    type: GET_VEHICLE_HEALTH_BY_SERVICEKEY,
    payload
  };
};

export const getVehicleHealthByServiceKeySuccess = payload => {
  return {
    type: GET_VEHICLE_HEALTH_BY_SERVICEKEY_SUCCESS,
    payload
  };
};

export const getVehicleHealthByServiceKeyFailure = payload => {
  return {
    type: GET_VEHICLE_HEALTH_BY_SERVICEKEY_FAILURE,
    payload
  };
};

//Send VHR Report
export const sendVHRReportByServiceKey = payload => {
  console.log('sendVHRReportByServiceKey payload is ::' + payload);
  return {
    type: SEND_VHR_REPORT_BY_SERVICEKEY,
    payload
  };
};

export const sendVHRReportByServiceKeySuccess = payload => {
  return {
    type: SEND_VHR_REPORT_BY_SERVICEKEY_SUCCESS,
    payload
  };
};

export const sendVHRReportByServiceKeyFailure = payload => {
  return {
    type: SEND_VHR_REPORT_BY_SERVICEKEY_FAILURE,
    payload
  };
};

//Get VHR Customer Preferences
export const getVHRCustomerPreferences = payload => {
  return {
    type: GET_VHR_CUSTOMER_PREFERENCES,
    payload
  };
};

export const getVHRCustomerPreferencesSuccess = payload => {
  return {
    type: GET_VHR_CUSTOMER_PREFERENCES_SUCCESS,
    payload
  };
};

export const getVHRCustomerPreferencesFailure = payload => {
  return {
    type: GET_VHR_CUSTOMER_PREFERENCES_FAILURE,
    payload
  };
};

///Get SirisXM Subscription Status
export const getSirisxmSubscriptionStatus = payload => {
  return {
    type: GET_SIRISXM_SUBSCRIPTION_STATUS,
    payload
  };
};

export const getSirisxmSubscriptionStatusSuccess = payload => {
  return {
    type: GET_SIRISXM_SUBSCRIPTION_STATUS_SUCCESS,
    payload
  };
};

export const getSirisxmSubscriptionStatusFailure = payload => {
  return {
    type: GET_SIRISXM_SUBSCRIPTION_STATUS_FAILUE,
    payload
  };
};

//Update Customer Details
export const updateCustomerDetails = payload => {
  return {
    type: UPDATE_CUSTOMER_DETAILS,
    payload
  };
};

export const updateCustomerDetailsSuccess = payload => {
  return {
    type: UPDATE_CUSTOMER_DETAILS_SUCCESS,
    payload
  };
};

export const updateCustomerDetailsFailure = payload => {
  return {
    type: UPDATE_CUSTOMER_DETAILS_FAILURE,
    payload
  };
};

//Update VHR Customer Preferences
export const updateVHRCustomerPreferences = payload => {
  console.log('Calling updateVHRCustomerPreferences' + JSON.stringify(payload));
  return {
    type: UPDATE_VHR_CUSTOMER_PREFERENCES,
    payload
  };
};
export const updateVHRCustomerPreferencesSuccess = payload => {
  return {
    type: UPDATE_VHR_CUSTOMER_PREFERENCES_SUCCESS,
    payload
  };
};
export const updateVHRCustomerPreferencesFailure = payload => {
  return {
    type: UPDATE_VHR_CUSTOMER_PREFERENCES_FAILURE,
    payload
  };
};

export const setUserVehicleContext = payload => {
  return {
    type: SET_USER_VEHICLE_CONTEXT,
    payload
  };
};

export const getStorePlanPricingInfo = payload => {
  return {
    type: GET_STORE_PLAN_PRICING_INFO,
    payload
  };
};

export const getStorePlanPricingInfoSuccess = payload => {
  return {
    type: GET_STORE_PLAN_PRICING_INFO_SUCCESS,
    payload
  };
};

export const getStorePlanPricingInfoFailure = payload => {
  return {
    type: GET_STORE_PLAN_PRICING_INFO_FAILURE,
    payload
  };
};

export const getStoreCartItems = payload => {
  return {
    type: GET_STORE_CART_ITEMS,
    payload
  };
};

export const getStoreCartItemsSuccess = payload => {
  return {
    type: GET_STORE_CART_ITEMS_SUCCESS,
    payload
  };
};

export const getCartItemsFailure = payload => {
  return {
    type: GET_STORE_CART_ITEMS_FAILURE,
    payload
  };
};

export const getCurrentWalletDetails = payload => {
  return {
    type: GET_CURRENT_WALLET_DETAILS,
    payload
  };
};

export const getCurrentWalletDetailsSuccess = payload => {
  return {
    type: GET_CURRENT_WALLET_DETAILS_SUCCESS,
    payload
  };
};

export const getCurrentWalletDetailsFailure = payload => {
  return {
    type: GET_CURRENT_WALLET_DETAILS_FAILURE,
    payload
  };
};

export const getmessageCenterInfo = payload => {
  console.log('message value is ::' + payload);
  return {
    type: GET_MESSAGE_CENTER_INFO,
    payload
  };
};

export const getmessageCenterInfoSuccess = payload => {
  return {
    type: GET_MESSAGE_CENTER_INFO_SUCCESS,
    payload
  };
};

export const getmessageCenterInfoFailure = payload => {
  return {
    type: GET_MESSAGE_CENTER_INFO_FAILURE,
    payload
  };
};
//END

export const deleteMessageCenterEvent = payload => {
  console.log('selectedmessagecenter value is ::' + payload);
  return {
    type: DELETE_MESSAGE_CENTER_EVENT,
    payload
  };
};

export const deleteMessageCenterEventSuccess = payload => {
  return {
    type: DELETE_MESSAGE_CENTER_EVENT_SUCCESS,
    payload
  };
};

export const deleteMessageCenterEventFailure = payload => {
  return {
    type: DELETE_MESSAGE_CENTER_EVENT_FAILURE,
    payload
  };
};

export const deleteAllMessageCenterEvent = payload => {
  console.log('selectedmessagecenterall value is ::' + payload);
  return {
    type: DELETE_ALL_MESSAGE_CENTER_EVENT,
    payload
  };
};

export const deleteAllMessageCenterEventSuccess = payload => {
  return {
    type: DELETE_ALL_MESSAGE_CENTER_EVENT_SUCCESS,
    payload
  };
};

export const deleteAllMessageCenterEventFailure = payload => {
  return {
    type: DELETE_ALL_MESSAGE_CENTER_EVENT_FAILURE,
    payload
  };
};

export const readMessageCenterEvent = payload => {
  console.log('selectedmessagecenter value is ::' + payload);
  return {
    type: READ_MESSAGE_CENTER_EVENT,
    payload
  };
};

export const readMessageCenterEventSuccess = payload => {
  return {
    type: READ_MESSAGE_CENTER_EVENT_SUCCESS,
    payload
  };
};

export const readMessageCenterEventFailure = payload => {
  return {
    type: READ_MESSAGE_CENTER_EVENT_FAILURE,
    payload
  };
};
