import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { findIn } from '../../../util/utilityMethods';
import { digitsOnly } from '../CarnetHome';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { TSP_PROVIDER } from '../providers/SelfEnrollmentProvider';

const Capability = Object.freeze({
  STD_SERVICES: 'STD_SERVICES',
  EV_SERVICES: 'EV_SERVICES',
  RDT: 'RDT',
  WINDOWHEATING: 'WINDOWHEATING'
});

const Status = Object.freeze({
  ENABLE: 'ENABLE',
  DISABLE: 'DISABLE'
});

const translator = getLocaleTranslator();

const SubscribedFetaureVzt = props => {
  const capabilityStatus = findIn('userVehicleContext.capabilityStatus', props);

  const vztSubscriptionAvailable =
    capabilityStatus &&
    Array.isArray(props.enableForAny) &&
    props.enableForAny.some(name => capabilityStatus[name] === Status.ENABLE);

  const tspProvider = findIn('userVehicleContext.tspProvider', props) || '';
  const nonVztVehicle = tspProvider.toUpperCase() !== TSP_PROVIDER.VZT;

  if (vztSubscriptionAvailable || nonVztVehicle) {
    return React.cloneElement(props.children, { tspProvider: tspProvider.toUpperCase() });
  }

  return (
    <div className='bg-light'>
      <div className='alert alert-warning text-center' role='alert'>
        {translator.t('subscribed-fetaure-vzt.standard-feature-disabled')}&nbsp;
        <a className='carnetLink' href={`tel:${digitsOnly(translator.t('subscribed-fetaure-vzt.call-center-number'))}`}>
          {translator.t('subscribed-fetaure-vzt.call-center-number')}
        </a>
      </div>
      <div className='cwpPointerEventsNone pb-3 opacity-1 cwpContentHeightMin'>
        {React.Children.map(props.children, child => React.cloneElement(child, { subscriptionNotAvailable: true }))}
      </div>
    </div>
  );
};

SubscribedFetaureVzt.propTypes = {
  enableForAny: PropTypes.array,
  userVehicleContext: PropTypes.object
};

const mapStateToProps = state => {
  const { userVehicleContext } = state;

  return { ...userVehicleContext };
};

export default connect(mapStateToProps, null)(SubscribedFetaureVzt);

export { Capability };
