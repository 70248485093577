import Axios from 'axios';
import getCwpMeta from './cwpMetaProvider';

const TOS_MANIFEST_ERROR = 'GET__TOS_MANIFEST__FAILURE';

/**
 * Provider to provide a path to various things TOS-related
 */
const getTosDocUri = async (url, locale) => {
  // not an extraneous xhr as reponse is already cached
  let meta = await getCwpMeta();
  let basePathUrl = meta && meta.static && meta.static.content.urls.baseUrl;

  let staticPath = meta && meta.static && meta.static.content.urls.securecontent;
  let needSecureContentInBaseUrl = !url.includes('securecontent');
  let baseUrlForTos = needSecureContentInBaseUrl ? staticPath : basePathUrl;
  let finalUrl = `${baseUrlForTos}${url}`;
  if (locale) {
    let loweredLocale = String(locale).toLowerCase();
    if (loweredLocale === 'fr-ca' && finalUrl.includes('en-CA')) {
      finalUrl = finalUrl.replace(/en\-CA/g, 'fr-CA');
    }
  }
  if (locale) {
    let loweredLocale2 = String(locale).toLowerCase();
    if (loweredLocale2 === 'en-ca' && finalUrl.includes('fr-CA')) {
      finalUrl = finalUrl.replace(/fr\-CA/g, 'en-CA');
    }
  }
  // console.log('finalUrl ', finalUrl);
  return finalUrl;
};

/**
 * Helper extract tos of a given type from list
 */
const getTosDocById = (tosList, id) => {
  return tosList.filter(tosItem => tosItem.id === id)[0] || null;
};

const getTosManifest = async locale => {
  let tosManifestResponse = null;
  let response;
  let requestObject = { url: 'cwpmeta/tosmanifest', method: 'GET' };
  if (locale) {
    requestObject.headers = { 'x-user-locale': locale };
  }
  try {
    tosManifestResponse = await Axios(requestObject);
    response = (tosManifestResponse && tosManifestResponse.data) || {};
  } catch (err) {
    console.log('unable to retrieve tos manifest');
    throw new Error(TOS_MANIFEST_ERROR);
  }
  return response;
};

export { getTosManifest, getTosDocUri, getTosDocById };
