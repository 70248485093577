import React from 'react';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';

const VehicleHealthReport = props => {
  let translator = getLocaleTranslator();

  const msgTextIndent = msgText => {
    if (msgText) {
      return msgText.split('\n').map(str => <p>{str}</p>);
    }
  };

  return (
    <div>
      {Array.isArray(props.applicableCategories) ? (
        props.applicableCategories.map(applicableCategory => (
          <div>
            <div
              className={`vhrRow vhr-details-img priorityCode${applicableCategory.categoryPriorityCode}`}
              aria-label={translator.t(
                `vehicleHealth.category_priority_${applicableCategory.categoryPriorityCode}_desc`
              )}
              title={translator.t(`vehicleHealth.category_priority_${applicableCategory.categoryPriorityCode}_desc`)}
            >
              <span className='category-desc'>{applicableCategory.categoryDesc}</span>
            </div>
            {Array.isArray(applicableCategory.diagnosticMessages) &&
              applicableCategory.diagnosticMessages.map((diagnosticMessage, i) => (
                <div>
                  <div
                    className={
                      i === applicableCategory.diagnosticMessages.length - 1
                        ? `vhrRowDetails noBorder vhr-details-img priorityCode${diagnosticMessage.priorityCode}`
                        : `vhrRowDetails vhr-details-img priorityCode${diagnosticMessage.priorityCode}`
                    }
                  >
                    <span className='subcategory-desc'>{diagnosticMessage.msgSubCategoryDesc}</span>
                    <br />
                    <div className='diagnostic-desc'>{msgTextIndent(diagnosticMessage.msgText)}</div>
                  </div>
                </div>
              ))}
            <div className='vhrRowDetails'></div>
          </div>
        ))
      ) : (
        <div className='category-desc'>{translator.t('vehicleHealth.error_text')}</div>
      )}
    </div>
  );
};

export default VehicleHealthReport;
