import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { isAvailableUbiPausedMessage } from '../../providers/FeatureAssessmentProvider';
import { findErrorMessage } from '../../../../util/i18n/i18nService';

export default class ModalUbiPaused extends React.Component {
  static propTypes = {
    plan: PropTypes.object.isRequired
  };

  state = {
    showModal: false
  };

  componentDidMount () {
    this.initData();
  }

  initData = async () => {
    try {
      const showUbiPausedMessage = await isAvailableUbiPausedMessage();
      this.setState({ showModal: showUbiPausedMessage });
    } catch (err) {
      this.setState({ alert: findErrorMessage(err) });
    }
  };

  onClickClose = () => {
    this.setState({ showModal: false });
  };

  render () {
    const { translator } = this.props;

    return (
      <Modal className='carnet-sg-modal' isOpen={this.state.showModal} onHide={this.onClickClose} centered={true}>
        <ModalHeader>{translator.t('buyplans.ubi_paused_msg.title')}</ModalHeader>
        <ModalBody>{translator.t('buyplans.ubi_paused_msg.description')}</ModalBody>
        <ModalFooter>
          <button className='modal-primary-btn' onClick={this.onClickClose}>
            {translator.t('button.okay')}
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}
