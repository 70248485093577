import React, { Component } from 'react';
import './Footer.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clearVehicleLocaleContext } from '../../../providers/languageProvider';
import { clearHistory } from '../../../providers/historyProvider';
import rightArrow from '../../../assets/images/rightArrow.svg';
import '../CarnetHome.scss';
import defaultVehicleImage from '../../../assets/images/defaultImg.png';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';

class Feature extends Component {
  render () {
    let translator = getLocaleTranslator();
    let mdname = this.props.userVehicleContext.modelName;
    let garageSize = this.props.userVehicleContext.garageSize;
    if (this.props.userVehicleContext.vin)
      if (garageSize == '1') {
        return (
          <div className='model'>
            <div className='row'>
              <div className='col'>
                <div className='centeralign'>
                  <div className='cwp-header-post-login'>
                    <br />
                    <br />
                    <br />
                    <hr aria-hidden='true' className='mt-0' />
                    <span id='vwlogo-post-login' />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <div className='leftalign'>
                  <span>
                    <img
                      src={this.props.userVehicleContext.representativeImgURLComplete || defaultVehicleImage}
                      alt=''
                      className={
                        this.props.userVehicleContext.representativeImgURLComplete
                          ? 'img-responsive-logo'
                          : 'img-responsive'
                      }
                    />
                  </span>

                  {this.props.locale === 'fr-ca' ? (
                    <>
                      <span className='modelnm2'>{this.props.userVehicleContext.nickName}</span>
                      <span className={this.props.userVehicleContext.nickName ? 'modelnmtext3' : 'modelnm3'}>
                        {mdname}
                      </span>
                      <span className='modelYr'>{this.props.userVehicleContext.modelYear}</span>
                    </>
                  ) : (
                    <>
                      <span className='modelYr'>{this.props.userVehicleContext.modelYear}</span>
                      <span className='modelnm'>{this.props.userVehicleContext.nickName}</span>
                      <span
                        className={
                          this.props.userVehicleContext.nickName
                            ? 'modelnmtext cwpFocusVisible '
                            : 'modelnm cwpFocusVisible'
                        }
                      >
                        {mdname}
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className='col'>
                <div className='righttalign'>
                  <span
                    className='add cwpFocusVisible px-2'
                    onClick={clickSwitchVehicle}
                    tabIndex={0}
                    role='button'
                    onKeyPress={e => {
                      if (e.key && (/enter/gi.test(e.key.toString()) || e.key === ' ')) {
                        clickSwitchVehicle(e);
                      } else {
                        e.preventDefault();
                      }
                    }}
                  >
                    <span>{translator.t('garage.add_vehicle')}</span>
                  </span>
                  <span className='rightArrow cwpCursorPointer' onClick={clickSwitchVehicle}>
                    <img src={rightArrow} width='22' alt='' />
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className='model'>
            <div className='row'>
              <div className='col'>
                <div className='centeralign'>
                  <header className='cwp-header-post-login'>
                    <br />
                    <br />
                    <br />
                    <hr aria-hidden='true' className='mt-0' />
                    <span id='vwlogo-post-login' />
                  </header>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <div className='leftalign'>
                  <span>
                    <img
                      src={this.props.userVehicleContext.representativeImgURLComplete || defaultVehicleImage}
                      alt=''
                      className={
                        this.props.userVehicleContext.representativeImgURLComplete
                          ? 'img-responsive-logo'
                          : 'img-responsive'
                      }
                    />
                  </span>

                  {this.props.locale === 'fr-ca' ? (
                    <>
                      <span className='modelnm'>{this.props.userVehicleContext.nickName}</span>
                      <span className={this.props.userVehicleContext.nickName ? 'modelnmtext' : 'modelnm'}>
                        {mdname}
                      </span>
                      <span className='modelYr'>{this.props.userVehicleContext.modelYear}</span>
                    </>
                  ) : (
                    <>
                      <span className='modelYr cwpFocusVisible' tabIndex={0} role='button'>
                        {this.props.userVehicleContext.modelYear}
                      </span>
                      {this.props.userVehicleContext.nickName && (
                        <span className='modelnm cwpFocusVisible' tabIndex={0} role='button'>
                          {this.props.userVehicleContext.nickName}
                        </span>
                      )}
                      <span className={this.props.userVehicleContext.nickName ? 'modelnmtext' : 'modelnm'}>
                        {mdname}
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className='col'>
                <div className='righttalign'>
                  <span
                    className='add cwpFocusVisible px-2'
                    onClick={clickSwitchVehicle}
                    tabIndex={0}
                    role='button'
                    onKeyPress={e => {
                      if (e.key && (/enter/gi.test(e.key.toString()) || e.key === ' ')) {
                        clickSwitchVehicle(e);
                      } else {
                        e.preventDefault();
                      }
                    }}
                  >
                    <span>{translator.t('switch_vehicle')}</span>
                  </span>
                  <span className='rightArrow cwpCursorPointer' onClick={clickSwitchVehicle}>
                    <img src={rightArrow} width='22' alt='' />
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      }
    return <banner role='banner' className='model'></banner>;
  }
}

const clickSwitchVehicle = e => {
  e.preventDefault();
  e.stopPropagation();
  // clear out the vehicle context for language as we are exiting the vehicle
  clearVehicleLocaleContext();
  // clear out history so we don't restore unintentionally
  clearHistory();
  // ET go home (re-route to garage view)
  window.location = window.location.origin + '/#/';
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({}, dispatch)
  };
};

const mapStateToProps = state => {
  const { userVehicleContext } = state;

  return {
    ...userVehicleContext
  };
};
export default connect(mapStateToProps, null)(Feature);
