import { UserRole } from '../../../providers/userRolesProvider';
import { isNullOrEmptyObject } from '../../../util/utilityMethods';
import invoke from './ServiceProxy';

const ProfileStatus = Object.freeze({
  PROFILE_AVAILABLE: 'PROFILE_AVAILABLE',
  PROFILE_PARTIALLY_AVAILABLE: 'PROFILE_PARTIALLY_AVAILABLE',
  PROFILE_NOT_AVAILABLE: 'PROFILE_NOT_AVAILABLE'
});

const SpinStatus = Object.freeze({
  SPIN_AVAILABLE: 'SPIN_AVAILABLE',
  SPIN_NOT_APPLICABLE: 'SPIN_NOT_APPLICABLE',
  SPIN_NOT_AVAILABLE: 'SPIN_NOT_AVAILABLE'
});

const AssociationStatus = Object.freeze({
  ASSOCIATION_VERIFIED: 'ASSOCIATION_VERIFIED',
  ASSOCIATION_NOT_VERIFIED: 'ASSOCIATION_NOT_VERIFIED'
});

const TosStatus = Object.freeze({
  TOS_ACCEPTED: 'TOS_ACCEPTED',
  TOS_NOT_ACCEPTED: 'TOS_NOT_ACCEPTED'
});

const TosStatusOption = Object.freeze({
  ACCEPT: 'Accept',
  DECLINE: 'Decline'
});

const TosManifestId = Object.freeze({
  PRIM_USER_TOS: 'PRIM_USER_TOS',
  ADDNL_USER_TOS: 'ADDNL_USER_TOS',
  UBI_TOS: 'UBI_TOS',
  UBI: 'UBI',
  VEHICLE_HEALTH_CONCIERGE: 'VEHICLE_HEALTH_CONCIERGE',
  DATA_TRIAL: 'DATA_TRIAL',
  MARKETING_COMMUNICATIONS_CONSENT: 'MARKETING_COMMUNICATIONS_CONSENT',

  MY_VW_TOS: 'myVWtos',
  MY_VW: 'myVW',
  Privacy_2020_above: 'Privacy_2020_above',
  Privacy_2020_below: 'Privacy_2020_below',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  PrimUserProgramAndPrivacyPolicy: 'PrimUserProgramAndPrivacyPolicy'
});

const TosType = Object.freeze({
  CARNET: 'CARNET',
  UBI: 'UBI',
  VEHICLE_HEALTH_CONCIERGE: 'VEHICLE_HEALTH_CONCIERGE',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  MY_VW: 'myVW'
});
const getSpinStatus = async () => {
  return invoke({ url: '/garage/getSpinStatus' });
};

const getEnrollmentStatusNonDestructive = async () => {
  return invoke({ url: '/garage/getEnrollmentStatus?readOnly=true' });
};

const getEnrollmentStatus = async () => {
  return invoke({ url: '/garage/getEnrollmentStatus' });
};

const sendResetSpinSecurityCode = async () => {
  return invoke({ url: '/garage/forgotSpin', method: 'POST' });
};

const resetSpinWithOTP = async payload => {
  return invoke({
    url: '/garage/resetSpinWithOTP',
    method: 'POST',
    data: payload
  });
};

const validateSpin = async spinRequest => {
  return invoke({
    url: '/garage/validateSpin',
    method: 'POST',
    data: spinRequest
  });
};

const createSPIN = async spin => {
  let requestConfig = {
    method: 'POST',
    url: `/garage/createSpin`,
    data: { spin }
  };

  return invoke(requestConfig);
};

const getEstoreMarketingContent = async () => {
  let requestConfig = {
    url: 'static/content/estore/marketing'
  };

  return invoke(requestConfig);
};

const getSubscriptionSummary = async vehicleId => {
  let requestConfig = {
    method: 'POST',
    url: `/garage/manageServicePlans/getSubscriptionSummary`,
    data: { vehicleId }
  };

  return invoke(requestConfig);
};

const removeServicePlans = async (vehicleId, skuList) => {
  let requestConfig = {
    method: 'POST',
    url: `/garage/manageServicePlans/removeServicePlans`,
    data: { vehicleId, skuList }
  };

  return invoke(requestConfig);
};

const unenrollVehicle = async vehicleId => {
  let requestConfig = {
    method: 'POST',
    url: `/garage/manageServicePlans/unenrollVehicle`,
    data: { vehicleId }
  };

  return invoke(requestConfig);
};

const findVehicleByVehicleId = async vehicleId => {
  let requestConfig = {
    method: 'POST',
    url: `/garage/findVehicleByVehicleId`,
    data: { vehicleId }
  };

  return invoke(requestConfig);
};

const isTosRequired = (enrollmentStatus = {}, tosManifest) => {
  return (
    TosStatus.TOS_NOT_ACCEPTED === enrollmentStatus.tosStatus ||
    isNullOrEmptyObject(enrollmentStatus.tos) ||
    isTosUpdateRequired(enrollmentStatus, tosManifest)
  );
};

const isTosUpdateRequired = (enrollmentStatus, tosManifest) => {
  const { tosType, tosVersion } = enrollmentStatus?.tos || {};

  if (tosType !== TosType.CARNET) {
    return true;
  }

  const applicableManifest = findApplicableManifestForCarnetTOS(enrollmentStatus, tosManifest) || {};
  return applicableManifest.tosVersion !== tosVersion;
};

const findApplicableManifestForCarnetTOS = (enrollmentStatus, tosManifest) => {
  const roles = enrollmentStatus?.rolesAndRights?.roles;
  const manifest = tosManifest?.manifest;

  if (!Array.isArray(roles) || roles.length < 1 || !Array.isArray(manifest) || manifest.length < 1) {
    return null;
  }

  if (roles.includes(UserRole.PRIMARY_USER)) {
    return manifest.find(mf => mf.id === TosManifestId.PRIM_USER_TOS);
  } else if (roles.includes(UserRole.SECONDARY_USER)) {
    return manifest.find(mf => mf.id === TosManifestId.ADDNL_USER_TOS);
  }

  return null;
};

const findTosStatusList = () => {
  return invoke({ url: '/garage/tosStatus' });
};

const findMyVWTosStatusIfAccepted = async tosManifest => {
  const { manifest } = tosManifest;
  let tosResponse = [];
  try {
    let responseFromTosServier = await findTosStatusList();
    tosResponse = responseFromTosServier?.toses || [];
  } catch (err) {
    console.log('GarageProvider >> findMyVWTosStatusIfAccepted unable to extract tos list from tosStatus', err);
  }
  const tosResponseEmpty = !tosResponse.length;
  const manifestMyVWtos = manifest.find(m => m.id === TosManifestId.MY_VW_TOS);
  const tosMyVW = (Array.isArray(tosResponse) && tosResponse.find(t => t.tosType === TosType.MY_VW)) || null;

  if (
    tosResponseEmpty ||
    !tosMyVW ||
    tosMyVW?.tosVersion !== manifestMyVWtos?.tosVersion ||
    tosMyVW?.tosStatus?.toUpperCase() !== TosStatusOption.ACCEPT.toUpperCase()
  ) {
    return manifestMyVWtos;
  }
  return null;
};

const findPreGaragePendingManifest = async tosManifest => {
  const { toses } = await findTosStatusList();
  const { manifest } = tosManifest;

  if (!Array.isArray(toses) || !Array.isArray(manifest)) {
    return null;
  }

  const manifestMyVWtos = manifest.find(m => m.id === TosManifestId.MY_VW_TOS);
  const tosMyVW = toses.find(t => t.tosType === TosType.MY_VW);

  if (
    tosMyVW?.tosVersion !== manifestMyVWtos?.tosVersion ||
    tosMyVW?.tosStatus?.toUpperCase() !== TosStatusOption.ACCEPT.toUpperCase()
  ) {
    return manifestMyVWtos;
  }

  const manifestPrivacyPolicy = manifest.find(m => m.id === TosManifestId.PRIVACY_POLICY);
  const tosPrivacyPolicy = toses.find(t => t.tosType === TosType.PRIVACY_POLICY);

  if (
    tosPrivacyPolicy?.tosVersion !== manifestPrivacyPolicy?.tosVersion ||
    tosPrivacyPolicy?.tosStatus?.toUpperCase() !== TosStatusOption.ACCEPT.toUpperCase()
  ) {
    return manifestPrivacyPolicy;
  }

  const manifestMCC = manifest.find(m => m.id === TosManifestId.MARKETING_COMMUNICATIONS_CONSENT);
  const tosMCC = toses.find(t => t.tosType === TosManifestId.MARKETING_COMMUNICATIONS_CONSENT);

  if (tosMCC?.tosVersion !== manifestMCC?.tosVersion) {
    return manifestMCC;
  }

  return null;
  // Workaround block ::::: ends here
};

const updateTOS = async (tosStatus, tosType, tosVersion, vehicleId) => {
  return invoke({
    method: 'PUT',
    url: '/garage/tosStatus',
    data: {
      tosStatus,
      tosType,
      tosVersion,
      vehicleId
    }
  });
};

const getSecureContentHTML = async (url, locale, absolute) => {
  return invoke({
    method: 'POST',
    url: '/securecontent/htmlContent',
    data: {
      url,
      locale,
      absolute
    }
  });
};

const findTosTypeByManifestId = manifestId => {
  switch (manifestId) {
    case TosManifestId.PRIM_USER_TOS:
    case TosManifestId.ADDNL_USER_TOS:
      return TosType.CARNET;

    case TosManifestId.Privacy_2020_above:
    case TosManifestId.Privacy_2020_below:
    case TosManifestId.PRIVACY_POLICY:
      return TosType.PRIVACY_POLICY;

    case TosManifestId.MY_VW_TOS:
      return TosType.MY_VW;

    default:
      return manifestId;
  }
};

const actionHookProfileCompletion = (action, stateId) => {
  return invoke({
    method: 'PUT',
    url: `/profilecompletion/action/${action}/state/${stateId}`,
    data: {}
  });
};

export {
  ProfileStatus,
  SpinStatus,
  AssociationStatus,
  TosStatus,
  TosStatusOption,
  TosManifestId,
  getSpinStatus,
  getEnrollmentStatus,
  getEnrollmentStatusNonDestructive,
  sendResetSpinSecurityCode,
  resetSpinWithOTP,
  validateSpin,
  createSPIN,
  getSubscriptionSummary,
  removeServicePlans,
  unenrollVehicle,
  findTosStatusList,
  isTosRequired,
  findVehicleByVehicleId,
  getEstoreMarketingContent,
  findApplicableManifestForCarnetTOS,
  findMyVWTosStatusIfAccepted,
  findPreGaragePendingManifest,
  updateTOS,
  getSecureContentHTML,
  findTosTypeByManifestId,
  actionHookProfileCompletion
};
