import { GET_PREFERRED_DEALER, GET_PREFERRED_DEALER_SUCCESS, GET_PREFERRED_DEALER_FAILURE } from '../../actions/types';

const initalState = {
  preferredDealerData: null
};

const preferredDealerReducer = (state = initalState, action = { payload: {} }) => {
  let newState = { ...state };
  let actionType = (action && action.type) || 'default';
  let responseData = action.payload || {};

  switch (actionType) {
    case GET_PREFERRED_DEALER:
      newState = { ...initalState };
      return newState;

    case GET_PREFERRED_DEALER_SUCCESS:
      let { data } = responseData;
      newState['preferredDealerData'] = data || null;
      return newState;

    case GET_PREFERRED_DEALER_FAILURE:
      newState['preferredDealerData'] = null;
      return newState;
    default:
      return newState;
  }
};

export default preferredDealerReducer;
