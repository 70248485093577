import React from 'react';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import Loader from '../common/loader';
import carnet_constants from '../../../constants/carnet_constants';
import { getTosEnrollmentsMarketingConsent } from '../providers/AccountAndSecurityProvider';
import { Row, Col } from 'reactstrap';
import './AccountManagement.scss';
import { setTitle } from '../../../providers/documentTitleProvider';

class MarketingConsent extends React.Component {
  constructor (props) {
    super(props); //props.tsp injects the vehicle TSP
    this.state = {
      loadingData: true,
      enrolled: false,
      firstActiveFocusLoading: true
    };
  }

  componentDidMount = () => {
    setTitle('document_titles.account_management.marketing_consent');
    getTosEnrollmentsMarketingConsent()
      .then(data => {
        console.log('Successfull response back from getting MarketingConsent enrollment status... ');
        console.log(JSON.stringify(data));

        this.displayLoadedEnrollmentStatus(data[0]);
      })
      .catch(err => {
        console.log('Error thrown trying to get MarketingConsent enrollment: ');
        console.log(JSON.stringify(err));
      });
  };

  componentDidUpdate () {
    if (this.props.focus && this.state.firstActiveFocusLoading && !this.state.loadingData) {
      try {
        document.getElementById('firstActiveFocus').focus();
      } catch (err) {
        console.log('element not found for autofocus');
      }
      this.setState({ firstActiveFocusLoading: false });
    }
  }

  displayLoadedEnrollmentStatus = statusRecord => {
    let mcEnrolled = false;
    if (statusRecord) {
      mcEnrolled = statusRecord.tosStatus == carnet_constants.TOS_STATUS_ACCEPT;
    }
    this.setState({
      enrolled: mcEnrolled,
      loadingData: false
    });
  };

  editField = reference => {
    console.log('editField() invoked for ref: ' + reference);
    this.props.editingCallback(reference);
  };

  render () {
    this.translator = getLocaleTranslator();
    //the way to create a Deep Clone (nested props)
    let enrollmentStatusFlag =
      this.state.enrolled === true
        ? this.translator.t('acctmgmt_accepted_label')
        : this.translator.t('acctmgmt_declined_label');

    return this.state.loadingData === true ? (
      <div id='acctmgmt-contentpane'>
        <Loader />
      </div>
    ) : (
      <div className='acctmgmt-contentpane'>
        <div id='ghost-wrapper' class='vehicle-info-parent'>
          <div className='contentpane-title'>
            <h2 id='firstActiveFocus' tabIndex={-1} className='h6'>
              {this.translator.t('acctmgmt_mc_heading')}
            </h2>
          </div>
          <div>
            <Row>
              <Col md='2'>
                <span className='carnet-theme-field-label'>{this.translator.t('acctmgmt_consent_forms')}</span>
              </Col>
              <Col md='2'></Col>
              <Col md='6'>
                <span ref='pi-name-field' className='carnet-theme-field-value vi-data'>
                  {enrollmentStatusFlag}
                </span>
              </Col>
              <Col md='2'>
                <span className='theme-updatelink'>
                  <button
                    className='btn btn-link carnetLinkLight'
                    type='button'
                    onClick={() => this.editField('mc-editor')}
                  >
                    {this.translator.t('acctmgmt_pi_edit_link_label')}
                  </button>
                </span>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default MarketingConsent;
