import React from 'react';
import DriveViewScoreReading from './driveViewScoreReading';
import idleTime from '../../../assets/driveView/idle.png';
import excessOfSpeed from '../../../assets/driveView/speed-grey.png';
import nightTimeDriving from '../../../assets/driveView/nighttime-driving.png';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { findIn } from '../../../util/utilityMethods';

const Snapshot = props => {
  let snapShot = props.snapShot;
  let translator = getLocaleTranslator();
  return (
    <div>
      <div id='snapShotHeading' className='driveViewComponentSectionHeading'>
        {translator.t('driveview.snapshot.heading')}
      </div>
      <div id='snapShotInfo' className='driveViewComponentSnapShot'>
        <div class='driveViewComponentSnapShotInfo'>
          <DriveViewScoreReading score={findIn('tripWise.score', snapShot) || 0} />

          <div className='driveViewComponentSnapShotPadding'>
            <img src={nightTimeDriving} alt={translator.t('driveview.snapshot.label.night-time-driving')} />
            <br />
            <span className='textSize'>{translator.t('driveview.snapshot.label.night-time-driving')}</span>
            <br />
            <span className='numberSize'>
              {snapShot ? findIn('tripWise.nightTimeDrivingPercent', snapShot) || 0 : 0}%
            </span>
          </div>

          <div className='driveViewComponentSnapShotPadding'>
            <img src={excessOfSpeed} alt={translator.t('driveview.snapshot.label.excess-of-speed')} />
            <br />
            <span className='textSize'>{translator.t('driveview.snapshot.label.excess-of-speed')}</span>
            <br />
            <span className='numberSize'>{snapShot ? findIn('tripWise.excessOfSpeedPercent', snapShot) || 0 : 0}%</span>
          </div>

          <div className='driveViewComponentSnapShotPadding'>
            <img src={idleTime} alt={translator.t('driveview.snapshot.label.idle-time')} />
            <br />
            <span className='textSize'>{translator.t('driveview.snapshot.label.idle-time')}</span>
            <br />
            <span className='numberSize'>
              {snapShot && snapShot.tripWise.idleTimePercent ? findIn('tripWise.idleTimePercent', snapShot) || 0 : 0}%
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Snapshot;
