import React, { Component } from 'react';
import Faq from 'react-faq-component';
import '../faqCanada/faqCA.scss';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import goback from '../../../assets/faq/goback.png';
import { historyBack } from './faqCAhome';
import { setTitle } from '../../../providers/documentTitleProvider';

export default class VHRFaqCA extends Component {
  componentDidMount () {
    setTitle('document_titles.faq.vhr');
  }
  render () {
    let translator = getLocaleTranslator();

    return (
      <div className='faqCAheader' role='main' id='main-content'>
        <div id='exit-editing-link'>
          <span
            id='backButton'
            class='cwpBack'
            role='button'
            onClick={historyBack}
            onKeyPress={e => {
              e.preventDefault();
              if (e.key && (e.key === 'Enter' || e.key === ' ')) {
                historyBack(e);
              }
            }}
            tabIndex={0}
          >
            {translator.t('button.back')}
          </span>
        </div>
        <div className='message-header'>
          <div className='td-ms-header'>
            <h1>{translator.t('vhr_title')}</h1>
          </div>
        </div>
        <Faq data={data} />
      </div>
    );
  }
}

const data = {
  rows: [
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          What is a Vehicle Health Report (VHR)?{' '}
        </div>
      ),
      content: `<span class='mdlyr'>Model Year 2022+:</span> A Vehicle Health Report is a snapshot of your vehicle’s health. This information is gathered each time your vehicle is turned off. Requesting a new health report through the mobile app or website will provide information on your vehicle from the time of the last engine shut off. 
      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          What do the different Status colors of Vehicle Health Report (VHR) messages mean?{' '}
        </div>
      ),
      content: `
      <span class='mdlyr'>Model Year 2022+:</span> 
        <ol> 
           <li>Green - No faults detected. – at this time</li>
           <li> Yellow - Please see your authorized Volkswagen dealer or an authorized Volkswagen Service Facility for needed repair or adjustment.</li>
           <li> Red - Stop! Do not drive vehicle, contact an authorized Volkswagen dealer.</li>
        </ol>
      `
    },

    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          How do I obtain my Vehicle Health Report (VHR)?{' '}
        </div>
      ),
      content: ` <span class='mdlyr'>Model Year 2022+:</span> You will receive an automated monthly report on the monthly anniversary of your Car Net enrollment date. You may also request an updated report manually through the mobile app or web portal. The Car-Net Web Portal has a rolling 12-month view of your past Vehicle Health Reports.  
      `
    }
  ]
};

export { VHRFaqCA };
