import React from 'react';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';

const PriorityCodeLegend = () => {
  let translator = getLocaleTranslator();

  return (
    <div>
      <span className='legend-text'>{translator.t('vehicleHealth.priority_legend.legend_label')}</span>
      <div className='priorityCode-legend' align='center'>
        <table className='vhr-legend'>
          <tr>
            <td className='legend-icon priorityCodeLegendA'>
              <span className='priorityCode-legend'>{translator.t('vehicleHealth.priority_legend.code_a_label')}</span>
            </td>
          </tr>
          <tr>
            <td className='legend-icon priorityCodeLegendB'>
              <span className='priorityCode-legend'>{translator.t('vehicleHealth.priority_legend.code_b_label')}</span>
            </td>
          </tr>
          {/*<tr>
            <td className='legend-icon priorityCodeLegendC'>
              <span className='priorityCode-legend'>{translator.t('vehicleHealth.priority_legend.code_c_label')}</span>
            </td>
          </tr> */}
          <tr>
            <td className='legend-icon priorityCodeLegendD'>
              <span className='priorityCode-legend'>{translator.t('vehicleHealth.priority_legend.code_d_label')}</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default PriorityCodeLegend;
