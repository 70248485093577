import {
  PUT_USER_SECURITY_QUESTIONS,
  PUT_USER_SECURITY_QUESTIONS_SUCCESS,
  PUT_USER_SECURITY_QUESTIONS_FAILURE
} from '../../actions/types';

const initalState = {
  customerData: null
};

const saveUserSecurityQuestionsReducer = (state = initalState, action = { payload: {} }) => {
  let newState = { ...state };
  let actionType = (action && action.type) || 'default';
  let responseData = action.payload || {};

  switch (actionType) {
    case PUT_USER_SECURITY_QUESTIONS:
      newState = { ...initalState };
      return newState;
    case PUT_USER_SECURITY_QUESTIONS_SUCCESS:
      let { data } = responseData;
      newState['data'] = data || null;
      return newState;
    case PUT_USER_SECURITY_QUESTIONS_FAILURE:
      newState['data'] = null;
      return newState;
    default:
      return newState;
  }
};

export default saveUserSecurityQuestionsReducer;
