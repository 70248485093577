import React, { Component } from 'react';
import './Navbar.scss';
import '../CarnetHome.scss';
import NavbarATC from './NavbarATC';
import NavbarWTC from './NavbarWTC';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeaderPrePinAuth from '../garage/HeaderPrePinAuth';

class NavBar extends Component {
  constructor (props) {
    super(props);
  }

  render () {
    if (!this.props.userVehicleContext || !this.props.userVehicleContext.vin) {
      return <HeaderPrePinAuth />;
    }

    if (this.props.userVehicleContext.tspProvider === 'ATC') {
      return <NavbarATC />;
    } else {
      return <NavbarWTC />;
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({}, dispatch)
  };
};

const mapStateToProps = state => {
  const { userVehicleContext } = state;

  return {
    ...userVehicleContext
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
