import invoke from './ServiceProxy';

const getWifiConfiguration = async address => {
  return await invoke({ url: '/customer/account/wificonfig', method: 'GET' }).then(res => {
    if (res && res.error) {
      return Promise.reject(res);
    }
    return res;
  });
};

const createWifiConfiguration = async (data = {}) => {
  return await invoke({ url: '/customer/account/wificonfig', method: 'POST', data }).then(res => {
    if (res && res.error) {
      return Promise.reject(res);
    }
    return res;
  });
};

const updateWifiConfiguration = async (data = {}) => {
  return await invoke({ url: '/customer/account/wificonfig/update', method: 'POST', data }).then(res => {
    if (res && res.error) {
      return Promise.reject(res);
    }
    return res;
  });
};

const toggleWifi = async status => {
  let data = {
    isEnabled: status
  };
  return await invoke({ url: '/customer/account/wificonfig/toggle', method: 'POST', data }).then(res => {
    if (res && res.error) {
      return Promise.reject(res);
    }
    return res;
  });
};

const resetWifiConfiguration = async () => {
  return await invoke({ url: '/customer/account/wificonfig/reset', method: 'POST' }).then(res => {
    if (res && res.error) {
      return Promise.reject(res);
    }
    return res;
  });
};

export { getWifiConfiguration, createWifiConfiguration, updateWifiConfiguration, toggleWifi, resetWifiConfiguration };
