import React from 'react';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ThemedCheckBox from '../../reusable_cmp_lib/ThemedCheckBox/ThemedCheckBox';
import { findApplicableTos, TOS_TYPE } from '../providers/SelfEnrollmentProvider';
import { unenrollFromConciergePilot } from '../providers/AccountAndSecurityProvider';
import '../../reusable_cmp_lib/ThemedButtonsAssortment/ThemedButtonsAssortment.scss';
import './AccountManagement.scss';

class VSCUnenroll extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      signedOff: false,
      showModal: false,
      unenrollResponse: null,
      error: null
    };
  }

  processUnenrollment = async () => {
    if (this.state.signedOff === false) {
      return;
    }
    this.setState({
      saving: true
    });

    let tosVersion;
    try {
      let { tosVersion } = await findApplicableTos(TOS_TYPE.VEHICLE_HEALTH_CONCIERGE);
    } catch (err) {
      console.log('Error thrown trying to get the TOS version: ' + tosVersion);
    }
    console.log('Now, saving unenrollmentn from VSC ...');
    let ultimateDelta = { saving: false };
    unenrollFromConciergePilot(tosVersion)
      .then(() => {
        console.log('Appears to be a successful SAVE of enrollment prefs!');
        ultimateDelta['unenrollResponse'] = 'Success';
      })
      .catch(err => {
        console.log('Appears to be a failure of the NEW pathway save.: ' + JSON.stringify(err));
        ultimateDelta['unenrollResponse'] = 'Error';
        ultimateDelta['error'] = JSON.stringify(err);
      })
      .finally(() => {
        ultimateDelta['showModal'] = true;
        this.setState(ultimateDelta);
      });
  };

  navBackToPrevPage = () => {
    window.history.back();
  };

  handleCheckBoxToggled = evt => {
    let checkedState = evt.target.checked;
    this.setState({
      signedOff: checkedState
    });
  };

  render () {
    this.translator = getLocaleTranslator();
    let anyOutcomeDetails = '';
    if (this.state.showModal === true && this.state.unenrollResponse) {
      anyOutcomeDetails =
        this.state.unenrollResponse && this.state.unenrollResponse === 'Error'
          ? this.state.error || ''
          : this.translator.t('acctmgmt_unenroll_success');
    }

    return (
      <Row class='borderless'>
        <Col md='3'>
          <div>
            <div id='exit-editing-link' onClick={this.navBackToPrevPage}>
              <a>
                <span class='arrow'> ← </span>
                <span class='editor-navback-arrow-text'>{this.translator.t('acctmgmt_back_label')}</span>
              </a>
            </div>
          </div>
        </Col>
        <Col md='9'>
          <div id='unenroll-vsc-page'>
            <div class='vertical-spacer-small'>
              <br />
            </div>
            <div id='placeholder-page-title-aligner'>
              {/* NO TRANSLATION NEEDED: TEMP. PLACEHOLDER FILE ONLY!!! */}
              <span>{this.translator.t('acctmgmt_vsc_pilot_label')}</span>
            </div>
            <div class='vertical-spacer-small'>
              <Modal isOpen={this.state.showModal} className='carnet-sg-modal'>
                <ModalHeader>{this.state.unenrollResponse}</ModalHeader>
                <ModalBody>{anyOutcomeDetails}</ModalBody>
                <ModalFooter>
                  <Button
                    className='modal-primary-btn'
                    color='modal_background_color'
                    onClick={() => {
                      this.setState({
                        showModal: false,
                        unenrollResponse: null,
                        error: null
                      });
                    }}
                  >
                    {this.translator.t('button.ok')}
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
            <div id='unenroll-vsc-page-desscription'>
              <span id='unenroll-vsc-page-description-lbl'>{this.translator.t('acctmgmt_vsc_unenroll_tempt')}</span>
            </div>
            <div class='vertical-spacer-small'>
              <br />
            </div>
            <div id='unenroll-vsc-page-instrux'>
              <div class='vsc-unenroll-page-instrux' id='p1'>
                {this.translator.t('acctmgmt_vsc_unenroll_instrux_1')}
              </div>
              <div class='vsc-unenroll-page-instrux' id='p2'>
                {this.translator.t('tos_vhc.main_desc_2_part_1')}
                <sup>2</sup>
                {this.translator.t('tos_vhc.main_desc_2_part_2')}
              </div>
              <div class='vsc-unenroll-page-instrux' id='p3'>
                {this.translator.t('acctmgmt_vsc_detail')}
              </div>
              <div id='vsc-instrux-footnotes'>
                <ol>
                  <li>{this.translator.t('acctmgmt_vsc_enroll_footnote_1')}</li>
                  <li>
                    <sup>2</sup>
                    {this.translator.t('acctmgmt_vsc_enroll_footnote_2')}
                  </li>
                </ol>
              </div>
            </div>

            <div id='vsc-unenroll-checkbox-aligner'>
              <ThemedCheckBox
                toggleHandler={this.handleCheckBoxToggled}
                name='unenroll-chkbox'
                id='unenroll-vsc-chkbox'
                label='I would like to unenroll from VSC Pilot'
                isChecked={false}
              />
            </div>
            <div id='vsc-unenroll-commit-btn'>
              <Button
                onClick={this.processUnenrollment}
                className='cwp'
                color='primary'
                disabled={!this.state.signedOff}
              >
                {this.translator.t('unenroll_label')}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default VSCUnenroll;
