import React from 'react';
import PropTypes from 'prop-types';
import ReactShowMoreText from 'react-show-more-text';
import AutoLabelCheckbox from '../../common/marketingConfiguration/AutoLabelCheckbox';
import { getLocaleTranslator } from '../../../../util/i18n/i18nService';
import { getCarnetMarketingStaticInfo } from '../../providers/SelfEnrollmentProvider';
import { UncontrolledAlert } from 'reactstrap';
import Disclaimer from '../../buyPlansNew/components/Disclaimer';

export default class TosComponentDataTrial extends React.Component {
  static propTypes = {
    defaultMno: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  };

  state = {
    alertMessage: '',
    marketingConfiguration: null,
    marketingConfigurationConsentCheckbox: null,
    disclaimerText: null,
    disclaimerNumber: null,
    disclaimerTargetDomNode: null
  };

  constructor (props) {
    super(props);
    this.translator = getLocaleTranslator();
  }

  componentDidMount () {
    this.initMarketingConfigurations();
  }

  showDisclaimer = (disclaimerText, disclaimerNumber, disclaimerTargetDomNode) =>
    this.setState({ disclaimerText, disclaimerNumber, disclaimerTargetDomNode });

  initMarketingConfigurations = async () => {
    try {
      const { configurations } = await getCarnetMarketingStaticInfo();

      if (Array.isArray(configurations)) {
        const marketingConfiguration = configurations.find(
          c => c.subCategory?.toLowerCase() === 'trial' && c?.providerCode === this.props.defaultMno
        );
        const marketingConfigurationConsentCheckbox = AutoLabelCheckbox.parseValuesFromConsentCheckbox(
          marketingConfiguration?.consentCheckbox
        );

        this.setState({ marketingConfiguration, marketingConfigurationConsentCheckbox });
      }
    } catch (ex) {
      this.setState({ alertMessage: ex.message || ex.stack || ex });
    }
  };

  clearAlerts = () => this.setState({ alert: null });

  getAlerts = () => {
    if (this.state.alert) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts}>
          {this.state.alert}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  findDisclaimerText = (list, number) => {
    let itemFound = list.filter(item => item['disclaimerNumber'] === number)[0] || {};
    return itemFound['disclaimerText'];
  };

  render () {
    const { marketingConfiguration, marketingConfigurationConsentCheckbox } = this.state;

    return (
      <>
        <h2 className='h4 font-weight-bold'>{this.translator.t('self_enrollment.data_trial.wifi_trial')}</h2>
        {this.getAlerts()}
        <article className='mb-3 lead'>
          {marketingConfiguration?.currentPlanContent?.descriptionActive}
          {marketingConfiguration?.articleDisclaimerFlag &&
            marketingConfiguration?.articleDisclaimerNumber &&
            Array.isArray(marketingConfiguration?.additionalDisclaimerList) && (
              <sup
                className='cwp-disclaimer'
                tabIndex={0}
                role='button'
                onClick={e => {
                  this.showDisclaimer(
                    this.findDisclaimerText(
                      marketingConfiguration.additionalDisclaimerList,
                      marketingConfiguration.articleDisclaimerNumber
                    ),
                    marketingConfiguration.articleDisclaimerNumber,
                    e.currentTarget
                  );
                }}
              >
                {marketingConfiguration.articleDisclaimerNumber}
              </sup>
            )}
        </article>

        <article className='mb-3 lead'>
          <h3 className='h5 font-weight-bold'>{this.translator.t('self_enrollment.data_trial.wifi_trial_includes')}</h3>
          <ul className='list-unstyled'>
            <li>{this.translator.t('self_enrollment.data_trial.feature_1')}</li>
            <li>{this.translator.t('self_enrollment.data_trial.feature_2')}</li>
            <li>{this.translator.t('self_enrollment.data_trial.feature_3')}</li>
          </ul>
        </article>

        <ReactShowMoreText
          lines={3}
          more={this.translator.t('read_more')}
          less={this.translator.t('read_less')}
          className='small mb-3'
          width={800}
          anchorClass='carnetLinkLight small'
          expanded={false}
        >
          {Array.isArray(marketingConfiguration?.additionalDisclaimerList) &&
            marketingConfiguration?.additionalDisclaimerList.map(disc => <>{disc.disclaimerText}</>)}
        </ReactShowMoreText>
        <p className='lead'>{this.translator.t(`self_enrollment.data_trial.${this.props.defaultMno}.accept_prompt`)}</p>

        {Array.isArray(marketingConfigurationConsentCheckbox) &&
          marketingConfigurationConsentCheckbox.map(cc => (
            <AutoLabelCheckbox
              value={cc}
              translator={this.translator}
              onClickHyperLink={() => {}}
              unClickedLinks={[]}
              onChange={this.props.onChange}
              disableCheckboxUntilAllLinksClicked={false}
            />
          ))}

        {this.state.disclaimerText && (
          <Disclaimer
            translator={this.translator}
            disclaimerText={this.state.disclaimerText}
            disclaimerNumber={this.state.disclaimerNumber}
            disclaimerTargetDomNode={this.state.disclaimerTargetDomNode}
            onExit={() => {
              if (this.state.disclaimerTargetDomNode) {
                this.state.disclaimerTargetDomNode.focus();
              }
              this.setState({ disclaimerText: null, disclaimerNumber: null, disclaimerTargetDomNode: null });
            }}
          />
        )}
      </>
    );
  }
}
