import React from 'react';
import { Modal, ModalHeader, ModalBody, Form, UncontrolledAlert } from 'reactstrap';
import Spinner from '../Spinner';
import { findErrorMessage } from '../../../util/i18n/i18nService';
import * as garageProvider from '../providers/GarageProvider';
import { TSP_PROVIDER } from '../providers/SelfEnrollmentProvider';
import { findIn } from '../../../util/utilityMethods';
import { setTitle } from '../../../providers/documentTitleProvider';

const SpinStatus = Object.freeze({
  LOCKED: 'LOCKED',
  DEFINED: 'DEFINED'
});

class ModalInputPIN extends React.Component {
  constructor (props) {
    super(props);

    const { spinStatus = SpinStatus.LOCKED } = props;

    this.state = {
      spinStatus,
      spin: '',
      processing: false,
      invalidSpinAlert: null,
      alert: null,
      pinVisible: false
    };
  }

  clearAlerts = () => this.setState({ alert: null });

  getAlerts = () => {
    if (this.state.alert) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts}>
          {this.state.alert}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  sendOTP = async e => {
    e.preventDefault();

    if (this.props.enrollmentStatus.vehicle.tspProvider === TSP_PROVIDER.VZT) {
      this.props.toggleModalOTP();
      return;
    }

    this.setState({ processing: true, alert: null });
    try {
      await garageProvider.sendResetSpinSecurityCode();

      this.setState({ processing: false });
      this.props.toggleModalOTP();
    } catch (error) {
      this.setState({ processing: false, alert: findErrorMessage(error, this.props.translator) });
    }
  };

  validateSpin = async () => {
    const tspProvider = findIn('enrollmentStatus.vehicle.tspProvider', this.props);
    const inputLockedPIN = this.state.spinStatus === 'LOCKED' && tspProvider !== TSP_PROVIDER.VZT;

    if (inputLockedPIN || !this.state.spin || this.state.spin.length < 4) {
      return;
    }

    this.setState({ processing: true, alert: null, invalidSpinAlert: null });

    let spinRequest = {
      vehicleId: this.props.enrollmentStatus.vehicle.vehicleId,
      spin: this.state.spin,
      tspProvider: this.props.enrollmentStatus.vehicle.tspProvider
    };

    if (this.props.enrollmentStatus.vehicle.tspProvider === TSP_PROVIDER.VZT) {
      spinRequest.tspAccountNum = this.props.enrollmentStatus.rolesAndRights.tspAccountNum;
    }

    let data = null;
    try {
      data = await garageProvider.validateSpin(spinRequest);
    } catch (err) {
      data = { error: err };
    }

    if (data.error) {
      const { spinStatus, remainingTries } = await garageProvider.getSpinStatus();

      if (data.error.errorCode === 'SPIN_INVALID_SECURITYPIN') {
        this.setState({
          processing: false,
          alert: null,
          spinStatus,
          invalidSpinAlert: `${findErrorMessage(
            data.error,
            this.props.translator
          )} ${remainingTries} ${this.props.translator.t('pin_prompt.tries_remaining')}`
        });
      } else {
        this.setState({
          spinStatus,
          processing: false,
          alert: findErrorMessage(data.error, this.props.translator),
          invalidSpinAlert: null
        });
      }

      return;
    }

    this.setState({ processing: false });

    this.props.onSuccess(data);
  };

  toggle = e => {
    e.preventDefault();
    this.setState({ processing: false, alert: null }, () => {
      this.props.toggle(e);
      setTitle('document_titles.garage.title');
    });
  };

  updateSpinEntry = val => {
    let spinStr = String(val).replace(/[^0-9]/gi, '');
    this.setState({ spin: spinStr });
  };

  render () {
    const tspProvider = findIn('enrollmentStatus.vehicle.tspProvider', this.props);

    const inputLockedPIN = this.state.spinStatus === 'LOCKED' && tspProvider !== TSP_PROVIDER.VZT;

    return (
      <Modal
        isOpen={this.props.visible}
        toggle={this.toggle}
        centered={true}
        className='preVehicleAuth'
        aria-labelledby='lblEnterPin'
        autoFocus={false}
        keyboard={true}
      >
        <ModalHeader tag='h2' className='downSizedh2' id='lblEnterPin' toggle={this.toggle}>
          {this.props.translator.t('pin_prompt.enter_pin')}
        </ModalHeader>
        <ModalBody>
          {inputLockedPIN && (
            <UncontrolledAlert color='warning'>{this.props.translator.t('pin_prompt.pin_locked')}</UncontrolledAlert>
          )}
          {this.getAlerts()}
          <Form
            hidden={this.state.processing}
            onSubmit={e => {
              e.preventDefault();
              this.validateSpin();
              return false;
            }}
            className='pinForm'
          >
            <label htmlFor='pininput' className='cwp-blue float-left'>
              {this.props.translator.t('pin_prompt.pin')}:{' '}
            </label>
            <input
              className='cwp text-center'
              type={this.state.pinVisible ? 'text' : 'password'}
              maxLength='4'
              placeholder='XXXX'
              autoComplete='off'
              title={this.props.translator.t('pin_prompt.4_digit_number_only')}
              onChange={e => {
                e.preventDefault();
                this.updateSpinEntry(e.currentTarget.value);
                return false;
              }}
              onSubmit={this.validateSpin}
              autoFocus={true}
              disabled={inputLockedPIN}
              value={this.state.spin}
              id='pininput'
            />
            <span
              className={this.state.pinVisible ? 'cwpInputEye cwpInputEyeOpen' : 'cwpInputEye'}
              onClick={e => this.setState({ pinVisible: !this.state.pinVisible })}
            />
            <br />
            <small className='text-danger'>{this.state.invalidSpinAlert}</small>
            <br />
            <span
              className='arrowRight cwp-blue float-left'
              role='link'
              tabIndex={0}
              onClick={this.sendOTP}
              onKeyPress={this.sendOTP}
            >
              {this.props.translator.t('pin_prompt.reset_pin')}
            </span>
            <br />
            <br />
            <br />
            <button
              className='cwp float-left'
              type='submit'
              disabled={inputLockedPIN || !this.state.spin || this.state.spin.length < 4}
            >
              {this.props.translator.t('button.continue')}
            </button>
            <button className='cwpSecondary float-right' onClick={this.toggle}>
              {this.props.translator.t('button.cancel')}
            </button>
            <br />
            <br />
            <br />
          </Form>
          <Spinner hidden={!this.state.processing} />
        </ModalBody>
      </Modal>
    );
  }
}

export default ModalInputPIN;
