import React from 'react';
import Capabilities from './Capabilities';
import Trial from './Trial';
import './modelYr2014_19.scss';
import ModelYr2014_19Res from './ModelYr2014_19Res';
import VinLookUp from './VinLookUp';

const ModelYr2014_19 = () => {
  return (
    <div className='main-page'>
      <div className='3G_Langing'>
        <table className='remote-data'>
          <tr>
            <td>
              <Capabilities />
            </td>
          </tr>
          <tr>
            <td>
              <Trial />
            </td>
          </tr>
          <tr>
            <td>
              <div id='eligibility'></div>
              <VinLookUp />
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default ModelYr2014_19;
