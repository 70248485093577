import React from 'react';
import statusActive from '../../../assets/buyPlans/active.png';
import statusPending from '../../../assets/buyPlans/pending.png';
import statusExpired from '../../../assets/buyPlans/expired.png';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { findIn } from '../../../util/utilityMethods';

const ONE_TIME = 'ONE_TIME';
const RECURRING = 'RECURRING';
const YEARS = 'YEARS';
const MONTHS = 'MONTHS';
const YEARLY = 'YEARLY';
const PREPAID = 'prepaid';
const USD = 'USD';
const SCHEDULED_FOR_PURCHASE = 'SCHEDULED_FOR_PURCHASE';
const PENDING = 'PENDING';
const PURCHASED = 'PURCHASED';
const PURCHASE = 'PURCHASE';
const INACTIVE = 'INACTIVE';
let translator = getLocaleTranslator();

const CHAMBERLAIN_SUB_TYPES = Object.freeze({
  GARAGECONTROL_TRIAL: 'GARAGECONTROL_TRIAL',
  GARAGECONTROL: 'GARAGECONTROL'
});

export const isInactiveAccountLink = (planInfo = {}) => {
  //testing
  // true
  return planInfo['linkingStatus'] && planInfo['linkingStatus'].toUpperCase() === INACTIVE;
};

export const isGarageControl = (planInfo = {}) => {
  let isGarageControlSubCategoryType =
    (planInfo['subCategory'] && planInfo['subCategory'] === CHAMBERLAIN_SUB_TYPES.GARAGECONTROL_TRIAL) ||
    planInfo['subCategory'] === CHAMBERLAIN_SUB_TYPES.GARAGECONTROL;
  return isGarageControlSubCategoryType;
};

export const statusCapitalizeFirstLetter = (status, planInfo = {}) => {
  status = status || '';

  // Prioritize displaying linking status over purchase status
  if (planInfo && isGarageControl(planInfo) && isInactiveAccountLink(planInfo)) {
    return translator.t('chamberlain.linkAccountStatus');
  }

  if (status === 'ACTIVE') {
    return translator.t('buyplans_purchase_status_active');
  } else if (status === SCHEDULED_FOR_PURCHASE || status === PENDING || status === PURCHASED || status === PURCHASE) {
    return translator.t('buyplans_purchase_status_pending');
  } else {
    return translator.t('buyplans_purchase_status_expired');
  }
};

export const statusIcon = (status, planInfo = {}) => {
  if (status.length > 0) {
    // Prioritize displaying linking status over purchase status
    if (planInfo && isGarageControl(planInfo) && isInactiveAccountLink(planInfo)) {
      return <img src={statusPending} className='estoreStatusIcon' alt='' />;
    }

    if (status === 'ACTIVE') {
      return <img src={statusActive} className='estoreStatusIcon' alt='' />;
    } else if (status === SCHEDULED_FOR_PURCHASE || status === PENDING || status === PURCHASED || status === PURCHASE) {
      return <img src={statusPending} className='estoreStatusIcon' alt='' />;
    } else {
      return <img src={statusExpired} className='estoreStatusIcon' alt='' />;
    }
  }
};

export const oneTimeOrRecurringText = (pricingType, uptoPeriodType, timePeriodType, uptoPeriod) => {
  let oneTimeOrRecurringText = '';
  if (pricingType === ONE_TIME && uptoPeriodType === YEARS) {
    oneTimeOrRecurringText = uptoPeriod + translator.t('buyplans_onetime_pricing_plan_years');
  } else if (pricingType === ONE_TIME && uptoPeriodType === MONTHS) {
    oneTimeOrRecurringText = uptoPeriod + translator.t('buyplans_common_pricing_plan_years_recurring_monthly');
  } else if (pricingType === RECURRING && timePeriodType === YEARLY) {
    if (uptoPeriod > 0) {
      oneTimeOrRecurringText = uptoPeriod + translator.t('buyplans_common_pricing_plan_years_recurring_annually');
    } else {
      oneTimeOrRecurringText = translator.t('buyplans_common_pricing_plan_recurring_annually');
    }
  } else {
    oneTimeOrRecurringText = translator.t('buyplans_common_pricing_plan_recurring_monthly');
  }
  return oneTimeOrRecurringText;
};

export const getDisclaimerText = (disclaimerNumber, marketingConfigurationId, marketingConfigurations) => {
  let disclaimer = '';
  console.log('marketingConfigurations:::' + JSON.stringify(marketingConfigurations));
  let marketingConfiguration = marketingConfigurations.filter(mc => mc.configurationId === marketingConfigurationId)[0];
  if (marketingConfiguration) {
    disclaimer = marketingConfiguration.disclaimerTextList.filter(
      disclaimerText => disclaimerText.disclaimerNumber === disclaimerNumber
    )[0];
  }

  if (disclaimer) {
    console.log('disclaimer Text:::' + disclaimer.disclaimerNumber + '.' + disclaimer.disclaimerText);
    return disclaimer.disclaimerNumber + '.' + disclaimer.disclaimerText;
  }
};

export const getPlanMarketingConfiguration = (
  marketingConfigurations,
  marketingConfigurationId,
  marketingProductIds
) => {
  let marketingPlanConfiguration = {};
  let marketingProducts = [];
  let marketingConfiguration = marketingConfigurations.filter(mc => mc.configurationId === marketingConfigurationId)[0];

  marketingProductIds.forEach(marketingProductId => {
    let marketingProduct = marketingConfiguration.marketingProducts.filter(
      mp => mp.marketingProductId === marketingProductId
    )[0];
    if (marketingProduct) {
      marketingProducts.push({
        title: marketingProduct.title,
        imageURL: marketingProduct.imageURL,
        disclaimerFlag: marketingProduct.disclaimerFlag,
        description: marketingProduct.description,
        disclaimerNumber: marketingProduct.disclaimerNumber
      });
    }
  });

  marketingPlanConfiguration = {
    title: marketingConfiguration.title,
    description: marketingConfiguration.description,
    additionalDescription: marketingConfiguration.additionalDescription,
    providerLogo: marketingConfiguration.summary.providerLogo,
    pricingDisclaimer: marketingConfiguration.pricingDisclaimer,
    articleDisclaimerFlag: marketingConfiguration.articleDisclaimerFlag,
    articleDisclaimerNumber: marketingConfiguration.articleDisclaimerNumber,
    disclaimerText: marketingConfiguration.disclaimerText,
    subCategory: marketingConfiguration.subCategory,
    configurationId: marketingConfiguration.configurationId,
    marketingProducts: marketingProducts,
    pricingDisclaimerList: marketingConfiguration.pricingDisclaimerList,
    additionalDisclaimerList: marketingConfiguration.additionalDisclaimerList,
    disclaimerTextList: marketingConfiguration.disclaimerTextList,
    consentCheckbox: marketingConfiguration.consentCheckbox,
    providerName: marketingConfiguration.summary.providerName
  };

  console.log('marketingPlanConfiguration :::::', JSON.stringify(marketingPlanConfiguration, null, 1));
  return marketingPlanConfiguration;
};

export const getCurrentPlanSelectedMarketingConfiguration = (currentPlanSelected, marketingConfigurations) => {
  let marketingPlanConfiguration = {};
  let marketingProducts = [];
  let marketingConfiguration = marketingConfigurations.filter(
    marketingConfiguration => marketingConfiguration.configurationId === currentPlanSelected.marketingConfigurationId
  )[0];

  currentPlanSelected.marketingProductIds &&
    currentPlanSelected.marketingProductIds.forEach(marketingProductId => {
      let marketingProduct = marketingConfiguration.marketingProducts.filter(
        marketingProduct => marketingProduct.marketingProductId === marketingProductId
      )[0];
      if (marketingProduct) {
        marketingProducts.push({
          title: marketingProduct.title,
          imageURL: marketingProduct.imageURL,
          disclaimerFlag: marketingProduct.disclaimerFlag,
          description: marketingProduct.description,
          disclaimerNumber: marketingProduct.disclaimerNumber
        });
      }
    });

  marketingPlanConfiguration = {
    title: marketingConfiguration.title,
    description: marketingConfiguration.description,
    additionalDescription: marketingConfiguration.additionalDescription,
    providerLogo: marketingConfiguration.summary.providerLogo,
    pricingDisclaimer: marketingConfiguration.pricingDisclaimer,
    articleDisclaimerFlag: marketingConfiguration.articleDisclaimerFlag,
    articleDisclaimerNumber: marketingConfiguration.articleDisclaimerNumber,
    disclaimerText: marketingConfiguration.disclaimerText,
    subCategory: marketingConfiguration.subCategory,
    configurationId: marketingConfiguration.configurationId,
    marketingProducts: marketingProducts,
    pricingDisclaimerList: marketingConfiguration.pricingDisclaimerList,
    additionalDisclaimerList: marketingConfiguration.additionalDisclaimerList,
    disclaimerTextList: marketingConfiguration.disclaimerTextList,
    provider: currentPlanSelected.provider,
    linkingStatus: currentPlanSelected['linkingStatus']
  };

  console.log('marketingPlanConfiguration :::::', JSON.stringify(marketingPlanConfiguration, null, 1));
  return marketingPlanConfiguration;
};

export const getPlanPricingList = planPricingInfo => {
  let planPricingList = [];

  planPricingInfo.forEach((planPricing, index) => {
    planPricing.package.pricingOptions.forEach(pricingOption => {
      planPricingList.push({
        sku: planPricing.package.sku,
        price: pricingOption.price,
        priceOptionId: pricingOption.priceOptionId,
        currency: pricingOption.currency,
        uptoPeriod: pricingOption.uptoPeriod,
        timePeriodType: pricingOption.timePeriodType,
        endCondition: pricingOption.endCondition,
        uptoPeriodType: pricingOption.uptoPeriodType,
        pricingType: pricingOption.pricingType,
        subCategory: planPricing.subCategory,
        configurationId: pricingOption.marketingConfigurationId
      });
    });
  });
  console.log('planPricingList :::::', JSON.stringify(planPricingList, null, 1));
  return planPricingList;
};

export const getCartItemsList = (cartItems, marketingConfigurations) => {
  let cartItemsList = [];
  console.log('cart Items in util::' + JSON.stringify(cartItems));
  console.log('marketing Data in util::' + JSON.stringify(marketingConfigurations));
  cartItems.forEach((cartItem, index) => {
    let marketingConfiguration = marketingConfigurations.filter(
      marketingConfiguration => marketingConfiguration.subCategory === cartItem.subcategory
    )[0];

    if (marketingConfiguration) {
      cartItemsList.push({
        sku: cartItem.sku,
        price: cartItem.price,
        priceOptionId: cartItem.priceOptionId,
        currency: cartItem.currency,
        uptoPeriod: cartItem.uptoPeriod,
        timePeriodType: cartItem.timePeriodType,
        endCondition: cartItem.endCondition,
        uptoPeriodType: cartItem.uptoPeriodType,
        pricingType: cartItem.pricingType,
        providerLogo: marketingConfiguration.summary.providerLogo,
        title: marketingConfiguration.title,
        cartId: cartItem.cartId,
        pricingDisclaimerList: marketingConfiguration.pricingDisclaimerList,
        additionalDisclaimerList: marketingConfiguration.additionalDisclaimerList,
        cartTermsOfService: marketingConfiguration.cartTermsOfService,
        subCategory: cartItem.subcategory,
        itemTax: cartItem.itemTax,
        isTaxIncludedInPrice: cartItem.isTaxIncludedInPrice !== 'undefined' ? false : cartItem.isTaxIncludedInPrice
      });
    }
  });

  console.log('cartItemsList :::::', JSON.stringify(cartItemsList, null, 1));
  return cartItemsList;
};

export const getCheckoutDisclaimers = (cartItems, marketingCartDisclaimers, marketingConfigurations) => {
  let checkoutDisclaimers = [];

  cartItems &&
    cartItems.forEach((cartItem, index) => {
      let variables = findIn('additionalInfo.cartDisclaimers.0.variables', cartItem);

      let marketingConfiguration = marketingConfigurations.filter(
        marketingConfiguration => marketingConfiguration.configurationId === cartItem.marketingConfigurationId
      )[0];

      let cartDisclaimers = cartItem.additionalInfo && cartItem.additionalInfo.cartDisclaimers;
      cartDisclaimers &&
        cartDisclaimers.forEach(cartDisclaimer => {
          let disclaimer = marketingCartDisclaimers.filter(
            marketingCartDisclaimer => cartDisclaimer.disclaimerId === marketingCartDisclaimer.disclaimerId
          )[0];

          if (findIn('disclaimerText', disclaimer)) {
            let disclaimerText = updateDisclaimerText(disclaimer, variables);

            if (findIn('uptoPeriodType', cartItem) === MONTHS) {
              disclaimerText = disclaimerText.replaceAll('year', 'month');
            }

            checkoutDisclaimers.push({
              disclaimerCopyText: disclaimerText,
              title: marketingConfiguration.title
            });
          }
        });
    });
  console.log('checkoutDisclaimers :::::', JSON.stringify(checkoutDisclaimers, null, 1));
  return checkoutDisclaimers;
};

const updateDisclaimerText = (disclaimer, variables) => {
  let disclaimerUpdated = findIn('disclaimerText', disclaimer);
  variables.map(variable => {
    if (disclaimerUpdated.includes(variable.placeHolderName)) {
      disclaimerUpdated = disclaimerUpdated.replace(variable.placeHolderName, variable.placeHolderValue);
    }
  });
  return disclaimerUpdated;
};

export const prePaidExists = cartItems => {
  let prePaidExists = false;
  let cartItem = cartItems.filter(cartItem => cartItem.subcategory === PREPAID)[0];
  if (cartItem) {
    prePaidExists = true;
  }
  return prePaidExists;
};

export const selectedPriceOption = (planPricingList, cartItems) => {
  let selectedPriceOptionId = 0;
  planPricingList.forEach((planPricing, index) => {
    cartItems.forEach(cartItem => {
      if (planPricing.priceOptionId === cartItem.priceOptionId) {
        selectedPriceOptionId = planPricing.priceOptionId;
      }
      console.log('selectedPriceOptionId :::::', JSON.stringify(selectedPriceOptionId, null, 1));
    });
  });

  return selectedPriceOptionId;
};

export const getCurrentPlans = (subscribedPlans, marketingConfigurations) => {
  let currentPlans = [];

  subscribedPlans.forEach(subscribedPlan => {
    let marketingConfiguration = marketingConfigurations.filter(
      marketingConfiguration => marketingConfiguration.configurationId === subscribedPlan.marketingConfigurationId
    )[0];

    let currentPlanContent = (marketingConfiguration && marketingConfiguration.currentPlanContent) || {};

    let fallbackTitle = findIn('title', marketingConfiguration) || findIn('package.name', subscribedPlan) || '';

    let isThereCurrentPlanTitle = currentPlanContent.hasOwnProperty('title');
    let currentPlanTitle = isThereCurrentPlanTitle ? currentPlanContent.title : null;
    currentPlanTitle = currentPlanTitle === null ? fallbackTitle : currentPlanTitle;

    if (marketingConfiguration) {
      currentPlans.push({
        icon: marketingConfiguration.summary.icon,
        title: currentPlanTitle,
        status: subscribedPlan.status,
        activatedOn: subscribedPlan.activatedOn,
        expirationDate: subscribedPlan.expirationDate,
        currentPlanContent: { ...currentPlanContent },
        shortDescription:
          marketingConfiguration.summary.shortDescription !== ''
            ? marketingConfiguration.summary.shortDescription
            : subscribedPlan.package.shortDescription,
        providerLogo: marketingConfiguration.summary.providerLogo,
        providerName: marketingConfiguration.summary.providerName,
        marketingConfigurationId: subscribedPlan.marketingConfigurationId,
        marketingProductIds: subscribedPlan.marketingProductIds,
        articleDisclaimerFlag: marketingConfiguration.articleDisclaimerFlag,
        articleDisclaimerNumber: marketingConfiguration.articleDisclaimerNumber,
        marketingProductsFound: subscribedPlan.marketingProductIds.length > 0 ? true : false,
        planType: subscribedPlan.planType,
        category: subscribedPlan.category,
        subCategory: subscribedPlan.subCategory,
        linkingStatus: subscribedPlan['linkingStatus']
      });
    }
  });
  console.log('currentPlans :::::', JSON.stringify(currentPlans, null, 1));
  return currentPlans;
};

export const getAdditionalPlans = (allPlans, marketingConfigurations) => {
  let additionalPlans = [];

  allPlans.forEach(allPlan => {
    let features = [];
    let marketingConfiguration = marketingConfigurations.filter(
      marketingConfiguration => marketingConfiguration.configurationId === allPlan.marketingConfigurationId
    )[0];

    allPlan.marketingFeatureIds.forEach(featureId => {
      let feature = marketingConfiguration.summary.features.filter(feature => feature.featureId === featureId)[0];
      if (feature) {
        features.push({
          featureText: feature.featureText,
          disclaimerFlag: feature.disclaimerFlag,
          disclaimerNumber: feature.disclaimerNumber
        });
      }
    });
    console.log('features :::::', JSON.stringify(features, null, 1));

    let isThereAPlanTitle = allPlan.hasOwnProperty('title');
    let planTitle = null;
    if (isThereAPlanTitle) {
      planTitle = allPlan.title;
    }

    if (marketingConfiguration) {
      additionalPlans.push({
        icon: marketingConfiguration.summary.icon,
        planTitle,
        title: marketingConfiguration.title,
        shortDescription: marketingConfiguration.summary.shortDescription,
        providerLogo: marketingConfiguration.summary.providerLogo,
        provider: allPlan.provider.name,
        features: features,
        category: allPlan.category,
        subCategory: allPlan.subCategory,
        marketingConfigurationId: allPlan.marketingConfigurationId,
        purchaseable: allPlan.purchaseable,
        marketingProductIds: allPlan.marketingProductIds,
        submitButtonText: marketingConfiguration.summary.submitButtonText,
        purchaseBlockReason: allPlan.purchaseBlockReason,
        articleDisclaimerFlag: marketingConfiguration.articleDisclaimerFlag,
        articleDisclaimerNumber: marketingConfiguration.articleDisclaimerNumber,
        marketingProductsFound: marketingConfiguration.marketingProducts.length > 0 ? true : false
      });
    }
  });

  return additionalPlans;
};

export const getStoredPayments = storedPaymentOptions => {
  console.log('storedPaymentOptions :::::', JSON.stringify(storedPaymentOptions, null, 1));
  let storedPayments = [];
  storedPaymentOptions.forEach((storedPaymentOption, index) => {
    storedPayments.push({
      name: storedPaymentOption.name,
      carrierNumber: '************' + storedPaymentOption.carrierNumber.substr(-4),
      reference: storedPaymentOption.reference,
      inputId: 'storedPaymentOption' + index,
      showCVVId: index,
      showCVV: false
    });
  });
  console.log('storedPayments :::::', JSON.stringify(storedPayments, null, 1));
  return storedPayments;
};

export const getAvailablePaymentOptions = availablePaymentOptions => {
  console.log('availablePaymentOptions :::::', JSON.stringify(availablePaymentOptions, null, 1));
  let avaliablePaymentTypes = [];
  availablePaymentOptions.forEach((availablePaymentOption, index) => {
    avaliablePaymentTypes.push({
      name: availablePaymentOption.name,
      code: availablePaymentOption.code
    });
  });
  console.log('avaliablePaymentTypes :::::', JSON.stringify(avaliablePaymentTypes, null, 1));
  return avaliablePaymentTypes;
};

export const getTotalPrice = (price, itemTax) => {
  let totalPrice;
  if (typeof itemTax !== 'undefined') {
    totalPrice = price + itemTax;
  } else {
    totalPrice = price;
  }

  console.log('totalPrice :::::', totalPrice);
  return totalPrice.toFixed(2);
};

export const getCurrencySymbol = currency => {
  if (currency === USD) {
    return '$';
  }
};

export const getErrorCodeDesc = errorCode => {
  let errorDesc;

  console.log('errorCode ::' + errorCode);

  const PURCHASE_ERROR_CODES = ['3011', '3012', '3013', '3014', '3015', '3017', '3018', '3020', '3021'];
  const CUBIC_ERROR_CODES = ['2016', '2023', '2030', '2031', '2032'];
  const REFUND_ERROR_CODES = ['3019'];
  const CREDIT_CARD_VERIFY_ERROR_CODES = ['3016'];
  const CUBIC_TRIALPLAN_ERROR_CODES = ['2011', '2017', '2020'];
  const CUBIC_POSTPAID_ERROR_CODES = ['2013', '2019', '2022'];
  const CUBIC_PREPAID_ERROR_CODES = ['2012', '2018', '2021'];

  if (PURCHASE_ERROR_CODES.includes(errorCode)) {
    errorDesc = translator.t('buyplans_purchase_error_message');
  } else if (CUBIC_ERROR_CODES.includes(errorCode)) {
    errorDesc = translator.t('buyplans_cubic_error_message');
  } else if (REFUND_ERROR_CODES.includes(errorCode)) {
    errorDesc = translator.t('buyplans_refund_error_message');
  } else if (CREDIT_CARD_VERIFY_ERROR_CODES.includes(errorCode)) {
    errorDesc = translator.t('buyplans_purchase_verify_creditcard_error_message');
  } else if (CUBIC_TRIALPLAN_ERROR_CODES.includes(errorCode)) {
    errorDesc = translator.t('buyplans_cubic_trialplan_error_message');
  } else if (CUBIC_POSTPAID_ERROR_CODES.includes(errorCode)) {
    errorDesc = translator.t('buyplans_cubic_postpaid_error_message');
  } else if (CUBIC_PREPAID_ERROR_CODES.includes(errorCode)) {
    errorDesc = translator.t('buyplans_cubic_prepaid_error_message');
  } else {
    errorDesc = translator.t('buyplans_cubic_error_message');
  }

  console.log('errorDesc ::' + errorDesc);

  return errorDesc;
};

export const getCurrencySymbolPrice = (currency, price) => {
  if (currency === USD) {
    return '$' + price;
  } else {
    return price;
  }
};

export const SUBCATEGORY_TYPES = Object.freeze({
  TRIAL_IN_CURRENT_PLAN: 'TRIAL_IN_CURRENT_PLAN',
  ALEXA_WEBAPP: 'ALEXA_WEBAPP',
  PTP: 'ptp',
  UBI: 'UBI',
  TRIAL: 'TRIAL',
  GARAGE_CONTROL_TRIAL: 'GARAGECONTROL_TRIAL',
  GARAGE_CONTROL: 'GARAGECONTROL',
  PREPAID: 'PREPAID',
  POSTPAID: 'POSTPAID',
  SAFETY_FREE: 'SAFETY_FREE'
});

export const CATEGORY_TYPES = Object.freeze({
  DATA: 'DATA'
});

export const PURCHASE_BLOCK_REASON = Object.freeze({
  ACTIVATE_TRIAL_FIRST: 'ACTIVATE_TRIAL_FIRST'
});

export const checkTrialPlanExists = additionalPlans => {
  let trialPlanExists = false;
  let trialPlan =
    Array.isArray(additionalPlans) &&
    additionalPlans.filter(
      additionalPlan =>
        additionalPlan.category.toUpperCase() === CATEGORY_TYPES.DATA &&
        additionalPlan.subCategory.toUpperCase() === SUBCATEGORY_TYPES.TRIAL
    )[0];
  if (trialPlan) {
    trialPlanExists = true;
  }
  return trialPlanExists;
};

export const getTrialPlan = additionalPlans => {
  let trialPlan =
    Array.isArray(additionalPlans) &&
    additionalPlans.filter(
      additionalPlan =>
        additionalPlan.category.toUpperCase() === CATEGORY_TYPES.DATA &&
        additionalPlan.subCategory.toUpperCase() === SUBCATEGORY_TYPES.TRIAL
    )[0];
  return trialPlan;
};
