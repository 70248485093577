export const GET_RANDOM_DAD_JOKE = 'GET_RANDOM_DAD_JOKE';
export const GET_RANDOM_DAD_JOKE_SUCCESS = 'GET_RANDOM_DAD_JOKE_success';
export const GET_RANDOM_DAD_JOKE_FAILURE = 'GET_RANDOM_DAD_JOKE_failure';

export const GET_USER_INFO = 'get_user_info';
export const GET_USER_INFO_SUCCESS = 'get_user_info_success';
export const GET_USER_INFO_FAILURE = 'get_user_info_failure';

//GET CARNET USER PRIVILEGES
export const GET_CARNET_USER_PRIVILEGES = 'get_carnet_user_privileges';
export const GET_CARNET_USER_PRIVILEGES_SUCCESS = 'get_carnet_user_privileges_success';
export const GET_CARNET_USER_PRIVILEGES_FAILURE = 'get_carnet_user_privileges_failure';

//GET CAR NICK NAME
export const GET_CAR_NICK_NAME = 'get_car_nick_name';
export const GET_CAR_NICK_NAME_SUCCESS = 'get_car_nick_name_success';
export const GET_CAR_NICK_NAME_FAILURE = 'get_car_nick_name_failure';

//GET PREFERRED DEALER
export const GET_PREFERRED_DEALER = 'get_preferred_dealer';
export const GET_PREFERRED_DEALER_SUCCESS = 'get_preferred_dealer_success';
export const GET_PREFERRED_DEALER_FAILURE = 'get_preferred_dealer_failure';

//GET VEHICLE HEALTH BY VEHICLEID
export const GET_VEHICLE_HEALTH_BY_VEHICLEID = 'get_vehicle_health_by_vehicleid';
export const GET_VEHICLE_HEALTH_BY_VEHICLEID_SUCCESS = 'get_vehicle_health_by_vehicleid_success';
export const GET_VEHICLE_HEALTH_BY_VEHICLEID_FAILURE = 'get_vehicle_health_by_vehicleid_failure';

//REFRESH VEHICLE HEALTH
export const REFRESH_VEHICLE_HEALTH = 'refresh_vehicle_health';
export const REFRESH_VEHICLE_HEALTH_SUCCESS = 'refresh_vehicle_health_success';
export const REFRESH_VEHICLE_HEALTH_FAILURE = 'refresh_vehicle_health_failure';

// DELETE Vehicle History EVENT

export const DELETE_VEHICLE_HISTORY_EVENT = 'delete_vehicle_history_event';
export const DELETE_VEHICLE_HISTORY_EVENT_SUCCESS = 'delete_vehicle_history_event_success';
export const DELETE_VEHICLE_HISTORY_EVENT_FAILURE = 'delete_vehicle_history_event_failure';

// GET Vehicle History

export const GET_VEHICLE_HISTORY_INFO = 'get_vehicle_history_info';
export const GET_VEHICLE_HISTORY_INFO_SUCCESS = 'get_vehicle_history_info_success';
export const GET_VEHICLE_HISTORY_INFO_FAILURE = 'get_vehicle_history_info_failure';

// GET BUY PLANS LANDING Info

export const GET_STORE_PLANS_AND_CART_INFO = 'get_store_plans_and_cart_info';
export const GET_STORE_PLANS_AND_CART_INFO_SUCCESS = 'get_store_plans_and_cart_info_success';
export const GET_STORE_PLANS_AND_CART_INFO_FAILURE = 'get_store_plans_and_cart_info_failure';

//Get Customer Account Details
export const GET_CUSTOMER_ACCOUNT_DETAILS = 'get_customer_account_details';
export const GET_CUSTOMER_ACCOUNT_DETAILS_SUCCESS = 'get_customer_account_details_success';
export const GET_CUSTOMER_ACCOUNT_DETAILS_FAILURE = 'get_customer_account_details_failure';

//CRUD for Users chosen Security Questions
export const GET_USER_SECURITY_QUESTIONS = 'get_user_security_questions';
export const GET_USER_SECURITY_QUESTIONS_SUCCESS = 'get_user_security_questions_success';
export const GET_USER_SECURITY_QUESTIONS_FAILURE = 'get_user_security_questions_failure';

export const PUT_USER_SECURITY_QUESTIONS = 'put_user_security_questions';
export const PUT_USER_SECURITY_QUESTIONS_SUCCESS = 'put_user_security_questions_success';
export const PUT_USER_SECURITY_QUESTIONS_FAILURE = 'put_user_security_questions_failure';

// Get Carnet Customer data States, Countries, Security Questions dropdowns
export const GET_CARNET_CUSTOMER_STATIC_DATA = 'get_carnet_customer_static_data';
export const GET_CARNET_CUSTOMER_STATIC_DATA_SUCCESS = 'get_carnet_customer_static_data_success';
export const GET_CARNET_CUSTOMER_STATIC_DATA_FAILURE = 'get_carnet_customer_static_data_failure';

//GET DEALER URL's
export const GET_DEALER_URLS = 'get_dealer_urls';
export const GET_DEALER_URLS_SUCCESS = 'get_dealer_urls_success';
export const GET_DEALER_URLS_FAILURE = 'get_dealer_urls_failure';

//Get Vehicle Health by ServiceKey
export const GET_VEHICLE_HEALTH_BY_SERVICEKEY = 'get_vehicle_health_by_servicekey';
export const GET_VEHICLE_HEALTH_BY_SERVICEKEY_SUCCESS = 'get_vehicle_health_by_servicekey_success';
export const GET_VEHICLE_HEALTH_BY_SERVICEKEY_FAILURE = 'get_vehicle_health_by_servicekey_failure';

//Send VHR Report by ServiceKey
export const SEND_VHR_REPORT_BY_SERVICEKEY = 'send_vhr_report_by_servicekey';
export const SEND_VHR_REPORT_BY_SERVICEKEY_SUCCESS = 'send_vhr_report_by_servicekey_success';
export const SEND_VHR_REPORT_BY_SERVICEKEY_FAILURE = 'send_vhr_report_by_servicekey_failure';

//Get VHR Customer Preferences
export const GET_VHR_CUSTOMER_PREFERENCES = 'get_vhr_customer_preferences';
export const GET_VHR_CUSTOMER_PREFERENCES_SUCCESS = 'get_vhr_customer_preferences_success';
export const GET_VHR_CUSTOMER_PREFERENCES_FAILURE = 'get_vhr_customer_preferences_failure';

//Get SirisXM Subscription Status
export const GET_SIRISXM_SUBSCRIPTION_STATUS = 'get_sirisxm_subscription_status';
export const GET_SIRISXM_SUBSCRIPTION_STATUS_SUCCESS = 'get_sirisxm_subscription_status_success';
export const GET_SIRISXM_SUBSCRIPTION_STATUS_FAILUE = 'get_sirisxm_subscription_status_failure';

//Update Customer Details
export const UPDATE_CUSTOMER_DETAILS = 'update_customer_details';
export const UPDATE_CUSTOMER_DETAILS_SUCCESS = 'update_customer_details_success';
export const UPDATE_CUSTOMER_DETAILS_FAILURE = 'update_customer_details_failure';

//Update VHR Prefereneces
export const UPDATE_VHR_CUSTOMER_PREFERENCES = 'update_vhr_customer_preferences';
export const UPDATE_VHR_CUSTOMER_PREFERENCES_SUCCESS = 'update_vhr_customer_preferences_success';
export const UPDATE_VHR_CUSTOMER_PREFERENCES_FAILURE = 'update_vhr_customer_preferences_failure';

export const UPDATE_ACCT_AND_VEHICLE_PREFS = 'update_acct_and_vehicle_prefs';
export const UPDATE_ACCT_AND_VEHICLE_PREFS_SUCCESS = 'update_acct_and_vehicle_prefs_success';
export const UPDATE_ACCT_AND_VEHICLE_PREFS_FAILURE = 'update_acct_and_vehicle_prefs_failure';

export const SET_USER_VEHICLE_CONTEXT = 'set_user_vehicle_context';

export const GET_STORE_PLAN_PRICING_INFO = 'get_store_plan_pricing_info';
export const GET_STORE_PLAN_PRICING_INFO_SUCCESS = 'get_store_plan_pricing_info_success';
export const GET_STORE_PLAN_PRICING_INFO_FAILURE = 'get_store_plan_pricing_info_failure';

export const GET_STORE_CART_ITEMS = 'get_store_cart_items';
export const GET_STORE_CART_ITEMS_SUCCESS = 'get_store_cart_items_success';
export const GET_STORE_CART_ITEMS_FAILURE = 'get_store_cart_items_failure';

export const GET_CURRENT_WALLET_DETAILS = 'get_current_wallet_details';
export const GET_CURRENT_WALLET_DETAILS_SUCCESS = 'get_current_wallet_details_success';
export const GET_CURRENT_WALLET_DETAILS_FAILURE = 'get_current_wallet_details_failure';

//GET Message Center Info

export const GET_MESSAGE_CENTER_INFO = 'get_message_center_info';
export const GET_MESSAGE_CENTER_INFO_SUCCESS = 'get_message_center_info_success';
export const GET_MESSAGE_CENTER_INFO_FAILURE = 'get_message_center_info_failure';

// DELETE  Message Center EVENT

export const DELETE_MESSAGE_CENTER_EVENT = 'delete_message_center_event';
export const DELETE_MESSAGE_CENTER_EVENT_SUCCESS = 'delete_message_center_event_success';
export const DELETE_MESSAGE_CENTER_EVENT_FAILURE = 'delete_message_center_event_failure';

// DELETE ALL  Message Center EVENT

export const DELETE_ALL_MESSAGE_CENTER_EVENT = 'delete_All_message_center_event';
export const DELETE_ALL_MESSAGE_CENTER_EVENT_SUCCESS = 'delete_All_message_center_event_success';
export const DELETE_ALL_MESSAGE_CENTER_EVENT_FAILURE = 'delete_All_message_center_event_failure';

//Read ALL  Message Center EVENT
export const READ_MESSAGE_CENTER_EVENT = 'read_message_center_event';
export const READ_MESSAGE_CENTER_EVENT_SUCCESS = 'read_message_center_event_success';
export const READ_MESSAGE_CENTER_EVENT_FAILURE = 'read_message_center_event_failure';
