import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Player } from 'video-react';
import academy from '../../../assets/faq/academy.jpg';
import { getCwpMeta } from '../../../providers/cwpMetaProvider';
import './Video-react.scss';

export class VideoDisplay extends Component {
  state = {
    videoVisible: false
  };

  toggleopen = id => {
    this.setState({ videoVisible: !this.state.videoVisible });
  };

  componentDidMount = async () => {
    let metaResponse = await getCwpMeta();
    let videoContentURL = metaResponse.static.content.urls.videos;
    this.setState({
      videoContentURL
    });
  };

  render () {
    return (
      <div>
        <a href='javascript:void(0);' onClick={this.toggleopen}>
          <img src={academy} width='370px' height='210px' />
        </a>
        <span className='video-title'>Car-Net Mobile App Tour</span>
        <Modal isOpen={this.state.videoVisible} toggle={this.toggleopen} centered={true} className='video'>
          <ModalHeader toggle={this.toggleopen}>Car-Net Mobile App Tour</ModalHeader>
          <ModalBody>
            {this.state.videoContentURL && (
              <Player playsInline src={`${this.state.videoContentURL}/CarNetAppTour.mp4`} />
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default VideoDisplay;
