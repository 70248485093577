import React, { Component } from 'react';
import Faq from 'react-faq-component';
import '../faqCanada/faqCA.scss';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import goback from '../../../assets/faq/goback_fr.png';
import { historyBack } from './faqCAhome';
import { setTitle } from '../../../providers/documentTitleProvider';

export default class NavigateFaqCA extends Component {
  componentDidMount () {
    setTitle('document_titles.faq.poi');
  }
  render () {
    let translator = getLocaleTranslator();

    return (
      <div className='faqCAheader' role='main' id='main-content'>
        <div className='goback'>
          <span onClick={historyBack}>
            <img src={goback} alt='viewfaq' width='100'></img>
          </span>
        </div>
        <div className='message-header'>
          <div className='td-ms-header'>
            <h1>{translator.t('navigate_title')}</h1>
          </div>
        </div>
        <Faq data={data} />
      </div>
    );
  }
}

const data = {
  rows: [
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          Qu’est-ce que l’onglet de navigation sur le portail Web ou l’application mobile Car-NetMD de Volkswagen?{' '}
        </div>
      ),
      content: ` 
      <span class='mdlyr'>Années-modèles 2022 et années ultérieures:</span>À partir de la page de navigation du portail Web ou de l’application mobile Car-NetMD de Volkswagen, vous pouvez envoyer des points d’intérêt directement à votre véhicule. Votre véhicule doit être équipé d’un système de navigation installé en usine pour profiter de ce service. À la page de navigation, vous pouvez chercher et sélectionner une destination. Une fois que vous avez choisi votre point d’intérêt, appuyez sur « Send to my VW » (envoyer à mon véhicule VW) pour que l’adresse soit envoyée directement au système de navigation de votre véhicule. Si elle n’a pas été envoyée, le bouton « Resend to my VW » (envoyer de nouveau à mon véhicule VW) devrait apparaître. Appuyez sur ce bouton pour envoyer de nouveau le point d’intérêt sélectionné précédemment. 
       `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Que signifient les différents états dans l’onglet de navigation?{' '}
        </div>
      ),
      content: ` 
      <span class='mdlyr'>Années-modèles 2022 et années ultérieures :</span>
      <li> « Successful » (succès) – Le point d’intérêt a été envoyé avec succès à votre véhicule Volkswagen.</li></br>
      <li>« Pending » (en attente) – Le point d’intérêt a été envoyé et nous attendons que le système de navigation de votre véhicule l’accepte et le télécharge.</li></br>
      <li>« Failed » (échec) – Le point d’intérêt n’a pas été envoyé au système de navigation de votre véhicule et vous devriez essayer de l’envoyer de nouveau.</li></br>`
    }
  ]
};

export { NavigateFaqCA };
