import React from 'react';
import PropTypes from 'prop-types';
import { getPriceDescription } from '../buyPlansUtil';
import Price from '../components/Price';
import AutoLabelCheckbox from '../components/AutoLabelCheckbox';

export default class CartArticle extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    translator: PropTypes.object.isRequired,
    contentURL: PropTypes.string.isRequired,
    userCountry: PropTypes.string.isRequired,
    configuration: PropTypes.object.isRequired,
    onClickRemove: PropTypes.func.isRequired,
    cartTosAcceptances: PropTypes.array
  };

  state = {
    unClickedLinks: []
  };

  componentDidMount () {
    this.initData();
  }

  initData = async () => {
    const cartTosAcceptances = this.props.cartTosAcceptances;

    if (Array.isArray(cartTosAcceptances)) {
      const unClickedLinks = AutoLabelCheckbox.getInnerUrls(cartTosAcceptances);

      this.setState({ unClickedLinks });
    }
  };

  onClickHyperLink = href => {
    const unClickedLinks = this.state.unClickedLinks.filter(unCLickedLink => unCLickedLink !== href);

    this.setState({ unClickedLinks });
  };

  render () {
    const { item, configuration, contentURL, userCountry, translator, onClickRemove } = this.props;
    const cartTosAcceptances = Array.isArray(this.props.cartTosAcceptances) ? this.props.cartTosAcceptances : [];

    return (
      <section className='cartItem d-flex flex-column my-3 p-3'>
        <section className='d-flex justify-content-between'>
          <article className='d-flex flex-column flex-grow-1 align-items-start'>
            {configuration?.summary?.providerLogo && (
              <img src={contentURL + configuration?.summary?.providerLogo} alt={configuration?.summary?.providerLogo} />
            )}
            <strong className='h5 font-weight-bold mt-3'>{configuration?.summary?.title}</strong>
            <span className='small'>{getPriceDescription(translator, item)}</span>
            {Array.isArray(configuration?.pricingDisclaimerList) &&
              configuration.pricingDisclaimerList.map((pd, index) => (
                <span key={`pricingDisclaimer_${index}`} className='small text-muted'>
                  {pd.disclaimerText}
                </span>
              ))}
            <button type='button' className='carnetLink delete p-0 my-3' onClick={() => onClickRemove()}>
              {translator.t('buyplans_view_cart_remove_from_cart')}
            </button>
          </article>
          <article className='d-flex flex-column text-right'>
            <span className='h1 ml-4'>
              <Price currency={item.currency} value={item.price?.toFixed(2)} />
            </span>
            <span className='small text-nowrap'>
              {item.isTaxIncludedInPrice
                ? translator.t('buyplans_view_cart_taxes_included')
                : translator.t('buyplans_view_cart_plus_applicable_taxes')}
            </span>
          </article>
        </section>
        {cartTosAcceptances.map(tosAcceptance => (
          <AutoLabelCheckbox
            key={`CartTosCheckbox-${tosAcceptance.checkboxNumber}`}
            value={tosAcceptance}
            onClickHyperLink={this.onClickHyperLink}
            unClickedLinks={this.state.unClickedLinks}
            disableCheckboxUntilAllLinksClicked={userCountry === 'CA'}
            translator={translator}
          />
        ))}
      </section>
    );
  }
}
