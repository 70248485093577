import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Player } from 'video-react';
import wifiFR from '../../../assets/images/wifi-French.jpg';
import { getCwpMeta } from '../../../providers/cwpMetaProvider';
import '../marketingVideoCA/Video-react.scss';

export class VideoDisplay extends Component {
  state = {
    videoVisible: false
  };

  toggleopen = id => {
    this.setState({ videoVisible: !this.state.videoVisible });
  };

  componentDidMount = async () => {
    let metaResponse = await getCwpMeta();
    let videoContentURL = metaResponse.static.content.urls.videos;
    // manually swapping en-CA for fr-CA for French
    videoContentURL = videoContentURL.replace('en-CA', 'fr-CA');
    this.setState({
      videoContentURL
    });
  };

  render () {
    return (
      <div>
        <a href='javascript:void(0);' onClick={this.toggleopen}>
          <img src={wifiFR} width='370px' height='210px' alt='Comment configurer le Wi-Fi' />
        </a>
        <span className='video-title'>Comment configurer le Wi-Fi</span>
        <Modal isOpen={this.state.videoVisible} toggle={this.toggleopen} centered={true} className='video'>
          <ModalHeader toggle={this.toggleopen}>Comment configurer le Wi-Fi</ModalHeader>
          <ModalBody>
            {this.state.videoContentURL && (
              <Player playsInline src={`${this.state.videoContentURL}/Comment configurer le Wi-Fi.mp4`} />
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default VideoDisplay;
