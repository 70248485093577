import React, { useState, useEffect } from 'react';
import DatePicker from './DuetDatePickerWrapper';
import { formatDateISO } from '../../../util/utilityMethods';
import { getLocaleSync } from '../../../providers/languageProvider';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { dateAdapters } from './DatePickerAdapters';

import './cwpDatePicker.scss';

// let lastInput = '';

/**
 * Wrapped duetds datepicker
 * @param {Object} props {min, max, date, onChange}
 */
const CwpDatePicker = ({ min = null, max = null, id = 'cwpDatePickerComponentId', date = '', onChange = null }) => {
  const [propsDate, setPropsDate] = useState('');
  const [lastInput, setLastInput] = useState('');

  let localeData = getLocaleSync();
  let { locale = 'en-US' } = localeData;

  let _isMounted = true;

  useEffect(() => {
    let localeData = getLocaleSync();
    let { locale = 'en-US' } = localeData;
    let formatted = '';
    if (!!date && String(date).length >= 8 && testPropsDateIsValidDate(date)) {
      formatted = formatDateISO(new Date(date));
    }
    if (_isMounted) {
      console.log('running CwpDatePicker >> useEffect >> setting propsDate to ' + formatted);
      setPropsDate(formatted);
      if (formatted) {
        let itemToSetAsLastInput = date.replace(/-/gi, '/');
        if (locale.split('-')[0].toLowerCase() === 'fr') {
          // need to do a little string magic
          let splitIso = formatted.split('-');
          itemToSetAsLastInput = `${splitIso[2]}/${splitIso[1]}/${splitIso[0]}`;
        }
        setLastInput(itemToSetAsLastInput);
      }
    }

    return () => {
      _isMounted = false;
    };
  }, [date]);

  const translator = getLocaleTranslator();

  let languageIsFrench = locale.split('-')[0].toLowerCase() === 'fr';
  let localization = {
    buttonLabel: translator.t('date_picker.buttonLabel'),
    placeholder: translator.t('date_picker.buttonLabel'),
    selectedDateMessage: translator.t('date_picker.buttonLabel'),
    prevMonthLabel: translator.t('date_picker.buttonLabel'),
    nextMonthLabel: translator.t('date_picker.buttonLabel'),
    monthSelectLabel: translator.t('date_picker.buttonLabel'),
    yearSelectLabel: translator.t('date_picker.buttonLabel'),
    closeLabel: translator.t('date_picker.buttonLabel'),
    calendarHeading: translator.t('date_picker.buttonLabel'),
    dayNames: [
      translator.t('date_picker.dayNames.0'),
      translator.t('date_picker.dayNames.1'),
      translator.t('date_picker.dayNames.2'),
      translator.t('date_picker.dayNames.3'),
      translator.t('date_picker.dayNames.4'),
      translator.t('date_picker.dayNames.5'),
      translator.t('date_picker.dayNames.6')
    ],
    monthNames: [
      translator.t('date_picker.monthNames.0'),
      translator.t('date_picker.monthNames.1'),
      translator.t('date_picker.monthNames.2'),
      translator.t('date_picker.monthNames.3'),
      translator.t('date_picker.monthNames.4'),
      translator.t('date_picker.monthNames.5'),
      translator.t('date_picker.monthNames.6'),
      translator.t('date_picker.monthNames.7'),
      translator.t('date_picker.monthNames.8'),
      translator.t('date_picker.monthNames.9'),
      translator.t('date_picker.monthNames.10'),
      translator.t('date_picker.monthNames.11')
    ],
    monthNamesShort: [
      translator.t('date_picker.monthNamesShort.0'),
      translator.t('date_picker.monthNamesShort.1'),
      translator.t('date_picker.monthNamesShort.2'),
      translator.t('date_picker.monthNamesShort.3'),
      translator.t('date_picker.monthNamesShort.4'),
      translator.t('date_picker.monthNamesShort.5'),
      translator.t('date_picker.monthNamesShort.6'),
      translator.t('date_picker.monthNamesShort.7'),
      translator.t('date_picker.monthNamesShort.8'),
      translator.t('date_picker.monthNamesShort.9'),
      translator.t('date_picker.monthNamesShort.10'),
      translator.t('date_picker.monthNamesShort.11')
    ]
  };

  const testPropsDateIsValidDate = maybeADateThing => {
    let attempt = null;
    try {
      attempt = new Date(maybeADateThing).getTime();
      if (!isNaN(attempt)) {
        return true;
      }
      return false;
    } catch (err) {
      console.log('not a valid date thing');
    }
    return false;
  };

  // map to correct localized date adapter
  const dateAdapter = dateAdapters[locale.toLowerCase()];

  const validateInputString = dateStringFromCalendar => {
    // console.log('CwpDatePicker >> validateInputString >> ' + dateStringFromCalendar);
    //0:month,1:day,2:year -- en-US && en-CA
    //0:day,1:month,2:year -- fr-CA
    // the input field restricts the characters allowed to digits, and delimiters: .,/,-
    let dateStringValues = dateStringFromCalendar.split(/[\.\/-]/i);

    // dateString converted to (yyyy-MM-dd)
    let dateFromCalendar = `${dateStringValues[2]}-${dateStringValues[0]}-${dateStringValues[1]}`;
    // french entered in different order (dd-MM-yyyy) differently, same end result
    if (languageIsFrench) {
      dateFromCalendar = `${dateStringValues[2]}-${dateStringValues[1]}-${dateStringValues[0]}`;
    }

    // validates that values are present in the iso format against a regex (yyyy-MM-dd)
    let dateFromCalendarIsValid = /^(19|20)(\d{2})-(\d{1,2})-(\d{1,2})$/.test(dateFromCalendar);

    // 0: year, 1: month, 2: day,
    // verify that only valid dates are present (might not need this)
    let dateFromCalendarArrayForValidation = dateFromCalendar.split('-');
    dateFromCalendarIsValid =
      dateFromCalendarIsValid &&
      parseInt(dateFromCalendarArrayForValidation[1]) >= 1 &&
      parseInt(dateFromCalendarArrayForValidation[1]) <= 12;
    dateFromCalendarIsValid =
      dateFromCalendarIsValid &&
      parseInt(dateFromCalendarArrayForValidation[2]) >= 1 &&
      parseInt(dateFromCalendarArrayForValidation[2]) <= 31;

    // console.log('dateFromCalendarIsValid ' + dateFromCalendarIsValid + ' ' + dateFromCalendar);
    // try to convert it into a real date to see if it passes muster
    let dateValueValidation = Date.parse(dateFromCalendar);
    let dateIsValid = true;
    if (isNaN(dateValueValidation)) {
      dateIsValid = false;
    }

    // console.log(dateFromCalendar + ' dateIsValid  = ' + dateIsValid);
    if (
      dateIsValid &&
      dateFromCalendarIsValid &&
      (!min || dateFromCalendar >= min) &&
      (!max || dateFromCalendar <= max)
    ) {
      return true;
    }
    return false;
  };

  // wrapper for onChange prop
  const propsOnChange = dateStr => {
    if (onChange && typeof onChange === 'function') {
      onChange(dateStr);
    }
  };

  const onChangeHandler = e => {
    let dateString = e.detail.value;
    let splitDateString = dateString.split('-');
    let formattedDate = `${splitDateString[1]}-${splitDateString[2]}-${splitDateString[0]}`;
    // console.log('CwpDatePicker >> internal onChange >> ' + dateString + ' vs ' + formattedDate);
    propsOnChange(formattedDate);
  };

  const onBlur = e => {
    // console.log('CwpDatePicker >> onBlur');
    let inputString = lastInput;
    if (!validateInputString(inputString)) {
      // console.log('CwpDatePicker >> onBlur invalid stuff ' + inputString);
      setPropsDate('');
      propsOnChange('');
      // lastInput = '';
      setLastInput('');
    }
  };

  const renderDatePicker = () => {
    return (
      <DatePicker
        value={propsDate}
        onChange={onChangeHandler}
        onBlur={onBlur}
        onInput={e => {
          e.preventDefault();
          e.stopPropagation();
          // comes out of picker in user entered format
          let dateStringFromCalendar = String(e.target.value);
          if (validateInputString(dateStringFromCalendar)) {
            let cwpFormattedDate = dateStringFromCalendar.replace(/[\.\/]/gi, '-');
            // console.log(
            //  'onInput is valid >> original ' + dateStringFromCalendar + ' and formatted ' + cwpFormattedDate
            // );
            // lastInput = cwpFormattedDate;
            setLastInput(cwpFormattedDate);
            return propsOnChange(cwpFormattedDate);
          }
          // lastInput = dateStringFromCalendar;
          setLastInput(dateStringFromCalendar);
        }}
        min={min}
        max={max}
        id={id}
        localization={localization}
        dateAdapter={dateAdapter}
        expand={true}
      />
    );
  };

  return <>{renderDatePicker()}</>;
};

export default CwpDatePicker;
