import React, { Component } from 'react';
import Faq from 'react-faq-component';
import '../faqCanada/faqCA.scss';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import goback from '../../../assets/faq/goback.png';
import { historyBack } from './faqCAhome';
import { setTitle } from '../../../providers/documentTitleProvider';

export default class PlanFaqCA extends Component {
  componentDidMount () {
    setTitle('document_titles.faq.buy_plans');
  }
  render () {
    let translator = getLocaleTranslator();

    return (
      <div className='faqCAheader' role='main' id='main-content'>
        <div id='exit-editing-link'>
          <span
            id='backButton'
            class='cwpBack'
            role='button'
            onClick={historyBack}
            onKeyPress={e => {
              e.preventDefault();
              if (e.key && (e.key === 'Enter' || e.key === ' ')) {
                historyBack(e);
              }
            }}
            tabIndex={0}
          >
            {translator.t('button.back')}
          </span>
        </div>
        <div className='message-header'>
          <div className='td-ms-header'>
            <h1>{translator.t('buyplan_title')}</h1>
          </div>
        </div>
        <Faq data={data} />
      </div>
    );
  }
}

const data = {
  rows: [
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          What is Trips Stats and what data is monitored?{' '}
        </div>
      ),
      content: `<span class='mdlyr'>MY22+:</span> This mobile app feature is available for vehicles equipped with an MIB3 Infotainment System. Trip stats is a feature when you enroll in VW Car-Net Remote Access that allows you to monitor your short-term trip. A 'trip' is generated from the moment you start your vehicle and ends when your vehicle has been in a stationary position for over 2 hours. Each time your vehicle stops during your trip for less than 2 hours a "stop" is created. You can view daily, weekly or monthly statuses of the 'trips' you have taken. It displays your average L/100 km, total distance traveled, and average km/h. Trip stats also shows you what time you started driving and what time you ended driving with how many stops along the way.`
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          How is the data gathered for my Trips Stats?
        </div>
      ),
      content: ` <span class='mdlyr'>MY22+:</span> Data from your vehicle is gathered to create a consolidated view of your Trips. This feature is available for vehicles equipped with an MIB3 Infotainment System.`
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          Who can see my Trip Stats data?
        </div>
      ),
      content: ` <span class='mdlyr'>MY22+:</span> Only a primary user or additional user of your vehicle can view trip data in the VW Car-Net App. You can assign or delete additional users at any time under the Account Management tab in the mobile app or our website at www.vw.ca/en/innovation-and-technology/car-net.html.`
    },

    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          How do I access my Wi-Fi SSID & Passphrase?{' '}
        </div>
      ),
      content: ` <span class='mdlyr'>MY22+:</span> You will find the SSID & Passphrase within your vehicle’s head unit. Please go to Menu -> Settings -> Wi-Fi -> Infotainment system as hotspot to find your vehicle's information. This information is not available via the VW Car-Net App.`
    }
  ]
};

export { PlanFaqCA };
