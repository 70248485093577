import React from 'react';

export const BuyPlansContext = React.createContext({
  loading: false,
  translator: null,
  contentURL: null,
  vehicleId: null,
  userId: null,
  mnoProvider: null,
  userCountry: null,
  userLang: null,
  marketingConfigurationHash: null,
  currentPlans: [],
  additionalPlans: [],
  selectedPlan: null,
  cart: null,
  redirectTo: () => {},
  set: () => {},
  selectPlan: () => {},
  selectPlanFromList: () => {},
  showDisclaimer: () => {},
  addToCart: () => {},
  initPlansAndCart: () => {}
});
