import React, { Component } from 'react';
import carnet_constants from '../../../constants/carnet_constants';
import { formatTimeWithZone } from '../../../util/i18n/localeUtils';
import { findIn } from '../../../util/utilityMethods';
import './messageCenterInfo.scss';
import deleteImgSrc from '../../../assets/images/close.svg';
import carmask from '../../../assets/images/car_mask.png';
import unread from '../../../assets/images/unread-mark.png';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import {
  getmessageCenterInfoServiceCall,
  handleMessageDeleteAllServiceCall,
  handleMessageDeleteServiceCall,
  checkMoreServiceCall,
  viewMessageServiceCall,
  searchMessagesServiceCall
} from './messageCenterInfoProvider';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const CategoryFilters = Object.freeze({
  ALL: 'all',
  VEHICLES: 'Vehicles',
  INFO: 'Info',
  OFFERS: 'Offers'
});

export default class MessageCenterInfo extends Component {
  state = {
    header: CategoryFilters.ALL,
    messageId: '',
    deleteVisible: false,
    pageSize: 10,
    messageCenterList: [],
    nextMsgs: [],
    stateUpdated: false,
    hideMSFeatures: false,
    selectedMessage: {},
    searchText: '',
    deleteMessageCenterEvents: []
  };
  componentDidMount () {
    this.getmessageCenterInfo();
  }
  /****** getmessageCenterInfo ******/
  getmessageCenterInfo = async (category = 'all', pageSize = '10', pageNo = '0') => {
    try {
      var messageCenterInfo = await getmessageCenterInfoServiceCall(category, pageSize, pageNo);
      let msgs = findIn('data.data.messages', messageCenterInfo);
      this.setState(
        {
          messageCenterList: msgs
        },
        this.checkMore
      );
    } catch (err) {
      console.log('getmessageCenterInfo() failed - ' + err);
    }
  };

  //***************Delete All*******************
  handleMessageDeleteAll = async () => {
    try {
      await handleMessageDeleteAllServiceCall();
    } catch (err) {
      console.log('deleteAllmessagecenter() failed - ' + err);
    }

    try {
      await this.getmessageCenterInfo();
    } catch (err) {
      console.log('getmessageCenterInfo() failed - ' + err);
    }
  };
  //***************Delete All END*******************

  //*****************Delete Single Message *********************

  handleMessageDelete = async () => {
    this.setState({ deleteVisible: this.state.deleteVisible }, async () => {
      let deleteMessageCenterEvents = [...this.state.deleteMessageCenterEvents];
      try {
        let deleteResult = await handleMessageDeleteServiceCall(this.state.messageId);

        if (deleteResult.status === 200) {
          deleteMessageCenterEvents.push(this.state.messageId);
        }
      } catch (err) {
        console.log('deletemessagecenter single message() failed - ' + err);
      }

      this.setState(
        {
          deleteVisible: !this.state.deleteVisible,
          hideMSFeatures: false,
          selectedMessage: {},
          deleteMessageCenterEvents,
          messageId: ''
        },
        () => {
          setTimeout(() => {
            this.getmessageCenterInfo();
          }, 500);
        }
      );
    });
  };
  //************Delete Single Message End here *************

  filter = header => {
    this.setState({ stateUpdated: true, header: header });
  };

  focusConfirmationModal = () => {
    try {
      document.getElementById('messageDeleteConfirmation').focus();
    } catch (e) {
      console.log('unable to set focus on confirmation modal', e);
    }
  };

  toggledelete = id => {
    this.setState({ deleteVisible: !this.state.deleteVisible, messageId: id });
  };

  checkMore = async () => {
    let pageSize = this.state.pageSize;
    let advpageSize = pageSize + carnet_constants.DEFAULT_MSGCENTER_PAGING_ADVANCE;
    try {
      let checkMoreResponses = await checkMoreServiceCall(advpageSize);
      let nextMsgs = findIn('data.data.messages', checkMoreResponses);
      let nextPageMsgs = nextMsgs !== false ? nextMsgs : [];
      console.log('::::::::::::::::::: CHECKING FOR NEXT PAGE OF MESSAGES, FOUND:   ::::');
      console.log(JSON.stringify(nextPageMsgs));
      this.setState({
        nextMsgs: nextPageMsgs
      });
    } catch (err) {
      console.log('checkMore() failed - ' + err);
    }
  };

  showMore = async () => {
    let pageSize = this.state.pageSize;
    let nxtPageSize = pageSize + carnet_constants.DEFAULT_MSGCENTER_PAGING_ADVANCE;
    if (this.state.nextMsgs) {
      let pendingNextMsgs = JSON.parse(JSON.stringify(this.state.nextMsgs));
      const nextPageOfMsgs = pendingNextMsgs || [];
      if (nextPageOfMsgs.length > 0) {
        this.setState(
          {
            pageSize: nxtPageSize,
            stateUpdated: true,
            messageCenterList: nextPageOfMsgs
          },
          () => {
            this.checkMore();
          }
        );
      }
    }
  };

  viewMessage = async messageId => {
    try {
      let viewMessageResponse = await viewMessageServiceCall(messageId);
      let viewMessage = viewMessageResponse.data.data;
      this.setState(
        {
          hideMSFeatures: true,
          selectedMessage: viewMessage
        },
        () => {
          try {
            let el = document.getElementById('showMSFeatures');
            el.scrollIntoView({ behavior: 'smooth', block: 'center' });
          } catch (err) {
            console.log('unable to locate message detail element');
          }
        }
      );
    } catch (err) {
      console.log(' view Message Response failed - ' + err);
    }
  };

  handleSearchText = event => {
    this.setState({
      searchText: event.target.value
    });
  };

  searchMessage = async () => {
    try {
      var searchMessageResponse = await searchMessagesServiceCall(this.state.searchText);
      this.setState({
        pageNo: 1,
        pageSize: 10,
        searchText: this.state.searchText,
        stateUpdated: true,
        messageCenterList: searchMessageResponse.data.data.messages
      });
    } catch (err) {
      console.log(' Search Message Response failed - ' + err);
    }
  };

  render () {
    let translator = getLocaleTranslator();
    let viewMessage = {};
    viewMessage = this.state.selectedMessage;

    //'Enter topic here...'
    let i18nSearchPlaceholder = translator.t('message_center_search_placeholder_label');
    let messageCenterList = this.state.messageCenterList || [];
    if (this.state.header !== CategoryFilters.ALL) {
      //messageCenterList = this.state.messageCenterList;
      messageCenterList = this.state.messageCenterList
        ? messageCenterList.filter(message => message.category === this.state.header)
        : [];
    } else {
      messageCenterList = this.state.messageCenterList;
    }

    messageCenterList = messageCenterList.filter(message => !this.state.deleteMessageCenterEvents.includes(message.id));
    let actualNumMsgs = messageCenterList.length; //messageCenterList is initialized as [] above, so will never be null.

    return (
      <div class='message-center-css-insulator' role='main' id='main-content'>
        <h1 className='mx-auto message-header'>{translator.t('message_center_title')}</h1>
        <div className='container-fluid'>
          <div className='row justify-content-center'>
            <div className='col-12 col-md-9 pb-4'>
              <div className='pl-3'>
                <label htmlFor='searchBox'>{translator.t('message_center_search_label')}</label>
                <br />
                <input
                  type='text'
                  name='searchBox'
                  id='searchBox'
                  className='ms-input'
                  value={this.state.searchText}
                  onChange={this.handleSearchText}
                  placeholder={i18nSearchPlaceholder}
                  onKeyUpCapture={event => {
                    if (String(event?.key).toLowerCase() === 'enter') {
                      this.searchMessage();
                    }
                  }}
                />
                <button
                  className='search-info'
                  aria-label={translator.t('search_label')}
                  value='Send'
                  onClick={this.searchMessage}
                ></button>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-12 col-md-4'>
              <div className='message'>
                <ul
                  className='nav nav-fill'
                  role='navigation'
                  aria-label={translator.t('message_center_result_filters')}
                >
                  <li className='nav-item'>
                    <button
                      onClick={e => this.filter(CategoryFilters.ALL)}
                      className={`btn btn-link carnetLink ${
                        this.state.header === CategoryFilters.ALL ? 'activeall' : 'messageAll'
                      }`}
                      aria-current={this.state.header === CategoryFilters.ALL}
                    >
                      {translator.t('ALL')}
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      onClick={e => this.filter(CategoryFilters.VEHICLES)}
                      className={`btn btn-link carnetLink ${
                        this.state.header === CategoryFilters.VEHICLES ? 'activeall' : 'messageAll'
                      }`}
                      aria-current={this.state.header === CategoryFilters.VEHICLES}
                    >
                      {translator.t('VEHICLES')}
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      onClick={e => this.filter(CategoryFilters.INFO)}
                      className={`btn btn-link carnetLink ${
                        this.state.header === CategoryFilters.INFO ? 'activeall' : 'messageAll'
                      }`}
                      aria-current={this.state.header === CategoryFilters.INFO}
                    >
                      {translator.t('INFO')}
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      onClick={e => this.filter(CategoryFilters.OFFERS)}
                      className={`btn btn-link carnetLink ${
                        this.state.header === CategoryFilters.OFFERS ? 'activeall' : 'messageAll'
                      }`}
                      aria-current={this.state.header === CategoryFilters.OFFERS}
                    >
                      {translator.t('OFFERS')}
                    </button>
                  </li>
                </ul>
                <div className='hrline'></div>
                {messageCenterList &&
                  messageCenterList.map((message, index) => (
                    <div className='mscenter' key={index}>
                      <span className='read'>
                        {message.read === true ? '' : <img alt='' src={unread} width='9px' height='9px' />}
                      </span>
                      <span
                        tabIndex='0'
                        role='button'
                        className='header'
                        onClick={() => {
                          this.viewMessage(message.id);
                        }}
                        onKeyPress={e => {
                          if (e.key && (e.key === 'Enter' || e.key === ' ')) {
                            this.viewMessage(message.id);
                          }
                        }}
                      >
                        {message.header}
                      </span>
                      <br />
                      <span
                        role='button'
                        tabIndex='0'
                        className='delete'
                        onClick={() => this.toggledelete(message.id)}
                        onKeyPress={e => {
                          if (e.key && (e.key === 'Enter' || e.key === ' ')) {
                            this.toggledelete(message.id);
                          }
                        }}
                      >
                        <img
                          alt={translator.t('message_center_delete')}
                          src={deleteImgSrc}
                          width='24px'
                          height='24px'
                        />
                      </span>
                      <br />
                      <img src={carmask} alt='' width='15px' height='12px' />{' '}
                      <span className='subHeader'> {message.subHeader}</span>
                      <br />
                      <span className='createdTime'>
                        {translator.t('message_center_created_at')} {formatTimeWithZone(message.createdTime)}
                      </span>
                      <br />
                      <span className='richText'>{message.richText}</span>
                      <div className='hrline'></div>
                    </div>
                  ))}

                {messageCenterList.length === 0 && <span>{translator.t('message_center_search_results_empty')}</span>}

                {actualNumMsgs > 0 && this.state.nextMsgs && this.state.nextMsgs.length > 0 && (
                  <span
                    role='button'
                    tabIndex='0'
                    className='showmore'
                    onClick={this.showMore}
                    onKeyPress={e => {
                      if (e.key && (e.key === 'Enter' || e.key === ' ')) {
                        this.showMore();
                      }
                    }}
                  >
                    {translator.t('show_more_label')}
                  </span>
                )}

                {actualNumMsgs > 0 && (
                  <span
                    role='button'
                    tabIndex='0'
                    className='delete rightPadding20'
                    onClick={() => this.handleMessageDeleteAll()}
                    onKeyPress={e => {
                      if (e.key && (e.key === 'Enter' || e.key === ' ')) {
                        this.handleMessageDeleteAll();
                      }
                    }}
                  >
                    {translator.t('delete_all_label')}
                  </span>
                )}

                {/* Modal PopUp */}

                <Modal
                  id='messageDeleteConfirmation'
                  className='message-center-modal'
                  isOpen={this.state.deleteVisible}
                  toggle={this.toggledelete}
                  centered={true}
                  onShow={this.focusConfirmationModal}
                  aria-live='polite'
                  aria-labelledby='deleteMessageConfirmationTitle'
                  aria-describedby='deleteMessageConfirmation'
                >
                  <ModalHeader toggle={this.toggledelete} id='deleteMessageConfirmationTitle'>
                    {translator.t('message_center_title')}
                  </ModalHeader>
                  <ModalBody id='deleteMessageConfirmation'>
                    <p> {translator.t('delete_this_message')}</p>
                  </ModalBody>
                  <ModalFooter>
                    <button className='btn-secondary' onClick={this.toggledelete}>
                      {translator.t('message_center_modal_cancel')}
                    </button>{' '}
                    <button className='btn-primary' onClick={() => this.handleMessageDelete()}>
                      {translator.t('message_center_modal_ok')}
                    </button>
                  </ModalFooter>
                </Modal>

                <br />
              </div>
            </div>
            <div className='col-12 col-md-5'>
              <div id='showMSFeatures' className='pl-md-5'>
                {this.state.hideMSFeatures && (
                  <div aria-live={'polite'}>
                    <div className='hrline'></div>
                    <span className='header-detail'> {viewMessage.header}</span>
                    <br />
                    <img alt='' src={carmask} width='15px' height='12px' />{' '}
                    <span className='subHeader-detail'> {viewMessage.subHeader}</span>
                    <br />
                    <span className='createdTime'>
                      {translator.t('message_center_created_at')} {formatTimeWithZone(viewMessage.createdTime)}
                    </span>
                    <br />
                    <span className='richText-detail'>{viewMessage.richText}</span>
                    <div className='hrline'></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
