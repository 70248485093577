import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import '../../carnet/CarnetHome.scss';
import './ThemedToast.scss';

const ThemedToast = props => {
  let {
    title,
    msg,
    msg2 = '',
    msgLinkText = '',
    msgLinkOnClick = () => {},
    display,
    displaySecondButton = false,
    cancelButtonLabel,
    confirmButtonLabel,
    onCancelClick,
    onConfirmClick,
    returnFocusAfterClose = true,
    onClosed = () => {}
  } = props;
  const [show, setShow] = useState(true);

  const toggle = () => {
    if (onConfirmClick && typeof onConfirmClick === 'function') {
      onConfirmClick();
    }
    setShow(!show);
  };

  const toggleSecondary = () => {
    if (onCancelClick && typeof onCancelClick === 'function') {
      onCancelClick();
    }
    setShow(!show);
  };

  const translator = getLocaleTranslator();

  confirmButtonLabel = confirmButtonLabel || translator.t('acctmgmt_label_ok');

  return (
    <div>
      <Modal
        aria-live={'polite'}
        isOpen={display && show}
        className='cwpThemedModal'
        labelledBy='themedModalHeaderId'
        returnFocusAfterClose={returnFocusAfterClose}
        onClosed={onClosed}
        onOpened={() => {
          try {
            document.getElementById('confirmButton').focus();
          } catch (err) {
            console.log('unable to get primary button');
          }
        }}
      >
        <div class='modal-header'>
          <h2 id='themedModalHeaderId' className='h5 font-weight-bold modal-title'>
            {title || ''}
          </h2>
        </div>
        <ModalBody>
          <p>{msg || ''}</p>
          {msg2 && <p>{msg2}</p>}
          {msgLinkText && (
            <span role='button' onClick={msgLinkOnClick} className='messageLink'>
              {msgLinkText}
            </span>
          )}
        </ModalBody>
        <ModalFooter>
          {displaySecondButton && (
            <Button
              className='cwpSecondary secondaryModalButton themedToastBtn cwpFocusVisible'
              onClick={toggleSecondary}
            >
              {cancelButtonLabel}
            </Button>
          )}
          <Button id='confirmButton' className='cwpSecondary themedToastBtn cwpFocusVisible' onClick={toggle}>
            {confirmButtonLabel}
          </Button>
        </ModalFooter>
      </Modal>
      <br />
    </div>
  );
};

export default ThemedToast;
