import React from 'react';
import { getLocaleTranslator, findErrorMessage } from '../../../util/i18n/i18nService';
import HeaderPrePinAuth from './HeaderPrePinAuth';
import {
  getEstoreMarketingContent,
  getSubscriptionSummary,
  removeServicePlans,
  unenrollVehicle
} from '../providers/GarageProvider';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UncontrolledAlert, Modal, ModalBody } from 'reactstrap';
import Spinner from '../Spinner';
import LandingPage from './LandingPage';
import defaultVehicleImage from '../../../assets/images/defaultVehicleImage.png';
import './ChangeServices.scss';
import '../../reusable_cmp_lib/ThemedModal/ThemedModal.scss';
import ChangeServicesPlan from './ChangeServicesPlan';
import { findIn } from '../../../util/utilityMethods';
import { dateFormat } from '../../../util/i18n/localeUtils';
import { setTitle } from '../../../providers/documentTitleProvider';

const MNO = Object.freeze({
  TMO: 'TMO',
  VERIZON: 'VERIZON'
});

class ChangeServices extends React.Component {
  state = {
    spinnerMessage: null,
    alert: null,
    configurations: [],
    data: null,
    skuList: [],
    unenroll: false,
    isOpenModalRemoveService: false,
    isOpenModalUnenroll: false
  };

  constructor (props) {
    super(props);

    this.translator = getLocaleTranslator();
  }

  clearAlerts = () => this.setState({ alert: null });

  getAlerts = () => {
    if (this.state.alert) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts}>
          {this.state.alert}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  componentDidMount = () => {
    setTitle('document_titles.garage.change_services');
    getEstoreMarketingContent()
      .then(response => {
        const configurations = findIn('data.configurations', response);

        this.setState({ configurations });
      })
      .catch(error => {
        this.setState({ configurations: [], alert: findErrorMessage(error, this.translator) });
      });

    this.refresh();
  };

  findApplicableConfiguration = (plan = {}) => {
    const { marketingConfigurationId } = plan;
    if (
      !marketingConfigurationId ||
      !Array.isArray(this.state.configurations) ||
      this.state.configurations.length < 1
    ) {
      return null;
    }

    const configurations = this.state.configurations.filter(
      ({ configurationId }) => configurationId === marketingConfigurationId
    );
    if (configurations.length > 0) {
      return configurations[0];
    }

    return null;
  };

  findApplicableDescription = plan => {
    let config = this.findApplicableConfiguration(plan);
    let description = (config && config.description) || '';
    // this throws the react white screen of doom when there is no plan
    // const { description = '' } = this.findApplicableConfiguration(plan);
    return description;
  };

  // helper method, added to class in the event that it is not used with the 'withSpinEntry' HOC
  registerPromiseCatchers = (callback, vehicleId) => {
    if (this.props.registerPromiseCatchers) {
      return this.props.registerPromiseCatchers(callback, vehicleId);
    }
    return callback;
  };

  refresh = async () => {
    const vehicleId = this.props.userVehicleContext.vehicleId;

    if (!vehicleId) {
      window.location.href = LandingPage.GARAGE;

      return;
    }

    this.setState({ spinnerMessage: this.translator.t('change_services.init_message'), alert: null, data: null });
    try {
      let data = await getSubscriptionSummary(vehicleId);

      if (data.errorData) {
        this.setState({ data: data, spinnerMessage: null, alert: findErrorMessage(data.errorData, this.translator) });

        return;
      }

      this.setState({ spinnerMessage: null, data, skuList: [] });
    } catch (error) {
      this.setState({ spinnerMessage: null, alert: findErrorMessage(error, this.translator) });
    }
  };

  onClickRemoveServices = async e => {
    e.preventDefault();
    const vehicleId = this.props.userVehicleContext.vehicleId;

    this.setState({
      spinnerMessage: this.translator.t('change_services.applying_changes'),
      alert: null,
      isOpenModalRemoveService: false
    });
    try {
      let response = await removeServicePlans(vehicleId, this.state.skuList);
      if (response && response.errorData) {
        this.setState({ spinnerMessage: null, alert: findErrorMessage(data, this.translator) });
        return;
      }
      //    await this.refresh();
      const skuList = findIn('data.skuList', response);
      const data = { ...this.state.data };
      if (Array.isArray(skuList) && Array.isArray(data.subscribedPlans)) {
        const subscribedPlans = data.subscribedPlans.filter(sp => !skuList.includes(findIn('package.sku', sp)));

        data.subscribedPlans = subscribedPlans;
      }

      this.setState({ data, spinnerMessage: null, skuList: [] });
      //  window.location.href = LandingPage.GARAGE;
    } catch (error) {
      this.setState({ spinnerMessage: null, alert: findErrorMessage(error, this.translator) });
    }
  };

  onClickUnenrollVehicle = async e => {
    e.preventDefault();
    const vehicleId = this.props.userVehicleContext.vehicleId;

    this.setState({
      spinnerMessage: this.translator.t('change_services.applying_changes'),
      alert: null,
      isOpenModalUnenroll: false
    });

    try {
      let data = await this.registerPromiseCatchers({
        asyncPromiseToMake: unenrollVehicle(vehicleId),
        vehicleId,
        retry: () => {
          return unenrollVehicle(vehicleId);
        }
      });

      // async request resolved with an error
      if (data && data.errorData) {
        this.setState({ spinnerMessage: null, alert: findErrorMessage(data.errorData, this.translator) });
        return;
      }

      // indicates successful PIN re-entry after request failed (would not happen after retry)
      // if (data === 'PIN_ENTRY_COMPLETE') {
      //  return this.setState({ spinnerMessage: null, alert: null });
      //}
      // succesful result, re-route to GARAGE

      window.location.href = LandingPage.GARAGE;
    } catch (error) {
      let alertMsg = '';
      if (error && typeof error === 'object') {
        alertMsg = findErrorMessage(error, this.translator) || '';
      }
      //this.setState({ spinnerMessage: null, alert: null });
      this.setState({ spinnerMessage: null, alert: alertMsg });
    }
  };

  onClickSaveChanges = e => {
    e.preventDefault();

    if (this.state.unenroll) {
      this.toggleModalUnenroll();
      return;
    }

    this.toggleModalRemoveService();
  };

  toggleServicePlan = sku => {
    const skuList = new Set(this.state.skuList);

    if (skuList.has(sku)) {
      skuList.delete(sku);
    } else {
      skuList.add(sku);
    }

    this.setState({ skuList: Array.from(skuList) });
  };

  toggleModalRemoveService = () => {
    this.setState({ isOpenModalRemoveService: !this.state.isOpenModalRemoveService });
  };

  toggleModalUnenroll = () => {
    this.setState({ isOpenModalUnenroll: !this.state.isOpenModalUnenroll });
  };

  findDataPlanCacellationInstruction = dataPlan => {
    const mno = findIn('provider.name', dataPlan) || '';
    switch (mno.toUpperCase()) {
      case MNO.VERIZON:
        return this.translator.t('change_services.cancel_volkswagen_hotspot_desc_verizon');
      case MNO.TMO:
        return this.translator.t('change_services.cancel_volkswagen_hotspot_desc_tmo');
      default:
        return this.translator.t('change_services.cancel_volkswagen_hotspot_desc');
    }
  };

  goBack = e => {
    if (e) {
      e.preventDefault();
    }

    document.location.href = LandingPage.GARAGE;
  };

  render () {
    if (this.state.spinnerMessage || !this.state.data)
      return (
        <div className='container-fluid preVehicleAuth text-center'>
          <HeaderPrePinAuth />
          <br />
          <br />
          <h1 className='font-weight-normal'>
            {this.translator.t('change_services.edit')}{' '}
            <strong>{this.translator.t('change_services.service_plans')}</strong>
          </h1>
          <br />
          <Spinner message={this.state.spinnerMessage} />
        </div>
      );

    let vehicle = this.state.data.vehicle;
    if (!vehicle) vehicle = {};

    let servicePlans = [];
    let dataPlan = null;

    // All things displayed by primary
    // secondary user only show those plans subscribed by
    let isPrimaryUser = this.state.data.isPrimaryUser;
    if (Array.isArray(findIn('data.subscribedPlans', this.state))) {
      servicePlans = this.state.data.subscribedPlans.filter(
        plan =>
          plan.planType === 'SERVICE' &&
          plan.viewable &&
          (plan.subscribedByUserId === this.props.userVehicleContext.userId || isPrimaryUser)
      );
      dataPlan = this.state.data.subscribedPlans.find(plan => plan.planType === 'DATA' && plan.viewable);
    }

    const imgUrl = vehicle.representativeImgURLComplete || defaultVehicleImage;
    let vehicleName = `${vehicle.modelYear} ${vehicle.modelName}`;

    return (
      <div className='preVehicleAuth'>
        <HeaderPrePinAuth />
        <div role='main' id='main-content' className='pt-3'>
          <span className='cwpBack ml-3' role='button' tabIndex={0} onClick={this.goBack} onKeyPress={this.goBack}>
            {this.translator.t('change_services.back_to_garage')}
          </span>
          <div className='row justify-content-center pt-3'>
            <div className='col col-md-8'>
              <h1 className='font-weight-normal text-center h2'>
                {this.translator.t('change_services.edit')}{' '}
                <strong>{this.translator.t('change_services.service_plans')}</strong>
              </h1>
              <span className='mx-auto pt-3 pb-3 alignChangeServicesTextBlockLeft'>
                {this.translator.t('change_services.page_title_desc')}
              </span>
              {this.getAlerts()}
              <img src={imgUrl} alt='' height='150' className='float-left' />
              <div>
                <h2 className='font-weight-normal'>
                  <br />
                  {vehicleName}
                </h2>
                <strong>
                  {this.translator.t('change_services.label_vin')} {vehicle.vin}
                </strong>
              </div>
            </div>
          </div>

          <div hidden={!servicePlans || servicePlans.length < 1} className='row justify-content-center'>
            <div className='changePlan col col-md-8'>
              <h2 className='font-weight-bold my-0 h5 pt-5'>
                {this.translator.t('change_services.cancel_service_plans')}
              </h2>
              <span className='small'>{this.translator.t('change_services.cancel_service_plans_desc')}</span>
              <div className='row mx-0 justify-content-around pt-3'>
                <div className='col-md-5'>
                  {servicePlans
                    .filter((servicePlan, index) => index % 2 === 0)
                    .map(plan => (
                      <ChangeServicesPlan
                        key={plan.orderId}
                        plan={plan}
                        selected={sku => this.state.skuList.includes(sku)}
                        translator={this.translator}
                        toggle={sku => this.toggleServicePlan(sku)}
                        configuration={this.findApplicableConfiguration(plan)}
                      />
                    ))}
                </div>
                <div className='col-md-5'>
                  {servicePlans
                    .filter((servicePlan, index) => index % 2 === 1)
                    .map(plan => (
                      <ChangeServicesPlan
                        key={plan.orderId}
                        plan={plan}
                        selected={sku => this.state.skuList.includes(sku)}
                        translator={this.translator}
                        toggle={sku => this.toggleServicePlan(sku)}
                        configuration={this.findApplicableConfiguration(plan)}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>

          {/* <div className='row justify-content-center' hidden={!this.state.data.isPrimaryUser}> */}
          <div className='row justify-content-center'>
            <div className='changePlan col col-md-8'>
              <br />
              <h2 className='font-weight-bold my-0 h5'>{this.translator.t('change_services.unenroll_vehicle')}</h2>
              <span className='small'>{this.translator.t('change_services.unenroll_vehicle_desc')}</span>
              <br />
              <br />
              <input
                id='chkUnenroll'
                type='checkbox'
                checked={this.state.unenroll}
                className={`cwpCheckbox ${this.state.unenroll ? 'cwpCheckboxOn' : 'cwpCheckboxOff'}`}
                onChange={e => this.setState({ unenroll: !this.state.unenroll })}
                tabIndex='0'
              />
              <label htmlFor='chkUnenroll' className='h4'>
                {this.translator.t('change_services.unenroll_vehicle')}
              </label>
            </div>
          </div>

          {dataPlan && (
            <div className='row justify-content-center'>
              <div className='changePlan col col-md-8'>
                <h2 className='font-weight-bold my-0 h5 pt-5'>
                  {this.translator.t('change_services.cancel_volkswagen_hotspot')}
                </h2>
                <span className='small pb-5'>{this.findDataPlanCacellationInstruction(dataPlan)}</span>
                <div>
                  <strong className='pb-3'>{findIn('package.name', dataPlan) || ''}</strong>
                  {dataPlan.purchaseDate && (
                    <span>
                      {this.translator.t('change_services.start_date')} {dateFormat(dataPlan.purchaseDate)}
                    </span>
                  )}
                  {dataPlan.expirationDate && (
                    <span className='ml-4'>
                      {this.translator.t('change_services.ends_on')} {dateFormat(dataPlan.expirationDate)}
                    </span>
                  )}
                  <span className='small pt-3'>
                    {this.findApplicableDescription(dataPlan) || findIn('package.shortDescription', dataPlan) || ''}
                  </span>
                </div>
              </div>
            </div>
          )}
          <div className='container-fluid text-center pb-5'>
            <button
              className='cwp'
              type='button'
              onClick={this.onClickSaveChanges}
              disabled={!(this.state.unenroll || this.state.skuList.length > 0)}
            >
              {this.translator.t('change_services.btn_save_changes')}
            </button>
          </div>
        </div>
        <Modal
          isOpen={this.state.isOpenModalRemoveService}
          toggle={this.toggleModalRemoveService}
          centered={true}
          className='carnet-sg-modal'
        >
          <ModalBody aria-live='polite' aria-labelledby='lblRemovePlan' aria-describedby='descRemovePlan'>
            <h2 id='lblRemovePlan' className='font-weight-bold'>
              {this.translator.t('change_services.remove_service_plans')}
            </h2>
            <span id='descRemovePlan'>{this.translator.t('change_services.remove_service_plans_desc')}</span>
            <br />
            <br />
            <button
              className='modal-primary-btn w-100 mx-auto d-block'
              onClick={this.onClickRemoveServices}
              color='$modal_background_color'
            >
              {this.translator.t('change_services.btn_remove_services')}
            </button>
            <br />
            <br />
            <button
              className='modal-secondary-btn w-100 mx-auto d-block'
              onClick={this.toggleModalRemoveService}
              color='modal-text-color'
            >
              {this.translator.t('change_services.btn_cancel')}
            </button>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalUnenroll}
          toggle={this.toggleModalUnenroll}
          centered={true}
          className='carnet-sg-modal'
        >
          <ModalBody aria-live='polite' aria-labelledby='lblUnenrollVehicle' aria-describedby='descUnenrollVehicle'>
            <h2 id='lblUnenrollVehicle' className='font-weight-bold'>
              {this.translator.t('change_services.unenroll_vehicle')}
            </h2>
            <span id='descUnenrollVehicle'>{this.translator.t('change_services.unenroll_vehicle_alert')}</span>
            <br />
            <br />
            <button className='modal-primary-btn w-100 mx-auto d-block' onClick={this.onClickUnenrollVehicle}>
              {this.translator.t('change_services.btn_unenroll_vehicle')}
            </button>
            <br />
            <br />
            <button className='modal-secondary-btn w-100 mx-auto d-block' onClick={this.toggleModalUnenroll}>
              {this.translator.t('change_services.btn_cancel')}
            </button>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({}, dispatch)
  };
};

const mapStateToProps = state => {
  const { userVehicleContext } = state;

  return {
    ...userVehicleContext
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeServices);
