import {
  GET_CURRENT_WALLET_DETAILS,
  GET_CURRENT_WALLET_DETAILS_SUCCESS,
  GET_CURRENT_WALLET_DETAILS_FAILURE
} from '../../actions/types';

const initialState = {
  walletInfo: null,
  dataLoading: false
};

// Search for customers associated with a particular vehicle
const storeWalletDetailsReducer = (state = initialState, action = { payload: {} }) => {
  let newState = { ...state };
  let actionType = (action && action.type) || 'default';
  let responseData = action.payload || {};

  switch (actionType) {
    case GET_CURRENT_WALLET_DETAILS:
      console.log(`storeWalletDetailsReducer >> ${actionType}`);
      console.log('action.payload is in storeWalletDetailsReducer is :::' + JSON.stringify(action.payload));
      newState = { ...initialState };
      return newState;

    case GET_CURRENT_WALLET_DETAILS_SUCCESS:
      console.log(`storeWalletDetailsReducer >> ${actionType}`);
      console.log('action.payload is in storeWalletDetailsReducer is :::' + JSON.stringify(action.payload));
      let { data } = responseData;
      newState['walletInfo'] = data;
      newState.dataLoading = true;
      return newState;

    case GET_CURRENT_WALLET_DETAILS_FAILURE:
      console.log(`storeWalletDetailsReducer >> ${actionType}`);
      console.log('action.payload is in storeWalletDetailsReducer is :::' + JSON.stringify(action.payload));
      newState = { ...initialState };
      return newState;

    default:
      return newState;
  }
};

export default storeWalletDetailsReducer;
