import React, { useEffect, useReducer, useState } from 'react';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import {
  getCwpSessionInfo,
  registerWithSessionTimeout,
  registerWithSessionRefresh
} from '../../../providers/sessionProvider';
import { login, logout } from '../../../providers/logInNOutProvider';

import { DisplayTimeoutWarning } from './DisplayTimeoutWarning';

import './loginOut.scss';

const LoginOut = props => {
  const thePolyGlot = getLocaleTranslator();

  const initLoginOutReducer = () => {
    return { USER_LOGGED_IN: null };
  };

  const loginLogOutReducer = (state, action) => {
    switch (action.type) {
      case 'login':
        return { USER_LOGGED_IN: true };
      case 'logout':
        return { USER_LOGGED_IN: false };
      default:
        console.log('default action for unknown type on internal loginLogOutReducer do nothing');
    }
  };

  const [state, dispatch] = useReducer(loginLogOutReducer, null, initLoginOutReducer);
  const [disabled, setDisabled] = useState(true); // button disabled initially until initial status query returns

  const [DISPLAY_SESSION_WARNING, setSessionWarning] = useState(false);

  const onClickLoginOut = e => {
    e.preventDefault();
    e.stopPropagation();

    if (disabled) {
      console.log('button disabled stopping here');
      return;
    }

    // disable the button whilst async runs
    setDisabled(true);

    let isLoggedIn = state.USER_LOGGED_IN;

    if (isLoggedIn) {
      logout()
        .then(() => {
          dispatch({ type: 'logout' });
        })
        .catch(er => {
          // revert back to logged in state
          setDisabled(false);
          dispatch({ type: 'login' });
        });
    } else {
      login()
        .then(() => {
          console.log('LOGIN RESPONSE SUCCESSFUL, WAITING ON RELOAD');
        })
        .catch(er => {
          // revert back to logged out state
          setDisabled(false);
          dispatch({ type: 'logout' });
        });
    }
  };

  // GET THE USER SESSION STATUS ON INIT
  useEffect(() => {
    let mounted = true;

    getCwpSessionInfo()
      .then(() => {
        if (mounted) {
          dispatch({ type: 'login' });
          registerWithSessionTimeout(() => {
            return setSessionWarning(true);
          });

          registerWithSessionRefresh(() => {
            setSessionWarning(false);
          });
          return true;
        }
      })
      .catch(err => {
        if (mounted) {
          return dispatch({ type: 'logout' });
        }
      })
      .finally(() => {
        if (mounted) {
          setDisabled(false);
        }
      });

    return function cleanup () {
      mounted = false;
    };
  }, []);

  const getLabel = () => {
    let label = '';
    if (!state.USER_LOGGED_IN) {
      label = props.label || thePolyGlot.t('login_label');
    } else {
      label = thePolyGlot.t('logout_label');
    }
    return label;
  };

  return (
    <div className={props.inverted ? 'logInOut inverted' : 'logInOut'}>
      <span
        onClick={onClickLoginOut}
        onKeyPress={e => (e.key === ' ' || e.key === 'Enter' ? onClickLoginOut(e) : e.preventDefault())}
        className={`cwpFocusVisible App-link font-weight-bold ${disabled ? 'App-link disabled' : ''}`}
        role='button'
        tabIndex={0}
      >
        {getLabel()}
      </span>
      {!!state.USER_LOGGED_IN && !!DISPLAY_SESSION_WARNING && <DisplayTimeoutWarning />}
    </div>
  );
};

export default LoginOut;
