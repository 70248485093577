import React, { Component } from 'react';
import Faq from 'react-faq-component';
import '../faqCanada/faqCA.scss';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import goback from '../../../assets/faq/goback.png';
import { historyBack } from './faqCAhome';
import { setTitle } from '../../../providers/documentTitleProvider';

export default class MessageFaqCA extends Component {
  componentDidMount () {
    setTitle('document_titles.faq.message_center');
  }
  render () {
    let translator = getLocaleTranslator();

    return (
      <div className='faqCAheader' role='main' id='main-content'>
        <div id='exit-editing-link'>
          <span
            id='backButton'
            class='cwpBack'
            role='button'
            onClick={historyBack}
            onKeyPress={e => {
              e.preventDefault();
              if (e.key && (e.key === 'Enter' || e.key === ' ')) {
                historyBack(e);
              }
            }}
            tabIndex={0}
          >
            {translator.t('button.back')}
          </span>
        </div>
        <div className='message-header'>
          <div className='td-ms-header'>
            <h1>{translator.t('message_title')}</h1>
          </div>
        </div>
        <Faq data={data} />
      </div>
    );
  }
}

const data = {
  rows: [
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          How do I refresh the vehicle status information?{' '}
        </div>
      ),
      content: ` 
      <span class='mdlyr'>Model Year 2022+:</span> Refreshing your vehicle is as easy as pressing a button. From the Volkswagen Car-Net® web portal, press the refresh symbol (half arrow circle) next to the timestamp to get updated information. On the Volkswagen Car-Net® mobile app pull down on the date and time text to refresh the data on your vehicle status. 
       `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          What are Push Notifications and how are they used?{' '}
        </div>
      ),
      content: ` 
      <span class='mdlyr'>Model Year 2022+:</span>Push notifications are alerts you receive through the mobile app regarding your vehicle status. For example, Volkswagen Car-Net® will check door status approximately 30 seconds after the engine is shut off to confirm whether or not your doors are unlocked. Or if rain is in the forecast, Volkswagen Car-Net® will check window status, and if they are down will send you an alert.
        `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          {' '}
          How do I add an additional driver on my account?{' '}
        </div>
      ),
      content: ` 
      <span class='mdlyr'>Model Year 2022+:Press the “Add Vehicle” button in the Volkswagen Car-Net® mobile app and follow the instructions. If your vehicle is still not in your virtual garage, call the Volkswagen Car-Net® response center at 1-833-435-1013.
      
      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Why do I get my Push Notification long after I turned off my Volkswagen vehicle?
        </div>
      ),
      content: ` 
      <span class='mdlyr'>Model Year 2022+:</span>Press the “Add Vehicle” button in the Volkswagen Car-Net® mobile app and follow instructions. If your vehicle is still not in your virtual garage call the Volkswagen Car-Net® Response Centre at 1-833-435-1013. Model Year 2022: Primary Drivers have access to all settings and features they are eligible for. Additional drivers can still use remote features and POIs but cannot edit other's account information. However, the Primary driver can grant them access to certain things like Guardian Services and Vehicle Health Reports.  
      
      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          How do I turn Push Notifications On/Off?{' '}
        </div>
      ),
      content: `<span class='mdlyr'>Model Year 2022+:</span>If services are requested that do not require ID verification, then the services will be delivered. If the services require ID verification, and the ID cannot confirmed, then the services will not be delivered. Anything related to the account or personal information of the subscriber, requires ID verification of the caller.   
      
      `
    },
    {
      title: (
        <div className='faqCAheader cwpFocusVisible' tabIndex={0} role='button'>
          Where can I view all the activity that has occurred on my account?{' '}
        </div>
      ),
      content: `<span class='mdlyr'>Model Year 2022+:</span>Volkswagen Car-Net® has partnered with Rogers Communications to provide affordable Wi-Fi mobile plans, which are required for a Volkswagen Car-Net® Hotspot. Hotspot pre-paid plans powered by Rogers can be purchased through the “Buy Plans” page on 
                <a href="https://www.vw.ca/en/why-vw/innovation-and-technology/car-net.html" target="_blank">www.vw.ca/en/why-vw/innovation-and-technology/car-net.html</a> or through the Volkswagen Car-Net® mobile app. Please note that you have to be enroled in Volkswagen Car-Net® to purchase Wi-Fi plans. Expect more mobile carriers to partner with Volkswagen Car-Net® in the near future. 
      
      `
    }
  ]
};

export { MessageFaqCA };
