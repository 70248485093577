import React from 'react';
import './tripStats.scss';
import brakes from '../../../assets/tripStats/brakes.png';
import idle from '../../../assets/tripStats/idle.png';
import nighttime from '../../../assets/tripStats/nighttime-driving.png';
import speed from '../../../assets/tripStats/speed.png';
import info from '../../../assets/tripStats/info.png';
import up from '../../../assets/tripStats/chevron-up.png';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { Col, Row } from 'reactstrap';
import { isUserPrimary } from '../../../providers/userRolesProvider';

const DriveViewFeatures = props => {
  const translator = getLocaleTranslator();
  const userIsPrimary = isUserPrimary();
  return (
    <div className='rts-driveview-features'>
      <div className='rts-hide' onClick={props.viewDriveViewFeatures}>
        {translator.t('trips.hide_label')}
        <img src={up} className='up-icon' alt={translator.t('trips.up_logo')} />
      </div>
      <Row>
        <div className='rts-get-your-driveview'>
          {translator.t('trips.get_your_driveview_label')}&nbsp;
          <img
            src={info}
            className='driview-feature-icon'
            alt={translator.t('trips.info_logo')}
            onClick={props.toggleAboutDriveViewModal}
          />
        </div>
      </Row>
      <Row>
        <Col md={3}>
          <div className='feature-icon'>
            <img src={brakes} className='driview-feature-icon' alt={translator.t('trips.brakes_logo')} />
            <span className='rts-driveview'>{translator.t('trips.hard_breaking_label')}</span>
          </div>
        </Col>
        <Col md={3}>
          <div>
            <img src={nighttime} className='driview-feature-icon' alt={translator.t('trips.night_logo')} />
            <span className='rts-driveview'>{translator.t('trips.night_driving_label')}</span>
          </div>
        </Col>
        <Col md={3}>
          <div>
            <img src={speed} className='driview-feature-icon' alt={translator.t('trips.speed_logo')} />
            <span className='rts-driveview'>{translator.t('trips.high_speed_driving_label')}</span>
          </div>
        </Col>
        <Col md={3}>
          <div>
            <img src={idle} className='driview-feature-icon' alt={translator.t('trips.idle_time_logo')} />
            <span className='rts-driveview'>{translator.t('trips.idle_time_label')}</span>
          </div>
        </Col>
      </Row>
      <br />
      <br />
      {userIsPrimary && props.remoteTripDetails && props.remoteTripDetails.tripWiseEnrolled === false && (
        <Row>
          <div className='driview-btn'>
            <button type='button' className='driview-enroll-btn' onClick={props.callDriveView}>
              {translator.t('trips.enroll_in_driveview')}
            </button>
          </div>
        </Row>
      )}
    </div>
  );
};

export default DriveViewFeatures;
