import React from 'react';
import Axios from 'axios';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { validateEmail } from '../../../providers/emailValidationProvider';
import { isEmailShaped } from '../../../util/utilityMethods';
import { logout } from '../../../providers/logInNOutProvider';
import { Container, Row, Col, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import '../../reusable_cmp_lib/ThemedFormInputs/ThemedForm.scss';
import '../../reusable_cmp_lib/ThemedButtonsAssortment/ThemedButtonsAssortment.scss';
import './AccountManagement.scss';
import { setTitle } from '../../../providers/documentTitleProvider';

class UsernameEditor extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      inProgressEmail: '',
      invalidEmail: false,
      failedSave: false,
      successSave: false
    };
  }

  handleTextEditing = evt => {
    this.state.inProgressEmail = evt.target.value;
    let isInvalidEmail = this.state.inProgressEmail.length === 0 || !isEmailShaped(this.state.inProgressEmail);
    this.setState({
      invalidEmail: isInvalidEmail
    });
  };

  cancelHandler = evt => {
    this.setState(
      {
        inProgressEmail: '',
        invalidEmail: false,
        failedSave: false
      },
      () => {
        window.location.assign('#/acct-mgmt/personal');
      }
    );
  };

  updateUserEmail = async data => {
    console.log('UsernameEditor >> updateUserEmail >> ', JSON.stringify(data));
    let url = '/changeusername/step2';
    return Axios({
      method: 'POST',
      url,
      data
    });
  };

  displaySaveFailureModal = () => {
    this.setState({
      failedSave: true
    });
  };

  displaySuccessModal = () => {
    this.setState({
      failedSave: false,
      successSave: true
    });
  };

  logoutConfirmation = () => {
    return logout();
  };

  saveNewUsername = async () => {
    if (this.state.inProgressEmail == null || this.state.invalidEmail) {
      return;
    }
    if (!isEmailShaped(this.state.inProgressEmail)) {
      this.setState({
        invalidEmail: true
      });
      return;
    }

    let emailAddressIsValid = false;
    try {
      emailAddressIsValid = await validateEmail(this.state.inProgressEmail);
    } catch (e) {
      console.log('email address validation failed ', e);
    }
    // email status remains false
    if (!emailAddressIsValid || String(emailAddressIsValid) === 'invalid') {
      return this.setState({ invalidEmail: true });
    }
    this.setState({ invalidEmail: false });

    try {
      await this.updateUserEmail({ email: this.state.inProgressEmail });
      this.displaySuccessModal();
    } catch (err) {
      this.displaySaveFailureModal();
    }
  };

  render () {
    setTitle('document_titles.account_management.edit_email');
    this.translator = getLocaleTranslator();
    const removeBorder = {
      border: 'none'
    };

    const vspacer = {
      height: '30px'
    };

    return (
      <div id='standalone-parent-editor'>
        <div className='editTitle'>
          <h1 className='h3'>{this.translator.t('acctmgmt_pi_update_link_label')}</h1>
        </div>
        <Container fluid={true}>
          <Row>
            <Col md='4'></Col>
            <Col md='8'>
              <div className='editor-firstrow-label-aligner'>
                <span className='first-editor-field-label'>
                  <label htmlFor='the-userid-entry'>{this.translator.t('acctmgmt_pi_field_label_userid')}</label>
                </span>
                <div id='update-uername-instructions-blurb'>
                  {this.translator.t('acctmgmt_text_useridchange_instrux')}
                </div>
              </div>
              <div>
                <br />
              </div>
              <div class='problematic-div'>
                <Input
                  className='userid-entry-input'
                  type='text'
                  name='userid'
                  id='the-userid-entry'
                  onChange={this.handleTextEditing}
                  placeholder={this.translator.t('acctmgmt_text_label_newuserid')}
                  required
                />

                {this.state.invalidEmail === true && (
                  <div class='indicator-div' id='invalid-phonenumber-indicator'>
                    {this.translator.t('acctmgmt_invalid_email')}
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md='4'></Col>
            <Col md='8'>
              <Modal isOpen={this.state.failedSave} className='carnet-sg-modal'>
                <ModalHeader>{this.translator.t('acctmgmt_xd_api_failure')}</ModalHeader>
                <ModalBody>{this.translator.t('acctmgmt_exchange_api_choke_dtl')}</ModalBody>
                <ModalFooter>
                  <Button className='modal-primary-btn' color='modal_background_color' onClick={this.cancelHandler}>
                    {this.translator.t('acctmgmt_label_ok')}
                  </Button>
                </ModalFooter>
              </Modal>

              <Modal isOpen={this.state.successSave} className='carnet-sg-modal'>
                <ModalHeader>{this.translator.t('acctmgmt_xd_api_success')}</ModalHeader>
                <ModalBody>
                  {this.translator.t('acctmgmt_xd_api_success_message')}
                  <br />
                  <br />
                  {this.translator.t('acctmgmt_xd_api_success_explanation', {
                    emailAddress: this.state.inProgressEmail
                  })}
                </ModalBody>
                <ModalFooter>
                  <Button
                    className='modal-primary-btn'
                    color='modal_background_color'
                    onClick={this.logoutConfirmation}
                  >
                    {this.translator.t('acctmgmt_label_ok')}
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
          <Row>
            <Col md='4'></Col>
            <Col md='3'>
              <div class='vertical-spacer-tiny'>&nbsp;</div>
            </Col>
            <Col md='1'>&nbsp;</Col>
          </Row>
          <Row>
            <Col md='6'>&nbsp;</Col>
            <Col md='1'></Col>
            <Col md='2'>
              <div class='right-aligner'>
                <Button onClick={this.cancelHandler} className='cwpSecondary' color='secondary'>
                  {this.translator.t('cancel_btn_camel')}
                </Button>
              </div>
            </Col>
            <Col md='2'>
              <div class='left-aligner'>
                <Button onClick={this.saveNewUsername} className='cwp' color='primary'>
                  {this.translator.t('submit_label')}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default UsernameEditor;
