import React from 'react';
import { Modal, ModalBody, Form, UncontrolledAlert, Input } from 'reactstrap';
import { findErrorMessage } from '../../../util/i18n/i18nService';
import * as garageProvider from '../providers/GarageProvider';
import Spinner from '../Spinner';

class ModalInputOTP extends React.Component {
  state = {
    otp: '',
    spin: '',
    alert: null
  };

  clearAlerts = () => this.setState({ alert: null });

  getAlerts = () => {
    if (this.state.alert) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts}>
          {this.state.alert}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  resetSpinWithOTP = async e => {
    e.preventDefault();

    const form = e.currentTarget.form;
    if (!form.checkValidity()) {
      form.reportValidity();

      return;
    }

    if (!this.state.otp || this.state.otp.length !== 6 || !this.state.spin || this.state.spin.length !== 4) {
      return;
    }

    try {
      let data = await garageProvider.resetSpinWithOTP({
        otp: this.state.otp,
        spin: this.state.spin
      });

      if (data.error) {
        this.setState({ alert: findErrorMessage(data.error, this.props.translator) });

        return;
      }

      this.props.onSuccess(data);
    } catch (err) {
      this.setState({ error: findErrorMessage(err, this.props.translator) });
    }
  };

  render () {
    return (
      <Modal
        isOpen={this.props.visible}
        toggle={this.props.toggle}
        centered={true}
        className='preVehicleAuth'
        labelledBy='lblResetPin'
        autoFocus={false}
        keyboard={true}
        onOpened={e => {
          try {
            document
              .getElementById('txtVerificationCode')
              .focus()
              .select();
          } catch (err) {
            try {
              document
                .getElementById('txtVerificationCode')
                .focus()
                .select();
            } catch (err) {
              console.log('unable to set autofocus');
            }
          }
        }}
      >
        <h2 id='lblResetPin' className='m-3 h5' aria-live={'polite'}>
          {this.props.translator.t('reset_spin.title')}
        </h2>

        <ModalBody>
          {this.getAlerts()}
          <Form hidden={this.state.processing}>
            {this.props.translator.t('reset_spin.desc')}
            <br />
            <br />
            <div className='form-row justify-content-md-center'>
              <div className='form-group cwpCol my-3'>
                <label htmlFor='txtVerificationCode' className='cwp float-left'>
                  {this.props.translator.t('reset_spin.verification_code')}:{' '}
                </label>
                <Input
                  type='text'
                  className='cwp ml-0'
                  maxLength='6'
                  placeholder={this.props.translator.t('reset_spin.verification_code')}
                  autoComplete='off'
                  title={this.props.translator.t('reset_spin.6_digit_number_only')}
                  onChange={e => {
                    let cleanedOTP = String(e.currentTarget.value).replace(/[^0-9]/gi, '');
                    this.setState({ otp: cleanedOTP, alert: null });
                  }}
                  required={true}
                  pattern='[0-9]{6}'
                  value={this.state.otp}
                  id='txtVerificationCode'
                />
              </div>
              <div className='form-group cwpCol'>
                <label htmlFor='txtNewPIN' className='cwp float-left'>
                  {this.props.translator.t('reset_spin.new_pin')}:{' '}
                </label>
                <Input
                  type='password'
                  className='cwp ml-0'
                  maxLength='4'
                  placeholder={this.props.translator.t('reset_spin.new_pin')}
                  autoComplete='off'
                  title={this.props.translator.t('reset_spin.4_digit_number_only')}
                  onChange={e => {
                    let cleanedSpin = String(e.currentTarget.value).replace(/[^0-9]/gi, '');
                    this.setState({ spin: cleanedSpin, alert: null });
                  }}
                  required={true}
                  pattern='[0-9]{4}'
                  value={this.state.spin}
                  id='txtNewPIN'
                />
              </div>
            </div>
            <br />
            <br />

            <div className='row justify-content-md-center'>
              <div className='col-12'>
                <button
                  className='cwp float-left'
                  type='submit'
                  disabled={this.state.spin.length < 4 || this.state.otp.length < 6}
                  onClick={this.resetSpinWithOTP}
                >
                  {this.props.translator.t('button.submit')}
                </button>
                <button className='cwpSecondary float-right' type='button' onClick={this.props.toggle}>
                  {this.props.translator.t('button.cancel')}
                </button>
              </div>
            </div>
          </Form>
          <Spinner hidden={!this.state.processing} />
        </ModalBody>
      </Modal>
    );
  }
}

export default ModalInputOTP;
