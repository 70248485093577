// import "Logic" (API connections)
import getUserInfoLogic from './user/userInfoLogic';
import getCarnetUserPrivilegesLogic from './common/getCarnetUserPrivilegesLogic';
import getCarNickNameLogic from './common/getCarNickNameLogic';
import getPreferredDealerLogic from './common/getPreferredDealerLogic';
import getVehicleHealthByVehicleIdLogic from './vehicleHealth/getVehicleHealthByVehicleIdLogic';
import refreshVehicleHealthLogic from './vehicleHealth/refreshVehicleHealthLogic';
import getVehicleHistoryInfoLogic from './vehicleHistory/vehicleHistoryInfoLogic';
import deleteVehicleHistoryEventLogic from './vehicleHistory/deleteVehicleHistoryEventLogic';
import getStorePlansCartInfoLogic from './buyPlans/storePlansCartInfoLogic';
import getCustomerAccountDetailsLogic from './accountManagement/getCustomerAccountDetailsLogic';
import getUserSecurityQuestionsLogic from './accountManagement/getUserSecurityQuestionsLogic';
import saveUserSecurityQuestionsLogic from './accountManagement/saveUserSecurityQuestionsLogic';
import getCarnetCustomerStaticDataLogic from './common/getCarnetCustomerStaticDataLogic';
import getDealerUrlsLogic from './common/getDealerUrlsLogic';
import getVehicleHealthByServiceKeyLogic from './vehicleHealth/getVehicleHealthByServiceKeyLogic';
import sendVHReportByServiceKeyLogic from './vehicleHealth/sendVHReportByServiceKeyLogic';
import getVHRCustomerPreferencesLogic from './vehicleHealth/getVHRCustomerPreferencesLogic';
import getSirisxmSubscriptionStatusLogic from './vehicleHealth/getSirisxmSubscriptionStatusLogic';
import updateCustomerDetailsLogic from './vehicleHealth/updateCustomerDetailsLogic';
import updateVHRCustomerPreferencesLogic from './vehicleHealth/updateVHRCustomerPreferencesLogic';
import getStorePlanPricingInfoLogic from './buyPlans/storePlanPricingInfoLogic';
import getStoreCartItemsLogic from './buyPlans/storeCartItemsLogic';
import getStoreCurrentWalletDetailsLogic from './buyPlans/storeCurrentWalletDetails';

export default [
  ...getUserInfoLogic,
  ...getCarnetUserPrivilegesLogic,
  ...getCarNickNameLogic,
  ...getPreferredDealerLogic,
  ...getVehicleHealthByVehicleIdLogic,
  ...refreshVehicleHealthLogic,
  ...getVehicleHistoryInfoLogic,
  ...deleteVehicleHistoryEventLogic,
  ...getStorePlansCartInfoLogic,
  ...getCustomerAccountDetailsLogic,
  ...getUserSecurityQuestionsLogic,
  ...saveUserSecurityQuestionsLogic,
  ...getCarnetCustomerStaticDataLogic,
  ...getDealerUrlsLogic,
  ...getVehicleHealthByServiceKeyLogic,
  ...sendVHReportByServiceKeyLogic,
  ...getVHRCustomerPreferencesLogic,
  ...getSirisxmSubscriptionStatusLogic,
  ...updateCustomerDetailsLogic,
  ...updateVHRCustomerPreferencesLogic,
  ...getStorePlanPricingInfoLogic,
  ...getStoreCartItemsLogic,
  ...getStoreCurrentWalletDetailsLogic
];
