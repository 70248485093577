import { getVehicleContext } from './historyProvider';
import { findIn } from '../util/utilityMethods';

const getUserRole = () => {
  let currentVehicleContext = getVehicleContext();
  let userRole = findIn('role', currentVehicleContext);
  return userRole || null;
};

const isUserPrimary = () => {
  let userRole = getUserRole();
  return userRole && userRole.toLowerCase() === 'pu';
};

const UserRole = Object.freeze({
  PRIMARY_USER: 'PU',
  SECONDARY_USER: 'SU'
});

export { getUserRole, isUserPrimary, UserRole };
export default getUserRole;
