import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import './modelYr2014_19.scss';
const Trial = () => {
  return (
    <div>
      <table className='sunset-3g'>
        <tr>
          <td className='sunset-3g-td'>
            <div className='view-non-responsive'>
              Model Year <br />
              2017-2019 <br />
              Car-Net <br />
              Equipped <br />
              Vehicles{' '}
            </div>
            <div className='view-responsive'>
              Model Year 2017-2019 Car-Net <br /> Equipped Vehicles{' '}
            </div>
            <br />
            <br />
          </td>
          <td className='sunset-3g-td-right'>
            <br />
            <span>
              Volkswagen has been working on a technical solution that will restore your vehicle&apos;s connectivity and
              enable resumption of some telematics services.{' '}
              <b>
                Due to unanticipated difficulties, the timing to release any technical solution is uncertain at this
                time. We will update this page when we have more information.
              </b>
              <br />
              <br /> Without a technical solution, Car-Net features, including emergency assistance, anti-theft alerts
              and automatic crash notifications will remain deactivated.
            </span>
            <br />
            <br />
            <br />
          </td>
        </tr>
      </table>
    </div>
  );
};

export default Trial;
