import React from 'react';
import {
  saveAdditionalDriver,
  deleteDriverFromVehicle,
  updateSecondaryUserPriveleges
} from '../providers/AccountAndSecurityProvider';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import {
  isValidNAPhoneNumber,
  isNonEmptyObject,
  isEmailShaped,
  isNullOrEmptyObject,
  filterInvalidInputCharacters
} from '../../../util/utilityMethods';
import carnet_constants from '../../../constants/carnet_constants';
import ThemedToast from '../../reusable_cmp_lib/ThemedToast/ThemedToast';
import { isUserPrimary } from '../../../providers/userRolesProvider';
import { validateEmail } from '../../../providers/emailValidationProvider';
import { Row, Col, Button, Input, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { US_PHONE_NUMBER_PATTERN, formatPhoneNumber, digitsOnly } from '../CarnetHome';
import ThemedCheckBox from '../../reusable_cmp_lib/ThemedCheckBox/ThemedCheckBox';
import '../../reusable_cmp_lib/ThemedFormInputs/ThemedForm.scss';
import '../../reusable_cmp_lib/ThemedButtonsAssortment/ThemedButtonsAssortment.scss';
import './AccountManagement.scss';
import { setTitle } from '../../../providers/documentTitleProvider';
import addDriver from '../../../assets/accountManagement/add_driver.png';

const FIRST_NAME = 'First Name',
  LAST_NAME = 'Last Name',
  PHONE_NUM = 'Phone Number',
  EMAIL_ADDRESS = 'Email Address';

// props.primaryUser
class AdditionalDrivers extends React.Component {
  constructor (props) {
    super(props);
    this.undocumentedDate = new Date(2020, 11, 15).getTime();
    this.checkboxConfig = [
      { access: 'acctmgmt_label_vhr', checked: false, code: 'VH:*:W' },
      { access: 'acctmgmt_label_trips', checked: false, code: 'TS:*:W' },
      { access: 'acctmgmt_label_guardian', checked: false, code: 'GS:*:D' }
    ];

    this.state = {
      driversList: [],
      mode: 'view',
      editor_first_name: '',
      editor_last_name: '',
      editor_phone_number: '',
      editor_email: '',
      accessLevels: this.checkboxConfig,
      editor_email_invalid: false,
      editor_phone_invalid: false,
      duplicateWarning: false,
      showOrphanDeleteWarning: false,
      userId: null,
      vehicleId: null,
      originalPrivList: [],
      originalAlaCarte: []
    };

    this.translator = getLocaleTranslator();
  }

  componentDidMount () {
    let driversList = this.resolveAdditionalDrivers();
    this.setState({ driversList }, () => {
      if (this.props.focus) {
        try {
          document.getElementById('firstActiveFocus').focus();
        } catch (err) {
          try {
            document.getElementById('secondActiveFocus').focus();
          } catch (err) {
            console.log('no element not found for autofocus');
          }
        }
      }
    });
  }

  componentDidUpdate (prevProps) {
    let prev = (prevProps.accountDetails && prevProps.accountDetails.allAssociatedUsers) || [];
    let cur = (this.props.accountDetails && this.props.accountDetails.allAssociatedUsers) || [];
    if (JSON.stringify(prev) !== JSON.stringify(cur)) {
      setTitle('document_titles.account_management.additional_drivers');
      let injectedDriversList = this.resolveAdditionalDrivers();
      if (JSON.stringify(injectedDriversList) !== JSON.stringify(this.state.driversList)) {
        this.setState({
          driversList: injectedDriversList
        });
      }
    }
  }

  getLabelForPrivelegeCode = privelege => {
    let matchingRecords = this.checkboxConfig.filter(record => {
      return record.code === privelege;
    });
    if (matchingRecords === null || matchingRecords.length === 0) {
      return '';
    }
    return matchingRecords[0].access;
  };

  toggleMode = () => {
    if (!isUserPrimary()) {
      console.log('not primary user, editing/adding not allowed');
      return;
    }

    let currentMode = this.state.mode;
    let nxMode = currentMode === 'view' ? 'add' : 'view';
    this.setState(
      {
        mode: nxMode
      },
      () => {
        if (nxMode === 'add') {
          setTitle('document_titles.account_management.add_additional_drivers');
          document.getElementById('ad_first_name').focus();
        } else {
          setTitle('document_titles.account_management.additional_drivers');
        }
      }
    );
  };

  deleteAddedDriver = index => {
    let targetDriver = this.state.driversList[index];
    const targetDriverId = targetDriver.userId;
    deleteDriverFromVehicle(targetDriverId)
      .then(resp => {
        if (resp && resp.data && resp.data.outcome && resp.data.outcome === 'success') {
          let mutableClone = JSON.parse(JSON.stringify(this.state.driversList));
          mutableClone.splice(index, 1);
          this.setState(
            {
              driversList: mutableClone,
              saveActionNotification: 'success'
            },
            // show modal
            // refresh user/account info after deleting additional driver
            this.props.refreshSourceDataCallback
          );
        }
      })
      .catch(err => {
        // show modal
        console.log('Error from the Delete operation: ' + JSON.stringify(err));
        this.setState({ saveActionNotification: 'failure' });
      });
  };

  newEntryValid = () => {
    let firstNamePresent = this.state.editor_first_name && this.state.editor_first_name !== FIRST_NAME;
    let lastNamePresent = this.state.editor_last_name && this.state.editor_last_name !== LAST_NAME;
    let phonePresent = this.state.editor_phone_number && this.state.editor_phone_number !== PHONE_NUM;
    let phoneIsValid = isValidNAPhoneNumber(this.state.editor_phone_number);
    let emailPresent = this.state.editor_email && this.state.editor_email !== EMAIL_ADDRESS;
    let thingToReturn = !!(firstNamePresent && lastNamePresent && phonePresent && emailPresent && phoneIsValid);
    return thingToReturn;
  };

  cancelEdit = () => {
    this.setState(
      {
        mode: 'view',
        editor_first_name: '',
        editor_last_name: '',
        editor_phone_number: '',
        accessLevels: this.checkboxConfig,
        editor_email: '',
        editor_email_invalid: false,
        editor_phone_invalid: false,
        userId: null,
        vehicleId: null,
        originalPrivList: [],
        originalAlaCarte: []
      },
      () => {
        setTitle('document_titles.account_management.additional_drivers');
      }
    );
  };

  handleTextEditing = (fieldName, evt) => {
    let statePatch = {};
    let presentTxt = evt.target.value;

    if (fieldName === 'editor_phone_number') {
      //Here, they are STILL TYPING the phone number! Not Yet Entered fully!
      //The Invalid indicator will appear but disappear as soon as they completely enter
      //a valid length phone number (NA)
      presentTxt = digitsOnly(presentTxt);
      // do comparison if is the exact same, means an attempt to delete a formatting character happened
      if (presentTxt && presentTxt === this.state.editor_phone_number) {
        presentTxt = presentTxt.slice(0, -1);
      }
      statePatch['editor_phone_invalid'] = !isValidNAPhoneNumber(presentTxt); //switches it ON/OFF as they complete their entry
    }

    if (fieldName === 'editor_email') {
      //Here, User is STILL TYPING the email address! Not Yet Entered fully!
      //This will re-execute for every character typed!
      //So: We DO NOT Want to do the FULL TOWER API Email validation HERE. Only the Regex-test HERE.
      //We Do do that TOWER API CALL VALIDATION once: Prior to Saving, if they click the Save button.
      statePatch['editor_email_invalid'] = !isEmailShaped(presentTxt); //switches it ON/OFF as they complete their entry
    }

    if (['editor_first_name', 'editor_last_name'].includes(fieldName)) {
      presentTxt = filterInvalidInputCharacters(evt.target.value);
    }

    statePatch[fieldName] = presentTxt;
    this.setState(statePatch);
  };

  accessCheckboxToggled = (arrIndex, evt) => {
    let checkedState = evt.target.checked;
    //(avoid direct-state-mutation ....)
    let accessStateClone = JSON.parse(JSON.stringify(this.state.accessLevels));
    //drill into it
    accessStateClone[arrIndex].checked = checkedState;
    this.setState({
      accessLevels: accessStateClone
    });
  };

  resolveAdditionalDrivers = () => {
    const injectedAssociatedUsers = (this.props.accountDetails && this.props.accountDetails.allAssociatedUsers) || [];
    if (injectedAssociatedUsers.length === 0) {
      return [];
    }
    let additionalDrivers = [];

    injectedAssociatedUsers.forEach(user => {
      const userRoles = user?.roles || [];
      if (!(userRoles.includes('SU') || userRoles.includes('GU'))) {
        return; ///continue to the next user
      }
      const alaCartePriveleges = [...(user.alaCartePriveleges || []), 'TS:*:R'];

      let userPrivileges = user.privileges || [];
      let filteredPriveleges = [...userPrivileges];
      filteredPriveleges = filteredPriveleges.filter(priv => {
        return alaCartePriveleges.includes(priv);
      });
      additionalDrivers.push({
        userId: user.userId,
        vehicleId: user.vehicleId,
        firstName: user.firstName,
        lastName: user.lastName,
        priveleges: filteredPriveleges,
        originalPrivList: [...userPrivileges],
        originalAlaCarte: [...alaCartePriveleges]
      });
    });
    return additionalDrivers;
  };

  editExistingDriver = listIndex => {
    let targetDriver = this.state.driversList[listIndex];
    let phoneNumber = targetDriver.phone?.number || '';
    let targetAccessCodes = targetDriver?.priveleges || targetDriver?.privileges || [];
    //deepclone the original checkbox config
    let presentAccessCheckstates = JSON.parse(JSON.stringify(this.checkboxConfig));
    presentAccessCheckstates.forEach(checkstate => {
      if (targetAccessCodes.includes(checkstate.code) || targetAccessCodes.includes(carnet_constants.TS_R)) {
        checkstate.checked = true;
      }
    });

    let nxState = {
      mode: 'editAccess',
      editor_first_name: targetDriver.firstName || '',
      editor_last_name: targetDriver.lastName || '',
      editor_phone_number: phoneNumber,
      editor_email: targetDriver.email || '',
      accessLevels: presentAccessCheckstates,
      userId: targetDriver.userId,
      vehicleId: targetDriver.vehicleId,
      originalPrivList: targetDriver.originalPrivList,
      originalAlaCarte: targetDriver.originalAlaCarte
    };
    this.setState(nxState, () => {
      setTitle('document_titles.account_management.edit_additional_drivers');
    });
  };

  readSelectedPriveleges = () => {
    let allCheckstates = this.state.accessLevels;
    if (allCheckstates === null || allCheckstates.length === 0) {
      return [];
    }

    let selectedCheckboxes = allCheckstates.filter(checkbox => {
      return checkbox.checked === true;
    });

    if (selectedCheckboxes.length === 0) {
      return [];
    }
    const selectedPriveleges = selectedCheckboxes.map(checkbox => {
      return checkbox.code;
    });
    return selectedPriveleges;
  };

  detectAddingDuplicateDriver = (candidateDriver, existingAddtlDrivers) => {
    if (isNullOrEmptyObject(existingAddtlDrivers) || existingAddtlDrivers.length === 0) {
      return false;
    }

    let isDupe = false;
    existingAddtlDrivers.forEach(existingRecord => {
      if (isDupe === true) {
        return; //cant use 'break' in a forEach. This aborts this lambda upon true
      }

      if (
        existingRecord.firstName === candidateDriver.firstName &&
        existingRecord.lastName === candidateDriver.lastName
      ) {
        isDupe = true;
      }

      if (existingRecord.email && candidateDriver.email && existingRecord.email === candidateDriver.email) {
        isDupe = true;
      }
    });

    if (isDupe === true) {
      this.setState(
        {
          duplicateWarning: true
        },
        this.clearInvalidEntry
      );
    } else {
      this.setState({
        duplicateWarning: false
      });
    }

    return isDupe;
  };

  clearInvalidEntry = () => {
    this.setState({
      editor_first_name: '',
      editor_last_name: '',
      editor_phone_number: '',
      editor_email: ''
    });
  };

  saveEdits = async () => {
    // selected privileges
    const selectedPriveleges = this.readSelectedPriveleges();

    if (this.state.mode === 'editAccess') {
      let updatedPrivs = [...this.state.originalPrivList];
      this.state.originalAlaCarte.forEach(privCode => {
        // it is there in the list make sure it is in the updatedList
        if (selectedPriveleges.includes(privCode)) {
          // not there, push it in the list
          if (!updatedPrivs.includes(privCode)) {
            updatedPrivs.push(privCode);
          }
        } else {
          // doesn't include the priv code, remove it from the list if there
          updatedPrivs = updatedPrivs.filter(privInList => privInList !== privCode);
        }
      });

      let payload = {
        roles: ['SU'],
        userId: this.state.userId,
        vehicleId: this.state.vehicleId,
        privileges: updatedPrivs
      };

      try {
        await updateSecondaryUserPriveleges(payload);
        this.setState({ saveActionNotification: 'success' });
      } catch (err) {
        console.log('Unable to update secondary user privileges');
        console.log(err);
        this.setState({ saveActionNotification: 'failure' });
      }

      this.setState(
        {
          mode: 'view',
          editor_first_name: '',
          editor_last_name: '',
          editor_phone_number: '',
          accessLevels: this.checkboxConfig,
          editor_email: '',
          editor_email_invalid: false,
          editor_phone_invalid: false,
          userId: null,
          vehicleId: null,
          originalPrivList: [],
          originalAlaCarte: []
        },
        // get the updated information
        this.props.refreshSourceDataCallback
      );
      return;
    } else {
      let candidateFirstName = this.state.editor_first_name;
      let candidateLastName = this.state.editor_last_name;
      let candidateEmail = this.state.editor_email;
      let candidatePhoneNumber = this.state.editor_phone_number;

      let emailAddressIsValid = false;
      try {
        emailAddressIsValid = await validateEmail(candidateEmail);
      } catch (e) {
        console.log('email address validation failed ', e);
      }

      // verifying not a duplicate email
      let primaryEmail = this.props.accountDetails?.customer?.email;
      if (candidateEmail.toLowerCase() === primaryEmail.toLowerCase()) {
        emailAddressIsValid = false;
      }

      let invalidFlags = {};
      // email status remains false
      if (!emailAddressIsValid || String(emailAddressIsValid) === 'invalid') {
        invalidFlags['editor_email_invalid'] = true;
      }

      if (!isValidNAPhoneNumber(candidatePhoneNumber)) {
        invalidFlags['editor_phone_invalid'] = true;
      }
      if (isNonEmptyObject(invalidFlags)) {
        return this.setState(invalidFlags);
      }

      let runtimeLocaleSetting = this.props.locale || 'en-US';

      //vehicleID below DOES GET POPULATED, inside the ROUTE in Hapi (invisible here)
      /* Told that the external API for some reason doesnt read the x-user-locale HEADER,
         Thus, requiring that we ALSO pass the locale property w the Toggle-switch setting */
      let freshUserObj = {
        firstName: candidateFirstName,
        lastName: candidateLastName,
        email: candidateEmail,
        localeCode: runtimeLocaleSetting,
        phone: {
          number: candidatePhoneNumber,
          type: 'CELL_PHONE_NUM'
        },
        vehicleId: '',
        roles: ['SU'],
        privileges: selectedPriveleges
      };

      let preDriversListState = this.state.driversList || [];
      let mergeReceptor = JSON.parse(JSON.stringify(preDriversListState));
      if (this.detectAddingDuplicateDriver(freshUserObj, mergeReceptor)) {
        //the above detector method takes care of showing notifications/warning msg to User
        return;
      }

      mergeReceptor.push(freshUserObj);

      let saveResponse;
      let payloadObj = {
        customer: freshUserObj
      };
      try {
        saveResponse = await saveAdditionalDriver(payloadObj);
        this.setState({ saveActionNotification: 'success' });
      } catch (err) {
        saveResponse = { error: err };
        this.setState({ saveActionNotification: 'failure' });
      }
      console.log('THe result of saving was: ' + JSON.stringify(saveResponse));
      this.setState(
        {
          driversList: mergeReceptor,
          mode: 'view',
          editor_first_name: '',
          editor_last_name: '',
          editor_phone_number: '',
          accessLevels: this.checkboxConfig,
          editor_email: '',
          editor_email_invalid: false,
          editor_phone_invalid: false
        },
        // get the updated information
        this.props.refreshSourceDataCallback
      );
    } //else block: mode='add'
  };

  disabledWarning = isOn => {
    if (isOn === false) {
      setTimeout(() => {
        this.setState({
          showOrphanDeleteWarning: false
        });
      }, 3000);
    } else {
      this.setState({
        showOrphanDeleteWarning: true
      });
    }
  };

  render () {
    const driverIsPrimary = isUserPrimary();
    let duplicateFoundMode = this.state.duplicateWarning === true;
    const fullEditMode = driverIsPrimary === true && this.state.mode === 'add';
    let isSaveBtnDisabled = false;
    if (this.state.mode === 'add') {
      isSaveBtnDisabled = !this.newEntryValid() || !isUserPrimary();
    }

    return (
      <div className='acctmgmt-contentpane'>
        {this.state.mode === 'view' && (
          <div id='ghost-wrapper' className='vehicle-info-parent'>
            <div className='contentpane-title'>
              <h2 id='firstActiveFocus' tabIndex={-1} className='h6'>
                {this.translator.t('acctmgmt_drivers_heading')}
              </h2>
            </div>

            {this.state.saveActionNotification && (
              <ThemedToast
                title={
                  this.state.saveActionNotification === 'success'
                    ? this.translator.t('acctmgmt_save_indicator_text')
                    : this.translator.t('acctmgmt_saveop_failed')
                }
                display={this.state.saveActionNotification}
                returnFocusAfterClose={false}
                onClosed={() => {
                  this.setState({ saveActionNotification: false });
                  this.props.setFocusOnBackAfterModalDismissed();
                }}
              />
            )}

            <div>
              {/* ITERATE DRIVERS HERE   */}
              {(this.state.driversList || []).map((driver, index) => {
                const driverPriveleges = driver.priveleges || driver.privileges || [];
                return (
                  <Row key={`${index}_${driver.userId}_privilegeGroup`}>
                    <Col md='2'>
                      <span className='carnet-theme-field-label'>{this.translator.t('acctmgmt_ad_addtl_driver')}</span>
                    </Col>
                    <Col md='2'></Col>
                    <Col md='6'>
                      <span ref='pi-name-field' className='carnet-theme-field-value vi-data'>
                        {(driver.firstName || '') + ' ' + (driver.lastName || '')}
                      </span>
                      <br />
                      <span ref='pi-name-field' className='carnet-theme-field-value vi-data'>
                        <b>{driver.email || (driver.phone && driver.phone.number) || ''}</b>
                      </span>
                      <br />
                      {driverIsPrimary &&
                        driverPriveleges?.map((privelege, privIndex) => {
                          const labelKeyForAccess =
                            privelege === carnet_constants.TS_R
                              ? 'acctmgmt_label_trips'
                              : this.getLabelForPrivelegeCode(privelege);

                          let labelForAccess = this.translator.t(labelKeyForAccess);
                          if (labelKeyForAccess === 'acctmgmt_label_guardian') {
                            labelForAccess = ` * ${labelForAccess}`;
                          }
                          return (
                            <div key={`${driver.userId}_${privelege}_${privIndex}`}>
                              <a className='additional-driver-links'>{labelForAccess}</a>
                              <br />
                            </div>
                          );
                        })}
                    </Col>
                    <Col md='2'>
                      {driverIsPrimary && driver.userId && this.state.showOrphanDeleteWarning === true && (
                        <div id='warning-popup'>
                          <div id='warning-popup-text'>
                            {this.translator.t('acctmgmt_warning_orphan_record_delete')}
                          </div>
                        </div>
                      )}

                      {driverIsPrimary === true && (
                        <div>
                          {driver.userId ? (
                            <button
                              className='btn btn-link carnetLinkLight'
                              type='button'
                              onClick={() => this.deleteAddedDriver(index)}
                            >
                              {this.translator.t('delete_label')}
                            </button>
                          ) : (
                            <div
                              id='disabled-link-wrapper'
                              onMouseEnter={() => this.disabledWarning(true)}
                              onMouseLeave={() => this.disabledWarning(false)}
                            >
                              <span className='greyed-out-link'>{this.translator.t('delete_label')}</span>
                            </div>
                          )}
                        </div>
                      )}

                      <div className='vertical-spacer-tiny'></div>
                      <div>
                        {driverIsPrimary === true && (
                          <button
                            className='btn btn-link carnetLinkLight'
                            type='button'
                            onClick={() => this.editExistingDriver(index)}
                          >
                            {this.translator.t('acctmgmt_pi_edit_link_label')}
                          </button>
                        )}
                      </div>
                    </Col>
                  </Row>
                );
              })}

              <Row>
                <Col md='3'>
                  {driverIsPrimary === true && (
                    <button
                      className='btn btn-link carnetLink'
                      id='secondActiveFocus'
                      type='button'
                      disabled={false}
                      onClick={this.toggleMode}
                    >
                      <h2 class='h6'>
                        {' '}
                        <img src={addDriver} className='addDriver' alt='' />{' '}
                        {this.translator.t('acctmgmt_ad_add_nother_driver')}
                      </h2>
                    </button>
                  )}
                </Col>
                <Col md='10'></Col>
              </Row>
            </div>
          </div>
        )}

        {/* render branch */}

        {(this.state.mode === 'add' || this.state.mode === 'editAccess') && (
          <form id='ghost-wrapper' className='vehicle-info-parent'>
            <div className='contentpane-title'>
              <div>{this.translator.t('acctmgmt_add_nother_driver_heading')}</div>
            </div>
            <fieldset>
              <Row className='borderless'>
                <Col md='3'>
                  <legend>
                    {' '}
                    <span className='carnet-theme-field-label'>{this.translator.t('acctmgmt_driver_info')}</span>
                  </legend>
                </Col>
                <Col md='2'>
                  {duplicateFoundMode === true && (
                    <ThemedToast
                      title={this.translator.t('acctmgmt_dupe_warning_lbl')}
                      msg={this.translator.t('acctmgmt_dupe_msg')}
                      display={duplicateFoundMode}
                    />
                  )}
                </Col>
                <Col md='6'>
                  <div className='editor-diatom-field'>
                    <label htmlFor='ad_first_name' className='cwp border-0'>
                      {this.translator.t('acctmgmt_pi_field_label_fname')}
                    </label>
                    <Input
                      disabled={!fullEditMode}
                      className='cwp'
                      onChange={evt => this.handleTextEditing('editor_first_name', evt)}
                      type='text'
                      name='editor_first_name'
                      id='ad_first_name'
                      value={this.state.editor_first_name || ''}
                      required={true}
                    />
                  </div>
                </Col>
                <Col md='2'></Col>
              </Row>
            </fieldset>
            {/* Last Name... */}
            <Row className='borderless'>
              <Col md='5'></Col>
              <Col md='6'>
                <div className='editor-diatom-field'>
                  <label htmlFor='ad_last_name' className='cwp border-0'>
                    {this.translator.t('acctmgmt_pi_field_label_lname')}
                  </label>
                  <Input
                    disabled={!fullEditMode}
                    className='cwp'
                    onChange={evt => this.handleTextEditing('editor_last_name', evt)}
                    type='text'
                    name='editor_last_name'
                    id='ad_last_name'
                    value={this.state.editor_last_name || ''}
                    required={true}
                  />
                </div>
              </Col>
              <Col md='2'></Col>
            </Row>

            {/* phone */}
            <Row className='borderless'>
              <Col md='5'></Col>
              <Col md='6'>
                <div className='editor-diatom-field'>
                  <label htmlFor='ad_phone_number' className='cwp border-0'>
                    {this.translator.t('acctmgmt_pi_field_label_phone')}
                  </label>
                  <Input
                    disabled={!fullEditMode}
                    className='cwp'
                    onChange={evt => this.handleTextEditing('editor_phone_number', evt)}
                    type='text'
                    pattern={US_PHONE_NUMBER_PATTERN}
                    name='editor_phone_number'
                    id='ad_phone_number'
                    maxLength={14}
                    value={(this.state.editor_phone_number && formatPhoneNumber(this.state.editor_phone_number)) || ''}
                    required={true}
                  />
                </div>
                {this.state.editor_phone_invalid && (
                  <div className='invalid-indicator'>{this.translator.t('acctmgmt_invalid_phone_indicator')}</div>
                )}
              </Col>
              <Col md='2'></Col>
            </Row>
            <Row>
              <Col md='5'></Col>
              <Col md='6'>
                <div className='editor-diatom-field'>
                  <label htmlFor='ad_email' className='cwp border-0'>
                    {this.translator.t('acctmgmt_label_eaddress')}
                  </label>
                  <Input
                    disabled={!fullEditMode}
                    className={this.state.editor_email_invalid ? 'cwp invalid_input' : 'cwp'}
                    onChange={evt => this.handleTextEditing('editor_email', evt)}
                    type='text'
                    name='editor_email'
                    id='ad_email'
                    value={this.state.editor_email || ''}
                    required={true}
                  />
                </div>
                {this.state.editor_email_invalid && (
                  <div className='invalid-indicator'>{this.translator.t('acctmgmt_invalid_email_indicator')}</div>
                )}
              </Col>
              <Col md='2'></Col>
            </Row>

            {this.props.tsp && this.props.tsp === carnet_constants.TSP_ATC && (
              <fieldset>
                <Row>
                  <Col md='3'>
                    <legend>
                      {' '}
                      <span className='carnet-theme-field-label'>{this.translator.t('acctmgmt_label_access_lvl')}</span>
                    </legend>
                  </Col>
                  <Col md='2'></Col>
                  <Col md='6'>
                    <div className='editor-diatom-field'>
                      {/* BEGIN LOOP OF ACCESS LEVEL CHECKBOXES HERE   */}
                      {this.state.accessLevels.map((level, index) => {
                        let levelLabel = this.translator.t(level.access);
                        if (level.access === 'acctmgmt_label_guardian') {
                          levelLabel = ` * ` + levelLabel;
                        }
                        return (
                          <div key={'access_rights_' + index}>
                            <ThemedCheckBox
                              disabled={!isUserPrimary()}
                              toggleHandler={evt => {
                                this.accessCheckboxToggled(index, evt);
                              }}
                              name={'accesslvl_' + index}
                              id={'checkbos_accesslvl_' + index}
                              label={levelLabel}
                              labelOverride='insul'
                              isChecked={level.checked}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </Col>
                  <Col md='2'></Col>
                </Row>
              </fieldset>
            )}

            <Row className='borderless no-gutters mx-0 justify-content-md-center text-center'>
              <Col md='2' className='mt-4'>
                <Button disabled={isSaveBtnDisabled} className='cwp' color='primary' onClick={this.saveEdits}>
                  {this.state.mode === 'add'
                    ? this.translator.t('acctmgmt_label_add_driver')
                    : this.translator.t('save_btn_camel')}
                </Button>
              </Col>
              <Col md='2' className='mt-4'>
                <Button
                  disabled={!isUserPrimary()}
                  onClick={this.cancelEdit}
                  className='cwpSecondary'
                  color='secondary'
                >
                  {this.translator.t('cancel_btn_camel')}
                </Button>
              </Col>
            </Row>
          </form>
        )}

        {this.props.tsp !== carnet_constants.TSP_WCT && (
          <div className='acctmgmt-disclaimer'>
            <span className='guardian-role-disclaimer'>* {this.translator.t('acctmgmt_disclaimer_guardian')}</span>
          </div>
        )}
      </div>
    );
  }
}

export default AdditionalDrivers;
