import React, { Component } from 'react';
import TosUBI from '../self_enrollment/TosUBI';
import { findVehicle, findEnrollmentProcedure } from '../providers/SelfEnrollmentProvider.js';
import Loader from '../common/loader';

class EnrollTripWise extends Component {
  constructor () {
    super();
    this.state = {
      showUBI: false
    };
  }

  componentDidMount () {
    this.hanleUBIEnrollment(this.props.vin)
      .then(() => {
        this.setState({ showUBI: true });
      })
      .catch(err => {
        console.log('Error: While enrolling DriveView from Trips');
      });
  }

  hanleUBIEnrollment = async vin => {
    await findVehicle(vin);
    await findEnrollmentProcedure();
  };

  handleSubmit = () => {
    window.location.reload();
  };

  render () {
    return (
      (this.state.showUBI && <TosUBI afterSubmit={this.handleSubmit} />) || (
        <div style={{ height: '500px' }}>
          <Loader />
        </div>
      )
    );
  }
}

export default EnrollTripWise;
