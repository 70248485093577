import { SET_USER_VEHICLE_CONTEXT } from '../../actions/types';
import { setVehicleContext } from '../../../providers/historyProvider';
import { setLocaleToVehicleContext } from '../../../providers/languageProvider';

const initialState = {
  userVehicleContext: {}
};

const userVehicleContext = (state = initialState, action = { payload: {} }) => {
  let actionType = (action && action.type) || 'default';

  if (actionType === SET_USER_VEHICLE_CONTEXT) {
    let newState = { ...state };
    newState['userVehicleContext'] = action.payload;
    // store it for recovery in localStorage (maintain state on browser refresh)
    setVehicleContext(action.payload);
    // set vehicle context for user locale info
    let countryForContext = action.payload && action.payload.country;
    if (countryForContext) {
      setLocaleToVehicleContext(countryForContext);
    }
    return newState;
  }

  return state;
};

export default userVehicleContext;
