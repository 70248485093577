import { GET_STORE_CART_ITEMS, GET_STORE_CART_ITEMS_SUCCESS, GET_STORE_CART_ITEMS_FAILURE } from '../../actions/types';

const initialState = {
  cartInfo: null,
  cartDataLoading: false
};

// Search for customers associated with a particular vehicle
const storeCartItemsReducer = (state = initialState, action = { payload: {} }) => {
  let newState = { ...state };
  let actionType = (action && action.type) || 'default';
  let responseData = action.payload || {};

  switch (actionType) {
    case GET_STORE_CART_ITEMS:
      console.log(`storeCartItemsReducer >> ${actionType}`);
      console.log('action.payload is in storeCartItemsReducer is :::' + JSON.stringify(action.payload));
      newState = { ...initialState };
      return newState;

    case GET_STORE_CART_ITEMS_SUCCESS:
      console.log(`storeCartItemsReducer >> ${actionType}`);
      console.log('action.payload is in storeCartItemsReducer is :::' + JSON.stringify(action.payload));
      let { data } = responseData;
      newState['cartInfo'] = data;
      newState.cartDataLoading = true;
      return newState;

    case GET_STORE_CART_ITEMS_FAILURE:
      console.log(`storeCartItemsReducer >> ${actionType}`);
      console.log('action.payload is in storeCartItemsReducer is :::' + JSON.stringify(action.payload));
      newState['cartInfo'] = null;
      return newState;

    default:
      return newState;
  }
};

export default storeCartItemsReducer;
