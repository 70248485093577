import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';

const Linechart = props => {
  let translator = getLocaleTranslator();

  let lineChart = {
    series: [
      {
        name: translator.t('trips.average_mph_or_kmph_label'),
        data: props.lineChartMphData
      },
      {
        name: translator.t('trips.average_mpg_or_kml_label'),
        data: props.lineChartMpgData
      }
    ],

    options: {
      chart: {
        height: 368,
        type: 'line',
        shadow: {
          enabled: true,
          color: '#000',
          top: 1,
          left: 1,
          blur: 1,
          opacity: 0.45
        },
        toolbar: {
          show: false
        }
      },
      colors: ['#067BC6', '#001e50'],
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        colors: undefined,
        width: 2,
        dashArray: 0
      },
      title: {
        text: '',
        align: 'left',
        style: {
          fontSize: '14px',
          color: '#666666'
        }
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        }
      },
      markers: {
        size: 5,
        colors: undefined,
        strokeColors: '#fff',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        fillOpacity: 1,
        shape: 'circle',
        radius: 1,
        hover: {
          size: undefined,
          sizeOffset: 2
        }
      },
      xaxis: {
        categories: props.xaxisCategories,
        title: {
          text: ''
        },
        labels: {
          style: {
            fontSize: 12
          }
        },
        tickAmount: 5,
        tickPlacement: 'between'
      },
      yaxis: {
        title: {
          text: ''
        },
        labels: {
          formatter: function (value) {
            return value;
          }
        },
        tickAmount: 12,
        min: 0,
        max: 120,
        floating: false,
        forceNiceScale: true,
        range: 10,
        decimalsInFloat: 1
      },
      tooltip: {
        theme: 'light',
        x: {
          show: false
        }
      },
      legend: {
        show: false,
        position: 'top',
        horizontalAlign: 'center',
        floating: true,
        offsetY: -10,
        offsetX: -1,
        fontSize: '15px',
        fontFamily: 'Helvetica, Arial',
        labels: {
          colors: ['#859a9f'],
          useSeriesColors: false
        }
      }
    }
  };
  return (
    <div id='lineChart' className='rts-graph-chart' aria-label={translator.t('trips.text_right_graph')}>
      <ReactApexChart options={lineChart.options} series={lineChart.series} type='line' height={350} />
    </div>
  );
};

export default Linechart;
