import React from 'react';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

const cliploaderOverride = css`
  display: block;
  margin: auto;
  position: absolute;
  left: 45%;
  top: 270px;
  margin-bottom: 300px;
`;

function Loader () {
  return (
    <div>
      <div className='emptyBuyPlansViewLoading'>
        <ClipLoader css={cliploaderOverride} sizeUnit={'px'} size={35} color={'#001e50'} />
        <br />
      </div>
    </div>
  );
}

export default Loader;
