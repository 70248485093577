import React from 'react';
import PropTypes from 'prop-types';
import { getPriceDescription } from '../buyPlansUtil';
import Price from '../components/Price';

export default class ReviewArticle extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    translator: PropTypes.object.isRequired,
    contentURL: PropTypes.string.isRequired,
    configuration: PropTypes.object.isRequired
  };

  render () {
    const { item, configuration, contentURL, translator } = this.props;
    const total = item.isTaxIncludedInPrice ? Number(item.price) : Number(item.price) + Number(item.itemTax);

    return (
      <section className='cartItem d-flex flex-column my-3 p-3'>
        <section className='d-flex flex-column flex-md-row justify-content-between'>
          <article className='d-flex flex-column flex-grow-1 align-items-start'>
            {configuration?.summary?.providerLogo && (
              <img src={contentURL + configuration?.summary?.providerLogo} alt={configuration?.summary?.providerLogo} />
            )}
            <strong className='h5 font-weight-bold mt-3'>{configuration?.summary?.title}</strong>
            <span className='small'>{getPriceDescription(translator, item)}</span>
            {Array.isArray(configuration?.pricingDisclaimerList) &&
              configuration.pricingDisclaimerList.map((pd, index) => (
                <span key={`pricingDisclaimer_${index}`} className='small text-muted'>
                  {pd.disclaimerText}
                </span>
              ))}
          </article>
          <article className='d-flex flex-column text-right text-nowrap h5'>
            <span className='ml-4'>
              <Price currency={item.currency} value={item.price?.toFixed(2)} />
            </span>
            <span className='mt-4 text-nowrap'>
              {item.isTaxIncludedInPrice && translator.t('buyplans_view_cart_taxes_included')}
              {!item.isTaxIncludedInPrice && (
                <>
                  <span className='mr-3 h6'>{translator.t('buyplans.label.taxes')}:</span>
                  <Price currency={item.currency} value={item.itemTax?.toFixed(2)} />
                </>
              )}
            </span>
            <span className='py-2 mt-3 text-nowrap h1 border-top price-total-border-top'>
              <span className='h6 mr-4'>{translator.t('buyplans.label.total')}:</span>
              <Price currency={item.currency} value={total?.toFixed(2)} />
            </span>
          </article>
        </section>
      </section>
    );
  }
}
