import {
  UPDATE_CUSTOMER_DETAILS,
  UPDATE_CUSTOMER_DETAILS_SUCCESS,
  UPDATE_CUSTOMER_DETAILS_FAILURE
} from '../../actions/types';

const initalState = {
  customerData: null
};

const updateCustomerDetailsReducer = (state = initalState, action = { payload: {} }) => {
  let newState = { ...state };
  let actionType = (action && action.type) || 'default';
  let responseData = action.payload || {};

  switch (actionType) {
    case UPDATE_CUSTOMER_DETAILS:
      newState = { ...initalState };

      return newState;

    case UPDATE_CUSTOMER_DETAILS_SUCCESS:
      let { data } = responseData;
      newState['customerData'] = data || null;
      newState['saveResult'] = true;

      return newState;
    case UPDATE_CUSTOMER_DETAILS_FAILURE:
      newState['customerData'] = null;
      newState['saveResult'] = false;
      return newState;
    default:
      return newState;
  }
};

export default updateCustomerDetailsReducer;
