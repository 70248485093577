import PropTypes from 'prop-types';

export default function Price (props) {
  const { currency, value } = props;
  switch (`${currency}`.toUpperCase()) {
    case 'CAD':
    case 'USD':
      return <>&#36;{value}</>;
    case 'POUND':
      return <>&#163;{value}</>;
    case 'YEN':
      return <>&#165;{value}</>;
    case 'EURO':
      return <>&#8364;{value}</>;
    case 'INR':
      return <>&#8377;{value}</>;
    default:
      return `${currency} ${value}`;
  }
}

Price.propTypes = {
  currency: PropTypes.string.isRequired,
  value: PropTypes.oneOfType(PropTypes.string, PropTypes.number)
};
