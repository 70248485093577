import PropTypes from 'prop-types';
import React from 'react';
import { BuyPlansContext } from '../BuyPlansContext';
import {
  PurchaseBlockReason,
  SubCategory,
  findArticleDisclaimerText,
  isNotPurchasableOrRestricted
} from '../buyPlansUtil';
import { Link } from 'react-router-dom';
import ModalPurchaseBlocked from '../components/ModalPurchaseBlocked';

export default class CardAdditionalPlan extends React.Component {
  static contextType = BuyPlansContext;

  static propTypes = {
    plan: PropTypes.object.isRequired,
    marketingConfiguration: PropTypes.object
  };

  state = {
    features: [],
    articleDisclaimer: null,
    showModalPurchaseBlock: false
  };

  componentDidMount () {
    const marketingConfiguration = this.props.marketingConfiguration;
    const articleDisclaimer = findArticleDisclaimerText(marketingConfiguration);

    const marketingFeatureIds = this.props.plan?.marketingFeatureIds;

    let features = marketingConfiguration?.summary?.features;

    if (Array.isArray(features) && Array.isArray(marketingFeatureIds)) {
      features = features.filter(f => marketingFeatureIds.includes(f.featureId));
    }

    this.setState({ articleDisclaimer, features });
  }

  showModalPurchaseBlock = () => this.setState({ showModalPurchaseBlock: true });
  hideModalPurchaseBlock = () => this.setState({ showModalPurchaseBlock: false });

  render () {
    const { contentURL, showDisclaimer } = this.context;
    const { plan, marketingConfiguration } = this.props;

    const iconUri = marketingConfiguration?.summary?.icon;

    return (
      <div className='h-100 d-flex flex-column align-items-start'>
        <section className='p-4'>
          <article className='d-flex flex-row'>
            {iconUri && <img className='mr-2 iconPlan' src={contentURL + iconUri} alt={iconUri} />}
            <h2 className='h4 font-weight-bold m-0'>
              {plan?.title || marketingConfiguration?.title}
              <button
                className='cwp-disclaimer'
                onClick={e =>
                  showDisclaimer(
                    this.state.articleDisclaimer,
                    marketingConfiguration.articleDisclaimerNumber,
                    e.currentTarget
                  )
                }
              >
                {marketingConfiguration?.articleDisclaimerNumber}
              </button>
            </h2>
          </article>
          <article className='mt-4'>
            <span className='cwpFontSmall'>{marketingConfiguration?.summary?.shortDescription}</span>
            {[SubCategory.ALEXA_WEBAPP, SubCategory.GARAGECONTROL_TRIAL].includes(plan.subCategory) && (
              <article>
                <img
                  src={contentURL + marketingConfiguration?.summary?.providerLogo}
                  alt={plan?.provider?.name}
                  className='mt-3'
                />
              </article>
            )}
          </article>
        </section>
        <hr className='hrAdditionalPlan' />
        <section className='p-4 d-flex flex-column'>
          {this.state.features.map(feature => (
            <article key={`featureId-${feature.featureId}`} className='my-2 feature'>
              {feature.featureText}
            </article>
          ))}
        </section>
        {this.renderButton()}
      </div>
    );
  }

  renderButton = () => {
    const { plan, marketingConfiguration } = this.props;

    if (isNotPurchasableOrRestricted(plan) && plan?.purchaseBlockReason === PurchaseBlockReason.TRIAL_IN_CURRENT_PLAN) {
      return (
        <article className='align-self-center mt-auto mb-3'>
          <button type='button' className='cwp cwpPrimary' onClick={() => this.showModalPurchaseBlock()}>
            {marketingConfiguration?.summary?.submitButtonText}
          </button>
          <ModalPurchaseBlocked
            translator={this.context.translator}
            purchaseBlockReason={plan?.purchaseBlockReason}
            isOpen={this.state.showModalPurchaseBlock === true}
            onClickClose={this.hideModalPurchaseBlock}
            onClickPrimary={this.hideModalPurchaseBlock}
            onClickSecondary={this.hideModalPurchaseBlock}
          />
        </article>
      );
    }

    return (
      <Link
        to={`/${plan.category}/${plan.subCategory}/${plan.marketingConfigurationId}`.toLowerCase()}
        className='align-self-center mt-auto mb-3'
      >
        <button type='button' className='cwp cwpPrimary' onClick={() => this.context.selectPlan(plan)}>
          {marketingConfiguration?.summary?.submitButtonText}
        </button>
      </Link>
    );
  };
}
