import {
  GET_VEHICLE_HEALTH_BY_VEHICLEID,
  GET_VEHICLE_HEALTH_BY_VEHICLEID_SUCCESS,
  GET_VEHICLE_HEALTH_BY_VEHICLEID_FAILURE
} from '../../actions/types';

const initalState = {
  vehicleHealthReportData: null,
  dataLoading: false
};

const vehicleHealthByVehicleIdReducer = (state = initalState, action = { payload: {} }) => {
  let newState = { ...state };
  let actionType = (action && action.type) || 'default';
  let responseData = action.payload || {};

  switch (actionType) {
    case GET_VEHICLE_HEALTH_BY_VEHICLEID:
      newState = { ...initalState };
      return newState;

    case GET_VEHICLE_HEALTH_BY_VEHICLEID_SUCCESS:
      let { data } = responseData;
      console.log('vehicleHealthReportData in Reducer', JSON.stringify(data, null, 1));
      newState['vehicleHealthReportData'] = data || null;
      newState['dataLoading'] = true;
      return newState;
    case GET_VEHICLE_HEALTH_BY_VEHICLEID_FAILURE:
      newState['vehicleHealthReportData'] = null;
      newState['dataLoading'] = false;
      return newState;
    default:
      return newState;
  }
};

export default vehicleHealthByVehicleIdReducer;
